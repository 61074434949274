import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { cleanup, getVulnerability } from "@/reducers/vulnerabilities/vulnerabilityInfo/vulnerabilityInfoAction";
import { useState } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import InfoIcon from '@mui/icons-material/Info';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PersonIcon from '@mui/icons-material/Person';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TabPanel, { tabIndexProps } from "@/shared/components/MaterialTabPanel";
import dayjs from "dayjs";

const VulnerabilityInfoModal = ({ open, onHideModal, vulnerabilityId }) => {
    const dispatch = useDispatch();
  
    const { 
      vulnerability, isLoadingVulnerability, getVulnerabilityError
    } = useSelector(({ vulnerabilityInfo }) => vulnerabilityInfo);

    const [tab, setTab] = useState(0);

    const handleTabChange = (event, newValue) => {
      setTab(newValue);
    };

    useEffect(() => {
      dispatch(getVulnerability(vulnerabilityId));

      return () => {
        dispatch(cleanup());
      }
    }, [dispatch]);

    useEffect(() => {
      console.log(vulnerability);
    }, [vulnerability]);
  
    const handleClose = (event, reason) => {
      onHideModal();
    }
  
    useEffect(() => {
      if(getVulnerabilityError){
        enqueueSnackbar(getVulnerabilityError, {variant: "error"});
      }
    }, [getVulnerabilityError]);
  
    return (
      <Dialog
          fullWidth={true}
          maxWidth='lg'
          scroll='paper'
          open={open}
          onClose={handleClose}
      >
          <DialogTitle>Vulnerability Info</DialogTitle>
          <DialogContent style={{height: 500}}>
          {isLoadingVulnerability || !vulnerability ? <div style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><CircularProgress /></div> : 
          <>
            <Tabs
              value={tab}
              variant="fullWidth"
              onChange={handleTabChange}
              aria-label="icon position tabs example"
            >
              <Tab 
                icon={<InfoIcon />} 
                iconPosition="start" 
                label="General Info"
                {...tabIndexProps(0)}
              />
              <Tab 
                icon={<TextSnippetIcon />} 
                iconPosition="start" 
                label="Metadata"
                {...tabIndexProps(1)}
              />
              <Tab 
                icon={<PersonIcon />} 
                iconPosition="start" 
                label="Audit"
                {...tabIndexProps(2)}
              />
            </Tabs>
          
            <TabPanel value={tab} index={0}>
              <Row>
                <Col md={12}>
                  <TextField
                      name='name'
                      label='Name'
                      defaultValue={vulnerability.name}
                      fullWidth
                      multiline
                      size="small"
                      rows={4}
                      InputProps={{
                        readOnly: true
                      }}
                  />
                </Col>
                <Col md={12}>
                  <TextField
                      style={{marginTop: 20}}
                      name='cve'
                      label='CVE'
                      defaultValue={vulnerability.cve}
                      fullWidth
                      size="small"
                      InputProps={{
                        readOnly: true
                      }}
                  />
                </Col>
                <Col md={6}>
                  <TextField
                      style={{marginTop: 20}}
                      name='baseScore'
                      label='Base Score'
                      defaultValue={vulnerability.baseScore}
                      fullWidth
                      size="small"
                      InputProps={{
                        readOnly: true
                      }}
                  />
                </Col>
                <Col md={6}>
                  <TextField
                      style={{marginTop: 20}}
                      name='epssScore'
                      label='EPSS Score'
                      defaultValue={vulnerability.epssScore}
                      fullWidth
                      size="small"
                      InputProps={{
                        readOnly: true
                      }}
                  />
                </Col>
                <Col md={6}>
                  <TextField
                      style={{marginTop: 20}}
                      name='knownExploited'
                      label='Known Exploited'
                      defaultValue={vulnerability.knownExploited}
                      fullWidth
                      size="small"
                      InputProps={{
                        readOnly: true
                      }}
                  />
                </Col>
                <Col md={6}>
                  <TextField
                      style={{marginTop: 20}}
                      name='source'
                      label='Source'
                      defaultValue={vulnerability.source}
                      fullWidth
                      size="small"
                      InputProps={{
                        readOnly: true
                      }}
                  />
                </Col>
              </Row>
            </TabPanel>
            <TabPanel value={tab} index={1}>
            { vulnerability.metadata && Object.keys(vulnerability.metadata).length ?
                Object.keys(vulnerability.metadata).map((key, index) => 
                  <Accordion key={index}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                    <Typography>{key}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <pre style={{"padding-right": "0px", "contain": "inline-size", "overflow-x": "scroll"}}>
                        {JSON.stringify(vulnerability.metadata[key], null, 2)}
                      </pre>
                    </AccordionDetails>
                  </Accordion>
                ) : 
                <div style={{display: "flex", height: '300px', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                    <Typography>No content to show.</Typography>
                </div>
              }              
            </TabPanel>
            <TabPanel value={tab} index={2}>
             <Row>
                <Col md={6}>
                  <TextField
                      style={{marginTop: 20}}
                      name='createDate'
                      label='Create Date'
                      defaultValue={vulnerability.createDate ? dayjs(vulnerability.createDate).format("DD/MM/YYYY") : "-"}
                      fullWidth
                      size="small"
                      InputProps={{
                        readOnly: true
                      }}
                  />
                </Col>
                <Col md={6}>
                  <TextField
                      style={{marginTop: 20}}
                      name='createdByUserId'
                      label='Created By User'
                      defaultValue={vulnerability.createdByUserId ?? "-"}
                      fullWidth
                      size="small"
                      InputProps={{
                        readOnly: true
                      }}
                  />
                </Col>
              </Row>
              <Row style={{marginTop: 20}}>
                <Col md={6}>
                  <TextField
                      style={{marginTop: 20}}
                      name='updateDate'
                      label='Update Date'
                      defaultValue={vulnerability.updateDate ? dayjs(vulnerability.updateDate).format("DD/MM/YYYY") : "-"}
                      fullWidth
                      size="small"
                      InputProps={{
                        readOnly: true
                      }}
                  />
                </Col>
                <Col md={6}>
                  <TextField
                      style={{marginTop: 20}}
                      name='updatedByUserId'
                      label='Updated By User'
                      defaultValue={vulnerability.updatedByUserId ?? "-"}
                      fullWidth
                      size="small"
                      InputProps={{
                        readOnly: true
                      }}
                  />
                </Col>
              </Row>
              <Row style={{marginTop: 20}}>
                <Col md={6}>
                  <TextField
                      style={{marginTop: 20}}
                      name='deleteDate'
                      label='Delete Date'
                      defaultValue={vulnerability.deleteDate ? dayjs(vulnerability.deleteDate).format("DD/MM/YYYY") : "-"}
                      fullWidth
                      size="small"
                      InputProps={{
                        readOnly: true
                      }}
                  />
                </Col>
                <Col md={6}>
                  <TextField
                      style={{marginTop: 20}}
                      name='deletedByUserId'
                      label='Deleted By User'
                      defaultValue={vulnerability.deletedByUserId ?? "-"}
                      fullWidth
                      size="small"
                      InputProps={{
                        readOnly: true
                      }}
                  />
                </Col>
              </Row>
            </TabPanel>
            </>
            }
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Close</Button>
          </DialogActions>
      </Dialog>
    );
  }
  
  export default VulnerabilityInfoModal;