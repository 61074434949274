import axios from "axios";
import { logout, refreshToken } from "../reducers/auth/authAction";
import { supportedLanguages } from "../utils/supportedLanguages";

export const instance = axios.create({
  baseURL:
    process.env.REACT_APP_API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

const retryQueue = [];
let isRefreshing = false;

const setup = (store) => {
  const { dispatch } = store;
  instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }

      var currentLanguage = localStorage.getItem("language") || navigator.language.split('-')[0];
      if(!supportedLanguages.includes(currentLanguage)){
        currentLanguage = supportedLanguages[0];
      }

      config.headers["Accept-Language"] = currentLanguage;

      config.timeout = 3000000;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      // dispatch(refreshToken(localStorage.getItem("accessToken", false)));
      return response;
    },
    async (error) => {
      const originalConfig = error.config;
      // if(error.response && error.response.data){
      //   if(error.response.data.title){
      //     enqueueSnackbar(error.response.data.title, {variant: "error"});
      //   }
      //   else if(error.response.data.description){
      //     // enqueueSnackbar(error.response.data.description, {variant: "error"});
      //   }
      //   else{
      //     enqueueSnackbar(error.response.data, {variant: "error"});
      //   }
      // }
      // else if(error.message){
      //   console.log(error.message);
      //   enqueueSnackbar(error.message, {variant: "error"});
      // }
      if (originalConfig.url !== "/login" && error.config) {
        if (error.response && error.response.status === 401) {
          if(!isRefreshing){
            isRefreshing = true;

            try {
              const rs = await instance.post("/v1/Account/RefreshToken", {
                token: localStorage.getItem("accessToken"),
                refreshToken: localStorage.getItem("refreshToken")
              });

              dispatch(refreshToken(rs.data.token, rs.data.refreshToken));

              retryQueue.forEach(({ config, resolve, reject }) => {
                instance
                  .request(config)
                  .then((response) => resolve(response))
                  .catch((err) => reject(err));
              });
    
              retryQueue.length = 0;

              return instance(originalConfig);
            } catch (error) {
              return dispatch(logout());
            } finally {
              isRefreshing = false;
            }
          }

          return new Promise((resolve, reject) => {
            retryQueue.push({ config: originalConfig, resolve, reject });
          });
        }
      }
      return Promise.reject(error);
    }
  );
};

export default setup;
