import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Card, CardContent, Chip, CircularProgress, Typography, Accordion, AccordionDetails, AccordionSummary, Paper, Button, Divider, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import PeopleIcon from '@mui/icons-material/People';
import SendIcon from '@mui/icons-material/Send';
import WarningIcon from '@mui/icons-material/Warning';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FlagIcon from '@mui/icons-material/Flag';
import ShortTextIcon from '@mui/icons-material/ShortText';
import NotesIcon from '@mui/icons-material/Notes';
import dayjs from 'dayjs';
import { styled } from '@mui/system';
import ActivityUserRoles from './activityUserRoles';
import { useState } from 'react';

const ActivityInfoPanel = ({activity}) => {

    const [open, setOpen] = useState(false);

    return(
        <Accordion 
            variant='outline' 
            expanded={open} 
            onChange={() => setOpen(!open)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                width: 'fill-available'
            }}>
                
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: 'fill-available'
                    }}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>                        
                            {activity &&
                                <Box sx={{display: 'flex', alignItems: 'center'}}>
                                    <Typography fontSize={18} marginRight="2px">{<strong>{activity.activityType.label}</strong>}</Typography>
                                    <Typography>(Status: {activity.workflowActivityStatus.label})</Typography>
                                </Box>
                            }
                        </Box>

                        {activity &&
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Typography>{activity.startDate ? dayjs(activity.startDate).format("DD/MM/YYYY") : ""} - {activity.endDate ? dayjs(activity.endDate).format("DD/MM/YYYY") : ""}</Typography>
                                <Chip 
                                    sx={{
                                        backgroundColor: activity.workflowActivityPriority.color,
                                        marginRight: 2,
                                        marginLeft: 1
                                    }} 
                                    label={activity.workflowActivityPriority.name} size="small" 
                                />
                            </Box>
                        }
                    </Box>
                
            </Box>
            </AccordionSummary>
            <AccordionDetails>
                {!activity ? <div style={{height: '200px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><CircularProgress /></div> : 
                    <Row>
                        <Col md={8}>
                            <Card style={{height: "100%"}}>
                                <CardContent>
                                    <TitleContainer>
                                        <InfoIcon />
                                        <Typography>Description</Typography>
                                    </TitleContainer>

                                    {/* <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginBottom: 2
                                    }}>
                                        <Typography style={{fontSize: 14}}>{activity.workflowActivityStatus.label}</Typography>
                                        <Divider style={{marginInline: 10}} orientation="vertical" flexItem component='div' />
                                        <Typography color='text.secondary' style={{fontSize: 14}}>{activity.startDate ? dayjs(activity.startDate).format("DD/MM/YYYY") : ""} - {activity.endDate ? dayjs(activity.endDate).format("DD/MM/YYYY") : ""}</Typography>
                                        <Divider style={{marginInline: 10}} orientation="vertical" flexItem component='div' />
                                        <Chip style={{backgroundColor: activity.workflowActivityPriority.color}} label={activity.workflowActivityPriority.name} size="small" />
                                    </Box> */}
                                        <Typography fontSize={16}>{activity.description}</Typography>
                                </CardContent>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card style={{height: "100%"}}>
                                <CardContent>
                                    <TitleContainer>
                                        <PeopleIcon />
                                        <Typography>Users</Typography>
                                    </TitleContainer>
                                        <ActivityUserRoles activity={activity}/>
                                </CardContent>
                            </Card>
                        </Col>
                    </Row>
                }
            </AccordionDetails>
        </Accordion>
    );
}

const TitleContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  & > p{
    font-size: 18px;
  }

  & > svg{
    margin-right: 5px;
    font-size: 22px;
  }
`;

export default ActivityInfoPanel;