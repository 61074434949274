import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_AUDIT_LOGS');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getAuditLogsRequest = createAction('GET_AUDIT_LOGS_REQUEST');
export const getAuditLogsSuccess = createAction('GET_AUDIT_LOGS_SUCCESS');
export const getAuditLogsError = createAction('GET_AUDIT_LOGS_ERROR');

export const getAuditLogs = (data) => async (dispatch) => {
  try {
    dispatch(getAuditLogsRequest());
    
    const resp = await instance.get(`/v1/AuditLogInfra/GetAuditLogByFilters`, { params: {
      ...data
    }});
    
    dispatch(getAuditLogsSuccess(resp.data));

  } catch (e) {
    
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getAuditLogsError(e.response.data.description));
    else
      dispatch(getAuditLogsError(e.message));
  }
};