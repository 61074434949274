import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_ASSET_VULNERABILITY');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getSchemaRequest = createAction('ASSET_VULNERABILITY_GET_SCHEMA_REQUEST');
export const getSchemaSuccess = createAction('ASSET_VULNERABILITY_GET_SCHEMA_SUCCESS');
export const getSchemaError = createAction('ASSET_VULNERABILITY_GET_SCHEMA_ERROR');

export const getSchema = () => async (dispatch) => {
  try {
    dispatch(getSchemaRequest());
    const resp = await instance.get(`/v1/AssetVulnerability/GetSchema`);
    
    dispatch(getSchemaSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getSchemaError(e.response.data.description));
    else
      dispatch(getSchemaError(e.message));
  }
};

export const getDomainsRequest = createAction('ASSET_VULNERABILITY_GET_DOMAINS_REQUEST');
export const getDomainsSuccess = createAction('ASSET_VULNERABILITY_GET_DOMAINS_SUCCESS');
export const getDomainsError = createAction('ASSET_VULNERABILITY_GET_DOMAINS_ERROR');

export const getDomains = () => async (dispatch) => {
  try {
    dispatch(getDomainsRequest());
    const resp = await instance.get(`/v1/Domain/GetAll`);
    
    dispatch(getDomainsSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getDomainsError(e.response.data.description));
    else
      dispatch(getDomainsError(e.message));
  }
};

export const getRatingScoresRequest = createAction('ASSET_VULNERABILITY_GET_RATING_SCORES_REQUEST');
export const getRatingScoresSuccess = createAction('ASSET_VULNERABILITY_GET_RATING_SCORES_SUCCESS');
export const getRatingScoresError = createAction('ASSET_VULNERABILITY_GET_RATING_SCORES_ERROR');

export const getRatingScores = () => async (dispatch) => {
  try {
    dispatch(getRatingScoresRequest());
    const resp = await instance.get(`/v1/RatingScore/GetAll`);
    
    dispatch(getRatingScoresSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getRatingScoresError(e.response.data.description));
    else
      dispatch(getRatingScoresError(e.message));
  }
};

export const getListForRemediationRequest = createAction('ASSET_VULNERABILITY_GET_LIST_REMEDIATION_REQUEST');
export const getListForRemediationSuccess = createAction('ASSET_VULNERABILITY_GET_LIST_REMEDIATION_SUCCESS');
export const getListForRemediationError = createAction('ASSET_VULNERABILITY_GET_LIST_REMEDIATION_ERROR');

export const getListForRemediation = (data) => async (dispatch) => {
  try {
    dispatch(getListForRemediationRequest());
    const resp = await instance.get(`/v1/AssetVulnerability/GetListForRemediation`, data);

    dispatch(getListForRemediationSuccess(resp.data));    
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getListForRemediationError(e.response.data.description));
    else
      dispatch(getListForRemediationError(e.message));
  }
};

export const addRemediationRequest = createAction('ASSET_VULNERABILITY_ADD_REMEDIATION_REQUEST');
export const addRemediationSuccess = createAction('ASSET_VULNERABILITY_ADD_REMEDIATION_SUCCESS');
export const addRemediationError = createAction('ASSET_VULNERABILITY_ADD_REMEDIATION_ERROR');

export const addRemediation = (data) => async (dispatch) => {
  try {
    dispatch(addRemediationRequest());
    const resp = await instance.post(`/v1/Remediation/Post`, data);

    dispatch(addRemediationSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(addRemediationError(e.response.data.description));
    else
      dispatch(addRemediationError(e.message));
  }
};