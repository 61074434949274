import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getSurveysRequest,
  getSurveysSuccess,
  getSurveysError,
  updateSurveyProgressRequest,
  completeCustomSurveyRequest
} from './surveyListAction';

const defaultState = {
  surveys: null,
  isLoadingSurveys: true,
  getSurveysError: null 
};

export default handleActions(
  {
    [getSurveysRequest](state) {
      return {
        ...state,
        surveys: null,
        isLoadingSurveys: true,
        getSurveysError: null,
      };
    },
    [getSurveysSuccess](state, { payload }) {
      return {
        ...state,
        surveys: payload,
        isLoadingSurveys: false,
        getSurveysError: null,
      };
    },
    [getSurveysError](state, { payload }) {
      return {
        ...state,
        surveys: null,
        isLoadingSurveys: false,
        getSurveysError: payload,
      };
    },
    [updateSurveyProgressRequest](state, { payload }) {
      var surveysClone = JSON.parse(JSON.stringify(state.surveys));
      var foundSurvey = surveysClone.resultSurveys.find(r => r.surveyId === payload.surveyId);
      foundSurvey.percentCompleted = payload.saveResult.newProgress;
      foundSurvey.calculation = payload.saveResult.calculation;

      return {
        ...state,
        surveys: surveysClone
      };
    },
    [completeCustomSurveyRequest](state, { payload }) {
      var surveysClone = JSON.parse(JSON.stringify(state.surveys));
      var foundSurvey = surveysClone.resultSurveys.find(r => r.surveyId === payload.surveyId);
      foundSurvey.percentCompleted = 1;

      return {
        ...state,
        surveys: surveysClone
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);