import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getActivityRequest,
  getActivitySuccess,
  getActivityError,
  getActivityStepsRequest,
  getActivityStepsSuccess,
  getActivityStepsError,
  getActivityTasksRequest,
  getActivityTasksSuccess,
  getActivityTasksError,
  getStepProgressRequest,
  getStepProgressSuccess,
  getStepProgressError,
  nextStatusRequest,
  nextStatusSuccess,
  nextStatusError
} from './editStepAction';

const defaultState = {
  activity: null,
  isLoadingActivity: true,
  getActivityError: null,
  steps: null,
  isLoadingSteps: true,
  getStepsError: null,
  tasks: null,
  isLoadingTasks: true,
  getTasksError: null,
  stepProgresses: [],
  isNextStatus: false,
  isNextStatusFinished: false,
  nextStatusError: null
};

export default handleActions(
  {
    [getActivityRequest](state) {
      return {
        ...state,
        activity: null,
        isLoadingActivity: true,
        getActivityError: null,
      };
    },
    [getActivitySuccess](state, { payload }) {
      return {
        ...state,
        activity: payload,
        isLoadingActivity: false,
        getActivityError: null,
      };
    },
    [getActivityError](state, { payload }) {
      return {
        ...state,
        activity: null,
        isLoadingActivity: false,
        getActivityError: payload,
      };
    },
    [getActivityStepsRequest](state) {
      return {
        ...state,
        steps: null,
        isLoadingSteps: true,
        getStepsError: null,
      };
    },
    [getActivityStepsSuccess](state, { payload }) {
      const newStepProgresses = payload.map((p) => {return {
        workflowActivityStepCompletedId: p.workflowActivityStepCompletedId,
        percentageCompleted: p.percentageCompleted,
        isLoadingProgress: false,
        getProgressError: null
      }});

      return {
        ...state,
        steps: payload,
        isLoadingSteps: false,
        getStepsError: null,
        stepProgresses: newStepProgresses
      };
    },
    [getActivityStepsError](state, { payload }) {
      return {
        ...state,
        steps: null,
        isLoadingSteps: false,
        getStepsError: payload,
      };
    },
    [getStepProgressRequest](state, { payload }) {
      const newStepProgresses = JSON.parse(JSON.stringify(state.stepProgresses));

      const stepProgress = newStepProgresses.find(s => s.workflowActivityStepCompletedId === payload.id);
      stepProgress.isLoadingProgress = true;
      stepProgress.getProgressError = null;

      return {
        ...state,
        stepProgresses: newStepProgresses
      };
    },
    [getStepProgressSuccess](state, { payload }) {
      const newStepProgresses = JSON.parse(JSON.stringify(state.stepProgresses));

      const stepProgress = newStepProgresses.find(s => s.workflowActivityStepCompletedId === payload.id);
      stepProgress.percentageCompleted = payload.data;
      stepProgress.isLoadingProgress = false;
      stepProgress.getProgressError = null;
      
      return {
        ...state,
        stepProgresses: newStepProgresses
      };
    },
    [getStepProgressError](state, { payload }) {
      const newStepProgresses = JSON.parse(JSON.stringify(state.stepProgresses));

      const stepProgress = newStepProgresses.find(s => s.workflowActivityStepCompletedId === payload.id);
      stepProgress.isLoadingProgress = false;
      stepProgress.getProgressError = payload.data;

      return {
        ...state,
        stepProgresses: newStepProgresses
      };
    },
    [getActivityTasksRequest](state) {
      return {
        ...state,
        tasks: null,
        isLoadingTasks: true,
        getTasksError: null,
      };
    },
    [getActivityTasksSuccess](state, { payload }) {
      return {
        ...state,
        tasks: payload,
        isLoadingTasks: false,
        getTasksError: null,
      };
    },
    [getActivityTasksError](state, { payload }) {
      return {
        ...state,
        tasks: null,
        isLoadingTasks: false,
        getTasksError: payload,
      };
    },
    [nextStatusRequest](state) {
      return {
        ...state,
        isNextStatus: true,
        isNextStatusFinished: false,
        nextStatusError: null,
      };
    },
    [nextStatusSuccess](state, { payload }) {
      return {
        ...state,
        isNextStatusFinished: true,
        isNextStatus: false,
        nextStatusError: null,
      };
    },
    [nextStatusError](state, { payload }) {
      return {
        ...state,
        isNextStatus: false,
        isNextStatusFinished: false,
        nextStatusError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);
