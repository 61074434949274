import { FormSelectField } from '@/shared/components/form/material-controls/SelectField';
import { useMemo } from 'react';

const TypeSelect = ({
    typeCode,
    name,
    defaultValue,
    parameterTypes,
    ...other
}) => {
    const options = useMemo(() => {
        return parameterTypes.filter(p => p.code === typeCode);
    }, [parameterTypes, typeCode]);

    return (
        <FormSelectField
            name={name}
            disableClearable={true}
            keyValue={{id:"parameterTypeId", label:"value"}}
            defaultValue={defaultValue ?? options[0].parameterTypeId}
            options={options}
            {...other}
        />
    );
}

export default TypeSelect;