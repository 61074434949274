import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_ADD_DOMAIN');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const addDomainRequest = createAction('ADD_DOMAIN_POST_REQUEST');
export const addDomainSuccess = createAction('ADD_DOMAIN_POST_SUCCESS');
export const addDomainError = createAction('ADD_DOMAIN_POST_ERROR');

export const addDomain = (request) => async (dispatch) => {
  try {
    dispatch(addDomainRequest());
    const resp = await instance.post(`/v1/Domain/Post`, request);

    dispatch(addDomainSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(addDomainError(e.response.data.description));
    else
      dispatch(addDomainError(e.message));
  }
};
