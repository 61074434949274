import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_FORGOT_PASSWORD');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const forgotPasswordRequest = createAction('FORGOT_PASSWORD_REQUEST');
export const forgotPasswordSuccess = createAction('FORGOT_PASSWORD_SUCCESS');
export const forgotPasswordError = createAction('FORGOT_PASSWORD_ERROR');

export const forgotPassword = (data) => async (dispatch) => {
  try {
    dispatch(forgotPasswordRequest());
    const resp = await instance.post(`/v1/Account/ResetForgottedPassword`, data);

    dispatch(forgotPasswordSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(forgotPasswordError(e.response.data.description));
    else if(e.response && e.response.data && e.response.data.description)
      dispatch(forgotPasswordError(e.response.data.description));
    else
      dispatch(forgotPasswordError(e.message));
  }
};
