import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_CARBON_FOOTPRINT_SURVEY');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getParameterUnitsRequest = createAction('CARBON_FOOTPRINT_SURVEY_GET_PARAMETERS_REQUEST');
export const getParameterUnitsSuccess = createAction('CARBON_FOOTPRINT_SURVEY_GET_PARAMETERS_SUCCESS');
export const getParameterUnitsError = createAction('CARBON_FOOTPRINT_SURVEY_GET_PARAMETERS_ERROR');

export const getParameterUnits = () => async (dispatch) => {
  try {
    dispatch(getParameterUnitsRequest());
    const resp = await instance.get(`/v1/ParameterUnit/GetParameterUnits`);
    
    dispatch(getParameterUnitsSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getParameterUnitsError(e.response.data.description));
    else
      dispatch(getParameterUnitsError(e.message));
  }
};

export const getParameterTypesRequest = createAction('CARBON_FOOTPRINT_SURVEY_GET_PARAMETER_TYPES_REQUEST');
export const getParameterTypesSuccess = createAction('CARBON_FOOTPRINT_SURVEY_GET_PARAMETER_TYPES_SUCCESS');
export const getParameterTypesError = createAction('CARBON_FOOTPRINT_SURVEY_GET_PARAMETER_TYPES_ERROR');

export const getParameterTypes = () => async (dispatch) => {
  try {
    dispatch(getParameterTypesRequest());
    const resp = await instance.get(`/v1/ParameterType/GetParameterTypes`);
    
    dispatch(getParameterTypesSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getParameterTypesError(e.response.data.description));
    else
      dispatch(getParameterTypesError(e.message));
  }
};


export const completeRequest = createAction('CARBON_FOOTPRINT_SURVEY_COMPLETE_REQUEST');
export const completeSuccess = createAction('CARBON_FOOTPRINT_SURVEY_COMPLETE_SUCCESS');
export const completeError = createAction('CARBON_FOOTPRINT_SURVEY_COMPLETE_ERROR');

export const complete = (data) => async (dispatch) => {
  try {
    dispatch(completeRequest());
    const resp = await instance.post(`/v1/ActivitySurvey/UpdateEmissionCo2`, data);
    
    dispatch(completeSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(completeError(e.response.data.description));
    else
      dispatch(completeError(e.message));
  }
};