import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getAssemblyCollectionListRequest,
  getAssemblyCollectionListSuccess,
  getAssemblyCollectionListError
} from './serviceConnectorsAction';

const defaultState = {
  assemblyCollectionList: []
};

export default handleActions(
  {
    [getAssemblyCollectionListRequest](state, { payload }) {
        const newCollectionsList = JSON.parse(JSON.stringify(state.assemblyCollectionList));

        newCollectionsList.push({
            connectorId: payload.connectorId,
            assemblyCollection: [],
            isLoadingAssemblyCollection: true,
            getAssemblyCollectionError: null
        });

        return {
            ...state,
            assemblyCollectionList: newCollectionsList
        };
    },
    [getAssemblyCollectionListSuccess](state, { payload }) {
        const newCollectionsList = JSON.parse(JSON.stringify(state.assemblyCollectionList));

        const collection = newCollectionsList.find(c => c.connectorId === payload.connectorId);
        collection.assemblyCollection = payload.data;
        collection.isLoadingAssemblyCollection = false;
        collection.getAssemblyCollectionError = null;

        return {
            ...state,
            assemblyCollectionList: newCollectionsList
        };
    },
    [getAssemblyCollectionListError](state, { payload }) {
        const newCollectionsList = JSON.parse(JSON.stringify(state.assemblyCollectionList));

        const collection = newCollectionsList.find(c => c.connectorId === payload.connectorId);
        collection.assemblyCollection = [];
        collection.isLoadingAssemblyCollection = false;
        collection.getAssemblyCollectionError = payload.data;

        return {
            ...state,
            assemblyCollectionList: newCollectionsList
        };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);