import { createAction } from 'redux-actions';
import { instance } from "../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_MENU');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getCurrentMenuRequest = createAction('MENU_GET_CURRENT_MENU_REQUEST');
export const getCurrentMenuSuccess = createAction('MENU_GET_CURRENT_MENU_SUCCESS');
export const getCurrentMenuError = createAction('MENU_GET_CURRENT_MENU_ERROR');

export const getCurrentMenu = () => async (dispatch) => {
  try {
    dispatch(getCurrentMenuRequest());
    const resp = await instance.get(`/v1/Navigation/GetCurrentMenu`);
    
    dispatch(getCurrentMenuSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getCurrentMenuError(e.response.data.description));
    else
      dispatch(getCurrentMenuError(e.message));
  }
};