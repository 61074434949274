import { getTokenRoles } from "../../utils/helpers";
import * as type from "./types";
import { jwtDecode } from "jwt-decode";

const initialState = {
  tokenPayload: localStorage.getItem("accessToken") 
    ? jwtDecode(localStorage.getItem("accessToken"))
    : null,
  token: localStorage.getItem("accessToken") ?? null,
  refreshToken: localStorage.getItem("refreshToken") ?? null,
  isLoggedIn: localStorage.getItem("accessToken") ? true : false,
  isLogging: false,
  urlRedirect: null,
  roles: localStorage.getItem("accessToken") 
    ? getTokenRoles(jwtDecode(localStorage.getItem("accessToken")))
    : [],
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOGIN:
      return {
        ...state,
        token: action.token,
        refreshToken: action.refreshToken,
        isLoggedIn: action.isLoggedIn,
        isLogging: action.isLogging,
        urlRedirect: action.urlRedirect,
        tokenPayload: action.tokenPayload,
        roles: action.roles
      };
    case type.LOGIN2FA:
      return {
        ...state,
        token: action.token,
        refreshToken: action.refreshToken,
        isLoggedIn: action.isLoggedIn,
        isLogging: action.isLogging,
        urlRedirect: null,
        tokenPayload: action.tokenPayload,
        roles: action.roles
      };
    case type.LOGOUT:
      return {
        ...state,
        token: action.token,
        refreshToken: action.refreshToken,
        isLoggedIn: action.isLoggedIn,
        tokenPayload: null,
        roles: null
      };
    case type.REFRESH_TOKEN:
      return {
        ...state,
        token: action.token,
        refreshToken: action.refreshToken
      };
    case type.IMPERSONATE_TENANT:
      return {
        ...state,
        token: action.token,
        refreshToken: action.refreshToken,
        isLoggedIn: action.isLoggedIn,
        isLogging: action.isLogging,
        urlRedirect: action.urlRedirect,
        tokenPayload: action.tokenPayload,
        roles: action.roles
      };
    default:
      return state;
  }
};

export default AuthReducer;
