import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  updateAssetRequest,
  updateAssetSuccess,
  updateAssetError
} from './AUUpdateAssetAction';

const defaultState = {
  isUpdatingAsset: false, 
  updatedAsset: false, 
  updateAssetError: null
};

export default handleActions(
  {
    [updateAssetRequest](state) {
      return {
        ...state,
        isUpdatingAsset: true,
        updateAssetError: null,
        updatedAsset: false
      };
    },
    [updateAssetSuccess](state, { payload }) {
      return {
        ...state,
        isUpdatingAsset: false,
        updateAssetError: null,
        updatedAsset: true
      };
    },
    [updateAssetError](state, { payload }) {
      return {
        ...state,
        isUpdatingAsset: false,
        updateAssetError: payload,
        updatedAsset: false
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);