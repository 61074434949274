import { Box, Typography } from "@mui/material";

const ReportGradient = ({
    calculation,
    gradient
}) => {
    return(
        <Box sx={{
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            flexWrap: 'nowrap',
            height: '100%',
            width: '100%'
        }}>
            <Box sx={{
                height: "10px",
                background: "#626262",
                position: "relative",
                borderRadius: "25px",
                width: '100%'
            }}>
                <Box sx={{
                    height: "100%",
                    borderRadius: "25px",
                    mask: "linear-gradient(#fff 0 0)",
                    width: (100 / 5) * calculation.value + '%',

                    "::before": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundImage: gradient
                    }
                }}>
                </Box>
            </Box>
            <Typography sx={{
                fontSize: '24px',
                color: calculation.color,
                ml: 1,
                fontWeight: 'bold'
            }}>{calculation.value}</Typography>
        </Box>
    );
}

export default ReportGradient;