export function IsValidEmail(email){
    const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return email.match(emailFormat);
}

export function IsValidPhoneNumber(number){
    const format = /^\d{10}$/;
    return number.match(format);
}

export function HasUpperCase(text){
    return text.toLowerCase() !== text;
}

export function HasLowerCase(text){
    return text.toUpperCase() !== text;
}

export function HasNumber(text){
    const pattern = /\d/;
    return pattern.test(text);
}

export function HasNonAlphanumeric(text){
    const pattern = /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/;
    return pattern.test(text);
}