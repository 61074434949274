import React, { useEffect, useState } from "react";
import {
  AccountCard,
  AccountContent,
  AccountHead,
  AccountLogo,
  AccountLogoAccent,
  AccountFooter,
  AccountTitle,
  AccountWrap,
  Logo,
  FormWrapper,
  LogoCompany,
  FormButtonContainer,
  AccountHeader,
  AccountLogoFooter,
  TitleWrapper
} from "@/shared/components/account/AccountElements";
import { login } from "@/reducers/auth/authAction";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import { FormPasswordField } from "@/shared/components/form/material-controls/PasswordField";
import { FormTextField } from "@/shared/components/form/material-controls/TextField";
import { FormCheckboxField } from "@/shared/components/form/material-controls/CheckboxField";
import { useForm } from "react-hook-form";
import { Backdrop, Button, CircularProgress, InputAdornment, Link, Typography } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import { Link as ReactRouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import LoginChatbot from "./components/LoginChatbot";
import { useRef } from "react";

const LogIn = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const redirectUrl = queryParams.get("redirectUrl");

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const emailRef = useRef(null);

    const {
        token, refreshToken, isLoggedIn, isLogging, urlRedirect
    } = useSelector(({ auth }) => auth);

    const { handleSubmit, reset, control, setValue, getValues } = useForm();
    const [showPassword, setShowPassword] = useState(false);

    const onSubmit = (data) => {
        dispatch(login(data, navigate, redirectUrl));
    };

    useEffect(() => {
        if(urlRedirect){
            navigate(urlRedirect);
        }
    }, [urlRedirect]);

    const OnTogglePasswordVisibility = (show) => {
        setShowPassword(show);
    };

    return(
    <AccountWrap>
        <AccountContent>
            <AccountCard>
                <AccountHeader />
                <LoginChatbot 
                    emailQuerySelector="#username"
                    passwordQuerySelector="#outlined-adornment-password-password"
                    passwordToggleQuerySelector="#togglePassword"
                    showPassword={showPassword}
                />
                <FormWrapper>
                    <Row>
                        <Col md={12}>
                            <FormTextField
                                id='username'
                                name="username"
                                label="Username / Email"
                                control={control}
                                rules={{ required: 'This field is required' }}
                                size=''
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircleIcon />
                                    </InputAdornment>
                                    ),
                                }}
                                inputRef={emailRef}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormPasswordField 
                                id='password'
                                name="password"
                                label="Password"
                                control={control}
                                rules={{ required: 'This field is required' }} 
                                size=''
                                toggleButtonId="togglePassword"
                                onToggleVisibilityChange={OnTogglePasswordVisibility}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <LockIcon />
                                    </InputAdornment>
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <ForgotPasswordWrapper>
                                <Link component={ReactRouterLink} to={"/Account/ForgotPassword"} underline="hover">
                                    Forgot Password?
                                </Link>
                            </ForgotPasswordWrapper>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormCheckboxField
                                name='rememberMe'
                                label='Remember Me'
                                control={control}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormButtonContainer style={{marginTop: 0}}>
                                <Button
                                    variant="contained"
                                    onClick={handleSubmit(onSubmit)}
                                    size="large"
                                >
                                    Sign In
                                </Button>
                            </FormButtonContainer>
                        </Col>
                    </Row>
                </FormWrapper>

                <AccountLogoFooter />
            </AccountCard>
        </AccountContent>

        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLogging}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </AccountWrap>
    );
};

const ForgotPasswordWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 5px;
`;

export default LogIn;
