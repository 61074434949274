import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_LOCATIONS');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getLocationsRequest = createAction('LOCATIONS_GET_REQUEST');
export const getLocationsSuccess = createAction('LOCATIONS_GET_SUCCESS');
export const getLocationsError = createAction('LOCATIONS_GET_ERROR');

export const getLocations = (assetId) => async (dispatch) => {
  try {
    dispatch(getLocationsRequest());
    const resp = await instance.post(`/v1/Asset/GetBySourceByFilter/${assetId}`, { "assetTypeId": "63ea1fb41600303865474038" });
    
    dispatch(getLocationsSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getLocationsError(e.response.data.description));
    else
      dispatch(getLocationsError(e.message));
  }
};

export const getCountriesRequest = createAction('LOCATIONS_GET_COUNTRIES_REQUEST');
export const getCountriesSuccess = createAction('LOCATIONS_GET_COUNTRIES_SUCCESS');
export const getCountriesError = createAction('LOCATIONS_FORM_GET_COUNTRIES_ERROR');

export const getCountries = () => async (dispatch) => {
  try {
    dispatch(getCountriesRequest());
    const resp = await instance.get(`/v1/DictionaryCountry/GetAll`);
    
    dispatch(getCountriesSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getCountriesError(e.response.data.description));
    else
      dispatch(getCountriesError(e.message));
  }
};

export const deleteLocationRequest = createAction('LOCATIONS_DELETE_REQUEST');
export const deleteLocationSuccess = createAction('LOCATIONS_DELETE_SUCCESS');
export const deleteLocationError = createAction('LOCATIONS_DELETE_ERROR');

export const deleteLocation = (id) => async (dispatch) => {
  try {
    dispatch(deleteLocationRequest());
    const resp = await instance.delete(`/v1/Asset/DeleteLogic/${id}`);

    dispatch(deleteLocationSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(deleteLocationError(e.response.data.description));
    else
      dispatch(deleteLocationError(e.message));
  }
};