import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getLogoRequest,
  getLogoSuccess,
  getLogoError
} from './AUAssetLogoAction';

const defaultState = {
  logo: null, 
  isLoadingLogo: true, 
  getLogoError: null
};

export default handleActions(
  {
    [getLogoRequest](state) {
      return {
          ...state,
          isLoadingLogo: true,
          getLogoError: null,
      };
    },
    [getLogoSuccess](state, { payload }) {
      return {
          ...state,
          logo: payload && payload.size ? payload : null,
          isLoadingLogo: false,
          getLogoError: null,
      };
    },
    [getLogoError](state, { payload }) {
      return {
          ...state,
          isLoadingLogo: false,
          getLogoError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);