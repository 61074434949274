import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getPrioritiesRequest,
  getPrioritiesSuccess,
  getPrioritiesError,
  getWorkflowRolesRequest,
  getWorkflowRolesSuccess,
  getWorkflowRolesError,
  getRolesWithUsersRequest,
  getRolesWithUsersSuccess,
  getRolesWithUsersError
} from './generalInfoAction';

const defaultState = {
  roles: null,
  isLoadingRoles: true,
  getRolesError: null,
  priorities: null,
  isLoadingPriorities: false,
  getPrioritiesError: null,
  rolesWithUsers: null,
  isLoadingRolesWithUsers: true,
  getRolesWithUsersError: null,
};

export default handleActions(
  {
    [getPrioritiesRequest](state) {
      return {
        ...state,
        isLoadingPriorities: true,
        getPrioritiesError: null,
      };
    },
    [getPrioritiesSuccess](state, { payload }) {
      return {
        ...state,
        priorities: payload,
        isLoadingPriorities: false,
        getPrioritiesError: null,
      };
    },
    [getPrioritiesError](state, { payload }) {
      return {
        ...state,
        isLoadingPriorities: false,
        getPrioritiesError: payload,
      };
    },
    [getWorkflowRolesRequest](state) {
      return {
        ...state,
        isLoadingRoles: true,
        getRolesError: null,
      };
    },
    [getWorkflowRolesSuccess](state, { payload }) {
      return {
        ...state,
        roles: payload,
        isLoadingRoles: false,
        getRolesError: null,
      };
    },
    [getWorkflowRolesError](state, { payload }) {
      return {
        ...state,
        isLoadingRoles: false,
        getRolesError: payload,
      };
    },
    [getRolesWithUsersRequest](state) {
      return {
        ...state,
        isLoadingRolesWithUsers: true,
        getRolesWithUsersError: null,
      };
    },
    [getRolesWithUsersSuccess](state, { payload }) {
      return {
        ...state,
        rolesWithUsers: payload,
        isLoadingRolesWithUsers: false,
        getRolesWithUsersError: null,
      };
    },
    [getRolesWithUsersError](state, { payload }) {
      return {
        ...state,
        isLoadingRolesWithUsers: false,
        getRolesWithUsersError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);
