import { createAction } from 'redux-actions';
import { instance } from "../../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_STAKEHOLDER_USER_UPDATE');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getUserRequest = createAction('GET_USER_STAKEHOLDER_USER_UPDATE_REQUEST');
export const getUserSuccess = createAction('GET_USER_STAKEHOLDER_USER_UPDATE_SUCCESS');
export const getUserError = createAction('GET_USER_STAKEHOLDER_USER_UPDATE_ERROR');

export const getUser = (id) => async (dispatch) => {
  try {
    dispatch(getUserRequest());

    const resp = await instance.get(`/v1/UserAndRole/GetUser/${id}`);
    
    dispatch(getUserSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getUserError(e.response.data.description));
    else
      dispatch(getUserError(e.message));
  }
};

export const getRolesRequest = createAction('GET_ROLES_STAKEHOLDER_USER_UPDATE_REQUEST');
export const getRolesSuccess = createAction('GET_ROLES_STAKEHOLDER_USER_UPDATE_SUCCESS');
export const getRolesError = createAction('GET_ROLES_STAKEHOLDER_USER_UPDATE_ERROR');

export const getRoles = () => async (dispatch) => {
  try {
    dispatch(getRolesRequest());

    const resp = await instance.get(`/v1/UserAndRole/GetApplicationRoles`);
    
    dispatch(getRolesSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getRolesError(e.response.data.description));
    else
      dispatch(getRolesError(e.message));
  }
};

export const updateUserRequest = createAction('STAKEHOLDER_USER_UPDATE_REQUEST');
export const updateUserSuccess = createAction('STAKEHOLDER_USER_UPDATE_SUCCESS');
export const updateUserError = createAction('STAKEHOLDER_USER_UPDATE_ERROR');

export const updateUser = (data) => async (dispatch) => {
  try {
    dispatch(updateUserRequest());

    const resp = await instance.put(`/v1/StakeholderApplicationUser/Put`, data);
    
    dispatch(updateUserSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(updateUserError(e.response.data.description));
    else
      dispatch(updateUserError(e.message));
  }
};