import { Backdrop, Box, Button, CircularProgress } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import StakeholderForm from "./stakeholderForm";
import { complete, cleanup } from "@/reducers/selfAssessment/stakeholderInformationSurvey/stakeholderInformationSurveyAction";

const StakeholderInformationSurvey = ({
    activitySurvey,
    surveyInfo,
    activitySurveyId,
    isSupervisor,
    workflowActivityStepCompletedId,
    onComplete
}) => {
    const dispatch = useDispatch();

    const { 
        isCompleting, completed, completeError
    } = useSelector(({ stakeholderInformationSurvey }) => stakeholderInformationSurvey);

    const { handleSubmit, reset, setValue, watch, control, getValues } = useForm();

    useEffect(() => {
        return () => {
            dispatch(cleanup());
        }
    }, []);

    useEffect(() => {
        if(completeError){
          enqueueSnackbar(completeError, {variant: "error"});
        }
    }, [completeError]);

    useEffect(() => {
        if(completed){
            enqueueSnackbar("Survey saved successfully", {variant: "success"});
            onComplete(surveyInfo.surveyId);
        }
    }, [completed]);

    const onSubmit = (data) => {
        const finalData = {
            id: activitySurveyId,
            supplierInfo: data,
            workflowActivityStepCompletedId,
            surveyId: surveyInfo.surveyId
        };

        dispatch(complete(finalData));
    }

    return (
        <Box sx={{ width: '100%', paddingTop: '10px' }}>
            <StakeholderForm 
                stakeholderInfo={activitySurvey.supplierInfo}
                control={control}
                setValue={setValue}
                watch={watch}
            />

            <Box sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end',
                marginTop: '20px'
            }}>
                <Button variant="contained" size="large" onClick={handleSubmit(onSubmit)}>Save</Button>
            </Box>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isCompleting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
};

export default StakeholderInformationSurvey;