import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getLocationsRequest,
  getLocationsSuccess,
  getLocationsError,
  deleteLocationRequest,
  deleteLocationSuccess,
  deleteLocationError,
  getCountriesRequest,
  getCountriesSuccess,
  getCountriesError
} from './locationAction';

const defaultState = {
  locations: [],
  isLoadingLocations: true,
  getLocationsError: null,
  isDeletingLocation: false,
  deleteLocationsError: null,
  deletedLocation: false,
  countries: null,
  isLoadingCountries: true,
  getCountriesError: null
};

export default handleActions(
  {
    [getLocationsRequest](state) {
      return {
        ...state,
        locations: [],
        isLoadingLocations: true,
        getLocationsError: null,
      };
    },
    [getLocationsSuccess](state, { payload }) {
      return {
        ...state,
        locations: payload,
        isLoadingLocations: false,
        getLocationsError: null,
      };
    },
    [getLocationsError](state, { payload }) {
      return {
        ...state,
        locations: [],
        isLoadingLocations: false,
        getLocationsError: payload,
      };
    },
    [getCountriesRequest](state) {
      return {
        ...state,
        countries: null,
        isLoadingCountries: true,
        getCountriesError: null,
      };
    },
    [getCountriesSuccess](state, { payload }) {
      return {
        ...state,
        countries: payload,
        isLoadingCountries: false,
        getCountriesError: null,
      };
    },
    [getCountriesError](state, { payload }) {
      return {
        ...state,
        countries: null,
        isLoadingCountries: false,
        getCountriesError: payload,
      };
    },
    [deleteLocationRequest](state) {
      return {
        ...state,
        isDeletingLocation: true,
        deletedLocation: false,
        deleteLocationsError: null,
      };
    },
    [deleteLocationSuccess](state, { payload }) {
      return {
        ...state,
        isDeletingLocation: false,
        deletedLocation: true,
        deleteLocationsError: null,
      };
    },
    [deleteLocationError](state, { payload }) {
      return {
        ...state,
        isDeletingLocation: false,
        deletedLocation: false,
        deleteLocationsError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);