import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { getAssets, getRolesWithUsers, getWorkflowRoles, getAuditCampaign, getSurveys, cleanup } from "@/reducers/auditCampaign/assignSurveys/AUAssignSurveysAction";
import { enqueueSnackbar } from "notistack";
import { Col, Row } from "react-bootstrap";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import { Backdrop, Button, CircularProgress, Typography } from "@mui/material";
import { useState } from "react";
import AssetCard from "./components/assetCard";
import AssetUpdateModal from "./components/assetUpdateModal";

const AUCampaignAssignSurvey = () => {
    const { 
        activity, 
        workflowActivityStepCompletedId,
        isEditor,
        handleRefreshProgress
    } = useOutletContext();

    const dispatch = useDispatch();

    const { 
        auditCampaign, isLoadingAuditCampaign, getAuditCampaignError,
        surveys, isLoadingSurveys, getSurveysError,
        roles, isLoadingRoles, getRolesError,
        rolesWithUsers, isLoadingRolesWithUsers, getRolesWithUsersError,
        assets, isLoadingAssets, getAssetsError,
    } = useSelector(({ auAssignSurveys }) => auAssignSurveys);

    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState(null);

    useEffect(() => {
        dispatch(getAuditCampaign(activity.workflowActivityId));
        dispatch(getWorkflowRoles(5));
        dispatch(getAssets());

        return () => {
            dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
        if(auditCampaign){
            dispatch(getSurveys(auditCampaign.surveyIds));
        }
    }, [auditCampaign]);

    useEffect(() => {
        if(getAssetsError){
          enqueueSnackbar(getAssetsError, {variant: "error"});
        }
    }, [getAssetsError]);

    useEffect(() => {
        if(getAuditCampaignError){
          enqueueSnackbar(getAuditCampaignError, {variant: "error"});
        }
    }, [getAuditCampaignError]);

    useEffect(() => {
        if(getSurveysError){
          enqueueSnackbar(getSurveysError, {variant: "error"});
        }
    }, [getSurveysError]);

    
    useEffect(() => {
        if(getRolesError){
        enqueueSnackbar(getRolesError, {variant: "error"});
        }
    }, [getRolesError]);

    useEffect(() => {
        if(getRolesWithUsersError){
        enqueueSnackbar(getRolesWithUsersError, {variant: "error"});
        }
    }, [getRolesWithUsersError]);

    useEffect(() => {
        if(roles && roles.length){
        dispatch(getRolesWithUsers(roles));
        }    
    }, [roles]);

    const onClickAsset = (asset) => {
        setSelectedAsset(asset);
        setShowUpdateModal(true);
    };

    const onHideUpdateModal = () => {
        setShowUpdateModal(false);
        setSelectedAsset(null);
    };

    const onAssetUpdated = () => {
        handleRefreshProgress();
        setShowUpdateModal(false);
        setSelectedAsset(null);
        dispatch(getAuditCampaign(activity.workflowActivityId));
    }

    return(
        <Row>
            {isLoadingAssets || isLoadingAuditCampaign || isLoadingSurveys || isLoadingRoles || isLoadingRolesWithUsers ? <CircularProgressWrapper /> :  
             !auditCampaign.campaignAssets || !auditCampaign.campaignAssets.length ? <div style={{height: '200px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Typography>There are no assets to show.</Typography></div> :
            
             <>
                {auditCampaign.campaignAssets.map((a, index) => {
                    return(
                        <Col md={4} style={{marginBottom: 30}}>
                            <AssetCard 
                                activityType={activity.activityType}
                                asset={a}
                                onClick={() => onClickAsset(a)}
                                surveys={surveys}
                                supplier={assets.find(s => s.assetId === a.assetId)}
                            />
                        </Col>
                    )
                })}

                { showUpdateModal &&
                    <AssetUpdateModal 
                    isEditor={isEditor}
                    auditCampaign={auditCampaign}
                    onHideModal={onHideUpdateModal} 
                    showModal={showUpdateModal}
                    asset={selectedAsset}
                    onAssetUpdated={onAssetUpdated}
                    surveys={surveys}
                    workflowActivityStepCompletedId={workflowActivityStepCompletedId}
                    roles={roles}
                    rolesWithUsers={rolesWithUsers}
                    supplier={assets.find(s => s.assetId === selectedAsset.assetId)}
                    activityType={activity.activityType}
                    />
                }
            </>
            }
        </Row>
    );
};

export default AUCampaignAssignSurvey;