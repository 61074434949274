import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_ADVANCED_FILTERS');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getSchemaRequest = createAction('GET_SCHEMA_REQUEST');
export const getSchemaSuccess = createAction('GET_SCHEMA_SUCCESS');
export const getSchemaError = createAction('GET_SCHEMA_ERROR');

export const getSchema = () => async (dispatch) => {
  try {
    dispatch(getSchemaRequest());
    const resp = await instance.get(`/v1/Asset/GetSchema`);

    dispatch(getSchemaSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getSchemaError(e.response.data.description));
    else
      dispatch(getSchemaError(e.message));
  }
};

export const getAssetsRequest = createAction('GET_ASSETS_REQUEST');
export const getAssetsSuccess = createAction('GET_ASSETS_SUCCESS');
export const getAssetsError = createAction('GET_ASSETS_ERROR');

export const getAssets = (data) => async (dispatch) => {
  try {
    dispatch(getAssetsRequest());
    const resp = await instance.post(`/v1/Asset/AdvancedSearch`, data);

    var mappedData = resp.data.map((d) => { return {label: d.name, value: d.id} });
    dispatch(getAssetsSuccess(mappedData));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getAssetsError(e.response.data.description));
    else
      dispatch(getAssetsError(e.message));
  }
};
