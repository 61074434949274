import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getLibrariesRequest,
  getLibrariesSuccess,
  getLibrariesError,
  downloadRequest,
  downloadSuccess,
  downloadError
} from './librariesAction';

const defaultState = {
  libraries: [],
  isLoadingLibraries: true,
  getLibrariesError: null,
  isDownloading: false, 
  downloaded: false, 
  downloadError: null
};

export default handleActions(
  {
    [getLibrariesRequest](state) {
      return {
        ...state,
        libraries: [],
        isLoadingLibraries: true,
        getLibrariesError: null,
      };
    },
    [getLibrariesSuccess](state, { payload }) {
      return {
        ...state,
        libraries: payload,
        isLoadingLibraries: false,
        getLibrariesError: null,
      };
    },
    [getLibrariesError](state, { payload }) {
      return {
        ...state,
        libraries: [],
        isLoadingLibraries: false,
        getLibrariesError: payload,
      };
    },
    [downloadRequest](state) {
      return {
        ...state,
        isDownloading: true,
        downloaded: false,
        downloadError: null,
      };
    },
    [downloadSuccess](state, { payload }) {

      const clon = JSON.parse(JSON.stringify(state.libraries));
      
      payload.forEach(id => {
        clon.find(c => c.libraryId === id).isDownloaded = true;
      });

      return {
        ...state,
        isDownloading: false,
        downloaded: true,
        downloadError: null,
        libraries: clon
      };
    },
    [downloadError](state, { payload }) {
      return {
        ...state,
        isDownloading: false,
        downloaded: false,
        downloadError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);