import { createAction } from 'redux-actions';
import { instance } from "../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_VULNERABILITY_STATUSES');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getVulnerabilityStatusesRequest = createAction('VULNERABILITY_STATUSES_GET_ALL_REQUEST');
export const getVulnerabilityStatusesSuccess = createAction('VULNERABILITY_STATUSES_GET_ALL_SUCCESS');
export const getVulnerabilityStatusesError = createAction('VULNERABILITY_STATUSES_GET_ALL_ERROR');

export const getVulnerabilityStatuses = () => async (dispatch) => {
  try {
    dispatch(getVulnerabilityStatusesRequest());
    const resp = await instance.get(`/v1/VulnerabilityStatus/GetAll`);
    
    dispatch(getVulnerabilityStatusesSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getVulnerabilityStatusesError(e.response.data.description));
    else
      dispatch(getVulnerabilityStatusesError(e.message));
  }
};

export const deleteVulnerabilityStatusRequest = createAction('VULNERABILITY_STATUSES_DELETE_REQUEST');
export const deleteVulnerabilityStatusSuccess = createAction('VULNERABILITY_STATUSES_DELETE_SUCCESS');
export const deleteVulnerabilityStatusError = createAction('VULNERABILITY_STATUSES_DELETE_ERROR');

export const deleteVulnerabilityStatus = (id) => async (dispatch) => {
  try {
    dispatch(deleteVulnerabilityStatusRequest());
    const resp = await instance.delete(`/v1/VulnerabilityStatus/DeleteLogic/${id}`);

    dispatch(deleteVulnerabilityStatusSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(deleteVulnerabilityStatusError(e.response.data.description));
    else
      dispatch(deleteVulnerabilityStatusError(e.message));
  }
};