import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_AU_SELECT_ASSETS');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getAuditCampaignRequest = createAction('AU_SELECT_ASSETS_GET_CAMPAIGN_REQUEST');
export const getAuditCampaignSuccess = createAction('AU_SELECT_ASSETS_GET_CAMPAIGN_SUCCESS');
export const getAuditCampaignError = createAction('AU_SELECT_ASSETS_GET_CAMPAIGN_ERROR');

export const getAuditCampaign = (id) => async (dispatch) => {
  try {
    dispatch(getAuditCampaignRequest());
    const resp = await instance.get(`/v1/ActivityCampaign/GetByActivityId/${id}`);
    
    dispatch(getAuditCampaignSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getAuditCampaignError(e.response.data.description));
    else
      dispatch(getAuditCampaignError(e.message));
  }
};

export const getAssetsRequest = createAction('AU_SELECT_ASSETS_GET_LIST_REQUEST');
export const getAssetsSuccess = createAction('AU_SELECT_ASSETS_GET_LIST_SUCCESS');
export const getAssetsError = createAction('AU_SELECT_ASSETS_GET_LIST_ERROR');

export const getAssets = () => async (dispatch) => {
  try {
    dispatch(getAssetsRequest());
    const resp = await instance.get(`/v1/Stakeholder/GetTenantStakeholders`);
    
    dispatch(getAssetsSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getAssetsError(e.response.data.description));
    else
      dispatch(getAssetsError(e.message));
  }
};

export const confirmAssetsRequest = createAction('AU_SELECT_ASSETS_CONFIRM_REQUEST');
export const confirmAssetsSuccess = createAction('AU_SELECT_ASSETS_CONFIRM_SUCCESS');
export const confirmAssetsError = createAction('AU_SELECT_ASSETS_CONFIRM_ERROR');

export const confirmAssets = (data) => async (dispatch) => {
  try {
    dispatch(confirmAssetsRequest());
    const resp = await instance.post(`/v1/ActivityCampaign/UpdateAssetIds`, data);
    
    dispatch(confirmAssetsSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(confirmAssetsError(e.response.data.description));
    else
      dispatch(confirmAssetsError(e.message));
  }
};