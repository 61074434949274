import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_AU_SELECT_LIBRARIES');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getAuditCampaignRequest = createAction('AU_SELECT_LIBRARIES_GET_CAMPAIGN_REQUEST');
export const getAuditCampaignSuccess = createAction('AU_SELECT_LIBRARIES_GET_CAMPAIGN_SUCCESS');
export const getAuditCampaignError = createAction('AU_SELECT_LIBRARIES_GET_CAMPAIGN_ERROR');

export const getAuditCampaign = (id) => async (dispatch) => {
  try {
    dispatch(getAuditCampaignRequest());
    const resp = await instance.get(`/v1/ActivityCampaign/GetByActivityId/${id}`);
    
    dispatch(getAuditCampaignSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getAuditCampaignError(e.response.data.description));
    else
      dispatch(getAuditCampaignError(e.message));
  }
};

export const getLibrariesRequest = createAction('AU_SELECT_LIBRARIES_GET_LIST_REQUEST');
export const getLibrariesSuccess = createAction('AU_SELECT_LIBRARIES_GET_LIST_SUCCESS');
export const getLibrariesError = createAction('AU_SELECT_LIBRARIES_GET_LIST_ERROR');

export const getLibraries = (applicationModuleId) => async (dispatch) => {
  try {
    dispatch(getLibrariesRequest());
    const resp = await instance.get(`/v1/Library/GetListByModuleId`, {
      params: {
        applicationModuleId
      }
    });
    
    dispatch(getLibrariesSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getLibrariesError(e.response.data.description));
    else
      dispatch(getLibrariesError(e.message));
  }
};

export const confirmLibrariesRequest = createAction('AU_SELECT_LIBRARIES_CONFIRM_REQUEST');
export const confirmLibrariesSuccess = createAction('AU_SELECT_LIBRARIES_CONFIRM_SUCCESS');
export const confirmLibrariesError = createAction('AU_SELECT_LIBRARIES_CONFIRM_ERROR');

export const confirmLibraries = (data) => async (dispatch) => {
  try {
    dispatch(confirmLibrariesRequest());
    const resp = await instance.post(`/v1/ActivityCampaign/UpdateLibraryIds`, data);
    
    dispatch(confirmLibrariesSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(confirmLibrariesError(e.response.data.description));
    else
      dispatch(confirmLibrariesError(e.message));
  }
};