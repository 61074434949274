import { createAction } from 'redux-actions';
import { instance } from "../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_VULNERABILITY_SCORES');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getVulnerabilityScoresRequest = createAction('VULNERABILITY_SCORES_GET_ALL_REQUEST');
export const getVulnerabilityScoresSuccess = createAction('VULNERABILITY_SCORES_GET_ALL_SUCCESS');
export const getVulnerabilityScoresError = createAction('VULNERABILITY_SCORES_GET_ALL_ERROR');

export const getVulnerabilityScores = () => async (dispatch) => {
  try {
    dispatch(getVulnerabilityScoresRequest());
    const resp = await instance.get(`/v1/RatingScore/GetAll`);
    
    dispatch(getVulnerabilityScoresSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getVulnerabilityScoresError(e.response.data.description));
    else
      dispatch(getVulnerabilityScoresError(e.message));
  }
};

export const deleteVulnerabilityScoreRequest = createAction('VULNERABILITY_SCORES_DELETE_REQUEST');
export const deleteVulnerabilityScoreSuccess = createAction('VULNERABILITY_SCORES_DELETE_SUCCESS');
export const deleteVulnerabilityScoreError = createAction('VULNERABILITY_SCORES_DELETE_ERROR');

export const deleteVulnerabilityScore = (id) => async (dispatch) => {
  try {
    dispatch(deleteVulnerabilityScoreRequest());
    const resp = await instance.delete(`/v1/RatingScore/DeleteLogic/${id}`);

    dispatch(deleteVulnerabilityScoreSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(deleteVulnerabilityScoreError(e.response.data.description));
    else
      dispatch(deleteVulnerabilityScoreError(e.message));
  }
};