import { createAction } from 'redux-actions';
import { instance } from "../../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_USER_INFO');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}


export const getUserOptionsRequest = createAction('GET_USER_OPTIONS_REQUEST');
export const getUserOptionsSuccess = createAction('GET_USER_OPTIONS_SUCCESS');
export const getUserOptionsError = createAction('GET_USER_OPTIONS_ERROR');

export const getUserOptions = () => async (dispatch) => {
  try {
    dispatch(getUserOptionsRequest());
    const resp = await instance.get(`/v1/Account/GetUserOption`);
    
    dispatch(getUserOptionsSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getUserOptionsError(e.response.data.description));
    else
      dispatch(getUserOptionsError(e.message));
  }
};


export const updateUserOptionsRequest = createAction('USER_OPTIONS_UPDATE_REQUEST');
export const updateUserOptionsSuccess = createAction('USER_OPTIONS_UPDATE_SUCCESS');
export const updateUserOptionsError = createAction('USER_OPTIONS_UPDATE_ERROR');

export const updateUserOptions = (data) => async (dispatch) => {
    try {
      dispatch(updateUserOptionsRequest());
      const resp = await instance.put(`/v1/Account/UpdateUserOption`, data);
      
      dispatch(updateUserOptionsSuccess(resp.data));
  
    } catch (e) {
      if(e.response && e.response.data && e.response.data.description)
        dispatch(updateUserOptionsError(e.response.data.description));
      else
        dispatch(updateUserOptionsError(e.message));
    }
  };

