import { Box } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export const ColoredSelectCell = ({ name, label, value, options, onChange, ...other }) => {
    return (
        <Autocomplete
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                '& > .MuiFormControl-root': { display: 'flex', flexDirection: 'row' }
            }}
            disableClearable={true}
            disablePortal
            options={options}
            value={value}
            onChange={onChange}
            getOptionLabel={(option) => option.nameTraslated}
            renderInput={(params) => 
                <TextField 
                    {...params} 
                    size="small"
                    fullWidth
                    label={label}
                    variant="outlined"
                    {...other}
                />
            }
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > div': { mr: 2, flexShrink: 0 } }} {...props}>
                  <div style={{width: 20, height: 20, backgroundColor: option.color, borderRadius: '100%'}}></div>
                  {option.nameTraslated}
                </Box>
              )}
        />
    )
};