import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

const AuditLogInfoModal = ({ auditLog, onHideModal, showModal }) => {

    console.log(auditLog);

    const handleClose = (event, reason) => {
      onHideModal();
    }
  
    return (
      <Dialog
          fullWidth={true}
          maxWidth='lg'
          scroll='paper'
          open={showModal}
          onClose={handleClose}
      >
          <DialogTitle>Audit Log Info</DialogTitle>
          <DialogContent style={{height: 500}}>
            <pre style={{ "contain": "inline-size", "overflow-x": "scroll"}}>
              {JSON.stringify(auditLog.data, null, 2)}
            </pre>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Close</Button>
          </DialogActions>
      </Dialog>
    );
  }
  
  export default AuditLogInfoModal;