import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_LIST_CREATE');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getActivitiesRequest = createAction('LIST_CREATE_GET_ACTIVITIES_REQUEST');
export const getActivitiesSuccess = createAction('LIST_CREATE_GET_ACTIVITIES_SUCCESS');
export const getActivitiesError = createAction('LIST_CREATE_GET_ACTIVITIES_ERROR');

export const getActivities = () => async (dispatch) => {
  try {
    dispatch(getActivitiesRequest());
    const resp = await instance.get(`/v1/ActivityType/GetListPagedByFilter`);
    
    const filteredActivities = resp.data.data.filter(a => a.isVisible);
    dispatch(getActivitiesSuccess(filteredActivities));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getActivitiesError(e.response.data.description));
    else
      dispatch(getActivitiesError(e.message));
  }
};