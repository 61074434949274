import { Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridToolbar, gridClasses } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { cleanup, getRemediations } from "@/reducers/remediation/view/viewAction";
import ProgressCell from "@/shared/components/datagrid/ProgressCell";
import { Col, Container, Row } from "react-bootstrap";
import ExpandableCell from '@/shared/components/datagrid/ExpandableCell';
import DeleteIcon from '@mui/icons-material/Delete';
import RuleIcon from '@mui/icons-material/Rule';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

const RemediationPlans = () => {

    const dispatch = useDispatch();
    const { 
        remediations, isLoadingRemediations, getRemediationsError
    } = useSelector(({ remediationView }) => remediationView);

    const [selectedRows, setSelectedRows] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getRemediations());
    
        return () => {
          dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
        if(getRemediationsError){
            enqueueSnackbar(getRemediationsError, {variant: "error"});
        }
    }, [getRemediationsError]);

    const mapRemediations = (remediations) => {
        var data = JSON.parse(JSON.stringify(remediations));
        data.forEach(d => d.users = d.userRoles ? Object.values(d.usersRoles).join(', ') : "");
        return data;
    }

    const handleClickCheckList = (id) => {
        navigate(`/RemediationPlans/CheckList/${id}`);
    }

    const handleClickEditRemediation = (id) => {
        console.log(id);
    }

    const handleClickDeleteRemediation = (id) => {
        console.log(id);
    }     

    const columns = [
        { 
            field: 'description', 
            headerName: 'Description',
            minWidth: 350,
            flex: 1,
        },
        { 
            field: 'progress', 
            headerName: 'Progress', 
            minWidth: 150,
            renderCell: (params) => <ProgressCell value={params.value * 100} />
        },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => [
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => handleClickDeleteRemediation(params.id)}
              />,
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                showInMenu
                onClick={() => handleClickEditRemediation(params.id)}
              />,
              <GridActionsCellItem
                icon={<RuleIcon />}
                label="Check List"
                showInMenu
                onClick={() => handleClickCheckList(params.id)}
              />,
            ],
          },
      ];

    return(
        <Container>
            <header style={{'marginBottom': 20}}>
                <h1>Remediations</h1>
                <Typography>Here you can filter and search remediations.</Typography>
            </header>
            <div style={{'height': 800, 'paddingBottom': 20, width: '100%'}}>
                <DataGrid 
                autoHeight
                density="compact"
                checkboxSelection 
                disableRowSelectionOnClick
                getRowHeight={() => 'auto'}
                getEstimatedRowHeight={() => 59}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setSelectedRows(newRowSelectionModel);
                }}
                rowSelectionModel={selectedRows}
                loading={isLoadingRemediations} 
                rows={mapRemediations(remediations)}
                columns={columns} 
                slots={{ toolbar: GridToolbar }} 
                slotProps={{
                    toolbar: {
                      showQuickFilter: true
                    },
                }}
                sx={{
                    [`& .${gridClasses.cell}`]: {
                      py: 1,
                    },
                }}
                initialState={{
                    pagination: { paginationModel: { pageSize: 30 } },
                }}
                pageSizeOptions={[15, 30, 50, 100]}
                />
            </div>
    </Container>
    )
}

export default RemediationPlans;