import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_SERVICE_CONNECTORS');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getAssemblyCollectionListRequest = createAction('SERVICE_CONNECTORS_GET_ASSEMBLY_COLLECTION_REQUEST');
export const getAssemblyCollectionListSuccess = createAction('SERVICE_CONNECTORS_GET_ASSEMBLY_COLLECTION_SUCCESS');
export const getAssemblyCollectionListError = createAction('SERVICE_CONNECTORS_GET_ASSEMBLY_COLLECTION_ERROR');

export const getAssemblyCollectionList = (connectorId) => async (dispatch) => {
  try {
    dispatch(getAssemblyCollectionListRequest({connectorId}));
    const resp = await instance.get(`/v1/Connector/GetAssemblyCollection?connectorId=${connectorId}`);

    dispatch(getAssemblyCollectionListSuccess({connectorId, data: resp.data}));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getAssemblyCollectionListError({connectorId, data: e.response.data.description}));
    else
      dispatch(getAssemblyCollectionListError({connectorId, data: e.message}));
  }
};