import { Col, Row } from "react-bootstrap";
import UnitSelect from "./unitSelect";
import { FormTextField } from '@/shared/components/form/material-controls/TextField';


const EnergyTab = ({
    control,
    parameterUnits,
    emissionCo2
}) => {
    return(
        <>
        <Row>
            <Col md={12}>
                <Row>
                    <Col md={6}>
                        <FormTextField
                            name="electricityValue"
                            label="Electricity"
                            type="number"
                            control={control}
                            rules={{ required: 'This field is required' }} 
                            defaultValue={emissionCo2?.emissionData?.energy?.electricityValue ?? 0}
                        />
                    </Col>
                    <Col md={6}>
                        <UnitSelect 
                            name="electricityUnitId"
                            label="Unit"
                            control={control}
                            rules={{ required: 'This field is required' }} 
                            unitCode="energy_unit"
                            parameterUnits={parameterUnits}
                            defaultValue={emissionCo2?.emissionData?.energy?.electricityUnitId}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormTextField
                            name="naturalGasValue"
                            label="Natural Gas"
                            type="number"
                            control={control}
                            rules={{ required: 'This field is required' }} 
                            defaultValue={emissionCo2?.emissionData?.energy?.naturalGasValue ?? 0}
                        />
                    </Col>
                    <Col md={6}>
                        <UnitSelect 
                            name="naturalGasUnitId"
                            label="Unit"
                            control={control}
                            rules={{ required: 'This field is required' }} 
                            unitCode="volume_unit"
                            parameterUnits={parameterUnits}
                            defaultValue={emissionCo2?.emissionData?.energy?.naturalGasUnitId}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormTextField
                            name="propaneValue"
                            label="Propane"
                            type="number"
                            control={control}
                            rules={{ required: 'This field is required' }}
                            defaultValue={emissionCo2?.emissionData?.energy?.propaneValue ?? 0}
                        />
                    </Col>
                    <Col md={6}>
                        <UnitSelect 
                            name="propaneUnitId"
                            label="Unit"
                            control={control}
                            rules={{ required: 'This field is required' }} 
                            unitCode="volume_unit"
                            parameterUnits={parameterUnits}
                            defaultValue={emissionCo2?.emissionData?.energy?.propaneUnitId}
                        />
                    </Col>
                </Row>
            </Col>
            <Col md={12}>
                <Row>
                    <Col md={6}>
                        <FormTextField
                            name="lpgValue"
                            label="LPG"
                            type="number"
                            control={control}
                            rules={{ required: 'This field is required' }} 
                            defaultValue={emissionCo2?.emissionData?.energy?.lpgValue ?? 0}
                        />
                    </Col>
                    <Col md={6}>
                        <UnitSelect 
                            name="lpgUnitId"
                            label="Unit"
                            control={control}
                            rules={{ required: 'This field is required' }} 
                            unitCode="volume_unit"
                            parameterUnits={parameterUnits}
                            defaultValue={emissionCo2?.emissionData?.energy?.lpgUnitId}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormTextField
                            name="coalValue"
                            label="Coal"
                            type="number"
                            control={control}
                            rules={{ required: 'This field is required' }} 
                            defaultValue={emissionCo2?.emissionData?.energy?.coalValue ?? 0}
                        />
                    </Col>
                    <Col md={6}>
                        <UnitSelect 
                            name="coalUnitId"
                            label="Unit"
                            control={control}
                            rules={{ required: 'This field is required' }} 
                            unitCode="weight_unit"
                            parameterUnits={parameterUnits}
                            defaultValue={emissionCo2?.emissionData?.energy?.coalUnitId}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormTextField
                            name="woodenPelletValue"
                            label="Wooden Pellet"
                            type="number"
                            control={control}
                            rules={{ required: 'This field is required' }} 
                            defaultValue={emissionCo2?.emissionData?.energy?.woodenPelletValue ?? 0}
                        />
                    </Col>
                    <Col md={6}>
                        <UnitSelect 
                            name="woodenPelletUnitId"
                            label="Unit"
                            control={control}
                            rules={{ required: 'This field is required' }} 
                            unitCode="weight_unit"
                            parameterUnits={parameterUnits}
                            defaultValue={emissionCo2?.emissionData?.energy?.woodenPelletUnitId}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
            
            
        </>
    );
}

export default EnergyTab;