import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getCountriesRequest,
  getCountriesSuccess,
  getCountriesError
} from './stakeholderFormAction';

const defaultState = {
  countries: null,
  isLoadingCountries: true,
  getCountrieError: null
};

export default handleActions(
  {
    [getCountriesRequest](state) {
      return {
        ...state,
        countries: null,
        isLoadingCountries: true,
        getCountrieError: null,
      };
    },
    [getCountriesSuccess](state, { payload }) {
      return {
        ...state,
        countries: payload,
        isLoadingCountries: false,
        getCountrieError: null,
      };
    },
    [getCountriesError](state, { payload }) {
      return {
        ...state,
        countries: null,
        isLoadingCountries: false,
        getCountrieError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);