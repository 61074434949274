import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getUserOptionsRequest,
  getUserOptionsSuccess,
  getUserOptionsError,
  updateUserOptionsRequest,
  updateUserOptionsSuccess,
  updateUserOptionsError,
} from './userOptionAction';

const defaultState = {
  
  userOption: null,
  isLoadingUserOptions: false,
  getUserOptionsError: null,
  isUpdatingUserOptions: false,
  updateUserOptionsError: null,
  updatedOptionsUser: false
};

export default handleActions(
  {
    [getUserOptionsRequest](state) {
      return {
        ...state,
        isLoadingUserOptions: true,
        getUserOptionsError: null,
      };
    },
    [getUserOptionsSuccess](state, { payload }) {
      return {
        ...state,
        userOption: payload,
        isLoadingUserOptions: false,
        getUserOptionsError: null,
      };
    },
    [getUserOptionsError](state, { payload }) {
      return {
        ...state,
        isLoadingUserOptions: false,
        getUserOptionsError: payload,
      };
    },
    [updateUserOptionsRequest](state) {
        return {
            ...state,
            isUpdatingUserOptions: true,
            updateUserOptionsError: null,
            updatedUserOptions: false
        };
    },
    [updateUserOptionsSuccess](state, { payload }) {
        return {
            ...state,
            isUpdatingUserOptions: false,
            updateUserOptionsError: null,
            updatedUserOptions: true
        };
    },
    [updateUserOptionsError](state, { payload }) {
        return {
            ...state,
            isUpdatingUserOptions: false,
            updateUserOptionsError: payload,
            updatedUserOptions: false
        };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);