import { createAction } from 'redux-actions';
import { instance } from "../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_SERVICES');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getServicesRequest = createAction('SERVICES_GET_ALL_REQUEST');
export const getServicesSuccess = createAction('SERVICES_GET_ALL_SUCCESS');
export const getServicesError = createAction('SERVICES_GET_ALL_ERROR');

export const getServices = () => async (dispatch) => {
  try {
    dispatch(getServicesRequest());
    const resp = await instance.get(`/v1/ApplicationModule/GetListPagedByFilter`);
    
    dispatch(getServicesSuccess(resp.data.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getServicesError(e.response.data.description));
    else
      dispatch(getServicesError(e.message));
  }
};

export const deleteServiceRequest = createAction('SERVICES_DELETE_REQUEST');
export const deleteServiceSuccess = createAction('SERVICES_DELETE_SUCCESS');
export const deleteServiceError = createAction('SERVICES_DELETE_ERROR');

export const deleteService = (id) => async (dispatch) => {
  try {
    dispatch(deleteServiceRequest());
    const resp = await instance.delete(`/v1/Service/DeleteLogic/${id}`);

    dispatch(deleteServiceSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(deleteServiceError(e.response.data.description));
    else
      dispatch(deleteServiceError(e.message));
  }
};