import { Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Step, StepButton, StepContent, Backdrop, Stepper, Typography, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { getActivityType, getWorkflowSteps, getWorkflowCreateTask, createActivity, cleanup } from "@/reducers/activity/create/activityCreateAction";
import { enqueueSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import NotesIcon from '@mui/icons-material/Notes';
import { CardPage, CardPageBody, CardPageHeader, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap, CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import { useTranslation } from "react-i18next";

const CreateActivity = () => {
    const { id } = useParams();
    const {t} = useTranslation("common");

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { handleSubmit, reset, control, setValue } = useForm();

    const { 
        activityType, isLoadingActivityType, getActivityTypeError,
        steps, isLoadingSteps, getStepsError,
        createTask, isLoadingCreateTask, getCreateTaskError,
        isAddingActivity, isAddedActivity, addActivityError
     } = useSelector(({ activityCreate }) => activityCreate);
    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {
        dispatch(getActivityType(id));
        dispatch(getWorkflowSteps(id));
        dispatch(getWorkflowCreateTask(id));

        return () => {
            dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
        if(getActivityTypeError){
          enqueueSnackbar(getActivityTypeError, {variant: "error"});
        }
    }, [getActivityTypeError]);

    useEffect(() => {
        if(getCreateTaskError){
          enqueueSnackbar(getCreateTaskError, {variant: "error"});
        }
    }, [getCreateTaskError]);

    useEffect(() => {
        if(addActivityError){
          enqueueSnackbar(addActivityError, {variant: "error"});
        }
    }, [addActivityError]);

    useEffect(() => {
        if(getStepsError){
          enqueueSnackbar(getStepsError, {variant: "error"});
        }
    }, [getStepsError]);

    useEffect(() => {
        if(activityType && steps){
            navigate(steps[currentStep].componentPath);
        }
    }, [activityType, currentStep, steps, navigate]);

    const handleClickStep = (step) => {
        setCurrentStep(step);
        reset();
    }

    const handleClickCreate = (data) => {
        data.activityTypeId = id;
        dispatch(createActivity(data));
    }

    useEffect(() => {
        if(isAddedActivity){
          enqueueSnackbar(`${activityType.label} was created successfuly.`, {variant: "success"});
          navigate("/Activities");
        }
    }, [isAddedActivity]);

    return (
        <Container style={{paddingBottom: 20}}>
            <CardPage>
                <CardPageBody>         
                    <CardPageHeader>
                        <CardPageTitleWrap>
                            <CardPageTitle>Create Activity</CardPageTitle>
                        </CardPageTitleWrap>
                    </CardPageHeader>

                    <Row>
                        <Col md={12}>
                            <Card variant="outline">
                            {isLoadingActivityType || !activityType || isLoadingCreateTask || isLoadingSteps || !steps || steps.length === 0 ? <div style={{height: '400px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><CircularProgress /></div> : 
                                <>
                                    <CardHeader
                                        title={activityType.label}
                                    />
                                    <CardContent>
                                        <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                                            <div style={{width: '80%'}}>
                                                <Stepper nonLinear activeStep={currentStep} style={{marginBottom: 30, justifyContent: (steps.length === 1 ? 'center' : 'flex-start') }}>
                                                    {steps.map((step, index) => (
                                                        <Step key={index} completed={step.comlpeted}>
                                                            <StepButton color="inherit" onClick={() => handleClickStep(index)}>
                                                                {step.label}
                                                            </StepButton>
                                                        </Step>
                                                    ))}
                                                </Stepper>
                                            </div>
                                        </div>
                                        
                                        <Typography style={{marginBottom: 20}}><NotesIcon fontSize="small"/> {steps[currentStep].description}</Typography>
                                        
                                        <Outlet context={{
                                            activityTypeId: id, 
                                            control: control,
                                            handleClickCreate: handleSubmit(handleClickCreate),
                                            createLabel: createTask?.taskJob.label,
                                            setValue: setValue
                                        }}/>

                                    </CardContent>
                                    { steps.length > 1 &&
                                        <>
                                            <Divider component='div'/>
                                            
                                            <CardActions style={{padding: 16}}>
                                                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                                    <Button 
                                                        variant="outlined"
                                                        disabled={currentStep === 0} 
                                                        onClick={() => setCurrentStep(currentStep - 1)}
                                                    >
                                                        {t("app.activity.steps.back")}
                                                    </Button>

                                                    <Button 
                                                        variant="outlined"
                                                        disabled={currentStep === steps.length - 1} 
                                                        onClick={() => setCurrentStep(currentStep + 1)}
                                                    >
                                                        {t("app.activity.steps.next")}
                                                    </Button>
                                                </div>
                                            </CardActions>
                                        </>
                                    }
                                </>
                            }
                            </Card>
                        </Col>
                    </Row>

                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isAddingActivity}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </CardPageBody>
            </CardPage>
        </Container>
    )
}

export default CreateActivity;