import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_ASSETTYPE_CREATE');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const createAssetTypesRequest = createAction('CREATE_ASSETTYPES_REQUEST');
export const createAssetTypesSuccess = createAction('CREATE_ASSETTYPES_SUCCESS');
export const createAssetTypesError = createAction('CREATE_ASSETTYPES_ERROR');

export const createAssetTypes = (data) => async (dispatch) => {
  try {
    dispatch(createAssetTypesRequest());
    const resp = await instance.post("/v1/AssetType/Post", data);
    if(resp.data){
      dispatch(createAssetTypesSuccess(resp.data));
    }
    else{
      dispatch(createAssetTypesError(resp));
    }
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(createAssetTypesError(e.response.data.description));
    else
      dispatch(createAssetTypesError(e.message));
  }
};
