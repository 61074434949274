import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { getAuditCampaign, getSurveys, confirmSurveys, cleanup } from "@/reducers/auditCampaign/selectSurveys/AUSelectSurveysAction";
import { enqueueSnackbar } from "notistack";
import { Col, Row } from "react-bootstrap";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import { Backdrop, Button, CircularProgress, Typography } from "@mui/material";
import SurveySelectCard from "../SASteps/components/surveySelectCard";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const AUCampaignSelectSurvey = () => {
    
    const { 
        activity, 
        workflowActivityStepCompletedId, 
        isEditor,
        handleRefreshProgress,
        handleClickNext
    } = useOutletContext();

    const {t} = useTranslation("common");
    const dispatch = useDispatch();

    const { 
        auditCampaign, isLoadingAuditCampaign, getAuditCampaignError,
        surveys, isLoadingSurveys, getSurveysError,
        isConfirmingSurveys, confirmedSurveys, confirmSurveysError
    } = useSelector(({ auSelectSurveys }) => auSelectSurveys);

    const [selectedSurveys, setSelectedSurveys] = useState([]);

    useEffect(() => {
        dispatch(getAuditCampaign(activity.workflowActivityId));

        return () => {
            dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
       
        if(auditCampaign){
 
            console.log([auditCampaign.libraryIds]);
            const surveyIds = auditCampaign.surveyIds ? [...auditCampaign.surveyIds] : [];
            setSelectedSurveys(surveyIds);
            dispatch(getSurveys([auditCampaign.libraryIds]));
        }
    }, [auditCampaign]);

    useEffect(() => {
        if(getAuditCampaignError){
          enqueueSnackbar(getAuditCampaignError, {variant: "error"});
        }
    }, [getAuditCampaignError]);

    useEffect(() => {
        if(getSurveysError){
          enqueueSnackbar(getSurveysError, {variant: "error"});
        }
    }, [getSurveysError]);

    useEffect(() => {
        if(confirmSurveysError){
          enqueueSnackbar(confirmSurveysError, {variant: "error"});
        }
    }, [confirmSurveysError]);

    const onClickSurvey = (survey) => {
        var clone = [...selectedSurveys];

        if(clone.includes(survey.surveyId)){
            const index = clone.indexOf(survey.surveyId);
            clone.splice(index, 1);

            setSelectedSurveys([...clone]);
        }
        else{
            clone.push(survey.surveyId);
            setSelectedSurveys([...clone]);
        }
    };

    const handleClickConfirmSelection = () => {
        if(!selectedSurveys.length){
            enqueueSnackbar("You have to select at least 1 survey", {variant: "error"});
        }
        else{
            const finalData = {
                id: auditCampaign.id,
                workflowActivityStepCompletedId,
                surveyIds: selectedSurveys
            }
            dispatch(confirmSurveys(finalData));
        }
    };

    useEffect(() => {
        if(confirmedSurveys){
            enqueueSnackbar("Selected surveys saved successfully", {variant: "success"});
            handleRefreshProgress();
            handleClickNext();
        }
    }, [confirmedSurveys]);
    
    return(
        <Row>
            {isLoadingSurveys || isLoadingAuditCampaign ? <CircularProgressWrapper /> :  
             surveys && !surveys.length ? <div style={{height: '200px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Typography>There are no surveys to show.</Typography></div> :
            
             <>
                {surveys.map((s, index) => {
                    return(
                        <Col md={4} style={{marginBottom: 30}}>
                            <SurveySelectCard 
                                activityType={activity.activityType}
                                survey={s}
                                onClick={onClickSurvey}
                                isSelected={selectedSurveys.includes(s.surveyId)}
                                disabled={!isEditor}
                            />
                        </Col>
                    )
                })}

                { isEditor && 
                    <Col sm={12}>
                        <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: 10}}>
                            <Button 
                                variant="contained" 
                                onClick={handleClickConfirmSelection}
                                disabled={!selectedSurveys.length}
                            >
                                {t("app.activity.steps.saveAndContinue")}
                            </Button>  
                        </div>
                    </Col>
                }
            </>
            }

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isConfirmingSurveys}
            >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Row>
    );
}

export default AUCampaignSelectSurvey;