import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getAllAssetsRequest,
  getAllAssetsSuccess,
  getAllAssetsError,
  getClassificationsRequest,
  getClassificationsSuccess,
  getClassificationsError
} from './assetViewAction';

const defaultState = {
  assets: [],
  isLoadingAssets: true,
  getAssetsError: null,
  classifications: [],
  isLoadingClassifications: true,
  getClassificationsError: null
};

export default handleActions(
  {
    [getAllAssetsRequest](state) {
      return {
        ...state,
        isLoadingAssets: true,
        getAssetsError: null,
      };
    },
    [getAllAssetsSuccess](state, { payload }) {
      return {
        ...state,
        assets: payload,
        isLoadingAssets: false,
        getAssetsError: null,
      };
    },
    [getAllAssetsError](state, { payload }) {
      return {
        ...state,
        isLoadingAssets: false,
        getAssetsError: payload,
      };
    },
    [getClassificationsRequest](state) {
      return {
        ...state,
        isLoadingClassifications: true,
        getClassificationsError: null,
      };
    },
    [getClassificationsSuccess](state, { payload }) {
      return {
        ...state,
        classifications: payload,
        isLoadingClassifications: false,
        getClassificationsError: null,
      };
    },
    [getClassificationsError](state, { payload }) {
      return {
        ...state,
        isLoadingClassifications: false,
        getClassificationsError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);