import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  updateAvatarRequest,
  updateAvatarSuccess,
  updateAvatarError
} from './avatarChangeAction';

const defaultState = {
  isUpdatingAvatar: false,
  updateAvatarError: null,
  updatedAvatar: false
};

export default handleActions(
  {
    [updateAvatarRequest](state) {
        return {
            ...state,
            isUpdatingAvatar: true,
            updateAvatarError: null,
            updatedAvatar: false
        };
    },
    [updateAvatarSuccess](state, { payload }) {
        return {
            ...state,
            isUpdatingAvatar: false,
            updateAvatarError: null,
            updatedAvatar: true
        };
    },
    [updateAvatarError](state, { payload }) {
        return {
            ...state,
            isUpdatingAvatar: false,
            updateAvatarError: payload,
            updatedAvatar: false
        };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);