import * as type from "./types";
import { instance } from "./../../config/interceptor";
import { enqueueSnackbar } from "notistack";
import { jwtDecode } from "jwt-decode";
import { getTokenRoles } from "../../utils/helpers";

export const login = (values, navigate, redirectUrl) => async (dispatch) => {

  try{
    await dispatch({
      type: type.LOGIN,
      token: null,
      refreshToken: null,
      isLoggedIn: false,
      isLogging: true,
      urlRedirect: null
    });

    const resp = await instance.post("/v1/Account/SignIn", values);
    if (!resp.data.token) {
      if(resp.data.errorsDetail){
        enqueueSnackbar(resp.data.errorsDetail, {variant: "error"});
      }

      await dispatch({
        type: type.LOGIN,
        token: null,
        refreshToken: null,
        isLoggedIn: false,
        isLogging: false,
        urlRedirect: resp.data.urlRedirect
      });
    } 
    else {
      localStorage.setItem("accessToken", resp.data.token);
      localStorage.setItem("refreshToken", resp.data.refreshToken);

      const tokenPayload = jwtDecode(localStorage.getItem("accessToken"));

      await dispatch({
        type: type.LOGIN,
        token: localStorage.getItem("accessToken"),
        refreshToken: localStorage.getItem("refreshToken"),
        isLoggedIn: true,
        isLogging: false,
        urlRedirect: resp.data.urlRedirect,
        tokenPayload: tokenPayload,
        roles: getTokenRoles(tokenPayload)
      });

      if(redirectUrl)
        navigate(redirectUrl);
    }
  }
  catch(e){
    await dispatch({
      type: type.LOGIN,
      token: null,
      refreshToken: null,
      isLoggedIn: false,
      isLogging: false,
      urlRedirect: null
    });    
    
    if(e.response && e.response.data && e.response.data.description){
      enqueueSnackbar(e.response.data.description, {variant: "error"});
    }
    else if(e.response && e.response.data && e.response.data.description){
      enqueueSnackbar(e.response.data.description, {variant: "error"});
    }
    else if(e.response && e.response.data){
      enqueueSnackbar(e.response.data, {variant: "error"});
    }
  }
};

export const logout = () => async (dispatch) => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");

  await dispatch({
    type: type.LOGOUT,
    token: null,
    refreshToken: null,
    isLoggedIn: false,
  });
};

export const refreshToken = (accessToken, refreshToken) => async (dispatch) => {
  localStorage.setItem("accessToken", accessToken);

  if(refreshToken)
    localStorage.setItem("refreshToken", refreshToken);

  await dispatch({
    type: type.REFRESH_TOKEN,
    token: accessToken,
    refreshToken
  });
};

export const impersonateTenant = (id) => async (dispatch) => {
  try {
    await dispatch({
      type: type.IMPERSONATE_TENANT,
      isLogging: true
    });

    const resp = await instance.post(`/v1/Account/ImpersonateTenant/${id}`);

    if (!resp.data.token) {
      await dispatch({
        type: type.IMPERSONATE_TENANT,
        token: null,
        refreshToken: null,
        isLoggedIn: false,
        isLogging: false,
        urlRedirect: resp.data.urlRedirect,
        roles: null
      });
    } 

    localStorage.setItem("accessToken", resp.data.token);
    localStorage.setItem("refreshToken", resp.data.refreshToken);

    window.location.reload();
    
  } catch (e) {
    await dispatch({
      type: type.IMPERSONATE_TENANT,
      token: null,
      refreshToken: null,
      isLoggedIn: false,
      isLogging: false,
      urlRedirect: null,
      roles: null
    });
  }
};