import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { getAuditCampaign, cleanup } from "@/reducers/auditCampaign/report/AUReportAction";
import { enqueueSnackbar } from "notistack";
import { Col, Row } from "react-bootstrap";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import DoughnutChart from "@/shared/components/charts/DoughnutChart";

const AUCampaignReport = () => {

    const { 
        activity, 
        workflowActivityStepCompletedId, 
        isEditor,
        handleRefreshProgress
    } = useOutletContext();

    const dispatch = useDispatch();
    
    const { 
        auditCampaign, isLoadingAuditCampaign, getAuditCampaignError
    } = useSelector(({ auReport }) => auReport);


    useEffect(() => {
        dispatch(getAuditCampaign(activity.workflowActivityId));

        return () => {
            dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
        if(getAuditCampaignError){
          enqueueSnackbar(getAuditCampaignError, {variant: "error"});
        }
    }, [getAuditCampaignError]);

    return(
        <Row>
            {isLoadingAuditCampaign || !auditCampaign ? <CircularProgressWrapper /> :
                <Col md={12}>
                    <DoughnutChart 
                        endpoint={`GetCampaignExternalAuditRisk?campaignId=${auditCampaign.id}`}
                        height={500} 
                        legend={{
                            orient: 'vertical',
                            left: 'left'
                        }}
                        title={{
                            text: "Campaign Rating Score",
                            left: 'center'
                        }}
                        tooltip= {{
                            trigger: 'item',
                            axisPointer: {
                              type: 'cross'
                            },
                            hideDelay: 2000,
                            position: function (pos, params, dom, rect, size) {
                                if((pos[0] < size.viewSize[0] / 2)){
                                    return "left";
                                }
                                else{
                                    return "right";
                                }
                            },
                            enterable: true,
                            formatter: function (params, ticket, callback) {
                                const content = params.data.items.join(', ');
                                return `
                                    <div><strong>${params.name}: </strong>${params.value}</div>
                                    </br>
                                    <div style="max-width: 350px; max-height: 400px; overflow-y: auto; white-space: wrap;">
                                        <strong>Suppliers: </strong>${content}
                                    </div>
                                `;
                            }
                        }}
                    />
                </Col>
            }
        </Row>
    );
};

export default AUCampaignReport;