import { Autocomplete, Button, TextField } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

const InspectTab = ({
    onExpandAll,
    onCollapseAll,
    hiddenNodes,
    nodes,
    onChangeHiddenNodes
}) => {


    return(
        <Row>
            <Col md={6}>
                <Button 
                    fullWidth 
                    variant="contained" 
                    onClick={onExpandAll}
                    endIcon={<OpenInFullIcon />}
                >
                    Expand All
                </Button>
            </Col>
            <Col md={6}>
                <Button 
                    fullWidth 
                    variant="contained" 
                    onClick={onCollapseAll}
                    endIcon={<CloseFullscreenIcon />}
                >
                    Collapse All
                </Button>
            </Col>
            <Col md={12}>
                <Autocomplete
                    multiple
                    id="hidden-nodes"
                    options={nodes}
                    value={hiddenNodes}
                    limitTags={3}
                    getOptionLabel={(node) => node.name}
                    onChange={(e, data) => onChangeHiddenNodes(data)}
                    renderInput={(params) => {
                        const { InputProps, ...restParams } = params;
                        const { startAdornment, ...restInputProps } = InputProps;
            
                        return (
                        <TextField 
                            { ...restParams }
                            label="Hidden Nodes"
                            style={{'marginTop': 20}}
                            fullWidth
                            variant="outlined"
                            InputProps={ {
                                ...restInputProps,
                                startAdornment: (
                                  <div style={{
                                    maxHeight: '150px',
                                    overflowY: 'auto',
                                  }}
                                  >
                                    {startAdornment}
                                  </div>
                                ),
                              } }
                        />
                    )}}
                />
            </Col>
        </Row>
    );
};

export default InspectTab;