import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getSuppliersRequest,
  getSuppliersSuccess,
  getSuppliersError,
  deleteSuppliersRequest,
  deleteSuppliersSuccess,
  deleteSuppliersError,
  getClassificationsRequest,
  getClassificationsSuccess,
  getClassificationsError
} from './supplierAction';

const defaultState = {
  suppliers: [],
  isLoadingSuppliers: true,
  getSuppliersError: null,
  isDeletingSuppliers: false,
  deleteSuppliersError: null,
  deletedSuppliers: false,
  classifications: [],
  isLoadingClassifications: true,
  getClassificationsError: null
};

export default handleActions(
  {
    [getSuppliersRequest](state) {
      return {
        ...state,
        suppliers: [],
        isLoadingSuppliers: true,
        getSuppliersError: null,
      };
    },
    [getSuppliersSuccess](state, { payload }) {
      return {
        ...state,
        suppliers: payload,
        isLoadingSuppliers: false,
        getSuppliersError: null,
      };
    },
    [getSuppliersError](state, { payload }) {
      return {
        ...state,
        suppliers: [],
        isLoadingSuppliers: false,
        getSuppliersError: payload,
      };
    },
    [deleteSuppliersRequest](state) {
      return {
        ...state,
        isDeletingSuppliers: true,
        deletedSuppliers: false,
        deleteSuppliersError: null,
      };
    },
    [deleteSuppliersSuccess](state, { payload }) {
      return {
        ...state,
        isDeletingSuppliers: false,
        deletedSuppliers: true,
        deleteSuppliersError: null,
      };
    },
    [deleteSuppliersError](state, { payload }) {
      return {
        ...state,
        isDeletingSuppliers: false,
        deletedSuppliers: false,
        deleteSuppliersError: payload,
      };
    },
    [getClassificationsRequest](state) {
      return {
        ...state,
        isLoadingClassifications: true,
        getClassificationsError: null,
      };
    },
    [getClassificationsSuccess](state, { payload }) {
      return {
        ...state,
        classifications: payload,
        isLoadingClassifications: false,
        getClassificationsError: null,
      };
    },
    [getClassificationsError](state, { payload }) {
      return {
        ...state,
        isLoadingClassifications: false,
        getClassificationsError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);