import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getActivitiesRequest,
  getActivitiesSuccess,
  getActivitiesError
} from './listCreateAction';

const defaultState = {
  activities: null,
  isLoadingActivities: true,
  getActivitiesError: null,
};

export default handleActions(
  {
    [getActivitiesRequest](state) {
      return {
        ...state,
        isLoadingActivities: true,
        getActivitiesError: null,
      };
    },
    [getActivitiesSuccess](state, { payload }) {
      return {
        ...state,
        activities: payload,
        isLoadingActivities: false,
        getActivitiesError: null,
      };
    },
    [getActivitiesError](state, { payload }) {
      return {
        ...state,
        isLoadingActivities: false,
        getActivitiesError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);
