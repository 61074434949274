import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getActivityTypeRequest,
  getActivityTypeSuccess,
  getActivityTypeError,
  getWorkflowStepsRequest,
  getWorkflowStepsSuccess,
  getWorkflowStepsError,
  getWorkflowCreateTaskRequest,
  getWorkflowCreateTaskSuccess,
  getWorkflowCreateTaskError,
  createActivityRequest,
  createActivitySuccess,
  createActivityError
} from './activityCreateAction';

const defaultState = {
  activityType: null,
  isLoadingActivityType: true,
  getActivityTypeError: null,
  steps: null,
  isLoadingSteps: true,
  getStepsError: null,
  createTask: null,
  isLoadingCreateTask: true,
  getCreateTaskError: null,
  isAddingActivity: false,
  isAddedActivity: false,
  addActivityError: null
};

export default handleActions(
  {
    [getActivityTypeRequest](state) {
      return {
        ...state,
        isLoadingActivityType: true,
        getActivityTypeError: null,
      };
    },
    [getActivityTypeSuccess](state, { payload }) {
      return {
        ...state,
        activityType: payload,
        isLoadingActivityType: false,
        getActivityTypeError: null,
      };
    },
    [getActivityTypeError](state, { payload }) {
      return {
        ...state,
        isLoadingActivityType: false,
        getActivityTypeError: payload,
      };
    },
    [getWorkflowStepsRequest](state) {
      return {
        ...state,
        isLoadingSteps: true,
        getStepsError: null,
      };
    },
    [getWorkflowStepsSuccess](state, { payload }) {
      return {
        ...state,
        steps: payload,
        isLoadingSteps: false,
        getStepsError: null,
      };
    },
    [getWorkflowStepsError](state, { payload }) {
      return {
        ...state,
        isLoadingSteps: false,
        getStepsError: payload,
      };
    },
    [getWorkflowCreateTaskRequest](state) {
      return {
        ...state,
        isLoadingCreateTask: true,
        getCreateTaskError: null,
      };
    },
    [getWorkflowCreateTaskSuccess](state, { payload }) {
      return {
        ...state,
        createTask: payload,
        isLoadingCreateTask: false,
        getCreateTaskError: null,
      };
    },
    [getWorkflowCreateTaskError](state, { payload }) {
      return {
        ...state,
        isLoadingCreateTask: false,
        getCreateTaskError: payload,
      };
    },
    [createActivityRequest](state) {
      return {
        ...state,
        isAddingActivity: true,
        addActivityError: null,
      };
    },
    [createActivitySuccess](state, { payload }) {
      return {
        ...state,
        isAddedActivity: true,
        isAddingActivity: false,
        addActivityError: null,
      };
    },
    [createActivityError](state, { payload }) {
      return {
        ...state,
        isAddingActivity: false,
        addActivityError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);
