import { Backdrop, Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridToolbar, gridClasses } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { cleanup, getRoles, getUsers, deleteStakeholderUser } from "@/reducers/supplier/stakeholderUsers/stakeholderUserListAction";
import { Col, Container, Row } from "react-bootstrap";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { CardPage, CardPageFilterWrapper, CardPageHeader, CardPageBody, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap,CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import AddIcon from '@mui/icons-material/Add';
import ConfirmationDialog from '@/shared/components/ConfirmationDialog';

const UsersTab = ({
    stakeholderId
}) => {
    const dispatch = useDispatch();

    const { 
        users, usersTotalCount, isLoadingUsers, getUsersError,
        isDeletingStakeholderUser, deletedStakeholderUser, deleteStakeholderUserError,
    } = useSelector(({ stakeholderUserList }) => stakeholderUserList);

    const [selectedRows, setSelectedRows] = useState([]);
    const [searchFilters, setSearchFilters] = useState({ 
        pageSize: 30, 
        page: 1,
        stakeholderId: stakeholderId
    });

    const navigate = useNavigate();

    useEffect(() => {
        return () => {
          dispatch(cleanup());
        }
    }, [dispatch]);
    
    useEffect(() => {
        if(getUsersError){
            enqueueSnackbar(getUsersError, {variant: "error"});
        }
    }, [getUsersError]);

    useEffect(() => {
        if(deleteStakeholderUserError){
            enqueueSnackbar(deleteStakeholderUserError, {variant: "error"});
        }
    }, [deleteStakeholderUserError]);
    
    useEffect(() => {
        dispatch(getUsers(searchFilters));
    }, [searchFilters]);

    const setPaginationModel = (model) => {
        setSearchFilters({ 
            ...searchFilters, 
            page: model.page + 1,
            pageSize: model.pageSize
        });
    };

    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [entityToDelete, setEntityToDelete] = useState(null);

    const handleClickDeleteUser = (user) => {
      setEntityToDelete(user);
      setOpenConfirmDelete(true);
    }

    const handleConfirmDelete = () => {
      setOpenConfirmDelete(false);
      dispatch(deleteStakeholderUser(entityToDelete.stakeholderApplicationUserId));
      setEntityToDelete(null);
    } 

    const handleOnCloseDelete = () => {
        setOpenConfirmDelete(false);
        setEntityToDelete(null);
    };

    useEffect(() => {
        if(deletedStakeholderUser){
            enqueueSnackbar("User deleted successfully", {variant: "success"});
            dispatch(getUsers(searchFilters));
        }
    }, [deletedStakeholderUser]);

    const onFilterSubmit = (data) => {
        setSearchFilters({ ...searchFilters, page: 1, ...data});
    }

    const handleClickEditUser = (id) => {
        navigate("UpdateUser/" + id);
    }

    const handleClickAddUser = () => {
        navigate("AddUser");
    }

    const columns = [
        { 
            field: 'userName',
            headerName: 'User name',
            minWidth: 200,
            flex: 0.5
        },
        { 
            field: 'email', 
            headerName: 'Email', 
            minWidth: 300,
            flex: 1
        },
        { 
            field: 'firstName', 
            headerName: 'First Name', 
            minWidth: 200,
            flex: 0.5
        },
        { 
            field: 'lastName', 
            headerName: 'Last Name', 
            minWidth: 200,
            flex: 0.5
        },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => [
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => handleClickDeleteUser(params.row)}
              />
            ],
          },
      ];

    return(
        <Row>
            <Col md={12}>
                <Box sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flex-end',
                    marginBottom: 2
                }}>
                    <Button 
                        onClick={handleClickAddUser}
                        variant="contained" 
                        color="primary" 
                        endIcon={<AddIcon/>}>
                            Add User
                    </Button>
                </Box>

                <CardPageGridWrap>
                    <DataGrid 
                    autoHeight
                    density="compact"
                    checkboxSelection 
                    disableRowSelectionOnClick
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setSelectedRows(newRowSelectionModel);
                    }}
                    rowSelectionModel={selectedRows}
                    loading={isLoadingUsers} 
                    rows={users}
                    columns={columns} 
                    getRowId={(row) => row.stakeholderApplicationUserId}
                    slots={{ toolbar: GridToolbar }}
                    sx={{
                        [`& .${gridClasses.cell}`]: {
                        py: 1,
                        },
                    }}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 30 } },
                    }}
                    pageSizeOptions={[5, 15, 30, 50, 100]}
                    rowCount={usersTotalCount}
                    paginationModel={{
                        page: searchFilters.page - 1, 
                        pageSize: searchFilters.pageSize}}
                    paginationMode="server"
                    onPaginationModelChange={setPaginationModel}
                    />
                </CardPageGridWrap>

                { openConfirmDelete &&
                    <ConfirmationDialog 
                        open={openConfirmDelete}
                        onConfirm={handleConfirmDelete}
                        onClose={handleOnCloseDelete}
                        title="Delete User"
                        body={`Are you sure you want to delete the user ${entityToDelete.userName}?`}
                        color='error'
                        confirmButtonText='Delete'
                    />
                }

                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} 
                    open={isDeletingStakeholderUser}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Col>
        </Row>
    )
}

export default UsersTab;