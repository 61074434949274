import { CardPage, CardPageBody, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap, CardPageGridWrap, CardPageChartBoxWrap, CardPageChartBoxTitleWrap} from '@/shared/components/MaterialCardPage';
import { Col, Container, Row } from "react-bootstrap";
import AccountChangePassword from './components/changePassword';
import TwoFactorConfiguration from './components/twoFactorSettings';
import UserOption from './components/userOption';
import { YardRounded } from '@mui/icons-material';


const AccountSettings = () => {
    return(
        <Container>
            <CardPage>
                <CardPageBody>
                    <CardPageTitleWrap>
                        <CardPageTitle>Accuont Settings</CardPageTitle>
                    </CardPageTitleWrap>

                    <Row style={{ marginTop: 10 }}>
                        <Col md={12}>
                            <UserOption />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} style={{ marginTop: 30 }}>
                            <AccountChangePassword />
                        </Col>
                        <Col md={6} style={{ marginTop: 30 }}>
                            <TwoFactorConfiguration />
                        </Col>
                    </Row>
                </CardPageBody>
            </CardPage>
        </Container>
    );
}

export default AccountSettings;