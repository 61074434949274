import { Box, Typography, Icon} from "@mui/material";
import { colorRed, colorWhite } from '@/utils/palette';
import BotAvatarAnimated from "@/shared/components/chatbot/BotAvatarAnimated";
import WarningAmber from '@mui/icons-material/WarningAmber';
import styled from "styled-components";
import {
    borderLeft,
    borderRight,
    marginLeft,
    marginRight,
    right,
    left,
    paddingLeft,
  } from "@/utils/directions";
import { size } from "polished";

export const ChatComment = styled.div`
  margin-bottom: 20px;
  width: 93%;
  float: ${right};
  ${paddingLeft}: 10px;
  ${borderLeft}: 2px solid ${colorWhite};
`;
export const ChatCommentIcon = styled.div`
  float: ${left};
  text-align: ${right};
`;

const LoginChatbot = ({
    emailQuerySelector,
    passwordQuerySelector,
    passwordToggleQuerySelector,
    showPassword
}) => {

    const showBot = process.env.REACT_APP_SHOW_BOT === "true";
    const loginUrl = process.env.REACT_APP_LOGIN_URL;

    return(
        <Box sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
        }}>
            { showBot &&
                <Box sx={{width: '120px', height: '120px', marginBottom: 1}}>
                    <BotAvatarAnimated
                        size="120px" 
                        emailQuerySelector={emailQuerySelector}
                        passwordQuerySelector={passwordQuerySelector}
                        passwordToggleQuerySelector={passwordToggleQuerySelector}
                        showPassword={showPassword}
                    />
                </Box>
            }
            <Box style={{ width: '100%', marginTop: '10px' }}>
                <ChatCommentIcon>
                    <Typography color='#da9500'>
                        <WarningAmber />
                    </Typography>
                </ChatCommentIcon>
                <ChatComment>
                    <Typography color='text.primary' style={{ fontSize: '15px' }}> Please always check that you are viewing the correct URL: <span style={{ color: colorRed }}>https</span>://*.{loginUrl}</Typography>
                </ChatComment>
            </Box>
        </Box>
        
    );
}

export default LoginChatbot;