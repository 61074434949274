import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_VULNERABILITY_INFO');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getVulnerabilityRequest = createAction('VULNERABILITY_INFO_GET_BY_ID_REQUEST');
export const getVulnerabilitySuccess = createAction('VULNERABILITY_INFO_GET_BY_ID_SUCCESS');
export const getVulnerabilityError = createAction('VULNERABILITY_INFO_GET_BY_ID_ERROR');

export const getVulnerability = (id) => async (dispatch) => {
  try {
    dispatch(getVulnerabilityRequest());
    const resp = await instance.get(`/v1/Vulnerability/GetById/${id}`);
    
    dispatch(getVulnerabilitySuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getVulnerabilityError(e.response.data.description));
    else
      dispatch(getVulnerabilityError(e.message));
  }
};