import { Backdrop, Button, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { cleanup, addTenant } from "@/reducers/system/tenants/addTenant/systemAddTenantAction";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { CardPage, CardPageFilterWrapper, CardPageBody, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap,CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import { FormTextField } from "@/shared/components/form/material-controls/TextField";
import { useForm } from "react-hook-form";
import { IsValidEmail } from "@/utils/formValidations";

const SystemAddTenant = () => {
    const dispatch = useDispatch();
    const { 
        isAddingTenant, addedTenant, addTenantError
    } = useSelector(({ systemAddTenant }) => systemAddTenant);

    const navigate = useNavigate();
    const { handleSubmit, reset, control, setValue } = useForm();

    useEffect(() => {
        return () => {
          dispatch(cleanup());
        }
    }, [dispatch]);
    
    useEffect(() => {
        if(addTenantError){
            enqueueSnackbar(addTenantError, {variant: "error"});
        }
    }, [addTenantError]);

    useEffect(() => {
        if(addedTenant){
          enqueueSnackbar(`Tenant created successfuly.`, {variant: "success"});
          navigate("/SystemSettings/Tenants");
        }
    }, [addedTenant]);

    const onSubmit = (data) => {
        dispatch(addTenant(data));
    }

    const onClickCancel = () => {
        navigate("/SystemSettings/Tenants");
    }

    return(
        <Container>
            <CardPage>
                <CardPageBody>         
                    <CardPageTitleWrap>
                        <CardPageTitle>Add Tenant</CardPageTitle>
                    </CardPageTitleWrap>
                    <Typography variant="subtitle1" style={{ marginTop: 20 }}>Contact Information</Typography>
                    <Row>
                        <Col md={12}>
                            <FormTextField
                                name="name" 
                                label="Name" 
                                control={control}
                                rules={{ required: 'This field is required' }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormTextField
                                name="emailAddress" 
                                label="Email" 
                                control={control}
                                rules={{ 
                                    required: 'This field is required',
                                    validate: {
                                        matchPattern: (email) => IsValidEmail(email) ||
                                        "Email address must be a valid address"
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormTextField
                                name="pecEmailAddress" 
                                label="Pec Email" 
                                control={control}
                                rules={{ 
                                    required: 'This field is required',
                                    validate: {
                                        matchPattern: (email) => IsValidEmail(email) ||
                                        "Email address must be a valid address"
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <Divider component='div' style={{ marginTop: 20 }} />
                    <Typography variant="subtitle1" style={{ marginTop: 20 }}>Billing Information</Typography>
                    <Row>
                        <Col md={12}>
                            <FormTextField 
                                name="billingName" 
                                label="Billing Name" 
                                control={control} 
                                rules={{ required: 'This field is required' }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormTextField 
                                name="billingAddress" 
                                label="Billing Address" 
                                control={control} 
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormTextField 
                                name="billingCountry" 
                                label="Billing Country" 
                                control={control} 
                            />
                        </Col>
                        <Col md={6}>
                            <FormTextField 
                                name="billingCity" 
                                label="Billing City" 
                                control={control} 
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormTextField 
                                name="vat" 
                                label="VAT" 
                                control={control} 
                            />
                        </Col>
                        <Col md={6}>
                            <FormTextField 
                                name="vatCode" 
                                label="SDI Code" 
                                control={control} 
                            />
                        </Col>
                    </Row>

                    <Row style={{marginTop: 20}}>
                        <Col md={12}>
                            <Grid
                                container
                                justifyContent="flex-end"
                                spacing={2}
                            >
                                <Grid item>
                                    <Button
                                        onClick={onClickCancel}
                                        variant="outlined"
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={handleSubmit(onSubmit)}
                                        variant="contained"
                                    >
                                        Create
                                    </Button>
                                </Grid>
                                
                            </Grid>
                        </Col>
                    </Row>
                </CardPageBody>
            </CardPage>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isAddingTenant}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}

export default SystemAddTenant;