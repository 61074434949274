import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, Typography, Backdrop, CircularProgress, Box, Divider, Icon } from '@mui/material';
import { Col, Container, Row } from 'react-bootstrap';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { FormMultiSelectField } from '@/shared/components/form/material-controls/MultiSelectField';
import { FormSelectField } from '@/shared/components/form/material-controls/SelectField';
import { FormDateField } from '@/shared/components/form/material-controls/DateField';
import { FormRangeSliderField } from "@/shared/components/form/material-controls/RangeSliderField";

const BasicFilters = ({
    control,
    ratingScores,
    contextualScores,
    watch,
    getValues,
    setValue
}) => {
    return(
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
            <Typography>Basic Filters</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Row>
                    {/* <Col md={6}>
                        <FormTextField 
                            name="asset" 
                            label="Asset" 
                            control={control} 
                        />
                    </Col> */}
                    <Col md={6}>
                        <FormTextField 
                            name="cve" 
                            label="CVE" 
                            control={control} 
                        />
                    </Col>
                    <Col md={6}>
                        <FormDateField 
                            name="createDate" 
                            label="Create Date" 
                            control={control}
                        />
                    </Col>
                    <Col md={6}>
                        <FormTextField 
                            name="vulnerability" 
                            label="Vulnerability Name" 
                            control={control} 
                        />
                    </Col>
                    {/* <Col md={6}>
                        <FormMultiSelectField 
                            name="domain" 
                            label="Domain" 
                            control={control} 
                            options={domains}
                            keyValue={{id:"id", label:"value"}}
                        />
                    </Col> */}
                    <Divider component="div" sx={{marginBlock: 2}} />
                    <Typography>Scores</Typography>
                    <Col md={6}>
                        <FormSelectField 
                            name="baseScore" 
                            label="CVSS Base Score" 
                            control={control} 
                            options={ratingScores}
                            keyValue={{id:"id", label:"name"}}
                        />
                    </Col>
                    <Col md={6}>
                        <FormSelectField 
                            name="contextualScore" 
                            label="Contextual Score" 
                            control={control} 
                            options={contextualScores}
                            keyValue={{id:"id", label:"name"}}
                        />
                    </Col>
                    <Col md={6}>
                        <FormSelectField 
                            name="exploited" 
                            label="Exploited" 
                            control={control} 
                            options={[
                                { id: false, label: "No" }, 
                                { id: true, label: "Si" }
                            ]}
                            keyValue={{id:"id", label:"label"}}
                        />
                    </Col>
                    <Col md={6}>
                        <FormRangeSliderField 
                            name='threshold'
                            label='EPSS Threshold'
                            canEnable={true}
                            defaultEnabled={false}
                            nameCheckbox="enableEpss"
                            labelCheckbox="Enable"
                            watch={watch}
                            control={control}
                            min={0}
                            max={100}
                            step={0.01}
                            defaultValue={[0, 100]}
                            showPercent={true}
                            showArrows={true}
                            decimals={2}
                            setValue={setValue}
                            getValues={getValues}
                        />
                    </Col>
                </Row>
            </AccordionDetails>
        </Accordion>
    );
};

export default BasicFilters;