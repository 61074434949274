import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Controller } from "react-hook-form";
import { DemoItem } from '@mui/x-date-pickers/internals/demo';

export const FormDateField = ({ name, control, label, rules, defaultValue=null, ...other }) => {
  return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({
          field: { onChange, value },
          fieldState: { error },
          formState,
        }) => (
          <DemoItem>
            <DatePicker 
              value={value}
              slotProps={{ 
                textField: { 
                  required: rules && rules.required,
                  style:{'marginTop': 20}, 
                  helperText: error ? error.message : null, 
                  size:"small",
                  error:!!error, 
                  fullWidth: true,
                  ...other
                },
                actionBar: { actions: ['clear', 'today'] }
              }}
              onChange={onChange}
              label={label}              
              variant="outlined"              
            />
          </DemoItem>
        )}
      />
  );
};