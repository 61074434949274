import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_SEND_TEST_EMAIL');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const sendTestEmailRequest = createAction('SEND_TEST_EMAIL_REQUEST');
export const sendTestEmailSuccess = createAction('SEND_TEST_EMAIL_SUCCESS');
export const sendTestEmailError = createAction('SEND_TEST_EMAIL_ERROR');

export const sendTestEmail = (data) => async (dispatch) => {
  try {
    dispatch(sendTestEmailRequest());
    const resp = await instance.post(`/v1/EmailMessageTemplate/SendTestEmail`, data);
    
    dispatch(sendTestEmailSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(sendTestEmailError(e.response.data.description));
    else
      dispatch(sendTestEmailError(e.message));
  }
};