import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { FlexCenteredText } from "@/shared/components/CircularProgressWrapper";

const ScanModal = ({ scanDetails, onHideModal, showModal }) => {

    const handleClose = (event, reason) => {
        onHideModal();
      }

    return(
        <Dialog
          fullWidth={true}
          maxWidth='lg'
          scroll='paper'
          open={showModal}
          onClose={handleClose}
      >
          <DialogTitle>Scan Details</DialogTitle>
          <DialogContent style={{height: 500}}>
            { scanDetails ?
                <pre style={{
                    fontSize: "16px", 
                    whiteSpace: 'pre-wrap', 
                    wordBreak: 'break-word', 
                    paddingRight: "0px", 
                    contain: "inline-size"
                }}>
                    {JSON.stringify(JSON.parse(scanDetails), null, 2)}
                </pre>
                :            
                <FlexCenteredText>
                    No content to show.
                </FlexCenteredText>
            }
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Close</Button>
          </DialogActions>
      </Dialog>
    )
};

export default ScanModal;