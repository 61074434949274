import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAvatar, cleanup } from "@/reducers/account/userInfo/avatarChange/avatarChangeAction";
import styled from 'styled-components';
import { DropzoneArea } from "mui-file-dropzone";

const AvatarChangeModal = ({
    avatar,
    open, 
    onHideModal,
    onUpdateAvatar
}) => {
    
    const {
        isUpdatingAvatar, updatedAvatar, updateAvatarError
    } = useSelector(({ avatarChange }) => avatarChange);

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(cleanup());
        }
    }, [dispatch]);
    
    const [uploadedImage, setUploadedImage] = useState(null);

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") 
            return;
        
        onHideModal();
    }

    useEffect(() => {
        if(updateAvatarError){
            enqueueSnackbar(updateAvatarError, {variant: "error"});
        }
    }, [updateAvatarError]);

    const onImageChange = (files) => {
        if(files && files.length){
            setUploadedImage(files[0]);
        }
        else{
            setUploadedImage(null);
        }
    }

    const onSubmit = () => {
        var formData = new FormData();
        formData.append("formFileAvatar", uploadedImage);
        dispatch(updateAvatar(formData));
    }
    
    useEffect(() => {
        if(updatedAvatar){
            enqueueSnackbar("Avatar updated successfully", {variant: "success"});
            handleClose();
            onUpdateAvatar();
        }
    }, [updatedAvatar]);

    return(
        <Dialog
          fullWidth
          maxWidth='md'
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>Edit Avatar</DialogTitle>
          <DialogContent>
            <ProfileAvatarWrapper>
                <ProfileAvatar>
                    {!uploadedImage ? 
                        !avatar ?
                        <img src="/img/avatar.svg" alt="avatar"/>
                        :
                        <img src={URL.createObjectURL(avatar)} alt="avatar"/>
                        :
                        <img src={URL.createObjectURL(uploadedImage)} alt="avatar"/>
                    }
                </ProfileAvatar>
            </ProfileAvatarWrapper>

            <DropzoneArea 
                clearOnUnmount
                onChange={onImageChange}
                acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                filesLimit={1}
                maxFileSize={5000000}
            />
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button disabled={uploadedImage === null} variant='contained' onClick={onSubmit}>Edit</Button>
          </DialogActions>
  
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isUpdatingAvatar}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
      </Dialog>
    );
}

export default AvatarChangeModal;

const ProfileAvatarWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const ProfileAvatar = styled.div`
  height: 300px;
  width: 300px;
  overflow: hidden;
  border-radius: 50%;

  img {
    object-fit: cover;
    height: 100%;
  }
`;