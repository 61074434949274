import { createAction } from 'redux-actions';
import { instance } from "../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_TENANT_SUBSCRIPTION');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getTenantRequest = createAction('TENANT_SUBSCRIPTION_GET_REQUEST');
export const getTenantSuccess = createAction('TENANT_SUBSCRIPTION_GET_SUCCESS');
export const getTenantError = createAction('STENANT_SUBSCRIPTION_GET_ERROR');

export const getTenant = (id) => async (dispatch) => {
  try {
    dispatch(getTenantRequest());

    const resp = await instance.get(`/v1/Tenant/GetFull/${id}`);
    
    dispatch(getTenantSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getTenantError(e.response.data.description));
    else
      dispatch(getTenantError(e.message));
  }
};

export const getSubscriptionTypesRequest = createAction('TENANT_SUBSCRIPTION_GET_TYPES_REQUEST');
export const getSubscriptionTypesSuccess = createAction('TENANT_SUBSCRIPTION_GET_TYPES_SUCCESS');
export const getSubscriptionTypesError = createAction('TENANT_SUBSCRIPTION_GET_TYPES_ERROR');

export const getSubscriptionTypes = () => async (dispatch) => {
  try {
    dispatch(getSubscriptionTypesRequest());

    const resp = await instance.get(`/v1/SubscriptionType/GetAllFull`);
    
    dispatch(getSubscriptionTypesSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getSubscriptionTypesError(e.response.data.description));
    else
      dispatch(getSubscriptionTypesError(e.message));
  }
};

export const updateSubscriptionRequest = createAction('TENANT_SUBSCRIPTION_UPDATE_REQUEST');
export const updateSubscriptionSuccess = createAction('TENANT_SUBSCRIPTION_UPDATE_SUCCESS');
export const updateSubscriptionError = createAction('TENANT_SUBSCRIPTION_UPDATE_ERROR');

export const updateSubscription = (data) => async (dispatch) => {
  try {
    dispatch(updateSubscriptionRequest());

    const resp = await instance.put(`/v1/Tenant/AddOrUpdateSubscription`, data);
    
    dispatch(updateSubscriptionSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(updateSubscriptionError(e.response.data.description));
    else
      dispatch(updateSubscriptionError(e.message));
  }
};