import { createAction } from 'redux-actions';
import { instance } from "../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_LIBRARIES');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getLibrariesRequest = createAction('LIBRARIES_GET_ALL_REQUEST');
export const getLibrariesSuccess = createAction('LIBRARIES_GET_ALL_SUCCESS');
export const getLibrariesError = createAction('LIBRARIES_GET_ALL_ERROR');

export const getLibraries = () => async (dispatch) => {
  try {
    dispatch(getLibrariesRequest());
    const resp = await instance.get(`/v1/Library/GetAllForDownload`);
    
    dispatch(getLibrariesSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getLibrariesError(e.response.data.description));
    else
      dispatch(getLibrariesError(e.message));
  }
};

export const downloadRequest = createAction('LIBRARIES_DOWNLOAD_REQUEST');
export const downloadSuccess = createAction('LIBRARIES_DOWNLOAD_SUCCESS');
export const downloadError = createAction('LIBRARIES_DOWNLOAD_ERROR');

export const download = (ids) => async (dispatch) => {
  try {
    dispatch(downloadRequest());
    const resp = await instance.post(`/v1/Library/DownloadLibraries`, ids);

    dispatch(downloadSuccess(ids));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(downloadError(e.response.data.description));
    else
      dispatch(downloadError(e.message));
  }
};