import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getAuditCampaignRequest,
  getAuditCampaignSuccess,
  getAuditCampaignError,
  getSurveysRequest,
  getSurveysSuccess,
  getSurveysError,
  getWorkflowRolesRequest,
  getWorkflowRolesSuccess,
  getWorkflowRolesError,
  getRolesWithUsersRequest,
  getRolesWithUsersSuccess,
  getRolesWithUsersError,
  getAssetsRequest,
  getAssetsSuccess,
  getAssetsError,
} from './AUAssignSurveysAction';

const defaultState = {
  auditCampaign: null, 
  isLoadingAuditCampaign: true, 
  getAuditCampaignError: null,
  surveys: [],
  isLoadingSurveys: true,
  getSurveysError: null,
  roles: null,
  isLoadingRoles: true,
  getRolesError: null,
  rolesWithUsers: null,
  isLoadingRolesWithUsers: true,
  getRolesWithUsersError: null,
  assets: [],
  isLoadingAssets: true,
  getAssetsError: null,
};

export default handleActions(
  {
    [getAuditCampaignRequest](state) {
      return {
        ...state,
        auditCampaign: null,
        isLoadingAuditCampaign: true,
        getAuditCampaignError: null,
      };
    },
    [getAuditCampaignSuccess](state, { payload }) {
      return {
        ...state,
        auditCampaign: payload,
        isLoadingAuditCampaign: false,
        getAuditCampaignError: null,
      };
    },
    [getAuditCampaignError](state, { payload }) {
      return {
        ...state,
        auditCampaign: null,
        isLoadingAuditCampaign: false,
        getAuditCampaignError: payload,
      };
    },
    [getSurveysRequest](state) {
      return {
        ...state,
        surveys: [],
        isLoadingSurveys: true,
        getSurveysError: null,
      };
    },
    [getSurveysSuccess](state, { payload }) {
      return {
        ...state,
        surveys: payload,
        isLoadingSurveys: false,
        getSurveysError: null,
      };
    },
    [getSurveysError](state, { payload }) {
      return {
        ...state,
        surveys: [],
        isLoadingSurveys: false,
        getSurveysError: payload,
      };
    },
    [getWorkflowRolesRequest](state) {
      return {
        ...state,
        isLoadingRoles: true,
        getRolesError: null,
      };
    },
    [getWorkflowRolesSuccess](state, { payload }) {
      return {
        ...state,
        roles: payload,
        isLoadingRoles: false,
        getRolesError: null,
      };
    },
    [getWorkflowRolesError](state, { payload }) {
      return {
        ...state,
        isLoadingRoles: false,
        getRolesError: payload,
      };
    },
    [getRolesWithUsersRequest](state) {
      return {
        ...state,
        isLoadingRolesWithUsers: true,
        getRolesWithUsersError: null,
      };
    },
    [getRolesWithUsersSuccess](state, { payload }) {
      return {
        ...state,
        rolesWithUsers: payload,
        isLoadingRolesWithUsers: false,
        getRolesWithUsersError: null,
      };
    },
    [getRolesWithUsersError](state, { payload }) {
      return {
        ...state,
        isLoadingRolesWithUsers: false,
        getRolesWithUsersError: payload,
      };
    },
    [getAssetsRequest](state) {
      return {
        ...state,
        assets: [],
        isLoadingAssets: true,
        getAssetsError: null,
      };
    },
    [getAssetsSuccess](state, { payload }) {
      return {
        ...state,
        assets: payload,
        isLoadingAssets: false,
        getAssetsError: null,
      };
    },
    [getAssetsError](state, { payload }) {
      return {
        ...state,
        assets: [],
        isLoadingAssets: false,
        getAssetsError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);