import { Backdrop, Box, Button, CircularProgress, IconButton} from "@mui/material"; 
import { DataGrid, GridActionsCellItem, GridToolbar, gridClasses } from "@mui/x-data-grid";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";
import AddIcon from '@mui/icons-material/Add';
import { Col, Container, Row } from "react-bootstrap";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
import CreateAssetTypeModal from "./components/createAssetTypeModal";
import EditAssetTypeModal from "./components/editAssetTypeModal";
import ConfirmationDialog from '@/shared/components/ConfirmationDialog';
import { CardPage, CardPageBody, CardPageTitleWrap, CardPageHeader, CardPageTitle, CardPageSubhead, CardPageButtonWrap, CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import { getAssetTypes, deleteAssetType, cleanup } from "@/reducers/assetType/view/assetTypeAction";
import MaterialReactTable from "material-react-table";


const AssetTypes = () => {
  
    const dispatch = useDispatch();

    const { 
      assetTypes, isFetching, error,
      isDeletingAssetType, deletedAssetType, deleteAssetTypeError, 
    } = useSelector(({ assetType }) => assetType);

    const [selectedRows, setSelectedRows] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getAssetTypes());
   
        return () => {
          dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
      if(error){
          enqueueSnackbar(error, {variant: "error"});
      }
    }, [error]);

    useEffect(() => {
      if(deleteAssetTypeError){
          enqueueSnackbar(deleteAssetTypeError, {variant: "error"});
      }
    }, [deleteAssetTypeError]);

    const onClickCreateModal = () => {
      setShowCreateModal(true);
    };
    
    const [showCreateModal, setShowCreateModal] = useState(false);
    
    const onHideCreateModal = () => {
      setShowCreateModal(false);
    };

    const onAssetTypeCreated = () => {
      setShowCreateModal(false);
      dispatch(getAssetTypes());
    }
  
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [entityToDelete, setEntityToDelete] = useState(null);

    const handleClickDelete = (assetType) => {
      setEntityToDelete(assetType);
      setOpenConfirmDelete(true);
    }

    const handleConfirmDelete = () => {
      setOpenConfirmDelete(false);
      dispatch(deleteAssetType(entityToDelete.id));
      setEntityToDelete(null);
    } 

    const handleOnCloseDelete = () => {
        setOpenConfirmDelete(false);
        setEntityToDelete(null);
    };

    useEffect(() => {
        if(deletedAssetType){
            enqueueSnackbar("Asset Type deleted successfully", {variant: "success"});
            dispatch(getAssetTypes());
        }
    }, [deletedAssetType]);

    // Update
    const [showAssetTypeEditModal, setShowAssetTypeEditModal] = useState(false);
    const [selectedAssetType, setSelectedAssetType] = useState(null);

    const handleClickEdit = (assetType) => {
      setSelectedAssetType(assetType);
      setShowAssetTypeEditModal(true);
    }

    const onHideAssetTypeEditModal = () => {
      setShowAssetTypeEditModal(false);
      setSelectedAssetType(null);
    };

    const onAssetTypeUpdated = () => {
      setShowAssetTypeEditModal(false);
      setSelectedAssetType(null);
      dispatch(getAssetTypes());
    }

    const columns = [
      { 
        accessorKey: 'nameTraslated',
        header: 'Name'
      },
      { 
        accessorKey: 'descriptionTraslated',
        header: 'Description'
      },
      { 
        accessorKey: 'systemVersionData', 
        header: 'System Version',
        minWidth: 150,
        flex: 0.5,
      },
      { 
        accessorKey: 'isSystem', 
        header: 'System',
        minWidth: 150,
        flex: 0.5,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ cell }) => cell.getValue() ? 
          <DoneIcon sx={{ color: 'text.secondary' }} fontSize="small" /> 
          : 
          <CloseIcon sx={{ color: 'text.secondary' }} fontSize="small" />
      }
    ];

    const renderActionsColumn = ({ row, table }) => {
        console.log(row.original);
        return (
        <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
          { !row.original.isReadOnly &&
            <IconButton
              onClick={() =>{ handleClickEdit(row.original) }}
            >
              <EditIcon />
            </IconButton>
          }
          { !row.original.isSystem && !row.original.isReadOnly &&
            <IconButton
              onClick={() => handleClickDelete(row.original)}
            >
              <DeleteIcon />
            </IconButton>
          }
        </Box>
        )
    };

    return(
      <Container>
        <CardPage>
          <CardPageBody>
              <CardPageHeader>
                <CardPageTitleWrap>
                  <CardPageTitle>Asset Types</CardPageTitle>
                  <CardPageSubhead>
                    Here you can customize your asset types.
                  </CardPageSubhead>
                </CardPageTitleWrap>
                <CardPageButtonWrap>
                  <Button variant="contained" color="primary"  onClick={onClickCreateModal} endIcon={<AddIcon/>}>Add Asset Type</Button>
                </CardPageButtonWrap>
              </CardPageHeader>
              <CardPageGridWrap>
                <MaterialReactTable 
                    data={assetTypes}
                    columns={columns} 
                    initialState={{ 
                      density: 'compact', 
                      pagination: { pageIndex: 0, pageSize: 100 } 
                    }}
                    state={{
                      isLoading: isFetching
                    }}
                    enableRowActions
                    positionActionsColumn="last"
                    renderRowActions={renderActionsColumn}
                    enableExpanding
                    enableExpandAll
                    getSubRows={(originalRow) => originalRow.subRows}
                />
              </CardPageGridWrap>

              { showCreateModal &&
                <CreateAssetTypeModal
                    onHideModal={onHideCreateModal}
                    open={showCreateModal}
                    onAssetTypeCreated={onAssetTypeCreated}
                    assetTypes={assetTypes}
                />
              }

              { showAssetTypeEditModal &&
                <EditAssetTypeModal
                    onHideModal={onHideAssetTypeEditModal}
                    open={showAssetTypeEditModal}
                    onAssetTypeUpdated={onAssetTypeUpdated}
                    assetType={selectedAssetType}
                    assetTypes={assetTypes}
                />
              }

              { openConfirmDelete &&
                  <ConfirmationDialog 
                      open={openConfirmDelete}
                      onConfirm={handleConfirmDelete}
                      onClose={handleOnCloseDelete}
                      title="Delete Asset Type"
                      body={`Are you sure you want to delete ${entityToDelete.name.it}?`}
                      color='error'
                      confirmButtonText='Delete'
                  />
              }

          </CardPageBody>
        </CardPage>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} 
            open={isDeletingAssetType}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    )
}

export default AssetTypes;