import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  addVulnerabilityStatusRequest,
  addVulnerabilityStatusSuccess,
  addVulnerabilityStatusError
} from './addVulnerabilityStatusAction';

const defaultState = {
  isAddingVulnerabilityStatus: false,
  addVulnerabilityStatusError: null,
  addedVulnerabilityStatus: false
};

export default handleActions(
  {
    [addVulnerabilityStatusRequest](state) {
      return {
        ...state,
        isAddingVulnerabilityStatus: true,
        addVulnerabilityStatusError: null,
        addedVulnerabilityStatus: false
      };
    },
    [addVulnerabilityStatusSuccess](state, { payload }) {
      return {
        ...state,
        isAddingVulnerabilityStatus: false,
        addVulnerabilityStatusError: null,
        addedVulnerabilityStatus: true
      };
    },
    [addVulnerabilityStatusError](state, { payload }) {
      return {
        ...state,
        isAddingVulnerabilityStatus: false,
        addVulnerabilityStatusError: payload,
        addedVulnerabilityStatus: false
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);