import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { getFlag, languages } from '../../../../utils/supportedLanguages';
import { Box } from '@mui/material';

const SelectLanguages = ({
    onChange,
    value
}) => {

    const languageValues = Object.values(languages);

    return(
        <Autocomplete
            sx={{
                width: '300px'
            }}
            multiple
            value={value && value.length ? languageValues?.filter(o => value.includes(o.applicationLanguageId)) : value}
            onChange={(event, data) => onChange(data.map(d => d.applicationLanguageId)) }
            options={languageValues}
            getOptionLabel={(option) => option.displayName}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2 } }} {...props}>
                  <img
                    style={{ width: '20px' }}
                    loading="lazy"
                    src={getFlag(40, option.countryCode)}
                    alt={option.countryCode}
                  />
                  {option.displayName}
                </Box>
              )}
            renderInput={(params) => (
            <TextField
                {...params}
                label="Languages"
                inputProps={{
                ...params.inputProps,
                }}
            />
            )}
        />
    );
};

export default SelectLanguages;