import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_LIST_CURRENT_ACTIVITIES');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getPrioritiesRequest = createAction('LIST_CURRENT_ACTIVITIES_GET_PRIORITIES_REQUEST');
export const getPrioritiesSuccess = createAction('LIST_CURRENT_ACTIVITIES_GET_PRIORITIES_SUCCESS');
export const getPrioritiesError = createAction('LIST_CURRENT_ACTIVITIES_GET_PRIORITIES_ERROR');

export const getPriorities = () => async (dispatch) => {
  try {
    dispatch(getPrioritiesRequest());
    const resp = await instance.get(`/v1/Workflow/GetWorkflowActivityPriority`);

    if(resp.data.isSuccessed)
      dispatch(getPrioritiesSuccess(resp.data.data));
    else
      dispatch(getPrioritiesError(resp.data.error));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getPrioritiesError(e.response.data.description));
    else
      dispatch(getPrioritiesError(e.message));
  }
};

export const getCurrentActivitiesRequest = createAction('GET_LIST_CURRENT_ACTIVITIES_REQUEST');
export const getCurrentActivitiesSuccess = createAction('GET_LIST_CURRENT_ACTIVITIES_SUCCESS');
export const getCurrentActivitiesError = createAction('GET_LIST_CURRENT_ACTIVITIES_ERROR');

export const getCurrentActivities = (data) => async (dispatch) => {
  try {
    dispatch(getCurrentActivitiesRequest());
    const resp = await instance.get(`/v1/Workflow/GetActivityByUserId`, { 
      params: { ...data },
      paramsSerializer: {
        indexes: true
      }
    });
    
    if(resp.data.isSuccessed)
      dispatch(getCurrentActivitiesSuccess(resp.data.data));
    else
      dispatch(getCurrentActivitiesError(resp.data.error));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getCurrentActivitiesError(e.response.data.description));
    else
      dispatch(getCurrentActivitiesError(e.message));
  }
};

export const getStepsRequest = createAction('GET_STEPS_CURRENT_ACTIVITIES_REQUEST');
export const getStepsSuccess = createAction('GET_STEPS_CURRENT_ACTIVITIES_SUCCESS');
export const getStepsError = createAction('GET_STEPS_CURRENT_ACTIVITIES_ERROR');

export const getSteps = (workflowActivityId) => async (dispatch) => {
  try {
    dispatch(getStepsRequest({workflowActivityId}));
    const resp = await instance.get(`/v1/Workflow/GetWorkflowActivityStepsCompletedByStatus?WorkflowActivityId=${workflowActivityId}`);
    
    if(resp.data.isSuccessed)
      dispatch(getStepsSuccess({workflowActivityId, data: resp.data.data}));
    else
      dispatch(getStepsError({workflowActivityId, data: resp.data.error}));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getStepsError(e.response.data.description));
    else
      dispatch(getStepsError(e.message));
  }
};

export const getActivityTypesRequest = createAction('CURRENT_ACTIVITIES_GET_ACTIVITY_TYPES_REQUEST');
export const getActivityTypesSuccess = createAction('CURRENT_ACTIVITIES_GET_ACTIVITY_TYPES_SUCCESS');
export const getActivityTypesError = createAction('CURRENT_ACTIVITIES_GET_ACTIVITY_TYPES_ERROR');

export const getActivityTypes = () => async (dispatch) => {
  try {
    dispatch(getActivityTypesRequest());
    const resp = await instance.get(`/v1/ActivityType/GetListPagedByFilter`);
    
    if(resp.data && resp.data.data)
      dispatch(getActivityTypesSuccess(resp.data.data));
    else
      dispatch(getActivityTypesSuccess([]));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getActivityTypesError(e.response.data.description));
    else
      dispatch(getActivityTypesError(e.message));
  }
};

export const getActivityStatusesRequest = createAction('CURRENT_ACTIVITIES_GET_ACTIVITY_STATUSES_REQUEST');
export const getActivityStatusesSuccess = createAction('CURRENT_ACTIVITIES_GET_ACTIVITY_STATUSES_SUCCESS');
export const getActivityStatusesError = createAction('CURRENT_ACTIVITIES_GET_ACTIVITY_STATUSES_ERROR');

export const getActivityStatuses = () => async (dispatch) => {
  try {
    dispatch(getActivityStatusesRequest());
    const resp = await instance.get(`/v1/Workflow/GetWorkflowGenericStatus`);
    
    if(resp.data.isSuccessed)
      dispatch(getActivityStatusesSuccess(resp.data.data));
    else
      dispatch(getActivityStatusesError(resp.data.error));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getActivityStatusesError(e.response.data.description));
    else
      dispatch(getActivityStatusesError(e.message));
  }
};

export const getUsersInActivityRequest = createAction('CURRENT_ACTIVITIES_GET_USERS_IN_ACTIVITY_REQUEST');
export const getUsersInActivitySuccess = createAction('CURRENT_ACTIVITIES_GET_USERS_IN_ACTIVITY_SUCCESS');
export const getUsersInActivityError = createAction('CURRENT_ACTIVITIES_GET_USERS_IN_ACTIVITY_ERROR');

export const getUsersInActivity = () => async (dispatch) => {
  try {
    dispatch(getUsersInActivityRequest());
    const resp = await instance.get(`/v1/Workflow/GetUsersInActivity`);
    
    if(resp.data.isSuccessed)
      dispatch(getUsersInActivitySuccess(resp.data.data));
    else
      dispatch(getUsersInActivityError(resp.data.error));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getUsersInActivityError(e.response.data.description));
    else
      dispatch(getUsersInActivityError(e.message));
  }
};


export const deleteActivityRequest = createAction('CURRENT_ACTIVITIES_DELETE_REQUEST');
export const deleteActivitySuccess = createAction('CURRENT_ACTIVITIES_DELETE_SUCCESS');
export const deleteActivityError = createAction('CURRENT_ACTIVITIES_DELETE_ERROR');

export const deleteActivity = (workflowActivityId) => async (dispatch) => {
  try {
    dispatch(deleteActivityRequest());
    const resp = await instance.delete(`/v1/Workflow/DeleteLogicActivity`, {
      params: {
        workflowActivityId
      }
    });
    
    if(resp.data.isSuccessed)
      dispatch(deleteActivitySuccess(resp.data.data));
    else
      dispatch(getStepsError(resp.data.error));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(deleteActivityError(e.response.data.description));
    else
      dispatch(deleteActivityError(e.message));
  }
};