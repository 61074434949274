import { Box } from "@mui/material";
import CarbonFootprintSurvey from "./customSurveyComponents/carboonFootprint/carbonFootprintSurvey";
import StakeholderInformationSurvey from "./customSurveyComponents/stakeholderInformation/stakeholderInformationSurvey";


const components = {
    "Co2Footprint": CarbonFootprintSurvey,
    "SupplierGeneralInfo": StakeholderInformationSurvey
};

const CustomSurveyComponent = ({
    activitySurvey,
    surveyInfo,
    activitySurveyId,
    isSupervisor,
    workflowActivityStepCompletedId,
    onComplete
}) => {
    const getCustomComponent = (name) => {
        const CustomComponent = components[name];
        return <CustomComponent 
                    activitySurvey={activitySurvey}
                    surveyInfo={surveyInfo}
                    activitySurveyId={activitySurveyId}
                    isSupervisor={isSupervisor}
                    workflowActivityStepCompletedId={workflowActivityStepCompletedId}
                    onComplete={onComplete}
                />
    }

    return (
        <Box>
            { getCustomComponent(surveyInfo.componentPath) }
        </Box>
    );
}

export default CustomSurveyComponent;