import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getAssetTypesRequest,
  getAssetTypesSuccess,
  getAssetTypesError,
  getNodesRequest,
  getNodesSuccess,
  getNodesError,
  addNodesRequest,
  addNodesSuccess,
  addNodesError,
  getNodesForMergeRequest,
  getNodesForMergeSuccess,
  getNodesForMergeError,
  getByTargetRequest,
  getByTargetSuccess,
  getByTargetError,
  getBySourceRequest,
  getBySourceSuccess,
  getBySourceError,
  getByLinkNameRequest,
  getByLinkNameSuccess,
  getByLinkNameError,
  getByLinkValueRequest,
  getByLinkValueSuccess,
  getByLinkValueError,
  getClassificationsRequest,
  getClassificationsSuccess,
  getClassificationsError,
  clearAllAction
} from './cytoscapeAction';

const defaultState = {
  assetTypes: [],
  isLoadingAssetTypes: true,
  getAssetTypesError: null,
  nodes: [],
  isLoadingNodes: false,
  getNodesError: null,
  nodesForAdd: [],
  isLoadingNodesForAdd: false,
  getNodesForAddError: null,
  newNodes: [],
  isLoadingNodesForMerge: false,
  getNodesForMergeError: null,
  classifications: [],
  isLoadingClassifications: true,
  getClassificationsError: null
};

export default handleActions(
  {
    [getAssetTypesRequest](state) {
      return {
        ...state,
        isLoadingAssetTypes: true,
        getAssetTypesError: null,
      };
    },
    [getAssetTypesSuccess](state, { payload }) {
      return {
        ...state,
        assetTypes: payload,
        isLoadingAssetTypes: false,
        getAssetTypesError: null,
      };
    },
    [getAssetTypesError](state, { payload }) {
      return {
        ...state,
        isLoadingAssetTypes: false,
        getAssetTypesError: payload,
      };
    },
    [getClassificationsRequest](state) {
      return {
        ...state,
        isLoadingClassifications: true,
        getClassificationsError: null,
      };
    },
    [getClassificationsSuccess](state, { payload }) {
      return {
        ...state,
        classifications: payload,
        isLoadingClassifications: false,
        getClassificationsError: null,
      };
    },
    [getClassificationsError](state, { payload }) {
      return {
        ...state,
        isLoadingClassifications: false,
        getClassificationsError: payload,
      };
    },
    [getNodesRequest](state) {
      return {
        ...state,
        isLoadingNodes: true,
        getNodesError: null,
      };
    },
    [getNodesSuccess](state, { payload }) {
      return {
        ...state,
        nodes: payload,
        isLoadingNodes: false,
        getNodesError: null,
      };
    },
    [getNodesError](state, { payload }) {
      return {
        ...state,
        isLoadingNodes: false,
        getNodesError: payload,
      };
    },
    [addNodesRequest](state) {
      return {
        ...state,
        isLoadingNodesForAdd: true,
        getNodesForAddError: null,
      };
    },
    [addNodesSuccess](state, { payload }) {
      return {
        ...state,
        nodesForAdd: payload,
        isLoadingNodesForAdd: false,
        getNodesForAddError: null,
      };
    },
    [addNodesError](state, { payload }) {
      return {
        ...state,
        isLoadingNodesForAdd: false,
        getNodesForAddError: payload,
      };
    },
    [getNodesForMergeRequest](state) {
      return {
        ...state,
        isLoadingNodesForMerge: true,
        getNodesForMergeError: null,
      };
    },
    [getNodesForMergeSuccess](state, { payload }) {
      return {
        ...state,
        newNodes: payload,
        isLoadingNodesForMerge: false,
        getNodesForMergeError: null,
      };
    },
    [getNodesForMergeError](state, { payload }) {
      return {
        ...state,
        isLoadingNodesForMerge: false,
        getNodesForMergeError: payload,
      };
    },
    [clearAllAction](state, { payload }){
      return {
        ...state,
        nodes: []
      }
    },
    [getByTargetRequest](state) {
      return {
        ...state,
        isLoadingNodes: true,
        getNodesError: null,
      };
    },
    [getByTargetSuccess](state, { payload }) {
      return {
        ...state,
        nodes: payload,
        isLoadingNodes: false,
        getNodesError: null,
      };
    },
    [getByTargetError](state, { payload }) {
      return {
        ...state,
        isLoadingNodes: false,
        getNodesError: payload,
      };
    },

    [getBySourceRequest](state) {
      return {
        ...state,
        isLoadingNodes: true,
        getNodesError: null,
      };
    },
    [getBySourceSuccess](state, { payload }) {
      return {
        ...state,
        nodes: payload,
        isLoadingNodes: false,
        getNodesError: null,
      };
    },
    [getBySourceError](state, { payload }) {
      return {
        ...state,
        isLoadingNodes: false,
        getNodesError: payload,
      };
    },

    [getByLinkNameRequest](state) {
      return {
        ...state,
        isLoadingNodes: true,
        getNodesError: null,
      };
    },
    [getByLinkNameSuccess](state, { payload }) {
      return {
        ...state,
        nodes: payload,
        isLoadingNodes: false,
        getNodesError: null,
      };
    },
    [getByLinkNameError](state, { payload }) {
      return {
        ...state,
        isLoadingNodes: false,
        getNodesError: payload,
      };
    },

    [getByLinkValueRequest](state) {
      return {
        ...state,
        isLoadingNodes: true,
        getNodesError: null,
      };
    },
    [getByLinkValueSuccess](state, { payload }) {
      return {
        ...state,
        nodes: payload,
        isLoadingNodes: false,
        getNodesError: null,
      };
    },
    [getByLinkValueError](state, { payload }) {
      return {
        ...state,
        isLoadingNodes: false,
        getNodesError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);