import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_STAKEHOLDER_FORM');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getCountriesRequest = createAction('STAKEHOLDER_FORM_GET_COUNTRIES_REQUEST');
export const getCountriesSuccess = createAction('STAKEHOLDER_FORM_GET_COUNTRIES_SUCCESS');
export const getCountriesError = createAction('STAKEHOLDER_FORM_GET_COUNTRIES_ERROR');

export const getCountries = () => async (dispatch) => {
  try {
    dispatch(getCountriesRequest());
    const resp = await instance.get(`/v1/DictionaryCountry/GetAll`);
    
    dispatch(getCountriesSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getCountriesError(e.response.data.description));
    else
      dispatch(getCountriesError(e.message));
  }
};