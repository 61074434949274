import { Backdrop, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAsset, cleanup } from "@/reducers/auditCampaign/updateAsset/AUUpdateAssetAction";
import { useState } from "react";
import SurveySelectCard from "../../SASteps/components/surveySelectCard";
import { Col, Row } from "react-bootstrap";
import { FormSelectField } from '@/shared/components/form/material-controls/SelectField';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const AssetUpdateModal = ({ 
  isEditor,
  auditCampaign,
  asset, 
  activityType,
  surveys,
  roles,
  rolesWithUsers,
  supplier,
  workflowActivityStepCompletedId,
  onHideModal, 
  showModal, 
  onAssetUpdated
}) => {

    const {t} = useTranslation("common");
    const dispatch = useDispatch();
  
    const { 
        isUpdatingAsset, updatedAsset, updateAssetError
    } = useSelector(({ auUpdateAsset }) => auUpdateAsset);

    const startSurveys = asset.assetSurveys
                         ? asset.assetSurveys
                         : [];

    const [selectedSurveys, setSelectedSurveys] = useState(startSurveys);
    const { handleSubmit, reset, control, setValue, getValues } = useForm();

    useEffect(() => {
      return () => {
        dispatch(cleanup());
      }
    }, [dispatch]);
  
    const handleClose = (event, reason) => {
      onHideModal();
    }
  
    useEffect(() => {
      if(updateAssetError){
        enqueueSnackbar(updateAssetError, {variant: "error"});
      }
    }, [updateAssetError]);

    useEffect(() => {
        if(updatedAsset){
          enqueueSnackbar("Asset saved successfully", {variant: "success"});
          onAssetUpdated();
        }
      }, [updatedAsset]);

      const onClickSurvey = (survey) => {
        var clone = [...selectedSurveys];

        const found = clone.some(s => s.surveyId === survey.surveyId);
        if(found){
            const index = clone.indexOf(found);
            clone.splice(index, 1);

            setSelectedSurveys([...clone]);
        }
        else{
            clone.push({
              surveyId: survey.surveyId, 
              libraryId: survey.libraryId
            });

            setSelectedSurveys([...clone]);
        }
    };

    const onSubmit = (data) => {
      const finalData = {
          activityCampaignId: auditCampaign.id,
          assetId: asset.assetId,
          workflowActivityStepCompletedId,
          assetSurveys: selectedSurveys,
          userRoles: data.usersRoles
      }
      dispatch(updateAsset(finalData));
  };

    return(
        <Dialog
            fullWidth={true}
            maxWidth='lg'
            scroll='paper'
            open={showModal}
            onClose={handleClose}
        >
            <DialogTitle>Asset Info</DialogTitle>
            <DialogContent>
              <Row>
                  <Col md={12}>
                    <TextField
                        style={{marginTop: 20}}
                        name='name'
                        label='Name'
                        defaultValue={supplier.name}
                        fullWidth
                        size="small"
                        InputProps={{
                          readOnly: true
                        }}
                    />
                  </Col>
                  <Col md={12}>
                    <TextField
                        style={{marginTop: 20}}
                        name='description'
                        label='Description'
                        defaultValue={supplier.description}
                        fullWidth
                        size="small"
                        multiline
                        rows={4}
                        InputProps={{
                          readOnly: true
                        }}
                    />
                  </Col>

                  <Col md={12} style={{marginTop: 20}}>
                    <Card variant='outline' sx={{border: '1px solid'}}>
                      <CardContent>
                        <Typography>Assign Users</Typography>
                        <Row>
                            {roles.map((item, index) => {
                              const grouppedUsers = rolesWithUsers.find(r => r.roleId === item);
                              
                              return ( 
                                <Col md={4} key={index}>
                                    <FormSelectField
                                        name={`usersRoles.${item}`}
                                        label={`User for role ${grouppedUsers.roleName}`}
                                        rules={{ required: 'This field is required' }} 
                                        control={control}
                                        options={grouppedUsers.users}
                                        keyValue={{label: "fullName", id: "id"}}
                                        defaultValue={asset.userRoles ? asset.userRoles[item] : null}
                                    />
                                </Col>
                            )})
                          }
                        </Row>
                      </CardContent>
                    </Card>
                  </Col>

                  <Divider style={{marginTop: 20}} component='div'/>

                    {surveys.map((s, index) => {
                        return(
                            <Col md={4} style={{marginTop: 20}}>
                                <SurveySelectCard 
                                    disabled={!isEditor}
                                    activityType={activityType}
                                    survey={s}
                                    onClick={onClickSurvey}
                                    isSelected={selectedSurveys.some(su => su.surveyId === s.surveyId)}
                                />
                            </Col>
                        )
                    })}
            </Row>
            
              
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                { isEditor && 
                  <Button 
                      disabled={!selectedSurveys.length}
                      variant="contained" 
                      onClick={handleSubmit(onSubmit)}
                  >
                      {t("app.assignSurvey.saveAndSend")}
                  </Button>
                }
            </DialogActions>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isUpdatingAsset}
            >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Dialog>
    );
};

export default AssetUpdateModal;