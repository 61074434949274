import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getClassificationsRequest,
  getClassificationsSuccess,
  getClassificationsError
} from './organizationAction';

const defaultState = {
  classifications: [],
  isLoadingClassifications: true,
  getClassificationsError: null
};

export default handleActions(
  {
    [getClassificationsRequest](state) {
      return {
        ...state,
        isLoadingClassifications: true,
        getClassificationsError: null,
      };
    },
    [getClassificationsSuccess](state, { payload }) {
      return {
        ...state,
        classifications: payload,
        isLoadingClassifications: false,
        getClassificationsError: null,
      };
    },
    [getClassificationsError](state, { payload }) {
      return {
        ...state,
        isLoadingClassifications: false,
        getClassificationsError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);