import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_EDIT_STEP');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getActivityRequest = createAction('EDIT_STEP_GET_ACTIVITY_REQUEST');
export const getActivitySuccess = createAction('EDIT_STEP_GET_ACTIVITY_SUCCESS');
export const getActivityError = createAction('EDIT_STEP_GET_ACTIVITY_ERROR');

export const getActivity = (id) => async (dispatch) => {
  try {
    dispatch(getActivityRequest());
    const resp = await instance.get(`/v1/Workflow/GetActivity?WorkflowActivityId=${id}`);

    if(resp.data.isSuccessed)
      dispatch(getActivitySuccess(resp.data.data));
    else
      dispatch(getActivityError(resp.data.error));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getActivityError(e.response.data.description));
    else
      dispatch(getActivityError(e.message));
  }
};

export const getActivityStepsRequest = createAction('EDIT_STEP_GET_WORKFLOW_STEPS_REQUEST');
export const getActivityStepsSuccess = createAction('EDIT_STEP_GET_WORKFLOW_STEPS_SUCCESS');
export const getActivityStepsError = createAction('EDIT_STEP_GET_WORKFLOW_STEPS_ERROR');

export const getActivitySteps = (id) => async (dispatch) => {
  try {
    dispatch(getActivityStepsRequest());
    const resp = await instance.get(`/v1/Workflow/GetWorkflowActivityStepsCompletedByStatus?WorkflowActivityId=${id}`);
    
    if(resp.data.isSuccessed)
      dispatch(getActivityStepsSuccess(resp.data.data));
    else
      dispatch(getActivityStepsError(resp.data.error));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getActivityStepsError(e.response.data.description));
    else
      dispatch(getActivityStepsError(e.message));
  }
};

export const getActivityTasksRequest = createAction('EDIT_STEP_GET_WORKFLOW_TASKS_REQUEST');
export const getActivityTasksSuccess = createAction('EDIT_STEP_GET_WORKFLOW_TASKS_SUCCESS');
export const getActivityTasksError = createAction('EDIT_STEP_GET_WORKFLOW_TASKS_ERROR');

export const getActivityTasks = (id) => async (dispatch) => {
  try {
    dispatch(getActivityTasksRequest());
    const resp = await instance.get(`/v1/Workflow/GetTaskActivity?WorkflowActivityId=${id}`);
    
    if(resp.data.isSuccessed)
      dispatch(getActivityTasksSuccess(resp.data.data));
    else
      dispatch(getActivityTasksError(resp.data.error));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getActivityTasksError(e.response.data.description));
    else
      dispatch(getActivityTasksError(e.message));
  }
};

export const getStepProgressRequest = createAction('EDIT_STEP_GET_STEP_PROGRESS_REQUEST');
export const getStepProgressSuccess = createAction('EDIT_STEP_GET_STEP_PROGRESS_SUCCESS');
export const getStepProgressError = createAction('EDIT_STEP_GET_STEP_PROGRESS_ERROR');

export const getStepProgress = (id) => async (dispatch) => {
  try {
    dispatch(getStepProgressRequest({id}));
    const resp = await instance.get(`/v1/Workflow/GetWorkflowActivityStepPercentage?WorkflowActivityStepCompletedId=${id}`);
    
    if(resp.data.isSuccessed)
      dispatch(getStepProgressSuccess({id, data: resp.data.data }));
    else
      dispatch(getStepProgressError({id, data: resp.data.error }));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getStepProgressError({ id, data: e.response.data.description }));
    else
      dispatch(getStepProgressError({ id, data: e.message }));
  }
};

export const nextStatusRequest = createAction('EDIT_STEP_NEXT_STATUS_REQUEST');
export const nextStatusSuccess = createAction('EDIT_STEP_NEXT_STATUS_SUCCESS');
export const nextStatusError = createAction('EDIT_STEP_NEXT_STATUS_ERROR');

export const nextStatus = (data) => async (dispatch) => {
  try {
    dispatch(nextStatusRequest());
    const resp = await instance.post(`/v1/Workflow/NextStatus`, data);
    
    if(resp.data.isSuccessed)
      dispatch(nextStatusSuccess(resp.data.data));
    else
      dispatch(nextStatusError(resp.data.error));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(nextStatusError(e.response.data.description));
    else
      dispatch(nextStatusError(e.message));
  }
};