import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateLogo, cleanup } from "@/reducers/supplier/logoChange/logoChangeAction";
import styled from 'styled-components';
import { DropzoneArea } from "mui-file-dropzone";

const LogoChangeModal = ({
    logo,
    open, 
    onHideModal,
    onUpdateLogo,
    stakeholderId
}) => {
    
    const {
        isUpdatingLogo, updatedLogo, updateLogoError
    } = useSelector(({ stakeholderChangeLogo }) => stakeholderChangeLogo);

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(cleanup());
        }
    }, [dispatch]);
    
    const [uploadedImage, setUploadedImage] = useState(null);

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") 
            return;
        
        onHideModal();
    }

    useEffect(() => {
        if(updateLogoError){
            enqueueSnackbar(updateLogoError, {variant: "error"});
        }
    }, [updateLogoError]);

    const onImageChange = (files) => {
        if(files && files.length){
            setUploadedImage(files[0]);
        }
        else{
            setUploadedImage(null);
        }
    }

    const onSubmit = () => {
        var formData = new FormData();
        formData.append("logo", uploadedImage);
        dispatch(updateLogo(formData, stakeholderId));
    }
    
    useEffect(() => {
        if(updatedLogo){
            enqueueSnackbar("Logo updated successfully", {variant: "success"});
            handleClose();
            onUpdateLogo();
        }
    }, [updatedLogo]);

    return(
        <Dialog
          fullWidth
          maxWidth='md'
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>Edit Avatar</DialogTitle>
          <DialogContent>
            <ProfileAvatarWrapper>
                <ProfileAvatar>
                    {!uploadedImage ? 
                        !logo ?
                        <img src="/img/avatar.svg" alt="avatar"/>
                        :
                        <img src={URL.createObjectURL(logo)} alt="avatar"/>
                        :
                        <img src={URL.createObjectURL(uploadedImage)} alt="avatar"/>
                    }
                </ProfileAvatar>
            </ProfileAvatarWrapper>

            <DropzoneArea 
                clearOnUnmount
                onChange={onImageChange}
                acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                filesLimit={1}
                maxFileSize={5000000}
            />
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button disabled={uploadedImage === null} variant='contained' onClick={onSubmit}>Edit</Button>
          </DialogActions>
  
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isUpdatingLogo}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
      </Dialog>
    );
}

export default LogoChangeModal;

const ProfileAvatarWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const ProfileAvatar = styled.div`
  height: 300px;
  width: auto;
  overflow: hidden;

  img {
    height: 100%;
  }
`;