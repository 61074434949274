import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getAuditCampaignRequest,
  getAuditCampaignSuccess,
  getAuditCampaignError
} from './AUReportAction';

const defaultState = {
  auditCampaign: null, 
  isLoadingAuditCampaign: true, 
  getAuditCampaignError: null
};

export default handleActions(
  {
    [getAuditCampaignRequest](state) {
      return {
        ...state,
        auditCampaign: null,
        isLoadingAuditCampaign: true,
        getAuditCampaignError: null,
      };
    },
    [getAuditCampaignSuccess](state, { payload }) {
      return {
        ...state,
        auditCampaign: payload,
        isLoadingAuditCampaign: false,
        getAuditCampaignError: null,
      };
    },
    [getAuditCampaignError](state, { payload }) {
      return {
        ...state,
        auditCampaign: null,
        isLoadingAuditCampaign: false,
        getAuditCampaignError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);