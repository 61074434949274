import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import HubIcon from '@mui/icons-material/Hub';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import InfoIcon from '@mui/icons-material/Info';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const AssetMenu = ({
    anchorPosition,
    open,
    handleClose,
    assetId,
    viewInfo,
    getAdjacentNodes,
    getAllPath,
    removeNode
}) => {
    return(
        <Menu
        id="asset-menu"
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        open={open}
        onClose={handleClose}
        >
        <MenuItem onClick={() => viewInfo(assetId)}>
            <ListItemIcon>
                <InfoIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>View Info</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => getAdjacentNodes(assetId)}>
            <ListItemIcon>
                <DeviceHubIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Expand Nodes</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => getAllPath(assetId)}>
            <ListItemIcon>
                <HubIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Expand All</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => removeNode(assetId)}>
            <ListItemIcon>
                <VisibilityOffIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Hide</ListItemText>
        </MenuItem>
      </Menu>
    );
};

export default AssetMenu;