import { Box, Button, Card, CardContent, Chip, Divider, List, ListItem, Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReducedActivityItem from "./reducedActivityItem";


const ReducedActivityList = ({
    activities
}) => {

    return(
        <Row>
            <Col md={12}>
                <List>
                    {activities.map((activity, index) => 
                        <ReducedActivityItem activity={activity}/>
                    )}
                </List>
            </Col>
        </Row>
    );
};


export default ReducedActivityList;