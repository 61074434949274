import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_ASSET_VULNERABILITY_LIST');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getRemediationRequest = createAction('ASSET_VULNERABILITY_LIST_GET_REMEDIATION_REQUEST');
export const getRemediationSuccess = createAction('ASSET_VULNERABILITY_LIST_GET_REMEDIATION_SUCCESS');
export const getRemediationError = createAction('ASSET_VULNERABILITY_LIST_GET_REMEDIATION_ERROR');

export const getRemediation = (id) => async (dispatch) => {
  try {
    dispatch(getRemediationRequest());
    const resp = await instance.get(`/v1/Remediation/GetByWorkflowActivityId?WorkflowActivityId=${id}`);
    
    dispatch(getRemediationSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getRemediationError(e.response.data.description));
    else
      dispatch(getRemediationError(e.message));
  }
};


export const calculatePerformanceRequest = createAction('ASSET_VULNERABILITY_LIST_CALCULATE_PERFORMANCE_REQUEST');
export const calculatePerformanceSuccess = createAction('ASSET_VULNERABILITY_LIST_CALCULATE_PERFORMANCE_SUCCESS');
export const calculatePerformanceError = createAction('ASSET_VULNERABILITY_LIST_CALCULATE_PERFORMANCE_ERROR');

export const calculatePerformance = (data) => async (dispatch) => {
  try {
    dispatch(calculatePerformanceRequest());
    const resp = await instance.post(`/v1/AssetVulnerability/CalculatePerformanceRemediation`, data);
    
    dispatch(calculatePerformanceSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(calculatePerformanceError(e.response.data.description));
    else
      dispatch(calculatePerformanceError(e.message));
  }
};

export const getClassificationsRequest = createAction('ASSET_VULNERABILITY_LIST_GET_CLASSIFICATIONS_REQUEST');
export const getClassificationsSuccess = createAction('ASSET_VULNERABILITY_LIST_GET_CLASSIFICATIONS_SUCCESS');
export const getClassificationsError = createAction('ASSET_VULNERABILITY_LIST_GET_CLASSIFICATIONS_ERROR');

export const getClassifications = () => async (dispatch) => {
  try {
    dispatch(getClassificationsRequest());
    const resp = await instance.get(`/v1/Classification/GetAll`);
    
    dispatch(getClassificationsSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getClassificationsError(e.response.data.description));
    else
      dispatch(getClassificationsError(e.message));
  }
};

export const getSchemaRequest = createAction('ASSET_VULNERABILITY_LIST_GET_SCHEMA_REQUEST');
export const getSchemaSuccess = createAction('ASSET_VULNERABILITY_LIST_GET_SCHEMA_SUCCESS');
export const getSchemaError = createAction('ASSET_VULNERABILITY_LIST_GET_SCHEMA_ERROR');

export const getSchema = () => async (dispatch) => {
  try {
    dispatch(getSchemaRequest());
    const resp = await instance.get(`/v1/AssetVulnerability/GetSchema`);
    
    dispatch(getSchemaSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getSchemaError(e.response.data.description));
    else
      dispatch(getSchemaError(e.message));
  }
};

export const getDomainsRequest = createAction('ASSET_VULNERABILITY_LIST_GET_DOMAINS_REQUEST');
export const getDomainsSuccess = createAction('ASSET_VULNERABILITY_LIST_GET_DOMAINS_SUCCESS');
export const getDomainsError = createAction('ASSET_VULNERABILITY_LIST_GET_DOMAINS_ERROR');

export const getDomains = () => async (dispatch) => {
  try {
    dispatch(getDomainsRequest());
    const resp = await instance.get(`/v1/Domain/GetAll`);
    
    dispatch(getDomainsSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getDomainsError(e.response.data.description));
    else
      dispatch(getDomainsError(e.message));
  }
};

export const getRatingScoresRequest = createAction('ASSET_VULNERABILITY_LIST_GET_RATING_SCORES_REQUEST');
export const getRatingScoresSuccess = createAction('ASSET_VULNERABILITY_LIST_GET_RATING_SCORES_SUCCESS');
export const getRatingScoresError = createAction('ASSET_VULNERABILITY_LIST_GET_RATING_SCORES_ERROR');

export const getRatingScores = () => async (dispatch) => {
  try {
    dispatch(getRatingScoresRequest());
    const resp = await instance.get(`/v1/RatingScore/GetByCVSS`);
    
    dispatch(getRatingScoresSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getRatingScoresError(e.response.data.description));
    else
      dispatch(getRatingScoresError(e.message));
  }
};

export const getContextualScoresRequest = createAction('ASSET_VULNERABILITY_LIST_GET_CONTEXTUAL_SCORES_REQUEST');
export const getContextualScoresSuccess = createAction('ASSET_VULNERABILITY_LIST_GET_CONTEXTUAL_SCORES_SUCCESS');
export const getContextualScoresError = createAction('ASSET_VULNERABILITY_LIST_GET_CONTEXTUAL_SCORES_ERROR');

export const getContextualScores = () => async (dispatch) => {
  try {
    dispatch(getContextualScoresRequest());
    const resp = await instance.get(`/v1/RatingScore/GetByContextualScore`);
    
    dispatch(getContextualScoresSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getContextualScoresError(e.response.data.description));
    else
      dispatch(getContextualScoresError(e.message));
  }
};

export const getListForRemediationRequest = createAction('ASSET_VULNERABILITY_LIST_GET_LIST_REMEDIATION_REQUEST');
export const getListForRemediationSuccess = createAction('ASSET_VULNERABILITY_LIST_GET_LIST_REMEDIATION_SUCCESS');
export const getListForRemediationError = createAction('ASSET_VULNERABILITY_LIST_GET_LIST_REMEDIATION_ERROR');

export const getListForRemediation = (data) => async (dispatch) => {
  try {
    dispatch(getListForRemediationRequest());
    const resp = await instance.post(`/v1/AssetVulnerability/GetListForRemediation`, data);

    dispatch(getListForRemediationSuccess(resp.data));    
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getListForRemediationError(e.response.data.description));
    else
      dispatch(getListForRemediationError(e.message));
  }
};

export const addRemediationRequest = createAction('ASSET_VULNERABILITY_LIST_ADD_REMEDIATION_REQUEST');
export const addRemediationSuccess = createAction('ASSET_VULNERABILITY_LIST_ADD_REMEDIATION_SUCCESS');
export const addRemediationError = createAction('ASSET_VULNERABILITY_LIST_ADD_REMEDIATION_ERROR');

export const addRemediation = (data) => async (dispatch) => {
  try {
    dispatch(addRemediationRequest());
    const resp = await instance.post(`/v1/Remediation/Post`, data);

    dispatch(addRemediationSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(addRemediationError(e.response.data.description));
    else
      dispatch(addRemediationError(e.message));
  }
};

export const updateRemediationRequest = createAction('ASSET_VULNERABILITY_LIST_UPDATE_REMEDIATION_REQUEST');
export const updateRemediationSuccess = createAction('ASSET_VULNERABILITY_LIST_UPDATE_REMEDIATION_SUCCESS');
export const updateRemediationError = createAction('ASSET_VULNERABILITY_LIST_UPDATE_REMEDIATION_ERROR');

export const updateRemediation = (data) => async (dispatch) => {
  try {
    dispatch(updateRemediationRequest());
    const resp = await instance.put(`/v1/AssetVulnerability/AddRemediationItems`, data);

    dispatch(updateRemediationSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(updateRemediationError(e.response.data.description));
    else
      dispatch(updateRemediationError(e.message));
  }
};