import { Col, Container, Row } from "react-bootstrap";
import React from 'react';
import DoughnutChart from "@/shared/components/charts/DoughnutChart";
import { CardPage, CardPageBody, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap, CardPageGridWrap, CardPageChartBoxWrap, CardPageChartBoxTitleWrap} from '@/shared/components/MaterialCardPage';

const AssetDashboard = () => {
  return(
    <Container>
        <CardPage>
          <CardPageBody>         
              <CardPageTitleWrap>
                <CardPageTitle>Asset Dashboard</CardPageTitle>
              </CardPageTitleWrap>

              <Row style={{marginTop: 50}}>
                <Col md={12}>
                  <DoughnutChart 
                    endpoint={`GetAssetByType`}
                    height={500} 
                    legend={{
                      orient: 'vertical',
                      left: 'left'
                    }}
                  />
                </Col>
              </Row>
          </CardPageBody>   
        </CardPage>
    </Container>
  );
}

export default AssetDashboard;