import { Card, CardActionArea, CardContent, Checkbox, Chip, Icon, Typography } from "@mui/material";
import CircularProgressWithLabel from '@/shared/components/CircularProgressWithLabel';
import { languages, supportedLanguages, getFlag } from "@/utils/supportedLanguages";

const SurveyInfoCard = ({
    activityType, 
    survey, 
    onClickSurvey,
    showCalculation
}) => {

    const languageCode = supportedLanguages[survey.applicationLanguageId - 1];

    const ratingScores = [
        {
            name: "Very Low",
            value: 1,
            color: "#00B050"
        },
        {
            name: "Low",
            value: 2,
            color: "#92D050"
        },
        {
            name: "Medium",
            value: 3,
            color: "#FFC000"
        },
        {
            name: "High",
            value: 4,
            color: "#FF8D00"
        },
        {
            name: "Critical",
            value: 5,
            color: "#FF0000"
        }
    ];

    const ratingScoreValue = survey.calculation ? survey.calculation.value : 1;
    
    var ratingScore = ratingScores.find(r => r.value === ratingScoreValue);
    if(!ratingScore)
        ratingScore = ratingScores[0];

    return(
        <Card
            onClick={onClickSurvey} 
            sx={{
                height: '100%',
                minHeight: '170px',

                '&:hover': {
                    cursor: 'pointer'
                },
            }}
        >
            <CardActionArea style={{height: '100%'}}>
                <CardContent style={{height: '100%'}}>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", height: '100%'}}>
                        <div style={{display: "flex", justifyContent: 'space-between'}}>
                            <div style={{display: "flex"}}>
                                <Icon style={{marginRight: 10, fontSize: 70}}>{survey.icon}</Icon>
                                <div>
                                    <Typography variant="h5" style={{display: 'flex', alignItems: 'center', fontSize: 24}}>{survey.label}</Typography>
                                    <Typography variant="body1" color="text.secondary" style={{fontSize: 14}}>{survey.description}</Typography>
                                    
                                    <div style={{display: "flex", alignItems: 'center', marginTop: 10}}>
                                        <Chip 
                                            size="small" 
                                            label={`V ${survey.version}`} 
                                        />
                                        {languageCode &&
                                            <img 
                                                style={{height: 12, width: 'auto', marginLeft: 10}}
                                                src={getFlag(40, languages[languageCode]?.countryCode)}
                                                alt={languages[languageCode]?.displayName}
                                            />
                                        }
                                    </div>
                                
                                </div>
                            </div>

                            <div>
                                <CircularProgressWithLabel 
                                    value={survey.percentCompleted * 100} />
                            </div>
                        </div>

                        <div style={{marginTop: 30, display: "flex", justifyContent: 'space-between', width: '100%', alignItems: 'flex-end'}}>
                            <div>
                                <Typography sx={{fontSize: '40px', fontWeight: 'bold', lineHeight: 1, color: activityType.applicationModule.color}}>
                                    {activityType.applicationModule.titleLabel}
                                </Typography>
                                <Typography sx={{fontSize: '20px', fontWeight: 'bold', lineHeight: 1 }}>
                                    SURVEYS
                                </Typography>
                                <Typography sx={{fontSize: '15px', lineHeight: 1 }} color="text.secondary">
                                    {survey.libraryName}
                                </Typography>
                            </div>

                            { showCalculation && 
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    {survey.calculation ? 
                                        <>
                                            <Typography 
                                            style={{
                                                color: ratingScore.color,
                                                fontSize: 40,
                                                fontWeight: 'bold',
                                                marginRight: 10
                                            }}>
                                                {ratingScore.value}
                                            </Typography>
                                            <Typography>
                                                {ratingScore.name}
                                            </Typography>
                                        </>
                                        :
                                        <Typography fontSize={20}>
                                            N / A
                                        </Typography>
                                    }                                    
                                </div>
                            }
                        </div>
                    </div>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default SurveyInfoCard;