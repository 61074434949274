import { Card, CardActionArea, CardContent, Checkbox, Chip, Icon, Typography } from "@mui/material";
import { languages, supportedLanguages, getFlag } from "@/utils/supportedLanguages";

const SurveySelectCard = ({
    activityType, 
    survey, 
    onClick, 
    isSelected,
    disabled
}) => {

    const languageCode = supportedLanguages[survey.applicationLanguageId - 1];

    return(
        <Card
            onClick={() => onClick(survey)} 
            sx={{
                height: '100%',
                minHeight: '170px',

                '&:hover': {
                    cursor: 'pointer'
                },
            }}
        >
            <CardActionArea style={{height: '100%'}} disabled={disabled}>
                <CardContent style={{height: '100%'}}>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", height: '100%'}}>
                        <div style={{display: "flex", justifyContent: 'space-between'}}>
                            <div style={{display: "flex"}}>
                                <Icon style={{marginRight: 10, fontSize: 70}}>{survey.icon}</Icon>
                                <div>
                                    <Typography variant="h5" style={{display: 'flex', alignItems: 'center', fontSize: 24}}>{survey.label}</Typography>
                                    <Typography variant="body1" color="text.secondary" style={{fontSize: 14}}>{survey.description}</Typography>
                                    
                                    <div style={{display: "flex", alignItems: 'center', marginTop: 10}}>
                                        <Chip 
                                            size="small" 
                                            label={`V ${survey.version}`} 
                                        />
                                        {languageCode &&
                                            <img 
                                                style={{height: 12, width: 'auto', marginLeft: 10}}
                                                src={getFlag(40, languages[languageCode]?.countryCode)}
                                                alt={languages[languageCode]?.displayName}
                                            />
                                        }
                                    </div>
                                
                                </div>
                            </div>
                        </div>

                        <div style={{marginTop: 30, display: "flex", justifyContent: 'space-between', width: '100%', alignItems: 'flex-end'}}>
                            <div>
                                <Typography sx={{fontSize: '40px', fontWeight: 'bold', lineHeight: 1, color: activityType.applicationModule.color}}>
                                    {activityType.applicationModule.titleLabel}
                                </Typography>
                                <Typography sx={{fontSize: '20px', fontWeight: 'bold', lineHeight: 1 }}>
                                    SURVEYS
                                </Typography>
                                <Typography sx={{fontSize: '15px', lineHeight: 1 }} color="text.secondary">
                                    {survey.library?.name ?? survey.libraryName}
                                </Typography>
                            </div>

                            <Checkbox 
                                disabled={disabled}
                                onClick={() => onClick(survey)}
                                checked={isSelected}
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 40 } }} 
                            />
                        </div>
                    </div>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default SurveySelectCard;