import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getActivitySurveyRequest,
  getActivitySurveySuccess,
  getActivitySurveyError,
  getLibrariesRequest,
  getLibrariesSuccess,
  getLibrariesError,
  confirmLibrariesRequest,
  confirmLibrariesSuccess,
  confirmLibrariesError
} from './selectLibrariesAction';

const defaultState = {
  activitySurvey: null, 
  isLoadingActivitySurvey: true, 
  getActivitySurveyError: null,
  libraries: [],
  isLoadingLibraries: true,
  getLibrariesError: null,
  isConfirmingLibraries: false, 
  confirmedLibraries: false, 
  confirmLibrariesError: null
};

export default handleActions(
  {
    [getActivitySurveyRequest](state) {
      return {
        ...state,
        activitySurvey: null,
        isLoadingActivitySurvey: true,
        getActivitySurveyError: null,
      };
    },
    [getActivitySurveySuccess](state, { payload }) {
      return {
        ...state,
        activitySurvey: payload,
        isLoadingActivitySurvey: false,
        getActivitySurveyError: null,
      };
    },
    [getActivitySurveyError](state, { payload }) {
      return {
        ...state,
        activitySurvey: null,
        isLoadingActivitySurvey: false,
        getActivitySurveyError: payload,
      };
    },
    [getLibrariesRequest](state) {
      return {
        ...state,
        libraries: [],
        isLoadingLibraries: true,
        getLibrariesError: null,
      };
    },
    [getLibrariesSuccess](state, { payload }) {
      return {
        ...state,
        libraries: payload,
        isLoadingLibraries: false,
        getLibrariesError: null,
      };
    },
    [getLibrariesError](state, { payload }) {
      return {
        ...state,
        libraries: [],
        isLoadingLibraries: false,
        getLibrariesError: payload,
      };
    },
    [confirmLibrariesRequest](state) {
      return {
        ...state,
        isConfirmingLibraries: true,
        confirmLibrariesError: null,
        confirmedLibraries: false
      };
    },
    [confirmLibrariesSuccess](state, { payload }) {
      return {
        ...state,
        isConfirmingLibraries: false,
        confirmLibrariesError: null,
        confirmedLibraries: true
      };
    },
    [confirmLibrariesError](state, { payload }) {
      return {
        ...state,
        isConfirmingLibraries: false,
        confirmLibrariesError: payload,
        confirmedLibraries: false
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);