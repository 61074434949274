import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import styled from 'styled-components';
import { colorAccent, colorText } from '@/utils/palette';
import { left, marginRight } from '@/utils/directions';
import { TopbarBack, TopbarButton, TopbarDownIcon } from './BasicTopbarComponents';
import {
  TopbarCollapse,
  TopbarCollapseContent,
} from './CollapseTopbarComponents';

const gb = `https://flagcdn.com/w20/gb.png`;
const it = `https://flagcdn.com/w20/it.png`;


const GbLng = () => (
  <TopbarLanguageButtonTitle>
    <img src={gb} alt="gb" />
    <span>EN</span>
  </TopbarLanguageButtonTitle>
);

const ItLng = () => (
  <TopbarLanguageButtonTitle>
    <img src={it} alt="it" />
    <span>IT</span>
  </TopbarLanguageButtonTitle>
);

const languages = {
  "it": <ItLng />,
  "en": <GbLng />
};

const TopbarLanguage = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  var currentLanguage = localStorage.getItem("language");
  const [mainButtonContent, setMainButtonContent] = useState(languages[currentLanguage]);

  const toggleLanguage = () => {
    setIsCollapsed(!isCollapsed);
  };

  const changeLanguage = (lng) => {
    setMainButtonContent(languages[lng]);
    localStorage.setItem("language", lng);
    toggleLanguage();
    window.location.reload();
  };

  return (
    <TopbarLanguageCollapse>
      <TopbarButton type="button" onClick={toggleLanguage}>
        {mainButtonContent}
      <TopbarDownIcon />
      </TopbarButton>
      {isCollapsed && (
        <TopbarBack
          type="button"
          aria-label="language button"
          onClick={toggleLanguage}
        />
      )}
      <Collapse
        in={isCollapsed}
      >
        <TopbarLanguageCollapseContent>
          {Object.keys(languages).map(l =>
            <TopbarLanguageButton
              type="button"
              onClick={() => changeLanguage(l)}
            >
              {languages[l]}
            </TopbarLanguageButton>
          )}
        </TopbarLanguageCollapseContent>
      </Collapse>
    </TopbarLanguageCollapse>
  );
};

export default TopbarLanguage;

// region STYLES

const TopbarLanguageCollapse = styled(TopbarCollapse)`
  min-width: 70px;
  display: block;

  & > button {
    padding: 0 4px;
    width: 100%;
  }
`;

const TopbarLanguageCollapseContent = styled(TopbarCollapseContent)`
  max-width: 75px;
  width: 100%;

  @media screen and (max-width: 1024px) {
    ${left}: 0;
  }
`;

const TopbarLanguageButton = styled.button`
  width: 100%;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 13px;
  line-height: 16px;
  transition: 0.3s;
  padding: 4px 5px 4px 15px;
  text-align: ${left};

  &:first-child {
    padding-top: 14px;
  }

  &:last-child {
    padding-bottom: 14px;
  }

  &:hover {
    color: ${colorAccent};
  }
`;

export const TopbarLanguageButtonTitle = styled.span`
  display: flex;
  font-size: 11px;
  align-items: center;
  margin: auto 0;
  color: ${colorText};

  &:not(:last-child) {
    ${marginRight}: 5px;
  }

  img {
    height: 11px;
    width: 16px;
    ${marginRight}: 4px;
  }
`;

// endregion
