import { Button, Card, CardContent, CircularProgress, Typography } from '@mui/material';
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { FormSelectField } from '@/shared/components/form/material-controls/SelectField';
import { FormDateField } from '@/shared/components/form/material-controls/DateField';
import { Col, Row } from 'react-bootstrap';
import dayjs from 'dayjs';

const GeneralInfoFields = ({
    control,
    priorities,
    isLoadingRolesWithUsers,
    rolesWithUsers,
    isLoadingRoles,
    roles,
}) => {
    return(
        <Row>
            <Col md={12}>
                <FormTextField
                    name='description'
                    label='Description'
                    control={control}
                    rules={{ required: 'This field is required' }} 
                    multiline
                    rows={4}
                />
            </Col>
            <Col md={4}>
            <FormSelectField 
                name="workflowActivityPriorityId" 
                label="Priority" 
                control={control} 
                options={priorities}
                keyValue={{id:"workflowActivityPriorityId", label:"name"}}
                rules={{ required: 'This field is required' }} 
            />
            </Col>
            <Col md={4}>
                <FormDateField
                    name='startDate'
                    label='Start Date'
                    control={control}
                    rules={{ required: 'This field is required' }} 
                    defaultValue={dayjs(new Date())}
                />
            </Col>
            <Col md={4}>
                <FormDateField
                    name='endDate'
                    label='End Date'
                    control={control}
                    rules={{ required: 'This field is required' }} 
                />
            </Col>
            <Col md={12} style={{marginBlock: 20}}>
                <Card variant='outline' sx={{border: '1px solid'}}>
                    <CardContent>
                    <Typography>Assign Users</Typography>
                    <Row>
                        { isLoadingRolesWithUsers || !rolesWithUsers || isLoadingRoles || !roles ? <div style={{height: '200px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><CircularProgress /></div> :
                        roles.map((item, index) => {
                            const grouppedUsers = rolesWithUsers.find(r => r.roleId === item);

                            return ( 
                            <Col md={4} key={index}>
                                <FormSelectField
                                    name={`usersRoles.${item}`}
                                    label={`User for role ${grouppedUsers.roleName}`}
                                    control={control}
                                    rules={{ required: 'This field is required' }} 
                                    options={grouppedUsers.users}
                                    keyValue={{label: "fullName", id: "id"}}
                                />
                            </Col>
                        )})
                        }
                    </Row>
                    </CardContent>
                </Card>
            </Col>
        </Row>
    );
};

export default GeneralInfoFields;