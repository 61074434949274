import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
} from 'react-bootstrap';
import { getClassifications, deleteClassification, cleanup } from "@/reducers/classification/classificationsAction";
import { DataGrid, GridActionsCellItem, GridToolbar, gridClasses } from '@mui/x-data-grid';
import { CardPage, CardPageBody, CardPageTitleWrap, CardPageHeader, CardPageTitle, CardPageSubhead, CardPageButtonWrap, CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import { enqueueSnackbar } from 'notistack';
import ExpandableCell from '@/shared/components/datagrid/ExpandableCell';
import ConfirmationDialog from '@/shared/components/ConfirmationDialog';
import CreateClassificationModal from './components/createClassificationModal';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ColorCell from '../../../shared/components/datagrid/ColorCell';
import EditClassificationModal from './components/editClassificationModal';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const Classifications = () => {
  
  const dispatch = useDispatch();
  const { 
    classifications, isLoadingClassifications, getClassificationsError
  } = useSelector(({ classifications }) => classifications);
  
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    dispatch(getClassifications());

    return () => {
      dispatch(cleanup());
    }
  }, [dispatch]);

  const [showClassificationCreateModal, setShowClassificationCreateModal] = useState(false);

  const [showClassificationEditModal, setShowClassificationEditModal] = useState(false);
  const [selectedClassification, setSelectedClassification] = useState(null);

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [classificationToDelete, setClassificationToDelete] = useState(null);

  useEffect(() => {
    if(getClassificationsError){
        enqueueSnackbar(getClassificationsError, {variant: "error"});
    }
  }, [getClassificationsError]);

  
  const handleClickEditClassification = (classification) => {
    setSelectedClassification(classification);
    setShowClassificationEditModal(true);
}

const handleClickDeleteClassification = (classification) => {
    setClassificationToDelete(classification);
    setOpenConfirmDelete(true);
}

const onClickCreateCreateModal = () => {
    setShowClassificationCreateModal(true);
};

const onHideClassificationCreateModal = () => {
    setShowClassificationCreateModal(false);
};

const onHideClassificationEditModal = () => {
    setShowClassificationEditModal(false);
    setSelectedClassification(null);
};

const onClassificationCreated = () => {
    setShowClassificationCreateModal(false);
    dispatch(getClassifications());
}

const onClassificationUpdated = () => {
    setShowClassificationEditModal(false);
    setSelectedClassification(null);
    dispatch(getClassifications());
}

const handleConfirmDelete = () => {
    setOpenConfirmDelete(false);
    dispatch(deleteClassification(classificationToDelete.id));
    setClassificationToDelete(null);
}

const handleOnCloseDelete = () => {
    setOpenConfirmDelete(false);
    setClassificationToDelete(null);
};

  const columns = [
    { 
        field: 'name', 
        headerName: 'Name',
        minWidth: 150,
        flex: 1,
    },
    { 
      field: 'description', 
      headerName: 'Description',
      minWidth: 250,
      renderCell: (params) => <ExpandableCell {...params} maxLength={150} />,
      flex: 1,
    },
    { 
      field: 'value', 
      headerName: 'Value',
      minWidth: 100,
      flex: 0.5,
    },
    { 
      field: 'color', 
      headerName: 'Color',
      minWidth: 150,
      renderCell: (params) => <ColorCell color={params.value} />,
      flex: 0.5,
    },
    { 
      field: 'systemVersionData', 
      headerName: 'System Version',
      minWidth: 150,
      flex: 0.5,
    },
    { 
      field: 'isSystem', 
      headerName: 'System',
      minWidth: 150,
      flex: 0.5,
      type: 'boolean',
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => {
        const actions = [];

        if(!params.row.isSystem && !params.row.isReadOnly){
            actions.push(
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                onClick={() => handleClickEditClassification(params.row)}
              />
            );
        }

        if(!params.row.isReadOnly){
            actions.push(
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => handleClickDeleteClassification(params.row)}
              />
            );
        }

        return actions;
      }
    },
];

  return (
    <Container>
      <CardPage>
        <CardPageBody>
            <CardPageHeader>
              <CardPageTitleWrap>
                <CardPageTitle>Classifications</CardPageTitle>
                <CardPageSubhead>
                  Here you can view your classifications.
                </CardPageSubhead>
              </CardPageTitleWrap>
              <CardPageButtonWrap>
                  <Button 
                      variant="contained" 
                      onClick={onClickCreateCreateModal} 
                      endIcon={<AddIcon/>}
                  >
                      Create Classification
                  </Button>
              </CardPageButtonWrap>
            </CardPageHeader>

            <CardPageGridWrap>
              <DataGrid 
                  autoHeight
                  disableRowSelectionOnClick
                  rowSelectionModel={selectedRows}
                  loading={isLoadingClassifications} 
                  rows={classifications}
                  columns={columns} 
                  slots={{ toolbar: GridToolbar }} 
                  slotProps={{
                      toolbar: {
                      showQuickFilter: true
                      },
                  }}
                  sx={{
                      [`& .${gridClasses.cell}`]: {
                        py: 1,
                      },
                  }}
                  initialState={{
                      pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  pageSizeOptions={[10, 25, 50]}
              />
            
            </CardPageGridWrap>

            { showClassificationCreateModal &&
                <CreateClassificationModal
                    onHideModal={onHideClassificationCreateModal}
                    open={showClassificationCreateModal}
                    onClassificationCreated={onClassificationCreated}
                />
            }

            { showClassificationEditModal &&
                <EditClassificationModal
                    onHideModal={onHideClassificationEditModal}
                    open={showClassificationEditModal}
                    onClassificationUpdated={onClassificationUpdated}
                    classification={selectedClassification}
                />
            }

            { openConfirmDelete &&
                <ConfirmationDialog 
                    open={openConfirmDelete}
                    onConfirm={handleConfirmDelete}
                    onClose={handleOnCloseDelete}
                    title="Delete Classification"
                    body={`Are you sure you want to delete ${classificationToDelete.name}?`}
                    color='error'
                    confirmButtonText='Delete'
                />
            }
        </CardPageBody>
      </CardPage>
    </Container>
  );
};

export default Classifications;