import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_ADD_REMEDIATION');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getRolesRequest = createAction('ADD_REMEDIATION_GET_WORKFLOW_ROLES_REQUEST');
export const getRolesSuccess = createAction('ADD_REMEDIATION_GET_WORKFLOW_ROLES_REQUEST_SUCCESS');
export const getRolesError = createAction('ADD_REMEDIATION_GET_WORKFLOW_ROLES_REQUEST_ERROR');

export const getRoles = () => async (dispatch) => {
  try {
    dispatch(getRolesRequest());
    const resp = await instance.get(`/v1/Workflow/GetRoles`);
    
    dispatch(getRolesSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getRolesError(e.response.data.description));
    else
      dispatch(getRolesError(e.message));
  }
};

export const addRemediationRequest = createAction('ADD_REMEDIATION_REQUEST');
export const addRemediationSuccess = createAction('ADD_REMEDIATION_SUCCESS');
export const addRemediationError = createAction('ADD_REMEDIATION_ERROR');

export const addRemediation = (data) => async (dispatch) => {
  try {
    dispatch(addRemediationRequest());
    const resp = await instance.post(`/v1/Remediation/Post`, data);

    dispatch(addRemediationSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(addRemediationError(e.response.data.description));
    else
      dispatch(addRemediationError(e.message));
  }
};