import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getVulnerabilitiesRequest,
  getVulnerabilitiesSuccess,
  getVulnerabilitiesError,
  getRatingScoresRequest,
  getRatingScoresSuccess,
  getRatingScoresError,
} from './vulnerabilitiesAction';

const defaultState = {
  vulnerabilities: [],
  isLoadingVulnerabilities: true,
  getVulnerabilitiesError: null,
  ratingScores: [],
  isLoadingRatingScores: true,
  getRatingScoresError: null,
};

export default handleActions(
  {
    [getVulnerabilitiesRequest](state) {
      return {
        ...state,
        vulnerabilities: [],
        isLoadingVulnerabilities: true,
        getVulnerabilitiesError: null,
      };
    },
    [getVulnerabilitiesSuccess](state, { payload }) {
      return {
        ...state,
        vulnerabilities: payload,
        isLoadingVulnerabilities: false,
        getVulnerabilitiesError: null,
      };
    },
    [getVulnerabilitiesError](state, { payload }) {
      return {
        ...state,
        vulnerabilities: [],
        isLoadingVulnerabilities: false,
        getVulnerabilitiesError: payload,
      };
    },
    [getRatingScoresRequest](state) {
      return {
        ...state,
        isLoadingRatingScores: true,
        getRatingScoresError: null,
      };
    },
    [getRatingScoresSuccess](state, { payload }) {
      return {
        ...state,
        ratingScores: payload,
        isLoadingRatingScores: false,
        getRatingScoresError: null,
      };
    },
    [getRatingScoresError](state, { payload }) {
      return {
        ...state,
        isLoadingRatingScores: false,
        getRatingScoresError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);