import ReactECharts from 'echarts-for-react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { instance } from "../../../config/interceptor";
import { enqueueSnackbar } from 'notistack';
import { Card, CardContent } from '@mui/material';

const MultipleLinesChart = ({ endpoint, chartSeries, height = 300, ...other }) => {
    const {theme} = useSelector(({changeTheme}) => changeTheme);

    const baseOptions = {
        legend: { },
        tooltip: {
            trigger: 'axis',
            showContent: false
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: []
            }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        series: [],
        ...other
    }

    const [isLoading, setIsLoading] = useState(!chartSeries);
    const [data, setData] = useState(chartSeries);
    const [apiError, setApiError] = useState(null);
    const [options, setOptions] = useState(baseOptions);

    useEffect(() => {
        if(endpoint){
            async function fetchData(){
                try {
                    setIsLoading(true);
                    const resp = await instance.get("/v1/Dashboard/" + endpoint);
                    setIsLoading(false);
                    setData(resp.data);
                }
                catch(e) {
                    if(e.response && e.response.data && e.response.data.description)
                        setApiError(e.response.data.description);
                    else
                        setApiError(e.message);
                }
            }
            
            fetchData();
        }
    }, [endpoint]);

    useEffect(() => {
        if(data){
            setOptions({
                legend: { },
                tooltip: {
                    trigger: 'axis',
                    showContent: false
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        data: data.axis
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                series: data.series.map(s => {
                    return {
                        name: s.groupName,
                        data: s.values,
                        itemStyle: { color: s.color === null || s.color === "" ? undefined : s.color },
                        type: 'line',
                        smooth: true,
                        seriesLayoutBy: 'row',
                        emphasis: { focus: 'series' }
                    };
                }),
                ...other
            });
        }
    }, [data]);

    useEffect(() => {
        if(apiError){
            enqueueSnackbar(apiError, {variant: "error"});
        }
    }, [apiError]);

    return(
        <Card>
            <CardContent>
                <ReactECharts
                    theme={theme}
                    style={{height: height}}
                    notMerge={true}
                    option={options}
                    showLoading={isLoading}
                    loadingOption={{
                        text: "",
                        color: '#3D5AFE',
                        maskColor: 'rgba(0, 0, 0, 0.5)',
                    }}
                />
            </CardContent>
        </Card>
    );
}

export default MultipleLinesChart;