import { Backdrop, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { cleanup, getRoles, getUser, updateUser } from "@/reducers/supplier/stakeholderUsers/updateUser/stakeholderUpdateUserAction";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { CardPage, CardPageFilterWrapper, CardPageBody, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap,CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import { FormMultiSelectField } from "@/shared/components/form/material-controls/MultiSelectField";
import { FormTextField } from "@/shared/components/form/material-controls/TextField";
import { FormDateField } from "@/shared/components/form/material-controls/DateField";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import { useForm } from "react-hook-form";
import { IsValidEmail } from "@/utils/formValidations";
import dayjs from "dayjs";

const StakeholderUpdateUser = () => {
    const { id, userId } = useParams();

    const dispatch = useDispatch();
    const { 
        user, isLoadingUser, getUserError,
        isUpdatingUser, updatedUser, updateUserError
    } = useSelector(({ stakeholderUpdateUser }) => stakeholderUpdateUser);

    const navigate = useNavigate();
    const { handleSubmit, reset, control, setValue } = useForm();

    useEffect(() => {
        dispatch(getUser(id));

        return () => {
          dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
        if(getUserError){
            enqueueSnackbar(getUserError, {variant: "error"});
        }
    }, [getUserError]);
    
    useEffect(() => {
        if(updateUserError){
            enqueueSnackbar(updateUserError, {variant: "error"});
        }
    }, [updateUserError]);

    useEffect(() => {
        if(updatedUser){
          enqueueSnackbar(`User updated successfuly.`, {variant: "success"});
        }
    }, [updatedUser]);

    const onSubmit = (data) => {
        data.id = id;
        dispatch(updateUser(data));
    }

    const onClickCancel = () => {
        navigate("/Settings/TenantUsers");
    }

    return(
        <Container>
            <CardPage>
                <CardPageBody>
                    <CardPageTitleWrap>
                        <CardPageTitle>Update User</CardPageTitle>
                    </CardPageTitleWrap>
                    { isLoadingUser ? <CircularProgressWrapper /> : 
                        <>
                            <Row>
                                <Col md={6}>
                                    <FormTextField
                                        name="email" 
                                        label="Email" 
                                        control={control}
                                        rules={{ 
                                            required: 'This field is required',
                                            validate: {
                                                matchPattern: (email) => IsValidEmail(email) ||
                                                "Email address must be a valid address"
                                            }
                                        }}
                                        disabled={true}
                                        defaultValue={user?.email}
                                    />
                                </Col>
                                <Col md={6}>
                                    <FormTextField 
                                        name="userName" 
                                        label="User Name" 
                                        control={control} 
                                        rules={{ required: 'This field is required' }}
                                        disabled={true}
                                        defaultValue={user?.userName}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormTextField 
                                        name="firstName" 
                                        label="First Name" 
                                        control={control} 
                                        rules={{ required: 'This field is required' }}
                                        defaultValue={user?.applicationUserProfile?.firstName}
                                    />
                                </Col>
                                <Col md={6}>
                                    <FormTextField 
                                        name="lastName" 
                                        label="Last Name" 
                                        control={control} 
                                        rules={{ required: 'This field is required' }}
                                        defaultValue={user?.applicationUserProfile?.lastName}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormTextField 
                                        name="birthPlace" 
                                        label="Birth Place" 
                                        control={control} 
                                        defaultValue={user?.applicationUserProfile?.birthPlace}
                                    />
                                </Col>
                                <Col md={6}>
                                    <FormDateField 
                                        name="birthDate" 
                                        label="Birthday" 
                                        control={control} 
                                        defaultValue={user?.applicationUserProfile?.birthDate ? dayjs(user?.applicationUserProfile?.birthDate) : undefined}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormTextField 
                                        name="fiscalCode" 
                                        label="Fiscal Code" 
                                        control={control}
                                        defaultValue={user?.applicationUserProfile?.fiscalCode}
                                    />
                                </Col>
                                <Col md={6}>
                                    <FormTextField 
                                        name="phoneNumber" 
                                        label="Phone Number"
                                        control={control}
                                        type="number"
                                        defaultValue={user?.applicationUserProfile?.phoneNumber}
                                    />
                                </Col>
                            </Row>

                            <Row style={{marginTop: 20}}>
                                <Col md={12}>
                                    <Grid
                                        container
                                        justifyContent="flex-end"
                                        spacing={2}
                                    >
                                        <Grid item>
                                            <Button
                                                onClick={onClickCancel}
                                                variant="outlined"
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                onClick={handleSubmit(onSubmit)}
                                                variant="contained"
                                            >
                                                Update
                                            </Button>
                                        </Grid>
                                        
                                    </Grid>
                                </Col>
                            </Row>
                        </>
                    }
                </CardPageBody>
            </CardPage>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isUpdatingUser}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}

export default StakeholderUpdateUser;