import { Backdrop, Box, Button, CircularProgress } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { CardPage, CardPageBody, CardPageHeader, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap, CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import { Col, Container, Row } from "react-bootstrap";
import { DataGrid, GridActionsCellItem, GridToolbar, gridClasses } from "@mui/x-data-grid";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmationDialog from '@/shared/components/ConfirmationDialog';
import CreateLocationModal from "./createLocationModal";
import { getCountries, getLocations, deleteLocation, cleanup } from "@/reducers/supplier/locations/locationAction";
import EditLocationModal from "./editLocationModal";

const SupplierLocationsForm = ({
    assetId
}) => {
    
    const dispatch = useDispatch();
    const { 
        locations, isLoadingLocations, getLocationsError,
        isDeletingLocation, deletedLocation, deleteLocationError,
        countries, isLoadingCountries, getCountriesError
    } = useSelector(({ locations }) => locations);

    const [showLocationCreateModal, setShowLocationCreateModal] = useState(false);

    const [showLocationEditModal, setShowLocationEditModal] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(null);

    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [locationToDelete, setLocationToDelete] = useState(null);

    useEffect(() => {
        dispatch(getLocations(assetId));
        dispatch(getCountries());
    
        return () => {
          dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
        if(getLocationsError){
            enqueueSnackbar(getLocationsError, {variant: "error"});
        }
    }, [getLocationsError]);

    useEffect(() => {
        if(getCountriesError){
            enqueueSnackbar(getCountriesError, {variant: "error"});
        }
    }, [getCountriesError]);

    useEffect(() => {
        if(deleteLocationError){
            enqueueSnackbar(deleteLocationError, {variant: "error"});
        }
    }, [deleteLocationError]);

    const handleClickEditLocation = (location) => {
        setSelectedLocation(location);
        setShowLocationEditModal(true);
    }

    const handleClickDeleteLocation = (location) => {
        setLocationToDelete(location);
        setOpenConfirmDelete(true);
    }

    const onClickCreateCreateModal = () => {
        setShowLocationCreateModal(true);
    };

    const onHideLocationCreateModal = () => {
        setShowLocationCreateModal(false);
    };

    const onHideLocationEditModal = () => {
        setShowLocationEditModal(false);
        setSelectedLocation(null);
    };

    const onLocationCreated = () => {
        setShowLocationCreateModal(false);
        dispatch(getLocations(assetId));
    }
    
    const onLocationUpdated = () => {
        setShowLocationEditModal(false);
        setSelectedLocation(null);
        dispatch(getLocations(assetId));
    }

    const handleConfirmDelete = () => {
        setOpenConfirmDelete(false);
        dispatch(deleteLocation(locationToDelete.id));
        setLocationToDelete(null);
    }

    const handleOnCloseDelete = () => {
        setOpenConfirmDelete(false);
        setLocationToDelete(null);
    };

    useEffect(() => {
        if(deletedLocation){
            enqueueSnackbar("Location deleted successfully", {variant: "success"});
            dispatch(getLocations(assetId));
        }
    }, [deletedLocation]);

    const columns = [
        { 
            field: 'name', 
            headerName: 'Name',
            minWidth: 200,
            flex: 1,
        },
        { 
            field: 'description', 
            headerName: 'Description', 
            minWidth: 300,
            flex: 1,
        },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => {
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        onClick={() => handleClickEditLocation(params.row)}
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={() => handleClickDeleteLocation(params.row)}
                    />
                ];
            },
          },
      ];

    return(
        <Row>
            <Col md={12}>
                <Box sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flex-end',
                    marginBottom: 2
                }}>
                    <Button variant="contained" color="primary"  onClick={onClickCreateCreateModal} endIcon={<AddIcon/>}>Create Location</Button>
                </Box>

                <CardPageGridWrap>
                    <DataGrid 
                        autoHeight
                        density="compact"
                        disableRowSelectionOnClick
                        loading={isLoadingLocations} 
                        rows={locations}
                        columns={columns} 
                        slots={{ toolbar: GridToolbar }} 
                        slotProps={{
                            toolbar: {
                            showQuickFilter: true
                            },
                        }}
                        sx={{
                            [`& .${gridClasses.cell}`]: {
                            py: 1,
                            },
                        }}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        pageSizeOptions={[10, 25, 50]}
                    />
                </CardPageGridWrap>

                { showLocationCreateModal &&
                    <CreateLocationModal
                        assetId={assetId}
                        countries={countries}
                        onHideModal={onHideLocationCreateModal}
                        open={showLocationCreateModal}
                        onLocationCreated={onLocationCreated}
                    />
                }

                { showLocationEditModal &&
                    <EditLocationModal
                        assetId={assetId}
                        countries={countries}
                        onHideModal={onHideLocationEditModal}
                        open={showLocationEditModal}
                        onLocationUpdated={onLocationUpdated}
                        location={selectedLocation}
                    />
                }

                { openConfirmDelete &&
                    <ConfirmationDialog 
                        open={openConfirmDelete}
                        onConfirm={handleConfirmDelete}
                        onClose={handleOnCloseDelete}
                        title="Delete Location"
                        body={`Are you sure you want to delete ${locationToDelete.name}?`}
                        color='error'
                        confirmButtonText='Delete'
                    />
                }

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isDeletingLocation}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Col>
        </Row>
    )
}

export default SupplierLocationsForm;