import { Backdrop, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { cleanup, updateDomain } from "@/reducers/domains/updateDomain/updateDomainAction";
import { FormMultiSelectField } from '@/shared/components/form/material-controls/MultiSelectField';
import { FormSelectField } from '@/shared/components/form/material-controls/SelectField';

const EditDomainModal = ({ open, onHideModal, onDomainUpdated, domain, domains, roles }) => {
    const dispatch = useDispatch();
    const { 
      isUpdatingDomain, updateDomainError, updatedDomain
    } = useSelector(({ updateDomain }) => updateDomain);

    useEffect(() => {
      return () => {
        dispatch(cleanup());
      }
    }, [dispatch]);
  
    const { handleSubmit, reset, control, setValue, getValues } = useForm();
  
    const handleClose = () => {
      reset();
      onHideModal();
    }
  
    useEffect(() => {
      if(updateDomainError){
        enqueueSnackbar(updateDomainError, {variant: "error"});
      }
    }, [updateDomainError]);
  
    const onSubmit = (data) => {
      console.log(data);
      const finalData = {
        id: domain.id,
        name: data.name,
        description: { it: data.descriptionTraslated, en: data.descriptionTraslated},
        key: data.key,
        value: data.value,
        parent: data.parent,
        roles: data.roles
      };
      
      console.log(finalData);
      dispatch(updateDomain(finalData));
    }
  
    useEffect(() => {
      if(updatedDomain){
        enqueueSnackbar("Domain updated successfully", {variant: "success"});
        handleClose();
        onDomainUpdated();
      }
    }, [updatedDomain]);

    const mapDomains = () => {
      return domains.filter(d => d.id !== domain.id);
    }
  
    return (
      <Dialog
          fullWidth={true}
          maxWidth='lg'
          scroll='paper'
          open={open}
          onClose={handleClose}
      >
          <DialogTitle>Edit Vulnerability Status</DialogTitle>
          <DialogContent>
          <Row>
              <Col md={12}>
                <FormTextField
                    name='name'
                    label='Name'
                    control={control}
                    rules={{ required: 'This field is required' }}
                    defaultValue={domain.name}
                />
              </Col>
              <Col md={12}>
                <FormTextField
                    name='descriptionTraslated'
                    label='Description'
                    control={control}
                    multiline
                    rows={4}
                    defaultValue={domain.descriptionTraslated}
                />
              </Col>
              <Col md={6}>
              <FormTextField
                  name='key'
                  label='Key'
                  control={control}
                  rules={{ required: 'This field is required' }}
                  defaultValue={domain.key}
                />
              </Col>   
              <Col md={6}>
                <FormTextField
                    name='value'
                    label='Value'
                    control={control}
                    rules={{ required: 'This field is required' }}
                    defaultValue={domain.value}
                />
              </Col>
              <Col md={6}>
                <FormSelectField
                    name='parent'
                    label='Parent'
                    control={control}
                    options={mapDomains()}
                    keyValue={{id:"id", label:"name"}}
                    defaultValue={domain.parent}
                />
              </Col>
              <Col md={6}>
                <FormMultiSelectField
                    name='roles'
                    label='Roles'
                    control={control}
                    options={roles}
                    keyValue={{id:"id", label:"name"}}
                    defaultValue={domain.roles}
                />
              </Col>
            </Row>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant='contained' color="success" onClick={handleSubmit(onSubmit)}>Edit</Button>
          </DialogActions>
  
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isUpdatingDomain}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
      </Dialog>
    );
  }
  
  export default EditDomainModal;