import { Backdrop, Button, CircularProgress, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridToolbar, gridClasses } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { cleanup, getDomains, deleteDomain } from "@/reducers/domains/domainsAction";
import { Col, Container, Row } from "react-bootstrap";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { enqueueSnackbar } from "notistack";
import CreateDomainModal from "./components/createDomainModal";
import EditDomainModal from "./components/editDomainModal";
import AddIcon from '@mui/icons-material/Add';
import ConfirmationDialog from '@/shared/components/ConfirmationDialog';
import { CardPage, CardPageFilterWrapper, CardPageHeader, CardPageBody, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap,CardPageGridWrap } from '@/shared/components/MaterialCardPage';

const Domains = () => {

    const dispatch = useDispatch();
    const { 
        domains, isLoadingDomains, getDomainsError,
        isDeletingDomain, deletedDomain, deleteDomainError,
    } = useSelector(({ domains }) => domains);

    const [showDomainCreateModal, setShowDomainCreateModal] = useState(false);

    const [showDomainEditModal, setShowDomainEditModal] = useState(false);
    const [selectedDomain, setSelectedDomain] = useState(null);

    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [domainToDelete, setDomainToDelete] = useState(null);

    const roles = [
        { id: 1, name: "Role1"},
        { id: 2, name: "Role2"},
        { id: 3, name: "Role3"},
        { id: 4, name: "Role4"},
        { id: 5, name: "Role5"},
        { id: 6, name: "Role6"}
    ]

    useEffect(() => {
        dispatch(getDomains());
    
        return () => {
          dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
        if(getDomainsError){
            enqueueSnackbar(getDomainsError, {variant: "error"});
        }
    }, [getDomainsError]);

    useEffect(() => {
        if(deleteDomainError){
            enqueueSnackbar(deleteDomainError, {variant: "error"});
        }
    }, [deleteDomainError]);

    const handleClickEditDomain = (domain) => {
        setSelectedDomain(domain);
        setShowDomainEditModal(true);
    }

    const handleClickDeleteDomain = (domain) => {
        setDomainToDelete(domain);
        setOpenConfirmDelete(true);
    }

    const onClickCreateCreateModal = () => {
        setShowDomainCreateModal(true);
    };

    const onHideDomainCreateModal = () => {
        setShowDomainCreateModal(false);
    };

    const onHideDomainEditModal = () => {
        setShowDomainEditModal(false);
        setSelectedDomain(null);
    };

    const onDomainCreated = () => {
        setShowDomainCreateModal(false);
        dispatch(getDomains());
    }
    
    const onDomainUpdated = () => {
        setShowDomainEditModal(false);
        setSelectedDomain(null);
        dispatch(getDomains());
    }

    const handleConfirmDelete = () => {
        setOpenConfirmDelete(false);
        dispatch(deleteDomain(domainToDelete.id));
        setDomainToDelete(null);
    }

    const handleOnCloseDelete = () => {
        setOpenConfirmDelete(false);
        setDomainToDelete(null);
    };

    useEffect(() => {
        if(deletedDomain){
            enqueueSnackbar("Domain deleted successfully", {variant: "success"});
            dispatch(getDomains());
        }
    }, [deletedDomain]);

    const columns = [
        { 
            field: 'name', 
            headerName: 'Name',
            flex: 0.5,
            minWidth: 200
        },
        { 
            field: 'descriptionTraslated', 
            headerName: 'Description',
            flex: 0.5,
            minWidth: 200
        },
        { 
            field: 'key', 
            headerName: 'Key',
            flex: 1,
            minWidth: 350
        },
        { 
            field: 'value', 
            headerName: 'Value',
            flex: 0.5,
            minWidth: 200
        },
        { 
            field: 'progress', 
            headerName: 'Progress',
            minWidth: 80
        },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    onClick={() => handleClickEditDomain(params.row)}
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={() => handleClickDeleteDomain(params.row)}
                />
            ]
          },
      ];

    return(
        <Container>
            <CardPage>
                <CardPageBody>
                    <CardPageHeader>
                        <CardPageTitleWrap>
                            <CardPageTitle>Domains</CardPageTitle>
                            <CardPageSubhead>
                                View and manage your domains.
                            </CardPageSubhead>
                        </CardPageTitleWrap>
                        <CardPageButtonWrap>
                            <Button 
                                variant="contained" 
                                onClick={onClickCreateCreateModal} 
                                endIcon={<AddIcon/>}
                            >
                                Create Domain
                            </Button>
                        </CardPageButtonWrap>
                    </CardPageHeader>

                    <CardPageGridWrap>
                        <DataGrid 
                            autoHeight
                            density="compact"
                            disableRowSelectionOnClick
                            loading={isLoadingDomains} 
                            rows={domains}
                            columns={columns} 
                            slots={{ toolbar: GridToolbar }} 
                            slotProps={{
                                toolbar: {
                                showQuickFilter: true
                                },
                            }}
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                py: 1,
                                },
                            }}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 30 } },
                            }}
                            pageSizeOptions={[15, 30, 50, 100]}
                        />
                    </CardPageGridWrap>

                    { showDomainCreateModal &&
                        <CreateDomainModal
                            onHideModal={onHideDomainCreateModal}
                            open={showDomainCreateModal}
                            onDomainCreated={onDomainCreated}
                            domains={domains}
                            roles={roles}
                        />
                    }

                    { showDomainEditModal &&
                        <EditDomainModal
                            onHideModal={onHideDomainEditModal}
                            open={showDomainEditModal}
                            onDomainUpdated={onDomainUpdated}
                            domain={selectedDomain}
                            domains={domains}
                            roles={roles}
                        />
                    }

                    { openConfirmDelete &&
                        <ConfirmationDialog 
                            open={openConfirmDelete}
                            onConfirm={handleConfirmDelete}
                            onClose={handleOnCloseDelete}
                            title="Delete Domain"
                            body={`Are you sure you want to delete ${domainToDelete.nameTraslated}?`}
                            color='error'
                            confirmButtonText='Delete'
                        />
                    }

                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isDeletingDomain}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>            
                </CardPageBody>
            </CardPage>
        </Container>
    )
}

export default Domains;