import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_ADD_SUPPLIER');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const addSupplierRequest = createAction('ADD_SUPPLIER_POST_REQUEST');
export const addSupplierSuccess = createAction('ADD_SUPPLIER_POST_SUCCESS');
export const addSupplierError = createAction('ADD_SUPPLIER_POST_ERROR');

export const addSupplier = (request) => async (dispatch) => {
  try {
    dispatch(addSupplierRequest());
    const resp = await instance.post(`/v1/Stakeholder/Post`, request);

    dispatch(addSupplierSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(addSupplierError(e.response.data.description));
    else
      dispatch(addSupplierError(e.message));
  }
};


export const getStakeholderTypesRequest = createAction('ADD_SUPPLIER_GET_STAKEHOLDER_TYPES_REQUEST');
export const getStakeholderTypesSuccess = createAction('ADD_SUPPLIER_GET_STAKEHOLDER_TYPES_SUCCESS');
export const getStakeholderTypesError = createAction('ADD_SUPPLIER_GET_STAKEHOLDER_TYPES_ERROR');

export const getStakeholderTypes = () => async (dispatch) => {
  try {
    dispatch(getStakeholderTypesRequest());
    const resp = await instance.get(`/v1/StakeholderType/GetAll`);

    dispatch(getStakeholderTypesSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getStakeholderTypesError(e.response.data.description));
    else
      dispatch(getStakeholderTypesError(e.message));
  }
};