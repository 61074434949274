import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import LogIn from "../containers/AccountMaterialLayout/LogIn";
import ForgotPassword from "../containers/AccountMaterialLayout/ForgotPassword";
import ResetPassword from "../containers/AccountMaterialLayout/RessetPassword";
import LoginWith2fa from "../containers/AccountMaterialLayout/LoginWith2fa";
// import LogIn from "../containers/Account/LogIn";

const ProtectedRoutes = (props) => {
  const location = useLocation();

  return (
    <React.Fragment>
      {props.isLoggedIn ? (
        props.children
      ) : (
        <Routes>
          <Route path='*' element={<Navigate to={`/Account/Login?redirectUrl=${location.pathname}`} />} />
          <Route path='/Account/Login' element={<LogIn />} />
          <Route path='/Account/ForgotPassword' element={<ForgotPassword />} />
          <Route path='/Account/ResetPassword' element={<ResetPassword />} />
          <Route path='/Account/LoginWith2fa/:email' element={<LoginWith2fa />} />
        </Routes>
      )}
    </React.Fragment>
  );
};
const mapStateToProps = ({auth}) => ({
  isLoggedIn: auth.isLoggedIn,
  token: auth.token,
});

export default connect(mapStateToProps, null)(ProtectedRoutes);
