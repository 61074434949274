import { useNavigate } from "react-router-dom";
import { Button, Card, CardContent, Typography, CircularProgress, Menu, MenuItem, TextField, Box, Icon } from "@mui/material";
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import React, { useState } from 'react';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import WarningIcon from '@mui/icons-material/Warning';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FlagIcon from '@mui/icons-material/Flag';
import NotesIcon from '@mui/icons-material/Notes';
import dayjs from 'dayjs';
import ActivityMenu from "./ActivityMenu";
import styled from "styled-components";
import StepList from "./StepList";
import OutputIcon from '@mui/icons-material/Output';
import ActivityChildren from "./ActivityChildren";
import { useSelector } from "react-redux";

const ActivityCard = ({
    activity, 
    handleActivityEditClick,
    handleActivityDeleteClick,
    activitySteps,
    loadSteps,
    showOptions,
}) => {
    const navigate = useNavigate();

    const { 
        user
      } = useSelector(({ userInfo }) => userInfo);

    const [showTasks, setShowTasks] = useState(false);
    const [touchedTasks, setTouchedTasks] = useState(activitySteps.steps);
    
    const handleClickLoadTasks = () => {
        setShowTasks(!showTasks);
        
        if(!touchedTasks){
            setTouchedTasks(true);
            loadSteps(activity.workflowActivityId);
        }
    }

    const handleClickEdit = () => {
        navigate("/Activities/EditStep/" + activity.workflowActivityId);
    }

    var dateString = "";
    if(activity.startDate)
        dateString += dayjs(activity.startDate).format("DD MMM YY");

    if(activity.endDate)
        dateString += " - " + dayjs(activity.endDate).format("DD MMM YY");

    const editorNames = [];
    if(activity.editors && activity.editors.length){
        activity.editors.forEach((e) => {
            if(e.id !== user.id){
                editorNames.push(e.firstName + " " + e.lastName);
            }
        });
    }

    return (
            <Card sx={{height: '100%', bgcolor: 'cardBackground2.main'}}>
                <CardContentWrapper>
                    <ColorLine style={{backgroundColor: activity.activityType.applicationModule.color}}/>
                    <Box sx={{
                        height: '100%', 
                        display: 'flex', 
                        flexDirection: 'column', 
                        width: '100%',
                        padding: 2
                    }}>
                            <TitleContainer>
                                <div>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <Typography style={{fontSize: 22}}>{activity.activityType.label}</Typography>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <Chip 
                                            label={activity.workflowActivityStatus.workflowGenericStatus.label} 
                                            size="small" 
                                            sx={{
                                                backgroundColor: activity.workflowActivityStatus.workflowGenericStatus.color, 
                                                fontSize: '12px',
                                                height: '20px',
                                                marginRight: 1
                                            }} 
                                        />
                                        <Typography 
                                            noWrap
                                            color='text.secondary' 
                                            sx={{
                                                fontSize: 14
                                            }}>
                                                {dateString}
                                        </Typography>
                                    </Box>
                                </div>

                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    { activity.isExpired && 
                                        <Icon 
                                            color="error" 
                                            sx={{
                                                marginLeft: 1
                                            }}>
                                                running_with_errors
                                        </Icon>
                                    }
                                    { activity.canEdit && 
                                        <Icon 
                                            color="success" 
                                            sx={{
                                                marginLeft: 1
                                            }}>
                                                edit
                                        </Icon>
                                    }
                                    { activity.workflowActivityChildren && activity.workflowActivityChildren.length && 
                                        <ActivityChildren 
                                            activity={activity}
                                        />
                                    }

                                    { showOptions &&
                                        <ActivityMenu 
                                            activity={activity}
                                            handleClickEditActivity={handleActivityEditClick}
                                            handleActivityDeleteClick={handleActivityDeleteClick}
                                        />
                                    }
                                </Box>

                            </TitleContainer>
                            { editorNames.length > 0 &&
                                <Box>
                                    <Typography noWrap color="text.secondary">[{editorNames.join(", ")}]</Typography>
                                </Box>
                            }
                            <Box sx={{
                                    width: '100%',
                                    height: 80,
                                    overflowY: 'auto',
                                    paddingInline: 1,
                                    marginBlock: 1,
                                    borderLeft: (theme) => `1px solid ${theme.palette.text.disabled}`
                                }}>
                                    <Typography color="text.secondary" fontSize={14}>{activity.description}</Typography>
                            </Box>
                            <Box sx={{
                                display: "flex",
                                width: '100%', 
                                justifyContent: 'space-between',
                                alignItems: "center",
                                marginBottom: 1
                            }}>
                                <Chip 
                                    label={activity.workflowActivityPriority.name} 
                                    size="small" 
                                    sx={{
                                        backgroundColor: activity.workflowActivityPriority.color, 
                                        fontSize: '12px',
                                        height: '20px'
                                    }} 
                                />
                                
                                <Button 
                                    style={{minWidth: 'auto'}}
                                    variant="contained"
                                    endIcon={<OutputIcon />}
                                    onClick={handleClickEdit}
                                >
                                    Enter
                                </Button>
                            </Box>

                            <List component="nav" disablePadding>
                                <ListItemButton onClick={handleClickLoadTasks} disableGutters>
                                    <ListItemText primary="Tasks" />
                                    {showTasks ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>

                                <StepList 
                                    activity={activity}
                                    activitySteps={activitySteps}
                                    handleClickEdit={handleClickEdit}
                                    touchedTasks={touchedTasks}
                                    showTasks={showTasks}
                                />
                            </List>
                    </Box>
                </CardContentWrapper>
            </Card>
    )
}

const TitleContainer = styled.div`
display: flex;
align-items: flex-start;
justify-content: space-between;
`;

const CardContentWrapper = styled.div`
display: flex;
width: 100%;
height: 100%;
`;

const ColorLine = styled.div`
padding-inline: 2px;
`;

export default ActivityCard;