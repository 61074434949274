import { Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Typography } from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getActivities, cleanup } from "@/reducers/activity/listCreate/listCreateAction";
import { enqueueSnackbar } from "notistack";
import { CardPage, CardPageBody, CardPageHeader, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap, CardPageGridWrap } from '@/shared/components/MaterialCardPage';

const ListCreateActivity = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { 
        activities, isLoadingActivities, getActivitiesError
    } = useSelector(({ listCreateActivity }) => listCreateActivity);

    useEffect(() => {
        dispatch(getActivities());

        return () => {
            dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
        if(getActivitiesError){
          enqueueSnackbar(getActivitiesError, {variant: "error"});
        }
    }, [getActivitiesError]);
    
    const handleClickCreate = (activityTypeId) => {
        navigate("/Activities/Create/" + activityTypeId);
    }
    return (
        <Container>
            <CardPage>
                <CardPageBody>         
                    <CardPageHeader>
                        <CardPageTitleWrap>
                            <CardPageTitle>Create Activity</CardPageTitle>
                        </CardPageTitleWrap>
                    </CardPageHeader>

                    <Row>
                        {isLoadingActivities || !activities ? <div style={{height: '400px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><CircularProgress /></div> : 
                        activities.map((activity, index) => (
                            <Col md={6} style={{marginTop: 30}} key={index}>
                                <Card style={{height: '100%'}}>
                                    <CardContent style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                                        <Typography variant='h5' sx={{marginBottom: "20px"}}>{activity.label}</Typography>
                                        <Typography>{activity.description}</Typography>

                                        <div style={{height: '100%', display: 'flex', alignItems: 'flex-end',}}>
                                            <div style={{display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'flex-end', marginTop: 30}}>
                                                <Typography sx={{fontSize: '100px', fontWeight: 'bold', lineHeight: 0.8, color: activity.applicationModule.color}}>
                                                    {activity.applicationModule.titleLabel}
                                                </Typography>
                                                <Button variant="contained" endIcon={<AddIcon/>} onClick={() => handleClickCreate(activity.activityTypeId.toString())}>Start</Button>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </CardPageBody>
            </CardPage>
        </Container>
    )
}

export default ListCreateActivity;