import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  updateActivityRequest,
  updateActivitySuccess,
  updateActivityError,
  getRolesWithUsersRequest,
  getRolesWithUsersSuccess,
  getRolesWithUsersError
} from './editActivityInfoAction';

const defaultState = {
  isUpdatingActivity: false,
  updateActivityError: null,
  updatedActivity: false,
  rolesWithUsers: null,
  isLoadingRolesWithUsers: true,
  getRolesWithUsersError: null
};

export default handleActions(
  {
    [updateActivityRequest](state) {
      return {
        ...state,
        isUpdatingActivity: true,
        updateActivityError: null,
        updatedActivity: false
      };
    },
    [updateActivitySuccess](state, { payload }) {
      return {
        ...state,
        isUpdatingActivity: false,
        updateActivityError: null,
        updatedActivity: true
      };
    },
    [updateActivityError](state, { payload }) {
      return {
        ...state,
        isUpdatingActivity: false,
        updateActivityError: payload,
        updatedActivity: false
      };
    },
    [getRolesWithUsersRequest](state) {
      return {
        ...state,
        isLoadingRolesWithUsers: true,
        getRolesWithUsersError: null,
      };
    },
    [getRolesWithUsersSuccess](state, { payload }) {
      return {
        ...state,
        rolesWithUsers: payload,
        isLoadingRolesWithUsers: false,
        getRolesWithUsersError: null,
      };
    },
    [getRolesWithUsersError](state, { payload }) {
      return {
        ...state,
        isLoadingRolesWithUsers: false,
        getRolesWithUsersError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);