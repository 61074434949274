import { Col, Container, Row } from "react-bootstrap";
import { CardPage, CardPageBody, CardPageTitleWrap, CardPageTitle, CardPageGridWrap} from '@/shared/components/MaterialCardPage';
import DoughnutChart from "@/shared/components/charts/DoughnutChart";
import { useState } from "react";
import { getDashboardFilterYears } from "@/utils/dates";
import { Autocomplete, TextField } from "@mui/material";
import ActivityCounts from "../../Home/components/activityCounts";

const SelfAssessmentDashboard = () => {

    const years = getDashboardFilterYears();
    const [selectedYear, setSelectedYear] = useState(years[years.length - 1]);

    return(
        <Container>
            <CardPage>
                <CardPageBody>
                <CardPageTitleWrap>
                    <CardPageTitle>Self Assesment Dashboard</CardPageTitle>
                </CardPageTitleWrap>
                    <Row style={{marginBottom: '16px'}}>
                        <Col md={12}>
                            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                <Autocomplete
                                    style={{ width: 100 }}
                                    disablePortal
                                    disableClearable
                                    options={years}
                                    value={selectedYear}
                                    onChange={(e, data) => setSelectedYear(data)}
                                    renderInput={(params) => 
                                        <TextField 
                                            {...params}
                                            size="small"
                                            label="Year"
                                        />
                                    }
                                />
                            </div>
                        </Col>  
                    </Row>
                    
                    <ActivityCounts year={selectedYear} activityTypeId={3} />

                    <Row style={{marginTop: 30}}>
                        <Col md={12}>
                            <DoughnutChart 
                                endpoint={`GetSelfAssessmentRisk?year=${selectedYear}`}
                                height={500} 
                                legend={{
                                    orient: 'vertical',
                                    left: 'left'
                                }}
                                title={{
                                    text: "Surveys Risk",
                                    left: 'center'
                                }}
                                tooltip={{
                                    trigger: 'item',
                                    axisPointer: {
                                      type: 'cross'
                                    },
                                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                    hideDelay: 2000,
                                    position: function (pos, params, dom, rect, size) {
                                        if((pos[0] < size.viewSize[0] / 2)){
                                            return "left";
                                        }
                                        else{
                                            return "right";
                                        }
                                    },
                                    enterable: true,
                                    formatter: function (params, ticket, callback) {
                                        const content = params.data.items.join(', ');
                                        return `
                                            <div><strong>${params.name}: </strong>${params.value}</div>
                                            </br>
                                            <div style="max-width: 350px; max-height: 200px; overflow-y: auto; white-space: wrap;">
                                                <strong>Surveys: </strong>${content}
                                            </div>
                                        `;
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                </CardPageBody>
            </CardPage>
        </Container>
    );
}

export default SelfAssessmentDashboard;