import { Controller } from "react-hook-form";
import { Checkbox, FormControlLabel } from "@mui/material";

export const FormCheckboxField = ({ name, control, label, rules, style, defaultValue=false, ...other }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({
        field: { onChange, value, ...field },
        fieldState: { error },
        formState,
      }) => (
        <FormControlLabel
            label={label}
            style={style}
            control={<Checkbox 
                {...field}
                required={rules && rules.required}
                onChange={onChange}
                checked={value}
                {...other}
            />}
        />
      )}
    />
  );
};