import { Box, CircularProgress, Typography } from "@mui/material";

const PerformancePercent = ({value}) => {
    return(
        <Box sx={{ 
            position: 'relative',
            display: 'inline-flex'
        }}>
            <CircularProgress  size={70} variant="determinate" value={value} />
            <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary" fontWeight="bold" fontSize="14px">
                    {`${Math.round(value)}%`}
                </Typography>
            </Box>
        </Box>
    );
};

export default PerformancePercent;