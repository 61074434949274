import { createAction } from 'redux-actions';
import { instance } from "../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_HOME_DASHBOARD');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const countCreatedRequest = createAction('HOME_DASHBOARD_COUNT_CREATED_REQUEST');
export const countCreatedSuccess = createAction('HOME_DASHBOARD_COUNT_CREATED_SUCCESS');
export const countCreatedError = createAction('HOME_DASHBOARD_COUNT_CREATED_ERROR');

export const getCountCreated = (year, activityTypeId) => async (dispatch) => {
  try {
    var url = `/v1/Workflow/CountActivitiesCreated?year=${year}`;
    if(activityTypeId)
      url += `&activityTypeId=${activityTypeId}`;

    dispatch(countCreatedRequest());
    const resp = await instance.get(url);
    
    dispatch(countCreatedSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(countCreatedError(e.response.data.description));
    else
      dispatch(countCreatedError(e.message));
  }
};

export const countToStartRequest = createAction('HOME_DASHBOARD_COUNT_TO_START_REQUEST');
export const countToStartSuccess = createAction('HOME_DASHBOARD_COUNT_TO_START_SUCCESS');
export const countToStartError = createAction('HOME_DASHBOARD_COUNT_TO_START_ERROR');

export const getCountToStart = (year, activityTypeId) => async (dispatch) => {
  try {
    var url = `/v1/Workflow/CountActivitiesToStart?year=${year}`;
    if(activityTypeId)
      url += `&activityTypeId=${activityTypeId}`;

    dispatch(countToStartRequest());
    const resp = await instance.get(url);
    
    dispatch(countToStartSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(countToStartError(e.response.data.description));
    else
      dispatch(countToStartError(e.message));
  }
};

export const countProcessingRequest = createAction('HOME_DASHBOARD_COUNT_PROCESSING_REQUEST');
export const countProcessingSuccess = createAction('HOME_DASHBOARD_COUNT_PROCESSING_SUCCESS');
export const countProcessingError = createAction('HOME_DASHBOARD_COUNT_PROCESSING_ERROR');

export const getCountProcessing = (year, activityTypeId) => async (dispatch) => {
  try {
    var url = `/v1/Workflow/CountActivitiesProcessing?year=${year}`;
    if(activityTypeId)
      url += `&activityTypeId=${activityTypeId}`;

    dispatch(countProcessingRequest());
    const resp = await instance.get(url);
    
    dispatch(countProcessingSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(countProcessingError(e.response.data.description));
    else
      dispatch(countProcessingError(e.message));
  }
};

export const countClosedRequest = createAction('HOME_DASHBOARD_COUNT_CLOSED_REQUEST');
export const countClosedSuccess = createAction('HOME_DASHBOARD_COUNT_CLOSED_SUCCESS');
export const countClosedError = createAction('HOME_DASHBOARD_COUNT_CLOSED_ERROR');

export const getCountClosed = (year, activityTypeId) => async (dispatch) => {
  try {
    var url = `/v1/Workflow/CountActivitiesClosed?year=${year}`;
    if(activityTypeId)
      url += `&activityTypeId=${activityTypeId}`;

    dispatch(countClosedRequest());
    const resp = await instance.get(url);
    
    dispatch(countClosedSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(countClosedError(e.response.data.description));
    else
      dispatch(countClosedError(e.message));
  }
};


export const countArchivedRequest = createAction('HOME_DASHBOARD_COUNT_ARCHIVED_REQUEST');
export const countArchivedSuccess = createAction('HOME_DASHBOARD_COUNT_ARCHIVED_SUCCESS');
export const countArchivedError = createAction('HOME_DASHBOARD_COUNT_ARCHIVED_ERROR');

export const getCountArchived = (year, activityTypeId) => async (dispatch) => {
  try {
    var url = `/v1/Workflow/CountActivitiesArchived?year=${year}`;
    if(activityTypeId)
      url += `&activityTypeId=${activityTypeId}`;
    
    dispatch(countArchivedRequest());
    const resp = await instance.get(url);
    
    dispatch(countArchivedSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(countArchivedError(e.response.data.description));
    else
      dispatch(countArchivedError(e.message));
  }
};


export const countExpiredRequest = createAction('HOME_DASHBOARD_COUNT_EXPIRED_REQUEST');
export const countExpiredSuccess = createAction('HOME_DASHBOARD_COUNT_EXPIRED_SUCCESS');
export const countExpiredError = createAction('HOME_DASHBOARD_COUNT_EXPIRED_ERROR');

export const getCountExpired = (data) => async (dispatch) => {
  try {
    dispatch(countExpiredRequest());
    const resp = await instance.get(`/v1/Workflow/CountActivitiesExpired`, { 
      params: { ...data },
      paramsSerializer: {
        indexes: true
      }
    });
    
    dispatch(countExpiredSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(countExpiredError(e.response.data.description));
    else
      dispatch(countExpiredError(e.message));
  }
};

export const getCurrentActivitiesRequest = createAction('GET_HOME_ACTIVITIES_REQUEST');
export const getCurrentActivitiesSuccess = createAction('GET_HOME_ACTIVITIES_SUCCESS');
export const getCurrentActivitiesError = createAction('GET_HOME_ACTIVITIES_ERROR');

export const getCurrentActivities = (data) => async (dispatch) => {
  try {
    dispatch(getCurrentActivitiesRequest());
    const resp = await instance.get(`/v1/Workflow/GetActivityByUserId`, { 
      params: { ...data },
      paramsSerializer: {
        indexes: true
      }
    });
    
    if(resp.data.isSuccessed)
      dispatch(getCurrentActivitiesSuccess(resp.data.data));
    else
      dispatch(getCurrentActivitiesError(resp.data.error));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getCurrentActivitiesError(e.response.data.description));
    else
      dispatch(getCurrentActivitiesError(e.message));
  }
};