import { DataGrid, GridActionsCellItem, GridToolbar, gridClasses } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { cleanup, getLogs } from "@/reducers/system/logs/logsAction";
import { Col, Container, Row } from "react-bootstrap";
import { enqueueSnackbar } from "notistack";
import { CardPage, CardPageHeader, CardPageBody, CardPageTitleWrap, CardPageFilterWrapper, CardPageTitle, CardPageSubhead, CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import { useMemo } from "react";
import ExpandableCell from '@/shared/components/datagrid/ExpandableCell';
import dayjs from "dayjs";
import { SearchFilters } from "@/shared/components/SearchFilters";
import { Grid } from "@mui/material";
import { FormDateField } from '@/shared/components/form/material-controls/DateField';

const Logs = () => {
    const dispatch = useDispatch();
    const { 
        logs, logsTotalCount, isLoadingLogs, getLogsError
    } = useSelector(({ logs }) => logs);

    const [selectedRows, setSelectedRows] = useState([]);
    const [searchFilters, setSearchFilters] = useState({ pageSize: 30, page: 1 });

    useEffect(() => {
        return () => {
          dispatch(cleanup());
        }
    }, [dispatch]);

    
    useEffect(() => {
        if(getLogsError){
            enqueueSnackbar(getLogsError, {variant: "error"});
        }
    }, [getLogsError]);

    useEffect(() => {
        dispatch(getLogs(searchFilters));
    }, [searchFilters]);

    const setPaginationModel = (model) => {
        setSearchFilters({ 
            ...searchFilters, 
            page: model.page + 1,
            pageSize: model.pageSize
        });
    };

    const onFilterSubmit = (data) => {
        setSearchFilters({ ...searchFilters, page: 1, ...data});
    }

    const columns = [
        { 
            field: 'date', 
            headerName: 'Date', 
            type: 'date',
            minWidth: 200,
            flex: 0.5,
            valueFormatter: params => params.value ? dayjs(params.value).format("L LTS") : ""
        },
        { 
            field: 'message', 
            headerName: 'Message',
            minWidth: 350,
            flex: 1,
            renderCell: (params) => <ExpandableCell {...params} maxLength={150} />,
        },
        { 
            field: 'level', 
            headerName: 'Level', 
            minWidth: 200,
            flex: 0.5
        },
        { 
            field: 'ip', 
            headerName: 'IP', 
            minWidth: 200,
            flex: 0.5
        },
        { 
            field: 'exception', 
            headerName: 'Exception', 
            minWidth: 350,
            flex: 1,
            renderCell: (params) => <ExpandableCell {...params} maxLength={150} />,
        },
        { 
            field: 'module', 
            headerName: 'Module', 
            minWidth: 300,
            flex: 1
        },
        { 
            field: 'callSite', 
            headerName: 'Call Site', 
            minWidth: 300,
            flex: 1
        },
        { 
            field: 'userId', 
            headerName: 'User Id', 
            minWidth: 300,
            flex: 1
        },
        { 
            field: 'tenantId', 
            headerName: 'Tenant Id', 
            minWidth: 300,
            flex: 1
        },
      ];

    return(
        <Container>
            <CardPage>
                <CardPageBody>
                    <CardPageHeader>
                        <CardPageTitleWrap>
                            <CardPageTitle>Logs</CardPageTitle>
                            <CardPageSubhead>
                                Here you can view all the Logs.
                            </CardPageSubhead>
                        </CardPageTitleWrap>
                    </CardPageHeader>

                    
                    <CardPageFilterWrapper>                        
                        <SearchFilters 
                            searchLabel='Search'
                            onFilterSubmit={onFilterSubmit}
                            renderChildrenInputs={(control) => {
                                return(
                                <>
                                    <Grid item xs={12} md={3}>
                                        <FormDateField
                                            style={{margin: 0}}
                                            name="startDate"
                                            label="Start Date"
                                            control={control}
                                        />                                
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormDateField
                                            style={{margin: 0}}
                                            name="endDate"
                                            label="End Date"
                                            control={control}
                                        />
                                    </Grid>
                                </>
                                );
                            }}
                        />
                    </CardPageFilterWrapper>

                    <CardPageGridWrap>
                        <DataGrid 
                        autoHeight
                        density="compact"
                        disableRowSelectionOnClick
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setSelectedRows(newRowSelectionModel);
                        }}
                        rowSelectionModel={selectedRows}
                        loading={isLoadingLogs} 
                        rows={logs}
                        getRowId={(row) => row.logId}
                        getRowHeight={() => 'auto'}
                        getEstimatedRowHeight={() => 59}
                        columns={columns} 
                        slots={{ toolbar: GridToolbar }}
                        sx={{
                            [`& .${gridClasses.cell}`]: {
                            py: 1,
                            },
                        }}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 30 } },
                        }}
                        pageSizeOptions={[15, 30, 50, 100]}
                        rowCount={logsTotalCount}
                        paginationModel={{
                            page: searchFilters.page - 1, 
                            pageSize: searchFilters.pageSize}}
                        paginationMode="server"
                        onPaginationModelChange={setPaginationModel}
                        />
                    </CardPageGridWrap>
                </CardPageBody>
            </CardPage>
        </Container>
    )
}

export default Logs;