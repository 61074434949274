import { Backdrop, Box, Button, CircularProgress, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { cleanup, getLibraries, download } from "@/reducers/libraries/librariesAction";
import { Col, Container, Row } from "react-bootstrap";
import { enqueueSnackbar } from "notistack";
import ConfirmationDialog from '@/shared/components/ConfirmationDialog';
import { CardPage, CardPageBody, CardPageHeader, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap, CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import LibraryCard from "./components/libraryCard";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import DownloadIcon from '@mui/icons-material/Download';
import { useNavigate } from "react-router-dom";
import { FormMultiSelectField } from '@/shared/components/form/material-controls/MultiSelectField';
import SelectLanguages from "./components/selectLanguages";

const Libraries = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { 
        libraries, isLoadingLibraries, getLibrariesError,
        isDownloading, downloaded, downloadError,
    } = useSelector(({ libraries }) => libraries);

    const [selectedLibraries, setSelectedLibraries] = useState([]);
    const [openConfirmDownload, setOpenConfirmDownload] = useState(false);

    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [filteredLibraries, setFilteredLibraries] = useState([]);

    useEffect(() => {
        dispatch(getLibraries());
    
        return () => {
          dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
        if(getLibrariesError){
            enqueueSnackbar(getLibrariesError, {variant: "error"});
        }
    }, [getLibrariesError]);

    useEffect(() => {
        if(downloadError){
            enqueueSnackbar(downloadError, {variant: "error"});
        }
    }, [downloadError]);

    useEffect(() => {
        if(libraries && libraries.length){
            filterLibraries(selectedLanguages);
        }
    }, [libraries]);

    const onClickLibrary = (library) => {
        var clone = [...selectedLibraries];

        if(clone.includes(library.libraryId)){
            const index = clone.indexOf(library.libraryId);
            clone.splice(index, 1);

            setSelectedLibraries([...clone]);
        }
        else{
            clone.push(library.libraryId);
            setSelectedLibraries([...clone]);
        }
    };
    
    const handleConfirmDownload = () => {
        setOpenConfirmDownload(false);

        dispatch(download(selectedLibraries));
    }

    const handleOnCloseDownload = () => {
        setOpenConfirmDownload(false);
    };

    const onClickDownload = () => {
        setOpenConfirmDownload(true);
    };

    useEffect(() => {
        if(downloaded){
            enqueueSnackbar("Libraries downloaded successfully", {variant: "success"});
            setSelectedLibraries([]);
        }
    }, [downloaded]);

    const onChangeLanguage = (newLanguages) => {
        setSelectedLanguages(newLanguages);
        filterLibraries(newLanguages);        
    };

    const filterLibraries = (languages) => {
        if(languages.length){
            const librariesClon = [...libraries];
            const filtered = librariesClon.filter(l => languages.some(sl => sl === l.applicationLanguage.applicationLanguageId));
            console.log(filtered);
            setFilteredLibraries([...filtered]);
        }
        else {
            setFilteredLibraries([...libraries]);
        }
    };

    return(
        <Container>
            <CardPage>
                <CardPageBody>
                    <CardPageHeader>
                        <CardPageTitleWrap>
                            <CardPageTitle>Libraries</CardPageTitle>
                            <CardPageSubhead>
                                Here you can view and import new libraries.
                            </CardPageSubhead>
                        </CardPageTitleWrap>
                        <CardPageButtonWrap>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-end'
                            }}>
                                <Button
                                    sx={{mb: 1}} 
                                    variant="contained" 
                                    color="primary"  
                                    onClick={onClickDownload} 
                                    disabled={selectedLibraries.length === 0}
                                    endIcon={<DownloadIcon/>}>
                                        Download ({selectedLibraries.length})
                                </Button>

                                <SelectLanguages 
                                    value={selectedLanguages} 
                                    onChange={onChangeLanguage}
                                />
                            </Box>
                        </CardPageButtonWrap>
                    </CardPageHeader>

                    <Row style={{marginTop: 10}}>
                        { isLoadingLibraries ? <CircularProgressWrapper /> :              
                            <>
                                
                            
                                {filteredLibraries.map((l, index) => {
                                    return(
                                        <Col md={4} style={{marginBottom: 30}}>
                                            <LibraryCard 
                                                library={l}
                                                onClick={onClickLibrary}
                                                isSelected={selectedLibraries.includes(l.libraryId)}
                                            />
                                        </Col>
                                    )
                                })}
                            </>
                        }
                    </Row>

                    { openConfirmDownload &&
                        <ConfirmationDialog 
                            open={openConfirmDownload}
                            onConfirm={handleConfirmDownload}
                            onClose={handleOnCloseDownload}
                            title="Download Libraries"
                            body={`Are you sure you want to download ${selectedLibraries.length} libraries?`}
                            color='primary'
                            confirmButtonText='Download'
                        />
                    }
                    
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isDownloading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </CardPageBody>
            </CardPage>
        </Container>
    )
}

export default Libraries;