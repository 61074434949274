import { Box, Card, CardActionArea, CardContent, Icon, Typography } from "@mui/material";

const NumberBlock = ({
    title,
    color,
    icon,
    value,
    onClick,
    selected
}) => {

    var cardProperties = {};

    if(selected)
        cardProperties.borderBottom = (theme) => '3px solid ' + theme.palette.primary.main;

    return(
        <Card sx={cardProperties}>
            <CardActionArea 
                sx={{height: '100%', padding: 1}} 
                onClick={onClick}
            >
                <Typography sx={{
                    fontSize: '14px',
                    marginBottom: 1,
                }}>{title}</Typography>

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    
                }}>
                    <Icon sx={{
                        fontSize: '30px',
                        marginRight: 1
                    }}>{icon}</Icon>

                    <Typography sx={{
                        fontSize: '40px',
                        color: {color},
                        lineHeight: 1,
                        fontWeight: 'bold'
                    }}>{value}</Typography>
                </Box>
            </CardActionArea>
        </Card>
    );
}

export default NumberBlock;