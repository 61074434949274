import { Controller } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Checkbox, createFilterOptions } from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export const FormMultiSelectField = ({ 
  name, 
  control, 
  label, 
  rules, 
  limitTags,
  defaultValue=[], 
  keyValue={id:"id", label:"name"}, 
  checkboxes=false,
  options, 
  ...other 
}) => {
  
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({
        field: { onChange, ref, ...field },
        fieldState: { error }
      }) => (
        <Autocomplete
            multiple
            options={options}
            limitTags={limitTags}
            defaultValue={options?.filter(o => defaultValue?.includes(o[keyValue.id]))}
            getOptionLabel={(option) => option[keyValue.label]}
            onChange={(e, data) => onChange(data.map(d => d[keyValue.id]))}
            renderInput={(params) =>
                <TextField 
                    {...params}
                    {...field }
                    required={rules && rules.required}
                    style={{'marginTop': 20}}
                    helperText={error ? error.message : null}
                    error={!!error}
                    fullWidth
                    inputRef={ref}
                    label={label}
                    variant="outlined"
                    size='small'
                    {...other}
                />
            }
            disableCloseOnSelect={checkboxes}
            renderOption={ checkboxes ? (props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={ <CheckBoxOutlineBlankIcon fontSize="small" /> }
                  checkedIcon={ <CheckBoxIcon fontSize="small" /> }
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option[keyValue.label]}
              </li>
            ) : undefined}
        />
      )}
    />
  );
};