import { Col, Container, Row } from "react-bootstrap";
import React from 'react';
import ReactECharts from 'echarts-for-react';
import { useSelector } from "react-redux";
import MultipleLinesChart from "@/shared/components/charts/MultipleLinesChart";
import StackedBarsChart from "@/shared/components/charts/StackedBarsChart";
import StackedLinesChart from "@/shared/components/charts/StackedLinesChart";
import { Autocomplete, Card, CardContent, TextField, Typography } from "@mui/material";
import { useState } from "react";
import DoughnutChart from "@/shared/components/charts/DoughnutChart";
import { getDashboardFilterYears } from "../../../utils/dates";
import ActivityCounts from "../../Home/components/activityCounts";
import { CardPage, CardPageBody, CardPageTitleWrap, CardPageTitle, CardPageGridWrap} from '@/shared/components/MaterialCardPage';

const VulnerabilityDashboard = () => {

  const {theme} = useSelector(({changeTheme}) => changeTheme);

  const years = getDashboardFilterYears();
    const [selectedYear, setSelectedYear] = useState(years[years.length - 1]);

  const optionsPriorities3 = {
    toolbox: {
        feature: {
          saveAsImage: {}
        }
      },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: { },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'value'
    },
    yAxis: {
      type: 'category',
      data: ["App 10", "App 9", "App 8", "App 7", "App 6", "App 5", "App 4", "App 3", "App 2", "App 1"]
    },
    series: [
      {
        name: 'None',
        type: 'bar',
        stack: 'total',
        label: {
          show: true
        },
        emphasis: {
          focus: 'series'
        },
        data: [50, 100, 150, 100, 130, 100, 40, 60, 80, 54]
      },
      {
        name: 'Low',
        type: 'bar',
        stack: 'total',
        label: {
          show: true
        },
        emphasis: {
          focus: 'series'
        },
        data: [80, 120, 163, 120, 80, 94, 50, 30, 50, 90]
      },
      {
        name: 'Medium',
        type: 'bar',
        stack: 'total',
        label: {
          show: true
        },
        emphasis: {
          focus: 'series'
        },
        data: [30, 70, 50, 130, 60, 90, 60, 80, 89, 53]
      },
      {
        name: 'High',
        type: 'bar',
        stack: 'total',
        label: {
          show: true
        },
        emphasis: {
          focus: 'series'
        },
        data: [40, 60, 100, 63, 96, 123, 100, 56, 90, 35]
      },
      {
        name: 'Critical',
        type: 'bar',
        stack: 'total',
        label: {
          show: true
        },
        emphasis: {
          focus: 'series'
        },
        data: [50, 80, 120, 135, 76, 91, 58, 70, 84, 67]
      }
    ],
    dataZoom:[
      {
        type: 'slider',
        yAxisIndex: 0,
        zoomLock: false,
        width: 10,
        right: 10,
        start: 80,
        end: 100,
        handleSize: 20,
        zoomOnMouseWheel: true
      },
    ]
  };

  const optionsPriorities2 = {
    title: {
        text: "Critical Applications"
    },
    toolbox: {
        feature: {
          saveAsImage: {}
        }
      },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: { },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
        type: 'value'
    },
    yAxis: {
      type: 'category',
      data: ["App 10", "App 9", "App 8", "App 7", "App 6", "App 5", "App 4", "App 3", "App 2", "App 1"]
    },
    series:{
        type: 'bar',
        data: [50, 80, 90, 56, 74, 87, 90, 120, 40, 50, 54]
    },
    dataZoom:[
      {
        type: 'slider',
        yAxisIndex: 0,
        zoomLock: false,
        width: 10,
        right: 10,
        start: 80,
        end: 100,
        handleSize: 20,
        zoomOnMouseWheel: true
      },
    ]
  };

  return(
    <Container>
      <CardPage>
        <CardPageBody>
          <CardPageTitleWrap>
              <CardPageTitle>Vulnerability Management Dashboard</CardPageTitle>
          </CardPageTitleWrap>

          <Row style={{marginBottom: '16px'}}>
            <Col md={12}>
              <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Autocomplete
                  style={{ width: 100 }}
                  disablePortal
                  disableClearable
                  options={years}
                  value={selectedYear}
                  onChange={(e, data) => setSelectedYear(data)}
                  renderInput={(params) => 
                      <TextField 
                          {...params}
                          size="small"
                          label="Year"
                      />
                  }
                />
              </div>
            </Col>
          </Row>

          <ActivityCounts year={selectedYear} activityTypeId={2} />

          <Row style={{marginTop: 20}}>
            <Col md={8}>
              <MultipleLinesChart 
                endpoint={`GetVulnerabilityTrendCVSS?year=${selectedYear}`}
                title={{
                  text: 'Vulnerability Trend CVSS'
                }}
              />
            </Col>
            <Col md={4}>
              <DoughnutChart 
                endpoint={`GetReportingVulnerabilityCVSSscore?year=${selectedYear}`}
                legend={{
                  orient: 'vertical',
                  left: 'left'
                }}
              />
            </Col>
          </Row>

          <Row style={{marginTop: 30}}>
            <Col md={8}>
              <MultipleLinesChart 
                endpoint={`GetVulnerabilityTrendEPSS?year=${selectedYear}`}
                title={{
                  text: 'Vulnerability Trend EPSS'
                }}
              />
            </Col>
            <Col md={4}>
              <DoughnutChart 
                endpoint={`GetReportingVulnerabilityEPSSscore?year=${selectedYear}`}
                legend={{
                  orient: 'vertical',
                  left: 'left'
                }}
              />
            </Col>
          </Row>

          <Row style={{marginTop: 30}}>
            <Col md={8}>
              <MultipleLinesChart 
                endpoint={`GetVulnerabilityTrendExploit?year=${selectedYear}`}
                title={{
                  text: 'Vulnerability Trend Exploit'
                }}
              />
            </Col>
            <Col md={4}>
              <DoughnutChart 
                endpoint={`GetReportingVulnerabilityExploitScore?year=${selectedYear}`}
                legend={{
                  orient: 'vertical',
                  left: 'left'
                }}
              />
            </Col>
          </Row>

          <Row style={{marginTop: 30}}>
            <Col md={6}>
              <StackedLinesChart 
                endpoint={`GetReportingAssetVulnerabilityPlannedContextualScore?year=${selectedYear}`}
                title={{
                  text: 'Date Planned Contextual Score'
                }}
              />
            </Col>
            <Col md={6}>
              <StackedBarsChart 
                endpoint={`GetReportingAssetVulnerabilityStatusContextualScore?year=${selectedYear}`}
                title={{
                  text: 'Status Contextual Score'
                }}
              />
            </Col>
          </Row>

          <Row style={{marginTop: 30}}>
            <Col md={6}>
              <StackedLinesChart 
                endpoint={`GetReportingAssetVulnerabilityPlannedCVSS?year=${selectedYear}`}
                title={{
                  text: 'Date Planned CVSS'
                }}
              />
            </Col>
            <Col md={6}>
              <StackedBarsChart 
                endpoint={`GetReportingAssetVulnerabilityStatusCVSS?year=${selectedYear}`}
                title={{
                  text: 'Status CVSS'
                }}
              />
            </Col>
          </Row>

          <Row style={{marginTop: 30}}>
            <Col md={6}>
              <StackedLinesChart 
                endpoint={`GetReportingAssetVulnerabilityPlannedEPSS?year=${selectedYear}`}
                title={{
                  text: 'Date Planned EPSS'
                }}
              />
            </Col>
            <Col md={6}>
              <StackedBarsChart 
                endpoint={`GetReportingAssetVulnerabilityStatusEPSS?year=${selectedYear}`}
                title={{
                  text: 'Status EPSS'
                }}
              />
            </Col>
          </Row>

          <Row style={{marginTop: 30}}>
            <Col md={6}>
              <StackedLinesChart 
                endpoint={`GetReportingAssetVulnerabilityPlannedExploit?year=${selectedYear}`}
                title={{
                  text: 'Date Planned Exploit'
                }}
              />
            </Col>
            <Col md={6}>
              <StackedBarsChart 
                endpoint={`GetReportingAssetVulnerabilityStatusExploit?year=${selectedYear}`}
                title={{
                  text: 'Status Exploit'
                }}
              />
            </Col>
          </Row>

          <Row style={{marginTop: 30}}>
            <Col md={12}>
              <StackedBarsChart 
                endpoint={`GetAssetVulnerabilityBySO?year=${selectedYear}`}
                title={{
                  text: "SO Vulnerabilities"
                }}
                dataZoom={[
                  {
                    type: 'slider',
                    yAxisIndex: 0,
                    zoomLock: false,
                    width: 10,
                    right: 10,
                    start: 80,
                    end: 100,
                    handleSize: 20,
                    zoomOnMouseWheel: true
                  },
                ]}
              />
            </Col>
          </Row>

          <Row style={{marginTop: 30, paddingBottom: 30}}>
            <Col md={6}>
              <Card>
                <CardContent>
                  <ReactECharts
                    theme={theme}
                    option={optionsPriorities2}
                  />
                </CardContent>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                  <CardContent>
                    <ReactECharts
                      theme={theme}
                      option={optionsPriorities3}
                    />
                  </CardContent>
              </Card>
            </Col>
          </Row>
        </CardPageBody>
      </CardPage>
    </Container>
  );
}

export default VulnerabilityDashboard;