import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getListForRemediationRequest,
  getListForRemediationSuccess,
  getListForRemediationError,
  getSchemaRequest,
  getSchemaSuccess,
  getSchemaError,
  getDomainsRequest,
  getDomainsSuccess,
  getDomainsError,
  getRatingScoresRequest,
  getRatingScoresSuccess,
  getRatingScoresError,
  addRemediationRequest,
  addRemediationSuccess,
  addRemediationError
} from './viewAction';

const defaultState = {
  schema: [],
  isLoadingSchema: true,
  getSchemaError: null,
  domains: [],
  isLoadingDomains: true,
  getDomainsError: null,
  ratingScores: [],
  isLoadingRatingScores: true,
  getRatingScoresError: null,
  assetVulnerabilities: [],
  isLoadingAssetVulnerabilities: false,
  getAssetVulnerabilitiesError: null,
  isAddingRemediation: false,
  addRemediationError: null,
  addedRemediation: false
};

export default handleActions(
  {
    [getSchemaRequest](state) {
      return {
        ...state,
        isLoadingSchema: true,
        getSchemaError: null,
      };
    },
    [getSchemaSuccess](state, { payload }) {
      return {
        ...state,
        schema: payload,
        isLoadingSchema: false,
        getSchemaError: null,
      };
    },
    [getSchemaError](state, { payload }) {
      return {
        ...state,
        isLoadingSchema: false,
        getSchemaError: payload,
      };
    },    
    [getDomainsRequest](state) {
      return {
        ...state,
        isLoadingDomains: true,
        getDomainsError: null,
      };
    },
    [getDomainsSuccess](state, { payload }) {
      return {
        ...state,
        domains: payload,
        isLoadingDomains: false,
        getDomainsError: null,
      };
    },
    [getDomainsError](state, { payload }) {
      return {
        ...state,
        isLoadingDomains: false,
        getDomainsError: payload,
      };
    },    
    [getRatingScoresRequest](state) {
      return {
        ...state,
        isLoadingRatingScores: true,
        getRatingScoresError: null,
      };
    },
    [getRatingScoresSuccess](state, { payload }) {
      return {
        ...state,
        ratingScores: payload,
        isLoadingRatingScores: false,
        getRatingScoresError: null,
      };
    },
    [getRatingScoresError](state, { payload }) {
      return {
        ...state,
        isLoadingRatingScores: false,
        getRatingScoresError: payload,
      };
    },    
    [getListForRemediationRequest](state) {
      return {
        ...state,
        isLoadingAssetVulnerabilities: true,
        getAssetVulnerabilitiesError: null,
      };
    },
    [getListForRemediationSuccess](state, { payload }) {
      return {
        ...state,
        assetVulnerabilities: payload,
        isLoadingAssetVulnerabilities: false,
        getAssetVulnerabilitiesError: null,
      };
    },
    [getListForRemediationError](state, { payload }) {
      return {
        ...state,
        isLoadingAssetVulnerabilities: false,
        getAssetVulnerabilitiesError: payload,
      };
    },
    [addRemediationRequest](state) {
      return {
        ...state,
        isAddingRemediation: true,
        addRemediationError: null,
        addedRemediation: false
      };
    },
    [addRemediationSuccess](state, { payload }) {
      return {
        ...state,
        isAddingRemediation: false,
        addRemediationError: null,
        addedRemediation: true
      };
    },
    [addRemediationError](state, { payload }) {
      return {
        ...state,
        isAddingRemediation: false,
        addRemediationError: payload,
        addedRemediation: false
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);