import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_REMEDIATION_LIST');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getRemediationsRequest = createAction('REMEDIATION_GET_ALL_REQUEST');
export const getRemediationsSuccess = createAction('REMEDIATION_GET_ALL_SUCCESS');
export const getRemediationsError = createAction('REMEDIATION_GET_ALL_ERROR');

export const getRemediations = () => async (dispatch) => {
  try {
    dispatch(getRemediationsRequest());
    const resp = await instance.get(`/v1/Remediation/GetAll`);
    
    dispatch(getRemediationsSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getRemediationsError(e.response.data.description));
    else
      dispatch(getRemediationsError(e.message));
  }
};