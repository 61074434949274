import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { cleanup, getServiceLogs } from "@/reducers/services/serviceLogs/serviceLogsAction";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dayjs from "dayjs";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import styled from "styled-components";

const ServiceLogsModal = ({ open, onHideModal, service }) => {
    const dispatch = useDispatch();
  
    const { 
      serviceLogs, isLoadingServiceLogs, getServiceLogsError
    } = useSelector(({ serviceLogs }) => serviceLogs);


    useEffect(() => {
        dispatch(getServiceLogs(service.id));

        return () => {
            dispatch(cleanup());
        }
    }, [dispatch]);
  
    const handleClose = () => {
      onHideModal();
    }
  
    useEffect(() => {
      if(getServiceLogsError){
        enqueueSnackbar(getServiceLogsError, {variant: "error"});
      }
    }, [getServiceLogsError]);

    return (
      <Dialog
          fullWidth={true}
          maxWidth='lg'
          scroll='paper'
          open={open}
          onClose={handleClose}
      >
          <DialogTitle>{service.name} Logs</DialogTitle>
          <DialogContent style={{height: 500}}>
                <Row>
                    <Col md={12}>
                        { isLoadingServiceLogs ? <CircularProgressWrapper /> :
                            serviceLogs.map((logGroup, index) => {
                                return(
                                    <Accordion key={index}>
                                        <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`${index}-content`}
                                        id={`${index}-header`}
                                        >
                                        <Typography>{dayjs(logGroup.createDate).format("DD/MM/YYYY HH:mm:ss")}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                                { logGroup.logs.map((log, index) => {
                                                    return(
                                                        <Typography key={index}>
                                                            <LogContainer>
                                                                {log.isSuccessed ? <DoneIcon /> : <CloseIcon />}{dayjs(log.createDate).format("DD/MM/YYYY HH:mm:ss")} - {log.serviceName} - {log.log}
                                                            </LogContainer>
                                                        </Typography>
                                                    );
                                                })}
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            })
                        }
                    </Col>
                </Row>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Close</Button>
          </DialogActions>
      </Dialog>
    );
  }

  const LogContainer = styled.div`
  display: flex;
  align-items: center;

  & > svg{
    margin-right: 5px;
  }
`;
  
  export default ServiceLogsModal;