import { Box } from '@mui/material';

const ColorCell = ({
    color
}) => {
    return(
        <>
            <Box style={{
                width: 20, 
                height: 20, 
                backgroundColor: color, 
                marginRight: 10,
                borderRadius: 5
            }} />
            {color}
        </>
    );
};
  export default ColorCell;