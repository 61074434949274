import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { FormSelectField } from '@/shared/components/form/material-controls/SelectField';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import MaterialFieldValuesTable from './fieldValuesTable';
;

const MaterialAddDynamicFilterModal = ({ controller, open, onHideModal, onKeyValueAdded, schema }) => {
  const [searchField, setSearchField] = useState(null);

  const { handleSubmit, reset, control, setValue, getValues } = useForm();

  const loadFieldValueTable = () => {
    const field = getValues("field");
    if(field){
      setSearchField(field);
    }
  }

  const handleFieldChange = (field) => {
    if(searchField !== field){
      setSearchField(null);
    }
  }

  const onValueSelected = (value) => {
    setValue("value", value);
  }

  const handleClose = () => {
    setSearchField(null);
    reset();
    onHideModal();
  }

  const onSubmit = (data) => {
    setSearchField(null);
    reset();
    onKeyValueAdded({key: data.field, value: data.value});
  }

  return (
    <Dialog
        fullWidth
        maxWidth='lg'
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Add Advanced Filter</DialogTitle>
        <DialogContent>
          <Row>
            <Col md={6}>
              <FormSelectField 
                name="field" 
                label="Field"
                control={control}
                options={schema.map((s) => { return {id: s, name: s }})}
                onChange={handleFieldChange}
                rules={{ required: 'This field is required' }} 
              />
            </Col>
            <Col md={6}>
              <FormTextField
                name="value"
                label="Value"
                control={control}
              />
            </Col>
          </Row>

          <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 20}}>
            <Button variant='contained' onClick={() => loadFieldValueTable()} endIcon={<FindInPageIcon/>}>View Data</Button>
            <div>
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant='contained' onClick={handleSubmit(onSubmit)}>Add</Button>
            </div>
          </div>
          
          <hr/>

          { !searchField ? <div style={{height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>Click the search button to load possible values.</div> :
            <MaterialFieldValuesTable
              controller={controller}
              field={searchField}
              onValueSelected={onValueSelected}
            />
          }
        </DialogContent>
      </Dialog>
  );
}

export default MaterialAddDynamicFilterModal;