import { Col, Row } from "react-bootstrap";
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { useForm } from "react-hook-form";
import { Box, Button, FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { FlexCenteredText } from '@/shared/components/CircularProgressWrapper';
import TypeSelect from "./typeSelect";

const CarsTab = ({
    onCarAdd,
    onCarRemove,
    cars,
    parameterTypes
}) => {

    const { handleSubmit, reset, setValue, control } = useForm();

    const onSubmit = (data) => {
        onCarAdd(data);
    }
    
    return (
        <Box sx={{marginTop: '10px'}}>
            <Row>
                <Col md={6}>
                    <Row>
                        <Col md={12}>
                            <FormTextField
                                name="km"
                                label="Kilometers"
                                control={control}
                                type="number"
                                rules={{ 
                                    required: 'This field is required', 
                                    min: { 
                                        value: 1,
                                        message: "Must have at least 1 kilometer"
                                }, }} 
                                defaultValue={0}
                            />
                        </Col>

                        <Col md={12}>
                            <TypeSelect 
                                name="carSizeId"
                                label="Car Size"
                                control={control}
                                rules={{ required: 'This field is required' }} 
                                typeCode="car_size"
                                parameterTypes={parameterTypes}
                            />
                        </Col>

                        <Col md={12}>
                            <TypeSelect 
                                name="carTypeId"
                                label="Car Type"
                                control={control}
                                rules={{ required: 'This field is required' }} 
                                typeCode="car_type"
                                parameterTypes={parameterTypes}
                            />
                        </Col>

                        <Col md={12} style={{marginTop: 20}}>
                            <Button variant="contained" color="co2GreenColor" fullWidth onClick={handleSubmit(onSubmit)}>Add Car</Button>
                        </Col>
                    </Row>
                </Col>
                <Col md={6}>
                    <Box sx={{
                        width: '100%', 
                        border: '1px solid',
                        borderRadius: '2px',
                        height: '236px',
                        padding: 1,
                        overflowY: 'auto',
                    }}>
                        {cars && cars.length ? 
                            cars.map(car => {
                                return(
                                    <Box sx={{
                                        display: 'flex', 
                                        justifyContent: 'space-between', 
                                        width: '100%',
                                        alignItems: "center",
                                        border: '1px solid',
                                        borderRadius: '2px',
                                        padding: 1,
                                        marginBottom: 1
                                    }}>
                                        <Typography>
                                            {parameterTypes.find(c => c.parameterTypeId === car.carSizeId).value} car - {parameterTypes.find(c => c.parameterTypeId === car.carTypeId).value} engine - {car.km} km
                                        </Typography>
                                        <IconButton onClick={() => onCarRemove(car)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                );
                            })
                        :
                            <FlexCenteredText height='100%'>
                                No cars added.
                            </FlexCenteredText>
                        }
                    </Box>
                </Col>
            </Row>
        </Box>
    );
}

export default CarsTab;