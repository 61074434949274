import { Backdrop, Button, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { enqueueSnackbar } from "notistack";
import { CardPage, CardPageFilterWrapper, CardPageBody, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap,CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import { FormTextField } from "@/shared/components/form/material-controls/TextField";
import { useForm } from "react-hook-form";
import { IsValidEmail } from "@/utils/formValidations";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import { useState } from "react";
import { getLogo, updateTenant, deleteLogo, getCurrentTenant } from "@/reducers/currentTenant/currentTenantAction";
import styled from "styled-components";
import LogoChangeModal from "./components/logoChangeModal";
import ConfirmationDialog from '@/shared/components/ConfirmationDialog';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';

const TenantSettings = () => {

    const dispatch = useDispatch();

    const { 
        currentTenant, isLoadingCurrentTenant, getCurrentTenantError,
        logo, isLoadingLogo, getLogoError,
        isUpdatingTenant, updatedTenant, updateTenantError,
        isDeletingLogo, deletedLogo, deleteLogoError
    } = useSelector(({ currentTenant }) => currentTenant);

    
    useEffect(() => {
        return () => {
            reset();
        }
    }, []);
    
    const [showLogoModal, setShowLogoModal] = useState(false);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  
    const { handleSubmit, reset, control, setValue, getValues } = useForm();


    const onClickDeleteLogo = () => {
        setOpenConfirmDelete(true);
    };

    const handleConfirmDelete = () => {
        setOpenConfirmDelete(false);
        dispatch(deleteLogo());
    }

    const handleCloseDelete = () => {
        setOpenConfirmDelete(false);
    }

    useEffect(() => {
        if(deleteLogoError){
        enqueueSnackbar(deleteLogoError, {variant: "error"});
        }
    }, [deleteLogoError]);

    useEffect(() => {
        if(deletedLogo){
        enqueueSnackbar("Logo deleted successfully", {variant: "success"});
        }
    }, [deletedLogo]);

    const onUpdateLogo = () => {
        dispatch(getLogo());
    };

    const onHideLogoModal = () => {
        setShowLogoModal(false);
    };

    const onClickEditLogo = () => {
        setShowLogoModal(true);
    };
    
    useEffect(() => {
        if(getCurrentTenantError){
            enqueueSnackbar(getCurrentTenantError, {variant: "error"});
        }
    }, [getCurrentTenantError]);

    useEffect(() => {
        if(updateTenantError){
            enqueueSnackbar(updateTenantError, {variant: "error"});
        }
    }, [updateTenantError]);

    useEffect(() => {
        if(updatedTenant){
          enqueueSnackbar(`Tenant information updated successfuly.`, {variant: "success"});
          dispatch(getCurrentTenant());
        }
    }, [updatedTenant]);

    const onSubmit = (data) => {
        dispatch(updateTenant(data));
    }

    return(
        <Container>
            <CardPage>
                <CardPageBody>         
                    <CardPageTitleWrap>
                        <CardPageTitle>Tenant Profile</CardPageTitle>
                    </CardPageTitleWrap>
                    
                    {isLoadingCurrentTenant || !currentTenant ? <CircularProgressWrapper /> :
                    <>
                        <Row style={{marginTop: 20}}>
                            <Col md={6}>
                                <ProfileInformation>
                                    <ProfileAvatar>
                                    { isLoadingLogo ? <CircularProgressWrapper /> : 
                                        !logo ? 
                                        <img src="/img/building2.png" alt="avatar"/>
                                        :
                                        <img src={URL.createObjectURL(logo)} alt="avatar" />
                                    }
                                    </ProfileAvatar>
                                    <div style={{display: 'flex', flexDirection: 'column', marginLeft: 20}}>
                                        <Button 
                                            endIcon={<AddAPhotoIcon />} 
                                            onClick={onClickEditLogo} 
                                            variant='contained' 
                                            color="primary">
                                        Change Logo
                                        </Button>
                                        <Button 
                                            disabled={logo === null}
                                            style={{marginTop: 10}}
                                            endIcon={<NoPhotographyIcon />} 
                                            onClick={onClickDeleteLogo} 
                                            variant='contained' 
                                            color="error">
                                        Delete Logo
                                        </Button>
                                    </div>
                                </ProfileInformation>
                            </Col>
                            <Col md={6}>
                            <Typography variant="subtitle1" style={{ marginTop: 20 }}>Contact Information</Typography>
                                <Row>
                                    <Col md={12}>
                                        <FormTextField
                                            name="name" 
                                            label="Name" 
                                            control={control}
                                            rules={{ required: 'This field is required' }}
                                            defaultValue={currentTenant.name}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormTextField
                                            name="emailAddress" 
                                            label="Email" 
                                            control={control}
                                            rules={{ 
                                                required: 'This field is required',
                                                validate: {
                                                    matchPattern: (email) => IsValidEmail(email) ||
                                                    "Email address must be a valid address"
                                                }
                                            }}
                                            defaultValue={currentTenant.emailAddress}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormTextField
                                            name="pecEmailAddress" 
                                            label="Pec Email" 
                                            control={control}
                                            rules={{ 
                                                required: 'This field is required',
                                                validate: {
                                                    matchPattern: (email) => IsValidEmail(email) ||
                                                    "Email address must be a valid address"
                                                }
                                            }}
                                            defaultValue={currentTenant.pecEmailAddress}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>      

                        <Row>
                            <Col md={12}>
                                <Divider component='div' style={{ marginTop: 20 }} />
                                <Typography variant="subtitle1" style={{ marginTop: 20 }}>Billing Information</Typography>
                                <Row>
                                    <Col md={12}>
                                        <FormTextField 
                                            name="billingName" 
                                            label="Billing Name" 
                                            control={control} 
                                            rules={{ required: 'This field is required' }}
                                            defaultValue={currentTenant.billingName}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormTextField 
                                            name="billingAddress" 
                                            label="Billing Address" 
                                            control={control} 
                                            defaultValue={currentTenant.billingAddress}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormTextField 
                                            name="billingCountry" 
                                            label="Billing Country" 
                                            control={control} 
                                            defaultValue={currentTenant.billingCountry}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <FormTextField 
                                            name="billingCity" 
                                            label="Billing City" 
                                            control={control} 
                                            defaultValue={currentTenant.billingCity}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormTextField 
                                            name="vat" 
                                            label="VAT" 
                                            control={control} 
                                            defaultValue={currentTenant.vat}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <FormTextField 
                                            name="vatCode" 
                                            label="SDI Code" 
                                            control={control} 
                                            defaultValue={currentTenant.vatCode}
                                        />
                                    </Col>
                                </Row>

                                <Row style={{marginTop: 20}}>
                                    <Col md={12}>
                                        <Grid
                                            container
                                            justifyContent="flex-end"
                                        >
                                            <Grid item>
                                                <Button
                                                    onClick={handleSubmit(onSubmit)}
                                                    variant="contained"
                                                >
                                                    Update
                                                </Button>
                                            </Grid>
                                            
                                        </Grid>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                    }
                </CardPageBody>
            </CardPage>

            { showLogoModal &&
                <LogoChangeModal
                onHideModal={onHideLogoModal}
                onUpdateLogo={onUpdateLogo} 
                open={showLogoModal}
                logo={logo}
                />
            }
            
            <ConfirmationDialog 
                open={openConfirmDelete}
                onConfirm={handleConfirmDelete}
                onClose={handleCloseDelete}
                title="Delete Logo"
                body={`Are you sure you want to delete your logo image?`}
                color='error'
                confirmButtonText='Delete'
            />

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isUpdatingTenant || isDeletingLogo}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}


const ProfileAvatar = styled.div`
  height: 200px;
  width: auto;

  img {
    height: 100%;
  }

  @media (max-width: 1345px) and (min-width: 1200px) {
    height: 130px;
    width: 130px;
  }
`;

const ProfileInformation = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
`;

export default TenantSettings;