import { Col, Row } from "react-bootstrap";
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { useForm } from "react-hook-form";
import airports from './airports.json';
import { useMemo, useState } from "react";
import { FormSelectField } from '@/shared/components/form/material-controls/SelectField';
import { Box, Button, FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { FlexCenteredText } from '@/shared/components/CircularProgressWrapper';
import TypeSelect from "./typeSelect";

const FlightsTab = ({
    onFlightAdd,
    onFlightRemove,
    flights,
    parameterTypes
}) => {

    const { handleSubmit, reset, setValue, control } = useForm();

    const [flightType, setFlightType] = useState("returnTrip");

    const onChangeFlightType = (e, value) => {
        setFlightType(value);
    };

    const mappedAirports = useMemo(() => {
        return airports.map(a => {
            return { id: a.Code, name: `${a.Code} | ${a.Airport} (${a.Country})` };
        });
    }, []);

    const onSubmit = (data) => {
        data.returnTrip = flightType === 'returnTrip';
        onFlightAdd(data);
    }
    
    return (
        <Box sx={{marginTop: '10px'}}>
            <Row>
                <Col md={6}>
                    <Row>
                        <Col md={12}>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="flight-type-radio-buttons"
                                    name="flightType"
                                    value={flightType}
                                    onChange={onChangeFlightType}
                                >
                                    <FormControlLabel 
                                    value="returnTrip" 
                                    control={<Radio />} 
                                    label={<Typography>Return trip</Typography>}
                                    />
                                    <FormControlLabel 
                                    value="oneWay" 
                                    control={<Radio />} 
                                    label={<Typography>One-way flight</Typography>}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Col>
                    
                        <Col md={12}>
                            <FormSelectField
                                name="origin"
                                label="Origin"
                                control={control}
                                rules={{ required: 'This field is required' }} 
                                options={mappedAirports}
                            />
                        </Col>
                        <Col md={12}>
                            <FormSelectField
                                name="destination"
                                label="Destination"
                                control={control}
                                rules={{ required: 'This field is required' }} 
                                options={mappedAirports}
                            />
                        </Col>
                        <Col md={12}>
                            <TypeSelect 
                                name="classId"
                                label="Class"
                                control={control}
                                rules={{ required: 'This field is required' }} 
                                typeCode="air_details"
                                parameterTypes={parameterTypes}
                            />
                        </Col>
                        <Col md={12}>
                            <FormTextField
                                name="trips"
                                label="Trips"
                                control={control}
                                type="number"
                                rules={{ 
                                    required: 'This field is required', 
                                    min: { 
                                        value: 1,
                                        message: "Must have at least 1 trip"
                                }, }} 
                                defaultValue={1}
                            />
                        </Col>

                        <Col md={12} style={{marginTop: 20}}>
                            <Button variant="contained" color="co2GreenColor" fullWidth onClick={handleSubmit(onSubmit)}>Add Flight</Button>
                        </Col>
                    </Row>
                </Col>
                <Col md={6}>
                    <Box sx={{
                        width: '100%', 
                        border: '1px solid',
                        borderRadius: '2px',
                        height: '380px',
                        padding: 1,
                        overflowY: 'auto',
                    }}>
                        {flights && flights.length ? 
                            flights.map(flight => {
                                return(
                                    <Box sx={{
                                        display: 'flex', 
                                        justifyContent: 'space-between', 
                                        width: '100%',
                                        alignItems: "center",
                                        border: '1px solid',
                                        borderRadius: '2px',
                                        padding: 1,
                                        marginBottom: 1
                                    }}>
                                        <Typography>
                                            {parameterTypes.find(c => c.parameterTypeId === flight.classId).value} class - {flight.returnTrip ? "with return" : "one way"} from {flight.origin} to {flight.destination} x {flight.trips}
                                        </Typography>
                                        <IconButton onClick={() => onFlightRemove(flight)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                );
                            })
                        :
                            <FlexCenteredText height='100%'>
                                No flights added.
                            </FlexCenteredText>
                        }
                    </Box>
                </Col>
            </Row>
        </Box>
    );
}

export default FlightsTab;