import { Backdrop, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Slider, TextField, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { cleanup, addVulnerabilityScore } from "@/reducers/vulnerabilityScores/addVulnerabilityScore/addVulnerabilityScoreAction";
import { FormColorField } from "@/shared/components/form/material-controls/ColorField";
import { FormRangeSliderField } from "@/shared/components/form/material-controls/RangeSliderField";

const CreateVulnerabilityScoreModal = ({ open, onHideModal, onVulnerabilityScoreCreated }) => {
    const dispatch = useDispatch();
  
    const { 
      isAddingVulnerabilityScore, addVulnerabilityScoreError, addedVulnerabilityScore
    } = useSelector(({ addVulnerabilityScore }) => addVulnerabilityScore);

    useEffect(() => {
      return () => {
        dispatch(cleanup());
      }
    }, [dispatch]);
  
    const { handleSubmit, reset, control, setValue, getValues } = useForm();

    const handleClose = (event, reason) => {
      if (reason && reason === "backdropClick") 
          return;
        
      reset();
      onHideModal();
    }
  
    useEffect(() => {
      if(addVulnerabilityScoreError){
        enqueueSnackbar(addVulnerabilityScoreError, {variant: "error"});
      }
    }, [addVulnerabilityScoreError]);
  
    const onSubmit = (data) => {       
      data.minValue = data.value[0];
      data.maxValue = data.value[1];
      dispatch(addVulnerabilityScore(data));
    }
  
    useEffect(() => {
      if(addedVulnerabilityScore){
        enqueueSnackbar("Vulnerability Score created successfully", {variant: "success"});
        handleClose();
        onVulnerabilityScoreCreated();
      }
    }, [addedVulnerabilityScore]);
  
    return (
      <Dialog
          fullWidth
          maxWidth='lg'
          scroll='paper'
          open={open}
          onClose={handleClose}
      >
          <DialogTitle>Create Vulnerability Score</DialogTitle>
          <DialogContent>
          <Row>
                  <Col md={12}>
                    <FormTextField
                        name='name'
                        label='Name'
                        control={control}
                        rules={{ required: 'This field is required' }}
                    />
                  </Col>
                  <Col md={6}>
                    <FormRangeSliderField 
                      name='value'
                      label='Value'
                      control={control}
                      min={0}
                      max={10}
                      step={0.1}
                    />
                  </Col>
                  <Col md={6}>
                    <FormColorField 
                        name='color'
                        label='Color'
                        control={control}
                    />
                  </Col>
                </Row>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant='contained' color="success" onClick={handleSubmit(onSubmit)}>Create</Button>
          </DialogActions>
  
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isAddingVulnerabilityScore}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
      </Dialog>
    );
  }
  
  export default CreateVulnerabilityScoreModal;