import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getRolesRequest,
  getRolesSuccess,
  getRolesError,
  getUsersRequest,
  getUsersSuccess,
  getUsersError,
  deleteStakeholderUserRequest,
  deleteStakeholderUserSuccess,
  deleteStakeholderUserError
} from './stakeholderUserListAction';

const defaultState = {
  roles: null,
  isLoadingRoles: true,
  getRolesError: null,
  users: [],
  isLoadingUsers: true,
  getUsersError: null,
  usersTotalCount: 0,
  isDeletingStakeholderUser: false,
  deleteStakeholderUserError: null,
  deletedStakeholderUser: false,
};

export default handleActions(
  {
    [getRolesRequest](state) {
      return {
        ...state,
        isLoadingRoles: true,
        getRolesError: null,
      };
    },
    [getRolesSuccess](state, { payload }) {
      return {
        ...state,
        roles: payload,
        isLoadingRoles: false,
        getRolesError: null,
      };
    },
    [getRolesError](state, { payload }) {
      return {
        ...state,
        isLoadingRoles: false,
        getRolesError: payload,
      };
    },
    [getUsersRequest](state) {
        return {
        ...state,
        isLoadingUsers: true,
        getUsersError: null,
        };
    },
    [getUsersSuccess](state, { payload }) {
        return {
        ...state,
        users: payload.data,
        usersTotalCount: payload.totalCount,
        isLoadingUsers: false,
        getUsersError: null,
        };
    },
    [getUsersError](state, { payload }) {
        return {
        ...state,
        isLoadingUsers: false,
        getUsersError: payload,
        };
    },
    [deleteStakeholderUserRequest](state) {
      return {
        ...state,
        isDeletingStakeholderUser: true,
        deletedStakeholderUser: false,
        deleteStakeholderUserError: null,
      };
    },
    [deleteStakeholderUserSuccess](state, { payload }) {
      return {
        ...state,
        isDeletingStakeholderUser: false,
        deletedStakeholderUser: true,
        deleteStakeholderUserError: null,
      };
    },
    [deleteStakeholderUserError](state, { payload }) {
      return {
        ...state,
        isDeletingStakeholderUser: false,
        deletedStakeholderUser: false,
        deleteStakeholderUserError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);
