import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getCheckListRequest,
  getCheckListSuccess,
  getCheckListError,
  getSchemaRequest,
  getSchemaSuccess,
  getSchemaError,
  getDomainsRequest,
  getDomainsSuccess,
  getDomainsError,
  getRatingScoresRequest,
  getRatingScoresSuccess,
  getRatingScoresError,
  getVulnerabilityStatusesRequest,
  getVulnerabilityStatusesSuccess,
  getVulnerabilityStatusesError,
  saveCheckListRequest,
  saveCheckListSuccess,
  saveCheckListError,
  resetTableDataRequest
} from './viewAction';

const defaultState = {
  schema: [],
  isLoadingSchema: true,
  getSchemaError: null,
  vulnerabilityStatuses: [],
  isLoadingVulnerabilityStatuses: true,
  getVulnerabilityStatusesError: null,
  domains: [],
  isLoadingDomains: true,
  getDomainsError: null,
  ratingScores: [],
  isLoadingRatingScores: true,
  getRatingScoresError: null,
  checkList: [],
  isLoadingCheckList: false,
  getCheckListError: null,
  isSavingCheckList: false,
  savedCheckList: false,
  saveCheckListError: null
};

export default handleActions(
  {
    [getSchemaRequest](state) {
      return {
        ...state,
        isLoadingSchema: true,
        getSchemaError: null,
      };
    },
    [getSchemaSuccess](state, { payload }) {
      return {
        ...state,
        schema: payload,
        isLoadingSchema: false,
        getSchemaError: null,
      };
    },
    [getSchemaError](state, { payload }) {
      return {
        ...state,
        isLoadingSchema: false,
        getSchemaError: payload,
      };
    },    
    [getDomainsRequest](state) {
      return {
        ...state,
        isLoadingDomains: true,
        getDomainsError: null,
      };
    },
    [getDomainsSuccess](state, { payload }) {
      return {
        ...state,
        domains: payload,
        isLoadingDomains: false,
        getDomainsError: null,
      };
    },
    [getDomainsError](state, { payload }) {
      return {
        ...state,
        isLoadingDomains: false,
        getDomainsError: payload,
      };
    },
    [getVulnerabilityStatusesRequest](state) {
      return {
        ...state,
        isLoadingVulnerabilityStatuses: true,
        getVulnerabilityStatusesError: null,
      };
    },
    [getVulnerabilityStatusesSuccess](state, { payload }) {
      return {
        ...state,
        vulnerabilityStatuses: payload,
        isLoadingVulnerabilityStatuses: false,
        getVulnerabilityStatusesError: null,
      };
    },
    [getVulnerabilityStatusesError](state, { payload }) {
      return {
        ...state,
        isLoadingVulnerabilityStatuses: false,
        getVulnerabilityStatusesError: payload,
      };
    },    
    [getRatingScoresRequest](state) {
      return {
        ...state,
        isLoadingRatingScores: true,
        getRatingScoresError: null,
      };
    },
    [getRatingScoresSuccess](state, { payload }) {
      return {
        ...state,
        ratingScores: payload,
        isLoadingRatingScores: false,
        getRatingScoresError: null,
      };
    },
    [getRatingScoresError](state, { payload }) {
      return {
        ...state,
        isLoadingRatingScores: false,
        getRatingScoresError: payload,
      };
    },    
    [getCheckListRequest](state) {
      return {
        ...state,
        isLoadingCheckList: true,
        getCheckListError: null,
      };
    },
    [getCheckListSuccess](state, { payload }) {
      return {
        ...state,
        checkList: payload,
        isLoadingCheckList: false,
        getCheckListError: null,
      };
    },
    [getCheckListError](state, { payload }) {
      return {
        ...state,
        isLoadingCheckList: false,
        getCheckListError: payload,
      };
    },
    [saveCheckListRequest](state) {
      return {
        ...state,
        isSavingCheckList: true,
        savedCheckList: false,
        saveCheckListError: null
      };
    },
    [saveCheckListSuccess](state, { payload }) {
      return {
        ...state,
        isSavingCheckList: false,
        savedCheckList: true,
        saveCheckListError: null
      };
    },
    [saveCheckListError](state, { payload }) {
      return {
        ...state,
        isSavingCheckList: false,
        savedCheckList: false,
        saveCheckListError: payload
      };
    },
    [resetTableDataRequest](state, { payload }) {
      return {
        ...state,
        checkList: payload
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);