import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAsset, cleanup } from '@/reducers/asset/info/assetInfoAction';import { useState } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import InfoIcon from '@mui/icons-material/Info';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PersonIcon from '@mui/icons-material/Person';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TabPanel, { tabIndexProps } from "@/shared/components/MaterialTabPanel";
import dayjs from "dayjs";
import ClassificationChip from "./classificationChip";
import CopyrightIcon from '@mui/icons-material/Copyright';
import ReportAndKPI from "./reportAndKpi";
import AssessmentIcon from '@mui/icons-material/Assessment';

const MaterialAssetInfoModal = ({ assetId, onHideModal, showModal, classifications }) => {
    const dispatch = useDispatch();
  
    const { 
      asset, isGetting, getError
    } = useSelector(({ assetInfo }) => assetInfo);

    const [tab, setTab] = useState(0);

    const handleTabChange = (event, newValue) => {
      setTab(newValue);
    };

    useEffect(() => {
      dispatch(getAsset(assetId));

      return () => {
        dispatch(cleanup());
      }
    }, [dispatch]);
  
    const handleClose = (event, reason) => {
      onHideModal();
    }
  
    useEffect(() => {
      if(getError){
        enqueueSnackbar(getError, {variant: "error"});
      }
    }, [getError]);
  
    return (
      <Dialog
          fullWidth={true}
          maxWidth='lg'
          scroll='paper'
          open={showModal}
          onClose={handleClose}
      >
          <DialogTitle>Asset Info</DialogTitle>
          <DialogContent style={{height: 700}}>
          {isGetting || !asset ? <div style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><CircularProgress /></div> : 
          <>
            <Tabs
              value={tab}
              variant="fullWidth"
              onChange={handleTabChange}
              aria-label="icon position tabs example"
            >
              <Tab 
                icon={<InfoIcon />} 
                iconPosition="start" 
                label="General Info"
                {...tabIndexProps(0)}
              />
               <Tab 
                icon={<CopyrightIcon />} 
                iconPosition="start" 
                label="Classification"
                {...tabIndexProps(1)}
              />
              <Tab 
                icon={<TextSnippetIcon />} 
                iconPosition="start" 
                label="Metadata"
                {...tabIndexProps(2)}
              />
              <Tab 
                icon={<PersonIcon />} 
                iconPosition="start" 
                label="Audit"
                {...tabIndexProps(3)}
              />
              {
                asset.metadata?.surveyCalculations?.length &&
                <Tab 
                  icon={<AssessmentIcon />} 
                  iconPosition="start" 
                  label="Report and KPI"
                  {...tabIndexProps(4)}
                />
              }
            </Tabs>
          
            <TabPanel value={tab} index={0}>
              <Row>
                <Col md={12}>
                  <TextField
                      name='name'
                      label='Name'
                      defaultValue={asset.name}
                      fullWidth
                      size="small"
                      InputProps={{
                        readOnly: true
                      }}
                  />
                </Col>
                <Col md={12}>
                  <TextField
                      style={{marginTop: 20}}
                      name='description'
                      label='Description'
                      defaultValue={asset.description}
                      fullWidth
                      size="small"
                      multiline
                      rows={4}
                      InputProps={{
                        readOnly: true
                      }}
                  />
                </Col>
                <Col md={12} style={{marginBottom: 20}}>
                  <TextField
                      style={{marginTop: 20}}
                      name='type'
                      label='Type'
                      defaultValue={asset.assetType?.nameTraslated}
                      fullWidth
                      size="small"
                      InputProps={{
                        readOnly: true
                      }}
                  />
                </Col>
              </Row>
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <Row>
                  <Col md={4} style={{textAlign: "center"}}>
                    <Typography fontSize='20px' style={{marginBottom: 10}}>Confidentiality</Typography>
                    <ClassificationChip
                      name="Confidentiality" 
                      classification={classifications.find(c => c.id === asset.classification?.confidentialityId)} 
                      sx={{
                        py: 3,
                        fontWeight: 'bold',
                        '& .MuiChip-label': {
                            fontSize: '16px'
                        }
                      }}
                    />
                  </Col>
                  <Col md={4} style={{textAlign: "center"}}>
                    <Typography fontSize='20px' style={{marginBottom: 10}}>Integrity</Typography>
                    <ClassificationChip
                      name="Integrity" 
                      classification={classifications.find(c => c.id === asset.classification?.integrityId)} 
                      sx={{
                        py: 3,
                        fontWeight: 'bold',
                        '& .MuiChip-label': {
                            fontSize: '16px'
                        }
                      }}
                    />
                  </Col>
                  <Col md={4} style={{textAlign: "center"}}>
                    <Typography fontSize='20px' style={{marginBottom: 10}}>Availability</Typography>
                    <ClassificationChip 
                      name="Availability" 
                      classification={classifications.find(c => c.id === asset.classification?.availabilityId)} 
                      sx={{
                        py: 3,
                        fontWeight: 'bold',
                        '& .MuiChip-label': {
                            fontSize: '16px'
                        }
                      }}
                    />
                  </Col>
                </Row>
            </TabPanel>
            <TabPanel value={tab} index={2}>
              { asset.metadata && Object.keys(asset.metadata).length ?
                Object.keys(asset.metadata).map((key, index) => 
                  <Accordion key={index}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                    <Typography>{key}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <pre style={{
                        fontSize: "16px", 
                        whiteSpace: 'pre-wrap', 
                        wordBreak: 'break-word', 
                        paddingRight: "0px", 
                        contain: "inline-size"
                      }}>
                        {JSON.stringify(asset.metadata[key], null, 2)}
                      </pre>
                    </AccordionDetails>
                  </Accordion>
                ) :
                <div style={{display: "flex", height: '300px', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                    <Typography>No content to show.</Typography>
                </div>
              }
            </TabPanel>
            <TabPanel value={tab} index={3}>
             <Row>
                <Col md={6}>
                  <TextField
                      style={{marginTop: 20}}
                      name='createDate'
                      label='Create Date'
                      defaultValue={asset.createDate ? dayjs(asset.createDate).format("DD/MM/YYYY") : "-"}
                      fullWidth
                      size="small"
                      InputProps={{
                        readOnly: true
                      }}
                  />
                </Col>
                <Col md={6}>
                  <TextField
                      style={{marginTop: 20}}
                      name='createdByUserId'
                      label='Created By User'
                      defaultValue={asset.createdByUserId ?? "-"}
                      fullWidth
                      size="small"
                      InputProps={{
                        readOnly: true
                      }}
                  />
                </Col>
              </Row>
              <Row style={{marginTop: 20}}>
                <Col md={6}>
                  <TextField
                      style={{marginTop: 20}}
                      name='updateDate'
                      label='Update Date'
                      defaultValue={asset.updateDate ? dayjs(asset.updateDate).format("DD/MM/YYYY") : "-"}
                      fullWidth
                      size="small"
                      InputProps={{
                        readOnly: true
                      }}
                  />
                </Col>
                <Col md={6}>
                  <TextField
                      style={{marginTop: 20}}
                      name='updatedByUserId'
                      label='Updated By User'
                      defaultValue={asset.updatedByUserId ?? "-"}
                      fullWidth
                      size="small"
                      InputProps={{
                        readOnly: true
                      }}
                  />
                </Col>
              </Row>
              <Row style={{marginTop: 20}}>
                <Col md={6}>
                  <TextField
                      style={{marginTop: 20}}
                      name='deleteDate'
                      label='Delete Date'
                      defaultValue={asset.deleteDate ? dayjs(asset.deleteDate).format("DD/MM/YYYY") : "-"}
                      fullWidth
                      size="small"
                      InputProps={{
                        readOnly: true
                      }}
                  />
                </Col>
                <Col md={6}>
                  <TextField
                      style={{marginTop: 20}}
                      name='deletedByUserId'
                      label='Deleted By User'
                      defaultValue={asset.deletedByUserId ?? "-"}
                      fullWidth
                      size="small"
                      InputProps={{
                        readOnly: true
                      }}
                  />
                </Col>
              </Row>
            </TabPanel>
            {
              asset.metadata?.surveyCalculations?.length &&
              <TabPanel value={tab} index={4}>
                <ReportAndKPI asset={asset}/>
              </TabPanel>
            }
            </>
            }
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Close</Button>
          </DialogActions>
      </Dialog>
    );
  }
  
  export default MaterialAssetInfoModal;