import { DataGrid, GridActionsCellItem, GridToolbar, gridClasses } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { cleanup, getAuditLogs } from "@/reducers/system/auditLogs/auditLogsAction";
import { Col, Container, Row } from "react-bootstrap";
import { enqueueSnackbar } from "notistack";
import { CardPage, CardPageHeader, CardPageBody, CardPageTitleWrap, CardPageFilterWrapper, CardPageTitle, CardPageSubhead, CardPageButtonWrap,CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import { useMemo } from "react";
import dayjs from "dayjs";
import { SearchFilters } from "@/shared/components/SearchFilters";
import { Grid } from "@mui/material";
import { FormDateField } from '@/shared/components/form/material-controls/DateField';
import SearchIcon from '@mui/icons-material/Search';
import AuditLogInfoModal from "./components/auditLogInfoModal";

const AuditLogs = () => {
    const dispatch = useDispatch();
    const { 
        auditLogs, auditLogsTotalCount, isLoadingAuditLogs, getAuditLogsError
    } = useSelector(({ auditLogs }) => auditLogs);

    const [selectedRows, setSelectedRows] = useState([]);
    const [searchFilters, setSearchFilters] = useState({ pageSize: 30, page: 1 });
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [selectedLog, setSelectedLog] = useState(null);

    useEffect(() => {
        return () => {
          dispatch(cleanup());
        }
    }, [dispatch]);

    
    useEffect(() => {
        if(getAuditLogsError){
            enqueueSnackbar(getAuditLogsError, {variant: "error"});
        }
    }, [getAuditLogsError]);

    useEffect(() => {
        dispatch(getAuditLogs(searchFilters));
    }, [searchFilters]);

    const setPaginationModel = (model) => {
        setSearchFilters({ 
            ...searchFilters, 
            page: model.page + 1,
            pageSize: model.pageSize
        });
    };

    const onFilterSubmit = (data) => {
        setSearchFilters({ ...searchFilters, page: 1, ...data});
    }

    const mappedAuditLogs = useMemo(() => {
        const clon = JSON.parse(JSON.stringify(auditLogs));
        clon.forEach(a => {
            a.userName = a.environment.userName;
            a.machineName = a.environment.machineName;
        });
        return clon;
    }, [auditLogs]);

    const onHideModal = () => {
        setShowInfoModal(false);
        setSelectedLog(null);
      };
    
      const openInfoModal = (selectedLogId) => {
        const log = auditLogs.find(a => a.id === selectedLogId);
        setSelectedLog(log);
        setShowInfoModal(true);
      };

    const columns = [
        { 
            field: 'userName',
            headerName: 'User',
            minWidth: 200,
            flex: 0.5
        },
        { 
            field: 'startDate', 
            headerName: 'Start', 
            type: 'date',
            minWidth: 200,
            flex: 0.5,
            valueFormatter: params => params.value ? dayjs(params.value).format("L LTS") : ""
        },
        { 
            field: 'endDate', 
            headerName: 'End', 
            type: 'date',
            minWidth: 200,
            flex: 0.5,
            valueFormatter: params => params.value ? dayjs(params.value).format("L LTS") : ""
        },
        { 
            field: 'eventType', 
            headerName: 'Event Type', 
            minWidth: 200,
            flex: 0.5
        },
        { 
            field: 'entityId', 
            headerName: 'Entity Id', 
            minWidth: 250,
            flex: 0.5
        },
        { 
            field: 'service', 
            headerName: 'Service', 
            minWidth: 250,
            flex: 0.5
        },
        { 
            field: 'dataType', 
            headerName: 'Data Type', 
            minWidth: 200,
            flex: 0.5
        },
        { 
            field: 'ip', 
            headerName: 'IP', 
            minWidth: 200,
            flex: 0.5
        },
        { 
            field: 'machineName', 
            headerName: 'Machine', 
            minWidth: 300,
            flex: 1
        },  
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => [
              <GridActionsCellItem
                icon={<SearchIcon />}
                label="View"
                onClick={() => openInfoModal(params.id)}
              />
            ],
        },
      ];

    return(
        <Container>
            <CardPage>
                <CardPageBody>
                    <CardPageHeader>
                        <CardPageTitleWrap>
                            <CardPageTitle>Audit Logs</CardPageTitle>
                            <CardPageSubhead>
                                Here you can view all the Audit Logs.
                            </CardPageSubhead>
                        </CardPageTitleWrap>
                    </CardPageHeader>

                    <CardPageFilterWrapper>                        
                        <SearchFilters 
                            searchLabel='Search'
                            onFilterSubmit={onFilterSubmit}
                            renderChildrenInputs={(control) => {
                                return(
                                <>
                                    <Grid item xs={12} md={3}>
                                        <FormDateField
                                            style={{margin: 0}}
                                            name="startDate"
                                            label="Start Date"
                                            control={control}
                                        />                                
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormDateField
                                            style={{margin: 0}}
                                            name="endDate"
                                            label="End Date"
                                            control={control}
                                        />
                                    </Grid>
                                </>
                                );
                            }}
                        />
                    </CardPageFilterWrapper>

                    <CardPageGridWrap>
                        <DataGrid 
                            autoHeight
                            density="compact"
                            disableRowSelectionOnClick
                            onRowSelectionModelChange={(newRowSelectionModel) => {
                                setSelectedRows(newRowSelectionModel);
                            }}
                            rowSelectionModel={selectedRows}
                            loading={isLoadingAuditLogs} 
                            rows={mappedAuditLogs}
                            columns={columns} 
                            slots={{ toolbar: GridToolbar }}
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                py: 1,
                                },
                            }}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 30 } },
                            }}
                            pageSizeOptions={[15, 30, 50, 100]}
                            rowCount={auditLogsTotalCount}
                            paginationModel={{
                                page: searchFilters.page - 1, 
                                pageSize: searchFilters.pageSize}}
                            paginationMode="server"
                            onPaginationModelChange={setPaginationModel}
                        />
                    </CardPageGridWrap>

                    { showInfoModal &&
                        <AuditLogInfoModal 
                            onHideModal={onHideModal} 
                            showModal={showInfoModal}
                            auditLog={selectedLog}
                        />
                    }
                </CardPageBody>
            </CardPage>
        </Container>
    )
}

export default AuditLogs;