import { Controller } from "react-hook-form";
import { Box, IconButton, Slider, Typography } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { FormCheckboxField } from "./CheckboxField";
import { useEffect, useState } from "react";

export const FormRangeSliderField = ({ 
  name, 
  canEnable,
  defaultEnabled,
  nameCheckbox,
  labelCheckbox,
  watch,
  control, 
  setValue,
  getValues,
  label, 
  rules, 
  defaultValue=[4, 6], 
  min=0, 
  max=10, 
  step=1, 
  marginTop=16, 
  showPercent=false, 
  showArrows=false, 
  decimals,
  ...other 
}) => {
  
  const [enabled, setEnabled] = useState(defaultEnabled);

  const decreaseValue = () => {
    var currentValue = getValues(name);

    if(decimals)
      currentValue[0] = (((parseFloat(currentValue[0]) * 1000) - (step * 1000)) / 1000).toFixed(decimals);
    else
      currentValue[1] += step;
    
    if(currentValue[0] <= min){
      currentValue[0] = min;
    }

    setValue(name, currentValue);
  };

  const increaseValue = () => {
    var currentValue = getValues(name);

    if(decimals)
      currentValue[1] = ((parseFloat(currentValue[1]) * 1000 + step * 1000) / 1000).toFixed(decimals);
    else
      currentValue[1] += step;

    if(currentValue[1] >= max){
      currentValue[1] = max;
    }

    setValue(name, currentValue);
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if(name === nameCheckbox){
          setEnabled(value[nameCheckbox]);
      }
    });
  
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <div style={{marginTop: marginTop, paddingInline: 10}}>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}>
              <Typography style={{marginLeft: 8.5}}>{label}: {value[0]} - {value[1]}</Typography>
              { canEnable &&
                <FormCheckboxField 
                  name={nameCheckbox}
                  label={labelCheckbox}
                  control={control}
                  defaultValue={defaultEnabled}
                />
              }
            </Box>
            { !showArrows ?
            <Slider
                max={max}
                min={min}
                step={step}
                size="medium"
                valueLabelDisplay="auto"
                onChange={onChange}
                value={value}
                disabled={!enabled}
                {...other}
            />
            :
              <Box sx={{
                display: 'flex', 
                alignItems: 'center'
              }}>
                <IconButton onClick={decreaseValue}>
                  <ChevronLeftIcon />
                </IconButton>
                <Slider
                  max={max}
                  min={min}
                  step={step}
                  size="medium"
                  valueLabelDisplay="auto"
                  onChange={onChange}
                  value={value}
                  disabled={!enabled}
                  {...other}
                />
                <IconButton onClick={increaseValue}>
                  <ChevronRightIcon />
                </IconButton>
              </Box>
            }
        </div>
      )}
    />
  );
};