import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row } from "react-bootstrap";
import { useSelector,useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { Backdrop, Button, Card, CardContent, CardHeader, CircularProgress, Typography } from "@mui/material";
import styled from 'styled-components';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { HasUpperCase, HasLowerCase, HasNonAlphanumeric, HasNumber } from "@/utils/formValidations";
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { FormCheckboxField } from '@/shared/components/form/material-controls/CheckboxField';
import { cleanup, updateUserOptions, getUserOptions } from '@/reducers/account/settings/userOption/userOptionAction';
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';


  const UserOption = () => {

    const dispatch = useDispatch();
    
    const { 
        userOption, isLoadingUserOptions,
        isUpdatingUserOptions, updatedUserOptions, updateUserOptionsError,
      } = useSelector(({ userOption }) => userOption);

      
    const { handleSubmit, reset, control, setValue, getValues, watch } = useForm();

    useEffect(() => {
        return () => {
          dispatch(cleanup());
        }
      }, [dispatch]);

    useEffect(() => {
        return () => {
          reset();
        }
      }, []);

    useEffect(() => {
        if (updateUserOptionsError) {
            enqueueSnackbar(updateUserOptionsError, { variant: "error" });
        }
    }, [updateUserOptionsError]);

    useEffect(() => {
        if (updatedUserOptions) {
            enqueueSnackbar("Account setting updated successfully", { variant: "success" });
            getUserOptions();
        }
    }, [userOption]);

    const onSubmit = (data) => {
        dispatch(updateUserOptions(data));
    }
      
    return (
        <Card>
            <CardContent>
                <TitleContainer>
                    <ManageAccountsIcon />
                    <Typography variant='h5'>User account settings</Typography>
                </TitleContainer>              
                {isLoadingUserOptions || !userOption ? <CircularProgressWrapper /> :        
                    <Row>
                        <Row>
                            <Col md={12}>
                                <FormTextField
                                    name="userName"
                                    label="Username"
                                    control={control}
                                    defaultValue={userOption.userName}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormTextField
                                    name="email"
                                    label="Email"
                                    control={control}
                                    defaultValue={userOption.email}
                                    disabled={true}
                                />
                            </Col>
                            <Col md={6}>
                                <FormTextField
                                    name="phoneNumber"
                                    label="Mobile Phone Number"
                                    control={control}
                                    defaultValue={userOption.phoneNumber}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormCheckboxField
                                    name="emailConfirmed"
                                    label="Email Confirmed"
                                    control={control}
                                    defaultValue={userOption.emailConfirmed}
                                    disabled={true}
                                />
                            </Col>
                            <Col md={6}>
                                <FormCheckboxField
                                    name="phoneNumberConfirmed"
                                    label="Phone Number Confirmed"
                                    control={control}
                                    defaultValue={userOption.phoneNumberConfirmed}
                                    disabled={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormTextField
                                    name="accessFailCount"
                                    label="Access Fail Count"
                                    control={control}
                                    defaultValue={userOption.accessFailCount}
                                    disabled={true}
                                />
                            </Col>
                            <Col md={6}>
                                <FormTextField
                                    name="lockoutEnd"
                                    label="Lockout End"
                                    control={control}
                                    defaultValue={userOption.lockoutEnd}
                                    disabled={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormCheckboxField
                                    name="lockoutEnabled"
                                    label="Lockout Enabled"
                                    control={control}
                                    defaultValue={userOption.lockoutEnabled}
                                    disabled={true}
                                />
                            </Col>
                            <Col md={6}>
                                <FormTextField
                                    name="lastAccessDate"
                                    label="Last Access"
                                    control={control}
                                    defaultValue={!userOption.lastAccessDate ? '' : userOption.lastAccessDate}
                                    disabled={true}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 10 }}>
                        <Col md={12}>
                            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                <Button 
                                    variant="contained"
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    Update settings
                                </Button>
                            </div>
                        </Col>
                        </Row>
                    </Row>
                }
            </CardContent>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isUpdatingUserOptions}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Card>
    );
};

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 20px;

  & > svg{
    margin-right: 5px;
    font-size: 30px;
  }
`;

export default UserOption;