import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getRemediationRequest,
  getRemediationSuccess,
  getRemediationError,
  getReportRequest,
  getReportSuccess,
  getReportError,
} from './assetVulnerabilityReportAction';

const defaultState = {
  remediation: null,
  isLoadingRemediation: true,
  getRemediationError: null,
  report: null,
  isLoadingReport: true,
  getReportError: null,
};

export default handleActions(
  {
    [getRemediationRequest](state) {
      return {
        ...state,
        isLoadingRemediation: true,
        getRemediationError: null,
      };
    },
    [getRemediationSuccess](state, { payload }) {
      return {
        ...state,
        remediation: payload,
        isLoadingRemediation: false,
        getRemediationError: null,
      };
    },
    [getRemediationError](state, { payload }) {
      return {
        ...state,
        isLoadingRemediation: false,
        getRemediationError: payload,
      };
    },
    [getReportRequest](state) {
      return {
        ...state,
        isLoadingReport: true,
        getReportError: null,
      };
    },
    [getReportSuccess](state, { payload }) {
      return {
        ...state,
        report: payload,
        isLoadingReport: false,
        getReportError: null,
      };
    },
    [getReportError](state, { payload }) {
      return {
        ...state,
        isLoadingReport: false,
        getReportError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);