import { createAction } from 'redux-actions';
import { instance } from "../../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_TWO_FACTOR_SETTINGS');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}


export const getTwoFactorSettingsRequest = createAction('GET_TWO_FACTOR_SETTINGS_REQUEST');
export const getTwoFactorSettingsSuccess = createAction('GET_TWO_FACTOR_SETTINGS_SUCCESS');
export const getTwoFactorSettingsError = createAction('GET_TWO_FACTOR_SETTINGS_ERROR');

export const getTwoFactorSettings = () => async (dispatch) => {
  try {
    dispatch(getTwoFactorSettingsRequest());
    const resp = await instance.get(`/v1/Account/GetTwoFactorAuthenticationEnabled`);
    
    dispatch(getTwoFactorSettingsSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getTwoFactorSettingsError(e.response.data.description));
    else
      dispatch(getTwoFactorSettingsError(e.message));
  }
};

export const disable2FARequest = createAction('DISABLE_2FA_REQUEST');
export const disable2FASuccess = createAction('DISABLE_2FA_SUCCESS');
export const disable2FAError = createAction('DISABLE_2FA_ERROR');

export const disable2FAs = (data) => async (dispatch) => {
  try {

    const resp = await instance.post(`/v1/Account/SetTwoFactorAuthenticationDisabled`);

    dispatch(disable2FASuccess(resp.data));

  } catch (e) {

    if (e.response.data && e.response.data.description)
      dispatch(disable2FAError(e.response.data.description));
    else
      dispatch(disable2FAError(e.message));
  }
};

export const setup2FARequest = createAction('SETUP_2FA_REQUEST');
export const setup2FASuccess = createAction('SETUP_2FA_SUCCESS');
export const setup2FAError = createAction('SETUP_2FA_ERROR');

export const setup2FAs = (data) => async (dispatch) => {
  try {

    const resp = await instance.post(`/v1/Account/TwoFactorAuthenticationSetup`, data);

    dispatch(setup2FASuccess(resp.data));

  } catch (e) {

    if (e.response.data && e.response.data.description)
      dispatch(setup2FAError(e.response.data.description));
    else
      dispatch(setup2FAError(e.message));
  }
};

// export const updateUserOptionsRequest = createAction('USER_OPTIONS_UPDATE_REQUEST');
// export const updateUserOptionsSuccess = createAction('USER_OPTIONS_UPDATE_SUCCESS');
// export const updateUserOptionsError = createAction('USER_OPTIONS_UPDATE_ERROR');

// export const updateUserOptions = (data) => async (dispatch) => {
//     try {
//       dispatch(updateUserOptionsRequest());
//       const resp = await instance.put(`/v1/Account/UpdateUserOption`, data);
      
//       dispatch(updateUserOptionsSuccess(resp.data));
  
//     } catch (e) {
//       if(e.response.data && e.response.data.description)
//         dispatch(updateUserOptionsError(e.response.data.description));
//       else
//         dispatch(updateUserOptionsError(e.message));
//     }
//   };

