import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_ASSET_VULNERABILITY_REPORT');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getRemediationRequest = createAction('ASSET_VULNERABILITY_REPORT_GET_REMEDIATION_REQUEST');
export const getRemediationSuccess = createAction('ASSET_VULNERABILITY_REPORT_GET_REMEDIATION_SUCCESS');
export const getRemediationError = createAction('ASSET_VULNERABILITY_REPORT_GET_REMEDIATION_ERROR');

export const getRemediation = (id) => async (dispatch) => {
  try {
    dispatch(getRemediationRequest());
    const resp = await instance.get(`/v1/Remediation/GetByWorkflowActivityId?WorkflowActivityId=${id}`);
    
    dispatch(getRemediationSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getRemediationError(e.response.data.description));
    else
      dispatch(getRemediationError(e.message));
  }
};

export const getReportRequest = createAction('ASSET_VULNERABILITY_REPORT_GET_REPORT_REQUEST');
export const getReportSuccess = createAction('ASSET_VULNERABILITY_CHECK_LISTREPORT_GET_REPORT_SUCCESS');
export const getReportError = createAction('ASSET_VULNERABILITY_REPORT_GET_REPORT_ERROR');

export const getReport = (id) => async (dispatch) => {
  try {
    dispatch(getReportRequest());
    const resp = await instance.get(`/v1/Dashboard/GetRemediationReport?remediationId=${id}`);
    
    dispatch(getReportSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getReportError(e.response.data.description));
    else
      dispatch(getReportError(e.message));
  }
};