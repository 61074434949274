import * as type from "../types";

export const changeTheme = (theme) => {
  return async (dispatch) => {
    await dispatch({
      type: type.CHANGE_THEME,
      theme: theme === "dark" ? "light" : "dark",
    });
  };
};
