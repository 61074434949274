import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ProfileMain from './components/ProfileMain';
import ProfileSettings from './components/ProfileSettings';
import ProfileCalendar from './components/ProfileCalendar';
import ProfileTasks from './components/ProfileTasks';
import ProfileTabs from './components/ProfileTabs';
import { CardPage, CardPageBody, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap, CardPageGridWrap } from '@/shared/components/MaterialCardPage';


const Calendar = () => (
  <Container>
        <CardPage>
          <CardPageBody>         
              <CardPageTitleWrap>
                <CardPageTitle>User Profile</CardPageTitle>
                <CardPageSubhead>
                  Here you can view and modify User Profile.
                </CardPageSubhead>
              </CardPageTitleWrap>
              <CardPageButtonWrap>
               
              </CardPageButtonWrap>
              <CardPageGridWrap>
                <ProfileMain/>
              </CardPageGridWrap>
          </CardPageBody>
        </CardPage>
  </Container>
);

export default Calendar;
