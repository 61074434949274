import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getClassificationsRequest,
  getClassificationsSuccess,
  getClassificationsError,
  getRemediationRequest,
  getRemediationSuccess,
  getRemediationError,
  getCheckListRequest,
  getCheckListSuccess,
  getCheckListError,
  getSchemaRequest,
  getSchemaSuccess,
  getSchemaError,
  getDomainsRequest,
  getDomainsSuccess,
  getDomainsError,
  getRatingScoresRequest,
  getRatingScoresSuccess,
  getRatingScoresError,
  getContextualScoresRequest,
  getContextualScoresSuccess,
  getContextualScoresError,
  getVulnerabilityStatusesRequest,
  getVulnerabilityStatusesSuccess,
  getVulnerabilityStatusesError,
  saveCheckListRequest,
  saveCheckListSuccess,
  saveCheckListError,
  removeItemsRequest,
  removeItemsSuccess,
  removeItemsError,
  resetTableDataRequest
} from './assetVulnerabilityCheckListAction';

const defaultState = {
  remediation: null,
  isLoadingRemediation: true,
  getRemediationError: null,
  schema: [],
  isLoadingSchema: true,
  getSchemaError: null,
  vulnerabilityStatuses: [],
  isLoadingVulnerabilityStatuses: true,
  getVulnerabilityStatusesError: null,
  classifications: [],
  isLoadingClassifications: true,
  getClassificationsError: null,
  domains: [],
  isLoadingDomains: true,
  getDomainsError: null,
  ratingScores: [],
  isLoadingRatingScores: true,
  getRatingScoresError: null,
  contextualScores: [],
  isLoadingContextualScores: true,
  getRatingContextualError: null,
  checkList: [],
  isLoadingCheckList: false,
  getCheckListError: null,
  isSavingCheckList: false,
  savedCheckList: false,
  saveCheckListError: null,
  isRemovingItems: false,
  removedItems: false,
  removeItemsError: null
};

export default handleActions(
  {
    [getRemediationRequest](state) {
      return {
        ...state,
        isLoadingRemediation: true,
        getRemediationError: null,
      };
    },
    [getRemediationSuccess](state, { payload }) {
      return {
        ...state,
        remediation: payload,
        isLoadingRemediation: false,
        getRemediationError: null,
      };
    },
    [getRemediationError](state, { payload }) {
      return {
        ...state,
        isLoadingRemediation: false,
        getRemediationError: payload,
      };
    },
    [getSchemaRequest](state) {
      return {
        ...state,
        isLoadingSchema: true,
        getSchemaError: null,
      };
    },
    [getSchemaSuccess](state, { payload }) {
      return {
        ...state,
        schema: payload,
        isLoadingSchema: false,
        getSchemaError: null,
      };
    },
    [getSchemaError](state, { payload }) {
      return {
        ...state,
        isLoadingSchema: false,
        getSchemaError: payload,
      };
    },    
    [getDomainsRequest](state) {
      return {
        ...state,
        isLoadingDomains: true,
        getDomainsError: null,
      };
    },
    [getDomainsSuccess](state, { payload }) {
      return {
        ...state,
        domains: payload,
        isLoadingDomains: false,
        getDomainsError: null,
      };
    },
    [getDomainsError](state, { payload }) {
      return {
        ...state,
        isLoadingDomains: false,
        getDomainsError: payload,
      };
    },
    [getVulnerabilityStatusesRequest](state) {
      return {
        ...state,
        isLoadingVulnerabilityStatuses: true,
        getVulnerabilityStatusesError: null,
      };
    },
    [getVulnerabilityStatusesSuccess](state, { payload }) {
      return {
        ...state,
        vulnerabilityStatuses: payload,
        isLoadingVulnerabilityStatuses: false,
        getVulnerabilityStatusesError: null,
      };
    },
    [getVulnerabilityStatusesError](state, { payload }) {
      return {
        ...state,
        isLoadingVulnerabilityStatuses: false,
        getVulnerabilityStatusesError: payload,
      };
    },   
    [getClassificationsRequest](state) {
      return {
        ...state,
        isLoadingClassifications: true,
        getClassificationsError: null,
      };
    },
    [getClassificationsSuccess](state, { payload }) {
      return {
        ...state,
        classifications: payload,
        isLoadingClassifications: false,
        getClassificationsError: null,
      };
    },
    [getClassificationsError](state, { payload }) {
      return {
        ...state,
        isLoadingClassifications: false,
        getClassificationsError: payload,
      };
    }, 
    [getRatingScoresRequest](state) {
      return {
        ...state,
        isLoadingRatingScores: true,
        getRatingScoresError: null,
      };
    },
    [getRatingScoresSuccess](state, { payload }) {
      return {
        ...state,
        ratingScores: payload,
        isLoadingRatingScores: false,
        getRatingScoresError: null,
      };
    },
    [getRatingScoresError](state, { payload }) {
      return {
        ...state,
        isLoadingRatingScores: false,
        getRatingScoresError: payload,
      };
    },    
    [getContextualScoresRequest](state) {
      return {
        ...state,
        isLoadingContextualScores: true,
        getContextualScoresError: null,
      };
    },
    [getContextualScoresSuccess](state, { payload }) {
      return {
        ...state,
        contextualScores: payload,
        isLoadingContextualScores: false,
        getContextualScoresError: null,
      };
    },
    [getContextualScoresError](state, { payload }) {
      return {
        ...state,
        isLoadingContextualScores: false,
        getContextualScoresError: payload,
      };
    },    
    [getCheckListRequest](state) {
      return {
        ...state,
        isLoadingCheckList: true,
        getCheckListError: null,
      };
    },
    [getCheckListSuccess](state, { payload }) {
      return {
        ...state,
        checkList: payload,
        isLoadingCheckList: false,
        getCheckListError: null,
      };
    },
    [getCheckListError](state, { payload }) {
      return {
        ...state,
        isLoadingCheckList: false,
        getCheckListError: payload,
      };
    },
    [saveCheckListRequest](state) {
      return {
        ...state,
        isSavingCheckList: true,
        savedCheckList: false,
        saveCheckListError: null
      };
    },
    [saveCheckListSuccess](state, { payload }) {
      return {
        ...state,
        isSavingCheckList: false,
        savedCheckList: true,
        saveCheckListError: null
      };
    },
    [saveCheckListError](state, { payload }) {
      return {
        ...state,
        isSavingCheckList: false,
        savedCheckList: false,
        saveCheckListError: payload
      };
    },
    [removeItemsRequest](state) {
      return {
        ...state,
        isRemovingItems: true,
        removedItems: false,
        removeItemsError: null
      };
    },
    [removeItemsSuccess](state, { payload }) {
      return {
        ...state,
        isRemovingItems: false,
        removedItems: true,
        removeItemsError: null
      };
    },
    [removeItemsError](state, { payload }) {
      return {
        ...state,
        isRemovingItems: false,
        removedItems: false,
        removeItemsError: payload
      };
    },
    [resetTableDataRequest](state, { payload }) {
      return {
        ...state,
        checkList: payload
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);