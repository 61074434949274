import { createAction } from 'redux-actions';
import { instance } from "../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_BUSINESSUNITS_STATUSES');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getBusinessUnitsRequest = createAction('BUSINESSUNITS_GET_ALL_REQUEST');
export const getBusinessUnitsSuccess = createAction('BUSINESSUNITS_GET_ALL_SUCCESS');
export const getBusinessUnitsError = createAction('BUSINESSUNITS_GET_ALL_ERROR');

export const getBusinessUnits = () => async (dispatch) => {
  try {
    dispatch(getBusinessUnitsRequest());
    const resp = await instance.get(`/v1/BusinessUnit/GetListByFilter`);
    
    dispatch(getBusinessUnitsSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getBusinessUnitsError(e.response.data.description));
    else
      dispatch(getBusinessUnitsError(e.message));
  }
};

export const deleteVulnerabilityStatusRequest = createAction('BUSINESSUNITS_DELETE_REQUEST');
export const deleteVulnerabilityStatusSuccess = createAction('BUSINESSUNITS_DELETE_SUCCESS');
export const deleteVulnerabilityStatusError = createAction('BUSINESSUNITS_DELETE_ERROR');

export const deleteVulnerabilityStatus = (id) => async (dispatch) => {
  try {
    dispatch(deleteVulnerabilityStatusRequest());
    const resp = await instance.delete(`/v1/BusinessUnit/DeleteLogic/${id}`);

    dispatch(deleteVulnerabilityStatusSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(deleteVulnerabilityStatusError(e.response.data.description));
    else
      dispatch(deleteVulnerabilityStatusError(e.message));
  }
};