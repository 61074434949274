import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getAuditCampaignRequest,
  getAuditCampaignSuccess,
  getAuditCampaignError,
  getSurveysRequest,
  getSurveysSuccess,
  getSurveysError,
  confirmSurveysRequest,
  confirmSurveysSuccess,
  confirmSurveysError
} from './AUSelectSurveysAction';

const defaultState = {
  auditCampaign: null, 
  isLoadingAuditCampaign: true, 
  getAuditCampaignError: null,
  surveys: [],
  isLoadingSurveys: true,
  getSurveysError: null,
  isConfirmingSurveys: false, 
  confirmedSurveys: false, 
  confirmSurveysError: null
};

export default handleActions(
  {
    [getAuditCampaignRequest](state) {
      return {
        ...state,
        auditCampaign: null,
        isLoadingAuditCampaign: true,
        getAuditCampaignError: null,
      };
    },
    [getAuditCampaignSuccess](state, { payload }) {
      return {
        ...state,
        auditCampaign: payload,
        isLoadingAuditCampaign: false,
        getAuditCampaignError: null,
      };
    },
    [getAuditCampaignError](state, { payload }) {
      return {
        ...state,
        auditCampaign: null,
        isLoadingAuditCampaign: false,
        getAuditCampaignError: payload,
      };
    },
    [getSurveysRequest](state) {
      return {
        ...state,
        surveys: [],
        isLoadingSurveys: true,
        getSurveysError: null,
      };
    },
    [getSurveysSuccess](state, { payload }) {
      return {
        ...state,
        surveys: payload,
        isLoadingSurveys: false,
        getSurveysError: null,
      };
    },
    [getSurveysError](state, { payload }) {
      return {
        ...state,
        surveys: [],
        isLoadingSurveys: false,
        getSurveysError: payload,
      };
    },
    [confirmSurveysRequest](state) {
      return {
        ...state,
        isConfirmingSurveys: true,
        confirmSurveysError: null,
        confirmedSurveys: false
      };
    },
    [confirmSurveysSuccess](state, { payload }) {
      return {
        ...state,
        isConfirmingSurveys: false,
        confirmSurveysError: null,
        confirmedSurveys: true
      };
    },
    [confirmSurveysError](state, { payload }) {
      return {
        ...state,
        isConfirmingSurveys: false,
        confirmSurveysError: payload,
        confirmedSurveys: false
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);