import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_SURVEY_LIST');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getSurveysRequest = createAction('SURVEY_LIST_GET_REQUEST');
export const getSurveysSuccess = createAction('SURVEY_LIST_GET_SUCCESS');
export const getSurveysError = createAction('SURVEY_LIST_GET_ERROR');

export const getSurveys = (workflowActivityId) => async (dispatch) => {
  try {
    dispatch(getSurveysRequest());
    const resp = await instance.get(`/v1/ActivitySurvey/GetSurveyList`, {
      params: {
        workflowActivityId
      }
    });
    
    dispatch(getSurveysSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getSurveysError(e.response.data.description));
    else
      dispatch(getSurveysError(e.message));
  }
};

export const updateSurveyProgressRequest = createAction('SURVEY_LIST_UPDATE_PROGRESS');

export const updateSurveyProgress = (surveyId, saveResult) => async (dispatch) => {
    dispatch(updateSurveyProgressRequest({surveyId, saveResult}));
};

export const completeCustomSurveyRequest = createAction('SURVEY_LIST_COMPLETE_CUSTOM_SURVEY');

export const completeCustomSurvey = (surveyId) => async (dispatch) => {
    dispatch(completeCustomSurveyRequest({surveyId}));
};
