import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export const SelectCell = ({ name, label, value, options, onChange, ...other }) => {
    return (
        <Autocomplete
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                '& > .MuiFormControl-root': { display: 'flex', flexDirection: 'row' }
            }}
            disableClearable={true}
            disablePortal
            options={options}
            value={value}
            onChange={onChange}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => 
                <TextField 
                    {...params} 
                    size="small"
                    fullWidth
                    label={label}
                    variant="outlined"
                    {...other}
                />
            }
        />
    )
};