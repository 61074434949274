import { Backdrop, Button, CircularProgress, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridToolbar, gridClasses } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { cleanup, getVulnerabilityStatuses, deleteVulnerabilityStatus } from "@/reducers/vulnerabilityStatuses/vulnerabilityStatusesAction";
import { Col, Container, Row } from "react-bootstrap";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { enqueueSnackbar } from "notistack";
import CreateVulnerabilityStatusModal from "./components/createVulnerabilityStatusModal";
import EditVulnerabilityStatusModal from "./components/editVulnerabilityStatusModal";
import AddIcon from '@mui/icons-material/Add';
import ConfirmationDialog from '@/shared/components/ConfirmationDialog';
import { CardPage, CardPageFilterWrapper, CardPageHeader, CardPageBody, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap,CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import ColorCell from "../../../shared/components/datagrid/ColorCell";

const VulnerabilityStatuses = () => {

    const dispatch = useDispatch();
    const { 
        vulnerabilityStatuses, isLoadingVulnerabilityStatuses, getVulnerabilityStatusesError,
        isDeletingVulnerabilityStatus, deletedVulnerabilityStatus, deleteVulnerabilityStatusError,
    } = useSelector(({ vulnerabilityStatuses }) => vulnerabilityStatuses);

    const [showVulnerabilityStatusCreateModal, setShowVulnerabilityStatusCreateModal] = useState(false);

    const [showVulnerabilityStatusEditModal, setShowVulnerabilityStatusEditModal] = useState(false);
    const [selectedVulnerabilityStatus, setSelectedVulnerabilityStatus] = useState(null);

    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [vulnerabilityStatusToDelete, setVulnerabilityStatusToDelete] = useState(null);

    useEffect(() => {
        dispatch(getVulnerabilityStatuses());
    
        return () => {
          dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
        if(getVulnerabilityStatusesError){
            enqueueSnackbar(getVulnerabilityStatusesError, {variant: "error"});
        }
    }, [getVulnerabilityStatusesError]);

    useEffect(() => {
        if(deleteVulnerabilityStatusError){
            enqueueSnackbar(deleteVulnerabilityStatusError, {variant: "error"});
        }
    }, [deleteVulnerabilityStatusError]);

    const handleClickEditVulnerabilityStatus = (vulnerabilityStatus) => {
        setSelectedVulnerabilityStatus(vulnerabilityStatus);
        setShowVulnerabilityStatusEditModal(true);
    }

    const handleClickDeleteVulnerabilityStatus = (vulnerabilityStatus) => {
        setVulnerabilityStatusToDelete(vulnerabilityStatus);
        setOpenConfirmDelete(true);
    }

    const onClickCreateCreateModal = () => {
        setShowVulnerabilityStatusCreateModal(true);
    };

    const onHideVulnerabilityStatusCreateModal = () => {
        setShowVulnerabilityStatusCreateModal(false);
    };

    const onHideVulnerabilityStatusEditModal = () => {
        setShowVulnerabilityStatusEditModal(false);
        setSelectedVulnerabilityStatus(null);
    };

    const onVulnerabilityStatusCreated = () => {
        setShowVulnerabilityStatusCreateModal(false);
        dispatch(getVulnerabilityStatuses());
    }
    
    const onVulnerabilityStatusUpdated = () => {
        setShowVulnerabilityStatusEditModal(false);
        setSelectedVulnerabilityStatus(null);
        dispatch(getVulnerabilityStatuses());
    }

    const handleConfirmDelete = () => {
        setOpenConfirmDelete(false);
        dispatch(deleteVulnerabilityStatus(vulnerabilityStatusToDelete.id));
        setVulnerabilityStatusToDelete(null);
    }

    const handleOnCloseDelete = () => {
        setOpenConfirmDelete(false);
        setVulnerabilityStatusToDelete(null);
    };

    useEffect(() => {
        if(deletedVulnerabilityStatus){
            enqueueSnackbar("Vulnerability Status deleted successfully", {variant: "success"});
            dispatch(getVulnerabilityStatuses());
        }
    }, [deletedVulnerabilityStatus]);

    const columns = [
        { 
            field: 'nameTraslated', 
            headerName: 'Name',
            minWidth: 150,
            flex: 1,
        },
        { 
            field: 'descriptionTraslated', 
            headerName: 'Description',
            minWidth: 250,
            flex: 1,
        },
        { 
            field: 'value', 
            headerName: 'Value',
            minWidth: 150,
            flex: 0.5,
        },
        { 
            field: 'color', 
            headerName: 'Color',
            minWidth: 150,
            flex: 0.5,
            renderCell: (params) => <ColorCell color={params.value}/>,
        },
        { 
            field: 'systemVersionData', 
            headerName: 'System Version',
            minWidth: 150,
            flex: 0.5,
        },
        { 
            field: 'isSystem', 
            headerName: 'System',
            minWidth: 150,
            flex: 0.5,
            type: 'boolean',
        },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => {
                const actions = [];

                if(!params.row.isSystem && !params.row.isReadOnly){
                    actions.push(
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="Delete"
                            onClick={() => handleClickDeleteVulnerabilityStatus(params.row)}
                        />
                    );
                }

                if(!params.row.isReadOnly){
                    actions.push(
                        <GridActionsCellItem
                            icon={<EditIcon />}
                            label="Edit"
                            onClick={() => handleClickEditVulnerabilityStatus(params.row)}
                        />
                    );
                }

                return actions;
            }
          },
      ];

    return(
        <Container>
            <CardPage>
                <CardPageBody>
                    <CardPageHeader>
                        <CardPageTitleWrap>
                            <CardPageTitle>Vulnerability Statuses</CardPageTitle>
                            <CardPageSubhead>
                                View and manage your vulnerability statuses.
                            </CardPageSubhead>
                        </CardPageTitleWrap>
                        <CardPageButtonWrap>
                            <Button 
                                variant="contained" 
                                onClick={onClickCreateCreateModal} 
                                endIcon={<AddIcon/>}
                            >
                                Create Vulnerability Status
                            </Button>
                        </CardPageButtonWrap>
                    </CardPageHeader>

                    <CardPageGridWrap>
                        <DataGrid 
                            autoHeight
                            density="compact"
                            loading={isLoadingVulnerabilityStatuses} 
                            rows={vulnerabilityStatuses}
                            columns={columns} 
                            slots={{ toolbar: GridToolbar }} 
                            slotProps={{
                                toolbar: {
                                showQuickFilter: true
                                },
                            }}
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                py: 1,
                                },
                            }}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 30 } },
                            }}
                            pageSizeOptions={[15, 30, 50, 100]}
                        />
                    </CardPageGridWrap>

                    { showVulnerabilityStatusCreateModal &&
                        <CreateVulnerabilityStatusModal
                            onHideModal={onHideVulnerabilityStatusCreateModal}
                            open={showVulnerabilityStatusCreateModal}
                            onVulnerabilityStatusCreated={onVulnerabilityStatusCreated}
                        />
                    }

                    { showVulnerabilityStatusEditModal &&
                        <EditVulnerabilityStatusModal
                            onHideModal={onHideVulnerabilityStatusEditModal}
                            open={showVulnerabilityStatusEditModal}
                            onVulnerabilityStatusUpdated={onVulnerabilityStatusUpdated}
                            vulnerabilityStatus={selectedVulnerabilityStatus}
                        />
                    }

                    { openConfirmDelete &&
                        <ConfirmationDialog 
                            open={openConfirmDelete}
                            onConfirm={handleConfirmDelete}
                            onClose={handleOnCloseDelete}
                            title="Delete Vulnerability Status"
                            body={`Are you sure you want to delete ${vulnerabilityStatusToDelete.nameTraslated}?`}
                            color='error'
                            confirmButtonText='Delete'
                        />
                    }

                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isDeletingVulnerabilityStatus}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>

                </CardPageBody>
            </CardPage>
        </Container>
    )
}

export default VulnerabilityStatuses;