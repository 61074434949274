import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row} from 'react-bootstrap';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { getSchema, getAssets, cleanup } from '@/reducers/cytoscape/advancedFilters/advancedFiltersAction';
import PropTypes from 'prop-types';
import { enqueueSnackbar } from "notistack";
import { Box, Button, Divider } from "@mui/material";
import { FormSelectField } from "@/shared/components/form/material-controls/SelectField";
import { FormMultiSelectField } from "@/shared/components/form/material-controls/MultiSelectField";
import MaterialDynamicFilters from '@/shared/components/dynamic-filters/dynamicFilters';
import SearchIcon from '@mui/icons-material/Search';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import MultiSelectAllField from "../../../../shared/components/form/material-controls/MultiSelectAllField";

const AdvancedFilters = ({ assetTypes, handleGetNodes, handleClearAll }) => {

  const dispatch = useDispatch();

  const { 
    schema, isLoadingSchema, getSchemaError, 
    assets, isLoadingAssets, getAssetsError 
  } = useSelector(({ advancedFilters }) => advancedFilters);

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
  } = useForm();

  const [assetsValue, setAssetsValue] = useState([]);

  useEffect(() => {
    dispatch(getSchema());

    return () => {
      dispatch(cleanup());
    }
  }, [dispatch]);

  useEffect(() => {
    if(getSchemaError){
      enqueueSnackbar(getSchemaError, {variant: "error"});
    }
  }, [getSchemaError]);

  useEffect(() => {
    if(getAssetsError){
      enqueueSnackbar(getAssetsError, {variant: "error"});
    }
  }, [getAssetsError]);
  
  const onSubmit = (data) => {
    var keyValue = {};
    
    data.dynamicFilter.forEach(f => {
      keyValue[f.key] = f.value;
    });
    
    if(data.assetType){
      keyValue.assetTypeId = data.assetType;
    }

    setAssetsValue([]);
    dispatch(getAssets(keyValue));
  };

  const onClickViewAll = () => {
    if(assetsValue && assetsValue.length)
    {
      handleGetNodes(assetsValue);
    }
  };

  const onClickClearAll = () => {
    handleClearAll();
  };

  return (
    <div>
      <Row>
          <Col md={12}>
            <FormMultiSelectField 
              name="assetType"
              label="Asset Types"
              control={control}
              options={assetTypes}
              keyValue={{label: "nameTraslated", id: "id"}}
              style={{marginTop: 0}}
            />
          </Col>

          <Col md={12}>
            <MaterialDynamicFilters 
              controller="Asset"
              schema={schema} 
              control={control} 
              name="dynamicFilter" 
            />
          </Col>

          <SearchAssetsButtonContainer>
            <Button 
              variant="outlined"
              onClick={handleSubmit(onSubmit)}
              endIcon={<SearchIcon />}
              disabled={isLoadingAssets}
            >
              Search Assets
            </Button>
          </SearchAssetsButtonContainer>

        <Divider component='div' />

        <Col md={12} style={{marginBlock: 20}}>
          <MultiSelectAllField 
            style={{marginTop: 0}}
            name="assets"
            label="Assets"
            options={assets}
            keyValue={{label: "label", id: "value"}}
            limitTags={3}
            value={assetsValue}
            setValue={setAssetsValue}
          />
        </Col>
        <Col xs={6}>
            <Button
              style={{width: '100%'}}
              onClick={ onClickViewAll }
              variant='contained'
            >
              Add to chart
            </Button>
        </Col>
        <Col xs={6}>
            <Button
              style={{width: '100%'}}
              onClick={ onClickClearAll }
              variant='contained'
              color="error"
            >
              Clear all
            </Button>
        </Col>
      </Row>
    </div>
  );
};

const SearchAssetsButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  margin-top: 20px;
  margin-bottom: 20px;
`;

AdvancedFilters.propTypes = {
  assetTypes: PropTypes.array.isRequired,
  handleGetAllPath: PropTypes.func,
  handleGetParentChild: PropTypes.func,
};

export default AdvancedFilters;