import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getSurvey, cleanup } from "@/reducers/surveyTest/surveyTestAction";
import "survey-core/defaultV2.min.css";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "./survey.css";
import { enqueueSnackbar } from "notistack";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import * as darkTheme from "./dark-theme.json";
import * as lightTheme from "./light-theme.json";
import { ReactSurveyElement, ReactElementFactory } from "survey-react-ui";
import { Icon, IconButton, Badge } from "@mui/material";
import ChatIcon from '@mui/icons-material/Chat';
import ChatModal from "./chatModal";

const RenderSurvey = ({
    templateId,
    onSurveyCompleted,
    onSurveySaved
}) => {

    const dispatch = useDispatch();
    const {
      surveyJson, isLoadingSurveyJson, getSurveyJsonError,
    } = useSelector(({ surveyTest }) => surveyTest);

    const [surveyData, setSurveyData] = useState(null);

    const [showChatModal, setShowChatModal] = useState(false);
    const onHideChatModal = () => {
      setShowChatModal(false);
    };
    const onClickChat = (questionName) => {
      setShowChatModal(true);
    };

    const { mode } = useSelector(({ changeTheme }) => ({
      mode: changeTheme.theme,
    }));

    useEffect(() => {
        dispatch(getSurvey(templateId));

        return () => {
            dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
        if(getSurveyJsonError){
          enqueueSnackbar(getSurveyJsonError, {variant: "error"});
        }
    }, [getSurveyJsonError]);

    const recursiveFindQuestions = (currentQuestions, savedQuestions) => {
      currentQuestions.forEach(q => {
        if(q.elements && q.elements.length){
          recursiveFindQuestions(q.elements, savedQuestions);
        }
        else{
          savedQuestions.push(q);
        }
      })
    }

    const getDtoFromAnswers = (data) => {
      var questions = [];
      surveyJson.pages.forEach(page => {
        recursiveFindQuestions(page.elements, questions);
      });

      var answers = [];
      for(var answer in data)
      {
        var foundQuestion = questions.find(q => q.name === answer);

        let answerContent;


        if(foundQuestion.type === "matrixdropdown"){
          var rowNames = Object.keys(data[answer]);

          var answerContentArray = [];

          for(var r in rowNames){
            var rowValues = data[answer][rowNames[r]];
            var rowContent = [];

            var columnNames = Object.keys(rowValues);
            for(var c in columnNames){
              var foundColumn = foundQuestion.columns.find(col => col.name === columnNames[c]);
              let subAnswerContent;

              if(foundColumn.choices){
                if(rowValues[columnNames[c]] && rowValues[columnNames[c]].length){

                  var answerSubContentArray = [];
                  for(var a in rowValues[columnNames[c]]){

                    var foundChoice = foundColumn.choices.find(ch => ch.value === rowValues[columnNames[c]][a]);
                    if(foundChoice){
                      answerSubContentArray.push({
                        id: foundChoice.value,
                        text: foundChoice.text
                      });
                    }
                  }
                  subAnswerContent = answerSubContentArray;
                }
                else{

                  var foundChoice = foundColumn.choices.find(ch => ch.value === rowValues[columnNames[c]]);
                  if(foundChoice){

                    subAnswerContent = {
                      id: foundChoice.value,
                      text: foundChoice.text
                    };
                  }
                }
              }
              else{
                subAnswerContent = rowValues[columnNames[c]];
              }

              rowContent.push({
                column: columnNames[c],
                answerId: foundColumn.answerId,
                content: subAnswerContent
              });
            }

            answerContentArray.push({
              row: rowNames[r],
              values: rowContent
            });
          }

          answerContent = answerContentArray;
        }
        else if(foundQuestion.choices){
          if(data[answer] && data[answer].length){

            var answerContentArray = [];
            for(var a in data[answer]){

              var foundChoice = foundQuestion.choices.find(c => c.value === data[answer][a]);
              if(foundChoice){
                answerContentArray.push({
                  id: foundChoice.value,
                  text: foundChoice.text
                });
              }
            }
            answerContent = answerContentArray;
          }
          else{

            var foundChoice = foundQuestion.choices.find(c => c.value === data[answer]);
            if(foundChoice){

              answerContent = {
                id: foundChoice.value,
                text: foundChoice.text
              };
            }
          }
        }
        else{
          answerContent = data[answer];
        }

        answers.push({
          questionCode: foundQuestion.code,
          answerId: foundQuestion.answerId,
          content: answerContent
        });

      }

      const finalData = {
        answers
      };

      return finalData;
    }

    useEffect(() => {
      if(surveyJson){
        const surveyClon = JSON.parse(JSON.stringify(surveyJson));
        surveyClon.showProgressBar = "top";
        surveyClon.progressBarType = "questions";
        surveyClon.widthMode = "responsive";
        surveyClon.name = undefined;
        console.log(surveyClon);
        const surveyModel = new Model(surveyClon);
            console.log(surveyModel);
            // surveyModel.onAfterRenderSurvey.add((sender, options) => {
            //   if(surveyJson){
            //       if(surveyJson.model.customClass){
            //         options.htmlElement.classList.add(surveyJson.model.customClass);
            //       }
            //       if(surveyJson.model.customStyle){
            //         options.htmlElement.style.cssText += surveyJson.model.customStyle;
            //         options.htmlElement.attributeStyleMap.append(surveyJson.model.customStyle);
            //       }
            //   }
            // });

            // surveyModel.onAfterRenderPage.add((sender, options) => {
            //   if(surveyJson){
            //     var page = surveyJson.model.pages.find(p => p.name === options.page.propertyHash.name);

            //     if(page){
            //       if(page.customStyle){
            //         options.htmlElement.style.cssText += page.customStyle;
            //       }
            //       if(page.customClass){
            //         options.htmlElement.classList.add(page.customClass);
            //       }
            //     }
            //   }
            // });

            // surveyModel.onAfterRenderQuestion.add((sender, options) => {
            //   if(surveyJson){
            //     var page = surveyJson.model.pages.find(p => p.name === options.question.propertyHash.parent.propertyHash.name);
            //     var question = page.elements.find(e => e.name === options.question.propertyHash.name);

            //     if(question.customStyle){
            //       options.htmlElement.style.cssText += question.customStyle;
            //     }
            //     if(question.customClass){
            //       options.htmlElement.classList.add(question.customClass);
            //     }
            //   }
            // });

            surveyModel.onGetQuestionTitleActions.add((_, opt) => {
              opt.titleActions = [
                  {
                      questionName: opt.question.propertyHash.name,
                      openChat: onClickChat,
                      component: 'ChatButton2'
                  },
              ];
          });

            surveyModel.onCompleting.add((sender, options) => {
              options.allow = false;

              var finalData = getDtoFromAnswers(sender.data);
              onSurveyCompleted(finalData);
          });


            surveyModel.addNavigationItem({
              id: "save-draft",
              title: "Save draft",
              action: () => {
                const data = surveyModel.getAllValues();
                const answerSurvey = getDtoFromAnswers(data);

                const finalData = {
                  answerSurvey,
                  progress: surveyModel.getProgress()
                };

                onSurveySaved(finalData);
              }
            });

          setSurveyData(surveyModel);
        }
    }, [surveyJson]);

    useEffect(() => {
      if(surveyData){
        if(mode === 'dark')
          surveyData.applyTheme(darkTheme);
        else
          surveyData.applyTheme(lightTheme);
      }
    }, [surveyData, mode]);

  return (
    <>
        {isLoadingSurveyJson || !surveyData ? <CircularProgressWrapper /> :
          <SurveyWrapper>
            <Survey model={surveyData} />
          </SurveyWrapper>
        }

        { showChatModal &&
          <ChatModal
            open={showChatModal}
            onHideModal={onHideChatModal}
          />
        }
    </>
  );
};

export default RenderSurvey;

const SurveyWrapper = styled.div`
  margin-top: 30px;
  width: 100%;
`;


class ChatButton extends ReactSurveyElement {
  render() {
      return (
        <IconButton onClick={() => this.props.item.openChat(this.props.item.questionName)}>
            <Badge variant="dot" color="error">
              <ChatIcon color="action"/>
            </Badge>
        </IconButton>
      );
  }
}

ReactElementFactory.Instance.registerElement("ChatButton2", props => {
  return React.createElement(ChatButton, props);
});