import dayjs from 'dayjs';
import { graphic } from 'echarts';
import ReactECharts from 'echarts-for-react';
import { useSelector } from 'react-redux';

const PerformanceLineChart = ({
    calculations
}) => {

    const {theme} = useSelector(({changeTheme}) => changeTheme);

    const options = {
        gradientColor: ["#00B050", "#92D050", "#FFC000", '#FF8D00', '#FF0000'],
        visualMap: [
            {
              show: false,
              type: "continuous",
              seriesIndex: 0,
              min: 0,
              max: 5
            }
          ],
        xAxis: {
          type: 'category',
          data: calculations.map((c, index) => index + 1)
        },
        yAxis: {
          type: 'value',
          max: 5
        },
        series: [
          {
            data: calculations.map(c => c.value),
            type: 'line',
            smooth: true,
            // areaStyle: {
            //     color: new graphic.LinearGradient(0, 0, 0, 1, [
            //       {
            //         offset: 1,
            //         color: '#00B050'
            //       },
            //       {
            //         offset: .75,
            //         color: '#92D050'
            //       },
            //       {
            //         offset: .5,
            //         color: '#FFC000'
            //       },
            //       {
            //         offset: .25,
            //         color: '#FF8D00'
            //       },
            //       {
            //         offset: 0,
            //         color: '#FF0000'
            //       }
            //     ])
            //   },
          }
        ],
        
        grid: {
            left: 30,
            top: 30,
            right: 30,
            bottom: 30
        }
    };

    return(
        <ReactECharts
            theme={theme}
            style={{height: 300}}
            notMerge={true}
            option={options}
            loadingOption={{
                text: "",
                color: '#3D5AFE',
                maskColor: 'rgba(0, 0, 0, 0.5)',
            }}
        />
    );
}

export default PerformanceLineChart;