import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_UPDATE_SUPPLIER');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getSupplierRequest = createAction('UPDATE_SUPPLIER_GET_REQUEST');
export const getSupplierSuccess = createAction('UPDATE_SUPPLIER_GET_SUCCESS');
export const getSupplierError = createAction('UPDATE_SUPPLIER_GET_ERROR');

export const getSupplier = (id) => async (dispatch) => {
  try {
    dispatch(getSupplierRequest());
    const resp = await instance.get(`/v1/Stakeholder/GetById/${id}`);

    dispatch(getSupplierSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getSupplierError(e.response.data.description));
    else
      dispatch(getSupplierError(e.message));
  }
};


export const getAssetRequest = createAction('UPDATE_SUPPLIER_GET_ASSET_REQUEST');
export const getAssetSuccess = createAction('UPDATE_SUPPLIER_GET_ASSET_SUCCESS');
export const getAssetError = createAction('UPDATE_SUPPLIER_GET_ASSET_ERROR');

export const getAsset = (id) => async (dispatch) => {
  try {
    dispatch(getAssetRequest());
    const resp = await instance.get(`/v1/Asset/GetById/${id}`);

    dispatch(getAssetSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getAssetError(e.response.data.description));
    else
      dispatch(getAssetError(e.message));
  }
};

export const updateSupplierRequest = createAction('UPDATE_SUPPLIER_POST_REQUEST');
export const updateSupplierSuccess = createAction('UPDATE_SUPPLIER_POST_SUCCESS');
export const updateSupplierError = createAction('UPDATE_SUPPLIER_POST_ERROR');

export const updateSupplier = (request) => async (dispatch) => {
  try {
    dispatch(updateSupplierRequest());
    const resp = await instance.put(`/v1/Stakeholder/Put`, request);

    dispatch(updateSupplierSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(updateSupplierError(e.response.data.description));
    else
      dispatch(updateSupplierError(e.message));
  }
};


export const getStakeholderTypesRequest = createAction('UPDATE_SUPPLIER_GET_STAKEHOLDER_TYPES_REQUEST');
export const getStakeholderTypesSuccess = createAction('UPDATE_SUPPLIER_GET_STAKEHOLDER_TYPES_SUCCESS');
export const getStakeholderTypesError = createAction('UPDATE_SUPPLIER_GET_STAKEHOLDER_TYPES_ERROR');

export const getStakeholderTypes = () => async (dispatch) => {
  try {
    dispatch(getStakeholderTypesRequest());
    const resp = await instance.get(`/v1/StakeholderType/GetAll`);

    dispatch(getStakeholderTypesSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getStakeholderTypesError(e.response.data.description));
    else
      dispatch(getStakeholderTypesError(e.message));
  }
};


export const getLogoRequest = createAction('UPDATE_SUPPLIER_GET_LOGO_REQUEST');
export const getLogoSuccess = createAction('UPDATE_SUPPLIER_GET_LOGO_SUCCESS');
export const getLogoError = createAction('UPDATE_SUPPLIER_GET_LOGO_ERROR');

export const getLogo = (stakeholderId) => async (dispatch) => {
  try {
    dispatch(getLogoRequest());
    const resp = await instance.get(`/v1/Stakeholder/GetLogo/${stakeholderId}`, { 
        responseType: 'blob'
    });

    dispatch(getLogoSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getLogoError(e.response.data.description));
    else
      dispatch(getLogoError(e.message));
  }
};

export const deleteLogoRequest = createAction('UPDATE_SUPPLIER_DELETE_LOGO_REQUEST');
export const deleteLogoSuccess = createAction('UPDATE_SUPPLIER_DELETE_LOGO_SUCCESS');
export const deleteLogoError = createAction('UPDATE_SUPPLIER_DELETE_LOGO_ERROR');

export const deleteLogo = (stakeholderId) => async (dispatch) => {
  try {
    dispatch(deleteLogoRequest());
    const resp = await instance.post(`/v1/Stakeholder/DeleteLogo/${stakeholderId}`);

    dispatch(deleteLogoSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(deleteLogoError(e.response.data.description));
    else
      dispatch(deleteLogoError(e.message));
  }
};