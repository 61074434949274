import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  sendTestEmailRequest,
  sendTestEmailSuccess,
  sendTestEmailError
} from './sendTestEmailAction';

const defaultState = {
  isSendingTestEmail: false,
  sentTestEmail: false,
  sendTestEmailError: null,
};

export default handleActions(
  {
    [sendTestEmailRequest](state) {
      return {
        ...state,
        isSendingTestEmail: true,
        sentTestEmail: false,
        sendTestEmailError: null,
      };
    },
    [sendTestEmailSuccess](state, { payload }) {
      return {
        ...state,
        isSendingTestEmail: false,
        sentTestEmail: true,
        sendTestEmailError: null,
      };
    },
    [sendTestEmailError](state, { payload }) {
      return {
        ...state,
        isSendingTestEmail: false,
        sentTestEmail: false,
        sendTestEmailError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);