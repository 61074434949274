import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { sendTestEmail, cleanup } from "@/reducers/options/sendTestEmail/sendTestEmailAction";
import { useSelector } from "react-redux";
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { useForm } from "react-hook-form";

const SendTestEmailModal = ({
    open, 
    onHideModal,
}) => {
    
    const {
        isSendingTestEmail, sentTestEmail, sendTestEmailError
    } = useSelector(({ sendTestEmail }) => sendTestEmail);

    const { handleSubmit, reset, control, setValue, getValues } = useForm();

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(cleanup());
        }
    }, [dispatch]);

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") 
            return;
        
        onHideModal();
    }

    useEffect(() => {
        if(sendTestEmailError){
            enqueueSnackbar(sendTestEmailError, {variant: "error"});
        }
    }, [sendTestEmailError]);

    const onSubmit = (data) => {
        dispatch(sendTestEmail(data));
    }
    
    useEffect(() => {
        if(sentTestEmail){
            enqueueSnackbar("Test email sent successfully", {variant: "success"});
            handleClose();
        }
    }, [sentTestEmail]);

    return(
        <Dialog
          fullWidth
          maxWidth='md'
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>Send test email</DialogTitle>
          <DialogContent>
            <FormTextField
                name="to"
                label="Send to"
                control={control}
            />
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant="contained" onClick={handleSubmit(onSubmit)}>Send</Button>
          </DialogActions>
  
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isSendingTestEmail}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
      </Dialog>
    );
}

export default SendTestEmailModal;