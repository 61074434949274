import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCurrentActivities, cleanup, getCountCreated, getCountToStart, getCountProcessing, getCountClosed, getCountArchived, getCountExpired } from "@/reducers/homeDashboard/homeDashboardAction";
import { Col, Row } from "react-bootstrap";
import NumberBlock from "./numberBlock";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import { Box, Card, CardActionArea, Icon, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { filter } from "d3";
import { enqueueSnackbar } from "notistack";
import ReducedActivityList from "./reducedActivityList";

const ActivityCounts = ({
    year,
    activityTypeId
}) => {
    const dispatch = useDispatch();
    const { 
        currentActivities, isLoadingCurrentActivities, getCurrentActivitiesError,
        countCreated, isLoadingCountCreated, getCountCreatedError,
        countToStart, isLoadingCountToStart, getCountToStartError, 
        countProcessing, isLoadingCountProcessing, getCountProcessingError,
        countClosed, isLoadingCountClosed, getCountClosedError,
        countArchived, isLoadingCountArchived, getCountArchivedError,
        countExpired, isLoadingCountExpired, getCountExpiredError,
    } = useSelector(({ homeDashboard }) => homeDashboard);

    const [selected, setSelected] = useState(0);

    const workflowGenericStatusIds = [2, null, 5, 1, 3, 4];

    useEffect(() => {
        dispatch(getCountCreated(year, activityTypeId));
        dispatch(getCountToStart(year, activityTypeId));
        dispatch(getCountProcessing(year, activityTypeId));
        dispatch(getCountClosed(year, activityTypeId));
        dispatch(getCountArchived(year, activityTypeId));
        dispatch(getCountExpired(year, activityTypeId));

        return () => {
            dispatch(cleanup());
        }
    }, [dispatch, year]);

    useEffect(() => {
        const statusId = workflowGenericStatusIds[selected];
        const filters = { page: 1, pageSize: 2 };

        if(statusId){
            if(statusId === 5){
                filters.expired = true;
            }
            else {
            filters.genericStatusIds = [statusId];
            }
        }

        if(activityTypeId)
            filters.activityTypeId = activityTypeId;

        filters.startDate = new Date(year, 0, 0);

        dispatch(getCurrentActivities(filters));
    }, [selected, year]);

    useEffect(() => {
        if(getCurrentActivitiesError){
        enqueueSnackbar(getCurrentActivitiesError, {variant: "error"});
        }
    }, [getCurrentActivitiesError]);

    const onSelect = (index) => {
        setSelected(index);
    };

    const {t} = useTranslation("common");

    return (
        <>
            {isLoadingCountCreated || isLoadingCountToStart || isLoadingCountProcessing || 
            isLoadingCountClosed || isLoadingCountArchived || isLoadingCountExpired ? 
                <CircularProgressWrapper height={120} />
            :
                <Row>
                    <Col xs={6} md={2} style={{marginBottom: '30px'}}>
                        <NumberBlock 
                            color="#C0C0C0" 
                            title={t("app.home.activitiesCount.processing")}
                            value={countProcessing} 
                            icon="engineering"
                            onClick={() => onSelect(0)}
                            selected={selected === 0}
                        />
                    </Col>
                    <Col xs={6} md={2} style={{marginBottom: '30px'}}>
                        <NumberBlock 
                            color="#993333" 
                            title={t("app.home.activitiesCount.expired")}
                            value={countExpired} 
                            icon="running_with_errors" 
                            onClick={() => onSelect(2)}
                            selected={selected === 2}
                        />
                    </Col>
                    
                    <Col xs={6} md={2} style={{marginBottom: '30px'}}>
                        <NumberBlock 
                            color="#996333" 
                            title={t("app.home.activitiesCount.toStart")}
                            value={countToStart} 
                            icon="not_started" 
                            onClick={() => onSelect(3)}
                            selected={selected === 3}
                        />
                    </Col>
                    <Col xs={6} md={2} style={{marginBottom: '30px'}}>
                        <NumberBlock 
                            color="#339966" 
                            title={t("app.home.activitiesCount.closed")}
                            value={countClosed} 
                            icon="lock" 
                            onClick={() => onSelect(4)}
                            selected={selected === 4}
                        />
                    </Col>
                    <Col xs={6} md={2} style={{marginBottom: '30px'}}>
                        <NumberBlock 
                            color="#999733" 
                            title={t("app.home.activitiesCount.archived")}
                            value={countArchived} 
                            icon="inventory_2" 
                            onClick={() => onSelect(5)}
                            selected={selected === 5}
                        />
                    </Col>
                    <Col xs={6} md={2} style={{marginBottom: '30px'}}>
                        <NumberBlock 
                            color="#336999" 
                            title={t("app.home.activitiesCount.new")}
                            value={countCreated} 
                            icon="add_circle" 
                            onClick={() => onSelect(1)}
                            selected={selected === 1}
                        />
                    </Col>
                </Row>
            }
            { isLoadingCurrentActivities ? <CircularProgressWrapper height={120} /> :
              currentActivities && currentActivities.length ?
                <ReducedActivityList activities={currentActivities} />
            :
            <Typography sx={{
                textAlign: 'center'
            }}>There are no activities to show.</Typography>
            }
        </>
    );
}

export default ActivityCounts;