import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getLogsRequest,
  getLogsSuccess,
  getLogsError
} from './logsAction';

const defaultState = {
  logs: [],
  isLoadingLogs: true,
  getLogsError: null,
  logsTotalCount: 0
};

export default handleActions(
  {
    [getLogsRequest](state) {
        return {
        ...state,
        isLoadingLogs: true,
        getLogsError: null,
        };
    },
    [getLogsSuccess](state, { payload }) {
        return {
        ...state,
        logs: payload.data,
        logsTotalCount: payload.totalCount,
        isLoadingLogs: false,
        getLogsError: null,
        };
    },
    [getLogsError](state, { payload }) {
        return {
        ...state,
        isLoadingLogs: false,
        getLogsError: payload,
        };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);
