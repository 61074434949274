import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getParameterUnitsRequest,
  getParameterUnitsSuccess,
  getParameterUnitsError,
  getParameterTypesRequest,
  getParameterTypesSuccess,
  getParameterTypesError,
  completeRequest,
  completeSuccess,
  completeError
} from './carbonFootprintSurveyAction';

const defaultState = {
  parameterUnits: null,
  isLoadingParameterUnits: true,
  getParameterUnitsError: null,
  parameterTypes: null,
  isLoadingParameterTypes: true,
  getParameterTypesError: null,
  isCompleting: false,
  completed: null,
  completeError: null
};

export default handleActions(
  {
    [getParameterUnitsRequest](state) {
      return {
        ...state,
        parameterUnits: null,
        isLoadingParameterUnits: true,
        getParameterUnitsError: null,
      };
    },
    [getParameterUnitsSuccess](state, { payload }) {
      return {
        ...state,
        parameterUnits: payload,
        isLoadingParameterUnits: false,
        getParameterUnitsError: null,
      };
    },
    [getParameterUnitsError](state, { payload }) {
      return {
        ...state,
        parameterUnits: null,
        isLoadingParameterUnits: false,
        getParameterUnitsError: payload,
      };
    },
    [getParameterTypesRequest](state) {
      return {
        ...state,
        parameterTypes: null,
        isLoadingParameterTypes: true,
        getParameterTypesError: null,
      };
    },
    [getParameterTypesSuccess](state, { payload }) {
      return {
        ...state,
        parameterTypes: payload,
        isLoadingParameterTypes: false,
        getParameterTypesError: null,
      };
    },
    [getParameterTypesError](state, { payload }) {
      return {
        ...state,
        parameterTypes: null,
        isLoadingParameterTypes: false,
        getParameterTypesError: payload,
      };
    },
    [completeRequest](state) {
      return {
        ...state,
        isCompleting: true,
        completed: false,
        completeError: null
      };
    },
    [completeSuccess](state, { payload }) {
      return {
        ...state,
        isCompleting: false,
        completed: true,
        completeError: null
      };
    },
    [completeError](state, { payload }) {
      return {
        ...state,
        isCompleting: false,
        completed: false,
        completeError: payload
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);