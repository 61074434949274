import { CircularProgress, Typography } from "@mui/material";

export const CircularProgressWrapper = ({
    height=400
}) => {
    return(
        <div style={{
            height: height, 
            width: '100%', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center'
        }}>
            <CircularProgress />
        </div>
    );
};

export const FlexCenteredText = (props) => {
    return(
    <   div style={{
            height: props.height, 
            width: '100%', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center'
        }}>
            <Typography color="text.primary">{props.children}</Typography>
        </div>
    );
}

FlexCenteredText.defaultProps = {
    height: 200
};