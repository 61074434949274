import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getRolesRequest,
  getRolesSuccess,
  getRolesError,
  getTenantsRequest,
  getTenantsSuccess,
  getTenantsError,
  getUsersRequest,
  getUsersSuccess,
  getUsersError
} from './systemUserListAction';

const defaultState = {
  roles: null,
  isLoadingRoles: true,
  getRolesError: null,
  tenants: null,
  isLoadingTenants: true,
  getTenantsError: null,
  users: [],
  isLoadingUsers: true,
  getUsersError: null,
  usersTotalCount: 0,
};

export default handleActions(
  {
    [getRolesRequest](state) {
      return {
        ...state,
        isLoadingRoles: true,
        getRolesError: null,
      };
    },
    [getRolesSuccess](state, { payload }) {
      return {
        ...state,
        roles: payload,
        isLoadingRoles: false,
        getRolesError: null,
      };
    },
    [getRolesError](state, { payload }) {
      return {
        ...state,
        isLoadingRoles: false,
        getRolesError: payload,
      };
    },
    [getTenantsRequest](state) {
        return {
        ...state,
        isLoadingTenants: true,
        getTenantsError: null,
        };
    },
    [getTenantsSuccess](state, { payload }) {
        return {
        ...state,
        tenants: payload,
        isLoadingTenants: false,
        getTenantsError: null,
        };
    },
    [getTenantsError](state, { payload }) {
        return {
        ...state,
        isLoadingTenants: false,
        getTenantsError: payload,
        };
    },
    [getUsersRequest](state) {
        return {
        ...state,
        isLoadingUsers: true,
        getUsersError: null,
        };
    },
    [getUsersSuccess](state, { payload }) {
        return {
        ...state,
        users: payload.data,
        usersTotalCount: payload.totalCount,
        isLoadingUsers: false,
        getUsersError: null,
        };
    },
    [getUsersError](state, { payload }) {
        return {
        ...state,
        isLoadingUsers: false,
        getUsersError: payload,
        };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);
