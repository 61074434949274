import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GridToolbar, DataGrid, gridClasses, useGridApiRef   } from '@mui/x-data-grid';
import { Col, Container, Row } from 'react-bootstrap';
import { cleanup, getSchema, getCheckList,  getDomains, getRatingScores, getVulnerabilityStatuses, saveCheckList } from "@/reducers/checkList/view/viewAction";
import { useForm } from "react-hook-form";
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { FormMultiSelectField } from '@/shared/components/form/material-controls/MultiSelectField';
import { FormDateField } from '@/shared/components/form/material-controls/DateField';
import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Button, Chip, CircularProgress, Typography, darken, lighten, styled } from '@mui/material';
import MaterialDynamicFilters from '@/shared/components/dynamic-filters/dynamicFilters';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandableCell from '@/shared/components/datagrid/ExpandableCell';
import SearchIcon from '@mui/icons-material/Search';
import Edit from '@mui/icons-material/Edit';
import { ColoredSelectCell } from '@/shared/components/datagrid/ColoredSelectCell';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { grey } from '@mui/material/colors';
import ConfirmationDialog from '@/shared/components/ConfirmationDialog';
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { resetTableData } from '@/reducers/checkList/view/viewAction';

const CheckList = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const { 
    schema, isLoadingSchema, getSchemaError,
    domains, isLoadingDomains, getDomainsError,
    ratingScores, isLoadingRatingScores, getRatingScoresError,
    vulnerabilityStatuses, isLoadingVulnerabilityStatuses, getVulnerabilityStatusesError,
    checkList, isLoadingCheckList, getCheckListError ,
    isSavingCheckList, savedCheckList, saveCheckListError
  } = useSelector(({ checkListView }) => checkListView);

  const [selectedRows, setSelectedRows] = useState([]);
  const [tableCheckList, setTableCheckList] = useState([]);
  const [pendingChanges, setPendingChanges] = useState([]);
  const [openConfirmDiscard, setOpenConfirmDiscard] = useState(false);

  const apiRef = useGridApiRef();

  useEffect(() => {
    if(id){
      dispatch(getSchema());
      dispatch(getDomains());
      dispatch(getRatingScores());
      dispatch(getVulnerabilityStatuses());
      dispatch(getCheckList({ remediationId: id, dictionary: {} }));
    }

    return () => {
      dispatch(cleanup());
    }
  }, [dispatch, id]);
  
  useEffect(() => {
    if(getSchemaError){
      enqueueSnackbar(getSchemaError, {variant: "error"});
    }
  }, [getSchemaError]);

  useEffect(() => {
    if(getDomainsError){
      enqueueSnackbar(getDomainsError, {variant: "error"});
    }
  }, [getDomainsError]);

  useEffect(() => {
    if(getRatingScoresError){
      enqueueSnackbar(getRatingScoresError, {variant: "error"});
    }
  }, [getRatingScoresError]);

  useEffect(() => {
    if(getVulnerabilityStatusesError){
      enqueueSnackbar(getVulnerabilityStatusesError, {variant: "error"});
    }
  }, [getVulnerabilityStatusesError]);

  useEffect(() => {
    if(getCheckListError){
      enqueueSnackbar(getCheckListError, {variant: "error"});
    }
  }, [getCheckListError]);

  useEffect(() => {
    if(saveCheckListError){
      enqueueSnackbar(saveCheckListError, {variant: "error"});
    }
  }, [saveCheckListError]);

  useEffect(() => {
    if(checkList && vulnerabilityStatuses){
      resetTableCheckList();
    }
  }, [checkList, vulnerabilityStatuses]);

  const resetTableCheckList = () => {
    var mappedData = JSON.parse(JSON.stringify(checkList));
    mappedData.forEach(m => m.vulnerabilityStatusName = vulnerabilityStatuses.find(s => s.id === m.vulnerabilityStatusId).nameTraslated);
    setTableCheckList(mappedData);
  }

  const onChangeStatusCell = (newStatus, cellData) => {
    var clon = JSON.parse(JSON.stringify(pendingChanges));
    
    if(clon.some(c => c.rowId === cellData.id)){
      var row = clon.find(c => c.rowId === cellData.id);
      row.vulnerabilityStatusName = newStatus.nameTraslated;
      row.vulnerabilityStatusNameChanged = true;
    }
    else{
      clon.push({
        rowId: cellData.id, 
        vulnerabilityStatusName: newStatus.nameTraslated, 
        vulnerabilityStatusNameChanged: true,
        scheduledDate: cellData.scheduledDate
      });
    }
    setPendingChanges(clon);

    apiRef.current.setEditCellValue({
      id: cellData.id,
      field: "vulnerabilityStatusName",
      value: newStatus.nameTraslated,
    });
    apiRef.current.stopCellEditMode({ 
      id: cellData.id, 
      field: "vulnerabilityStatusName"
    });
  };

  const onChangeDateCell = (newScheduledDate, cellData) => {
    var clon = JSON.parse(JSON.stringify(pendingChanges));

    if(clon.some(c => c.rowId === cellData.id)){
      var row = clon.find(c => c.rowId === cellData.id);
      row.scheduledDate = newScheduledDate;
      row.scheduledDateChanged = true;
    }
    else{
      clon.push({
        rowId: cellData.id, 
        scheduledDate: newScheduledDate, 
        scheduledDateChanged: true,
        vulnerabilityStatusName: cellData.row.vulnerabilityStatusName
      });
    }
    setPendingChanges(clon);

    apiRef.current.setEditCellValue({
      id: cellData.id,
      field: "scheduledDate",
      value: newScheduledDate,
    });
    apiRef.current.stopCellEditMode({ 
      id: cellData.id, 
      field: "scheduledDate"
    });
  };

  const handleClickSaveChanges = () => {
    const data = pendingChanges.map(p => { return { 
      assetVulnerabilityId: p.rowId,
      statusId: vulnerabilityStatuses.find(s => s.nameTraslated === p.vulnerabilityStatusName).id,
      scheduledDate: p.scheduledDate,
      remediationId: id
    }});

    dispatch(saveCheckList(data));
  };

  useEffect(() => {
    if(savedCheckList){
      enqueueSnackbar("Changes saved successfully", {variant: "success"});
      
      setPendingChanges([]);

      var tableData = Object.values(apiRef.current.store.value.rows.dataRowIdToModelLookup);
      tableData.forEach(d => d.vulnerabilityStatusId = vulnerabilityStatuses.find(s => s.nameTraslated === d.vulnerabilityStatusName).id);
      dispatch(resetTableData(tableData));
    }
  }, [savedCheckList]);

  const handleClickDiscardChanges = () => {
    setOpenConfirmDiscard(true);
  }

  const handleConfirmDiscard = () => {
    setOpenConfirmDiscard(false);
    setPendingChanges([]);
    resetTableCheckList();
  }

  const handleOnCloseDiscard = () => {
    setOpenConfirmDiscard(false);
  };

  const { handleSubmit, reset, control, setValue } = useForm();

  const onSubmit = (data) => {
    setPendingChanges([]);
    var keyValues = {};

    if(data.asset){
      keyValues["asset.Name"] = data.asset;
    }

    if(data.vulnerability){
      keyValues["vulnerability.name"] = data.vulnerability;
    }

    if(data.cve){
      keyValues["vulnerability.cve"] = data.cve;
    }

    if(data.detail){
      keyValues["detail"] = data.detail;
    }

    if(data.severity && data.severity.length){
      keyValues["vulnerability.ratingScoreId"] = data.severity;
    }

    if(data.domain && data.domain.length){
      keyValues["domainId"] = data.domain;
    }

    if(data.createData){
      keyValues["createData"] = new Date(data.createData);
    }

    if(data.dynamicFilter && data.dynamicFilter.length){
      data.dynamicFilter.forEach(f => {
        keyValues[f.key] = f.value;
      });
    }

    const finalData = { remediationId: id, dictionary: keyValues };

    dispatch(getCheckList(finalData));
  }

  const columns = [
    { 
        field: 'assetName', 
        headerName: 'Asset', 
        minWidth: 150,
        flex: 1
    },
    { 
        field: 'vulnerabilityName', 
        headerName: 'Vulnerability',
        minWidth: 350,
        flex: 1,
        renderCell: (params) => <ExpandableCell {...params} maxLength={150} />,
    },
    { 
        field: 'vulnerabilityCVE', 
        headerName: 'CVE', 
        minWidth: 150,
        flex: 1
    },
    { 
        field: 'detail', 
        headerName: 'Details', 
        minWidth: 350,
        flex: 1,
        renderCell: (params) => <ExpandableCell {...params} maxLength={150} />,
    },
    { 
        field: 'ratingScoreName', 
        headerName: 'Severity', 
        minWidth: 150,
        flex: 1,
        renderCell: (params) => {
          const color = ratingScores.find(r => r.Name === params.value)?.color;
          return (
            <Chip style={{backgroundColor: color, fontWeight: 'bold'}} label={params.value} />
          );
        },
    },
    { 
        field: 'domains', 
        headerName: 'Domains', 
        minWidth: 350,
        flex: 1,
        renderCell: (params) => <ExpandableCell {...params} maxLength={150} />,
    },
    { 
        field: 'createDate', 
        headerName: 'Create Date', 
        type: 'date',
        minWidth: 150,
        flex: 1,
        valueFormatter: params => params.value ? dayjs(params.value).format("DD/MM/YYYY") : ""
    },
    { 
        field: 'vulnerabilityStatusName', 
        headerName: 'Vulnerability Status', 
        minWidth: 250,
        flex: 1,
        renderHeader: (params) => (<div>Vulnerability Status <Edit style={{marginLeft: 10}}/></div>),
        renderCell: (params) => {
          const color = vulnerabilityStatuses.find(r => r.nameTraslated === params.value)?.color;
          return (
            <Chip style={{backgroundColor: color, fontWeight: 'bold'}} label={params.value} />
          );
        },
        renderEditCell: (params) => {
          return(
            <ColoredSelectCell
              value={vulnerabilityStatuses.find(v => v.nameTraslated === params.value)}
              options={vulnerabilityStatuses}
              onChange={(e, data) => onChangeStatusCell(data, params)}
            />
          );
        },
        editable: true
    },
    { 
      field: 'scheduledDate', 
      headerName: 'Scheduled Date', 
      type: 'date',
      minWidth: 200,
      flex: 1,
      renderHeader: (params) => (<div>Scheduled Date <Edit style={{marginLeft: 10}}/></div>),
      valueFormatter: params => params.value ? dayjs(params.value).format("DD/MM/YYYY") : "",
      renderEditCell: (params) => {
        return(
          <DatePicker 
            slotProps={{
              textField: { fullWidth: true },
              actionBar: { actions: ['clear', 'today'] }
            }}
            defaultValue={params.value ? dayjs(params.value) : null}
            onChange={(data) => onChangeDateCell(data, params)}
          />
        );
      },
      editable: true
    },
    { 
      field: 'updateDate', 
      headerName: 'Update Date', 
      type: 'date',
      minWidth: 150,
      flex: 1,
      valueFormatter: params => params.value ? dayjs(params.value).format("DD/MM/YYYY") : ""
    }
  ];

  return (
    <Container>
      <header style={{'marginBottom': 20}}>
        <h1>Check List</h1>
        <Typography>Here you can filter and search check lists.</Typography>
      </header>
      <Row>
        <Col md={12}>
          <Accordion key={1}>
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
              >
              <Typography>Activity</Typography>
              </AccordionSummary>
              <AccordionDetails>
                progress...
              </AccordionDetails>
          </Accordion>
          <Accordion key={2}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
            <Typography>Basic Filters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Row>
                <Col md={6}>
                  <FormTextField name="asset" label="Asset" control={control} />
                </Col>
                <Col md={6}>
                  <FormTextField 
                    name="vulnerability" 
                    label="Vulnerability" 
                    control={control} 
                  />
                </Col>
                <Col md={6}>
                  <FormTextField 
                    name="detail" 
                    label="Detail" 
                    control={control} 
                  />
                </Col>
                <Col md={6}>
                  <FormTextField 
                    name="cve" 
                    label="CVE" 
                    control={control} 
                  />
                </Col>
                <Col md={6}>
                  <FormMultiSelectField 
                    name="severity" 
                    label="Severity" 
                    control={control} 
                    options={ratingScores}
                    keyValue={{id:"id", label:"Name"}}
                  />
                </Col>
                <Col md={6}>
                  <FormMultiSelectField 
                    name="domain" 
                    label="Domain" 
                    control={control} 
                    options={domains}
                    keyValue={{id:"id", label:"value"}}
                  />
                </Col>
                <Col md={6}>
                  <FormDateField 
                    name="createDate" 
                    label="Create Date" 
                    control={control}
                  />
                </Col>
              </Row>
            </AccordionDetails>
          </Accordion>
        </Col>

        <Col md={12}>
          <MaterialDynamicFilters schema={schema} control={control} name="dynamicFilter" />
        </Col>

      </Row>
    
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBlock: 20}}>
        <div>
          <Button disabled={!pendingChanges.length} color="success" size='large' variant="contained" onClick={handleClickSaveChanges}>Save ({pendingChanges.length} rows)</Button>
          <Button disabled={!pendingChanges.length} style={{marginLeft: 10}} color="error" size='large' variant="contained" onClick={handleClickDiscardChanges}>Discard Changes</Button>
        </div>
        <Button disabled={isLoadingCheckList} color="primary" size='medium' variant="contained" onClick={handleSubmit(onSubmit)} endIcon={<SearchIcon/>}>Search</Button>
      </div>
      
      <div style={{'height': 800, 'paddingBottom': 20}}>
        <StyledDataGrid 
          apiRef={apiRef}
          checkboxSelection 
          disableRowSelectionOnClick
          getRowId={(row) => row._id}
          getRowHeight={() => 'auto'}
          getEstimatedRowHeight={() => 59}
          // getRowClassName={(params) => pendingChanges.some(p => p.rowId === params.id) ? "edited-row": ""}
          getCellClassName={(params) => {
            if (params.field === 'scheduledDate' || params.field === 'vulnerabilityStatusName') {
              const change = pendingChanges.find(p => p.rowId === params.id);
              if(change && change[params.field + "Changed"]){
                return "edited-cell";
              }
            }
            return "";
          }}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setSelectedRows(newRowSelectionModel);
          }}
          rowSelectionModel={selectedRows}
          loading={isLoadingCheckList} 
          rows={tableCheckList}
          columns={columns} 
          slots={{ toolbar: GridToolbar }} 
          slotProps={{
            toolbar: {
              showQuickFilter: true
            },
          }}
          sx={{
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
          }}
        />
      </div>

      <ConfirmationDialog 
        open={openConfirmDiscard}
        onConfirm={handleConfirmDiscard}
        onClose={handleOnCloseDiscard}
        title="Discard Changes"
        body={`Are you sure you want to discard your changes on ${pendingChanges.length} rows?`}
        color='error'
        confirmButtonText='Discard'
      />

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isSavingCheckList}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
    </Container>
  );
}

const getBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .edited-cell': {
    backgroundColor: getBackgroundColor(
      grey[400],
      theme.palette.mode
      ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        grey[400],
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        grey[400],
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          grey[400],
          theme.palette.mode,
        ),
      },
    },
  },
}));

export default CheckList;