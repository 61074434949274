import ReactECharts from 'echarts-for-react';
import { useSelector } from 'react-redux';


const RiskRadar = ({calculations, ratingScores}) => {

    const {theme} = useSelector(({changeTheme}) => changeTheme);

    const options = {
        tooltip: {
            trigger: 'item',
            formatter: function (params, ticket, callback) {
              var htmlContent = "";
              params.value.forEach((v, index) => {
                htmlContent += params.data.names[index] + ": <strong>" + v + "</strong> </br>";
              });
              return htmlContent;
            }
        },
        radar: {
            radius: '60%',
            indicator: calculations.map(c => {
                return { 
                    name:  c.surveyCode, 
                    max: 5 
                };
            }),
            axisName: {
                fontSize: 10
            }
          },
          series: [
            {
              type: 'radar',
              itemStyle: {
                color: '#00ACAC'
              },
              areaStyle: {
                opacity: 0.3
              },
              data: [
                {
                  value: calculations.map(c => c.value),
                  percent: calculations.map(c => 100 * c.value),
                  names: calculations.map(c => c.surveyCode),
                  name: 'Risk'
                }
              ],
            },
          ]
    };
    
    return(
        <ReactECharts
            theme={theme}
            style={{height: 400, width: '100%'}}
            notMerge={true}
            option={options}
        />
    );
};

export default RiskRadar;