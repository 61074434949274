import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  addVulnerabilityScoreRequest,
  addVulnerabilityScoreSuccess,
  addVulnerabilityScoreError
} from './addVulnerabilityScoreAction';

const defaultState = {
  isAddingVulnerabilityScore: false,
  addVulnerabilityScoreError: null,
  addedVulnerabilityScore: false
};

export default handleActions(
  {
    [addVulnerabilityScoreRequest](state) {
      return {
        ...state,
        isAddingVulnerabilityScore: true,
        addVulnerabilityScoreError: null,
        addedVulnerabilityScore: false
      };
    },
    [addVulnerabilityScoreSuccess](state, { payload }) {
      return {
        ...state,
        isAddingVulnerabilityScore: false,
        addVulnerabilityScoreError: null,
        addedVulnerabilityScore: true
      };
    },
    [addVulnerabilityScoreError](state, { payload }) {
      return {
        ...state,
        isAddingVulnerabilityScore: false,
        addVulnerabilityScoreError: payload,
        addedVulnerabilityScore: false
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);