import ReactECharts from 'echarts-for-react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { instance } from "../../../config/interceptor";
import { enqueueSnackbar } from 'notistack';
import { Card, CardContent } from '@mui/material';

const DoughnutChart = ({ endpoint, chartSeries, height = 300, ...other }) => {
    const {theme} = useSelector(({changeTheme}) => changeTheme);

    const baseOptions = {
        toolbox: {
            feature: {
                saveAsImage: {}
            }
          },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            top: '5%',
            left: 'center'
        },
        series: [
            {
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 10,
                    borderWidth: 2
                },
                label: {
                    show: true,
                    formatter(param) {
                        return param.name + ' (' + param.percent + '%)';
                    }
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 15,
                        fontWeight: 'bold'
                    }
                },
                data: []
            }
        ],
        ...other
    }

    const [isLoading, setIsLoading] = useState(!chartSeries);
    const [data, setData] = useState(chartSeries);
    const [apiError, setApiError] = useState(null);
    const [options, setOptions] = useState(baseOptions);

    useEffect(() => {
        if(endpoint){
            async function fetchData(){
                try {
                    setIsLoading(true);
                    const resp = await instance.get("/v1/Dashboard/" + endpoint);
                    setIsLoading(false);
                    setData(resp.data);
                }
                catch(e) {
                    if(e.response && e.response.data && e.response.data.description)
                        setApiError(e.response.data.description);
                    else
                        setApiError(e.message);
                }
            }
            
            fetchData();
        }
    }, [endpoint]);

    useEffect(() => {
        if(data){
            setOptions({
                tooltip: {
                    trigger: 'item'
                  },
                  legend: {
                    top: '5%',
                    left: 'center'
                  },
                  series: [
                    {
                      type: 'pie',
                      radius: ['40%', '70%'],
                      avoidLabelOverlap: false,
                      itemStyle: {
                        borderRadius: 10,
                        borderWidth: 2
                      },
                      label: {
                        show: true,
                        formatter(param) {
                          return param.name + ' (' + param.percent + '%)';
                        }
                      },
                      emphasis: {
                        label: {
                          show: true,
                          fontSize: 15,
                          fontWeight: 'bold'
                        }
                      },
                        data: data.series.map(s => {
                            return { 
                                value: s.total, 
                                name: s.groupName, 
                                itemStyle: { color: s.color === null || s.color === "" ? undefined : s.color },
                                items: s.items ?? undefined
                            }
                        })
                    }
                ],
                ...other
            });
        }
    }, [data]);

    useEffect(() => {
        if(apiError){
            enqueueSnackbar(apiError, {variant: "error"});
        }
    }, [apiError]);

    return(
        <Card>
            <CardContent>
                <ReactECharts
                    theme={theme}
                    style={{height: height}}
                    notMerge={true}
                    option={options}
                    showLoading={isLoading}
                    loadingOption={{
                        text: "",
                        color: '#3D5AFE',
                        maskColor: 'rgba(0, 0, 0, 0.5)',
                    }}
                />
            </CardContent>
        </Card>
    );
}

export default DoughnutChart;