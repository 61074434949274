import { createAction } from 'redux-actions';
import { instance } from "../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_HOME');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getPrioritiesRequest = createAction('HOME_ACTIVITIES_GET_PRIORITIES_REQUEST');
export const getPrioritiesSuccess = createAction('HOME_CURRENT_ACTIVITIES_GET_PRIORITIES_SUCCESS');
export const getPrioritiesError = createAction('HOME_CURRENT_ACTIVITIES_GET_PRIORITIES_ERROR');

export const getPriorities = () => async (dispatch) => {
  try {
    dispatch(getPrioritiesRequest());
    const resp = await instance.get(`/v1/Workflow/GetWorkflowActivityPriority`);

    if(resp.data.isSuccessed)
      dispatch(getPrioritiesSuccess(resp.data.data));
    else
      dispatch(getPrioritiesError(resp.data.error));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getPrioritiesError(e.response.data.description));
    else
      dispatch(getPrioritiesError(e.message));
  }
};


export const getActivityTypesRequest = createAction('HOME_GET_ACTIVITY_TYPES_REQUEST');
export const getActivityTypesSuccess = createAction('HOME_GET_ACTIVITY_TYPES_SUCCESS');
export const getActivityTypesError = createAction('HOME_GET_ACTIVITY_TYPES_ERROR');

export const getActivityTypes = () => async (dispatch) => {
  try {
    dispatch(getActivityTypesRequest());
    const resp = await instance.get(`/v1/ActivityType/GetListPagedByFilter`);
    
    dispatch(getActivityTypesSuccess(resp.data.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getActivityTypesError(e.response.data.description));
    else
      dispatch(getActivityTypesError(e.message));
  }
};