import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  updateSupplierRequest,
  updateSupplierSuccess,
  updateSupplierError,
  getSupplierRequest,
  getSupplierSuccess,
  getSupplierError,
  getAssetRequest,
  getAssetSuccess,
  getAssetError,
  getStakeholderTypesRequest,
  getStakeholderTypesSuccess,
  getStakeholderTypesError,
  getLogoRequest,
  getLogoSuccess,
  getLogoError,
  deleteLogoRequest,
  deleteLogoSuccess,
  deleteLogoError
} from './updateSupplierAction';

const defaultState = {
  supplier: null,
  isLoadingSupplier: true,
  getSupplierError: null,
  asset: null,
  isLoadingAsset: true,
  getAssetError: null,
  isUpdatingSupplier: false,
  updateSupplierError: null,
  updatedSupplier: false,
  stakeholderTypes: [],
  isLoadingStakeholderTypes: true,
  getStakeholderTypesError: null,
  logo: null,
  isLoadingLogo: true,
  getLogoError: null,
  isDeletingLogo: false,
  deleteLogoError: null,
  deletedLogo: false,
};

export default handleActions(
  {
    [getSupplierRequest](state) {
      return {
        ...state,
        supplier: null,
        isLoadingSupplier: true,
        getSupplierError: null,
      };
    },
    [getSupplierSuccess](state, { payload }) {
      return {
        ...state,
        supplier: payload,
        isLoadingSupplier: false,
        getSupplierError: null,
      };
    },
    [getSupplierError](state, { payload }) {
      return {
        ...state,
        supplier: null,
        isLoadingSupplier: false,
        getSupplierError: payload,
      };
    },
    [getAssetRequest](state) {
      return {
        ...state,
        asset: null,
        isLoadingAsset: true,
        getAssetError: null,
      };
    },
    [getAssetSuccess](state, { payload }) {
      return {
        ...state,
        asset: payload,
        isLoadingAsset: false,
        getAssetError: null,
      };
    },
    [getAssetError](state, { payload }) {
      return {
        ...state,
        asset: null,
        isLoadingAsset: false,
        getAssetError: payload,
      };
    },
    [updateSupplierRequest](state) {
      return {
        ...state,
        isUpdatingSupplier: true,
        updateSupplier: null,
        updatedSupplier: false
      };
    },
    [updateSupplierSuccess](state, { payload }) {
      return {
        ...state,
        isUpdatingSupplier: false,
        updateSupplierError: null,
        updatedSupplier: true
      };
    },
    [updateSupplierError](state, { payload }) {
      return {
        ...state,
        isUpdatingSupplier: false,
        updateSupplierError: payload,
        updatedSupplier: false
      };
    },
    [getStakeholderTypesRequest](state) {
      return {
        ...state,
        isLoadingStakeholderTypes: true,
        getStakeholderTypesError: null,
      };
    },
    [getStakeholderTypesSuccess](state, { payload }) {
      return {
        ...state,
        stakeholderTypes: payload,
        isLoadingStakeholderTypes: false,
        getStakeholderTypesError: null,
      };
    },
    [getStakeholderTypesError](state, { payload }) {
      return {
        ...state,
        isLoadingStakeholderTypes: false,
        getStakeholderTypesError: payload,
      };
    },
    [getLogoRequest](state) {
      return {
        ...state,
        isLoadingLogo: true,
        getLogoError: null,
      };
    },
    [getLogoSuccess](state, { payload }) {
      return {
        ...state,
        logo: payload && payload.size ? payload : null,
        isLoadingLogo: false,
        getLogoError: null,
      };
    },
    [getLogoError](state, { payload }) {
      return {
          ...state,
          isLoadingLogo: false,
          getLogoError: payload,
      };
    },
    [deleteLogoRequest](state) {
      return {
          ...state,
          isDeletingLogo: true,
          deleteLogoError: null,
          deletedLogo: false
      };
    },
    [deleteLogoSuccess](state, { payload }) {
      return {
          ...state,
          isDeletingLogo: false,
          deleteLogoError: null,
          deletedLogo: true,
          logo: null
      };
    },
    [deleteLogoError](state, { payload }) {
      return {
          ...state,
          isDeletingLogo: false,
          deleteLogoError: payload,
          deletedLogo: false
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);