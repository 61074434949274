import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getVulnerabilityScoresRequest,
  getVulnerabilityScoresSuccess,
  getVulnerabilityScoresError,
  deleteVulnerabilityScoreRequest,
  deleteVulnerabilityScoreSuccess,
  deleteVulnerabilityScoreError
} from './vulnerabilityScoresAction';

const defaultState = {
  vulnerabilityScores: [],
  isLoadingVulnerabilityScores: true,
  getVulnerabilityScoresError: null,
  isDeletingVulnerabilityScore: false,
  deleteVulnerabilityScoreError: null,
  deletedVulnerabilityScore: false
};

export default handleActions(
  {
    [getVulnerabilityScoresRequest](state) {
      return {
        ...state,
        vulnerabilityScores: [],
        isLoadingVulnerabilityScores: true,
        getVulnerabilityScoresError: null,
      };
    },
    [getVulnerabilityScoresSuccess](state, { payload }) {
      return {
        ...state,
        vulnerabilityScores: payload,
        isLoadingVulnerabilityScores: false,
        getVulnerabilityScoresError: null,
      };
    },
    [getVulnerabilityScoresError](state, { payload }) {
      return {
        ...state,
        vulnerabilityScores: [],
        isLoadingVulnerabilityScores: false,
        getVulnerabilityScoresError: payload,
      };
    },
    [deleteVulnerabilityScoreRequest](state) {
      return {
        ...state,
        isDeletingVulnerabilityScore: true,
        deletedVulnerabilityScore: false,
        deleteVulnerabilityScoreError: null,
      };
    },
    [deleteVulnerabilityScoreSuccess](state, { payload }) {
      return {
        ...state,
        isDeletingVulnerabilityScore: false,
        deletedVulnerabilityScore: true,
        deleteVulnerabilityScoreError: null,
      };
    },
    [deleteVulnerabilityScoreError](state, { payload }) {
      return {
        ...state,
        isDeletingVulnerabilityScore: false,
        deletedVulnerabilityScore: false,
        deleteVulnerabilityScoreError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);