import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getFieldValuesRequest,
  getFieldValuesSuccess,
  getFieldValuesError
} from './fieldValuesAction';

const defaultState = {
  fieldValues: [],
  isLoadingFieldValues: true,
  getFieldValuesError: null
};

export default handleActions(
  {
    [getFieldValuesRequest](state) {
      return {
        ...state,
        isLoadingFieldValues: true,
        getFieldValuesError: null,
      };
    },
    [getFieldValuesSuccess](state, { payload }) {
      return {
        ...state,
        fieldValues: payload,
        isLoadingFieldValues: false,
        getFieldValuesError: null,
      };
    },
    [getFieldValuesError](state, { payload }) {
      return {
        ...state,
        isLoadingFieldValues: false,
        getFieldValuesError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);