import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  fetchAssetTypesRequest,
  fetchAssetTypesSuccess,
  fetchAssetTypesError,
  deleteAssetTypeRequest,
  deleteAssetTypeSuccess,
  deleteAssetTypeError
} from './assetTypeAction';

const defaultState = {
  assetTypes: [],
  isFetching: true,
  error: null,
  isDeletingAssetType: false,
  deletedAssetType: false,
  deleteAssetTypeError: false
};

export default handleActions(
  {
    [fetchAssetTypesRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchAssetTypesSuccess](state, { payload }) {
      return {
        ...state,
        assetTypes: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchAssetTypesError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [deleteAssetTypeRequest](state) {
      return {
        ...state,
        isDeletingAssetType: true,
        deletedAssetType: false,
        deleteAssetTypeError: null,
      };
    },
    [deleteAssetTypeSuccess](state, { payload }) {
      return {
        ...state,
        isDeletingAssetType: false,
        deletedAssetType: true,
        deleteAssetTypeError: null,
      };
    },
    [deleteAssetTypeError](state, { payload }) {
      return {
        ...state,
        isDeletingAssetType: false,
        deletedAssetType: false,
        deleteAssetTypeError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);