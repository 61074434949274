import { green, grey } from "@mui/material/colors";

export const getModeDependantColors = (mode) => {
    return mode === 'light' ? 
    {
        cardBackground1: {
            main: '#E5E5E5'
        },
        cardBackground2: {
            main: '#FFFFFF'
        },
        surveyChatOtherMessageBackground:{
            main: grey[300]
        },
        co2GreenColor: {
            main: green[300]
        },
        chatbot: {
            backgroundColor: {
                main: "#e9e9e9"
            },
            headerBackgroundColor: {
                main: grey[500]
            },
            botMessageBackground:{
                main: 'white'
            },
            userMessageBackground: {
                main: "rgb(171 253 196)"
            }
        },
    } 
    : 
    {
        cardBackground1: {
            main: '#121212'
        },
        cardBackground2: {
            main: '#121212'
        },
        surveyChatOtherMessageBackground:{
            main: grey[500]
        },
        co2GreenColor: {
            main: green[500]
        },
        chatbot: {
            backgroundColor: {
                main: grey[900]
            },
            headerBackgroundColor: {
                main: grey[500]
            },
            botMessageBackground:{
                main: grey[800]
            },
            userMessageBackground: {
                main: "rgb(80 181 152)"
            }
        },
    };
}