import { instance } from "../../config/interceptor";
import CytoscapeComponent from "react-cytoscapejs";
import { createAction } from 'redux-actions';

export const cleanupAction = createAction('CLEANUP_CYTOSCAPE');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const clearAllAction = createAction('CLEAR_ALL');

export const clearAll = ()  => async (dispatch) => {
  dispatch(clearAllAction());
}

export const getAssetTypesRequest = createAction('GET_ASSET_TYPES_REQUEST');
export const getAssetTypesSuccess = createAction('GET_ASSET_TYPES_SUCCESS');
export const getAssetTypesError = createAction('GET_ASSET_TYPES_ERROR');

export const getAssetTypes = () => async (dispatch) => {
  try {
    dispatch(getAssetTypesRequest());
    const resp = await instance.get(`/v1/Asset/GetAssetTypeUsed`);

    dispatch(getAssetTypesSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getAssetTypesError(e.response.data.description));
    else
      dispatch(getAssetTypesError(e.message));
  }
};

export const getNodesRequest = createAction('GET_NODES_REQUEST');
export const getNodesSuccess = createAction('GET_NODES_SUCCESS');
export const getNodesError = createAction('GET_NODES_ERROR');

export const getNodes = (ids) => async (dispatch) => {
  try {
    dispatch(getNodesRequest());
    const resp = await instance.get(`/v1/Cytoscape/GetNodes`, {
      params: { nodes: ids },
      paramsSerializer: {
        indexes: true
      }
    });
    const mappedData = mapResponseNodes(resp.data);

    dispatch(getNodesSuccess(mappedData));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getNodesError(e.response.data.description));
    else
      dispatch(getNodesError(e.message));
  }
};


export const addNodesRequest = createAction('ADD_NODES_REQUEST');
export const addNodesSuccess = createAction('ADD_NODES_SUCCESS');
export const addNodesError = createAction('ADD_NODES_ERROR');

export const addNodes = (ids) => async (dispatch) => {
  try {
    dispatch(addNodesRequest());
    const resp = await instance.get(`/v1/Cytoscape/GetNodes`, {
      params: { nodes: ids },
      paramsSerializer: {
        indexes: true
      }
    });
    const mappedData = mapResponseNodes(resp.data);

    dispatch(addNodesSuccess(mappedData));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(addNodesError(e.response.data.description));
    else
      dispatch(addNodesError(e.message));
  }
};

export const getNodesForMergeRequest = createAction('GET_NODES_FOR_MERGE_REQUEST');
export const getNodesForMergeSuccess = createAction('GET_NODES_FOR_MERGE_SUCCESS');
export const getNodesForMergeError = createAction('GET_NODES_FOR_MERGE_ERROR');

export const getNodeAllPathForMerge = (id) => async (dispatch) => {
  try {
    dispatch(getNodesForMergeRequest());
    const resp = await instance.get(`/v1/Cytoscape/GetNodeAllPath?node=${id}`);
    const mappedData = mapResponseNodes(resp.data);

    dispatch(getNodesForMergeSuccess(mappedData));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getNodesForMergeError(e.response.data.description));
    else
      dispatch(getNodesForMergeError(e.message));
  }
};

export const getNodeParentChildForMerge = (id) => async (dispatch) => {
  try {
    dispatch(getNodesForMergeRequest());
    const resp = await instance.get(`/v1/Cytoscape/GetNodeParentChild?node=${id}`);
    const mappedData = mapResponseNodes(resp.data);

    dispatch(getNodesForMergeSuccess(mappedData));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getNodesForMergeError(e.response.data.description));
    else
      dispatch(getNodesForMergeError(e.message));
  }
};


export const getByTargetRequest = createAction('GET_NODES_BY_TARGET_REQUEST');
export const getByTargetSuccess = createAction('GET_NODES_BY_TARGET_SUCCESS');
export const getByTargetError = createAction('GET_NODES_BY_TARGET_ERROR');

export const getByTarget = (id) => async (dispatch) => {
  try {
    dispatch(getByTargetRequest());
    const resp = await instance.get(`/v1/Cytoscape/GetByTarget?node=${id}`);
    const mappedData = mapResponseNodes(resp.data);

    dispatch(getByTargetSuccess(mappedData));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getByTargetError(e.response.data.description));
    else
      dispatch(getByTargetError(e.message));
  }
};

export const getBySourceRequest = createAction('GET_NODES_BY_SOURCE_REQUEST');
export const getBySourceSuccess = createAction('GET_NODES_BY_SOURCE_SUCCESS');
export const getBySourceError = createAction('GET_NODES_BY_SOURCE_ERROR');

export const getBySource = (id) => async (dispatch) => {
  try {
    dispatch(getBySourceRequest());
    const resp = await instance.get(`/v1/Cytoscape/GetBySource?node=${id}`);
    const mappedData = mapResponseNodes(resp.data);

    dispatch(getBySourceSuccess(mappedData));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getBySourceError(e.response.data.description));
    else
      dispatch(getBySourceError(e.message));
  }
};


export const getByLinkNameRequest = createAction('GET_NODES_BY_LINK_NAME_REQUEST');
export const getByLinkNameSuccess = createAction('GET_NODES_BY_LINK_NAME_SUCCESS');
export const getByLinkNameError = createAction('GET_NODES_BY_LINK_NAME_ERROR');

export const getByLinkName = (id) => async (dispatch) => {
  try {
    dispatch(getByLinkNameRequest());
    const resp = await instance.get(`/v1/Cytoscape/GetByLinkName?node=${id}`);
    const mappedData = mapResponseNodes(resp.data);

    dispatch(getByLinkNameSuccess(mappedData));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getByLinkNameError(e.response.data.description));
    else
      dispatch(getByLinkNameError(e.message));
  }
};


export const getByLinkValueRequest = createAction('GET_NODES_BY_LINK_VALUE_REQUEST');
export const getByLinkValueSuccess = createAction('GET_NODES_BY_LINK_VALUE_SUCCESS');
export const getByLinkValueError = createAction('GET_NODES_BY_LINK_VALUE_ERROR');

export const getByLinkValue = (id) => async (dispatch) => {
  try {
    dispatch(getByLinkValueRequest());
    const resp = await instance.get(`/v1/Cytoscape/GetByLinkValue?node=${id}`);
    const mappedData = mapResponseNodes(resp.data);

    dispatch(getByLinkValueSuccess(mappedData));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getByLinkValueError(e.response.data.description));
    else
      dispatch(getByLinkValueError(e.message));
  }
};

const mapResponseNodes = (data) => {
  return CytoscapeComponent.normalizeElements(data);
};


export const getClassificationsRequest = createAction('ENTERPRISE_ARCHITECTURE_GET_CLASSIFICATIONS_REQUEST');
export const getClassificationsSuccess = createAction('ENTERPRISE_ARCHITECTURE_GET_CLASSIFICATIONS_SUCCESS');
export const getClassificationsError = createAction('ENTERPRISE_ARCHITECTURE_GET_CLASSIFICATIONS_ERROR');

export const getClassifications = () => async (dispatch) => {
  try {
    dispatch(getClassificationsRequest());
    const resp = await instance.get(`/v1/Classification/GetAll`);

    dispatch(getClassificationsSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getClassificationsError(e.response.data.description));
    else
      dispatch(getClassificationsError(e.message));
  }
};