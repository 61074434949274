import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getAuditCampaignRequest,
  getAuditCampaignSuccess,
  getAuditCampaignError,
  getLibrariesRequest,
  getLibrariesSuccess,
  getLibrariesError,
  confirmLibrariesRequest,
  confirmLibrariesSuccess,
  confirmLibrariesError
} from './AUSelectLibrariesAction';

const defaultState = {
  auditCampaign: null, 
  isLoadingAuditCampaign: true, 
  getAuditCampaignError: null,
  libraries: [],
  isLoadingLibraries: true,
  getLibrariesError: null,
  isConfirmingLibraries: false, 
  confirmedLibraries: false, 
  confirmLibrariesError: null
};

export default handleActions(
  {
    [getAuditCampaignRequest](state) {
      return {
        ...state,
        auditCampaign: null,
        isLoadingAuditCampaign: true,
        getAuditCampaignError: null,
      };
    },
    [getAuditCampaignSuccess](state, { payload }) {
      return {
        ...state,
        auditCampaign: payload,
        isLoadingAuditCampaign: false,
        getAuditCampaignError: null,
      };
    },
    [getAuditCampaignError](state, { payload }) {
      return {
        ...state,
        auditCampaign: null,
        isLoadingAuditCampaign: false,
        getAuditCampaignError: payload,
      };
    },
    [getLibrariesRequest](state) {
      return {
        ...state,
        libraries: [],
        isLoadingLibraries: true,
        getLibrariesError: null,
      };
    },
    [getLibrariesSuccess](state, { payload }) {
      return {
        ...state,
        libraries: payload,
        isLoadingLibraries: false,
        getLibrariesError: null,
      };
    },
    [getLibrariesError](state, { payload }) {
      return {
        ...state,
        libraries: [],
        isLoadingLibraries: false,
        getLibrariesError: payload,
      };
    },
    [confirmLibrariesRequest](state) {
      return {
        ...state,
        isConfirmingLibraries: true,
        confirmLibrariesError: null,
        confirmedLibraries: false
      };
    },
    [confirmLibrariesSuccess](state, { payload }) {
      return {
        ...state,
        isConfirmingLibraries: false,
        confirmLibrariesError: null,
        confirmedLibraries: true
      };
    },
    [confirmLibrariesError](state, { payload }) {
      return {
        ...state,
        isConfirmingLibraries: false,
        confirmLibrariesError: payload,
        confirmedLibraries: false
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);