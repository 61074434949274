import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getPrioritiesRequest,
  getPrioritiesSuccess,
  getPrioritiesError,
  getCurrentActivitiesRequest,
  getCurrentActivitiesSuccess,
  getCurrentActivitiesError,
  getStepsRequest,
  getStepsSuccess,
  getStepsError,
  getActivityTypesRequest,
  getActivityTypesSuccess,
  getActivityTypesError,
  deleteActivityRequest,
  deleteActivitySuccess,
  deleteActivityError,
  getActivityStatusesRequest,
  getActivityStatusesSuccess,
  getActivityStatusesError,
  getUsersInActivityRequest,
  getUsersInActivitySuccess,
  getUsersInActivityError,
} from './listCurrentActivitiesAction';

const defaultState = {
  priorities: null,
  isLoadingPriorities: true,
  getPrioritiesError: null,
  currentActivities: null,
  isLoadingCurrentActivities: true,
  getCurrentActivitiesError: null,
  activitiesTotalCount: 0,
  steps: [],
  activityTypes: null,
  isLoadingActivityTypes: true,
  getActivityTypesError: null,
  isDeletingActivity: false,
  deletedActivity: false,
  deleteActivityError: null,
  statuses: [],
  isLoadingStatuses: true,
  getStatusesError: null,
  usersInActivity: [],
  isLoadingUsersInActivity: true,
  getUsersInActivityError: null,
};

export default handleActions(
  {
    [getPrioritiesRequest](state) {
      return {
        ...state,
        isLoadingPriorities: true,
        getPrioritiesError: null,
      };
    },
    [getPrioritiesSuccess](state, { payload }) {
      return {
        ...state,
        priorities: payload,
        isLoadingPriorities: false,
        getPrioritiesError: null,
      };
    },
    [getPrioritiesError](state, { payload }) {
      return {
        ...state,
        isLoadingPriorities: false,
        getPrioritiesError: payload,
      };
    },
    [getActivityStatusesRequest](state) {
      return {
        ...state,
        isLoadingStatuses: true,
        getStatusesError: null,
      };
    },
    [getActivityStatusesSuccess](state, { payload }) {
      return {
        ...state,
        statuses: payload,
        isLoadingStatuses: false,
        getStatusesError: null,
      };
    },
    [getActivityStatusesError](state, { payload }) {
      return {
        ...state,
        isLoadingStatuses: false,
        getStatusesError: payload,
      };
    },
    [getCurrentActivitiesRequest](state) {
      return {
        ...state,
        isLoadingCurrentActivities: true,
        getCurrentActivitiesError: null,
      };
    },
    [getCurrentActivitiesSuccess](state, { payload }) {
      const newSteps = payload.data.map((p) => {return {
        workflowActivityId: p.workflowActivityId,
        steps: null,
        isLoadingSteps: false,
        getStepsError: null
      }});

      return {
        ...state,
        currentActivities: payload.data,
        isLoadingCurrentActivities: false,
        getCurrentActivitiesError: null,
        activitiesTotalCount: payload.totalCount,
        steps: newSteps
      };
    },
    [getCurrentActivitiesError](state, { payload }) {
      return {
        ...state,
        isLoadingCurrentActivities: false,
        getCurrentActivitiesError: payload,
      };
    },
    [getStepsRequest](state, { payload }) {
      const newSteps = JSON.parse(JSON.stringify(state.steps));

      const step = newSteps.find(s => s.workflowActivityId === payload.workflowActivityId);
      step.steps = null;
      step.isLoadingSteps = true;
      step.getStepsError = null;

      return {
        ...state,
        steps: newSteps
      };
    },
    [getStepsSuccess](state, { payload }) {
      const newSteps = JSON.parse(JSON.stringify(state.steps));

      const step = newSteps.find(s => s.workflowActivityId === payload.workflowActivityId);
      step.steps = payload.data;
      step.isLoadingSteps = false;
      step.getStepsError = null;

      return {
        ...state,
        steps: newSteps
      };
    },
    [getStepsError](state, { payload }) {
      const newSteps = JSON.parse(JSON.stringify(state.steps));

      const step = newSteps.find(s => s.workflowActivityId === payload.workflowActivityId);
      step.steps = null;
      step.isLoadingSteps = false;
      step.getStepsError = payload.data;

      return {
        ...state,
        steps: newSteps
      };
    },
    [getActivityTypesRequest](state) {
      return {
        ...state,
        isLoadingActivityTypes: true,
        getActivityTypesError: null,
      };
    },
    [getActivityTypesSuccess](state, { payload }) {
      return {
        ...state,
        activityTypes: payload,
        isLoadingActivityTypes: false,
        getActivityTypesError: null,
      };
    },
    [getActivityTypesError](state, { payload }) {
      return {
        ...state,
        isLoadingActivityTypes: false,
        getActivityTypesError: payload,
      };
    },
    [getUsersInActivityRequest](state) {
      return {
        ...state,
        isLoadingUsersInActivity: true,
        getUsersInActivityError: null,
      };
    },
    [getUsersInActivitySuccess](state, { payload }) {
      return {
        ...state,
        usersInActivity: payload,
        isLoadingUsersInActivity: false,
        getUsersInActivityError: null,
      };
    },
    [getUsersInActivityError](state, { payload }) {
      return {
        ...state,
        isLoadingUsersInActivity: false,
        getUsersInActivityError: payload,
      };
    },
    [deleteActivityRequest](state) {
      return {
        ...state,
        isDeletingActivity: true,
        deletedActivity: false,
        deleteActivityError: null,
      };
    },
    [deleteActivitySuccess](state, { payload }) {
      return {
        ...state,
        isDeletingActivity: false,
        deletedActivity: true,
        deleteActivityError: null,
      };
    },
    [deleteActivityError](state, { payload }) {
      return {
        ...state,
        isDeletingActivity: false,
        deletedActivity: false,
        deleteActivityError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);
