import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  countArchivedRequest,
  countArchivedSuccess,
  countArchivedError,
  countClosedRequest,
  countClosedSuccess,
  countClosedError,
  countCreatedRequest,
  countCreatedSuccess,
  countCreatedError,
  countExpiredRequest,
  countExpiredSuccess,
  countExpiredError,
  countToStartRequest,
  countToStartSuccess,
  countToStartError,
  countProcessingRequest,
  countProcessingSuccess,
  countProcessingError,
  getCurrentActivitiesRequest,
  getCurrentActivitiesSuccess,
  getCurrentActivitiesError,
} from './homeDashboardAction';

const defaultState = {
  countCreated: 0,
  isLoadingCountCreated: true,
  getCountCreatedError: null,
  countToStart: 0,
  isLoadingCountToStart: true,
  getCountToStartError: null,
  countProcessing: 0,
  isLoadingCountProcessing: true,
  getCountProcessingError: null,
  countClosed: 0,
  isLoadingCountClosed: true,
  getCountClosedError: null,
  countArchived: 0,
  isLoadingCountArchived: true,
  getCountArchivedError: null,
  countExpired: 0,
  isLoadingCountExpired: true,
  getCountExpiredError: null,
  currentActivities: null,
  isLoadingCurrentActivities: false,
  getCurrentActivitiesError: null,
  activitiesTotalCount: 0,
};

export default handleActions(
  {
    [countCreatedRequest](state) {
      return {
        ...state,
        countCreated: 0,
        isLoadingCountCreated: true,
        getCountCreatedError: null,
      };
    },
    [countCreatedSuccess](state, { payload }) {
      return {
        ...state,
        countCreated: payload,
        isLoadingCountCreated: false,
        getCountCreatedError: null,
      };
    },
    [countCreatedError](state, { payload }) {
      return {
        ...state,
        countCreated: 0,
        isLoadingCountCreated: false,
        getCountCreatedError: payload,
      };
    },
    [countToStartRequest](state) {
      return {
        ...state,
        countToStart: 0,
        isLoadingCountToStart: true,
        getCountToStartError: null,
      };
    },
    [countToStartSuccess](state, { payload }) {
      return {
        ...state,
        countToStart: payload,
        isLoadingCountToStart: false,
        getCountToStartError: null,
      };
    },
    [countToStartError](state, { payload }) {
      return {
        ...state,
        countToStart: 0,
        isLoadingCountToStart: false,
        getCountToStartError: payload,
      };
    },
    [countToStartRequest](state) {
      return {
        ...state,
        countToStart: 0,
        isLoadingCountToStart: true,
        getCountToStartError: null,
      };
    },
    [countToStartSuccess](state, { payload }) {
      return {
        ...state,
        countToStart: payload,
        isLoadingCountToStart: false,
        getCountToStartError: null,
      };
    },
    [countToStartError](state, { payload }) {
      return {
        ...state,
        countToStart: 0,
        isLoadingCountToStart: false,
        getCountToStartError: payload,
      };
    },
    [countProcessingRequest](state) {
      return {
        ...state,
        countProcessing: 0,
        isLoadingCountProcessing: true,
        getCountProcessingError: null,
      };
    },
    [countProcessingSuccess](state, { payload }) {
      return {
        ...state,
        countProcessing: payload,
        isLoadingCountProcessing: false,
        getCountProcessingError: null,
      };
    },
    [countProcessingError](state, { payload }) {
      return {
        ...state,
        countProcessing: 0,
        isLoadingCountProcessing: false,
        getCountProcessingError: payload,
      };
    },
    [countClosedRequest](state) {
      return {
        ...state,
        countClosed: 0,
        isLoadingCountClosed: true,
        getCountClosedError: null,
      };
    },
    [countClosedSuccess](state, { payload }) {
      return {
        ...state,
        countClosed: payload,
        isLoadingCountClosed: false,
        getCountClosedError: null,
      };
    },
    [countClosedError](state, { payload }) {
      return {
        ...state,
        countClosed: 0,
        isLoadingCountClosed: false,
        getCountClosedError: payload,
      };
    },
    [countArchivedRequest](state) {
      return {
        ...state,
        countArchived: 0,
        isLoadingCountArchived: true,
        getCountArchivedError: null,
      };
    },
    [countArchivedSuccess](state, { payload }) {
      return {
        ...state,
        countArchived: payload,
        isLoadingCountArchived: false,
        getCountArchivedError: null,
      };
    },
    [countArchivedError](state, { payload }) {
      return {
        ...state,
        countArchived: 0,
        isLoadingCountArchived: false,
        getCountArchivedError: payload,
      };
    },
    [countExpiredRequest](state) {
      return {
        ...state,
        countExpired: 0,
        isLoadingCountExpired: true,
        getCountExpiredError: null,
      };
    },
    [countExpiredSuccess](state, { payload }) {
      return {
        ...state,
        countExpired: payload,
        isLoadingCountExpired: false,
        getCountExpiredError: null,
      };
    },
    [countExpiredError](state, { payload }) {
      return {
        ...state,
        countExpired: 0,
        isLoadingCountExpired: false,
        getCountExpiredError: payload,
      };
    },
    [getCurrentActivitiesRequest](state) {
      return {
        ...state,
        isLoadingCurrentActivities: true,
        getCurrentActivitiesError: null,
      };
    },
    [getCurrentActivitiesSuccess](state, { payload }) {
      return {
        ...state,
        currentActivities: payload.data,
        isLoadingCurrentActivities: false,
        getCurrentActivitiesError: null,
        activitiesTotalCount: payload.totalCount,
      };
    },
    [getCurrentActivitiesError](state, { payload }) {
      return {
        ...state,
        isLoadingCurrentActivities: false,
        getCurrentActivitiesError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);