import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { FormSelectField } from '@/shared/components/form/material-controls/SelectField';
import { FormDateField } from '@/shared/components/form/material-controls/DateField';
import { cleanup, getWorkflowRoles, getPriorities, getRolesWithUsers } from '@/reducers/activity/generalInfo/generalInfoAction';
import { enqueueSnackbar } from 'notistack';
import { useOutletContext } from 'react-router-dom';
import { Button, Card, CardContent, CircularProgress, Typography } from '@mui/material';
import dayjs from 'dayjs';
import GeneralInfoFields from './generalInfoFields';

const ActivityGeneralInfo = () => {
  
  const dispatch = useDispatch();
  const {activityTypeId, control, handleClickCreate, createLabel} = useOutletContext();

  const { 
    roles, isLoadingRoles, getRolesError,
    priorities, isLoadingPriorities, getPrioritiesError,
    rolesWithUsers, isLoadingRolesWithUsers, getRolesWithUsersError
  } = useSelector(({ generalInfo }) => generalInfo);

  useEffect(() => {
    dispatch(getWorkflowRoles(activityTypeId));
    dispatch(getPriorities());

    return () => {
      dispatch(cleanup());
    }
  }, [dispatch]);

  useEffect(() => {
    if(getRolesError){
      enqueueSnackbar(getRolesError, {variant: "error"});
    }
  }, [getRolesError]);

  useEffect(() => {
    if(getPrioritiesError){
      enqueueSnackbar(getPrioritiesError, {variant: "error"});
    }
  }, [getPrioritiesError]);

  useEffect(() => {
    if(getRolesWithUsersError){
      enqueueSnackbar(getRolesWithUsersError, {variant: "error"});
    }
  }, [getRolesWithUsersError]);

  useEffect(() => {
    if(roles && roles.length){
      dispatch(getRolesWithUsers(roles));
    }    
  }, [roles]);

  return (
    <>
      <GeneralInfoFields 
        control={control}
        priorities={priorities}
        isLoadingRolesWithUsers={isLoadingRolesWithUsers}
        rolesWithUsers={rolesWithUsers}
        isLoadingRoles={isLoadingRoles}
        roles={roles}
      />

      { createLabel &&
        <Row> 
              <Col sm={12}>
                <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
                  <Button variant="contained" onClick={handleClickCreate}>{createLabel}</Button>  
                </div>
              </Col>
        </Row>
      }
    </>
  );
}

export default ActivityGeneralInfo;