import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  addTenantRequest,
  addTenantSuccess,
  addTenantError
} from './systemAddTenantAction';

const defaultState = {
  isAddingTenant: false,
  addedTenant: false,
  addTenantError: null
};

export default handleActions(
  {
    [addTenantRequest](state) {
      return {
        ...state,
        isAddingTenant: true,
        addTenantError: null,
      };
    },
    [addTenantSuccess](state, { payload }) {
      return {
        ...state,
        addedTenant: true,
        isAddingTenant: false,
        addTenantError: null,
      };
    },
    [addTenantError](state, { payload }) {
      return {
        ...state,
        isAddingTenant: false,
        addTenantError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);
