import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import ChatInput from "./chatInput";
import ChatConversation from "./chatConversation";
import { useDispatch, useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { getConversation, sendMessage, cleanup } from "@/reducers/selfAssessment/chat/chatSurveyAction";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';

const ChatModal = ({
    open, 
    onHideModal,
    surveyInfo,
    activitySurveyId,
    questionCode,
    questionText
}) => {
    const dispatch = useDispatch();
    const { 
        conversation, isLoadingConversation, getConversationError,
        isSendingMessage, sentMessage, sendMessageError
    } = useSelector(({ chatSurvey }) => chatSurvey);

    const tokenPayload = useSelector(({ auth }) => auth.tokenPayload);

    useEffect(() => {
        dispatch(getConversation({
            surveyId: surveyInfo.surveyId, 
            activitySurveyId,
            questionCode
        }));
  
        return () => {
            dispatch(cleanup());
        }
    }, []);

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") 
            return;
        
        onHideModal();
    }

    useEffect(() => {
        if(sendMessageError){
          enqueueSnackbar(sendMessageError, {variant: "error"});
        }
    }, [sendMessageError]);

    useEffect(() => {
        if(getConversationError){
          enqueueSnackbar(getConversationError, {variant: "error"});
        }
    }, [getConversationError]);

    const onMessageSend = (message) => {
        const data = {
            message,
            activitySurveyId,
            surveyId: surveyInfo.surveyId,
            questionCode,
            userId: tokenPayload.sub
        };
        dispatch(sendMessage(data));
    };

    return(
        <Dialog
          fullWidth
          maxWidth='lg'
          open={open}
          onClose={handleClose}
        >
            <DialogTitle sx={{paddingBottom: '5px'}}>Conversation</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{marginTop: '0px'}}>
                    {`Question: "${questionText}"`}
                </DialogContentText>
                <Box sx={{
                    marginTop: '10px',
                    height: '480px', 
                    border: '1px solid', 
                    borderRadius: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column'
                }}>
                    <Box sx={{height: 'fill-available', overflowY: 'auto'}}>
                        {isLoadingConversation ? <CircularProgressWrapper /> : 
                            <ChatConversation conversation={conversation}/>
                        }
                    </Box>
                    <Box>
                        <ChatInput onMessageSend={onMessageSend}/>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
      </Dialog>
    );
}

export default ChatModal;