import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MultiSelectAllField({
  name,
  limitTags,
  keyValue={id:"id", label:"name"},
  options,
  value,
  setValue,
  ...other 
}) {
  return (
    <Autocomplete
        multiple
        limitTags={limitTags}
        value={value && value.length ? options?.filter(o => value.includes(o[keyValue.id])) : value}
        filterOptions={(options, params) => {
            if(options && options.length){
                const filter = createFilterOptions();
                const filtered = filter(options, params);
                const newOption = { all: true };
                newOption[keyValue.label] = 'Select All...';
                return [newOption, ...filtered];
            }
            else{
                return options;
            }
        }}
        onChange={(event, data) => {
            if (data.find(option => option.all))
                return setValue(value.length === options.length ? [] : options.map(d => d[keyValue.id]));
    
            setValue(data.map(d => d[keyValue.id]));
        }}
        options={options}
        disableCloseOnSelect
        getOptionLabel={(option) => option[keyValue.label]}
        renderOption={(props, option, { selected }) => (
            <li {...props}>
            <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={option.all ? !!(value.length === options.length) : selected}
            />
            {option[keyValue.label]}
            </li>
        )}
        renderInput={(params) => {

            const { InputProps, ...restParams } = params;
            const { startAdornment, ...restInputProps } = InputProps;

            return (
            <TextField 
                { ...restParams }
                style={{'marginTop': 20}}
                fullWidth
                variant="outlined"
                InputProps={ {
                    ...restInputProps,
                    startAdornment: (
                      <div style={{
                        maxHeight: '150px',
                        overflowY: 'auto',
                      }}
                      >
                        {startAdornment}
                      </div>
                    ),
                  } }
                {...other}
            />
        )}}
    />
  );
}
