import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  massiveUpdateRequest,
  massiveUpdateSuccess,
  massiveUpdateError,
  clearScheduledDatesRequest,
  clearScheduledDatesSuccess,
  clearScheduledDatesError
} from './massiveUpdateAction';

const defaultState = {
    isUpdatingCheckList: false, 
    updatedCheckList: false, 
    updateCheckListError: null,
    isClearingChecklist: false, 
    clearedCheckList: false, 
    clearCheckListError: null
};

export default handleActions(
  {
    [massiveUpdateRequest](state) {
      return {
        ...state,
        isUpdatingCheckList: true,
        updatedCheckList: false,
        updateCheckListError: null
      };
    },
    [massiveUpdateSuccess](state, { payload }) {
      return {
        ...state,
        isUpdatingCheckList: false,
        updatedCheckList: true,
        updateCheckListError: null
      };
    },
    [massiveUpdateError](state, { payload }) {
      return {
        ...state,
        isUpdatingCheckList: false,
        updatedCheckList: false,
        updateCheckListError: payload
      };
    },
    [clearScheduledDatesRequest](state) {
        return {
          ...state,
          isClearingChecklist: true,
          clearedCheckList: false,
          clearCheckListError: null
        };
      },
      [clearScheduledDatesSuccess](state, { payload }) {
        return {
          ...state,
          isClearingChecklist: false,
          clearedCheckList: true,
          clearCheckListError: null
        };
      },
      [clearScheduledDatesError](state, { payload }) {
        return {
          ...state,
          isClearingChecklist: false,
          clearedCheckList: false,
          clearCheckListError: payload
        };
      },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);