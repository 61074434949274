import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Card, CardContent, Chip, CircularProgress, Collapse, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import dayjs from "dayjs";
import WarningIcon from '@mui/icons-material/Warning';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FlagIcon from '@mui/icons-material/Flag';
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined';
import NotesIcon from '@mui/icons-material/Notes';
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import ActivityMenu from "./ActivityMenu";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import StepList from "./StepList";
import OutputIcon from '@mui/icons-material/Output';

const ActivityListItem = ({
    activity, 
    handleActivityEditClick,
    handleActivityDeleteClick,
    activitySteps,
    loadSteps,
    activityType,
    showOptions
}) => {

    const navigate = useNavigate();

    const [showTasks, setShowTasks] = useState(false);
    const [touchedTasks, setTouchedTasks] = useState(activitySteps.steps);
    
    const handleClickLoadTasks = () => {
        setShowTasks(!showTasks);
        
        if(!touchedTasks){
            setTouchedTasks(true);
            loadSteps(activity.workflowActivityId);
        }
    }

    var dateString = "";
    if(activity.startDate)
        dateString += dayjs(activity.startDate).format("DD MMM YY");

    if(activity.endDate)
        dateString += " - " + dayjs(activity.endDate).format("DD MMM YY");

    const handleClickEdit = () => {
        navigate("/Activities/EditStep/" + activity.workflowActivityId);
    }

    return(
        <CardContentWrapper style={{paddingBlock: 5}}>
            <ColorLine style={{backgroundColor: activityType.applicationModule.color}} />
            <ListItem alignItems="flex-start" disablePadding>
                <Card sx={{
                    width: '100%',
                    p: 2
                }}>
                            <Row>
                                <Col md={12}>
                                    <TitleContainer>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <Typography style={{fontSize: 22}}>{activityType.label}</Typography>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}>
                                                <Chip 
                                                    label={activity.workflowActivityStatus.workflowGenericStatus.label} 
                                                    size="small" 
                                                    sx={{
                                                        backgroundColor: activity.workflowActivityStatus.workflowGenericStatus.color, 
                                                        fontSize: '12px',
                                                        height: '20px',
                                                        marginInline: 1
                                                    }} 
                                                />
                                                <Typography 
                                                    color='text.secondary' 
                                                    sx={{
                                                        fontSize: 14
                                                    }}>
                                                    {dateString}
                                                </Typography>
                                            </Box>
                                        </Box>

                                        { showOptions && 
                                            <ActivityMenu 
                                                activity={activity}
                                                handleClickEditActivity={handleActivityEditClick}
                                                handleActivityDeleteClick={handleActivityDeleteClick}
                                                handleClickView={handleClickEdit}
                                            />
                                        }
                                    </TitleContainer>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Box sx={{
                                        width: '100%',
                                        height: 80,
                                        overflowY: 'auto',
                                        paddingInline: 1,
                                        marginBottom: 1,
                                        borderLeft: (theme) => `1px solid ${theme.palette.text.disabled}`
                                    }}>
                                        <Typography color="text.secondary" fontSize={14}>{activity.description}</Typography>
                                    </Box>
                                    <div style={{display: "flex", width: '100%', justifyContent: 'space-between', alignItems: 'flex-end'}}>
                                        <Chip 
                                            label={activity.workflowActivityPriority.name} 
                                            size="small" 
                                            sx={{
                                                backgroundColor: activity.workflowActivityPriority.color, 
                                                fontSize: '12px',
                                                height: '20px'
                                            }}  
                                        />

                                        <div>
                                            <Button 
                                                variant="contained"
                                                color="info"
                                                startIcon={<FormatListBulletedIcon />}
                                                endIcon={showTasks ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                onClick={handleClickLoadTasks}
                                            >
                                                Tasks
                                            </Button>
                                            <Button 
                                                style={{marginLeft: 10}}
                                                variant="contained"
                                                endIcon={<OutputIcon />}
                                                onClick={handleClickEdit}
                                            >
                                                Enter
                                            </Button>
                                        </div>
                                    </div>
                                </Col>

                                <Col md={12}>
                                    <StepList 
                                        activity={activity}
                                        activitySteps={activitySteps}
                                        handleClickEdit={handleClickEdit}
                                        touchedTasks={touchedTasks}
                                        showTasks={showTasks}
                                    />
                                </Col>
                            </Row>
                </Card>
            </ListItem>
        </CardContentWrapper>
    );
}

const TitleContainer = styled.div`
display: flex;
justify-content: space-between;
width: 100%;
`;

const CardContentWrapper = styled.div`
display: flex;
width: 100%;
`;

const ColorLine = styled.div`
padding-inline: 2px;
height: auto;
`;

export default ActivityListItem;