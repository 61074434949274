import { Card as BootstrapCard } from 'react-bootstrap';
import styled from 'styled-components';
import { colorBackground } from '@/utils/palette';
import { borderRadius, shadow } from '@/utils/styles';
import { left } from '@/utils/directions';


export const Card = styled(BootstrapCard)`
  width: 100%;
  padding-bottom: 30px;
  height: ${props => (props.height || '100%')};
  border: none;
  background-color: transparent;
`;


export const CopilotPageBox = styled(BootstrapCard)`
  width: 100%;
  padding-bottom: 30px;
  height: ${props => (props.height || '100%')};
  border: none;
  background-color: transparent;
`;

export const CopilotPageBoxBody = styled(Card.Body)`
  height: 100%;
  background-color: ${colorBackground};
  border-radius: ${borderRadius};
  box-shadow: ${shadow};
  padding: 20px;
`;

export const CopilotPageBoxTitleWrap = styled.div`
  margin-bottom: 30px;
  
  position: relative;
  text-align: ${left};

  &:not(:first-child) {
    margin-top: 40px;
  }

  * {
    margin-bottom: 0;
  }
`;

export const CopilotPageBoxButtonWrap = styled.div`
  padding-bottom: 10px;
`;

export const CopilotPageBoxTitle = styled.h5`
  font-size: 28px;
  text-align: ${left};
  font-weight: 500;
  ${props => props.centered && `
    text-align: center;
  `}
`;

export const CopilotPageBoxSubhead = styled.p`
  text-transform: none;
  font-size: 14px;
  line-height: 18px;
  opacity: 0.7;
  margin-top: 14px;
`;
