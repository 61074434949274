import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getRolesRequest,
  getRolesSuccess,
  getRolesError,
  addUserRequest,
  addUserSuccess,
  addUserError
} from './stakeholderAddUserAction';

const defaultState = {
  roles: [],
  isLoadingRoles: true,
  getRolesError: null,
  tenants: null,
  isLoadingTenants: true,
  getTenantsError: null,
  isAddingUser: false,
  addedUser: false,
  addUserError: null
};

export default handleActions(
  {
    [getRolesRequest](state) {
      return {
        ...state,
        isLoadingRoles: true,
        getRolesError: null,
      };
    },
    [getRolesSuccess](state, { payload }) {
      return {
        ...state,
        roles: payload,
        isLoadingRoles: false,
        getRolesError: null,
      };
    },
    [getRolesError](state, { payload }) {
      return {
        ...state,
        isLoadingRoles: false,
        getRolesError: payload,
      };
    },
    [addUserRequest](state) {
      return {
        ...state,
        isAddingUser: true,
        addUserError: null,
      };
    },
    [addUserSuccess](state, { payload }) {
      return {
        ...state,
        addedUser: true,
        isAddingUser: false,
        addUserError: null,
      };
    },
    [addUserError](state, { payload }) {
      return {
        ...state,
        isAddingUser: false,
        addUserError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);
