import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  addSupplierRequest,
  addSupplierSuccess,
  addSupplierError,
  getStakeholderTypesRequest,
  getStakeholderTypesSuccess,
  getStakeholderTypesError
} from './addSupplierAction';

const defaultState = {
  isAddingSupplier: false,
  addSupplierStatusError: null,
  addedSupplier: false,
  stakeholderTypes: [],
  isLoadingStakeholderTypes: true,
  getStakeholderTypesError: null
};

export default handleActions(
  {
    [addSupplierRequest](state) {
      return {
        ...state,
        isAddingSupplier: true,
        addSupplierError: null,
        addedSupplier: false
      };
    },
    [addSupplierSuccess](state, { payload }) {
      return {
        ...state,
        isAddingSupplier: false,
        addSupplierError: null,
        addedSupplier: true
      };
    },
    [addSupplierError](state, { payload }) {
      return {
        ...state,
        isAddingSupplier: false,
        addSupplierError: payload,
        addedSupplier: false
      };
    },
    [getStakeholderTypesRequest](state) {
      return {
        ...state,
        isLoadingStakeholderTypes: true,
        getStakeholderTypesError: null,
      };
    },
    [getStakeholderTypesSuccess](state, { payload }) {
      return {
        ...state,
        stakeholderTypes: payload,
        isLoadingStakeholderTypes: false,
        getStakeholderTypesError: null,
      };
    },
    [getStakeholderTypesError](state, { payload }) {
      return {
        ...state,
        isLoadingStakeholderTypes: false,
        getStakeholderTypesError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);