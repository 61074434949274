import { Backdrop, Button, CircularProgress, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridToolbar, gridClasses } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { cleanup, getVulnerabilityScores, deleteVulnerabilityScore } from "@/reducers/vulnerabilityScores/vulnerabilityScoresAction";
import { Col, Container, Row } from "react-bootstrap";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { enqueueSnackbar } from "notistack";
import CreateVulnerabilityScoreModal from "./components/createVulnerabilityScoreModal";
import EditVulnerabilityScoreModal from "./components/editVulnerabilityScoreModal";
import AddIcon from '@mui/icons-material/Add';
import ConfirmationDialog from '@/shared/components/ConfirmationDialog';
import { CardPage, CardPageFilterWrapper, CardPageHeader, CardPageBody, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap,CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import ColorCell from "../../../shared/components/datagrid/ColorCell";

const VulnerabilityScoring = () => {

    const dispatch = useDispatch();
    const { 
        vulnerabilityScores, isLoadingVulnerabilityScores, getVulnerabilityScoresError,
        isDeletingVulnerabilityScore, deletedVulnerabilityScore, deleteVulnerabilityScoreError,
    } = useSelector(({ vulnerabilityScores }) => vulnerabilityScores);

    const [showVulnerabilityScoreCreateModal, setShowVulnerabilityScoreCreateModal] = useState(false);

    const [showVulnerabilityScoreEditModal, setShowVulnerabilityScoreEditModal] = useState(false);
    const [selectedVulnerabilityScore, setSelectedVulnerabilityScore] = useState(null);

    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [vulnerabilityScoreToDelete, setVulnerabilityScoreToDelete] = useState(null);

    useEffect(() => {
        dispatch(getVulnerabilityScores());
    
        return () => {
          dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
        if(getVulnerabilityScoresError){
            enqueueSnackbar(getVulnerabilityScoresError, {variant: "error"});
        }
    }, [getVulnerabilityScoresError]);

    useEffect(() => {
        if(deleteVulnerabilityScoreError){
            enqueueSnackbar(deleteVulnerabilityScoreError, {variant: "error"});
        }
    }, [deleteVulnerabilityScoreError]);

    const handleClickEditVulnerabilityScore = (vulnerabilityScore) => {
        setSelectedVulnerabilityScore(vulnerabilityScore);
        setShowVulnerabilityScoreEditModal(true);
    }

    const handleClickDeleteVulnerabilityScore = (vulnerabilityScore) => {
        setVulnerabilityScoreToDelete(vulnerabilityScore);
        setOpenConfirmDelete(true);
    }

    const onClickCreateCreateModal = () => {
        setShowVulnerabilityScoreCreateModal(true);
    };

    const onHideVulnerabilityScoreCreateModal = () => {
        setShowVulnerabilityScoreCreateModal(false);
    };

    const onHideVulnerabilityScoreEditModal = () => {
        setShowVulnerabilityScoreEditModal(false);
        setSelectedVulnerabilityScore(null);
    };

    const onVulnerabilityScoreCreated = () => {
        setShowVulnerabilityScoreCreateModal(false);
        dispatch(getVulnerabilityScores());
    }
    
    const onVulnerabilityScoreUpdated = () => {
        setShowVulnerabilityScoreEditModal(false);
        setSelectedVulnerabilityScore(null);
        dispatch(getVulnerabilityScores());
    }

    const handleConfirmDelete = () => {
        setOpenConfirmDelete(false);
        dispatch(deleteVulnerabilityScore(vulnerabilityScoreToDelete.id));
        setVulnerabilityScoreToDelete(null);
    }

    const handleOnCloseDelete = () => {
        setOpenConfirmDelete(false);
        setVulnerabilityScoreToDelete(null);
    };

    useEffect(() => {
        if(deletedVulnerabilityScore){
            enqueueSnackbar("Vulnerability Score deleted successfully", {variant: "success"});
            dispatch(getVulnerabilityScores());
        }
    }, [deletedVulnerabilityScore]);

    const columns = [
        { 
            field: 'type', 
            headerName: 'Type',
            minWidth: 250,
            flex: 1,
        },
        { 
            field: 'name', 
            headerName: 'Name',
            minWidth: 150,
            flex: 1,
        },
        { 
            field: 'minValue', 
            headerName: 'Minimum Value',
            minWidth: 150,
            flex: 1,
        },
        { 
            field: 'maxValue', 
            headerName: 'Maximum Value',
            minWidth: 150,
            flex: 0.5,
        },
        { 
            field: 'color', 
            headerName: 'Color',
            minWidth: 150,
            flex: 0.5,
            renderCell: (params) => <ColorCell color={params.value}/>,
        },
        { 
            field: 'systemVersionData', 
            headerName: 'System Version',
            minWidth: 150,
            flex: 0.5,
        },
        { 
            field: 'isSystem', 
            headerName: 'System',
            minWidth: 150,
            flex: 0.5,
            type: 'boolean',
        },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => {
                const actions = [];

                if(!params.row.isSystem && !params.row.isReadOnly){
                    actions.push(
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="Delete"
                            onClick={() => handleClickDeleteVulnerabilityScore(params.row)}
                        />
                    );
                }

                if(!params.row.isReadOnly){
                    actions.push(
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="Delete"
                            onClick={() => handleClickDeleteVulnerabilityScore(params.row)}
                        />
                    );
                }

                return actions;
            }
          },
      ];

    return(
        <Container>
            <CardPage>
                <CardPageBody>
                    <CardPageHeader>
                        <CardPageTitleWrap>
                            <CardPageTitle>Vulnerability Scores</CardPageTitle>
                            <CardPageSubhead>
                                View and manage your vulnerability scores.
                            </CardPageSubhead>
                        </CardPageTitleWrap>
                        <CardPageButtonWrap>
                            <Button 
                                variant="contained" 
                                onClick={onClickCreateCreateModal} 
                                endIcon={<AddIcon/>}
                            >
                                Create Vulnerability Score
                            </Button>
                        </CardPageButtonWrap>
                    </CardPageHeader>

                    <CardPageGridWrap>
                        <DataGrid 
                            autoHeight
                            density="compact"
                            disableRowSelectionOnClick
                            loading={isLoadingVulnerabilityScores} 
                            rows={vulnerabilityScores}
                            columns={columns} 
                            slots={{ toolbar: GridToolbar }} 
                            slotProps={{
                                toolbar: {
                                showQuickFilter: true
                                },
                            }}
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                py: 1,
                                },
                            }}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 30 } },
                            }}
                            pageSizeOptions={[15, 30, 50, 100]}
                        />
                    </CardPageGridWrap>

                    { showVulnerabilityScoreCreateModal &&
                        <CreateVulnerabilityScoreModal
                            onHideModal={onHideVulnerabilityScoreCreateModal}
                            open={showVulnerabilityScoreCreateModal}
                            onVulnerabilityScoreCreated={onVulnerabilityScoreCreated}
                        />
                    }

                    { showVulnerabilityScoreEditModal &&
                        <EditVulnerabilityScoreModal
                            onHideModal={onHideVulnerabilityScoreEditModal}
                            open={showVulnerabilityScoreEditModal}
                            onVulnerabilityScoreUpdated={onVulnerabilityScoreUpdated}
                            vulnerabilityScore={selectedVulnerabilityScore}
                        />
                    }

                    { openConfirmDelete &&
                        <ConfirmationDialog 
                            open={openConfirmDelete}
                            onConfirm={handleConfirmDelete}
                            onClose={handleOnCloseDelete}
                            title="Delete Vulnerability Score"
                            body={`Are you sure you want to delete ${vulnerabilityScoreToDelete.name}?`}
                            color='error'
                            confirmButtonText='Delete'
                        />
                    }

                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isDeletingVulnerabilityScore}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </CardPageBody>
            </CardPage>
        </Container>
    )
}

export default VulnerabilityScoring;