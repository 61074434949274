import { useSelector } from "react-redux";
import ChatMessage from "./chatMessage";
import { Avatar, Box, Tooltip, Typography } from "@mui/material";

const ChatConversation = ({
    conversation
}) => {

    const tokenPayload = useSelector(({ auth }) => auth.tokenPayload);

    const { 
        rolesAndUsers
    } = useSelector(({ activityUserRoles }) => activityUserRoles);

    return(
        conversation && conversation.messages ? 
            conversation.messages.map((message, index) => {
                    var showAvatar = false;
                    var userInfo;
                    var isMine = true;
                    
                    if(tokenPayload.sub !== message.userId){
                        isMine = false;

                        //Cambio user, mettere avatar sopra
                        if(index === 0 || conversation.messages[index - 1].userId !== message.userId){
                            showAvatar = true;

                            const userIndex = rolesAndUsers.users.indexOf(rolesAndUsers.users.find(u => u.id === message.userId));
                            
                            if(userIndex !== -1){
                                userInfo = {
                                    fullName: rolesAndUsers.users[userIndex].fullName,
                                    roleName: rolesAndUsers.roles[userIndex].name
                                };
                            }
                            else{
                                userInfo = {
                                    fullName: 'SystemAdministrator',
                                    roleName: "SystemAdministrator"
                                };
                            }
                        }
                    }

                    return(
                        <Box sx={{
                            display: 'flex'
                        }}>
                            { showAvatar && 
                                <Tooltip title={`${userInfo.fullName} (${userInfo.roleName})`}>
                                    <Box 
                                        sx={{
                                            marginTop: '10px',
                                            marginLeft: '10px'
                                        }}>
                                        <Avatar {...stringAvatar(userInfo.fullName)} />
                                    </Box>
                                </Tooltip>
                            }
                            
                            <ChatMessage 
                                message={message}
                                isMine={isMine}
                                hasAvatar={showAvatar}
                            />
                        </Box>
                    );
                })
            :
                <Box sx={{
                    display: 'flex', 
                    justifyContent: "center", 
                    alignItems: 'center', 
                    height: '100%'
                }}>
                    <Typography>There aren't messages yet, send the first to start a conversation for this question.</Typography>
                </Box>
    );
}

function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  function stringAvatar(name) {

    var letters;
    if(name.split(' ')[1]){
        letters = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
    }
    else{
        letters = name.split(' ')[0][0];
    }

    console.log(letters);

    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: letters,
    };
  }

export default ChatConversation;