import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cleanup, getFieldValues } from "@/reducers/fieldValues/fieldValuesAction";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { enqueueSnackbar } from "notistack";

const MaterialFieldValuesTable = ({ controller, field, onValueSelected }) => {
    const { fieldValues, isLoadingFieldValues, getFieldValuesError } = useSelector(({ fieldValues }) => fieldValues);
  
    const dispatch = useDispatch();
  
    useEffect(() => {
        if(field){
            dispatch(getFieldValues(controller, field));
        }

        return () => {
            dispatch(cleanup());
        }
    }, [field]);

    useEffect(() => {
        if(getFieldValuesError){
            enqueueSnackbar(getFieldValuesError, {variant: "error"});
        }
    }, [getFieldValuesError]);

    const mapFieldValues = () => {
        if(fieldValues)
            return fieldValues.map(f => {return { id:f, value: f}});
        else
            return [];
    }

    const columns = [
        { 
            field: 'value', 
            headerName: 'Values', 
            flex: 1
        },
        {
            field: 'actions',
            headerName: 'Actions',
            type: 'actions',
            width: 150,
            getActions: ({ id }) => {
              return [
                <GridActionsCellItem
                  icon={<ContentCopyIcon />}
                  label="Copy"
                  title="Copy"
                  onClick={() => onValueSelected(id)}
                />
              ];
            }
          }
        ];

    return (
        <div style={{height: 400}}>
            <DataGrid 
                autoHeight
                density="compact"
                disableRowSelectionOnClick
                loading={isLoadingFieldValues} 
                rows={mapFieldValues()}
                columns={columns}
                slotProps={{
                    toolbar: {
                      showQuickFilter: true
                    },
                }}
                initialState={{
                    pagination: { paginationModel: { pageSize: 30 } },
                }}
                pageSizeOptions={[15, 30, 50, 100]}
            />
        </div>
    );
}



export default MaterialFieldValuesTable;