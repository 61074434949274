import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_ASSET_INFO');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getAssetRequest = createAction('GET_ASSET_REQUEST');
export const getAssetSuccess = createAction('GET_ASSET_SUCCESS');
export const getAssetError = createAction('GET_ASSET_ERROR');

export const getAsset = (id) => async (dispatch) => {
  try {
    dispatch(getAssetRequest());
    const resp = await instance.get(`/v1/Asset/GetById/${id}`);
    
    dispatch(getAssetSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getAssetError(e.response.data.description));
    else
      dispatch(getAssetError(e.message));
  }
};

// export const getConfidentialityRequest = createAction('GET_ASSET_CONFIDENTIALITY_REQUEST');
// export const getConfidentialitySuccess = createAction('GET_ASSET_CONFIDENTIALITY_SUCCESS');
// export const getConfidentialityError = createAction('GET_ASSET_CONFIDENTIALITY_ERROR');

// export const getConfidentiality = (id) => async (dispatch) => {
//   try {
//     dispatch(getConfidentialityRequest());
//     const resp = await instance.get(`/v1/Classification/GetById/${id}`);
    
//     dispatch(getConfidentialitySuccess(resp.data));
//   } catch (e) {
//     if(e.response && e.response.data && e.response.data.description)
//       dispatch(getConfidentialityError(e.response.data.description));
//     else
//       dispatch(getConfidentialityError(e.message));
//   }
// };

// export const getIntegrityRequest = createAction('GET_ASSET_INTEGRITY_REQUEST');
// export const getIntegritySuccess = createAction('GET_ASSET_INTEGRITY_SUCCESS');
// export const getIntegrityError = createAction('GET_ASSET_INTEGRITY_ERROR');

// export const getIntegrity = (id) => async (dispatch) => {
//   try {
//     dispatch(getIntegrityRequest());
//     const resp = await instance.get(`/v1/Classification/GetById/${id}`);
    
//     dispatch(getIntegritySuccess(resp.data));
//   } catch (e) {
//     if(e.response && e.response.data && e.response.data.description)
//       dispatch(getIntegrityError(e.response.data.description));
//     else
//       dispatch(getIntegrityError(e.message));
//   }
// };

// export const getAvailabilityRequest = createAction('GET_ASSET_AVAILABILITY_REQUEST');
// export const getAvailabilitySuccess = createAction('GET_ASSET_AVAILABILITY_SUCCESS');
// export const getAvailabilityError = createAction('GET_ASSET_AVAILABILITY_ERROR');

// export const getAvailability = (id) => async (dispatch) => {
//   try {
//     dispatch(getAvailabilityRequest());
//     const resp = await instance.get(`/v1/Classification/GetById/${id}`);
    
//     dispatch(getAvailabilitySuccess(resp.data));
//   } catch (e) {
//     if(e.response && e.response.data && e.response.data.description)
//       dispatch(getAvailabilityError(e.response.data.description));
//     else
//       dispatch(getAvailabilityError(e.message));
//   }
// };