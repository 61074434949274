import React from "react";
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { FormTextField } from "@/shared/components/form/material-controls/TextField";
import SearchIcon from '@mui/icons-material/Search';
import { Button } from "@mui/material";

const BasicFilters = ({ handleGetByTarget, handleGetBySource, handleGetByLinkName, handleGetByLinkValue }) => {  

  const {
    handleSubmit: handleSubmitByTarget,
    control: controlByTarget,
  } = useForm();

  const {
    handleSubmit: handleSubmitBySource,
    control: controlBySource,
  } = useForm();

  const {
    handleSubmit: handleSubmitByLinkName,
    control: controlByLinkName,
  } = useForm();

  const {
    handleSubmit: handleSubmitByLinkValue,
    control: controlByLinkValue,
  } = useForm();

  const OnSubmitByTarget = (data) => {
    handleGetByTarget(data.byTarget);
  };

  const OnSubmitBySource = (data) => {
    handleGetBySource(data.bySource);
  };

  const OnSubmitByLinkName = (data) => {
    handleGetByLinkName(data.byLinkName);
  };

  const OnSubmitByLinkValue = (data) => {
    handleGetByLinkValue(data.byLinkValue);
  };

  return (
    <div>
      <Row style={{'width': '100%', 'marginBottom': '20px'}}>
        <Col xs={11}>
          <FormTextField 
            style={{marginTop: 0}}
            name="byTarget"
            label="By Target"
            control={controlByTarget}
            rules={{ required: 'This field is required' }}
          />
        </Col>
        <Col style={{'padding': '0px'}} xs={1}>
          <Button variant="outlined" onClick={handleSubmitByTarget(OnSubmitByTarget)}>
            <SearchIcon />
          </Button>
        </Col>
      </Row>
      <Row style={{'width': '100%', 'marginBottom': '20px'}}>
        <Col xs={11}>
          <FormTextField 
            style={{marginTop: 0}}
            name="bySource"
            label="By Source"
            control={controlBySource}
            rules={{ required: 'This field is required' }}
          />
        </Col>
        <Col style={{'padding': '0px'}} xs={1}>
          <Button variant="outlined" onClick={handleSubmitBySource(OnSubmitBySource)}>
            <SearchIcon />
          </Button>
        </Col>
      </Row>
      <Row style={{'width': '100%', 'marginBottom': '20px'}}>
        <Col xs={11}>
          <FormTextField 
            style={{marginTop: 0}}
            name="byLinkName"
            label="By Link Name"
            control={controlByLinkName}
            rules={{ required: 'This field is required' }}
          />
        </Col>
        <Col style={{'padding': '0px'}} xs={1}>
          <Button variant="outlined" onClick={handleSubmitByLinkName(OnSubmitByLinkName)}>
            <SearchIcon />
          </Button>
        </Col>
      </Row>
      <Row style={{'width': '100%', 'marginBottom': '20px'}}>
        <Col xs={11}>
          <FormTextField 
            style={{marginTop: 0}}
            name="byLinkValue"
            label="By Link Value"
            control={controlByLinkValue}
            rules={{ required: 'This field is required' }}
          />
        </Col>
        <Col style={{'padding': '0px'}} xs={1}>
          <Button variant="outlined" onClick={handleSubmitByLinkValue(OnSubmitByLinkValue)}>
            <SearchIcon />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

BasicFilters.propTypes = {
    handleGetBySource: PropTypes.func, 
    handleGetByLinkName: PropTypes.func, 
    handleGetByLinkValue: PropTypes.func,
    handleOpenFieldValueModal: PropTypes.func
};

export default BasicFilters;