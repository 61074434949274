import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_STAKEHOLDER_USER_LIST');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getRolesRequest = createAction('GET_ROLES_STAKEHOLDER_USER_LIST_REQUEST');
export const getRolesSuccess = createAction('GET_ROLES_STAKEHOLDER_USER_LIST_SUCCESS');
export const getRolesError = createAction('GET_ROLES_STAKEHOLDER_USER_LIST_ERROR');

export const getRoles = () => async (dispatch) => {
  try {
    dispatch(getRolesRequest());

    const resp = await instance.get(`/v1/UserAndRole/GetApplicationRoles`);
    
    dispatch(getRolesSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getRolesError(e.response.data.description));
    else
      dispatch(getRolesError(e.message));
  }
};

export const getUsersRequest = createAction('GET_STAKEHOLDER_USER_LIST_REQUEST');
export const getUsersSuccess = createAction('GET_STAKEHOLDER_USER_LIST_SUCCESS');
export const getUsersError = createAction('GET_STAKEHOLDER_USER_LIST_ERROR');

export const getUsers = (data) => async (dispatch) => {
  try {
    dispatch(getUsersRequest());
    
    const resp = await instance.get(`/v1/StakeholderApplicationUser/GetListPagedByFilter`, { params: {
      ...data
    }});
    
    dispatch(getUsersSuccess(resp.data));

  } catch (e) {
    
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getUsersError(e.response.data.description));
    else
      dispatch(getUsersError(e.message));
  }
};

export const deleteStakeholderUserRequest = createAction('STAKEHOLDER_USER_LIST_DELETE_REQUEST');
export const deleteStakeholderUserSuccess = createAction('STAKEHOLDER_USER_LIST_DELETE_SUCCESS');
export const deleteStakeholderUserError = createAction('STAKEHOLDER_USER_LIST_DELETE_ERROR');

export const deleteStakeholderUser = (id) => async (dispatch) => {
  try {
    dispatch(deleteStakeholderUserRequest());
    const resp = await instance.delete(`/v1/StakeholderApplicationUser/DeleteLogic/${id}`);

    dispatch(deleteStakeholderUserSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(deleteStakeholderUserError(e.response.data.description));
    else
      dispatch(deleteStakeholderUserError(e.message));
  }
};