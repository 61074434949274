import { Box, Drawer, Typography } from "@mui/material";
import BotAvatar from "../../../../shared/components/chatbot/BotAvatar";
import HelpPanelHeader from "./helpPanelHeader";

const HelpPanel = ({
    open,
    onClickClose
}) => {
    return(
        <Drawer
            anchor='right'
            open={open}
            onClose={onClickClose}
            PaperProps={{
                sx:{
                    width: '600px',
                    maxWidth: '100%',
                }
            }}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                p: 2
            }}>
                <HelpPanelHeader onClickClose={onClickClose}/>

                <Box sx={{
                    height: 'fill-available',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    {/* <BotAvatar size='100px' /> */}
                    <Typography variant="h5">Help Guide</Typography>
                </Box>
            </Box>
        </Drawer>
    );
};

export default HelpPanel;