import { Avatar, Chip, Tooltip, Typography } from "@mui/material";

const ClassificationChip = ({
    classification,
    letter,
    name,
    sx
}) => {
    return(
        classification && 
        <Tooltip title={name} placement="top">
            <Chip 
                avatar={ letter ?
                    <Avatar sx={{
                        backgroundColor: 'white'
                    }}>
                        <Typography color='rgba(0, 0, 0, 0.6)' fontWeight='bold' align="center">
                            {letter}
                        </Typography>
                    </Avatar>
                    :
                    null
                }
                label={classification.name} 
                sx={{
                    backgroundColor: classification.color, 
                    marginLeft: '10px',
                    ...sx                    
                }} 
            />
        </Tooltip>
    );
}

export default ClassificationChip;