import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";
export const cleanupAction = createAction('CLEANUP_SURVEY_CHAT');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const sendMessageRequest = createAction('SEND_MESSAGE_SURVEY_CHAT_REQUEST');
export const sendMessageSuccess = createAction('SEND_MESSAGE_SURVEY_CHAT_SUCCESS');
export const sendMessageError = createAction('SEND_MESSAGE_SURVEY_CHAT_ERROR');

export const sendMessage = (data) => async (dispatch) => {
  try {
    dispatch(sendMessageRequest(data));
    const resp = await instance.post(`/v1/ActivitySurvey/SendMessage`, data);
    
    dispatch(sendMessageSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.description)
      dispatch(sendMessageError(e.response.data.description));
    else
      dispatch(sendMessageError(e.message));
  }
};

export const getConversationRequest = createAction('GET_CONVERSATION_SURVEY_CHAT_REQUEST');
export const getConversationSuccess = createAction('GET_CONVERSATION_SURVEY_CHAT_SUCCESS');
export const getConversationError = createAction('GET_CONVERSATION_SURVEY_CHAT_ERROR');

export const getConversation = (data) => async (dispatch) => {
  try {    

    dispatch(getConversationRequest());
    const resp = await instance.get(`/v1/ActivitySurvey/GetConversation`, { params: {
      ...data
    }});
    
    dispatch(getConversationSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getConversationError(e.response.data.description));
    else
      dispatch(getConversationError(e.message));
  }
};