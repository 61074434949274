import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { getAuditCampaign, getAssets, confirmAssets, cleanup } from "@/reducers/auditCampaign/selectAssets/AUSelectAssetsAction";
import { enqueueSnackbar } from "notistack";
import { Col, Row } from "react-bootstrap";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import { Backdrop, Button, CircularProgress, Typography } from "@mui/material";
import { useState } from "react";
import AssetSelectCard from "./components/assetSelectCard";
import { useTranslation } from "react-i18next";

const AUCampaignSelectAsset = () => {

    const { 
        activity, 
        workflowActivityStepCompletedId, 
        isEditor,
        handleRefreshProgress,
        handleClickNext
    } = useOutletContext();

    const {t} = useTranslation("common");
    const dispatch = useDispatch();
    
    const { 
        auditCampaign, isLoadingAuditCampaign, getAuditCampaignError,
        assets, isLoadingAssets, getAssetsError,
        isConfirmingAssets, confirmedAssets, confirmAssetsError
    } = useSelector(({ auSelectAssets }) => auSelectAssets);

    const [selectedAssets, setSelectedAssets] = useState([]);

    useEffect(() => {
        dispatch(getAuditCampaign(activity.workflowActivityId));
        dispatch(getAssets());

        return () => {
            dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
        if(auditCampaign){
            const assetIds = auditCampaign.campaignAssets 
                            ? [...auditCampaign.campaignAssets.map(c => c.assetId)] 
                            : [];

            setSelectedAssets(assetIds);
        }
    }, [auditCampaign]);

    useEffect(() => {
        if(getAuditCampaignError){
          enqueueSnackbar(getAuditCampaignError, {variant: "error"});
        }
    }, [getAuditCampaignError]);

    useEffect(() => {
        if(getAssetsError){
          enqueueSnackbar(getAssetsError, {variant: "error"});
        }
    }, [getAssetsError]);

    useEffect(() => {
        if(confirmAssetsError){
          enqueueSnackbar(confirmAssetsError, {variant: "error"});
        }
    }, [confirmAssetsError]);

    const onClickAsset = (asset) => {
        var clone = [...selectedAssets];

        if(clone.includes(asset.assetId)){
            const index = clone.indexOf(asset.assetId);
            clone.splice(index, 1);

            setSelectedAssets([...clone]);
        }
        else{
            clone.push(asset.assetId);
            setSelectedAssets([...clone]);
        }
    };

    const handleClickConfirmSelection = () => {
        const finalData = {
            id: auditCampaign.id,
            workflowActivityStepCompletedId,
            supplierAssetIds: selectedAssets
        }
        dispatch(confirmAssets(finalData));
    };

    useEffect(() => {
        if(confirmedAssets){
            enqueueSnackbar("Selected suppliers saved successfully", {variant: "success"});
            handleRefreshProgress();
            handleClickNext();
        }
    }, [confirmedAssets]);

    return(
        <Row>
            {isLoadingAssets || isLoadingAuditCampaign ? <CircularProgressWrapper /> :  
             assets && !assets.length ? <div style={{height: '200px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Typography>There are no surveys to show.</Typography></div> :
            
             <>
                {assets.map((a, index) => {
                    return(
                        <Col md={4} style={{marginBottom: 30}}>
                            <AssetSelectCard 
                                activityType={activity.activityType}
                                asset={a}
                                onClick={onClickAsset}
                                isSelected={selectedAssets.includes(a.assetId)}
                                disabled={!isEditor}
                            />
                        </Col>
                    )
                })}

                { isEditor && 
                    <Col sm={12}>
                        <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: 10}}>
                            <Button 
                                variant="contained" 
                                onClick={handleClickConfirmSelection}
                            >
                                {t("app.activity.steps.saveAndContinue")}
                            </Button>  
                        </div>
                    </Col>
                }
            </>
            }

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isConfirmingAssets}
            >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Row>
    );
};

export default AUCampaignSelectAsset;