import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getAuditLogsRequest,
  getAuditLogsSuccess,
  getAuditLogsError
} from './auditLogsAction';

const defaultState = {
  auditLogs: [],
  isLoadingAuditLogs: true,
  getAuditLogsError: null,
  auditLogsTotalCount: 0,
};

export default handleActions(
  {
    [getAuditLogsRequest](state) {
        return {
        ...state,
        isLoadingAuditLogs: true,
        getAuditLogsError: null,
        };
    },
    [getAuditLogsSuccess](state, { payload }) {
        return {
        ...state,
        auditLogs: payload.data,
        auditLogsTotalCount: payload.totalCount,
        isLoadingAuditLogs: false,
        getAuditLogsError: null,
        };
    },
    [getAuditLogsError](state, { payload }) {
        return {
        ...state,
        isLoadingAuditLogs: false,
        getAuditLogsError: payload,
        };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);
