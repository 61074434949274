import { Button } from "@mui/material"; 
import { cleanup, getBusinessUnits } from "@/reducers/businessUnits/businessUnitsAction";
import { DataGrid, GridActionsCellItem, GridToolbar, gridClasses } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import { Col, Container, Row } from "react-bootstrap";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";

// import CreateServiceModal from "./components/createServiceModal";
// import EditServiceModal from "./components/editServiceModal";
// import AddIcon from '@mui/icons-material/Add';

import ConfirmationDialog from '@/shared/components/ConfirmationDialog';
 //onClick={onClickCreateCreateModal} endIcon={<AddIcon/>}
import { CardPage, CardPageBody, CardPageHeader, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap,CardPageGridWrap } from '@/shared/components/MaterialCardPage';

const BusinessUnits = () => {

    const dispatch = useDispatch();

    const { 
        businessUnits, isLoadingBusinessUnits, getbusinessUnitsError,
    } = useSelector(({ businessUnits }) => businessUnits);

    const [selectedRows, setSelectedRows] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getBusinessUnits());
   
        return () => {
          dispatch(cleanup());
        }
    }, [dispatch]);

    const columns = [
      { 
          field: 'name',
          headerName: 'Name',
          minWidth: 250,
          flex: 0.5,
      },
      { 
          field: 'description',
          headerName: 'Description', 
          minWidth: 350,
          flex: 1,
      },
      {
          field: 'actions',
          type: 'actions',
          width: 80,
          getActions: (params) => {
              if(params.row.isSystem){
                  return [];
              }

              return [
                  <GridActionsCellItem
                      icon={<EditIcon />}
                      label="Edit"
                    //  onClick={() => handleClickEditService(params.row)}
                  />,
                  <GridActionsCellItem
                      icon={<DeleteIcon />}
                      label="Delete"
                    //  onClick={() => handleClickDeleteService(params.row)}
                  />
              ];
          },
        },
    ];

    return(
      <Container>
        <CardPage>
          <CardPageBody>
              <CardPageHeader>
                <CardPageTitleWrap>
                  <CardPageTitle>Business Units</CardPageTitle>
                  <CardPageSubhead>
                    Here you can view your Business Units.
                  </CardPageSubhead>
                </CardPageTitleWrap>
                <CardPageButtonWrap>
                  <Button variant="contained" color="primary" endIcon={<AddIcon/>}>Add Business Unit</Button>
                </CardPageButtonWrap>
              </CardPageHeader>
              <CardPageGridWrap>
              <DataGrid 
                  autoHeight
                  checkboxSelection 
                  disableRowSelectionOnClick
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                      setSelectedRows(newRowSelectionModel);
                  }}
                  rowSelectionModel={selectedRows}
                  loading={isLoadingBusinessUnits} 
                  rows={businessUnits}
                  getRowId={(row) => row.businessUnitId}
                  columns={columns} 
                  slots={{ toolbar: GridToolbar }} 
                  slotProps={{
                      toolbar: {
                      showQuickFilter: true
                      },
                  }}
                  sx={{
                      [`& .${gridClasses.cell}`]: {
                      py: 1,
                      },
                  }}
                  initialState={{
                      pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  pageSizeOptions={[10, 25, 50]}
              />
              </CardPageGridWrap>
          </CardPageBody>
        </CardPage>
      </Container>
    )
}

export default BusinessUnits;