import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getTenantRequest,
  getTenantSuccess,
  getTenantError,
  getSubscriptionTypesRequest,
  getSubscriptionTypesSuccess,
  getSubscriptionTypesError,
  updateSubscriptionRequest,
  updateSubscriptionSuccess,
  updateSubscriptionError
} from './tenantSubscriptionAction';

const defaultState = {
  tenant: null,
  isLoadingTenant: true,
  getTenantError: null,
  subscriptionTypes: [],
  isLoadingSubscriptionTypes: true,
  getSubscriptionTypesError: null,
  isUpdatingSubscription: false,
  updatedSubscription: false,
  updateSubscriptionError: null
};

export default handleActions(
  {
    [getTenantRequest](state) {
      return {
        ...state,
        tenant: null,
        isLoadingTenant: true,
        getTenantError: null,
      };
    },
    [getTenantSuccess](state, { payload }) {
      return {
        ...state,
        tenant: payload,
        isLoadingTenant: false,
        getTenantError: null,
      };
    },
    [getTenantError](state, { payload }) {
      return {
        ...state,
        tenant: null,
        isLoadingTenant: false,
        getTenantError: payload,
      };
    },
    [getSubscriptionTypesRequest](state) {
        return {
        ...state,
        isLoadingSubscriptionTypes: true,
        getSubscriptionTypesError: null,
        };
    },
    [getSubscriptionTypesSuccess](state, { payload }) {
        return {
          ...state,
          subscriptionTypes: payload,
          isLoadingSubscriptionTypes: false,
          getSubscriptionTypesError: null,
        };
    },
    [getSubscriptionTypesError](state, { payload }) {
        return {
        ...state,
        isLoadingSubscriptionTypes: false,
        getSubscriptionTypesError: payload,
        };
    },
    [updateSubscriptionRequest](state) {
      return {
        ...state,
        isUpdatingSubscription: true,
        updateSubscriptionError: null,
      };
    },
    [updateSubscriptionSuccess](state, { payload }) {
      return {
        ...state,
        updatedSubscription: true,
        isUpdatingSubscription: false,
        updateSubscriptionError: null,
      };
    },
    [updateSubscriptionError](state, { payload }) {
      return {
        ...state,
        isUpdatingSubscription: false,
        updateSubscriptionError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);
