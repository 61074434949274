import { useForm } from "react-hook-form";
import { FormPasswordField } from '@/shared/components/form/material-controls/PasswordField';
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { Backdrop, Button, Card, CardContent, CardHeader, CircularProgress, Typography } from "@mui/material";
import { cleanup, changePassword } from '@/reducers/account/settings/changePassword/changePasswordAction';
import styled from 'styled-components';
import LockResetIcon from '@mui/icons-material/LockReset';
import { HasUpperCase, HasLowerCase, HasNonAlphanumeric, HasNumber } from "@/utils/formValidations";

const AccountChangePassword = () => {

    const dispatch = useDispatch();

    const { 
        isChanginPass, changedPass, changePassError,
    } = useSelector(({ changePassword }) => changePassword);

    const { handleSubmit, reset, control, setValue, getValues, watch } = useForm();

    useEffect(() => {
        return () => {
          dispatch(cleanup());
        }
      }, [dispatch]);

    useEffect(() => {
        if(changePassError){
            enqueueSnackbar(changePassError, {variant: "error"});
        }
    }, [changePassError]);

    const onSubmit = (data) => {
        dispatch(changePassword(data));
    }

    useEffect(() => {
        if(changedPass){
            enqueueSnackbar("Password changed successfully", {variant: "success"});
            reset();
            dispatch(cleanup());
        }
    }, [changedPass]);

    return (
        <Card>
            <CardContent>
                <TitleContainer>
                    <LockResetIcon />
                    <Typography variant='h5'>Change Password</Typography>
                </TitleContainer>

                <Row>
                    <Col md={12}>
                        <Card variant="outlined">
                            <CardHeader
                                subheader={
                                    <ul style={{fontSize: 14}}>
                                        <li>Password must be at least <strong>6 characters long</strong></li>
                                        <li>Password must have at least <strong>1 number</strong></li>
                                        <li>Password must have at least <strong>1 uppercase letter</strong></li>
                                        <li>Password must have at least <strong>1 lowercase letter</strong></li>
                                        <li>Password must have at least <strong>1 symbol</strong></li>
                                    </ul>
                                }
                            />
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <FormPasswordField
                            name="oldPassword"
                            label="Current Password"
                            control={control}
                            rules={{ required: 'This field is required' }} 
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <FormPasswordField
                            name="newPassword"
                            label="New Password"
                            control={control}
                            rules={{ 
                                required: 'This field is required',
                                minLength: { 
                                    value: 6,
                                    message: "Password must be at least 6 characters long"
                                },
                                validate: (val) => {
                                    if (watch('oldPassword') === val) {
                                        return "Your new password can't be equal to your current password";
                                    }
                                    if(!HasNumber(val)){
                                        return "Password must have at least 1 number";
                                    }
                                    if(!HasUpperCase(val)){
                                        return "Password must have at least 1 uppercase letter";
                                    }
                                    if(!HasLowerCase(val)){
                                        return "Password must have at least 1 lowercase letter";
                                    }
                                    if(!HasNonAlphanumeric(val)){
                                        return "Password must have at least 1 symbol";
                                    }
                                },
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <FormPasswordField
                            name="confirmPassword"
                            label="Confirm Password"
                            control={control}
                            rules={{ 
                                required: 'This field is required',
                                validate: (val) => {
                                    if (watch('newPassword') !== val) {
                                        return "Passwords don't match";
                                    }
                                },
                            }}
                        />
                    </Col>
                </Row>

                <Row style={{marginTop: 20}}>
                    <Col md={12}>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button 
                                variant="contained"
                                onClick={handleSubmit(onSubmit)}
                            >
                                Change Password
                            </Button>
                        </div>
                    </Col>
                </Row>
            </CardContent>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isChanginPass}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Card>
    );
};

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 20px;

  & > svg{
    margin-right: 5px;
    font-size: 30px;
  }
`;

export default AccountChangePassword;