import { Card, CardContent, Chip, CircularProgress, Typography, Accordion, AccordionDetails, AccordionSummary, Paper, Button, Box } from '@mui/material';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CircularProgressWrapper, FlexCenteredText } from '@/shared/components/CircularProgressWrapper';
import { useTranslation } from 'react-i18next';

const ActivityActionsPanel = ({tasks, isLoadingTasks, handleNextStatus}) => {

    const {t} = useTranslation("common");

    return(
        <Box sx={{
            backgroundColor: 'background.paper',
            marginTop: '10px',
            padding: 2
        }}>
            <Typography color='text.primary' marginBottom={1}>{t("app.activity.actions.title")}</Typography>
            <Row>
                { isLoadingTasks ? <CircularProgressWrapper height={60} /> : 
                !tasks || tasks.length === 0 ? <FlexCenteredText height={60}>{t("app.activity.actions.emptyText")}</FlexCenteredText> : 
                    tasks.map((task, index) => (
                        <Col md={3}>
                            { task.taskJob.name.includes("Reopen") ?
                                <Button color='warning' fullWidth key={index} variant='outlined' onClick={() => handleNextStatus(task.workflowTaskStepId)}>{task.taskJob.label}</Button>
                            :
                                <Button color='success' fullWidth key={index} variant='contained' onClick={() => handleNextStatus(task.workflowTaskStepId)}>{task.taskJob.label}</Button>
                            }
                        </Col>
                    ))
                }
            </Row>
        </Box>
    );
};

export default ActivityActionsPanel;