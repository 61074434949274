import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getCurrentMenuRequest,
  getCurrentMenuSuccess,
  getCurrentMenuError
} from './menuAction';

const defaultState = {
  menuItems: null,
  isLoadingMenu: true,
  getCurrentMenuError: null,
};

export default handleActions(
  {
    [getCurrentMenuRequest](state) {
      return {
        ...state,
        isLoadingMenu: true,
        getCurrentMenuError: null,
      };
    },
    [getCurrentMenuSuccess](state, { payload }) {
      return {
        ...state,
        menuItems: payload,
        isLoadingMenu: false,
        getCurrentMenuError: null,
      };
    },
    [getCurrentMenuError](state, { payload }) {
      return {
        ...state,
        isLoadingMenu: false,
        getCurrentMenuError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);
