import ReactECharts from 'echarts-for-react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { instance } from "../../../config/interceptor";
import { enqueueSnackbar } from 'notistack';
import { Card, CardContent } from '@mui/material';

const LineChart = ({ endpoint, chartSeries, height = 300, ...other }) => {
    const {theme} = useSelector(({changeTheme}) => changeTheme);

    const baseOptions = {
        toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: { },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
            type: 'value'
        },
        series: {
            type: 'line',
            data: []
        },
        ...other
    }

    const [isLoading, setIsLoading] = useState(!chartSeries);
    const [data, setData] = useState(chartSeries);
    const [apiError, setApiError] = useState(null);
    const [options, setOptions] = useState(baseOptions);

    useEffect(() => {
        if(endpoint){
            async function fetchData(){
                try {
                    setIsLoading(true);
                    const resp = await instance.get("/v1/Dashboard/" + endpoint);
                    setIsLoading(false);
                    setData(resp.data);
                }
                catch(e) {
                    if(e.response && e.response.data && e.response.data.description)
                        setApiError(e.response.data.description);
                    else
                        setApiError(e.message);
                }
            }
            
            fetchData();
        }
    }, [endpoint]);

    useEffect(() => {
        if(data && data.series && data.series.length){

            setOptions({
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                    type: 'shadow'
                    }
                },
                legend: { },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: data.axis
                },
                yAxis: {
                    type: 'value'
                },
                series: {
                    type: 'line',
                    data: data.series[0].values
                },
                ...other
            });
        }
    }, [data, other]);

    useEffect(() => {
        if(apiError){
            enqueueSnackbar(apiError, {variant: "error"});
        }
    }, [apiError]);

    return(
        <Card>
            <CardContent>
                <ReactECharts
                    theme={theme}
                    style={{height: height}}
                    notMerge={true}
                    option={options}
                    showLoading={isLoading}
                    loadingOption={{
                        text: "",
                        color: '#3D5AFE',
                        maskColor: 'rgba(0, 0, 0, 0.5)',
                    }}
                />
            </CardContent>
        </Card>
    );
}

export default LineChart;