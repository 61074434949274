import { Backdrop, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { FormSelectField } from '@/shared/components/form/material-controls/SelectField';
import { FormCheckboxField } from '@/shared/components/form/material-controls/CheckboxField';
import { cleanup, addService } from "@/reducers/services/addService/addServiceAction";
import { useState } from "react";
import ServiceConnectorsTable from "./serviceConnectorsTable";
import { useRef } from "react";
import { FormSliderField } from "@/shared/components/form/material-controls/SliderField";

const CreateServiceModal = ({ connectors, cronIntervals, open, onHideModal, onServiceCreated }) => {
    const dispatch = useDispatch();
  
    const { 
      isAddingService, addServiceError, addedService
    } = useSelector(({ addService }) => addService);

    const tableRef = useRef();

    const [disableIntervals, setDisableIntervals] = useState(false);

    useEffect(() => {
      return () => {
        dispatch(cleanup());
      }
    }, [dispatch]);
  
    const { handleSubmit, reset, control, setValue, getValues } = useForm();

    const handleClose = (event, reason) => {
      if (reason && reason === "backdropClick") 
          return;
          reset();
          onHideModal();
    }
  
    useEffect(() => {
      if(addServiceError){
        enqueueSnackbar(addServiceError, {variant: "error"});
      }
    }, [addServiceError]);
  
    const onSubmit = (data) => { 
      data.serviceConnector = tableRef.current.getData();

      var error = false;
      data.serviceConnector.forEach((connector, index) => {
        if(!error){
          if(data.serviceConnector.some((c, i) => c.assemblyCollectionId === connector.assemblyCollectionId && i !== index)){
            enqueueSnackbar("Can't have more than one connector with the same function.", {variant: "error"});
            error = true;
          }
          else if(data.serviceConnector.some((c, i) => c.order === connector.order && i !== index)){
            enqueueSnackbar("Can't have more than one connector with the same order.", {variant: "error"});
            error = true;
          }
        }
      });

      if(!error){
        dispatch(addService(data));
      }
    }
  
    useEffect(() => {
      if(addedService){
        enqueueSnackbar("Service created successfully", {variant: "success"});
        handleClose();
        onServiceCreated();
      }
    }, [addedService]);

    const onChangeIsInterval = (e, value) => {
      setValue("isInterval", value);
      setDisableIntervals(!value);
    }
  
    return (
      <Dialog
          fullWidth
          maxWidth='lg'
          scroll='paper'
          open={open}
          onClose={handleClose}
      >
          <DialogTitle>Create Service</DialogTitle>
          <DialogContent>
                <Row>
                  <Col md={12}>
                    <FormTextField
                        name='name'
                        label='Name'
                        control={control}
                        rules={{ required: 'This field is required' }}
                    />
                  </Col>
                  <Col md={12}>
                    <FormTextField 
                        style={{marginBlock: 20}}
                        name='cron'
                        label='Cron'
                        control={control}
                    />
                  </Col>
                  <Col md={12}>
                    <FormCheckboxField 
                        name='scheduled'
                        label='Scheduled'
                        control={control}
                        defaultValue={true}
                    />
                  </Col>
                  <Col md={12}>
                    <FormCheckboxField 
                        name='enabled'
                        label='Enabled'
                        control={control}
                        defaultValue={true}
                    />
                  </Col>
                  <Col md={12}>
                    <FormCheckboxField 
                        name='isInterval'
                        label='Is Interval'
                        control={control}
                        onChange={onChangeIsInterval}
                        defaultValue={true}
                    />
                  </Col>
                  <Col md={6}>
                    <FormSliderField 
                        name='interval'
                        label='Interval'
                        control={control}
                        min={1}
                        max={360}
                        disabled={disableIntervals}
                    />
                  </Col>
                  <Col md={6}>
                    <FormSelectField 
                        name='cronIntervalId'
                        label='Cron Interval'
                        control={control}
                        options={cronIntervals}
                        keyValue={{ id:"key", label:"name" }}
                        disabled={disableIntervals}
                    />
                  </Col>
                  <Col md={12}>
                    <ServiceConnectorsTable 
                      ref={tableRef}
                      assemblyCollections={[]}
                      connectors={connectors} 
                      isEdit={false}
                      cronIntervals={cronIntervals}
                    />
                  </Col>
                </Row>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant='contained' onClick={handleSubmit(onSubmit)}>Create</Button>
          </DialogActions>
  
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isAddingService}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
      </Dialog>
    );
  }
  
  export default CreateServiceModal;