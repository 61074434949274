import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getSurveysRequest,
  getSurveysSuccess,
  getSurveysError
} from './externalAuditDasboardAction';

const defaultState = {
  surveys: null,
  isLoadingSurveys: true,
  getSurveysError: null
};

export default handleActions(
  {
    [getSurveysRequest](state) {
      return {
        ...state,
        surveys: null,
        isLoadingSurveys: true,
        getSurveysError: null,
      };
    },
    [getSurveysSuccess](state, { payload }) {
      return {
        ...state,
        surveys: payload,
        isLoadingSurveys: false,
        getSurveysError: null,
      };
    },
    [getSurveysError](state, { payload }) {
      return {
        ...state,
        surveys: null,
        isLoadingSurveys: false,
        getSurveysError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);