import { Backdrop, Button, CircularProgress, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridToolbar, gridClasses } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { cleanup, executeService, getServices, getConnectors, getCronIntervals, deleteService } from "@/reducers/services/servicesAction";
import { Col, Container, Row } from "react-bootstrap";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { enqueueSnackbar } from "notistack";
import CreateServiceModal from "./components/createServiceModal";
import AddIcon from '@mui/icons-material/Add';
import EditServiceModal from "./components/editServiceModal";
import ConfirmationDialog from '@/shared/components/ConfirmationDialog';
import ReplayIcon from '@mui/icons-material/Replay';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ServiceLogsModal from "./components/serviceLogsModal";
import { CardPage, CardPageFilterWrapper, CardPageHeader, CardPageBody, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap,CardPageGridWrap } from '@/shared/components/MaterialCardPage';

const Services = () => {

    const dispatch = useDispatch();
    const { 
        services, isLoadingServices, getServicesError,
        connectors, isLoadingConnectors, getConnectorsError,
        cronIntervals, isLoadingCronIntervals, getCronIntervalsError,
        isDeletingService, deletedService, deleteServiceError,
        isExecutingService, executedService, executeServiceError
    } = useSelector(({ services }) => services);

    const [showServiceCreateModal, setShowServiceCreateModal] = useState(false);

    const [showServiceEditModal, setShowServiceEditModal] = useState(false);
    const [selectedService, setSelectedService] = useState(null);

    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [serviceToDelete, setServiceToDelete] = useState(null);

    const [openConfirmExecute, setOpenConfirmExecute] = useState(false);
    const [serviceToExecute, setServiceToExecute] = useState(null);

    const [showServiceLogsModal, setShowServiceLogsModal] = useState(false);

    useEffect(() => {
        dispatch(getServices());
        dispatch(getConnectors());
        dispatch(getCronIntervals());
    
        return () => {
          dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
        if(getConnectorsError){
            enqueueSnackbar(getConnectorsError, {variant: "error"});
        }
    }, [getConnectorsError]);

    useEffect(() => {
        if(getCronIntervalsError){
            enqueueSnackbar(getCronIntervalsError, {variant: "error"});
        }
    }, [getCronIntervalsError]);

    useEffect(() => {
        if(getServicesError){
            enqueueSnackbar(getServicesError, {variant: "error"});
        }
    }, [getServicesError]);

    useEffect(() => {
        if(deleteServiceError){
            enqueueSnackbar(deleteServiceError, {variant: "error"});
        }
    }, [deleteServiceError]);

    useEffect(() => {
        if(executeServiceError){
            enqueueSnackbar(executeServiceError, {variant: "error"});
        }
    }, [executeServiceError]);

    const handleClickEditService = (service) => {
        setSelectedService(service);
        setShowServiceEditModal(true);
    }

    const handleClickDeleteService = (service) => {
        setServiceToDelete(service);
        setOpenConfirmDelete(true);
    }

    const handleClickExecuteService = (service) => {
        setServiceToExecute(service);
        setOpenConfirmExecute(true);
    };

    const handleConfirmExecute = () => {
        setOpenConfirmExecute(false);
        dispatch(executeService(serviceToExecute.id));
        setServiceToExecute(null);
    };

    const onClickCreateCreateModal = () => {
        setShowServiceCreateModal(true);
    };

    const onHideServiceCreateModal = () => {
        setShowServiceCreateModal(false);
    };

    const onHideServiceEditModal = () => {
        setShowServiceEditModal(false);
        setSelectedService(null);
    };

    const onHideServiceLogsModal = () => {
        setShowServiceLogsModal(false);
        setSelectedService(null);
    };

    const onServiceCreated = () => {
        setShowServiceCreateModal(false);
        dispatch(getServices());
    }
    
    const onServiceUpdated = () => {
        setShowServiceEditModal(false);
        setSelectedService(null);
        dispatch(getServices());
    }

    const handleConfirmDelete = () => {
        setOpenConfirmDelete(false);
        dispatch(deleteService(serviceToDelete.id));
        setServiceToDelete(null);
    }

    const handleOnCloseDelete = () => {
        setOpenConfirmDelete(false);
        setServiceToDelete(null);
    };

    const handleClickViewLogs = (service) => {
        setSelectedService(service);
        setShowServiceLogsModal(true);
    };


    useEffect(() => {
        if(deletedService){
            enqueueSnackbar("Service deleted successfully", {variant: "success"});
            dispatch(getServices());
        }
    }, [deletedService]);

    useEffect(() => {
        if(executedService){
            enqueueSnackbar("Service started execution successfully", {variant: "success"});
        }
    }, [executedService]);

    const columns = [
        { 
            field: 'name', 
            headerName: 'Name',
            minWidth: 350,
            flex: 1,
        },
        { 
            field: 'scheduled', 
            headerName: 'Scheduled', 
            type: 'boolean',
            minWidth: 150
        },
        { 
            field: 'cron', 
            headerName: 'Cron', 
            minWidth: 150
        },
        { 
            field: 'enabled', 
            headerName: 'Enabled', 
            type: 'boolean',
            minWidth: 150
        },
        { 
            field: 'isSystem', 
            headerName: 'System', 
            type: 'boolean',
            minWidth: 150
        },
        {
            field: 'actions',
            type: 'actions',
            width: 120,
            getActions: (params) => {
                if(params.row.isSystem){
                    return [
                        <GridActionsCellItem
                            icon={<ReplayIcon />}
                            label="Trigger Job"
                            showInMenu
                            onClick={() => handleClickExecuteService(params.row)}
                        />,
                        <GridActionsCellItem
                            icon={<EditIcon />}
                            label="Edit"
                            showInMenu
                            onClick={() => handleClickEditService(params.row)}
                        />,
                        <GridActionsCellItem
                            icon={<TextSnippetIcon />}
                            label="View Logs"
                            showInMenu
                            onClick={() => handleClickViewLogs(params.row)}
                        />
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<ReplayIcon />}
                        label="Trigger Job"
                        showInMenu
                        onClick={() => handleClickExecuteService(params.row)}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        showInMenu
                        onClick={() => handleClickEditService(params.row)}
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        showInMenu
                        onClick={() => handleClickDeleteService(params.row)}
                    />,
                    <GridActionsCellItem
                        icon={<TextSnippetIcon />}
                        label="View Logs"
                        showInMenu
                        onClick={() => handleClickViewLogs(params.row)}
                    />
                ];
            },
          },
      ];

    return(
        <Container>
            <CardPage>
                <CardPageBody>   
                    <CardPageHeader>
                        <CardPageTitleWrap>
                            <CardPageTitle>Services</CardPageTitle>
                            <CardPageSubhead>
                                View and manage your services.
                            </CardPageSubhead>
                        </CardPageTitleWrap>
                        <CardPageButtonWrap>
                            <Button 
                                variant="contained" 
                                onClick={onClickCreateCreateModal} 
                                endIcon={<AddIcon/>}
                            >
                                Create Service
                            </Button>
                        </CardPageButtonWrap>
                    </CardPageHeader>

                    <CardPageGridWrap>
                        <DataGrid 
                            autoHeight
                            density="compact"
                            disableRowSelectionOnClick
                            loading={isLoadingServices} 
                            rows={services}
                            columns={columns} 
                            slots={{ toolbar: GridToolbar }} 
                            slotProps={{
                                toolbar: {
                                showQuickFilter: true
                                },
                            }}
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                py: 1,
                                },
                            }}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 30 } },
                            }}
                            pageSizeOptions={[15, 30, 50, 100]}
                        />
                    </CardPageGridWrap>

                    { showServiceCreateModal &&
                        <CreateServiceModal
                            onHideModal={onHideServiceCreateModal}
                            open={showServiceCreateModal}
                            onServiceCreated={onServiceCreated}
                            connectors={connectors}
                            cronIntervals={cronIntervals}
                        />
                    }

                    { showServiceEditModal &&
                        <EditServiceModal
                            onHideModal={onHideServiceEditModal}
                            open={showServiceEditModal}
                            onServiceUpdated={onServiceUpdated}
                            connectors={connectors}
                            service={selectedService}
                            cronIntervals={cronIntervals}
                        />
                    }

                    { showServiceLogsModal &&
                        <ServiceLogsModal
                            onHideModal={onHideServiceLogsModal}
                            open={showServiceLogsModal}
                            service={selectedService}
                        />
                    }

                    { openConfirmDelete &&
                        <ConfirmationDialog 
                            open={openConfirmDelete}
                            onConfirm={handleConfirmDelete}
                            onClose={handleOnCloseDelete}
                            title="Delete Service"
                            body={`Are you sure you want to delete ${serviceToDelete.name}?`}
                            color='error'
                            confirmButtonText='Delete'
                        />
                    }

                    { openConfirmExecute &&
                        <ConfirmationDialog 
                            open={openConfirmExecute}
                            onConfirm={handleConfirmExecute}
                            onClose={handleConfirmExecute}
                            title="Execute Service"
                            body={`Are you sure you want to execute ${serviceToExecute.name} now?`}
                            color='primary'
                            confirmButtonText='Execute'
                        />
                    }

                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isDeletingService}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </CardPageBody>
            </CardPage>
        </Container>
    )
}

export default Services;