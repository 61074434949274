import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getSurveyRequest,
  getSurveySuccess,
  getSurveyError,
  answerSurveyRequest,
  answerSurveySuccess,
  answerSurveyError,
  saveDraftRequest,
  saveDraftSuccess,
  saveDraftError
} from './answerSurveyAction';

const defaultState = {
  surveyJson: null,
  isLoadingSurveyJson: true,
  getSurveyJsonError: null,
  isSendingSurvey: false,
  sendSurveyResult: null,
  sendSurveyError: null,
  isSavingDraft: false,
  saveDraftResult: null,
  saveDraftError: null,
};

export default handleActions(
  {
    [getSurveyRequest](state) {
      return {
        ...state,
        surveyJson: null,
        isLoadingSurveyJson: true,
        getSurveyJsonError: null,
      };
    },
    [getSurveySuccess](state, { payload }) {
      return {
        ...state,
        surveyJson: payload,
        isLoadingSurveyJson: false,
        getSurveyJsonError: null,
      };
    },
    [getSurveyError](state, { payload }) {
      return {
        ...state,
        surveyJson: null,
        isLoadingSurveyJson: false,
        getSurveyJsonError: payload,
      };
    },
    [answerSurveyRequest](state) {
      return {
        ...state,
        isSendingSurvey: true,
        sendSurveyResult: null,
        sendSurveyError: null
      };
    },
    [answerSurveySuccess](state, { payload }) {
      return {
        ...state,
        isSendingSurvey: false,
        sendSurveyResult: payload,
        sendSurveyError: null
      };
    },
    [answerSurveyError](state, { payload }) {
      return {
        ...state,
        isSendingSurvey: false,
        sendSurveyResult: null,
        sendSurveyError: payload
      };
    },
    [saveDraftRequest](state) {
      return {
        ...state,
        isSavingDraft: true,
        saveDraftResult: null,
        saveDraftError: null
      };
    },
    [saveDraftSuccess](state, { payload }) {
      return {
        ...state,
        isSavingDraft: false,
        saveDraftResult: payload,
        saveDraftError: null
      };
    },
    [saveDraftError](state, { payload }) {
      return {
        ...state,
        isSavingDraft: false,
        saveDraftResult: null,
        saveDraftError: payload
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);