import { FormSelectField } from '@/shared/components/form/material-controls/SelectField';
import { useMemo } from 'react';

const UnitSelect = ({
    unitCode,
    name,
    defaultValue,
    parameterUnits,
    ...other
}) => {
    const options = useMemo(() => {
        return parameterUnits.filter(p => p.code === unitCode);
    }, [parameterUnits, unitCode]);

    return (
        <FormSelectField
            name={name}
            disableClearable={true}
            keyValue={{id:"parameterId", label:"value"}}
            defaultValue={defaultValue ?? options[0].parameterId}
            options={options}
            {...other}
        />
    );
}

export default UnitSelect;