import * as type from "./types";

const initialState = {
  dataSurveys: null,
};

const SurveysReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.GET_SURVEYS:
      return { ...state, dataSurveys: action.dataSurveys };
    case type.SEND_SURVEYS:
      break;
    default:
      return state;
  }
};

export default SurveysReducer;
