import { Backdrop, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { FormSelectField } from '@/shared/components/form/material-controls/SelectField';
import { FormMultiSelectField } from '@/shared/components/form/material-controls/MultiSelectField';
import { FormNumberField } from "@/shared/components/form/material-controls/NumberField";
import { FormColorField } from "@/shared/components/form/material-controls/ColorField";
import { cleanup, createAssetTypes } from "@/reducers/assetType/create/assetTypeCreateAction";
import TabPanel, { tabIndexProps } from "@/shared/components/MaterialTabPanel";
import { languages } from "@/utils/supportedLanguages";
import { useState } from "react";

const CreateAssetTypeModal = ({ open, onHideModal, onAssetTypeCreated, assetTypes }) => {
    const dispatch = useDispatch();
    const { 
      isCreatingAssetType, createdAssetType, createAssetTypeError
    } = useSelector(({ assetTypeCreate }) => assetTypeCreate);
    
    const { handleSubmit, reset, control, setValue, getValues } = useForm();
    
    useEffect(() => {
      return () => {
        dispatch(cleanup());
      }
    }, [dispatch]);

    const [tab, setTab] = useState(0);

    const handleTabChange = (event, newValue) => {
      setTab(newValue);
    };

    useEffect(() => {
      if(createAssetTypeError){
          enqueueSnackbar(createAssetTypeError, {variant: "error"});
      }
    }, [createAssetTypeError]);  
  
    const handleClose = (event, reason) => {
      if (reason && reason === "backdropClick") 
          return;
        
      reset();
      onHideModal();
    }
  
    const onSubmit = (data) => {
      var error = false;
      Object.keys(languages).forEach(l => {
        if(!data.name[l]){
          enqueueSnackbar("You have to provide translations for required fields", {variant: "error"});
          error = true;
        }
      });
      
      if(!error)
        dispatch(createAssetTypes(data));
    }
  
    useEffect(() => {
      if(createdAssetType){
        enqueueSnackbar("Asset Type created successfully", {variant: "success"});
        handleClose();
        onAssetTypeCreated();
      }
    }, [createdAssetType]);
  
    return (
      <Dialog
          fullWidth={true}
          maxWidth='lg'
          scroll='paper'
          open={open}
          onClose={handleClose}
      >
          <DialogTitle>Create Asset Type</DialogTitle>
          <DialogContent>
            <Row>
            <Tabs
                value={tab}
                variant="fullWidth"
                onChange={handleTabChange}
                aria-label="icon position tabs example"
              >
                {Object.keys(languages).map((l, index) => 
                  <Tab 
                    style={{fontSize: 20}}
                    label={
                      <div style={{
                        display: "flex", 
                        alignItems: "center"
                      }}>
                        <img 
                          style={{marginRight: 10}} 
                          src={languages[l].image} 
                          alt={l}
                        />
                        {languages[l].displayName}
                      </div>
                    }
                    {...tabIndexProps(index)}
                  />
                )}
              </Tabs>

              {Object.keys(languages).map((l, index) => 
                <TabPanel value={tab} index={index}>
                  <Col md={12}>
                    <FormTextField
                      style={{marginTop: 0}}
                      name={`name.${l}`}
                      label={`Name ${l.toUpperCase()}`}
                      control={control}
                      rules={{ required: 'This field is required' }}
                    />
                  </Col>
                  <Col md={12}>
                    <FormTextField
                      name={`description.${l}`}
                      label={`Description ${l.toUpperCase()}`}
                      control={control}
                      multiline
                      rows={4}
                    />
                  </Col>
                </TabPanel>
              )}     
              
              <Col md={12}>
                <FormSelectField 
                    name='parent'
                    label='Parent'
                    control={control}
                    options={assetTypes}
                    keyValue={{id: "id", label: "nameTraslated"}}
                />
              </Col>
              <Col md={12}>
                <FormMultiSelectField 
                    name="children" 
                    label="Children" 
                    control={control} 
                    options={assetTypes}
                    keyValue={{id: "id", label: "nameTraslated"}}
                />
              </Col>
              <Col md={6}>
                <FormNumberField 
                    name="order" 
                    label="Order" 
                    control={control} 
                    rules={{ required: 'This field is required' }}
                />
              </Col>
              <Col md={6}>
                <FormTextField
                    name='icon'
                    label='Icon'
                    control={control}
                />
              </Col>
              <Col md={12}>
                <FormColorField 
                    name="color" 
                    label="Color" 
                    control={control} 
                    rules={{ required: 'This field is required' }}
                />
              </Col>
            </Row>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant='contained' onClick={handleSubmit(onSubmit)}>Create</Button>
          </DialogActions>
  
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isCreatingAssetType}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
      </Dialog>
    );
  }
  
  export default CreateAssetTypeModal;