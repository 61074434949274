import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_ADD_VULNERABILITY_STATUS');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const addVulnerabilityStatusRequest = createAction('ADD_VULNERABILITY_STATUS_POST_REQUEST');
export const addVulnerabilityStatusSuccess = createAction('ADD_VULNERABILITY_STATUS_POST_SUCCESS');
export const addVulnerabilityStatusError = createAction('ADD_VULNERABILITY_STATUS_POST_ERROR');

export const addVulnerabilityStatus = (request) => async (dispatch) => {
  try {
    dispatch(addVulnerabilityStatusRequest());
    const resp = await instance.post(`/v1/VulnerabilityStatus/Post`, request);

    dispatch(addVulnerabilityStatusSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(addVulnerabilityStatusError(e.response.data.description));
    else
      dispatch(addVulnerabilityStatusError(e.message));
  }
};
