import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_EDIT_ACTIVITY_INFO');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const updateActivityRequest = createAction('EDIT_ACTIVITY_INFO_UPDATE_REQUEST');
export const updateActivitySuccess = createAction('EDIT_ACTIVITY_INFO_UPDATE_SUCCESS');
export const updateActivityError = createAction('EDIT_ACTIVITY_INFO_UPDATE_ERROR');

export const updateActivity = (request) => async (dispatch) => {
  try {
    dispatch(updateActivityRequest());
    const resp = await instance.put(`/v1/Workflow/UpdateActivity`, request);

    dispatch(updateActivitySuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(updateActivityError(e.response.data.description));
    else
      dispatch(updateActivityError(e.message));
  }
};

export const getRolesWithUsersRequest = createAction('EDIT_ACTIVITY_INFO_GET_USERS_WITH_ROLES_REQUEST');
export const getRolesWithUsersSuccess = createAction('EDIT_ACTIVITY_INFO_GET_USERS_WITH_ROLES_SUCCESS');
export const getRolesWithUsersError = createAction('EDIT_ACTIVITY_INFO_GET_USERS_WITH_ROLES_ERROR');

export const getRolesWithUsers = (roles) => async (dispatch) => {
  try {
    dispatch(getRolesWithUsersRequest());
    
    const resp = await instance.post(`/v1/UserAndRole/GetUsersByRoleIds`, { 
      roleIds: roles 
    });
    
    dispatch(getRolesWithUsersSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getRolesWithUsersError(e.response.data.description));
    else
      dispatch(getRolesWithUsersError(e.message));
  }
};