import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_UPDATE_SERVICE');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const updateServiceRequest = createAction('UPDATE_SERVICE_POST_REQUEST');
export const updateServiceSuccess = createAction('UPDATE_SERVICE_POST_SUCCESS');
export const updateServiceError = createAction('UPDATE_SERVICE_POST_ERROR');

export const updateService = (data) => async (dispatch) => {
  try {
    dispatch(updateServiceRequest());
    var resp = null;
    if(!data.isSystem)
      resp = await instance.put(`/v1/Service/Put`, data);
    else
      resp = await instance.put(`/v1/Service/PutSystemService`, data);

    dispatch(updateServiceSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(updateServiceError(e.response.data.description));
    else
      dispatch(updateServiceError(e.message));
  }
};
