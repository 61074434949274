import { Backdrop, Button, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { cleanup, getTenant, updateTenant } from "@/reducers/system/tenants/updateTenant/systemUpdateTenantAction";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { CardPage, CardPageFilterWrapper, CardPageBody, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap,CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import { FormTextField } from "@/shared/components/form/material-controls/TextField";
import { useForm } from "react-hook-form";
import { IsValidEmail } from "@/utils/formValidations";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';

const SystemUpdateTenant = () => {
    const { id } = useParams();

    const dispatch = useDispatch();
    const { 
        tenant, isLoadingTenant, getTenantError,
        isUpdatingTenant, updatedTenant, updateTenantError
    } = useSelector(({ systemUpdateTenant }) => systemUpdateTenant);

    const navigate = useNavigate();
    const { handleSubmit, reset, control, setValue } = useForm();

    useEffect(() => {
        dispatch(getTenant(id));

        return () => {
          dispatch(cleanup());
        }
    }, [dispatch]);
    
    useEffect(() => {
        if(getTenantError){
            enqueueSnackbar(getTenantError, {variant: "error"});
        }
    }, [getTenantError]);

    useEffect(() => {
        if(updateTenantError){
            enqueueSnackbar(updateTenantError, {variant: "error"});
        }
    }, [updateTenantError]);

    useEffect(() => {
        if(updatedTenant){
          enqueueSnackbar(`Tenant updated successfuly.`, {variant: "success"});
          navigate("/SystemSettings/Tenants");
        }
    }, [updatedTenant]);

    const onSubmit = (data) => {
        data.tenantId = id;
        dispatch(updateTenant(data));
    }

    const onClickCancel = () => {
        navigate("/SystemSettings/Tenants");
    }

    return(
        <Container>
            <CardPage>
                <CardPageBody>         
                    <CardPageTitleWrap>
                        <CardPageTitle>Update Tenant</CardPageTitle>
                    </CardPageTitleWrap>
                    {isLoadingTenant || !tenant ? <CircularProgressWrapper /> :
                    <>
                        <Typography variant="subtitle1" style={{ marginTop: 20 }}>Contact Information</Typography>
                        <Row>
                            <Col md={12}>
                                <FormTextField
                                    name="name" 
                                    label="Name" 
                                    control={control}
                                    rules={{ required: 'This field is required' }}
                                    defaultValue={tenant.name}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <FormTextField
                                    name="emailAddress" 
                                    label="Email" 
                                    control={control}
                                    rules={{ 
                                        required: 'This field is required',
                                        validate: {
                                            matchPattern: (email) => IsValidEmail(email) ||
                                            "Email address must be a valid address"
                                        }
                                    }}
                                    defaultValue={tenant.emailAddress}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <FormTextField
                                    name="pecEmailAddress" 
                                    label="Pec Email" 
                                    control={control}
                                    rules={{ 
                                        required: 'This field is required',
                                        validate: {
                                            matchPattern: (email) => IsValidEmail(email) ||
                                            "Email address must be a valid address"
                                        }
                                    }}
                                    defaultValue={tenant.pecEmailAddress}
                                />
                            </Col>
                        </Row>
                        <Divider component='div' style={{ marginTop: 20 }} />
                        <Typography variant="subtitle1" style={{ marginTop: 20 }}>Billing Information</Typography>
                        <Row>
                            <Col md={12}>
                                <FormTextField 
                                    name="billingName" 
                                    label="Billing Name" 
                                    control={control} 
                                    rules={{ required: 'This field is required' }}
                                    defaultValue={tenant.billingName}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <FormTextField 
                                    name="billingAddress" 
                                    label="Billing Address" 
                                    control={control} 
                                    defaultValue={tenant.billingAddress}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormTextField 
                                    name="billingCountry" 
                                    label="Billing Country" 
                                    control={control} 
                                    defaultValue={tenant.billingCountry}
                                />
                            </Col>
                            <Col md={6}>
                                <FormTextField 
                                    name="billingCity" 
                                    label="Billing City" 
                                    control={control} 
                                    defaultValue={tenant.billingCity}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormTextField 
                                    name="vat" 
                                    label="VAT" 
                                    control={control} 
                                    defaultValue={tenant.vat}
                                />
                            </Col>
                            <Col md={6}>
                                <FormTextField 
                                    name="vatCode" 
                                    label="SDI Code" 
                                    control={control} 
                                    defaultValue={tenant.vatCode}
                                />
                            </Col>
                        </Row>

                        <Row style={{marginTop: 20}}>
                            <Col md={12}>
                                <Grid
                                    container
                                    justifyContent="flex-end"
                                    spacing={2}
                                >
                                    <Grid item>
                                        <Button
                                            onClick={onClickCancel}
                                            variant="outlined"
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            onClick={handleSubmit(onSubmit)}
                                            variant="contained"
                                        >
                                            Update
                                        </Button>
                                    </Grid>
                                    
                                </Grid>
                            </Col>
                        </Row>
                    </>
                    }
                </CardPageBody>
            </CardPage>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isUpdatingTenant}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}

export default SystemUpdateTenant;