import { createAction } from 'redux-actions';
import { instance } from "../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_CURRENT_TENANT');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getCurrentTenantRequest = createAction('GET_CURRENT_TENANT_REQUEST');
export const getCurrentTenantSuccess = createAction('GET_CURRENT_TENANT_SUCCESS');
export const getCurrentTenantError = createAction('GET_CURRENT_TENANT_ERROR');

export const getCurrentTenant = () => async (dispatch) => {
  try {
    dispatch(getCurrentTenantRequest());
    const resp = await instance.get(`/v1/Tenant/GetCurrentTenant`);
    
    dispatch(getCurrentTenantSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getCurrentTenantError(e.response.data.description));
    else
      dispatch(getCurrentTenantError(e.message));
  }
};

export const updateTenantRequest = createAction('CURRENT_TENANT_UPDATE_REQUEST');
export const updateTenantSuccess = createAction('CURRENT_TENANT_UPDATE_SUCCESS');
export const updateTenantError = createAction('CURRENT_TENANT_UPDATE_ERROR');

export const updateTenant = (data) => async (dispatch) => {
    try {
      dispatch(updateTenantRequest());
      const resp = await instance.put(`/v1/Tenant/UpdateCurrentTenant`, data);
      
      dispatch(updateTenantSuccess(resp.data));
  
    } catch (e) {
      if(e.response && e.response.data && e.response.data.description)
        dispatch(updateTenantError(e.response.data.description));
      else
        dispatch(updateTenantError(e.message));
    }
  };

export const getLogoRequest = createAction('CURRENT_TENANT_GET_LOGO_REQUEST');
export const getLogoSuccess = createAction('CURRENT_TENANT_GET_LOGO_SUCCESS');
export const getLogoError = createAction('CURRENT_TENANT_GET_LOGO_ERROR');

export const getLogo = () => async (dispatch) => {
  try {
    dispatch(getLogoRequest());
    const resp = await instance.get(`/v1/Tenant/GetCurrentTenantLogo`, { 
        responseType: 'blob'
    });

    dispatch(getLogoSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getLogoError(e.response.data.description));
    else
      dispatch(getLogoError(e.message));
  }
};

export const deleteLogoRequest = createAction('CURRENT_TENANT_DELETE_LOGO_REQUEST');
export const deleteLogoSuccess = createAction('CURRENT_TENANT_DELETE_LOGO_SUCCESS');
export const deleteLogoError = createAction('CURRENT_TENANT_DELETE_LOGO_ERROR');

export const deleteLogo = () => async (dispatch) => {
  try {
    dispatch(deleteLogoRequest());
    const resp = await instance.post(`/v1/Tenant/DeleteLogo`);

    dispatch(deleteLogoSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(deleteLogoError(e.response.data.description));
    else
      dispatch(deleteLogoError(e.message));
  }
};