import { createBrowserHistory } from "history";

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const history = createBrowserHistory();
    return <Component history={history} {...props} />;
  };

  return Wrapper;
};
