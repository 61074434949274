import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getVulnerabilityStatusesRequest,
  getVulnerabilityStatusesSuccess,
  getVulnerabilityStatusesError,
  deleteVulnerabilityStatusRequest,
  deleteVulnerabilityStatusSuccess,
  deleteVulnerabilityStatusError
} from './vulnerabilityStatusesAction';

const defaultState = {
  vulnerabilityStatuses: [],
  isLoadingVulnerabilityStatuses: true,
  getVulnerabilityStatusesError: null,
  isDeletingVulnerabilityStatus: false,
  deleteVulnerabilityStatusError: null,
  deletedVulnerabilityStatus: false
};

export default handleActions(
  {
    [getVulnerabilityStatusesRequest](state) {
      return {
        ...state,
        vulnerabilityStatuses: [],
        isLoadingVulnerabilityStatuses: true,
        getVulnerabilityStatusesError: null,
      };
    },
    [getVulnerabilityStatusesSuccess](state, { payload }) {
      return {
        ...state,
        vulnerabilityStatuses: payload,
        isLoadingVulnerabilityStatuses: false,
        getVulnerabilityStatusesError: null,
      };
    },
    [getVulnerabilityStatusesError](state, { payload }) {
      return {
        ...state,
        vulnerabilityStatuses: [],
        isLoadingVulnerabilityStatuses: false,
        getVulnerabilityStatusesError: payload,
      };
    },
    [deleteVulnerabilityStatusRequest](state) {
      return {
        ...state,
        isDeletingVulnerabilityStatus: true,
        deletedVulnerabilityStatus: false,
        deleteVulnerabilityStatusError: null,
      };
    },
    [deleteVulnerabilityStatusSuccess](state, { payload }) {
      return {
        ...state,
        isDeletingVulnerabilityStatus: false,
        deletedVulnerabilityStatus: true,
        deleteVulnerabilityStatusError: null,
      };
    },
    [deleteVulnerabilityStatusError](state, { payload }) {
      return {
        ...state,
        isDeletingVulnerabilityStatus: false,
        deletedVulnerabilityStatus: false,
        deleteVulnerabilityStatusError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);