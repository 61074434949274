import { Box, Link } from "@mui/material";
import { useState } from "react";

const ExpandableCell = ({ value, maxLength, sx }) => {
    const [expanded, setExpanded] = useState(false);
  
    const onClickExpand = (event) => {
      event.stopPropagation();
      setExpanded(!expanded);
    };

    return (
      <Box sx={sx}>
        {expanded ? value : value?.slice(0, maxLength)}&nbsp;
        {value?.length > maxLength && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            type="button"
            component="button"
            sx={{ fontSize: 'inherit' }}
            onClick={onClickExpand}
          >
            {expanded ? 'view less' : 'view more'}
          </Link>
        )}
      </Box>
    );
  }

  export default ExpandableCell;