import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getClassificationsRequest,
  getClassificationsSuccess,
  getClassificationsError,
  calculatePerformanceRequest,
  calculatePerformanceSuccess,
  calculatePerformanceError,
  getRemediationRequest,
  getRemediationSuccess,
  getRemediationError,
  getListForRemediationRequest,
  getListForRemediationSuccess,
  getListForRemediationError,
  getSchemaRequest,
  getSchemaSuccess,
  getSchemaError,
  getDomainsRequest,
  getDomainsSuccess,
  getDomainsError,
  getRatingScoresRequest,
  getRatingScoresSuccess,
  getRatingScoresError,
  getContextualScoresRequest,
  getContextualScoresSuccess,
  getContextualScoresError,
  addRemediationRequest,
  addRemediationSuccess,
  addRemediationError,
  updateRemediationRequest,
  updateRemediationSuccess,
  updateRemediationError
} from './assetVulnerabilityListAction';

const defaultState = {
  remediation: null,
  isLoadingRemediation: true,
  getRemediationError: null,
  performance: null,
  isLoadingPerformance: false,
  getPerformanceError: null,
  schema: [],
  isLoadingSchema: true,
  getSchemaError: null,
  domains: [],
  isLoadingDomains: true,
  getDomainsError: null,
  ratingScores: [],
  isLoadingRatingScores: true,
  getRatingScoresError: null,
  contextualScores: [],
  isLoadingContextualScores: true,
  getRatingContextualError: null,
  classifications: [],
  isLoadingClassifications: true,
  getClassificationsError: null,
  assetVulnerabilities: [],
  isLoadingAssetVulnerabilities: false,
  getAssetVulnerabilitiesError: null,
  isAddingRemediation: false,
  addRemediationError: null,
  addedRemediation: false,
  isUpdatingRemediation: false,
  updateRemediationError: null,
  updatedRemediation: false
};

export default handleActions(
  {
    [getRemediationRequest](state) {
      return {
        ...state,
        isLoadingRemediation: true,
        getRemediationError: null,
      };
    },
    [getRemediationSuccess](state, { payload }) {
      return {
        ...state,
        remediation: payload,
        isLoadingRemediation: false,
        getRemediationError: null,
      };
    },
    [getRemediationError](state, { payload }) {
      return {
        ...state,
        isLoadingRemediation: false,
        getRemediationError: payload,
      };
    },   
    [calculatePerformanceRequest](state) {
      return {
        ...state,
        isLoadingPerformance: true,
        getPerformanceError: null,
      };
    },
    [calculatePerformanceSuccess](state, { payload }) {
      return {
        ...state,
        performance: payload,
        isLoadingPerformance: false,
        getPerformanceError: null,
      };
    },
    [calculatePerformanceError](state, { payload }) {
      return {
        ...state,
        isLoadingPerformance: false,
        getPerformanceError: payload,
      };
    },   
    [getSchemaRequest](state) {
      return {
        ...state,
        isLoadingSchema: true,
        getSchemaError: null,
      };
    },
    [getSchemaSuccess](state, { payload }) {
      return {
        ...state,
        schema: payload,
        isLoadingSchema: false,
        getSchemaError: null,
      };
    },
    [getSchemaError](state, { payload }) {
      return {
        ...state,
        isLoadingSchema: false,
        getSchemaError: payload,
      };
    },    
    [getDomainsRequest](state) {
      return {
        ...state,
        isLoadingDomains: true,
        getDomainsError: null,
      };
    },
    [getDomainsSuccess](state, { payload }) {
      return {
        ...state,
        domains: payload,
        isLoadingDomains: false,
        getDomainsError: null,
      };
    },
    [getDomainsError](state, { payload }) {
      return {
        ...state,
        isLoadingDomains: false,
        getDomainsError: payload,
      };
    },  
    [getClassificationsRequest](state) {
      return {
        ...state,
        isLoadingClassifications: true,
        getClassificationsError: null,
      };
    },
    [getClassificationsSuccess](state, { payload }) {
      return {
        ...state,
        classifications: payload,
        isLoadingClassifications: false,
        getClassificationsError: null,
      };
    },
    [getClassificationsError](state, { payload }) {
      return {
        ...state,
        isLoadingClassifications: false,
        getClassificationsError: payload,
      };
    },  
    [getRatingScoresRequest](state) {
      return {
        ...state,
        isLoadingRatingScores: true,
        getRatingScoresError: null,
      };
    },
    [getRatingScoresSuccess](state, { payload }) {
      return {
        ...state,
        ratingScores: payload,
        isLoadingRatingScores: false,
        getRatingScoresError: null,
      };
    },
    [getRatingScoresError](state, { payload }) {
      return {
        ...state,
        isLoadingRatingScores: false,
        getRatingScoresError: payload,
      };
    },    
    [getContextualScoresRequest](state) {
      return {
        ...state,
        isLoadingContextualScores: true,
        getContextualScoresError: null,
      };
    },
    [getContextualScoresSuccess](state, { payload }) {
      return {
        ...state,
        contextualScores: payload,
        isLoadingContextualScores: false,
        getContextualScoresError: null,
      };
    },
    [getContextualScoresError](state, { payload }) {
      return {
        ...state,
        isLoadingContextualScores: false,
        getContextualScoresError: payload,
      };
    },    
    [getListForRemediationRequest](state) {
      return {
        ...state,
        isLoadingAssetVulnerabilities: true,
        getAssetVulnerabilitiesError: null,
      };
    },
    [getListForRemediationSuccess](state, { payload }) {
      return {
        ...state,
        assetVulnerabilities: payload,
        isLoadingAssetVulnerabilities: false,
        getAssetVulnerabilitiesError: null,
      };
    },
    [getListForRemediationError](state, { payload }) {
      return {
        ...state,
        isLoadingAssetVulnerabilities: false,
        getAssetVulnerabilitiesError: payload,
      };
    },
    [addRemediationRequest](state) {
      return {
        ...state,
        isAddingRemediation: true,
        addRemediationError: null,
        addedRemediation: false
      };
    },
    [addRemediationSuccess](state, { payload }) {
      return {
        ...state,
        isAddingRemediation: false,
        addRemediationError: null,
        addedRemediation: true,
        remediation: payload
      };
    },
    [addRemediationError](state, { payload }) {
      return {
        ...state,
        isAddingRemediation: false,
        addRemediationError: payload,
        addedRemediation: false
      };
    },
    [updateRemediationRequest](state) {
      return {
        ...state,
        isUpdatingRemediation: true,
        updateRemediationError: null,
        updatedRemediation: false
      };
    },
    [updateRemediationSuccess](state, { payload }) {
      return {
        ...state,
        isUpdatingRemediation: false,
        updateRemediationError: null,
        updatedRemediation: true
      };
    },
    [updateRemediationError](state, { payload }) {
      return {
        ...state,
        isUpdatingRemediation: false,
        updateRemediationError: payload,
        updatedRemediation: false
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);