import styled from 'styled-components';
import QRCode from "react-qr-code";

export const QR = ({value = "", size = 300}) => 
<QRWrapper>
    <div style={{width: size, height: size}}>
        <QRCode
            size={size}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={value}
            viewBox={`0 0 ${size} ${size}`}
        />
    </div>
</QRWrapper>

export const QRWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
`;