import { Controller } from "react-hook-form";
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useState } from "react";

export const FormPasswordField = ({ name, control, label, startAdornment, rules, toggleButtonId, onToggleVisibilityChange, defaultValue="", ...other }) => {
    
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => { 
      if(onToggleVisibilityChange)
        onToggleVisibilityChange(!showPassword);

      setShowPassword((show) => !show);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
  
    return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <FormControl
            size="small"
            variant="outlined"
            fullWidth
            style={{'marginTop': 20}}
            required={rules && rules.required}
            error={!!error}                      
            onChange={onChange}
            {...other}
        >
          <InputLabel htmlFor={`outlined-adornment-password-${name}`}>{label}</InputLabel>
          <OutlinedInput
            id={`outlined-adornment-password-${name}`}
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  id={toggleButtonId}
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            aria-describedby={`component-error-text-${name}`}
            label={label}
            value={value}  
            startAdornment={startAdornment}
          />
          <FormHelperText id={`component-error-text-${name}`}>{error ? error.message : null}</FormHelperText>
        </FormControl>
      )}
    />
  );
};