import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { getActivitySurvey, getLibraries, confirmLibraries, cleanup } from "@/reducers/selfAssessment/selectLibraries/selectLibrariesAction";
import { enqueueSnackbar } from "notistack";
import { Col, Row } from "react-bootstrap";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import { Backdrop, Box, Button, CircularProgress, Typography } from "@mui/material";
import LibraryCard from "./components/libraryCard";
import { useState } from "react";
import SelectLanguages from "../../../Settings/Libraries/components/selectLanguages";
import { useTranslation } from "react-i18next";

const SelfAssessmentSelectLibraries = () => {
    
    const { 
        activity, 
        workflowActivityStepCompletedId,
        isEditor,
        handleRefreshProgress,
        activityType,
        handleClickNext
    } = useOutletContext();

    const {t} = useTranslation("common");
    const dispatch = useDispatch();

    const { 
        activitySurvey, isLoadingActivitySurvey, getActivitySurveyError,
        libraries, isLoadingLibraries, getLibrariesError,
        isConfirmingLibraries, confirmedLibraries, confirmLibrariesError
    } = useSelector(({ selectLibraries }) => selectLibraries);

    const [selectedLibraries, setSelectedLibraries] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [filteredLibraries, setFilteredLibraries] = useState([]);

    useEffect(() => {
        dispatch(getActivitySurvey(activity.workflowActivityId));
        dispatch(getLibraries(activityType.applicationModuleId));

        return () => {
            dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
        if(activitySurvey){
            setSelectedLibraries(activitySurvey.libraryIds ? [...activitySurvey.libraryIds] : []);
        }
    }, [activitySurvey]);

    useEffect(() => {
        if(getActivitySurveyError){
          enqueueSnackbar(getActivitySurveyError, {variant: "error"});
        }
    }, [getActivitySurveyError]);

    useEffect(() => {
        if(getLibrariesError){
          enqueueSnackbar(getLibrariesError, {variant: "error"});
        }
    }, [getLibrariesError]);

    useEffect(() => {
        if(confirmLibrariesError){
          enqueueSnackbar(confirmLibrariesError, {variant: "error"});
        }
    }, [confirmLibrariesError]);

    useEffect(() => {
        if(libraries && libraries.length){
            filterLibraries(selectedLanguages);
        }
    }, [libraries]);

    const onClickLibrary = (library) => {
        var clone = [...selectedLibraries];

        if(clone.includes(library.libraryId)){
            const index = clone.indexOf(library.libraryId);
            clone.splice(index, 1);

            setSelectedLibraries([...clone]);
        }
        else{
            clone.push(library.libraryId);
            setSelectedLibraries([...clone]);
        }
    };

    const handleClickConfirmSelection = () => {
        if(!selectedLibraries.length){
            enqueueSnackbar("You have to select at least 1 library", {variant: "error"});
        }
        else{
            const finalData = {
                id: activitySurvey.id,
                workflowActivityStepCompletedId,
                libraryIds: selectedLibraries
            }
            dispatch(confirmLibraries(finalData));
        }
    };

    useEffect(() => {
        if(confirmedLibraries){
            enqueueSnackbar("Selected libraries saved successfully", {variant: "success"});
            handleRefreshProgress();
            handleClickNext();
        }
    }, [confirmedLibraries]);

    const onChangeLanguage = (newLanguages) => {
        setSelectedLanguages(newLanguages);
        filterLibraries(newLanguages);        
    };

    const filterLibraries = (languages) => {
        if(languages.length){
            const librariesClon = [...libraries];
            const filtered = librariesClon.filter(l => languages.some(sl => sl === l.applicationLanguage.applicationLanguageId));
            console.log(filtered);
            setFilteredLibraries([...filtered]);
        }
        else {
            setFilteredLibraries([...libraries]);
        }
    };
    
    return(
        <Row>
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mb: 2
            }}> 
                <SelectLanguages 
                    value={selectedLanguages} 
                    onChange={onChangeLanguage}
                />
            </Box>

            {isLoadingLibraries || isLoadingActivitySurvey ? <CircularProgressWrapper /> :  
             libraries && !libraries.length ? <div style={{height: '200px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Typography>There are no libraries to show.</Typography></div> :
            
             <>
                {libraries.map((l, index) => {
                    return(
                        <Col md={4} style={{marginBottom: 30}}>
                            <LibraryCard 
                                activityType={activity.activityType}
                                library={l}
                                onClick={onClickLibrary}
                                isSelected={selectedLibraries.includes(l.libraryId)}
                                disabled={!isEditor}
                            />
                        </Col>
                    )
                })}

                { isEditor &&
                    <Col sm={12}>
                        <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: 10}}>
                            <Button 
                                variant="contained" 
                                onClick={handleClickConfirmSelection}
                                disabled={!selectedLibraries.length}
                            >
                                {t("app.activity.steps.saveAndContinue")}
                            </Button>  
                        </div>
                    </Col>
                }
            </>
            }

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isConfirmingLibraries}
            >               
            <CircularProgress color="inherit" />
          </Backdrop>
        </Row>
    );
}

export default SelfAssessmentSelectLibraries;