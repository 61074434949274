import { Backdrop, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Slider, TextField, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { cleanup, addVulnerabilityStatus } from "@/reducers/vulnerabilityStatuses/addVulnerabilityStatus/addVulnerabilityStatusAction";
import { useRef } from "react";
import { FormColorField } from "@/shared/components/form/material-controls/ColorField";
import { FormNumberField } from "@/shared/components/form/material-controls/NumberField";
import { FormSliderField } from "../../../../shared/components/form/material-controls/SliderField";

const CreateVulnerabilityStatusModal = ({ open, onHideModal, onVulnerabilityStatusCreated }) => {
    const dispatch = useDispatch();
  
    const { 
      isAddingVulnerabilityStatus, addVulnerabilityStatusError, addedVulnerabilityStatus
    } = useSelector(({ addVulnerabilityStatus }) => addVulnerabilityStatus);

    useEffect(() => {
      return () => {
        dispatch(cleanup());
      }
    }, [dispatch]);
  
    const { handleSubmit, reset, control, setValue, getValues } = useForm();
  
    const handleClose = (event, reason) => {
      if (reason && reason === "backdropClick") 
          return;
        
      reset();
      onHideModal();
    }
  
    useEffect(() => {
      if(addVulnerabilityStatusError){
        enqueueSnackbar(addVulnerabilityStatusError, {variant: "error"});
      }
    }, [addVulnerabilityStatusError]);
  
    const onSubmit = (data) => { 
      data.name = { it: data.nameTraslated, en: data.nameTraslated};
      data.description = { it: data.descriptionTraslated, en: data.descriptionTraslated};
      
      dispatch(addVulnerabilityStatus(data));
    }
  
    useEffect(() => {
      if(addedVulnerabilityStatus){
        enqueueSnackbar("Vulnerability Status created successfully", {variant: "success"});
        handleClose();
        onVulnerabilityStatusCreated();
      }
    }, [addedVulnerabilityStatus]);
  
    return (
      <Dialog
          fullWidth
          maxWidth='lg'
          scroll='paper'
          open={open}
          onClose={handleClose}
      >
          <DialogTitle>Create Vulnerability Status</DialogTitle>
          <DialogContent>
            <Row>
              <Col md={12}>
                <FormTextField
                    name='nameTraslated'
                    label='Name'
                    control={control}
                    rules={{ required: 'This field is required' }}
                />
              </Col>
              <Col md={12}>
                <FormTextField
                    name='descriptionTraslated'
                    label='Description'
                    control={control}
                    multiline
                    rows={4}
                />
              </Col>
              <Col md={6}>
                <FormSliderField 
                  name='value'
                  label='Value'
                  control={control}
                  marks
                  min={0}
                  max={1}
                  step={0.1}
                />
              </Col>
              <Col md={6}>
                <FormColorField 
                    name='color'
                    label='Color'
                    control={control}
                />
              </Col>           
            </Row>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant='contained' color="success" onClick={handleSubmit(onSubmit)}>Create</Button>
          </DialogActions>
  
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isAddingVulnerabilityStatus}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
      </Dialog>
    );
  }
  
  export default CreateVulnerabilityStatusModal;