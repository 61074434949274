import { Backdrop, Box, Button, CircularProgress, Paper} from "@mui/material"; 
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CardPage, CardPageBody, CardPageHeader, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap, CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import StakeholderForm from "../../../Activities/EditStep/SASteps/components/customSurveyComponents/stakeholderInformation/stakeholderForm";
import { useForm } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { cleanup, addSupplier, getStakeholderTypes } from "@/reducers/supplier/addSupplier/addSupplierAction";
import { FormSelectField } from '@/shared/components/form/material-controls/SelectField';

const CreateSupplier = () => {
    const dispatch = useDispatch();

    const { 
        isAddingSupplier, addSupplierError, addedSupplier,
        stakeholderTypes, isLoadingStakeholderTypes, getStakeholderTypesError
      } = useSelector(({ addSupplier }) => addSupplier);

    const { handleSubmit, reset, setValue, watch, control, getValues } = useForm();

    const navigate = useNavigate();

    useEffect(() => {   
        dispatch(getStakeholderTypes());

        return () => {
          dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
        if(addSupplierError){
            enqueueSnackbar(addSupplierError, {variant: "error"});
        }
    }, [addSupplierError]);

    useEffect(() => {
        if(getStakeholderTypesError){
            enqueueSnackbar(getStakeholderTypesError, {variant: "error"});
        }
    }, [getStakeholderTypesError]);
    
    const onSubmit = (data) => { 
        dispatch(addSupplier(data));
    }

    useEffect(() => {
        if(addedSupplier){
            enqueueSnackbar("Stakeholder created successfully", {variant: "success"});
            navigate("/Registry/Stackholders");
        }
    }, [addedSupplier]);

    const onClickCancel = () => { 
        navigate("/Registry/Stackholders");
    }

    return(
      <Container>
        <CardPage>
          <CardPageBody>
              <CardPageHeader>
                <CardPageTitleWrap>
                  <CardPageTitle>Create Stakeholder</CardPageTitle>
                </CardPageTitleWrap>
                </CardPageHeader>
                <Row>
                    <Col md={12}>
                        <FormTextField
                            name='name'
                            label='Name'
                            control={control}
                            rules={{ required: 'This field is required' }}
                        />
                    </Col>
                    <Col md={12}>
                        <FormTextField
                            name='description'
                            label='Description'
                            control={control}
                            multiline
                            rows={4}
                        />
                    </Col>
                    <Col md={12}>
                        <FormSelectField
                            name='stakeholderTypeId'
                            label='Stakeholder Type'
                            control={control}
                            options={stakeholderTypes}
                            rules={{ required: 'This field is required' }}
                            keyValue={{ id:"stakeholderTypeId", label:"name" }}
                        />
                    </Col> 
                </Row>
                <StakeholderForm
                    control={control}
                    setValue={setValue}
                    watch={watch}
                />
                
                <Row>
                    <Col md={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: "flex-end",
                            marginTop: '20px'
                        }}>
                            <Button variant="outlined" color="primary" onClick={onClickCancel} sx={{marginRight: '20px'}}>Cancel</Button>
                            <Button variant="contained" color="primary"  onClick={handleSubmit(onSubmit)}>Create</Button>
                        </Box>
                    </Col>
                </Row>
             
          </CardPageBody>
        </CardPage>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isAddingSupplier}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    )
}

export default CreateSupplier;