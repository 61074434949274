import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getServiceLogsRequest,
  getServiceLogsSuccess,
  getServiceLogsError
} from './serviceLogsAction';

const defaultState = {
  serviceLogs: null,
  isLoadingServiceLogs: true,
  getServiceLogsError: null,
};

export default handleActions(
  {
    [getServiceLogsRequest](state) {
      return {
        ...state,
        isLoadingServiceLogs: true,
        getServiceLogsError: null,
      };
    },
    [getServiceLogsSuccess](state, { payload }) {
      return {
        ...state,
        serviceLogs: payload,
        isLoadingServiceLogs: false,
        getServiceLogsError: null,
      };
    },
    [getServiceLogsError](state, { payload }) {
      return {
        ...state,
        isLoadingServiceLogs: false,
        getServiceLogsError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);
