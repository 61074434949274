import { Controller } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export const FormSelectField = ({ 
  name, 
  control, 
  label, 
  isLoading, 
  disableClearable, 
  rules, 
  disabled = false, 
  defaultValue=null, 
  keyValue={id:"id", label:"name"}, 
  options, 
  ...other 
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({
        field: { onChange, ref, ...field },
        fieldState: { error }
      }) => (
        <Autocomplete
            disabled={disabled}
            loading={isLoading}
            disableClearable={disableClearable}
            options={options ?? []}
            defaultValue={options && options.length ? options.find(o => o[keyValue.id] === defaultValue) : defaultValue}
            getOptionLabel={(option) => option[keyValue.label]}
            onChange={(e, data) => onChange(data ? data[keyValue.id] : "")}
            renderInput={(params) => 
                <TextField 
                    {...params} 
                    {...field }
                    required={rules && rules.required}
                    style={{'marginTop': 20}}
                    helperText={error ? error.message : null}
                    size="small"
                    error={!!error}
                    fullWidth
                    inputRef={ref}
                    label={label}
                    variant="outlined"
                    {...other}
                />
            }
        />
      )}
    />
  );
};