import { Button, Grid, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridToolbar, gridClasses } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { cleanup, getRoles, getTenants, getUsers } from "@/reducers/system/users/systemUserListAction";
import { Col, Container, Row } from "react-bootstrap";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { CardPage, CardPageFilterWrapper, CardPageHeader, CardPageBody, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap,CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import AddIcon from '@mui/icons-material/Add';
import EyeIcon from "mdi-react/EyeIcon";
import { FormSelectField } from "@/shared/components/form/material-controls/SelectField";
import { SearchFilters } from "@/shared/components/SearchFilters";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import MailIcon from '@mui/icons-material/Mail';

const SystemUserList = () => {
    const dispatch = useDispatch();
    const { 
        roles, isLoadingRoles, getRolesError,
        tenants, isLoadingTenants, getTenantsError,
        users, usersTotalCount, isLoadingUsers, getUsersError
    } = useSelector(({ systemUserList }) => systemUserList);

    const [selectedRows, setSelectedRows] = useState([]);
    const [searchFilters, setSearchFilters] = useState({ pageSize: 30, page: 1 });

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getRoles());
        dispatch(getTenants());

        return () => {
          dispatch(cleanup());
        }
    }, [dispatch]);

    
    useEffect(() => {
        if(getRolesError){
            enqueueSnackbar(getRolesError, {variant: "error"});
        }
    }, [getRolesError]);

    useEffect(() => {
        if(getTenantsError){
            enqueueSnackbar(getTenantsError, {variant: "error"});
        }
    }, [getTenantsError]);
    
    useEffect(() => {
        if(getUsersError){
            enqueueSnackbar(getUsersError, {variant: "error"});
        }
    }, [getUsersError]);
    
    useEffect(() => {
        dispatch(getUsers(searchFilters));
    }, [searchFilters]);

    const setPaginationModel = (model) => {
        setSearchFilters({ 
            ...searchFilters, 
            page: model.page + 1,
            pageSize: model.pageSize
        });
    };

    const onFilterSubmit = (data) => {
        setSearchFilters({ ...searchFilters, page: 1, ...data});
    }

    const handleClickDeleteUser = (id) => {
        console.log(id);
    }

    const handleClickEditUser = (id) => {
        console.log(id);
    }

    const handleClickAddUser = () => {
        navigate("Add");
    }

    const columns = [
        { 
            field: 'userName',
            headerName: 'User name',
            minWidth: 200,
            flex: 0.5
        },
        { 
            field: 'roles', 
            headerName: 'Roles', 
            minWidth: 300,
            flex: 1
        },
        { 
            field: 'email', 
            headerName: 'Email', 
            minWidth: 300,
            flex: 1
        },        
        { 
            field: 'tenantName', 
            headerName: 'Tenant', 
            minWidth: 200,
            flex: 0.5
        },
        { 
            field: 'firstName', 
            headerName: 'First Name', 
            minWidth: 200,
            flex: 0.5
        },
        { 
            field: 'lastName', 
            headerName: 'Last Name', 
            minWidth: 200,
            flex: 0.5
        },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => [
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => handleClickDeleteUser(params.id)}
              />,
              <GridActionsCellItem
                icon={<EyeIcon />}
                label="View"
                showInMenu
                onClick={() => handleClickDeleteUser(params.id)}
              />,
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                showInMenu
                onClick={() => handleClickEditUser(params.id)}
              />,
              <GridActionsCellItem
                icon={<LockOpenIcon />}
                label="Unlock"
                showInMenu
                onClick={() => handleClickDeleteUser(params.id)}
              />,
              <GridActionsCellItem
                icon={<LockIcon />}
                label="Lock"
                showInMenu
                onClick={() => handleClickDeleteUser(params.id)}
              />,
              <GridActionsCellItem
                icon={<MailIcon />}
                label="Resend Confirmation Email"
                showInMenu
                onClick={() => handleClickDeleteUser(params.id)}
              />
            ],
          },
      ];

    return(
        <Container>
            <CardPage>
                <CardPageBody>   
                    <CardPageHeader>
                        <CardPageTitleWrap>
                            <CardPageTitle>Users</CardPageTitle>
                            <CardPageSubhead>
                            Here you can view all the users.
                            </CardPageSubhead>
                        </CardPageTitleWrap>
                        <CardPageButtonWrap>
                            <Button 
                                onClick={handleClickAddUser}
                                variant="contained" 
                                color="primary" 
                                endIcon={<AddIcon/>}>Add User</Button>
                        </CardPageButtonWrap>
                    </CardPageHeader>

                    <CardPageFilterWrapper>
                        <SearchFilters 
                            searchLabel='Search'
                            onFilterSubmit={onFilterSubmit}
                            renderChildrenInputs={(control) => {
                                return(
                                <>
                                    <Grid item xs={12} md={3}>
                                        <FormSelectField
                                            style={{margin: 0}}
                                            name="tenantId"
                                            label="Tenant"
                                            control={control}
                                            options={tenants}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                    <FormSelectField
                                            style={{margin: 0}}
                                            name="roleId"
                                            label="Role"
                                            control={control}
                                            options={roles}
                                        />
                                    </Grid>
                                </>
                                );
                            }}
                        />
                    </CardPageFilterWrapper>

                    <CardPageGridWrap>
                        <DataGrid 
                        autoHeight
                        density="compact"
                        checkboxSelection 
                        disableRowSelectionOnClick
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setSelectedRows(newRowSelectionModel);
                        }}
                        rowSelectionModel={selectedRows}
                        loading={isLoadingUsers} 
                        rows={users}
                        columns={columns} 
                        slots={{ toolbar: GridToolbar }}
                        sx={{
                            [`& .${gridClasses.cell}`]: {
                            py: 1,
                            },
                        }}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 30 } },
                        }}
                        pageSizeOptions={[5, 15, 30, 50, 100]}
                        rowCount={usersTotalCount}
                        paginationModel={{
                            page: searchFilters.page - 1, 
                            pageSize: searchFilters.pageSize}}
                        paginationMode="server"
                        onPaginationModelChange={setPaginationModel}
                        />
                    </CardPageGridWrap>
                </CardPageBody>
            </CardPage>
        </Container>
    )
}

export default SystemUserList;