import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getRolesAndUsers, cleanup } from "@/reducers/activity/activityUserRoles/activityUserRolesAction";
import { CircularProgress, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';



const ActivityUserRoles = ({activity}) => {
    const dispatch = useDispatch();

    const roleIds = activity.workflowActivityUserRole.map(w => w.roleId);
    const userIds = activity.workflowActivityUserRole.map(w => w.userId);

    const { 
        rolesAndUsers, isLoadingRolesAndUsers, getRolesAndUsersError
     } = useSelector(({ activityUserRoles }) => activityUserRoles);

     useEffect(() => {
        dispatch(getRolesAndUsers(userIds, roleIds));

        return () => {
            dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
        if(getRolesAndUsersError){
          enqueueSnackbar(getRolesAndUsersError, {variant: "error"});
        }
    }, [getRolesAndUsersError]);

    return(
        isLoadingRolesAndUsers ? <div style={{height: '100px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><CircularProgress /></div> : 
        roleIds.map((id, index) => {
            const userId = userIds[index];
            const user = rolesAndUsers.users.find(u => u.id === userId);
            const role = rolesAndUsers.roles.find(r => r.id === id);
            return(
                <Typography 
                    key={index} 
                    style={{fontSize: 14, marginBottom: 5}}>
                        {role ? role.name : ""}: {user ? user.fullName : ""}
                </Typography>
            )
        })
        
    );
}

export default ActivityUserRoles;