import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Button, Card, CardContent, CircularProgress, Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import ConfirmationDialog from '@/shared/components/ConfirmationDialog';
import { enqueueSnackbar } from 'notistack';
import { useForm } from "react-hook-form";
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormDateField } from '@/shared/components/form/material-controls/DateField';
import { FormSelectField } from '@/shared/components/form/material-controls/SelectField';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { massiveUpdate, clearScheduledDates } from "@/reducers/checkList/massiveUpdate/massiveUpdateAction";

const MassiveUpdatePanel = ({ 
  vulnerabilityStatuses, 
  selectedRowsIds, 
  remediationId, 
  stepCompletedId,
  onMassiveUpdate 
}) => {
  const dispatch = useDispatch();
  const { 
    isUpdatingCheckList, updatedCheckList, updateCheckListError,
    isClearingChecklist, clearedCheckList, clearCheckListError
  } = useSelector(({ massiveUpdateCheckList }) => massiveUpdateCheckList);

    const { handleSubmit, reset, control, setValue } = useForm();

    const [openConfirmClear, setOpenConfirmClear] = useState(false);
    const [openConfirmUpdate, setOpenConfirmUpdate] = useState(false);

    const handleClickClear = () => {
      setOpenConfirmClear(true);
    }

    const handleConfirmClear = () => {
      setOpenConfirmClear(false);

      const finalData = {
        assetVulnerabilityIds: selectedRowsIds,
        remediationId,
        workflowActivityStepCompletedId: stepCompletedId
      };

      dispatch(clearScheduledDates(finalData));
    }
  
    const handleOnCloseClear = () => {
      setOpenConfirmClear(false);
    };

    const handleClickUpdate = () => {
      setOpenConfirmUpdate(true);
    }
  
    const handleOnCloseUpdate = () => {
      setOpenConfirmUpdate(false);
    };

    const onSubmit = (data) => {
      if(data.scheduledDate || data.vulnerabilityStatusId){
        
        const finalData = {
          assetVulnerabilityIds: selectedRowsIds,
          statusId: data.vulnerabilityStatusId,
          scheduledDate: data.scheduledDate,
          remediationId,
          workflowActivityStepCompletedId: stepCompletedId
        };

        dispatch(massiveUpdate(finalData));
      }
      else{
        enqueueSnackbar("Select at least 1 field to update.", {variant: "error"});
      }
      setOpenConfirmUpdate(false);
    }

    useEffect(() => {
      if(clearCheckListError){
        enqueueSnackbar(clearCheckListError, {variant: "error"});
      }
    }, [clearCheckListError]);

    useEffect(() => {
      if(updateCheckListError){
        enqueueSnackbar(updateCheckListError, {variant: "error"});
      }
    }, [updateCheckListError]);

    useEffect(() => {
      if(updatedCheckList){
        enqueueSnackbar("Rows updated successfully", {variant: "success"});
        onMassiveUpdate();
      }
    }, [updatedCheckList]);

    useEffect(() => {
      if(clearedCheckList){
        enqueueSnackbar("Scheduled Dates cleared successfully", {variant: "success"});
        onMassiveUpdate();
      }
    }, [clearedCheckList]);

    return(
        <Accordion key={1} style={{marginTop: 20}}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
            <Typography>Massive Update ({selectedRowsIds.length} rows selected)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Row>
                <Col md={9}>
                  <Card style={{height: "100%"}} variant="outlined">
                      <CardContent>
                          <Row>
                            <Col md={4}>
                              <FormSelectField 
                                style={{marginTop: 10}}
                                name="vulnerabilityStatusId" 
                                label="Vulnerability Status" 
                                control={control} 
                                options={vulnerabilityStatuses}
                                keyValue={{id:"id", label:"nameTraslated"}}
                              />
                            </Col>
                            <Col md={4}>
                              <FormDateField 
                                style={{marginTop: 10}}
                                name="scheduledDate" 
                                label="Scheduled Date" 
                                control={control}
                              />
                            </Col>
                            <Col md={4}>
                              <Button 
                                onClick={handleClickUpdate}
                                disabled={selectedRowsIds.length === 0}
                                style={{marginTop: 10, width: "100%"}}
                                variant="contained"
                                endIcon={<DoneAllIcon />}>
                                  Update Selected
                              </Button>
                            </Col>                          
                          </Row>
                      </CardContent>
                  </Card>
                </Col>
                <Col md={3}>
                  <Card style={{height: "100%"}} variant="outlined">
                    <CardContent>
                      <Button 
                        onClick={handleClickClear}
                        disabled={selectedRowsIds.length === 0}
                        style={{marginTop: 10, width: "100%"}}
                        variant="contained" 
                        color="error" 
                        endIcon={<EventBusyIcon />}>
                          Clear Scheduled Dates
                      </Button>
                    </CardContent>
                  </Card>
                </Col>
              </Row>

              <ConfirmationDialog 
                open={openConfirmClear}
                onConfirm={handleConfirmClear}
                onClose={handleOnCloseClear}
                title="Clear Scheduled Dates"
                body={`Are you sure you want to clear Scheduled Date on ${selectedRowsIds.length} rows?`}
                color='error'
                confirmButtonText='Clear'
              />

              <ConfirmationDialog 
                open={openConfirmUpdate}
                onConfirm={handleSubmit(onSubmit)}
                onClose={handleOnCloseUpdate}
                title="Massive Update"
                body={`Are you sure you want to update ${selectedRowsIds.length} rows?`}
                color='primary'
                confirmButtonText='Update'
              />
            </AccordionDetails>

            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isUpdatingCheckList || isClearingChecklist}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
        </Accordion>
    );
}

export default MassiveUpdatePanel;