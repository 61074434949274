import { createAction } from 'redux-actions';
import { instance } from "../../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_SYSTEM_USER_ADD');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getRolesRequest = createAction('GET_ROLES_SYSTEM_USER_ADD_REQUEST');
export const getRolesSuccess = createAction('GET_ROLES_SYSTEM_USER_ADD_SUCCESS');
export const getRolesError = createAction('GET_ROLES_SYSTEM_USER_ADD_ERROR');

export const getRoles = () => async (dispatch) => {
  try {
    dispatch(getRolesRequest());

    const resp = await instance.get(`/v1/UserAndRole/GetApplicationRoles`);
    
    dispatch(getRolesSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getRolesError(e.response.data.description));
    else
      dispatch(getRolesError(e.message));
  }
};

export const getTenantsRequest = createAction('GET_TENANTS_SYSTEM_USER_ADD_REQUEST');
export const getTenantsSuccess = createAction('GET_TENANTS_SYSTEM_USER_ADD_SUCCESS');
export const getTenantsError = createAction('GET_TENANTS_SYSTEM_USER_ADD_ERROR');

export const getTenants = () => async (dispatch) => {
  try {
    dispatch(getTenantsRequest());

    const resp = await instance.get(`/v1/Tenant/GetAll`);
    
    dispatch(getTenantsSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getTenantsError(e.response.data.description));
    else
      dispatch(getTenantsError(e.message));
  }
};

export const addUserRequest = createAction('SYSTEM_USER_ADD_REQUEST');
export const addUserSuccess = createAction('SYSTEM_USER_ADD_SUCCESS');
export const addUserError = createAction('SYSTEM_USER_ADD_ERROR');

export const addUser = (data) => async (dispatch) => {
  try {
    dispatch(addUserRequest());

    const resp = await instance.post(`/v1/UserAndRole/AddNewUser`, data);
    
    dispatch(addUserSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(addUserError(e.response.data.description));
    else
      dispatch(addUserError(e.message));
  }
};