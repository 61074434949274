import { createAction } from 'redux-actions';
import { instance } from "../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_SUPPLIERS');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getSuppliersRequest = createAction('SUPPLIERS_GET_ALL_REQUEST');
export const getSuppliersSuccess = createAction('SUPPLIERS_GET_ALL_SUCCESS');
export const getSuppliersError = createAction('SUPPLIERS_GET_ALL_ERROR');

export const getSuppliers = () => async (dispatch) => {
  try {
    dispatch(getSuppliersRequest());

    const resp = await instance.get(`/v1/Stakeholder/GetTenantStakeholders`);
    
    dispatch(getSuppliersSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getSuppliersError(e.response.data.description));
    else
      dispatch(getSuppliersError(e.message));
  }
};

export const deleteSuppliersRequest = createAction('SUPPLIERS_DELETE_REQUEST');
export const deleteSuppliersSuccess = createAction('SUPPLIERS_DELETE_SUCCESS');
export const deleteSuppliersError = createAction('SUPPLIERS_DELETE_ERROR');

export const deleteSuppliers = (id) => async (dispatch) => {
  try {
    dispatch(deleteSuppliersRequest());
    const resp = await instance.delete(`/v1/Stakeholder/DeleteLogic/${id}`);

    dispatch(deleteSuppliersSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(deleteSuppliersError(e.response.data.description));
    else
      dispatch(deleteSuppliersError(e.message));
  }
};

export const getClassificationsRequest = createAction('SUPPLIERS_GET_CLASSIFICATIONS_REQUEST');
export const getClassificationsSuccess = createAction('SUPPLIERS_GET_CLASSIFICATIONS_SUCCESS');
export const getClassificationsError = createAction('SUPPLIERS_GET_CLASSIFICATIONS_ERROR');

export const getClassifications = () => async (dispatch) => {
  try {
    dispatch(getClassificationsRequest());
    const resp = await instance.get(`/v1/Classification/GetAll`);
    console.log(resp);
    dispatch(getClassificationsSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getClassificationsError(e.response.data.description));
    else
      dispatch(getClassificationsError(e.message));
  }
};