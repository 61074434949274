import { Box, Card, CardActionArea, CardContent, Checkbox, Typography } from "@mui/material";
import GroupIcon from '@mui/icons-material/Group';
import { useEffect, useMemo, useState } from "react";
import { instance } from "@/config/interceptor";

const AssetSelectCard = ({
    workflow, 
    asset, 
    onClick, 
    isSelected,
    disabled
}) => {
    const [logo, setLogo] = useState(null);

    async function getLogo() {
        try{
            const resp = await instance.get(`/v1/Stakeholder/GetLogo/${asset.stakeholderId}`, { 
                responseType: 'blob'
            });

            if(resp.data && resp.data.size){
                const urlObject = URL.createObjectURL(resp.data);
                setLogo(urlObject);
            }
        }
        catch(e){ }
    }

    useEffect(() => {
        getLogo();
    }, []);

    return(
        <Card
            onClick={() => onClick(asset)} 
            sx={{
                height: '100%',
                minHeight: '160px',

                '&:hover': {
                    cursor: 'pointer'
                },
            }}
        >
            <CardActionArea style={{height: '100%'}} disabled={disabled}>
                <CardContent style={{height: '100%'}}>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", height: '100%'}}>
                        <div style={{
                            display: "flex",
                            justifyContent: 'space-between'
                        }}>
                            <Box sx={{
                                display: 'flex'
                            }}>
                                <GroupIcon fontSize="large" style={{marginRight: 10}} />
                                <div>
                                    <Typography variant="h5" style={{display: 'flex', alignItems: 'center'}}>{asset.name}</Typography>
                                    <Typography variant="body1" color="text.secondary">{asset.description}</Typography>
                                </div>
                            </Box>
                            <Box sx={{ height: '100px' }}>
                                { logo && 
                                    <img style={{ maxWidth: 100, maxHeight: 100 }} src={logo} alt="avatar"/>
                                }
                            </Box>
                        </div>

                        <div style={{marginTop: 30, display: "flex", justifyContent: 'space-between', width: '100%', alignItems: 'flex-end'}}>
                            <div>
                                <Typography sx={{fontSize: '20px', fontWeight: 'bold', lineHeight: 1 }}>
                                    STAKEHOLDERS
                                </Typography>
                            </div>

                            <Checkbox 
                                disabled={disabled}
                                onClick={() => onClick(asset)}
                                checked={isSelected}
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 40 } }} 
                            />
                        </div>
                    </div>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default AssetSelectCard;