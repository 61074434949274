import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_UPDATE_VULNERABILITY_SCORE');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const updateVulnerabilityScoreRequest = createAction('UPDATE_VULNERABILITY_SCORE_POST_REQUEST');
export const updateVulnerabilityScoreSuccess = createAction('UPDATE_VULNERABILITY_SCORE_POST_SUCCESS');
export const updateVulnerabilityScoreError = createAction('UPDATE_VULNERABILITY_SCORE_POST_ERROR');

export const updateVulnerabilityScore = (request) => async (dispatch) => {
  try {
    dispatch(updateVulnerabilityScoreRequest());
    const resp = await instance.put(`/v1/RatingScore/Put`, request);

    dispatch(updateVulnerabilityScoreSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(updateVulnerabilityScoreError(e.response.data.description));
    else
      dispatch(updateVulnerabilityScoreError(e.message));
  }
};
