import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getVulnerabilityRequest,
  getVulnerabilitySuccess,
  getVulnerabilityError
} from './vulnerabilityInfoAction';

const defaultState = {
  vulnerability: null,
  isLoadingVulnerability: true,
  getVulnerabilityError: null
};

export default handleActions(
  {
    [getVulnerabilityRequest](state) {
      return {
        ...state,
        vulnerability: null,
        isLoadingVulnerability: true,
        getVulnerabilityError: null,
      };
    },
    [getVulnerabilitySuccess](state, { payload }) {
      return {
        ...state,
        vulnerability: payload,
        isLoadingVulnerability: false,
        getVulnerabilityError: null,
      };
    },
    [getVulnerabilityError](state, { payload }) {
      return {
        ...state,
        vulnerability: null,
        isLoadingVulnerability: false,
        getVulnerabilityError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);