import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getConversationRequest,
  getConversationSuccess,
  getConversationError,
  sendMessageRequest,
  sendMessageSuccess,
  sendMessageError
} from './chatSurveyAction';

const defaultState = {
  conversation: null,
  isLoadingConversation: true,
  getConversationError: null,
  isSendingMessage: false, 
  sentMessage: false, 
  sendMessageError: null
};

export default handleActions(
  {
    [getConversationRequest](state) {
      return {
        ...state,
        conversation: null,
        isLoadingConversation: true,
        getConversationError: null,
      };
    },
    [getConversationSuccess](state, { payload }) {
      return {
        ...state,
        conversation: payload,
        isLoadingConversation: false,
        getConversationError: null,
      };
    },
    [getConversationError](state, { payload }) {
      return {
        ...state,
        conversation: null,
        isLoadingConversation: false,
        getConversationError: payload,
      };
    },
    [sendMessageRequest](state, { payload }) {
      var conversation;
      if(state.conversation){
        conversation = JSON.parse(JSON.stringify(state.conversation));
      }
      else{
        conversation = { };
      }

      const newMessage = {
        text: payload.message,
        sendDate: new Date(),
        userId: payload.userId
      }

      if(!conversation.messages){
        conversation.messages = [];
      }
      conversation.messages.push(newMessage);

      return {
        ...state,
        isSendingMessage: true,
        sendMessageError: null,
        sentMessage: false,
        conversation
      };
    },
    [sendMessageSuccess](state, { payload }) {
      return {
        ...state,
        isSendingMessage: false,
        sendMessageError: null,
        sentMessage: true
      };
    },
    [sendMessageError](state, { payload }) {
      return {
        ...state,
        isSendingMessage: false,
        sendMessageError: payload,
        sentMessage: false
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);