import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_SURVEY_MODEL');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getSurveyRequest = createAction('SURVEY_MODEL_GET_REQUEST');
export const getSurveySuccess = createAction('SURVEY_MODEL_GET_SUCCESS');
export const getSurveyError = createAction('SURVEY_MODEL_GET_ERROR');

export const getSurvey = (surveyId, activitySurveyId) => async (dispatch) => {
  try {
    dispatch(getSurveyRequest());
    const resp = await instance.get(`/v1/ActivitySurvey/GetSurveyModel`, {
      params: {
        surveyId,
        activitySurveyId
      }
    });
    
    dispatch(getSurveySuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getSurveyError(e.response.data.description));
    else
      dispatch(getSurveyError(e.message));
  }
};

export const answerSurveyRequest = createAction('SURVEY_MODEL_ANSWER_REQUEST');
export const answerSurveySuccess = createAction('SURVEY_MODEL_ANSWER_SUCCESS');
export const answerSurveyError = createAction('SURVEY_MODEL_ANSWER_ERROR');

export const answerSurvey = (data) => async (dispatch) => {
  try {
    dispatch(answerSurveyRequest());
    const resp = await instance.post(`/v1/ActivitySurvey/AnswerSurvey`, data);
    
    dispatch(answerSurveySuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(answerSurveyError(e.response.data.description));
    else
      dispatch(answerSurveyError(e.message));
  }
};

export const saveDraftRequest = createAction('SURVEY_MODEL_SAVE_DRAFT_REQUEST');
export const saveDraftSuccess = createAction('SURVEY_MODEL_SAVE_DRAFT_SUCCESS');
export const saveDraftError = createAction('SURVEY_MODEL_SAVE_DRAFT_ERROR');

export const saveDraft = (data) => async (dispatch) => {
  try {
    dispatch(saveDraftRequest());
    const resp = await instance.post(`/v1/ActivitySurvey/SaveDraft`, data);
    
    dispatch(saveDraftSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(saveDraftError(e.response.data.description));
    else
      dispatch(saveDraftError(e.message));
  }
};