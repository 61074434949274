import React from "react";
import {
  AccountCard,
  AccountContent,
  AccountHead,
  AccountLogo,
  AccountLogoAccent,
  AccountFooter,
  AccountTitle,
  AccountWrap,
  Logo,
  FormWrapper,
  LogoCompany,
  FormButtonContainer,
  AccountHeader,
  AccountLogoFooter,
  TitleWrapper,
  SubtitleWrapper
} from "@/shared/components/account/AccountElements";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Backdrop, Button, Card, CardHeader, CircularProgress, InputAdornment, Link, Typography } from "@mui/material";
import { Link as ReactRouterLink, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { twoFactorLogin } from "@/reducers/auth/twoFactorLogin/twoFactorLoginAction";
import { login } from "@/reducers/auth/authAction";
import { FormTextField } from "@/shared/components/form/material-controls/TextField";

const LoginWith2fa = () => {

    const { email } = useParams();

    const dispatch = useDispatch();

    const {
        token, refreshToken, isLoggedIn, isLogging, urlRedirect
    } = useSelector(({ auth }) => auth);
    
    const { handleSubmit, reset, control, setValue, getValues } = useForm();

    useEffect(() => {
        return () => {
          reset();
        }
      }, [dispatch]);
    

    const onSubmit = (data) => {
        data.UserEmail = email;
        dispatch(twoFactorLogin(data));
    };


    // useEffect(() => {
    //     if(urlRedirect){
    //         navigate(urlRedirect);
    //     }
    // }, [urlRedirect]);

    return(
    <AccountWrap>
        <AccountContent>
            <AccountCard>
                <AccountHeader />

                <Row>
                    <Col md={12}>
                        <TitleWrapper>
                            <Typography variant="h5">
                                Two factor authentication
                            </Typography>
                        </TitleWrapper>

                        <SubtitleWrapper>
                            <Typography variant="subtitle2">
                                Open your authenticator app and write the 6 digit code to login.
                            </Typography>
                        </SubtitleWrapper>
                    </Col>
                </Row>

                <FormWrapper>
                    <Row>
                        <Col md={12}>
                            <FormTextField
                                name="verifyCode"
                                label="Code"
                                size=''
                                control={control}
                                rules={{ required: 'This field is required'}}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormButtonContainer>
                                <div style={{ marginRight: 20, marginTop:10}}>
                                    <Link component={ReactRouterLink} to="/Account/Login" underline="hover">
                                        Back to login
                                    </Link>
                                </div>
                                <Button
                                    variant="contained"
                                    onClick={handleSubmit(onSubmit)}
                                    size="large"
                                >
                                    Continue
                                </Button>
                            </FormButtonContainer>
                        </Col>
                    </Row>
                </FormWrapper>

                <AccountLogoFooter />
            </AccountCard>
        </AccountContent>

        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLogging}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </AccountWrap>
    );
};

const ForgotPasswordWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 5px;
`;

export default LoginWith2fa;
