import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getCurrentEmailAccountRequest,
  getCurrentEmailAccountSuccess,
  getCurrentEmailAccountError,
  updateEmailAccountRequest,
  updateEmailAccountSuccess,
  updateEmailAccountError,
  createEmailAccountRequest,
  createEmailAccountSuccess,
  createEmailAccountError
} from './optionsAction';

const defaultState = {
  emailAccount: null,
  isLoadingEmailAccount: true,
  getEmailAccountError: null,
  isUpdatingEmail: false,
  updatedEmail: false,
  updateEmailError: null,
  isCreatingEmail: false,
  createdEmail: false,
  createEmailError: null
};

export default handleActions(
  {
    [getCurrentEmailAccountRequest](state) {
      return {
        ...state,
        emailAccount: null,
        isLoadingEmailAccount: true,
        getEmailAccountError: null,
      };
    },
    [getCurrentEmailAccountSuccess](state, { payload }) {
      return {
        ...state,
        emailAccount: payload,
        isLoadingEmailAccount: false,
        getEmailAccountError: null,
      };
    },
    [getCurrentEmailAccountError](state, { payload }) {
      return {
        ...state,
        emailAccount: null,
        isLoadingEmailAccount: false,
        getEmailAccountError: payload,
      };
    },
    [updateEmailAccountRequest](state) {
      return {
        ...state,
        isUpdatingEmail: true,
        updateEmailError: null,
        updatedEmail: false
      };
    },
    [updateEmailAccountSuccess](state, { payload }) {
      return {
        ...state,
        isUpdatingEmail: false,
        updateEmailError: null,
        updatedEmail: true
      };
    },
    [updateEmailAccountError](state, { payload }) {
      return {
        ...state,
        isUpdatingEmail: false,
        updateEmailError: payload,
        updatedEmail: false
      };
    },
    [createEmailAccountRequest](state) {
      return {
        ...state,
        isCreatingEmail: true,
        createEmailError: null,
        createdEmail: false
      };
    },
    [createEmailAccountSuccess](state, { payload }) {
      return {
        ...state,
        isCreatingEmail: false,
        createEmailError: null,
        createdEmail: true
      };
    },
    [createEmailAccountError](state, { payload }) {
      return {
        ...state,
        isCreatingEmail: false,
        createEmailError: payload,
        createdEmail: false
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);