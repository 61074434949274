import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { FormSelectField } from '@/shared/components/form/material-controls/SelectField';
import { FormDateField } from '@/shared/components/form/material-controls/DateField';
import { cleanup, getWorkflowRoles, getPriorities, getRolesWithUsers } from '@/reducers/activity/generalInfo/generalInfoAction';
import { enqueueSnackbar } from 'notistack';
import { useOutletContext } from 'react-router-dom';
import { Box, Button, Card, CardContent, CircularProgress, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { cleanup as cleanupRemediation, getRemediation, getContextualScores, calculatePerformance, getClassifications, getSchema, getListForRemediation,  getDomains, getRatingScores, addRemediation, updateRemediation } from "@/reducers/activity/assetVulnerabilityList/assetVulnerabilityListAction";
import GeneralInfoFields from './generalInfoFields';
import { useForm } from 'react-hook-form';
import BasicFilters from '../../EditStep/VMSteps/components/basicFilters';
import PerformancePanel from '../../EditStep/VMSteps/components/performancePanel';
import AssetVulnerabilityTable from '../../EditStep/VMSteps/components/assetVulnerabilityTable';
import SearchIcon from '@mui/icons-material/Search';

const GeneralInfoVulnerability = () => {
  
  const dispatch = useDispatch();
  const {activityTypeId, control, handleClickCreate, createLabel, setValue} = useOutletContext();

  const { 
    roles, isLoadingRoles, getRolesError,
    priorities, isLoadingPriorities, getPrioritiesError,
    rolesWithUsers, isLoadingRolesWithUsers, getRolesWithUsersError
  } = useSelector(({ generalInfo }) => generalInfo);

  const { 
    classifications, isLoadingClassification, getClassificationsError,
    // schema, isLoadingSchema, getSchemaError,
    // domains, isLoadingDomains, getDomainsError,
    ratingScores, isLoadingRatingScores, getRatingScoresError,
    contextualScores, isLoadingContextualScore, getContextualScoresError,
    assetVulnerabilities, isLoadingAssetVulnerabilities, getAssetVulnerabilitiesError,
    performance, isLoadingPerformance, getPerformanceError
  } = useSelector(({ assetVulnerabilityList }) => assetVulnerabilityList);

  const { 
    handleSubmit, 
    reset, 
    control: controlVulnerabilities, 
    watch, 
    setValue: setValueVulnerabilities, 
    getValues 
  } = useForm();

  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    dispatch(getWorkflowRoles(activityTypeId));
    dispatch(getPriorities());
    
    dispatch(getClassifications());
    // dispatch(getSchema());
    // dispatch(getDomains());
    dispatch(getRatingScores());
    dispatch(getContextualScores());

    return () => {
      dispatch(cleanupRemediation());
      dispatch(cleanup());
    }
  }, []);

  const submitButton = useRef(null);

  useEffect(() => {
    if(getRolesError){
      enqueueSnackbar(getRolesError, {variant: "error"});
    }
  }, [getRolesError]);

  useEffect(() => {
    if(getPrioritiesError){
      enqueueSnackbar(getPrioritiesError, {variant: "error"});
    }
  }, [getPrioritiesError]);

  useEffect(() => {
    if(getRolesWithUsersError){
      enqueueSnackbar(getRolesWithUsersError, {variant: "error"});
    }
  }, [getRolesWithUsersError]);

  useEffect(() => {
    if(roles && roles.length){
      dispatch(getRolesWithUsers(roles));
    }    
  }, [roles]);

//   useEffect(() => {
//     if(getDomainsError){
//       enqueueSnackbar(getDomainsError, {variant: "error"});
//     }
//   }, [getDomainsError]);

  useEffect(() => {
    if(getContextualScoresError){
      enqueueSnackbar(getContextualScoresError, {variant: "error"});
    }
  }, [getContextualScoresError]);

  useEffect(() => {
    if(getClassificationsError){
      enqueueSnackbar(getClassificationsError, {variant: "error"});
    }
  }, [getClassificationsError]);

  useEffect(() => {
    if(getPerformanceError){
      enqueueSnackbar(getPerformanceError, {variant: "error"});
    }
  }, [getPerformanceError]);

//   useEffect(() => {
//     if(getSchemaError){
//       enqueueSnackbar(getSchemaError, {variant: "error"});
//     }
//   }, [getSchemaError]);

  useEffect(() => {
    if(getRatingScoresError){
      enqueueSnackbar(getRatingScoresError, {variant: "error"});
    }
  }, [getRatingScoresError]);

  useEffect(() => {
    if(getAssetVulnerabilitiesError){
      enqueueSnackbar(getAssetVulnerabilitiesError, {variant: "error"});
    }
  }, [getAssetVulnerabilitiesError]);

  const getFilters = (data) => {
    var keyValues = {};

    if(data.asset){
      keyValues["asset.Name"] = data.asset;
    }

    if(data.vulnerability){
      keyValues["vulnerability.name"] = data.vulnerability;
    }

    if(data.cve){
      keyValues["vulnerability.cve"] = data.cve;
    }

    if(data.detail){
      keyValues["detail"] = data.detail;
    }

    if(data.severity && data.severity.length){
      keyValues["vulnerability.ratingScoreId"] = data.severity;
    }

    if(data.domain && data.domain.length){
      keyValues["domainId"] = data.domain;
    }

    if(data.createData){
      keyValues["createData"] = new Date(data.createData);
    }

    if(data.dynamicFilter && data.dynamicFilter.length){
      data.dynamicFilter.forEach(f => {
        keyValues[f.key] = f.value;
      });
    }

    return keyValues;
  }

  const onSubmit = (data) => {
    var keyValues = getFilters(data);

    const finalData = {
      dictionary: keyValues,
      epssMin: data.threshold[0],
      epssMax: data.threshold[1],
      epss: data.enableEpss,
      baseScoreId: data.baseScore,
      contextualScoreId: data.contextualScore,
      exploited: data.exploited
    };
    
    dispatch(getListForRemediation(finalData));
  }

  const onCalculatePerformance = (data) => {
    var keyValues = getFilters(data);

    const finalData = {
      dictionary: keyValues,
      epssMin: data.threshold[0],
      epssMax: data.threshold[1],
      epss: data.enableEpss,
      baseScoreId: data.baseScore,
      contextualScoreId: data.contextualScore,
      exploited: data.exploited
    };

    dispatch(calculatePerformance(finalData));
  };

  const onSetSelectedRows = (selectionModel) => {
    setValue("ids", selectionModel);
    setSelectedRows(selectionModel);
  };

  const handleCreate = () => {
    if(!selectedRows.length){
      enqueueSnackbar("Selezionare almeno 1 vulnerabilità", {variant: "error"});
    }
    else {
      handleClickCreate();
    }
  };

  return (
    <>
        <GeneralInfoFields 
            control={control}
            priorities={priorities}
            isLoadingRolesWithUsers={isLoadingRolesWithUsers}
            rolesWithUsers={rolesWithUsers}
            isLoadingRoles={isLoadingRoles}
            roles={roles}
        />
        
        <Row>
            <Col md={12}>
                <BasicFilters
                    control={controlVulnerabilities}
                    ratingScores={ratingScores}
                    contextualScores={contextualScores}
                    watch={watch}
                    getValues={getValues}
                    setValue={setValueVulnerabilities}
                />
            </Col>

            {/* <Col md={12}>
            <MaterialDynamicFilters controller="AssetVulnerability" schema={schema} control={control} name="dynamicFilter" />
            </Col> */}

            <Col md={12}>
                <PerformancePanel 
                    isLoading={isLoadingPerformance} 
                    onCalculate={handleSubmit(onCalculatePerformance)}
                    performance={performance}
                />
            </Col>

        </Row>

        <Box style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBlock: 20}}>
            <Button ref={submitButton} disabled={isLoadingAssetVulnerabilities} color="primary" size='medium' variant="contained" onClick={handleSubmit(onSubmit)} endIcon={<SearchIcon/>}>Search</Button>
        </Box>

        <AssetVulnerabilityTable 
            classifications={classifications}
            ratingScores={ratingScores}
            contextualScores={contextualScores}
            setSelectedRows={onSetSelectedRows}
            selectedRows={selectedRows}
            isLoadingAssetVulnerabilities={isLoadingAssetVulnerabilities}
            assetVulnerabilities={assetVulnerabilities}
        />

        { createLabel &&
            <Row> 
                <Col sm={12}>
                    <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
                        <Button variant="contained" onClick={handleCreate}>{createLabel}</Button>  
                    </div>
                </Col>
            </Row>
        }
    </>
  );
}

export default GeneralInfoVulnerability;