import { DataGrid, GridActionsCellItem, GridToolbar, GridRowModes, GridRowEditStopReasons, useGridApiRef } from "@mui/x-data-grid";
import React, { useState } from "react";
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import { Col, Row } from "react-bootstrap";
import { Button, CircularProgress, Typography } from "@mui/material";
import { SelectCell } from '@/shared/components/datagrid/SelectCell';
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { cleanup, getAssemblyCollectionList } from "@/reducers/services/serviceConnectorsTable/serviceConnectorsAction";

const ServiceConnectorsTable = forwardRef(({assemblyCollections, connectors, isEdit}, ref) => {
    
  const dispatch = useDispatch();
  const { 
    assemblyCollectionList
  } = useSelector(({ serviceConnectorsTable }) => serviceConnectorsTable);
  
  const collectionsClon = JSON.parse(JSON.stringify(assemblyCollections)).map(a => {
    return {
      id: a.id, 
      connectorName: connectors.find(c => c.key === a.connectorId).name, 
      assemblyCollectionName: null, 
      startAssemblyCollectionId: a.assemblyCollectionId,
      order: a.order, 
      enabled: a.enabled,
      isNew: false,
      isCreated: false
    }
  });
  
  const [selectedRows, setSelectedRows] = useState([]);
  const [rows, setRows] = useState(collectionsClon);
  const [rowModesModel, setRowModesModel] = useState({});

  const apiRef = useGridApiRef();

  useImperativeHandle(ref, () => ({
    getData: () => {
      return rows.map(r => {
        const connectorId = connectors.find(a => a.name === r.connectorName).key;
        const row = {
          connectorId: connectorId,
          assemblyCollectionId: assemblyCollectionList.find(a => a.connectorId === connectorId)
                                                      ?.assemblyCollection?.find(a => a.name === r.assemblyCollectionName)
                                                      .id,
          enabled: r.enabled,
          order: r.order
        };

        if(!r.isCreated){
          row.id = r.id;
        }

        return row;
      });
    }
  }));

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    }
  }, []);

  useEffect(() => {
    if(assemblyCollections && assemblyCollections.length){
      var calledConnectors = [];
      assemblyCollections.forEach(sc => {
        if(!calledConnectors.includes(sc.connectorId)){
          calledConnectors.push(sc.connectorId);
          dispatch(getAssemblyCollectionList(sc.connectorId));
        }
      });
    }
  }, [assemblyCollections, dispatch]);

  useEffect(() => {
    if(isEdit && assemblyCollectionList && assemblyCollectionList.length){
      var oldRows = [...rows];
      oldRows.forEach(r => {
        const foundAssemblyCollectionName = assemblyCollectionList
          .find(a => a.connectorId === connectors
            .find(c => c.name === r.connectorName)
            ?.key
          )?.assemblyCollection?.find(a => a.id === r.startAssemblyCollectionId)
          ?.name;

        if(foundAssemblyCollectionName){
          r.assemblyCollectionName = foundAssemblyCollectionName;
          r.startAssemblyCollectionId = null;
        }
      });
      setRows(oldRows);
    }
  }, [assemblyCollectionList, isEdit]);

  const handleAddConnectorClick = () => {
      const id = makeid(5);
      setRows((oldRows) => {return [...oldRows, { 
          id, 
          connectorName: null,
          assemblyCollectionName: null, 
          order: 1, 
          enabled: true,
          isNew: true,
          isCreated: true
      }]});

      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'connectorName' },
      }));
    };

  const handleRowEditStop = (params, event) => {
      if (params.reason === GridRowEditStopReasons.rowFocusOut) {
        event.defaultMuiPrevented = true;
      }
    };
  
    const handleEditClick = (id) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };
  
    const handleSaveClick = (id) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };
  
    const handleDeleteClick = (id) => () => {
      setRows(rows.filter((row) => row.id !== id));
    };
  
    const handleCancelClick = (id) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
  
      const editedRow = rows.find((row) => row.id === id);
      if (editedRow.isNew) {
        setRows(rows.filter((row) => row.id !== id));
      }
    };
  
    const processRowUpdate = (newRow) => {
      const updatedRow = { ...newRow, isNew: false };
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    };
  
    const handleRowModesModelChange = (newRowModesModel) => {
      setRowModesModel(newRowModesModel);
    };

    const onChangeConnectorCell = (newConnector, cellData) => {  
      apiRef.current.setEditCellValue({
        id: cellData.id,
        field: "connectorName",
        value: newConnector.name,
      });

      if(!assemblyCollectionList.includes(a => a.connectorId === newConnector.key)){
        dispatch(getAssemblyCollectionList(newConnector.key));
      }
    };

    const onChangeAssemblyCollectionCell = (newAssemblyCollection, cellData) => {  
      apiRef.current.setEditCellValue({
        id: cellData.id,
        field: "assemblyCollectionName",
        value: newAssemblyCollection.name,
      });
    };
    
    const columns = [
    { 
      field: 'connectorName',
      headerName: 'Connector',
      minWidth: 350,
      flex: 1,
      editable: true,
      renderEditCell: (params) => {
        return(
          <SelectCell
            value={connectors.find(a => a.name === params.value)}
            options={connectors}
            onChange={(e, data) => onChangeConnectorCell(data, params)}
          />
        );
      },
    },
    { 
        field: 'assemblyCollectionName',
        headerName: 'Function',
        minWidth: 350,
        flex: 1,
        editable: true,
        renderEditCell: (params) => {
          const selectedConnector = params.row.connectorName;
          var collection = null;

          if(selectedConnector){
            collection = assemblyCollectionList.find(
              a => a.connectorId === connectors.find(
                c => c.name === params.row.connectorName)
                ?.key
            );
          }

          return( 
            !params.row.connectorName || !collection ? <>Select a connector first</> :
            collection.isLoadingAssemblyCollection ?
            <div style={{height: '100px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><CircularProgress /></div> :
            <SelectCell
              value={collection.assemblyCollection.find(a => a.name === params.value)}
              options={collection.assemblyCollection}
              onChange={(e, data) => onChangeAssemblyCollectionCell(data, params)}
            />
          );
        },
    },
    { 
        field: 'order', 
        headerName: 'Order', 
        type: 'number',
        minWidth: 150,
        editable: true
    },
    { 
        field: 'enabled', 
        headerName: 'Enabled', 
        type: 'boolean',
        minWidth: 150,
        editable: true
    },
    {
        field: 'actions',
        type: 'actions',
        width: 80,
        getActions: ({ id }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
    
            if (isInEditMode) {
              return [
                <GridActionsCellItem
                  icon={<SaveIcon />}
                  label="Save"
                  sx={{
                    color: 'primary.main',
                  }}
                  onClick={handleSaveClick(id)}
                />,
                <GridActionsCellItem
                  icon={<CancelIcon />}
                  label="Cancel"
                  className="textPrimary"
                  onClick={handleCancelClick(id)}
                  color="inherit"
                />,
              ];
            }
    
            return [
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(id)}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={handleDeleteClick(id)}
                color="inherit"
              />,
            ];
          },
        },
    ];

    return(
        <Row>
            <Col md={12}>
                <Typography variant='subtitle1'>Connectors</Typography>
                
                <Button style={{marginBlock: 10}} size='large' variant="contained" onClick={handleAddConnectorClick} endIcon={<AddIcon/>}> Add Connector</Button>
                
                <div style={{'height': 600, width: '100%'}}>
                  <DataGrid 
                      apiRef={apiRef}
                      checkboxSelection 
                      disableRowSelectionOnClick
                      editMode="row"
                      onRowSelectionModelChange={(newRowSelectionModel) => {
                          setSelectedRows(newRowSelectionModel);
                      }}
                      rowSelectionModel={selectedRows}
                      rows={rows}
                      rowModesModel={rowModesModel}
                      onRowModesModelChange={handleRowModesModelChange}
                      onRowEditStop={handleRowEditStop}
                      processRowUpdate={processRowUpdate}
                      columns={columns}
                      slots={{ toolbar: GridToolbar }} 
                      slotProps={{
                          toolbar: {
                              showQuickFilter: true
                          },
                      }}
                      initialState={{
                          pagination: { paginationModel: { pageSize: 30 } },
                      }}
                      pageSizeOptions={[15, 30, 50, 100]}
                  />
                </div>
            </Col>
        </Row>
        
    );
});

function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

export default ServiceConnectorsTable;