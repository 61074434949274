import { Backdrop, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Slider, TextField, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { cleanup, addDomain } from "@/reducers/domains/addDomain/addDomainAction";
import { FormMultiSelectField } from '@/shared/components/form/material-controls/MultiSelectField';
import { FormSelectField } from '@/shared/components/form/material-controls/SelectField';

const CreateDomainModal = ({ open, onHideModal, onDomainCreated, domains, roles }) => {
    const dispatch = useDispatch();
  
    const { 
      isAddingDomain, addDomainError, addedDomain
    } = useSelector(({ addDomain }) => addDomain);

    useEffect(() => {
      return () => {
        dispatch(cleanup());
      }
    }, [dispatch]);
  
    const { handleSubmit, reset, control, setValue, getValues } = useForm();
  
    const handleClose = () => {
      reset();
      onHideModal();
    }
  
    useEffect(() => {
      if(addDomainError){
        enqueueSnackbar(addDomainError, {variant: "error"});
      }
    }, [addDomainError]);
  
    const onSubmit = (data) => { 
      data.description = { it: data.descriptionTraslated, en: data.descriptionTraslated};
      
      dispatch(addDomain(data));
    }
  
    useEffect(() => {
      if(addedDomain){
        enqueueSnackbar("Domain created successfully", {variant: "success"});
        handleClose();
        onDomainCreated();
      }
    }, [addedDomain]);
  
    return (
      <Dialog
          fullWidth
          maxWidth='lg'
          scroll='paper'
          open={open}
          onClose={handleClose}
      >
          <DialogTitle>Create Domain</DialogTitle>
          <DialogContent>
            <Row>
              <Col md={12}>
                <FormTextField
                    name='name'
                    label='Name'
                    control={control}
                    rules={{ required: 'This field is required' }}
                />
              </Col>
              <Col md={12}>
                <FormTextField
                    name='descriptionTraslated'
                    label='Description'
                    control={control}
                    multiline
                    rows={4}
                />
              </Col>
              <Col md={6}>
              <FormTextField
                  name='key'
                  label='Key'
                  control={control}
                  rules={{ required: 'This field is required' }}
                />
              </Col>   
              <Col md={6}>
                <FormTextField
                    name='value'
                    label='Value'
                    control={control}
                    rules={{ required: 'This field is required' }}
                />
              </Col>
              <Col md={6}>
                <FormSelectField
                    name='parent'
                    label='Parent'
                    control={control}
                    options={domains}
                    keyValue={{id:"id", label:"name"}}
                />
              </Col>
              <Col md={6}>
                <FormMultiSelectField
                    name='roles'
                    label='Roles'
                    control={control}
                    options={roles}
                    keyValue={{id:"id", label:"name"}}
                />
              </Col>
            </Row>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant='contained' color="success" onClick={handleSubmit(onSubmit)}>Create</Button>
          </DialogActions>
  
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isAddingDomain}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
      </Dialog>
    );
  }
  
  export default CreateDomainModal;