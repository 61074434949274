import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, Typography, Backdrop, CircularProgress, Box, Divider, Icon } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Col, Row } from 'react-bootstrap';
import { CircularProgressWrapper, FlexCenteredText } from '@/shared/components/CircularProgressWrapper';
import PerformancePercent from './performancePercent';
import PerformanceSquare from './performanceSquare';
import PerformanceRadar from './performanceRadar';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PerformanceBars from './performanceBars';

const PerformancePanel = ({
    isLoading,
    onCalculate,
    performance
}) => {

    const [open, setOpen] = useState(false);

    const onClickCalculate = (e) => {
        e.stopPropagation();
        setOpen(true);
        onCalculate();
    }

    const {t} = useTranslation("common");

    return(
        <Accordion style={{marginTop: 20}} expanded={open} onChange={() => setOpen(!open)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
            <Typography>Performance <Button 
                                    style={{marginLeft:10}} 
                                    variant='outlined'
                                    disabled={isLoading}
                                    onClick={onClickCalculate}>
                                        Calculate
                                    </Button>
            </Typography>
            </AccordionSummary>
            <AccordionDetails>
            { isLoading ? <CircularProgressWrapper /> :
                !performance ?  <FlexCenteredText>Click on Calculate to see the performance with applied filters.</FlexCenteredText> :
                
                <Row>
                    <Col md={6}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                            width: '100%',
                            marginBottom: 4
                        }}>
                            <Box sx={{textAlign: 'center'}}>
                                <Typography marginBottom={1} fontWeight="bold" fontSize="18px">Effort</Typography>
                                <PerformancePercent value={performance.effort * 100} />
                            </Box>
                            <Box sx={{textAlign: 'center'}}>
                                <Typography marginBottom={1} fontWeight="bold" fontSize="18px">Efficiency</Typography>
                                <PerformancePercent value={performance.efficiency * 100} />
                            </Box>
                            <Box sx={{textAlign: 'center'}}>
                                <Typography marginBottom={1} fontWeight="bold" fontSize="18px">Coverage</Typography>
                                <PerformancePercent value={performance.coverage * 100} />
                            </Box>
                        </Box>

                        <Box>
                            <ul>
                                <li>
                                    <Typography marginBottom={1}>
                                        {t('app.vulnerabilityManagement.effortExplanation1')}
                                        <b>{t('app.vulnerabilityManagement.effortExplanation2')}</b>
                                        {t('app.vulnerabilityManagement.effortExplanation3')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography marginBottom={1}>
                                        {t('app.vulnerabilityManagement.efficiencyExplanation1')}
                                        <b>{t('app.vulnerabilityManagement.efficiencyExplanation2')}</b>
                                        {t('app.vulnerabilityManagement.efficiencyExplanation3')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography marginBottom={1}>
                                        {t('app.vulnerabilityManagement.coverageExplanation1')}
                                        <b>{t('app.vulnerabilityManagement.coverageExplanation2')}</b>
                                        {t('app.vulnerabilityManagement.coverageExplanation3')}
                                    </Typography>
                                </li>
                            </ul>
                        </Box>
                        {/* <PerformanceSquare performance={performance} /> */}
                        </Box>

                    </Col>
                    <Col md={6}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%'
                        }}>
                            {/* <PerformanceRadar performance={performance} /> */}
                            <PerformanceBars performance={performance} />
                        </Box>
                    </Col>
                </Row>
            }
            </AccordionDetails>
        </Accordion>
);
};

export default PerformancePanel;