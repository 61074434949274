import { Backdrop, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { cleanup, updateVulnerabilityScore } from "@/reducers/vulnerabilityScores/updateVulnerabilityScore/updateVulnerabilityScoreAction";
import { FormColorField } from "@/shared/components/form/material-controls/ColorField";
import { FormRangeSliderField } from "@/shared/components/form/material-controls/RangeSliderField";

const EditVulnerabilityScoreModal = ({ open, onHideModal, onVulnerabilityScoreUpdated, vulnerabilityScore }) => {
    const dispatch = useDispatch();
  
    const { 
      isUpdatingVulnerabilityScore, updateVulnerabilityScoreError, updatedVulnerabilityScore
    } = useSelector(({ updateVulnerabilityScore }) => updateVulnerabilityScore);

    useEffect(() => {
      return () => {
        dispatch(cleanup());
      }
    }, [dispatch]);
  
    const { handleSubmit, reset, control, setValue, getValues } = useForm();
  
    const handleClose = (event, reason) => {
      if (reason && reason === "backdropClick") 
          return;
        
      reset();
      onHideModal();
    }
  
    useEffect(() => {
      if(updateVulnerabilityScoreError){
        enqueueSnackbar(updateVulnerabilityScoreError, {variant: "error"});
      }
    }, [updateVulnerabilityScoreError]);
  
    const onSubmit = (data) => {
      data.id = vulnerabilityScore.id;
      data.minValue = data.value[0];
      data.maxValue = data.value[1];

      dispatch(updateVulnerabilityScore(data));
    }
  
    useEffect(() => {
      if(updatedVulnerabilityScore){
        enqueueSnackbar("Vulnerability Score updated successfully", {variant: "success"});
        handleClose();
        onVulnerabilityScoreUpdated();
      }
    }, [updatedVulnerabilityScore]);
  
    return (
      <Dialog
          fullWidth={true}
          maxWidth='lg'
          scroll='paper'
          open={open}
          onClose={handleClose}
      >
          <DialogTitle>Edit Vulnerability Score</DialogTitle>
          <DialogContent>
                <Row>
                  <Col md={12}>
                    <FormTextField
                        name='name'
                        label='Name'
                        control={control}
                        rules={{ required: 'This field is required' }}
                        defaultValue={vulnerabilityScore.Name}
                    />
                  </Col>
                  <Col md={6}>
                    <FormRangeSliderField 
                      name='value'
                      label='Value'
                      control={control}
                      min={0}
                      max={10}
                      step={0.1}
                      defaultValue={[vulnerabilityScore.minValue, vulnerabilityScore.maxValue]}
                    />
                  </Col>
                  <Col md={6}>
                    <FormColorField 
                        name='color'
                        label='Color'
                        control={control}
                        defaultValue={vulnerabilityScore.color}
                    />
                  </Col>
                </Row>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant='contained' color="success" onClick={handleSubmit(onSubmit)}>Edit</Button>
          </DialogActions>
  
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isUpdatingVulnerabilityScore}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
      </Dialog>
    );
  }
  
  export default EditVulnerabilityScoreModal;