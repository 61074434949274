import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { cleanup, getRemediation, getContextualScores, calculatePerformance, getClassifications, getSchema, getListForRemediation,  getDomains, getRatingScores, addRemediation, updateRemediation } from "@/reducers/activity/assetVulnerabilityList/assetVulnerabilityListAction";
import { useForm } from "react-hook-form";
import { Button, Backdrop, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { useOutletContext } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { useRef } from 'react';
import PerformancePanel from './components/performancePanel';
import BasicFilters from './components/basicFilters';
import AssetVulnerabilityTable from './components/assetVulnerabilityTable';

const AssetVulnerabilityList = () => {
  const dispatch = useDispatch();

  const submitButton = useRef(null);

  const { 
    activity, 
    workflowActivityStepCompletedId, 
    isEditor,
    handleRefreshProgress } = useOutletContext();

  const { 
    classifications, isLoadingClassification, getClassificationsError,
    remediation, isLoadingRemediation, getRemediationError,
    schema, isLoadingSchema, getSchemaError,
    domains, isLoadingDomains, getDomainsError,
    ratingScores, isLoadingRatingScores, getRatingScoresError,
    contextualScores, isLoadingContextualScore, getContextualScoresError,
    assetVulnerabilities, isLoadingAssetVulnerabilities, getAssetVulnerabilitiesError,
    isAddingRemediation, addRemediationError, addedRemediation,
    isUpdatingRemediation, updateRemediationError, updatedRemediation,
    performance, isLoadingPerformance, getPerformanceError
  } = useSelector(({ assetVulnerabilityList }) => assetVulnerabilityList);

  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    dispatch(getRemediation(activity.workflowActivityId));
    dispatch(getClassifications());
    dispatch(getSchema());
    dispatch(getDomains());
    dispatch(getRatingScores());
    dispatch(getContextualScores());

    return () => {
      dispatch(cleanup());
    }
  }, [dispatch]);

  const { handleSubmit, reset, control, watch, setValue, getValues } = useForm();

  
  useEffect(() => {
    if(getDomainsError){
      enqueueSnackbar(getDomainsError, {variant: "error"});
    }
  }, [getDomainsError]);

  useEffect(() => {
    if(getContextualScoresError){
      enqueueSnackbar(getContextualScoresError, {variant: "error"});
    }
  }, [getContextualScoresError]);

  useEffect(() => {
    if(getClassificationsError){
      enqueueSnackbar(getClassificationsError, {variant: "error"});
    }
  }, [getClassificationsError]);

  useEffect(() => {
    if(getPerformanceError){
      enqueueSnackbar(getPerformanceError, {variant: "error"});
    }
  }, [getPerformanceError]);

  useEffect(() => {
    if(getSchemaError){
      enqueueSnackbar(getSchemaError, {variant: "error"});
    }
  }, [getSchemaError]);

  useEffect(() => {
    if(getRatingScoresError){
      enqueueSnackbar(getRatingScoresError, {variant: "error"});
    }
  }, [getRatingScoresError]);

  useEffect(() => {
    if(getAssetVulnerabilitiesError){
      enqueueSnackbar(getAssetVulnerabilitiesError, {variant: "error"});
    }
  }, [getAssetVulnerabilitiesError]);

  useEffect(() => {
    if(addRemediationError){
      enqueueSnackbar(addRemediationError, {variant: "error"});
    }
  }, [addRemediationError]);

  useEffect(() => {
    if(addedRemediation){
      enqueueSnackbar("Remediation created successfully", {variant: "success"});
      handleRefreshProgress();
      submitButton.current.click();
    }
  }, [addedRemediation]);

  useEffect(() => {
    if(updateRemediationError){
      enqueueSnackbar(updateRemediationError, {variant: "error"});
    }
  }, [updateRemediationError]);

  useEffect(() => {
    if(updatedRemediation){
      enqueueSnackbar("Remediation items updated successfully", {variant: "success"});
      handleRefreshProgress();
      submitButton.current.click();
    }
  }, [updatedRemediation]);

  const handleClickAddToRemediation = () => {
    if(remediation) {
      const finalData = {
        remediationId: remediation.id,
        ids: selectedRows
      };

      dispatch(updateRemediation(finalData));
    }
    else {
      const finalData = {
        workflowActivityId: activity.workflowActivityId,
        workflowActivityStepCompletedId,
        assetVulnerabilityIds: selectedRows
      };
      
      dispatch(addRemediation(finalData));
    }
  };

  const getFilters = (data) => {
    var keyValues = {};

    if(data.asset){
      keyValues["asset.Name"] = data.asset;
    }

    if(data.vulnerability){
      keyValues["vulnerability.name"] = data.vulnerability;
    }

    if(data.cve){
      keyValues["vulnerability.cve"] = data.cve;
    }

    if(data.detail){
      keyValues["detail"] = data.detail;
    }

    if(data.severity && data.severity.length){
      keyValues["vulnerability.ratingScoreId"] = data.severity;
    }

    if(data.domain && data.domain.length){
      keyValues["domainId"] = data.domain;
    }

    if(data.createData){
      keyValues["createData"] = new Date(data.createData);
    }

    if(data.dynamicFilter && data.dynamicFilter.length){
      data.dynamicFilter.forEach(f => {
        keyValues[f.key] = f.value;
      });
    }

    return keyValues;
  }

  const onSubmit = (data) => {
    var keyValues = getFilters(data);

    const finalData = {
      dictionary: keyValues,
      epssMin: data.threshold[0],
      epssMax: data.threshold[1],
      epss: data.enableEpss,
      baseScoreId: data.baseScore,
      contextualScoreId: data.contextualScore,
      exploited: data.exploited
    };
    
    dispatch(getListForRemediation(finalData));
  }

  const onCalculatePerformance = (data) => {
    var keyValues = getFilters(data);

    const finalData = {
      dictionary: keyValues,
      epssMin: data.threshold[0],
      epssMax: data.threshold[1],
      epss: data.enableEpss,
      baseScoreId: data.baseScore,
      contextualScoreId: data.contextualScore,
      exploited: data.exploited
    };

    dispatch(calculatePerformance(finalData));
  };

  return (
    <React.Fragment>
      <Row>
        <Col md={12}>
          <BasicFilters
            control={control}
            ratingScores={ratingScores}
            contextualScores={contextualScores}
            watch={watch}
            getValues={getValues}
            setValue={setValue}
          />
        </Col>

        {/* <Col md={12}>
          <MaterialDynamicFilters controller="AssetVulnerability" schema={schema} control={control} name="dynamicFilter" />
        </Col> */}

        <Col md={12}>
            <PerformancePanel 
              isLoading={isLoadingPerformance} 
              onCalculate={handleSubmit(onCalculatePerformance)}
              performance={performance}
            />
        </Col>

      </Row>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBlock: 20}}>
          <div>
            { isEditor && 
              <Button disabled={isLoadingAssetVulnerabilities || !selectedRows.length} color="success" size='large' variant="contained" onClick={handleClickAddToRemediation} endIcon={<AddIcon/>}>{remediation ? "Add to remediation" : "Create remediation"}</Button>
            }
          </div>
          <Button ref={submitButton} disabled={isLoadingAssetVulnerabilities} color="primary" size='medium' variant="contained" onClick={handleSubmit(onSubmit)} endIcon={<SearchIcon/>}>Search</Button>
      </div>

      <AssetVulnerabilityTable 
        classifications={classifications}
        ratingScores={ratingScores}
        contextualScores={contextualScores}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        isLoadingAssetVulnerabilities={isLoadingAssetVulnerabilities}
        assetVulnerabilities={assetVulnerabilities}
      />

      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoadingRemediation || isAddingRemediation || isUpdatingRemediation}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
    </React.Fragment>
  );
}

export default AssetVulnerabilityList;