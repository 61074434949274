import { useParams } from "react-router-dom";
import RenderSurvey from "./components/renderSurvey";
import { useState } from "react";
import { Typography } from "@mui/material";

const SurveyTest = () => {

    const { id } = useParams();

    const [displayData, setDisplayData] = useState("");

    const onSurveyCompleted = (data) => {
        setDisplayData(JSON.stringify(data, null, 2));
    }

    const onSurveySaved = (data) => {
        setDisplayData(JSON.stringify(data, null, 2));
    }

    return(
        <>
        <RenderSurvey 
            templateId={id}
            onSurveyCompleted={onSurveyCompleted}
            onSurveySaved={onSurveySaved}
        />
            <div style={{marginTop: 20}}>
                <Typography variant="h5">
                    Json Result: 
                </Typography>
                <Typography>
                    <pre style={{
                        "padding-right": "0px", 
                        "padding-block": 20, 
                        "contain": "inline-size", 
                        "overflow-x": "auto"
                    }}>
                        {displayData}
                    </pre>
                </Typography>
            </div>
        </>
    );
}

export default SurveyTest;