import { Col, Row } from "react-bootstrap";
import ActivityCard from "./ActivityCard";
import { Card, CardContent, CircularProgress, Divider, List, Typography } from "@mui/material";
import ActivityListItem from "./ActivityListItem";
import { ViewTypeWrapper, ViewType } from "@/shared/components/ViewType";
import { useState } from "react";
import { useSelector } from "react-redux";

const ActivityList = ({
    activities=[],
    activityTypes,
    handleActivityEditClick,
    handleActivityDeleteClick,
    isLoading,
    steps,
    loadSteps,
}) => {

    const roles = useSelector(({ auth }) => auth.roles);
    const countsRoles = ["Administrator", "Manager", "TenantAdministrator", "SystemAdministrator", "Auditor"];
    const showOptions = roles.filter(value => countsRoles.includes(value)).length > 0;

    const startViewType = localStorage.getItem("activityViewType") ?? 'grid';
    const [viewType, setViewType] = useState(startViewType);

    const onChangeViewType = (newType) => {
        setViewType(newType);
        localStorage.setItem("activityViewType", newType);
    }

    return(
        <div style={{marginTop: '20px'}}>
            <Row>
                <Col md={12}>
                    <ViewTypeWrapper>
                        <ViewType 
                            viewType={viewType}
                            onChangeViewType={(newType) => onChangeViewType(newType)}
                        />
                    </ViewTypeWrapper>
                </Col>
            </Row>
            {!isLoading && activities && activities.length ? 
                viewType === 'grid' ? 
                <Row>
                    {activities.map((activity, index) => (
                        <Col md={4} style={{marginTop: 20}} key={index}>
                            <ActivityCard 
                                activity={activity} 
                                handleActivityEditClick={handleActivityEditClick}
                                handleActivityDeleteClick={handleActivityDeleteClick}
                                loadSteps={loadSteps}
                                activitySteps={steps.find(s => s.workflowActivityId === activity.workflowActivityId)}
                                showOptions={showOptions}
                                activityType={activityTypes.find(w => w.activityTypeId === activity.activityTypeId)}
                            />
                        </Col>
                    ))}
                </Row>
                :
                <Row>
                    <Col md={12}>
                        <List style={{marginTop: 20}}>
                            {activities.map((activity, index) => (
                                <>
                                    <ActivityListItem key={index}
                                        activity={activity} 
                                        handleActivityEditClick={handleActivityEditClick}
                                        handleActivityDeleteClick={handleActivityDeleteClick}
                                        loadSteps={loadSteps}
                                        activitySteps={steps.find(s => s.workflowActivityId === activity.workflowActivityId)}
                                        activityType={activityTypes.find(w => w.activityTypeId === activity.activityTypeId)}
                                        showOptions={showOptions}
                                    />
                                </>
                            ))}
                        </List>
                    </Col>
                </Row>
            :
            <div style={{height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                {isLoading ? <CircularProgress /> : 
                    <Typography>There are no activities to show.</Typography>
                }
            </div>
            }
        </div>
    );
};

export default ActivityList;