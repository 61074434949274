import React from "react";
import styled from 'styled-components';
import AdvancedFilters from "./advancedFilters";
import BasicFilters from "./basicFilters";
import PropTypes from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary, Box, Tab, Tabs, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useState } from "react";
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import LinkIcon from '@mui/icons-material/Link';
import TabPanel, { tabIndexProps } from "@/shared/components/MaterialTabPanel";
import InspectTab from "./inspectTab";

const AssetFilters = ({ 
  assetTypes, 
  handleClearAll,
  handleGetNodes, 
  handleGetByTarget, 
  handleGetBySource, 
  handleGetByLinkName, 
  handleGetByLinkValue,
  onExpandAll,
  onCollapseAll,
  hiddenNodes,
  nodes,
  onChangeHiddenNodes
}) => {  
  
  const [tab, setTab] = useState(0);
  
  const handleTabChange = (event, value) => {
    setTab(value);
  }
  
  return (
    <>
    <CollapseContainer>
      <ScrollableCollapse>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <AccordionTitle>
            <FilterAltIcon style={{marginRight: 10}}/>
            <Typography>Filters</Typography>
          </AccordionTitle>
        </AccordionSummary>
        <AccordionDetails>
          <Tabs 
            variant="fullWidth"
            value={tab} 
            onChange={handleTabChange}
          >
            <Tab 
              label="Assets"
              icon={<WorkspacesIcon />} 
              iconPosition="start" 
              {...tabIndexProps(0)}
            />
            <Tab 
              label="Relationships"
              icon={<LinkIcon />} 
              iconPosition="start" 
              {...tabIndexProps(1)}
            />
            <Tab 
              label="Inspect"
              icon={<VisibilityIcon />} 
              iconPosition="start" 
              {...tabIndexProps(2)}
            />
          </Tabs>
          <TabPanel value={tab} index={0}>
           <AdvancedFilters 
              assetTypes={assetTypes} 
              handleGetNodes={handleGetNodes} 
              handleClearAll={handleClearAll}
            />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <BasicFilters
              handleGetByTarget={handleGetByTarget}
              handleGetBySource={handleGetBySource}
              handleGetByLinkName={handleGetByLinkName}
              handleGetByLinkValue={handleGetByLinkValue}
            />
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <InspectTab
              onExpandAll={onExpandAll}
              onCollapseAll={onCollapseAll}
              hiddenNodes={hiddenNodes}
              nodes={nodes}
              onChangeHiddenNodes={onChangeHiddenNodes}
            />
          </TabPanel>
        </AccordionDetails>
      </ScrollableCollapse>
    </CollapseContainer>
    </>
  );
};

const CollapseContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ScrollableCollapse = styled(Accordion)`
  width: 600px;
  max-height: 820px;
  overflow-y: auto;
`;

const AccordionTitle = styled.div`
  display: flex;
  align-items: center;
`;

AssetFilters.propTypes = {
  assetTypes: PropTypes.array.isRequired, 
  handleGetAllPath: PropTypes.func, 
  handleGetParentChild: PropTypes.func, 
  handleGetByTarget: PropTypes.func, 
  handleGetBySource: PropTypes.func, 
  handleGetByLinkName: PropTypes.func, 
  handleGetByLinkValue: PropTypes.func,
  handleOpenFieldValueModal: PropTypes.func
};

export default AssetFilters;