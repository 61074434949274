import * as type from "../types";
import { instance } from "../../../config/interceptor";
import { enqueueSnackbar } from "notistack";
import { Link as ReactRouterLink, useNavigate, useParams } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import { getTokenRoles } from "../../../utils/helpers";


export const twoFactorLogin = (values) => async (dispatch) => {

    try{
      await dispatch({
        type: type.LOGIN,
        token: null,
        refreshToken: null,
        isLoggedIn: false,
        isLogging: true,
        urlRedirect: null
      });
  
      const resp = await instance.post("/v1/Account/TwoFactorLogin", values);
      if (!resp.data.token) {
        await dispatch({
          type: type.LOGIN,
          token: null,
          refreshToken: null,
          isLoggedIn: false,
          isLogging: false,
          urlRedirect: null
        });
      } 
      else {
        localStorage.setItem("accessToken", resp.data.token);
        localStorage.setItem("refreshToken", resp.data.refreshToken);
        
        const tokenPayload = jwtDecode(localStorage.getItem("accessToken"));

        await dispatch({
          type: type.LOGIN2FA,
          token: localStorage.getItem("accessToken"),
          refreshToken: localStorage.getItem("refreshToken"),
          isLoggedIn: true,
          tokenPayload: tokenPayload,
          roles: getTokenRoles(tokenPayload)
        });
      }
    }
    catch(e){
      await dispatch({
        type: type.LOGIN,
        token: null,
        refreshToken: null,
        isLoggedIn: false,
        isLogging: false,
        urlRedirect: null
      });
  
      if(e.response && e.response.data && e.response.data.description){
        enqueueSnackbar(e.response.data.description, {variant: "error"});
      }
      else if(e.response && e.response.data && e.response.data.description){
        enqueueSnackbar(e.response.data.description, {variant: "error"});
      }
    }
  };