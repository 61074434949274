import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_TENANT_CHANGE_LOGO');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const updateLogoRequest = createAction('TENANT_CHANGE_LOGO_REQUEST');
export const updateLogoSuccess = createAction('TENANT_CHANGE_LOGO_SUCCESS');
export const updateLogoError = createAction('TENANT_CHANGE_LOGO_ERROR');

export const updateLogo = (formData) => async (dispatch) => {
    try {
      dispatch(updateLogoRequest());
      const resp = await instance.post(`/v1/Tenant/ChangeLogo`, 
        formData, 
        { 
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      
      dispatch(updateLogoSuccess(resp.data));
  
    } catch (e) {
      if(e.response && e.response.data && e.response.data.description)
        dispatch(updateLogoError(e.response.data.description));
      else
        dispatch(updateLogoError(e.message));
    }
  };