import ReactECharts from 'echarts-for-react';
import { useSelector } from 'react-redux';

const ScoreChart = ({score, height = 300, width = height, large = false}) => {
    const {theme} = useSelector(({changeTheme}) => changeTheme);

      const options = {
        series: [
          {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            center: ['50%', '75%'],
            radius: '90%',
            min: 0,
            max: 5,
            splitNumber: 10,
            axisLine: {
              lineStyle: {
                width: 2,
                color: [
                    [0.2, '#00B050'],
                    [0.4, '#92D050'],
                    [0.6, '#FFC000'],
                    [0.8, '#FF8D00'],
                    [1, "#FF0000"],
                ]
              }
            },
            pointer: {
              icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
              length: '20%',
              width: 10,
              offsetCenter: [0, '-60%'],
              itemStyle: {
                color: 'auto'
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              show: false
            },
            title: {
              offsetCenter: [0, '20%'],
              fontSize: 12
            },
            detail: {
              fontSize: 20,
              offsetCenter: [0, '-35%'],
              valueAnimation: true,
              formatter: function (value) {
                return value + 0.5;
              },
              color: 'inherit'
            },
            data: [
              {
                value: score - 0.5,
                name: score === 5 ? "Critical" :
                      score === 4 ? "High" :
                      score === 3 ? "Medium" :
                      score === 2 ? "Low" :
                      "Very Low"
              }
            ]
          }
        ]
      };

      const largeOptions = {
        series: [
          {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            center: ['50%', '75%'],
            radius: '80%',
            min: 0,
            max: 5,
            splitNumber: 10,
            axisLine: {
              lineStyle: {
                width: 10,
                color: [
                    [0.2, '#00B050'],
                    [0.4, '#92D050'],
                    [0.6, '#FFC000'],
                    [0.8, '#FF8D00'],
                    [1, "#FF0000"],
                ]
              }
            },
            pointer: {
              icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
              length: '12%',
              width: 20,
              offsetCenter: [0, '-60%'],
              itemStyle: {
                color: 'auto'
              }
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false
            },
            axisLabel: {
                show: false
            },
            title: {
              offsetCenter: [0, '10%'],
              fontSize: 16
            },
            detail: {
              fontSize: 34,
              offsetCenter: [0, '-30%'],
              valueAnimation: true,
              formatter: function (value) {
                return value + 0.5;
              },
              color: 'inherit'
            },
            data: [
                {
                    value: score - 0.5,
                    name: score === 5 ? "Critical" :
                          score === 4 ? "High" :
                          score === 3 ? "Medium" :
                          score === 2 ? "Low" :
                          "Very Low"
                }
            ]
          }
        ]
      };
    
    return(
        <ReactECharts
            theme={theme}
            style={{height: height, width: width}}
            notMerge={true}
            option={large ? largeOptions : options}
        />
    );
}

export default ScoreChart;