import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordError
} from './forgotPasswordAction';

const defaultState = {
  isSendingForgotPasswordRequest: false,
  forgotPasswordRequestError: null,
  sentForgotPasswordRequest: false
};

export default handleActions(
  {
    [forgotPasswordRequest](state) {
      return {
        ...state,
        isSendingForgotPasswordRequest: true,
        forgotPasswordRequestError: null,
        sentForgotPasswordRequest: false
      };
    },
    [forgotPasswordSuccess](state, { payload }) {
      return {
        ...state,
        isSendingForgotPasswordRequest: false,
        forgotPasswordRequestError: null,
        sentForgotPasswordRequest: true
      };
    },
    [forgotPasswordError](state, { payload }) {
      return {
        ...state,
        isSendingForgotPasswordRequest: false,
        forgotPasswordRequestError: payload,
        sentForgotPasswordRequest: false
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);