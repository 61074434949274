import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MessageTextOutlineIcon from 'mdi-react/MessageTextOutlineIcon';
import { Card } from '@/shared/components/Card';
import { colorAdditional, colorRed, colorBorder } from '@/utils/palette';
import { paddingLeft, left } from '@/utils/directions';
import { ProfileCard } from '../ProfileBasicComponents';
import {
  Col,
  Row,
} from 'react-bootstrap';
import { FormButtonToolbar } from '@/shared/components/form/FormElements';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { FormSelectField } from '@/shared/components/form/material-controls/SelectField';
import { FormDateField } from '@/shared/components/form/material-controls/DateField';
import { updateUser, getAvatar, getUserProfile, deleteAvatar } from '@/reducers/account/userInfo/userInfoAction';
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import { enqueueSnackbar } from 'notistack';
import { Backdrop, Button, CircularProgress } from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import AvatarChangeModal from './AvatarChangeModal';
import ConfirmationDialog from '@/shared/components/ConfirmationDialog';
import dayjs from 'dayjs';

const ProfileMain = () => {

  const dispatch = useDispatch();
  
  const { 
    user, isLoadingUser, getUserError,
    avatar, isLoadingAvatar, getAvatarError,
    isUpdatingUser, updatedUser, updateUserError,
    isDeletingAvatar, deletedAvatar, deleteAvatarError
  } = useSelector(({ userInfo }) => userInfo);

  const [showAvatarModal, setShowAvatarModal] = useState(false);

  useEffect(() => {
    return () => {
      reset();
    }
  }, []);

  const { handleSubmit, reset, control, setValue, getValues } = useForm();

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const onClickDeleteAvatar = () => {
    setOpenConfirmDelete(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDelete(false);
    dispatch(deleteAvatar());
  }

  const handleCloseDelete = () => {
    setOpenConfirmDelete(false);
  }

  useEffect(() => {
    if(getUserError){
      enqueueSnackbar(getUserError, {variant: "error"});
    }
  }, [getUserError]);

  useEffect(() => {
    if(updateUserError){
      enqueueSnackbar(updateUserError, {variant: "error"});
    }
  }, [updateUserError]);

  useEffect(() => {
    if(deleteAvatarError){
      enqueueSnackbar(deleteAvatarError, {variant: "error"});
    }
  }, [deleteAvatarError]);

  useEffect(() => {
    if(deletedAvatar){
      enqueueSnackbar("Avatar deleted successfully", {variant: "success"});
    }
  }, [deletedAvatar]);

  useEffect(() => {
    if(updatedUser){
      enqueueSnackbar("Profile updated successfully", {variant: "success"});
      getUserProfile();
    }
  }, [updatedUser]);

  const onUpdateAvatar = () => {
    dispatch(getAvatar());
  };

  const onHideAvatarModal = () => {
    setShowAvatarModal(false);
  };

  const onClickEditAvatar = () => {
    setShowAvatarModal(true);
  };

  const onSubmit = (data) => {
    dispatch(updateUser(data));
  }

  return(
      <Row style={{ padding: '30px' }}>
        {isLoadingUser || !user ? <CircularProgressWrapper /> :
        <>
        <Col md={12} lg={12} xl={4}>
          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <ProfileCard>
                  <ProfileInformation>
                    <ProfileAvatar>
                      { isLoadingAvatar ? <CircularProgressWrapper /> : 
                        !avatar ? 
                          <img src="/img/avatar.svg" alt="avatar"/>
                          :
                          <img src={URL.createObjectURL(avatar)} alt="avatar" />
                      }
                    </ProfileAvatar>
                    <ProfileData>
                      <ProfileName>{user.userName}</ProfileName>
                      <ProfileWork>{user.roles.map(r => r.name).join(', ')}</ProfileWork>
                      <ProfileContact>{user.email}</ProfileContact>
                      <ProfileContact dir="ltr">{user.phoneNumber}</ProfileContact>
                      <div style={{display: 'flex', flexDirection: 'column'}}>
                        <Button 
                          endIcon={<AddAPhotoIcon />} 
                          onClick={onClickEditAvatar} 
                          variant='contained' 
                          color="primary">
                          Change Avatar
                        </Button>
                        <Button 
                          disabled={avatar === null}
                          style={{marginTop: 10}}
                          endIcon={<NoPhotographyIcon />} 
                          onClick={onClickDeleteAvatar} 
                          variant='contained' 
                          color="error">
                          Delete Avatar
                        </Button>
                      </div>
                    </ProfileData>
                  </ProfileInformation>
                </ProfileCard>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col md={12} lg={12} xl={8}>
          <Row>
            <Col md={6}>
              <FormTextField
                style={{marginTop: 0}}
                name="firstName"
                label="First Name"
                control={control}
                defaultValue={user.applicationUserProfile.firstName}
              />
            </Col>
            <Col md={6}>
              <FormTextField
                style={{marginTop: 0}}
                name="lastName"
                label="Last Name"
                control={control}
                defaultValue={user.applicationUserProfile.lastName}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormTextField
                name="emailPEC"
                label="Email Pec"
                control={control}
                defaultValue={user.applicationUserProfile.emailPec}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormTextField
                name="fiscalCode"
                label="Fiscal Code"
                control={control}
                defaultValue={user.applicationUserProfile.fiscalCode}
              />
            </Col>
            <Col md={6}>
              <FormTextField
                name="phoneNumber"
                label="Phone Number"
                control={control}
                defaultValue={user.applicationUserProfile.phoneNumber}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormTextField
                name="birthPlace"
                label="Birth Place"
                control={control}
                defaultValue={user.applicationUserProfile.birthPlace}
              />
            </Col>
            <Col md={6}>
              <FormDateField
                name="birthDate"
                label="Birthday"
                control={control}
                defaultValue={user.applicationUserProfile.birthDate ? dayjs(user.applicationUserProfile.birthDate) : null}
              />
            </Col>
          </Row>
          
          <Row style={{marginTop: 10}}>
            <Col md={12}>
              <Button variant='contained' color="primary" onClick={handleSubmit(onSubmit)}>Update profile</Button>
            </Col>
          </Row>
        </Col>
          { showAvatarModal &&
            <AvatarChangeModal
              onHideModal={onHideAvatarModal}
              onUpdateAvatar={onUpdateAvatar} 
              open={showAvatarModal}
              avatar={avatar}
            />
          }
            <ConfirmationDialog 
              open={openConfirmDelete}
              onConfirm={handleConfirmDelete}
              onClose={handleCloseDelete}
              title="Delete Avatar"
              body={`Are you sure you want to delete your avatar image?`}
              color='error'
              confirmButtonText='Delete'
            />

          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isUpdatingUser || isDeletingAvatar}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </>


        }

      </Row>
)};

export default ProfileMain;

const ProfileInformation = styled.div`
  padding: 30px 40px;
  display: flex;
  text-align: ${left};

  @media screen and (max-width: 360px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

const ProfileAvatar = styled.div`
  height: 140px;
  width: 140px;
  overflow: hidden;
  border-radius: 50%;

  img {
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 1345px) and (min-width: 1200px) {
    height: 130px;
    width: 130px;
  }
`;

const ProfileData = styled.div`
  width: calc(100% - 140px);
  ${paddingLeft}: 25px;
  
  @media screen and (max-width: 360px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0;
  }
`;

const ProfileName = styled.p`
  font-weight: 900;
  text-transform: uppercase;
  margin: 0;
  line-height: 18px;
`;

const ProfileWork = styled.p`
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 0;
  opacity: 0.6;
  line-height: 18px;
`;

const ProfileContact = styled.p`
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 18px;
`;