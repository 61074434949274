import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_ASSETTYPES');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}


export const fetchAssetTypesRequest = createAction('FETCH_ASSETTYPES_REQUEST');
export const fetchAssetTypesSuccess = createAction('FETCH_ASSETTYPES_SUCCESS');
export const fetchAssetTypesError = createAction('FETCH_ASSETTYPES_ERROR');

export const getAssetTypes = () => async (dispatch) => {
  try {
    dispatch(fetchAssetTypesRequest());
    const resp = await instance.get("/v1/AssetType/GetHierarchy");
    if(resp.data){
      dispatch(fetchAssetTypesSuccess(resp.data));
    }
    else{
      dispatch(fetchAssetTypesError(resp));
    }
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(fetchAssetTypesError(e.response.data.description));
    else
      dispatch(fetchAssetTypesError(e.message));
  }
};

export const deleteAssetTypeRequest = createAction('ASSETTYPES_DELETE_REQUEST');
export const deleteAssetTypeSuccess = createAction('ASSETTYPES_DELETE_SUCCESS');
export const deleteAssetTypeError = createAction('ASSETTYPES_DELETE_ERROR');

export const deleteAssetType = (id) => async (dispatch) => {
  try {
    dispatch(deleteAssetTypeRequest());
    const resp = await instance.delete(`/v1/AssetType/DeleteLogic/${id}`);

    dispatch(deleteAssetTypeSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(deleteAssetTypeError(e.response.data.description));
    else
      dispatch(deleteAssetTypeError(e.message));
  }
};