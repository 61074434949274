import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";

const ChatMessage = ({
    message,
    isMine,
    hasAvatar
}) => {
    
    const messageProperties = isMine ? 
        { marginRight: '10px', justifyContent: 'right' } : 
        { marginLeft: hasAvatar ? '10px' : '60px', justifyContent: 'left' };

    const borderRadiusProperties = isMine ?
        { borderRadius: '20px 0px 0px 20px'} :
        { borderRadius: '0px 20px 20px 0px'};

    return (
        <Box
            sx={{
                marginTop: '10px',
                display: 'flex',
                width: '100%',
                ...messageProperties
            }}
        >
            <Box 
                sx={{
                    bgcolor: isMine ? 'primary.main' : 'surveyChatOtherMessageBackground.main',
                    padding: '10px',
                    ...borderRadiusProperties,
                    maxWidth: '70%',
                    textAlign: isMine ? 'right' : 'left'
                }}>
                <Typography sx={{
                    fontSize: '18px',
                    textAlign: isMine ? 'right' : 'left'
                }}>
                    {message.text}
                </Typography>
                <Typography 
                    color='text.secondary'
                    sx={{
                        fontSize: '12px'
                    }}
                >
                    {dayjs(message.sendDate).format("DD/MM/YYYY HH:mm:ss")}
                </Typography>
            </Box>
        </Box>
    );
}

export default ChatMessage;