import { Controller } from "react-hook-form";
import { Slider, Typography } from "@mui/material";

export const FormSliderField = ({ name, control, label, rules, min=0, max=10, defaultValue=min, step=1, ...other }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <div style={{marginTop: 10, paddingInline: 10}}>
            <Typography style={{marginLeft: 8.5}}>{label}: {value}</Typography>
            <Slider
                max={max}
                min={min}
                step={step}
                size="medium"
                valueLabelDisplay="auto"
                onChange={onChange}
                value={value}
                {...other}
            />
        </div>
      )}
    />
  );
};