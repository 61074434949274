import { left } from '@/utils/directions';
import styled from '@emotion/styled';
import { Card, CardContent, Paper, Typography } from '@mui/material';

// export const CardPage = styled(Card)(({ theme }) => ({
//     bgColor: 'cardBackground1.main',
//     padding: '20px',
//     width: '100%',
//     paddingBottom: '30px',
//     height: '100%'
//   }));

export const CardPage = (props) => {
    return(
        <Card 
            sx={{
                bgcolor: 'transparent',
                width: '100%',
                paddingBottom: '30px',
                height: '100%'
            }}
            {...props}
        >
            {props.children}
        </Card>
    );
}

CardPage.defaultProps = {
    elevation: 0
};

export const CardPageBody = styled(CardContent)(({ theme }) => ({
    height: "100%",
    padding: '20px'
  }));


export const CardPageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const CardPageTitleWrap = styled.div`
  position: relative;
  text-align: ${left};

  &:not(:first-child) {
    margin-top: 40px;
  }

  * {
    margin-bottom: 0;
  }
`;

export const CardPageButtonWrap = styled.div`
  padding-bottom: 10px;
`;

export const CardPageGridWrap = styled(Paper)(({ theme }) => ({
  paddinBottom: '20px',
  width: '100%'
}));

export const CardPageTitle = (props) => {
    return(
        <Typography {...props}>
            {props.children}
        </Typography>
    )
};

CardPageTitle.defaultProps = {
    variant: 'h5'
};

export const CardPageSubhead = (props) => {
    return(
        <Typography sx={{marginTop: '11px'}} {...props}>
            {props.children}
        </Typography>
    )
};

CardPageSubhead.defaultProps = {
    variant: 'subtitle2',
    color: 'text.secondary'
};

export const CardPageChartBoxTitleWrap = styled.h6`
  font-size: 16px;
  text-align: ${left};
  font-weight: 500;
  text-transform: uppercase;
  ${props => props.centered && `
    text-align: center;
  `}
`;
export const CardPageChartBoxWrap = styled.div`
  padding-top: 10px;
`;

export const CardPageFilterWrapper = styled.div`
  margin-bottom: 10px;
`;

