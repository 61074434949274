import { createAction } from 'redux-actions';
import { instance } from "../../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_STAKEHOLDER_USER_ADD');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getRolesRequest = createAction('GET_ROLES_STAKEHOLDER_USER_ADD_REQUEST');
export const getRolesSuccess = createAction('GET_ROLES_STAKEHOLDER_USER_ADD_SUCCESS');
export const getRolesError = createAction('GET_ROLES_STAKEHOLDER_USER_ADD_ERROR');

export const getRoles = () => async (dispatch) => {
  try {
    dispatch(getRolesRequest());

    const resp = await instance.get(`/v1/UserAndRole/GetApplicationRoles`);
    
    dispatch(getRolesSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getRolesError(e.response.data.description));
    else
      dispatch(getRolesError(e.message));
  }
};

export const addUserRequest = createAction('STAKEHOLDER_USER_ADD_REQUEST');
export const addUserSuccess = createAction('STAKEHOLDER_USER_ADD_SUCCESS');
export const addUserError = createAction('STAKEHOLDER_USER_ADD_ERROR');

export const addUser = (data) => async (dispatch) => {
  try {
    dispatch(addUserRequest());

    const resp = await instance.post(`/v1/StakeholderApplicationUser/AddNewStakeholderUser`, data);
    
    dispatch(addUserSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(addUserError(e.response.data.description));
    else
      dispatch(addUserError(e.message));
  }
};