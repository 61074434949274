import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getActivitySurveyRequest,
  getActivitySurveySuccess,
  getActivitySurveyError,
  getSurveysRequest,
  getSurveysSuccess,
  getSurveysError,
  confirmSurveysRequest,
  confirmSurveysSuccess,
  confirmSurveysError
} from './selectSurveysAction';

const defaultState = {
  activitySurvey: null, 
  isLoadingActivitySurvey: true, 
  getActivitySurveyError: null,
  surveys: [],
  isLoadingSurveys: true,
  getSurveysError: null,
  isConfirmingSurveys: false, 
  confirmedSurveys: false, 
  confirmSurveysError: null
};

export default handleActions(
  {
    [getActivitySurveyRequest](state) {
      return {
        ...state,
        activitySurvey: null,
        isLoadingActivitySurvey: true,
        getActivitySurveyError: null,
      };
    },
    [getActivitySurveySuccess](state, { payload }) {
      return {
        ...state,
        activitySurvey: payload,
        isLoadingActivitySurvey: false,
        getActivitySurveyError: null,
      };
    },
    [getActivitySurveyError](state, { payload }) {
      return {
        ...state,
        activitySurvey: null,
        isLoadingActivitySurvey: false,
        getActivitySurveyError: payload,
      };
    },
    [getSurveysRequest](state) {
      return {
        ...state,
        surveys: [],
        isLoadingSurveys: true,
        getSurveysError: null,
      };
    },
    [getSurveysSuccess](state, { payload }) {
      return {
        ...state,
        surveys: payload,
        isLoadingSurveys: false,
        getSurveysError: null,
      };
    },
    [getSurveysError](state, { payload }) {
      return {
        ...state,
        surveys: [],
        isLoadingSurveys: false,
        getSurveysError: payload,
      };
    },
    [confirmSurveysRequest](state) {
      return {
        ...state,
        isConfirmingSurveys: true,
        confirmSurveysError: null,
        confirmedSurveys: false
      };
    },
    [confirmSurveysSuccess](state, { payload }) {
      return {
        ...state,
        isConfirmingSurveys: false,
        confirmSurveysError: null,
        confirmedSurveys: true
      };
    },
    [confirmSurveysError](state, { payload }) {
      return {
        ...state,
        isConfirmingSurveys: false,
        confirmSurveysError: payload,
        confirmedSurveys: false
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);