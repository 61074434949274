import { createAction } from 'redux-actions';
import { instance } from "../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_ORGANIZATIONS');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getClassificationsRequest = createAction('ORGANIZATIONS_GET_CLASSIFICATIONS_REQUEST');
export const getClassificationsSuccess = createAction('ORGANIZATIONS_GET_CLASSIFICATIONS_SUCCESS');
export const getClassificationsError = createAction('ORGANIZATIONS_GET_CLASSIFICATIONS_ERROR');

export const getClassifications = () => async (dispatch) => {
  try {
    dispatch(getClassificationsRequest());
    const resp = await instance.get(`/v1/Classification/GetAll`);
    console.log(resp);
    dispatch(getClassificationsSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getClassificationsError(e.response.data.description));
    else
      dispatch(getClassificationsError(e.message));
  }
};