import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCurrentEmailAccount, updateEmailAccount, createEmailAccount, cleanup } from "@/reducers/options/optionsAction";
import { enqueueSnackbar } from "notistack";
import { Backdrop, Box, Button, Card, CardContent, CircularProgress, Typography } from "@mui/material";
import { CardPage, CardPageBody, CardPageHeader, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap, CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import { Container, Row, Col } from "react-bootstrap";
import MailLockIcon from '@mui/icons-material/MailLock';
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { FormCheckboxField } from '@/shared/components/form/material-controls/CheckboxField';
import { useForm } from "react-hook-form";
import styled from "styled-components";
import SendTestEmailModal from "./components/sendTestEmailModal";

const Options = () => {
    const dispatch = useDispatch();

    const { 
        emailAccount, isLoadingEmailAccount, getEmailAccountError,
        isUpdatingEmail, updatedEmail, updateEmailError,
        isCreatingEmail, createdEmail, createEmailError
    } = useSelector(({ options }) => options);

    const { handleSubmit, reset, control, setValue, getValues, watch } = useForm();
    const [openTestEmailModal, setOpenTestEmailModal] = useState(false);

    useEffect(() => {
        dispatch(getCurrentEmailAccount());
    
        return () => {
          dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
        if(updateEmailError){
            enqueueSnackbar(updateEmailError, {variant: "error"});
        }
    }, [updateEmailError]);
    
    useEffect(() => {
        if(createEmailError){
            enqueueSnackbar(createEmailError, {variant: "error"});
        }
    }, [createEmailError]);

    useEffect(() => {
        if(updatedEmail){
            enqueueSnackbar("SMTP settings updated successfully", {variant: "success"});
        }
    }, [updatedEmail]);

    useEffect(() => {
        if(createdEmail){
            enqueueSnackbar("SMTP settings updated successfully", {variant: "success"});
            dispatch(getCurrentEmailAccount());
        }
    }, [createdEmail]);

    const onSubmit = (data) => {
        if(emailAccount){
            data.emailAccountSettingId = emailAccount.emailAccountSettingId;
            dispatch(updateEmailAccount(data));
        }
        else{
            dispatch(createEmailAccount(data));
        }
    }

    const onClickSendTestEmail = () => {
        setOpenTestEmailModal(true);
    };

    const onHideTestEmailModal = () => {
        setOpenTestEmailModal(false);
    };

    return (
        <Container>
            <CardPage>
                <CardPageBody>
                    <CardPageHeader>
                        <CardPageTitleWrap>
                            <CardPageTitle>Options</CardPageTitle>
                            <CardPageSubhead>
                                Here you can view and edit some options.
                            </CardPageSubhead>
                        </CardPageTitleWrap>
                        <CardPageButtonWrap>
                        </CardPageButtonWrap>
                    </CardPageHeader>

                    <Card>
                        <CardContent>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start'
                            }}>
                                <TitleContainer>
                                    <MailLockIcon />
                                    <Typography variant='h5'>SMTP Settings</Typography>
                                </TitleContainer>
                                <Button 
                                    // disabled={!emailAccount}
                                    variant="contained"
                                    color="warning"
                                    onClick={onClickSendTestEmail}
                                >
                                    Send test email
                                </Button>
                            </Box>
                            {isLoadingEmailAccount ? <CircularProgressWrapper /> :        
                                <Row>
                                    <Col md={12}>
                                        <FormTextField
                                            name="name"
                                            label="Name"
                                            control={control}
                                            defaultValue={emailAccount?.name}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <FormTextField
                                            name="emailAddress"
                                            label="Email"
                                            control={control}
                                            defaultValue={emailAccount?.emailAddress}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <FormTextField
                                            name="userName"
                                            label="Username"
                                            control={control}
                                            defaultValue={emailAccount?.userName}
                                        />
                                    </Col>                                    
                                    <Col md={4}>
                                        <FormTextField
                                            name="password"
                                            label="Password"
                                            control={control}
                                            defaultValue={emailAccount?.password}
                                            type="password"
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <FormTextField
                                            name="smtpUrl"
                                            label="SMTP Server"
                                            control={control}
                                            defaultValue={emailAccount?.smtpUrl}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <FormTextField
                                            name="smtpPort"
                                            label="SMTP Port"
                                            control={control}
                                            type="number"
                                            defaultValue={emailAccount?.smtpPort}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <FormCheckboxField
                                            name="smtpUseSSL"
                                            label="Use SMTP SSL"
                                            control={control}
                                            defaultValue={emailAccount?.smtpUseSSL}
                                            style={{marginTop: '20px'}}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <FormTextField
                                            name="pop3Host"
                                            label="POP3 Server"
                                            control={control}
                                            defaultValue={emailAccount?.pop3Host}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <FormTextField
                                            name="pop3Port"
                                            label="POP3 Port"
                                            control={control}
                                            type="number"
                                            defaultValue={emailAccount?.pop3Port}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <FormCheckboxField
                                            name="pop3UseSSL"
                                            label="Use POP3 SSL"
                                            control={control}
                                            defaultValue={emailAccount?.pop3UseSSL}
                                            style={{marginTop: '20px'}}
                                        />
                                    </Col>

                                    <Col md={12}>
                                        <Box sx={{
                                            display: 'flex', 
                                            justifyContent: 'flex-end',
                                            marginTop: '20px'
                                        }}>
                                            <Button 
                                                variant="contained"
                                                onClick={handleSubmit(onSubmit)}
                                            >
                                                Update settings
                                            </Button>
                                        </Box>
                                    </Col>
                                </Row>
                            }
                        </CardContent>
                    </Card>

                    { openTestEmailModal && 
                        <SendTestEmailModal 
                            open={openTestEmailModal}
                            onHideModal={onHideTestEmailModal}
                        />
                    }
                    
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isUpdatingEmail || isCreatingEmail}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </CardPageBody>
            </CardPage>
        </Container>
    );
}




const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 20px;

  & > svg{
    margin-right: 5px;
    font-size: 30px;
  }
`;
export default Options;