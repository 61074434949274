import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordError
} from './resetPasswordAction';

const defaultState = {
  isResettingPassword: false,
  resetPasswordError: null,
  resettedPassword: false
};

export default handleActions(
  {
    [resetPasswordRequest](state) {
      return {
        ...state,
        isResettingPassword: true,
        resetPasswordError: null,
        resettedPassword: false
      };
    },
    [resetPasswordSuccess](state, { payload }) {
      return {
        ...state,
        isResettingPassword: false,
        resetPasswordError: null,
        resettedPassword: true
      };
    },
    [resetPasswordError](state, { payload }) {
      return {
        ...state,
        isResettingPassword: false,
        resetPasswordError: payload,
        resettedPassword: false
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);