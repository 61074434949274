import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { cleanup, getSubscriptionTypes, getTenant, updateSubscription } from "@/reducers/tenantSubscription/tenantSubscriptionAction";
import { enqueueSnackbar } from "notistack";
import { Col, Container, Row } from "react-bootstrap";
import { CardPage, CardPageFilterWrapper, CardPageBody, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap,CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import SubscriptionCard from "./components/subscriptionCard";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import { Backdrop, CircularProgress, Divider, TextField } from "@mui/material";
import ModuleCard from "./components/moduleCard";
import { useParams } from "react-router-dom";
import ConfirmationDialog from '@/shared/components/ConfirmationDialog';


const Subscription = () => {
    const { id } = useParams();

    const dispatch = useDispatch();

    const { 
      tenant, isLoadingTenant, getTenantError,
      subscriptionTypes, isLoadingSubscriptionTypes, getSubscriptionTypesError,
      isUpdatingSubscription, updatedSubscription, updateSubscriptionError
    } = useSelector(({ tenantSubscription }) => tenantSubscription);

    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const [preSelectedSubscription, setPreSelectedSubscription] = useState(null);
    const [openConfirmChange, setOpenConfirmChange] = useState(false);

    // const [isCustom, setIsCustom] = useState(false);
    // const [selectedModules, setSelectedModules] = useState([]);

    useEffect(() => {
        dispatch(getTenant(id));
        dispatch(getSubscriptionTypes());
   
        return () => {
          dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
        console.log(tenant);
        if(tenant){
            setSelectedSubscription(tenant.subscription?.subscriptionTypeId);
        }
    }, [tenant]);


    
    // const onClickModule = (applicationModule) => {
    //     var clone = [...selectedModules];

    //     if(clone.includes(applicationModule.applicationModuleId)){
    //         const index = clone.indexOf(applicationModule.applicationModuleId);
    //         clone.splice(index, 1);

    //         setSelectedModules([...clone]);
    //     }
    //     else{
    //         clone.push(applicationModule.applicationModuleId);
    //         setSelectedModules([...clone]);
    //     }
    // };

    useEffect(() => {
      if(getSubscriptionTypesError){
          enqueueSnackbar(getSubscriptionTypesError, {variant: "error"});
      }
    }, [getSubscriptionTypesError]);

    useEffect(() => {
        if(getTenantError){
            enqueueSnackbar(getTenantError, {variant: "error"});
        }
      }, [getTenantError]);

    useEffect(() => {
        if(updatedSubscription){
            enqueueSnackbar("Subscription updated successfully!", {variant: "success"});
            setSelectedSubscription(preSelectedSubscription.subscriptionTypeId);
        }
    }, [updatedSubscription]);

    useEffect(() => {
        if(updateSubscriptionError){
            enqueueSnackbar(updateSubscriptionError, {variant: "error"});
        }
    }, [updateSubscriptionError]);

    const onSelectSubscription = (subscription) => {
        setPreSelectedSubscription(subscription);
        setOpenConfirmChange(true);
    };

    const handleConfirmChange = () => {
        setOpenConfirmChange(false);

        const data = {
            tenantId : id,
            subscriptionTypeId: preSelectedSubscription.subscriptionTypeId
        };

        dispatch(updateSubscription(data));

        setPreSelectedSubscription(null);
      }
    
      const handleOnCloseChange = () => {
        setOpenConfirmChange(false);
        setPreSelectedSubscription(null);
      };

    return(
        <Container>
            <CardPage>
                <CardPageBody>
                    <CardPageTitleWrap>
                        <CardPageTitle>Subscription</CardPageTitle>
                    </CardPageTitleWrap>

                    {isLoadingSubscriptionTypes || isLoadingTenant ? <CircularProgressWrapper /> :  
                    <>
                        <Row style={{marginTop: 20}}>
                            <Col md={6}>
                                <TextField
                                    name='name'
                                    label='Name'
                                    size="small"
                                    defaultValue={tenant.name}
                                    fullWidth
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Col>
                            <Col md={6}>
                                <TextField
                                    name='billingName'
                                    label='Billing Name'
                                    size="small"
                                    defaultValue={tenant.billingName}
                                    fullWidth
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Col>
                        </Row>
                        
                        <Row style={{marginTop: 20}}>
                            {
                                subscriptionTypes.map((subscriptionType, index) => 
                                    <Col md={4}>
                                        <SubscriptionCard 
                                            subscriptionType={subscriptionType}
                                            onSelect={onSelectSubscription}
                                            isSelected={selectedSubscription === subscriptionType.subscriptionTypeId}
                                        />
                                    </Col>
                                )
                            }
                        </Row>
                        
                        {/* <Divider style={{marginTop: 30}} component='div'/> */}

                        {/* <Row >
                            {selectedSubscription && 
                                selectedSubscription.subscriptionTypeModules.map((module, index) => 
                                <Col md={3} style={{marginTop: 30}}>
                                <ModuleCard 
                                applicationModule={module.applicationModule}
                                onClick={onClickModule}
                                isSelected={selectedModules.includes(module.applicationModule.applicationModuleId)}
                                />
                                </Col>
                                )
                            }
                        </Row> */}
                    </>
                    }
                </CardPageBody>
            </CardPage>

            {openConfirmChange &&
            <ConfirmationDialog 
                open={openConfirmChange}
                onConfirm={handleConfirmChange}
                onClose={handleOnCloseChange}
                title="Change Subscription"
                body={`Are you sure you want to change subscription?`}
                color='primary'
                confirmButtonText='Confirm'
            />
        }

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isUpdatingSubscription}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}

export default Subscription;