import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { colorText } from "../../../utils/palette";

const building = `${process.env.PUBLIC_URL}/img/building2.png`;

const TopbarTenantLogo = () => {
    const { 
        logo, isLoadingLogo, 
        currentTenant, isLoadingCurrentTenant 
    } = useSelector(({ currentTenant }) => currentTenant);

    return(
        <LogoImageWrapper>
            {isLoadingLogo ? <></> : logo ?
                <LogoImage src={logo ? URL.createObjectURL(logo) : building} alt='logo' />
                :
                !isLoadingCurrentTenant && currentTenant ?
                <Title sx={{color: 'text.primary'}} variant="h5">{currentTenant.name}</Title>
                :
                <></>
            }
        </LogoImageWrapper>
    );
}


export const Title = styled(Typography)`
  font-size: 24px;
  
  @media screen and (max-width: 576px) {
    font-size: 18px;
  }
`;

export const LogoImageWrapper = styled("div")`
  position: relative;
  height: 60px;
  margin-bottom: 0;
  margin-left: 0;
  display: flex;
  align-items: center;

  @media screen and (max-width: 576px) {
    margin: inherit;
  }

  @media screen and (max-width: 320px) {
    margin: auto 0;
  }
`;

const LogoImage = styled("img")`
  margin: auto 0;
  height: 50px;
  width: auto;
`;

export default TopbarTenantLogo;