import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  updateAssetTypesRequest,
  updateAssetTypesSuccess,
  updateAssetTypesError,
} from './assetTypeUpdateAction';

const defaultState = {
  isUpdatingAssetType: false,
  updateAssetTypeError: null,
  updatedAssetType: false
};

export default handleActions(
  {
    [updateAssetTypesRequest](state) {
      return {
        ...state,
        isUpdatingAssetType: true,
        updatedAssetType: false,
        updateAssetTypeError: null
      };
    },
    [updateAssetTypesSuccess](state, { payload }) {
      return {
        ...state,
        isUpdatingAssetType: false,
        updatedAssetType: true,
        updateAssetTypeError: null
      };
    },
    [updateAssetTypesError](state, { payload }) {
      return {
        ...state,
        isUpdatingAssetType: false,
        updatedAssetType: false,
        updateAssetTypeError: payload
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);
