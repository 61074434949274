import { Col, Row } from "react-bootstrap";
import { IsValidEmail } from "@/utils/formValidations";
import { FormTextField } from "@/shared/components/form/material-controls/TextField";
import { useEffect, useState } from "react";
import { Box, Checkbox, FormControlLabel } from "@mui/material";

const EmailAndUserName = ({
    control,
    watch,
    setValue,
    getValues
}) => {

    const [customUserName, setCustomUserName] = useState(false);

    const onChangeCustom = () => {
        if(customUserName){
            setValue("userName", getValues("email"));
        }

        setCustomUserName(!customUserName);
    }

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if(!customUserName && name === "email"){
                setValue("userName", value.email);
            }
        });

        return () => subscription.unsubscribe();
    }, [watch, customUserName]);

    return(
        <Row>
            <Col md={6}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    height: '100%'
                }}>
                    <FormTextField
                        name="email" 
                        label="Email" 
                        control={control}
                        rules={{ 
                            required: 'This field is required',
                            validate: {
                                matchPattern: (email) => IsValidEmail(email) ||
                                "Email address must be a valid address"
                            }
                        }}
                    />
                </Box>
            </Col>
            <Col md={6}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <FormControlLabel
                        label="Custom"
                        control={<Checkbox 
                            name="customUserName"
                            onChange={onChangeCustom}
                            checked={customUserName}
                        />}
                    />
                    <FormTextField 
                        style={{marginTop: 0}}
                        name="userName" 
                        label="User Name" 
                        control={control} 
                        rules={{ required: 'This field is required' }}
                        disabled={!customUserName}
                    />
                </Box>
            </Col>
        </Row>
    );
};

export default EmailAndUserName;