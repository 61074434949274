import { createAction } from 'redux-actions';
import { instance } from "../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_OPTIONS');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getCurrentEmailAccountRequest = createAction('OPTIONS_GET_CURRENT_EMAIL_ACCOUNT_REQUEST');
export const getCurrentEmailAccountSuccess = createAction('OPTIONS_GET_CURRENT_EMAIL_ACCOUNT_SUCCESS');
export const getCurrentEmailAccountError = createAction('OPTIONS_GET_CURRENT_EMAIL_ACCOUNT_ERROR');

export const getCurrentEmailAccount = () => async (dispatch) => {
  try {
    dispatch(getCurrentEmailAccountRequest());
    const resp = await instance.get(`/v1/EmailAccountSetting/GetCurrentEmailAccountSetting`);
    
    dispatch(getCurrentEmailAccountSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getCurrentEmailAccountError(e.response.data.description));
    else
      dispatch(getCurrentEmailAccountError(e.message));
  }
};

export const updateEmailAccountRequest = createAction('OPTIONS_UPDATE_CURRENT_EMAIL_ACCOUNT_REQUEST');
export const updateEmailAccountSuccess = createAction('OPTIONS_UPDATE_CURRENT_EMAIL_ACCOUNT_SUCCESS');
export const updateEmailAccountError = createAction('OPTIONS_UPDATE_CURRENT_EMAIL_ACCOUNT_ERROR');

export const updateEmailAccount = (data) => async (dispatch) => {
  try {
    dispatch(updateEmailAccountRequest());
    const resp = await instance.put(`/v1/EmailAccountSetting/Put`, data);
    
    dispatch(updateEmailAccountSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(updateEmailAccountError(e.response.data.description));
    else
      dispatch(updateEmailAccountError(e.message));
  }
};


export const createEmailAccountRequest = createAction('OPTIONS_CREATE_CURRENT_EMAIL_ACCOUNT_REQUEST');
export const createEmailAccountSuccess = createAction('OPTIONS_CREATE_CURRENT_EMAIL_ACCOUNT_SUCCESS');
export const createEmailAccountError = createAction('OPTIONS_CREATE_CURRENT_EMAIL_ACCOUNT_ERROR');

export const createEmailAccount = (data) => async (dispatch) => {
  try {
    dispatch(createEmailAccountRequest());
    const resp = await instance.post(`/v1/EmailAccountSetting/Post`, data);
    
    dispatch(createEmailAccountSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(createEmailAccountError(e.response.data.description));
    else
      dispatch(createEmailAccountError(e.message));
  }
};