import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getTenantsRequest,
  getTenantsSuccess,
  getTenantsError,
  deleteTenantRequest,
  deleteTenantSuccess,
  deleteTenantError
} from './systemTenantListAction';

const defaultState = {
  tenants: [],
  isLoadingTenants: true,
  getTenantsError: null,
  tenantsTotalCount: 0,
  isDeletingTenant: false,
  deleteTenantError: null,
  deletedTenant: false,
};

export default handleActions(
  {
    [getTenantsRequest](state) {
        return {
        ...state,
        isLoadingTenants: true,
        getTenantsError: null,
        };
    },
    [getTenantsSuccess](state, { payload }) {
        return {
          ...state,
          tenants: payload.data,
          tenantsTotalCount: payload.totalCount,
          isLoadingTenants: false,
          getTenantsError: null,
        };
    },
    [getTenantsError](state, { payload }) {
        return {
        ...state,
        isLoadingTenants: false,
        getTenantsError: payload,
        };
    },
    [deleteTenantRequest](state) {
      return {
        ...state,
        isDeletingTenant: true,
        deletedTenant: false,
        deleteTenantError: null,
      };
    },
    [deleteTenantSuccess](state, { payload }) {
      return {
        ...state,
        isDeletingTenant: false,
        deletedTenant: true,
        deleteTenantError: null,
      };
    },
    [deleteTenantError](state, { payload }) {
      return {
        ...state,
        isDeletingTenant: false,
        deletedTenant: false,
        deleteTenantError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);
