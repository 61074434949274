import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getPrioritiesRequest,
  getPrioritiesSuccess,
  getPrioritiesError,
  getActivityTypesRequest,
  getActivityTypesSuccess,
  getActivityTypesError,
} from './homeAction';

const defaultState = {
  priorities: null,
  isLoadingPriorities: true,
  getPrioritiesError: null,
  
  activityTypes: null,
  isLoadingActivityTypes: true,
  getActivityTypesError: null,
};

export default handleActions(
  {
    [getPrioritiesRequest](state) {
      return {
        ...state,
        isLoadingPriorities: true,
        getPrioritiesError: null,
      };
    },
    [getPrioritiesSuccess](state, { payload }) {
      return {
        ...state,
        priorities: payload,
        isLoadingPriorities: false,
        getPrioritiesError: null,
      };
    },
    [getPrioritiesError](state, { payload }) {
      return {
        ...state,
        isLoadingPriorities: false,
        getPrioritiesError: payload,
      };
    },
    
    [getActivityTypesRequest](state) {
      return {
        ...state,
        isLoadingActivityTypes: true,
        getActivityTypesError: null,
      };
    },
    [getActivityTypesSuccess](state, { payload }) {
      return {
        ...state,
        activityTypes: payload,
        isLoadingActivityTypes: false,
        getActivityTypesError: null,
      };
    },
    [getActivityTypesError](state, { payload }) {
      return {
        ...state,
        isLoadingActivityTypes: false,
        getActivityTypesError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);