import { createAction } from 'redux-actions';
import { instance } from "../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_FIELD_VALUES');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getFieldValuesRequest = createAction('GET_FIELD_VALUES_REQUEST');
export const getFieldValuesSuccess = createAction('GET_FIELD_VALUES_SUCCESS');
export const getFieldValuesError = createAction('GET_FIELD_VALUES_ERROR');

export const getFieldValues = (controller, field) => async (dispatch) => {
  try {
    dispatch(getFieldValuesRequest());
    const resp = await instance.get(`/v1/${controller}/GetAllFieldValues/?field=${field}`);

    if(resp.data && resp.data.data)
      dispatch(getFieldValuesSuccess(resp.data.data));
    else
      dispatch(getFieldValuesSuccess(resp.data));
    
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getFieldValuesError(e.response.data.description));
    else
      dispatch(getFieldValuesError(e.message));
  }
};