import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_CREATE_ACTIVITY');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}


export const getActivityTypeRequest = createAction('CREATE_ACTIVITY_GET_ACTIVITY_TYPE_REQUEST');
export const getActivityTypeSuccess = createAction('CREATE_ACTIVITY_GET_ACTIVITY_TYPE_SUCCESS');
export const getActivityTypeError = createAction('CREATE_ACTIVITY_GET_ACTIVITY_TYPE_ERROR');

export const getActivityType = (id) => async (dispatch) => {
  try {
    dispatch(getActivityTypeRequest());
    const resp = await instance.get(`/v1/ActivityType/GetFullById?ActivityTypeId=${id}`);

    if(resp.data)
      dispatch(getActivityTypeSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getActivityTypeError(e.response.data.description));
    else
      dispatch(getActivityTypeError(e.message));
  }
};

export const getWorkflowStepsRequest = createAction('CREATE_ACTIVITY_GET_WORKFLOW_STEPS_REQUEST');
export const getWorkflowStepsSuccess = createAction('CREATE_ACTIVITY_GET_WORKFLOW_STEPS_SUCCESS');
export const getWorkflowStepsError = createAction('CREATE_ACTIVITY_GET_WORKFLOW_STEPS_ERROR');

export const getWorkflowSteps = (id) => async (dispatch) => {
  try {
    dispatch(getWorkflowStepsRequest());
    const resp = await instance.get(`/v1/Workflow/GetWorkflowActivityStepsByStartStatus?ActivityTypeId=${id}`);
    
    if(resp.data.isSuccessed)
      dispatch(getWorkflowStepsSuccess(resp.data.data));
    else
      dispatch(getWorkflowStepsError(resp.data.error));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getWorkflowStepsError(e.response.data.description));
    else
      dispatch(getWorkflowStepsError(e.message));
  }
};

export const getWorkflowCreateTaskRequest = createAction('CREATE_ACTIVITY_GET_WORKFLOW_TASKS_REQUEST');
export const getWorkflowCreateTaskSuccess = createAction('CREATE_ACTIVITY_GET_WORKFLOW_TASKS_SUCCESS');
export const getWorkflowCreateTaskError = createAction('CREATE_ACTIVITY_GET_WORKFLOW_TASKS_ERROR');

export const getWorkflowCreateTask = (id) => async (dispatch) => {
  try {
    dispatch(getWorkflowCreateTaskRequest());
    const resp = await instance.get(`/v1/Workflow/GetTaskCreateActivity?ActivityTypeId=${id}`);

    if(resp.data.isSuccessed)
      dispatch(getWorkflowCreateTaskSuccess(resp.data.data));
    else
      dispatch(getWorkflowCreateTaskError(resp.data.error));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getWorkflowCreateTaskError(e.response.data.description));
    else
      dispatch(getWorkflowCreateTaskError(e.message));
  }
};

export const createActivityRequest = createAction('CREATE_ACTIVITY_REQUEST');
export const createActivitySuccess = createAction('CREATE_ACTIVITY_SUCCESS');
export const createActivityError = createAction('CREATE_ACTIVITY_ERROR');

export const createActivity = (data) => async (dispatch) => {
  try {
    dispatch(createActivityRequest());
    const resp = await instance.post(`/v1/Workflow/NewActivity`, data);
    
    if(resp.data.isSuccessed)
      dispatch(createActivitySuccess(resp.data.data));
    else
      dispatch(createActivityError(resp.data.error));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(createActivityError(e.response.data.description));
    else
      dispatch(createActivityError(e.message));
  }
};