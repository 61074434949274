import { Box, Card, CardActionArea, CardContent, Typography } from "@mui/material";
import GroupIcon from '@mui/icons-material/Group';
import { useEffect, useState } from "react";
import { instance } from "@/config/interceptor";

const AssetCard = ({workflow, asset, onClick, surveys, supplier}) => {

    const [logo, setLogo] = useState(null);

    async function getLogo() {
        try{
            const resp = await instance.get(`/v1/Stakeholder/GetLogo/${supplier.stakeholderId}`, { 
                responseType: 'blob'
            });

            if(resp.data && resp.data.size){
                const urlObject = URL.createObjectURL(resp.data);
                setLogo(urlObject);
            }
        }
        catch(e){ }
    }

    useEffect(() => {
        getLogo();
    }, []);

    return(
        <Card
            onClick={onClick} 
            sx={{
                height: '100%',
                minHeight: '160px',

                '&:hover': {
                    cursor: 'pointer'
                },
            }}
        >
            <CardActionArea style={{height: '100%'}}>
                <CardContent style={{height: '100%'}}>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", height: '100%'}}>
                        <div style={{display: "flex", justifyContent: 'space-between'}}>
                            <div style={{
                                display: "flex"
                            }}>
                                <GroupIcon fontSize="large" style={{marginRight: 10}} />
                                <div>
                                    <Typography variant="h5" style={{display: 'flex', alignItems: 'center'}}>{supplier.name}</Typography>
                                    <Typography variant="body1" color="text.secondary">{supplier.description}</Typography>
                                    <Typography variant="body1" color="text.secondary">Assigned surveys: {asset.assetSurveys ? asset.assetSurveys.length : 0} / {surveys ? surveys.length : 0}</Typography>
                                </div>
                            </div>
                            <Box sx={{ height: '100px' }}>
                                { logo && 
                                    <img style={{ maxWidth: 100, maxHeight: 100 }} src={logo} alt="avatar"/>
                                }
                            </Box>
                            
                            {/* <div>
                                <CircularProgressWithLabel 
                                    value={asset.percentCompleted * 100} />
                            </div> */}
                        </div>

                        <div style={{marginTop: 30, display: "flex", justifyContent: 'space-between', width: '100%', alignItems: 'flex-end'}}>
                            <div>
                                <Typography sx={{fontSize: '20px', fontWeight: 'bold', lineHeight: 1 }}>
                                    STAKEHOLDERS
                                </Typography>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default AssetCard;