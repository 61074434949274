import { createAction } from 'redux-actions';
import { instance } from "../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_DOMAINS');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getDomainsRequest = createAction('DOMAINS_GET_ALL_REQUEST');
export const getDomainsSuccess = createAction('DOMAINS_GET_ALL_SUCCESS');
export const getDomainsError = createAction('DOMAINS_GET_ALL_ERROR');

export const getDomains = () => async (dispatch) => {
  try {
    dispatch(getDomainsRequest());
    const resp = await instance.get(`/v1/Domain/GetAll`);
    
    dispatch(getDomainsSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getDomainsError(e.response.data.description));
    else
      dispatch(getDomainsError(e.message));
  }
};

export const deleteDomainRequest = createAction('DOMAINS_DELETE_REQUEST');
export const deleteDomainSuccess = createAction('DOMAINS_DELETE_SUCCESS');
export const deleteDomainError = createAction('DOMAINS_DELETE_ERROR');

export const deleteDomain = (id) => async (dispatch) => {
  try {
    dispatch(deleteDomainRequest());
    const resp = await instance.delete(`/v1/Domain/DeleteLogic/${id}`);

    dispatch(deleteDomainSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(deleteDomainError(e.response.data.description));
    else
      dispatch(deleteDomainError(e.message));
  }
};