import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_UPDATE_DOMAIN');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const updateDomainRequest = createAction('UPDATE_DOMAIN_POST_REQUEST');
export const updateDomainSuccess = createAction('UPDATE_DOMAIN_POST_SUCCESS');
export const updateDomainError = createAction('UPDATE_DOMAIN_POST_ERROR');

export const updateDomain = (request) => async (dispatch) => {
  try {
    dispatch(updateDomainRequest());
    const resp = await instance.put(`/v1/Domain/Put`, request);

    dispatch(updateDomainSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(updateDomainError(e.response.data.description));
    else
      dispatch(updateDomainError(e.message));
  }
};
