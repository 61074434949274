import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_ASSET_VULNERABILITY_CHECK_LIST');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getRemediationRequest = createAction('ASSET_VULNERABILITY_CHECK_LIST_GET_REMEDIATION_REQUEST');
export const getRemediationSuccess = createAction('ASSET_VULNERABILITY_CHECK_LIST_GET_REMEDIATION_SUCCESS');
export const getRemediationError = createAction('ASSET_VULNERABILITY_CHECK_LIST_GET_REMEDIATION_ERROR');

export const getRemediation = (id) => async (dispatch) => {
  try {
    dispatch(getRemediationRequest());
    const resp = await instance.get(`/v1/Remediation/GetByWorkflowActivityId?WorkflowActivityId=${id}`);
    
    dispatch(getRemediationSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getRemediationError(e.response.data.description));
    else
      dispatch(getRemediationError(e.message));
  }
};

export const getSchemaRequest = createAction('ASSET_VULNERABILITY_CHECK_LIST_GET_SCHEMA_REQUEST');
export const getSchemaSuccess = createAction('ASSET_VULNERABILITY_CHECK_LIST_GET_SCHEMA_SUCCESS');
export const getSchemaError = createAction('ASSET_VULNERABILITY_CHECK_LIST_GET_SCHEMA_ERROR');

export const getSchema = () => async (dispatch) => {
  try {
    dispatch(getSchemaRequest());
    const resp = await instance.get(`/v1/AssetVulnerability/GetSchema`);
    
    dispatch(getSchemaSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getSchemaError(e.response.data.description));
    else
      dispatch(getSchemaError(e.message));
  }
};

export const getVulnerabilityStatusesRequest = createAction('ASSET_VULNERABILITY_CHECK_LIST_GET_VULNERABILITY_STATUS_REQUEST');
export const getVulnerabilityStatusesSuccess = createAction('ASSET_VULNERABILITY_CHECK_LIST_GET_VULNERABILITY_STATUS_SUCCESS');
export const getVulnerabilityStatusesError = createAction('ASSET_VULNERABILITY_CHECK_LIST_GET_VULNERABILITY_STATUS_ERROR');

export const getVulnerabilityStatuses = () => async (dispatch) => {
  try {
    dispatch(getVulnerabilityStatusesRequest());
    const resp = await instance.get(`/v1/VulnerabilityStatus/GetAll`);
    
    dispatch(getVulnerabilityStatusesSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getVulnerabilityStatusesError(e.response.data.description));
    else
      dispatch(getVulnerabilityStatusesError(e.message));
  }
};


export const getClassificationsRequest = createAction('ASSET_VULNERABILITY_CHECK_LIST_GET_CLASSIFICATIONS_REQUEST');
export const getClassificationsSuccess = createAction('ASSET_VULNERABILITY_CHECK_LIST_GET_CLASSIFICATIONS_SUCCESS');
export const getClassificationsError = createAction('ASSET_VULNERABILITY_CHECK_LIST_GET_CLASSIFICATIONS_ERROR');

export const getClassifications = () => async (dispatch) => {
  try {
    dispatch(getClassificationsRequest());
    const resp = await instance.get(`/v1/Classification/GetAll`);
    
    dispatch(getClassificationsSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getClassificationsError(e.response.data.description));
    else
      dispatch(getClassificationsError(e.message));
  }
};

export const getDomainsRequest = createAction('ASSET_VULNERABILITY_CHECK_LIST_GET_DOMAINS_REQUEST');
export const getDomainsSuccess = createAction('ASSET_VULNERABILITY_CHECK_LIST_GET_DOMAINS_SUCCESS');
export const getDomainsError = createAction('ASSET_VULNERABILITY_CHECK_LIST_GET_DOMAINS_ERROR');

export const getDomains = () => async (dispatch) => {
  try {
    dispatch(getDomainsRequest());
    const resp = await instance.get(`/v1/Domain/GetAll`);
    
    dispatch(getDomainsSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getDomainsError(e.response.data.description));
    else
      dispatch(getDomainsError(e.message));
  }
};

export const getRatingScoresRequest = createAction('ASSET_VULNERABILITY_CHECK_LIST_GET_RATING_SCORES_REQUEST');
export const getRatingScoresSuccess = createAction('ASSET_VULNERABILITY_CHECK_LIST_GET_RATING_SCORES_SUCCESS');
export const getRatingScoresError = createAction('ASSET_VULNERABILITY_CHECK_LIST_GET_RATING_SCORES_ERROR');

export const getRatingScores = () => async (dispatch) => {
  try {
    dispatch(getRatingScoresRequest());
    const resp = await instance.get(`/v1/RatingScore/GetByCVSS`);
    
    dispatch(getRatingScoresSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getRatingScoresError(e.response.data.description));
    else
      dispatch(getRatingScoresError(e.message));
  }
};

export const getContextualScoresRequest = createAction('ASSET_VULNERABILITY_CHECK_LIST_GET_CONTEXTUAL_SCORES_REQUEST');
export const getContextualScoresSuccess = createAction('ASSET_VULNERABILITY_CHECK_LIST_GET_CONTEXTUAL_SCORES_SUCCESS');
export const getContextualScoresError = createAction('ASSET_VULNERABILITY_CHECK_LIST_GET_CONTEXTUAL_SCORES_ERROR');

export const getContextualScores = () => async (dispatch) => {
  try {
    dispatch(getContextualScoresRequest());
    const resp = await instance.get(`/v1/RatingScore/GetByContextualScore`);
    
    dispatch(getContextualScoresSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getContextualScoresError(e.response.data.description));
    else
      dispatch(getContextualScoresError(e.message));
  }
};

export const getCheckListRequest = createAction('ASSET_VULNERABILITY_CHECK_LIST_GET_REQUEST');
export const getCheckListSuccess = createAction('ASSET_VULNERABILITY_CHECK_LIST_GET_SUCCESS');
export const getCheckListError = createAction('ASSET_VULNERABILITY_CHECK_LIST_GET_ERROR');

export const getCheckList = (data) => async (dispatch) => {
  try {
    dispatch(getCheckListRequest());
    const resp = await instance.post(`/v1/AssetVulnerability/GetListForChecklist`, data);

    dispatch(getCheckListSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getCheckListError(e.response.data.description));
    else
      dispatch(getCheckListError(e.message));
  }
};

export const saveCheckListRequest = createAction('SAVE_ASSET_VULNERABILITY_CHECK_LIST_REQUEST');
export const saveCheckListSuccess = createAction('SAVE_ASSET_VULNERABILITY_CHECK_LIST_SUCCESS');
export const saveCheckListError = createAction('SAVE_ASSET_VULNERABILITY_CHECK_LIST_ERROR');

export const saveCheckList = (data) => async (dispatch) => {
  try {
    dispatch(saveCheckListRequest());
    const resp = await instance.put(`/v1/AssetVulnerability/UpdateRemediationChk`, data);

    dispatch(saveCheckListSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(saveCheckListError(e.response.data.description));
    else
      dispatch(saveCheckListError(e.message));
  }
};

export const removeItemsRequest = createAction('ASSET_VULNERABILITY_CHECK_LIST_REMOVE_FROM_REMEDIATION_REQUEST');
export const removeItemsSuccess = createAction('ASSET_VULNERABILITY_CHECK_LIST_REMOVE_FROM_REMEDIATION_SUCCESS');
export const removeItemsError = createAction('ASSET_VULNERABILITY_CHECK_LIST_REMOVE_FROM_REMEDIATION_ERROR');

export const removeItems = (data) => async (dispatch) => {
  try {
    dispatch(removeItemsRequest());
    const resp = await instance.put(`/v1/AssetVulnerability/RemoveRemediationItems`, data);

    dispatch(removeItemsSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(removeItemsError(e.response.data.description));
    else
      dispatch(removeItemsError(e.message));
  }
};

export const resetTableDataRequest = createAction('ASSET_VULNERABILITY_CHECK_LIST_RESET_TABLE_DATA_REQUEST');

export const resetTableData = (data) => async (dispatch) => {
    dispatch(resetTableDataRequest(data));
}