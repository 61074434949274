import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

const ChatModal = ({
    open, 
    onHideModal
}) => {

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") 
            return;
        
        onHideModal();
    }

    return(
        <Dialog
          fullWidth
          maxWidth='md'
          open={open}
          onClose={handleClose}
        >
            <DialogTitle>Chat</DialogTitle>
            <DialogContent>
                Work in progress...
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
      </Dialog>
    );
}

export default ChatModal;