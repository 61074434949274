import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getUserProfileRequest,
  getUserProfileSuccess,
  getUserProfileError,
  getUserOptionsRequest,
  getUserOptionsSuccess,
  getUserOptionsError,
  updateUserRequest,
  updateUserSuccess,
  updateUserError,
  getAvatarRequest,
  getAvatarSuccess,
  getAvatarError,
  deleteAvatarRequest,
  deleteAvatarSuccess,
  deleteAvatarError
} from './userInfoAction';

const defaultState = {
  user: null,
  isLoadingUser: false,
  getUserError: null,
  userOptions: null,
  isLoadingUserOptions: false,
  getUserOptionsError: null,
  avatar: null,
  isLoadingAvatar: false,
  getAvatarError: null,
  isUpdatingUser: false,
  updateUserError: null,
  updatedUser: false,
  isDeletingAvatar: false,
  deleteAvatarError: null,
  deletedAvatar: false
};

export default handleActions(
  {
    [getUserProfileRequest](state) {
      return {
        ...state,
        isLoadingUser: true,
        getUserError: null,
      };
    },
    [getUserProfileSuccess](state, { payload }) {
      return {
        ...state,
        user: payload,
        isLoadingUser: false,
        getUserError: null,
      };
    },
    [getUserProfileError](state, { payload }) {
      return {
        ...state,
        isLoadingUser: false,
        getUserError: payload,
      };
    },
    [getUserOptionsRequest](state) {
      return {
        ...state,
        isLoadingUserOptions: true,
        getUserOptionsError: null,
      };
    },
    [getUserOptionsSuccess](state, { payload }) {
      return {
        ...state,
        userOptions: payload,
        isLoadingUserOptions: false,
        getUserOptionsError: null,
      };
    },
    [getUserOptionsError](state, { payload }) {
      return {
        ...state,
        isLoadingUserOptions: false,
        getUserOptionsError: payload,
      };
    },
    [getAvatarRequest](state) {
        return {
            ...state,
            isLoadingAvatar: true,
            getAvatarError: null,
        };
    },
    [getAvatarSuccess](state, { payload }) {
        return {
            ...state,
            avatar: payload,
            isLoadingAvatar: false,
            getAvatarError: null,
        };
    },
    [getAvatarError](state, { payload }) {
        return {
            ...state,
            isLoadingAvatar: false,
            getAvatarError: payload,
        };
    },
    [updateUserRequest](state) {
        return {
            ...state,
            isUpdatingUser: true,
            updateUserError: null,
            updatedUser: false
        };
    },
    [updateUserSuccess](state, { payload }) {
        return {
            ...state,
            isUpdatingUser: false,
            updateUserError: null,
            updatedUser: true
        };
    },
    [updateUserError](state, { payload }) {
        return {
            ...state,
            isUpdatingUser: false,
            updateUserError: payload,
            updatedUser: false
        };
    },
    [deleteAvatarRequest](state) {
      return {
          ...state,
          isDeletingAvatar: true,
          deleteAvatarError: null,
          deletedAvatar: false
      };
    },
    [deleteAvatarSuccess](state, { payload }) {
        return {
            ...state,
            isDeletingAvatar: false,
            deleteAvatarError: null,
            deletedAvatar: true,
            avatar: null
        };
    },
    [deleteAvatarError](state, { payload }) {
        return {
            ...state,
            isDeletingAvatar: false,
            deleteAvatarError: payload,
            deletedAvatar: false
        };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);