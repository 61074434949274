import { Box, Chip } from "@mui/material";
import { useSelector } from "react-redux";

const TopbarImpersonating = () => {

    const tokenPayload = useSelector(({ auth }) => auth.tokenPayload);

    return(
        tokenPayload && tokenPayload.isImpersonating === "True" ? 
        <Box sx={{
            marginBlock: 'auto'
        }}>
            <Chip label="Impersonating" color="error" />
        </Box>
        : 
        <></>
    );
}

export default TopbarImpersonating;