import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  updateServiceRequest,
  updateServiceSuccess,
  updateServiceError
} from './updateServiceAction';

const defaultState = {
  isUpdatingService: false,
  updateServiceError: null,
  updatedService: false
};

export default handleActions(
  {
    [updateServiceRequest](state) {
      return {
        ...state,
        isUpdatingService: true,
        updateServiceError: null,
        updatedService: false
      };
    },
    [updateServiceSuccess](state, { payload }) {
      return {
        ...state,
        isUpdatingService: false,
        updateServiceError: null,
        updatedService: true
      };
    },
    [updateServiceError](state, { payload }) {
      return {
        ...state,
        isUpdatingService: false,
        updateServiceError: payload,
        updatedService: false
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);