import { Backdrop, Box, Button, CircularProgress, Paper, Tab, Tabs, Typography} from "@mui/material"; 
import { useNavigate, useParams } from "react-router-dom";
import StakeholderForm from "../../../Activities/EditStep/SASteps/components/customSurveyComponents/stakeholderInformation/stakeholderForm";
import { useForm } from "react-hook-form";
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { Col, Container, Row } from "react-bootstrap";
import { FormSelectField } from '@/shared/components/form/material-controls/SelectField';
import styled from "styled-components";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';

const SupplierGeneralInfo = ({
    supplier,
    asset,
    onUpdateSupplier,
    stakeholderTypes,
    logo,
    isLoadingLogo,
    onClickEditLogo,
    onClickDeleteLogo
}) => {

    const { handleSubmit, reset, setValue, watch, control, getValues } = useForm();
    
    const navigate = useNavigate();

    const onClickCancel = () => { 
        navigate("/Registry/Stackholders");
    }

    return(
        <>
            <Row>
                <Col md={12}>
                    <FormTextField
                        name='name'
                        label='Name'
                        control={control}
                        rules={{ required: 'This field is required' }}
                        defaultValue={supplier?.name}
                    />
                </Col>
                <Col md={12}>
                    <FormTextField
                        name='description'
                        label='Description'
                        control={control}
                        multiline
                        rows={4}
                        defaultValue={supplier?.description}
                    />
                </Col>
                {/* <Col md={12}>
                    <FormSelectField
                        name='stakeholderTypeId'
                        label='Stakeholder Type'
                        control={control}
                        options={stakeholderTypes}
                        rules={{ required: 'This field is required' }}
                        keyValue={{ id:"stakeholderTypeId", label:"name" }}
                        supplier={supplier?.stakeholderTypeId}
                    />
                </Col>  */}
            </Row>
            <StakeholderForm
                control={control}
                setValue={setValue}
                watch={watch}
                stakeholderInfo={asset?.metadata?.stakeholderGeneralInfo}
            />

            <Row style={{
                marginTop: '20px'
            }}>
                <Col md={6}>
                    <ProfileInformation>
                        <ProfileAvatar>
                        { isLoadingLogo ? <CircularProgressWrapper /> : 
                            !logo ? 
                            <img src="/img/avatar.svg" alt="avatar"/>
                            :
                            <img src={URL.createObjectURL(logo)} alt="avatar" />
                        }
                        </ProfileAvatar>
                        <div style={{display: 'flex', flexDirection: 'column', marginLeft: 20}}>
                            <Button 
                                endIcon={<AddAPhotoIcon />} 
                                onClick={onClickEditLogo} 
                                variant='contained' 
                                color="primary">
                            Change Logo
                            </Button>
                            <Button 
                                disabled={logo === null}
                                style={{marginTop: 10}}
                                endIcon={<NoPhotographyIcon />} 
                                onClick={onClickDeleteLogo} 
                                variant='contained' 
                                color="error">
                            Delete Logo
                            </Button>
                        </div>
                    </ProfileInformation>
                </Col>
            </Row>
            
            <Row>
                <Col md={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: "flex-end",
                        marginTop: '20px'
                    }}>
                        <Button variant="outlined" color="primary" onClick={onClickCancel} sx={{marginRight: '20px'}}>Cancel</Button>
                        <Button variant="contained" color="primary"  onClick={handleSubmit(onUpdateSupplier)}>Update</Button>
                    </Box>
                </Col>
            </Row>
        </>
    );
};



const ProfileAvatar = styled.div`
  height: 200px;
  width: auto;

  img {
    height: 100%;
  }

  @media (max-width: 1345px) and (min-width: 1200px) {
    height: 130px;
    width: 130px;
  }
`;

const ProfileInformation = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
`;

export default SupplierGeneralInfo;