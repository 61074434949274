import { useDispatch } from "react-redux";
import { TopbarButton } from "./BasicTopbarComponents";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { toggleChatbot } from "@/reducers/chatbot/chatbotAction";
import BotAvatar from "../../../shared/components/chatbot/BotAvatar";
import { Box } from "@mui/material";

const TopbarChatbot = () => {
    
    const dispatch = useDispatch();
    
    const show = process.env.REACT_APP_SHOW_BOT === "true";

    const handleClickChatbot = () => {
        dispatch(toggleChatbot());
    }

    return(
        show && 
        <TopbarButton type="button" onClick={handleClickChatbot}>
            <Box sx={{
                display:'flex',
                justifyContent: 'center',
                height: '100%',
                alignItems: 'center'
            }}>
                <BotAvatar size="40px"/>
            </Box>
        </TopbarButton>
    );
};

export default TopbarChatbot;