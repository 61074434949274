import { Chip } from "@mui/material";

const TransparentTextChip = ({ sx, ...other }) => {
    return(
        <Chip
            sx={{
                fontSize: '14px',
                fontWeight: 'bold',
                color: 'rgb(20, 20, 20, 0.6)',
                ...sx
            }} 
            {...other} 
        />
    );
};

export default TransparentTextChip;