import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  updateDomainRequest,
  updateDomainSuccess,
  updateDomainError
} from './updateDomainAction';

const defaultState = {
  isUpdatingDomain: false,
  updateDomainError: null,
  updatedDomain: false
};

export default handleActions(
  {
    [updateDomainRequest](state) {
      return {
        ...state,
        isUpdatingDomain: true,
        updateDomainError: null,
        updatedDomain: false
      };
    },
    [updateDomainSuccess](state, { payload }) {
      return {
        ...state,
        isUpdatingDomain: false,
        updateDomainError: null,
        updatedDomain: true
      };
    },
    [updateDomainError](state, { payload }) {
      return {
        ...state,
        isUpdatingDomain: false,
        updateDomainError: payload,
        updatedDomain: false
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);