import { Backdrop, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { cleanup, addClassification } from "@/reducers/classification/addClassification/addClassificationAction";
import { FormNumberField } from "@/shared/components/form/material-controls/NumberField";
import { FormColorField } from "@/shared/components/form/material-controls/ColorField";

const CreateClassificationModal = ({ open, onHideModal, onClassificationCreated }) => {
    const dispatch = useDispatch();
  
    const { 
      isAddingClassification, addClassificationError, addedClassification
    } = useSelector(({ addClassification }) => addClassification);

    useEffect(() => {
      return () => {
        dispatch(cleanup());
      }
    }, [dispatch]);
  
    const { handleSubmit, reset, control, setValue, getValues } = useForm();

    const handleClose = (event, reason) => {
      if (reason && reason === "backdropClick") 
          return;
      
      reset();
      onHideModal();
    }
  
    useEffect(() => {
      if(addClassificationError){
        enqueueSnackbar(addClassificationError, {variant: "error"});
      }
    }, [addClassificationError]);
  
    const onSubmit = (data) => { 
      dispatch(addClassification(data));
    }
  
    useEffect(() => {
      if(addedClassification){
        enqueueSnackbar("Classification created successfully", {variant: "success"});
        handleClose();
        onClassificationCreated();
      }
    }, [addedClassification]);
  
    return (
      <Dialog
          fullWidth
          maxWidth='lg'
          scroll='paper'
          open={open}
          onClose={handleClose}
      >
          <DialogTitle>Create Classification</DialogTitle>
          <DialogContent>
                <Row>
                  <Col md={12}>
                    <FormTextField
                        name='name'
                        label='Name'
                        control={control}
                        rules={{ required: 'This field is required' }}
                    />
                  </Col>
                  <Col md={12}>
                    <FormTextField 
                        name='description'
                        label='Description'
                        control={control}
                        multiline
                        rows={4}
                    />
                  </Col>
                  <Col md={12}>
                    <FormNumberField
                        name='value'
                        label='Value'
                        control={control}
                        rules={{ required: 'This field is required' }}
                    />
                  </Col>
                  <Col md={12}>
                    <FormColorField 
                        name="color" 
                        label="Color" 
                        control={control} 
                        rules={{ required: 'This field is required' }}
                    />
                  </Col>
                </Row>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant='contained' onClick={handleSubmit(onSubmit)}>Create</Button>
          </DialogActions>
  
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isAddingClassification}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
      </Dialog>
    );
  }
  
  export default CreateClassificationModal;