import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_MASSIVE_UPDATE_CHECK_LIST');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const massiveUpdateRequest = createAction('MASSIVE_UPDATE_CHECK_LIST_REQUEST');
export const massiveUpdateSuccess = createAction('MASSIVE_UPDATE_CHECK_LIST_SUCCESS');
export const massiveUpdateError = createAction('MASSIVE_UPDATE_CHECK_LIST_ERROR');

export const massiveUpdate = (data) => async (dispatch) => {
  try {
    dispatch(massiveUpdateRequest());
    const resp = await instance.put(`/v1/AssetVulnerability/UpdateRemediationChkGroup`, data);

    dispatch(massiveUpdateSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(massiveUpdateError(e.response.data.description));
    else
      dispatch(massiveUpdateError(e.message));
  }
};

export const clearScheduledDatesRequest = createAction('CLEAR_SCHEDULED_DATES_REQUEST');
export const clearScheduledDatesSuccess = createAction('CLEAR_SCHEDULED_DATES_SUCCESS');
export const clearScheduledDatesError = createAction('CLEAR_SCHEDULED_DATES_ERROR');

export const clearScheduledDates = (data) => async (dispatch) => {
  try {
    dispatch(clearScheduledDatesRequest());
    const resp = await instance.post(`/v1/AssetVulnerability/ClearScheduledDates`, data);

    dispatch(clearScheduledDatesSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(clearScheduledDatesError(e.response.data.description));
    else
      dispatch(clearScheduledDatesError(e.message));
  }
};