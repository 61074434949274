import { Avatar, List, Button, Card, CardContent, Icon, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Typography, CardActions } from "@mui/material"

const SubscriptionCard = ({
    subscriptionType,
    onSelect,
    isSelected
}) => {

    return(
        <Card sx={{
            height: '100%',
            border: isSelected ? (theme) => `1px solid ${theme.palette.primary.main}` : 'none'
        }}>
            <CardContent style={{height: '100%'}}>
                <div style={{
                    height: '100%',
                    display: 'flex', 
                    flexDirection: 'column', 
                    justifyContent: 'space-between'
                }}>
                    <div>
                        <div style={{display: "flex"}}>
                            <Icon style={{
                                marginRight: 10, 
                                fontSize: 70
                            }}>
                                {subscriptionType.icon}
                            </Icon>

                            <div>
                                <Typography variant="h5">
                                    {subscriptionType.name}
                                </Typography>
                                
                                <Typography variant="body2" color="text.secondary">
                                    {subscriptionType.description}
                                </Typography>
                            </div>
                        </div>
                        <List>
                            {subscriptionType.subscriptionTypeModules.map((module, index) =>
                                <ListItem>
                                    <ListItemIcon>
                                        <Icon color="success">
                                            done
                                        </Icon>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={module.applicationModule.label}
                                    />
                                </ListItem>
                            )}
                        </List>
                    </div>
                    <div>
                        <Button 
                            fullWidth 
                            variant="contained" 
                            size="large"
                            onClick={() => onSelect(subscriptionType)}
                            disabled={isSelected}
                        >
                            Select
                        </Button>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
}

export default SubscriptionCard;