import { Backdrop, Box, CircularProgress, Divider, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import { useEffect } from "react";
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { FormSelectField } from '@/shared/components/form/material-controls/SelectField';
import { Col, Row } from "react-bootstrap";
import { getCountries, cleanup } from "@/reducers/selfAssessment/stakeholderForm/stakeholderFormAction";
import StakeholderCountry from "./stakeholderCountry";

const StakeholderForm = ({
    stakeholderInfo,
    control,
    setValue,
    watch
}) => {
    const dispatch = useDispatch();

    const { 
        countries, isLoadingCountries, getCountriesError
    } = useSelector(({ stakeholderForm }) => stakeholderForm);

    useEffect(() => {
        dispatch(getCountries());

        return () => {
            dispatch(cleanup());
        }
    }, []);

    useEffect(() => {
        if(getCountriesError){
          enqueueSnackbar(getCountriesError, {variant: "error"});
        }
    }, [getCountriesError]);

    return (
        isLoadingCountries ? <CircularProgressWrapper /> :

        <Row style={{ paddingTop: '10px' }}>
            <Col md={12}>
                <Typography>Contact information</Typography>
            </Col>

            <Col md={6}>
                <FormTextField
                    name="emailAddress"
                    label="Email"
                    control={control}
                    defaultValue={stakeholderInfo?.emailAddress}
                    style={{marginTop: 10}}
                    rules={{ required: 'This field is required' }}
                />
            </Col>
            <Col md={6}>
                <FormTextField
                    name="pecEmailAddress"
                    label="PEC Email"
                    control={control}
                    defaultValue={stakeholderInfo?.pecEmailAddress}
                    style={{marginTop: 10}}
                />
            </Col>
            <Col md={6}>
                <FormTextField
                    name="phoneNumber"
                    label="Phone Number"
                    control={control}
                    defaultValue={stakeholderInfo?.pecEmailAddress}
                />
            </Col>
            <Col md={6}>
                <FormTextField
                    name="businessRole"
                    label="Business Role"
                    control={control}
                    defaultValue={stakeholderInfo?.emailAddress}
                />
            </Col>            

            <Col md={12}>
                <Divider component='div' sx={{marginTop: '20px', marginBottom: '20px'}}/>
                <Typography>Legal information</Typography>
            </Col>

            <Col md={6}>
                <FormTextField
                    name="billingName"
                    label="Legal Name"
                    control={control}
                    defaultValue={stakeholderInfo?.billingName}
                    style={{marginTop: 10}}
                    rules={{ required: 'This field is required' }}
                />
            </Col>
            <Col md={6}>
                <FormTextField
                    name="billingAddress"
                    label="Address"
                    control={control}
                    defaultValue={stakeholderInfo?.billingAddress}
                    style={{marginTop: 10}}
                    rules={{ required: 'This field is required' }}
                />
            </Col>
            {/* <Col md={4}>
                <FormTextField
                    name="billingCountry"
                    label="Country"
                    control={control}
                    defaultValue={stakeholderInfo?.billingCountry}
                    rules={{ required: 'This field is required' }}
                />
            </Col> */}
            <Col md={6}>
                <FormTextField
                    name="billingCity"
                    label="City"
                    control={control}
                    defaultValue={stakeholderInfo?.billingCity}
                    rules={{ required: 'This field is required' }}
                />
            </Col>
            <Col md={6}>
                <FormTextField
                    name="billingZipCode"
                    label="Zip Code"
                    control={control}
                    defaultValue={stakeholderInfo?.billingZipCode}
                    rules={{ required: 'This field is required' }}
                />
            </Col>
            <Col md={6}>
                <FormTextField
                    name="vat"
                    label="VAT"
                    control={control}
                    defaultValue={stakeholderInfo?.vat}
                />
            </Col>
            <Col md={6}>
                <FormTextField
                    name="vatCode"
                    label="SDI Code"
                    control={control}
                    defaultValue={stakeholderInfo?.vatCode}
                />
            </Col>
            
            <StakeholderCountry 
                control={control}
                countries={countries}
                watch={watch}
                setValue={setValue}
                stakeholderInfo={stakeholderInfo}
            />
        </Row>
    )
};

export default StakeholderForm;