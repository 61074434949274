import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  addServiceRequest,
  addServiceSuccess,
  addServiceError
} from './addServiceAction';

const defaultState = {
  isAddingService: false,
  addServiceError: null,
  addedService: false
};

export default handleActions(
  {
    [addServiceRequest](state) {
      return {
        ...state,
        isAddingService: true,
        addServiceError: null,
        addedService: false
      };
    },
    [addServiceSuccess](state, { payload }) {
      return {
        ...state,
        isAddingService: false,
        addServiceError: null,
        addedService: true
      };
    },
    [addServiceError](state, { payload }) {
      return {
        ...state,
        isAddingService: false,
        addServiceError: payload,
        addedService: false
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);