import { createAction } from 'redux-actions';
import { instance } from "../../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_UPDATE_LOCATION');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const updateLocationRequest = createAction('UPDATE_LOCATION_POST_REQUEST');
export const updateLocationSuccess = createAction('UPDATE_LOCATION_POST_SUCCESS');
export const updateLocationError = createAction('UPDATE_LOCATION_POST_ERROR');

export const updateLocation = (request) => async (dispatch) => {
  try {
    dispatch(updateLocationRequest());
    const resp = await instance.put(`/v1/Asset/UpdateFull`, request);

    dispatch(updateLocationSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(updateLocationError(e.response.data.description));
    else
      dispatch(updateLocationError(e.message));
  }
};
