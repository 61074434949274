import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getRolesRequest,
  getRolesSuccess,
  getRolesError,
  getTenantsRequest,
  getTenantsSuccess,
  getTenantsError,
  updateUserRequest,
  updateUserSuccess,
  updateUserError,
  getUserRequest,
  getUserSuccess,
  getUserError
} from './systemUpdateUserAction';

const defaultState = {
  user: null,
  isLoadingUser: false,
  getUserError: null,
  roles: [],
  isLoadingRoles: true,
  getRolesError: null,
  tenants: null,
  isLoadingTenants: true,
  getTenantsError: null,
  isUpdatingUser: false,
  updatedUser: false,
  updateUserError: null
};

export default handleActions(
  {
    [getUserRequest](state) {
      return {
        ...state,
        isLoadingUser: true,
        getUserError: null,
      };
    },
    [getUserSuccess](state, { payload }) {
      return {
        ...state,
        user: payload,
        isLoadingUser: false,
        getUserError: null,
      };
    },
    [getUserError](state, { payload }) {
      return {
        ...state,
        isLoadingUser: false,
        getUserError: payload,
      };
    },
    [getRolesRequest](state) {
      return {
        ...state,
        isLoadingRoles: true,
        getRolesError: null,
      };
    },
    [getRolesSuccess](state, { payload }) {
      return {
        ...state,
        roles: payload,
        isLoadingRoles: false,
        getRolesError: null,
      };
    },
    [getRolesError](state, { payload }) {
      return {
        ...state,
        isLoadingRoles: false,
        getRolesError: payload,
      };
    },
    [getTenantsRequest](state) {
        return {
        ...state,
        isLoadingTenants: true,
        getTenantsError: null,
        };
    },
    [getTenantsSuccess](state, { payload }) {
        return {
        ...state,
        tenants: payload,
        isLoadingTenants: false,
        getTenantsError: null,
        };
    },
    [getTenantsError](state, { payload }) {
        return {
        ...state,
        isLoadingTenants: false,
        getTenantsError: payload,
        };
    },
    [updateUserRequest](state) {
      return {
        ...state,
        isUpdatingUser: true,
        updateUserError: null,
      };
    },
    [updateUserSuccess](state, { payload }) {
      return {
        ...state,
        updatedUser: true,
        isUpdatingUser: false,
        updateUserError: null,
      };
    },
    [updateUserError](state, { payload }) {
      return {
        ...state,
        isUpdatingUser: false,
        updateUserError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);
