import { Box, Icon } from "@mui/material";
import { GridToolbar, DataGrid, gridClasses, GridActionsCellItem } from '@mui/x-data-grid';
import TransparentTextChip from "@/shared/components/TransparentTextChip";
import ClassificationChip from '../../../../AssetManagement/AssetInventory/components/classificationChip';
import { useState } from "react";
import ExpandableCell from "@/shared/components/datagrid/ExpandableCell";
import dayjs from "dayjs";
import VulnerabilityInfoModal from "../../../../VulnerabilityManagement/Vulnerabilities/components/vulnerabilityInfoModal";
import ScanModal from "./scanModal";
import MaterialAssetInfoModal from "../../../../AssetManagement/AssetInventory/components/materialAssetInfoModal";

const AssetVulnerabilityTable = ({
    classifications,
    ratingScores,
    contextualScores,
    setSelectedRows,
    selectedRows,
    isLoadingAssetVulnerabilities,
    assetVulnerabilities
}) => {

    const [showVulnerabilityInfoModal, setShowVulnerabilityInfoModal] = useState(false);
    const [selectedVulnerability, setSelectedVulnerability] = useState(null);
    
    const [showAssetInfoModal, setShowAssetInfoModal] = useState(false);
    const [selectedAssetId, setSelectedAssetId] = useState(null);
  
    const [showScanModal, setShowScanModal] = useState(false);
    const [selectedVulnerabilityScan, setSelectedVulnerabilityScan] = useState(null);


    const onCellClick = (params, event, details) => {
        if(params.field === "asset"){
          onClickAssetInfo(params.row?.assetId);
        }
        else if(params.field === "vulnerability"){
          handleClickViewVulnerability(params.row.vulnerabilityId);
        }
        else if(params.field === "scanDetails"){
          onClickScan(params.row.scanDetails);
        }
    }

    const onClickScan = (scanDetails) => {
        setSelectedVulnerabilityScan(scanDetails);
        setShowScanModal(true);
    }
    
    const onHideModal = () => {
        setShowScanModal(false);
        setSelectedVulnerabilityScan(null);
    };

    const onClickAssetInfo = (assetId) => {
        setSelectedAssetId(assetId);
        setShowAssetInfoModal(true);
    };

    const onHideAssetInfoModal = () => {
        setShowAssetInfoModal(false);
        setSelectedAssetId(null);
    };

    const handleClickViewVulnerability = (vulnerability) => {
        setSelectedVulnerability(vulnerability);
        setShowVulnerabilityInfoModal(true);
    }
    
    const onHideVulnerabilityInfoModal = () => {
        setShowVulnerabilityInfoModal(false);
        setSelectedVulnerability(null);
    };

    const mapAssetVulnerabilities = (assetVulnerabilities) => {
        var mappedData = JSON.parse(JSON.stringify(assetVulnerabilities));
      
        mappedData.forEach((m, index) => {
            m.vulnerability = JSON.stringify({ vulnerabilityCVE: m.vulnerabilityCVE, vulnerabilityName: m.vulnerabilityName, cwe: m.cwe });
            m.asset = JSON.stringify({ assetName: m.assetName, ip: m.ip, confidentiality: m.confidentiality, integrity: m.integrity, availability: m.availability });
            m.scanDetails = JSON.stringify(m.scanDetails);
        });
      
        return mappedData;
    };

    const columns = [
        { 
          field: 'asset', 
          headerName: 'Asset', 
          minWidth: 350,
          flex: 1,
          renderHeader: (params) => (<Box sx={{ display: 'flex', alignItems: 'center' }}>Asset <Icon style={{marginLeft: 10}}>ads_click</Icon></Box>),
          renderCell: (params) => {
            if(params.value){
              const value = JSON.parse(params.value);
    
              const confidentiality = classifications.find(r => r.name === value.confidentiality);
              const integrity = classifications.find(r => r.name === value.integrity);
              const availability = classifications.find(r => r.name === value.availability);
              
              return(
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%'
                }}>
                  <div>{value.ip}</div>
                  <div>{value.assetName}</div>
    
                  { confidentiality && integrity && availability &&
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignContent: 'center',
                    width: '100%',
                    marginTop: 1
                  }}>
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: 'center',
                    }}>
                      <ClassificationChip sx={{marginLeft: 0}} letter="C" name="Confidentiality" classification={confidentiality} />
                    </Box>
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: 'center'
                    }}>
                      <ClassificationChip letter="I"  name="Integrity"  classification={integrity} />
                    </Box>
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: 'center'
                    }}>
                      <ClassificationChip letter="A"  name="Availability"  classification={availability} />
                    </Box>
                  </Box>
                  }
                </Box>
              );
            }
    
            return <></>;
          }
        },
        {
          field: 'scanDetails', 
          headerName: 'Scan Details',
          minWidth: 350,
          flex: 1,
          renderHeader: (params) => (<Box sx={{ display: 'flex', alignItems: 'center' }}>Scan Details <Icon style={{marginLeft: 10}}>ads_click</Icon></Box>),
          renderCell: (params) => {
            if(params.value){
              return <ExpandableCell sx={{ lineBreak: 'anywhere' }} value={params.value} maxLength={80} />;
            }
    
            return <></>;
          }
        },
        { 
            field: 'vulnerability', 
            headerName: 'Vulnerability',
            minWidth: 350,
            flex: 1,
            renderHeader: (params) => (<Box sx={{ display: 'flex', alignItems: 'center' }}>Vulnerability <Icon style={{marginLeft: 10}}>ads_click</Icon></Box>),
            renderCell: (params) => {
              if(params.value){
                const value = JSON.parse(params.value);
    
                return(
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                  }}>
                    <div>{value.vulnerabilityCVE} {value.cwe && <span>({value.cwe})</span>}</div>
                    <ExpandableCell value={value.vulnerabilityName} maxLength={80} />
                  </Box>
                );
              }
    
              return <></>;
            }
        },
        { 
          field: 'contextualScore', 
          headerName: 'Contextual Score', 
          minWidth: 200,
          flex: 1,
          renderCell: (params) => {
            if(!params.value)
              return <></>;
    
            const contextualScore = contextualScores.find(r => r.minValue <= params.value && r.maxValue >= params.value);
            return (
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                { contextualScore && 
                  <TransparentTextChip 
                      label={(params.value ? params.value : 0) + " - " + contextualScore.name} 
                      sx={{ 
                        backgroundColor: contextualScore.color 
                      }} 
                    /> 
                }
              </Box>
            );
          },
        },
        { 
          field: 'baseScore', 
          headerName: 'CVSS Base Score', 
          minWidth: 200,
          flex: 1,
          renderCell: (params) => {
            if(!params.value)
              return <></>;
              
            const ratingScore = ratingScores.find(r => r.minValue <= params.value && r.maxValue >= params.value);
            return (
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                { ratingScore && 
                  <TransparentTextChip 
                    label={(params.value ? params.value : 0) + " - " + ratingScore.name} 
                    sx={{ 
                      backgroundColor: ratingScore.color 
                    }} 
                  /> 
                }
              </Box>
            );
          },
        },
        { 
          field: 'epssScore', 
          headerName: 'EPSS Score', 
          minWidth: 150,
          flex: 1,
          valueGetter: (params) => {
            if (!params.value) {
              return params.value;
            }
            return params.value * 100;
          },
          valueFormatter: (params) => {
            if (params.value == null) {
              return '';
            }
            return `${params.value.toFixed(2)}%`;
          }
        },
        { 
          field: 'knownExploited', 
          headerName: 'Known Exploited', 
          minWidth: 150,
          flex: 1
        },
        // { 
        //     field: 'domains', 
        //     headerName: 'Domains', 
        //     minWidth: 150,
        //     flex: 1,
        //     renderCell: (params) => <ExpandableCell {...params} maxLength={150} />,
        // },
        { 
          field: 'source', 
          headerName: 'Source', 
          minWidth: 150,
          flex: 1
        },
        { 
            field: 'createDate', 
            headerName: 'Create Date', 
            type: 'date',
            minWidth: 150,
            flex: 1,
            valueFormatter: params => params.value ? dayjs(params.value).format("DD/MM/YYYY") : ""
        }
    ];

    return(
        <Box sx={{'height': '1000px', 'paddingBottom': '20px'}}>
            <DataGrid           
                density="compact"
                checkboxSelection 
                disableRowSelectionOnClick
                getRowHeight={() => 'auto'}
                getEstimatedRowHeight={() => 59}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setSelectedRows(newRowSelectionModel);
                }}
                rowSelectionModel={selectedRows}
                loading={isLoadingAssetVulnerabilities} 
                rows={mapAssetVulnerabilities(assetVulnerabilities)}
                onCellClick={onCellClick}
                columns={columns} 
                slots={{ toolbar: GridToolbar }} 
                slotProps={{
                    toolbar: {
                    showQuickFilter: true
                    },
                }}
                sx={{
                    [`& .${gridClasses.cell}`]: {
                    py: 1,
                    },
                }}
                initialState={{
                    pagination: { paginationModel: { pageSize: 30 } },
                }}
                pageSizeOptions={[15, 30, 50, 100]}
            />

            { showVulnerabilityInfoModal &&
                <VulnerabilityInfoModal
                    onHideModal={onHideVulnerabilityInfoModal}
                    open={showVulnerabilityInfoModal}
                    vulnerabilityId={selectedVulnerability}
                />
            }

            { showScanModal &&
                <ScanModal 
                    onHideModal={onHideModal} 
                    showModal={showScanModal}
                    scanDetails={selectedVulnerabilityScan}
                    classifications={classifications}
                />
            } 

            { showAssetInfoModal &&
                <MaterialAssetInfoModal 
                    onHideModal={onHideAssetInfoModal} 
                    showModal={showAssetInfoModal}
                    assetId={selectedAssetId}
                    classifications={classifications}
                />
            }
        </Box>
    );
};

export default AssetVulnerabilityTable;