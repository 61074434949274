import { createAction } from 'redux-actions';
import { instance } from "../../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_SYSTEM_TENANT_ADD');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const addTenantRequest = createAction('SYSTEM_TENANT_ADD_REQUEST');
export const addTenantSuccess = createAction('SYSTEM_TENANT_ADD_SUCCESS');
export const addTenantError = createAction('SYSTEM_TENANT_ADD_ERROR');

export const addTenant = (data) => async (dispatch) => {
  try {
    dispatch(addTenantRequest());

    const resp = await instance.post(`/v1/Tenant/Post`, data);
    
    dispatch(addTenantSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(addTenantError(e.response.data.description));
    else
      dispatch(addTenantError(e.message));
  }
};