import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import CytoscapeComponent from "react-cytoscapejs";
import { cleanup, clearAll, addNodes, getClassifications, getNodeParentChildForMerge, getNodeAllPathForMerge, getNodes, getAssetTypes, getByTarget, getBySource, getByLinkName, getByLinkValue } from "@/reducers/cytoscape/cytoscapeAction";
import "./index.css";
import AssetFilters from "./components/assetFilters";
import SimpleLoader from '@/shared/components/SimpleLoader';
import styled from 'styled-components';
import ReactDOMServer from "react-dom/server";
import "cytoscape-context-menus/cytoscape-context-menus.css";
import "cytoscape-navigator/cytoscape.js-navigator.css";
import GraphicSettings from "./components/graphicSettings";
import { enqueueSnackbar } from "notistack";
import MaterialAssetInfoModal from "../AssetInventory/components/materialAssetInfoModal";
import { colorAdditional, colorBorder, colorText } from '@/utils/palette';
import { Backdrop, CircularProgress, Typography, useTheme } from "@mui/material";
import AssetMenu from "./components/assetMenu";
import { hslToHex } from "../../../utils/styles";

var cytoscape = require('cytoscape');
var nodeHtmlLabel = require('cytoscape-node-html-label');
var expandCollapse = require('cytoscape-expand-collapse');
var navigator = require('cytoscape-navigator');
// var undoRedo = require('cytoscape-undo-redo');
 
// undoRedo( cytoscape );
expandCollapse(cytoscape);
nodeHtmlLabel(cytoscape);
navigator(cytoscape);

const EnterpriseArchitecture = () => {
  const dispatch = useDispatch();
  const { assetTypes, isLoadingAssetTypes, getAssetTypesError,
          classifications, isLoadingClassifications, getClassificationsError,
          nodes, isLoadingNodes, getNodesError,
          newNodes, isLoadingNodesForMerge, getNodesForMergeError,
          nodesForAdd, isLoadingNodesForAdd, getNodesForAddError,
  } = useSelector(({ cytoscape }) => cytoscape);

  const [showInfoModal, setShowInfoModal] = useState(false);
  const [clickedAssetId, setClickedAssetId] = useState(null);
  const [cytoscapeStylesheet, setCytoscapeStylesheet] = useState(null);
  const [layout, setLayout] = useState("hierarchy");

  const theme = useTheme();

  const [assetMenuAnchorPosition, setAssetMenuAnchorPosition] = React.useState(null);
  const assetMenuOpen = Boolean(assetMenuAnchorPosition);

  const [hiddenNodes, setHiddenNodes] = useState([]);
  const [cyNodes, setCyNodes] = useState([]);
  
  const openAssetMenu = (anchorPosition, assetId) => {
    setClickedAssetId(assetId);
    setAssetMenuAnchorPosition(anchorPosition);
  };

  const handleCloseAssetMenu = () => {
    setAssetMenuAnchorPosition(null);
  };

  const cy = useRef();
  const cyNav = useRef();
  const cyExpandCollapse = useRef();
  // const cyUndoRedo = useRef();

  useEffect(() => {
    dispatch(getAssetTypes());
    dispatch(getClassifications());

    return () => {
      if(cyNav.current){
        cyNav.current.destroy();
      }
      if(cy.current){
        cy.current.destroy();
      }
      dispatch(cleanup());
    }
}, [dispatch]);

const getMappedNodes = (nodes) => {
  const clonnedElements = JSON.parse(JSON.stringify(nodes));

  clonnedElements.forEach((element, index) =>{
    if(element.data.id){
      element.data.assetType = assetTypes.find(t => t.id === element.data.assetTypeId);
    }
  });

  return clonnedElements;
};

  const handleViewInfo = (assetId) => {
    setShowInfoModal(true);
    handleCloseAssetMenu();
  };

  const handleGetAdjacentNodes = (assetId) => {
    dispatch(getNodeParentChildForMerge(assetId));
    handleCloseAssetMenu();
  };

  const handleGetAllPath = (assetId) => {
    dispatch(getNodeAllPathForMerge(assetId));
    handleCloseAssetMenu();
  };

  const onChangeHiddenNodes = (nodes) => {
    const nodesToShow = hiddenNodes.filter(x => !nodes.includes(x));
    const nodesToHide = nodes.filter(x => !hiddenNodes.includes(x));

    nodesToShow.forEach(n => cy.current.getElementById(n.id).restore());
    nodesToHide.forEach(n => cy.current.getElementById(n.id).remove());

    setHiddenNodes(nodes);
  };

  const handleClearAll = () => {
    dispatch(clearAll());
    setHiddenNodes([]);
  };

  const removeNode = (assetId) => {
    const element = cy.current.getElementById(assetId);

    const nodeData = { 
      id: element._private.data.id, 
      name: element._private.data.name 
    };
    setHiddenNodes([...hiddenNodes, nodeData]);
    
    cy.current.remove(element);
    cy.current.layout(getLayoutConfiguration(layout)).run();
  };

  const GetNodes = (assetIds) => {
    if(nodes && nodes.length){
      dispatch(addNodes(assetIds));
    }
    else{
      dispatch(getNodes(assetIds));
    }
  };

  const GetByTarget = (data) => {
    dispatch(getByTarget(data));
  };

  const GetBySource = (data) => {
    dispatch(getBySource(data));
  };

  const GetByLinkName = (data) => {
    dispatch(getByLinkName(data));
  };

  const GetByLinkValue = (data) => {
    dispatch(getByLinkValue(data));
  };

  const onHideModal = () => {
    setShowInfoModal(false);
  };

  const handleLayoutChange = (event, value) => {
    setLayout(value);
  }

  const onExpandAll = () => {
    cyExpandCollapse.current?.expandAll();
  }

  const onCollapseAll = () => {
    cyExpandCollapse.current?.collapseAll();
  }

  useEffect(() => {
    if(getAssetTypesError){
      enqueueSnackbar(getAssetTypesError, {variant: "error"});
    }
  }, [getAssetTypesError]);

  useEffect(() => {
    if(getNodesForAddError){
      enqueueSnackbar(getNodesForAddError, {variant: "error"});
    }
  }, [getNodesForAddError]);

  useEffect(() => {
    if(getNodesForMergeError){
      enqueueSnackbar(getNodesForMergeError, {variant: "error"});
    }
  }, [getNodesForMergeError]);

  useEffect(() => {
    if(getClassificationsError){
      enqueueSnackbar(getClassificationsError, {variant: "error"});
    }
  }, [getClassificationsError]);

  useEffect(() => {
    if(getNodesError){
      enqueueSnackbar(getNodesError, {variant: "error"});
    }
  }, [getNodesError]);

  const refreshCyNodes = () => {
    setCyNodes(cy.current.nodes().map(n => {
      return { 
        id: n._private.data.id, 
        name: n._private.data.name 
      }
    }));
  };

  useEffect(() => {
    if(nodes && nodes.length && cy.current && assetTypes && assetTypes.length){      
      const customHtmlNodes = [];
      
      // var conditionTypeSelector = "";
      
      // const selectedNode = nodes.find(n => n.data.id === selectedAssetId);
      // if(selectedNode && selectedNode.data && selectedNode.data.assetTypeId){
      //   const selectedAssetType = assetTypes.find(t => t.id === selectedNode.data.assetTypeId);
      //   customHtmlNodes.push({
      //     query: `node[id = "${selectedAssetId}"]`,
      //     halign: "center",
      //     valign: "center",
      //     halignBox: "center",
      //     valignBox: "center",
      //     tpl: (data) => {
      //       return getMainNodeHTMLElement(selectedAssetType, data);
      //     }
      //   });
        
      //   conditionTypeSelector = `[id != "${selectedAssetId}"]`;
      // }
      
      customHtmlNodes.push({
        query: `node[!isGroup]`,
        halign: "center",
        valign: "center",
        halignBox: "center",
        valignBox: "center",
        tpl: (data) => {
          return getNodeHTMLElement(data);
        }
      });

      customHtmlNodes.push({
        query: `node[?isCollapsed]`,
        halign: "center",
        valign: "center",
        halignBox: "center",
        valignBox: "center",
        tpl: (data) => {
          return getGroupHTMLElement(data);
        }
      });

      var navigatorConfiguration = {
        container: false,
        viewLiveFramerate: 0,
        thumbnailEventFramerate: 30,
        thumbnailLiveFramerate: false,
        dblClickDelay: 200,
        removeCustomContainer: false, 
        rerenderDelay: 100
      };
      
      cy.current.nodeHtmlLabel(customHtmlNodes);

      if(cyNav && cyNav.current){
        cyNav.current.destroy();
      }

      var nav = cy.current.navigator(navigatorConfiguration);
      cyNav.current = nav;

      var expandCollapseConfiguration = {
        // layoutBy: getLayoutConfiguration(layout).configuration,
        // layoutBy: {
        //   name: getLayoutConfiguration(layout).name,
        //   animate: true,
        //   randomize: false,
        //   fit: true
        // },
        animate: false,
        fisheye: false,
        undoable: false,
        cueEnabled: true,
        expandCollapseCuePosition: "top-left",
        expandCollapseCueSize: 24,
        expandCollapseCueLineSize: 24,
        expandCueImage: "/img/ic_expand_more.svg",
        collapseCueImage: "/img/ic_expand_less.svg",
        expandCollapseCueSensitivity: 1,
        zIndex: 100
      };

      cyExpandCollapse.current = cy.current.expandCollapse(expandCollapseConfiguration);
      // cyUndoRedo.current = cy.current.undoRedo();

      cy.current.off("click", "node").on("click", "node", (e) => { 
        if(e.target.length){
          console.log(e);
          if(!e.target[0]._private.data.isGroup){
            const anchor = {
              top: e.originalEvent.y,
              left: e.originalEvent.x
            }
            openAssetMenu(anchor, e.target[0]._private.data.id);
          }
        }
      });

      cy.current.on("expandcollapse.aftercollapse", function (e) {
        e.target._private.data.isCollapsed = true;
        console.log("isCollapsed");
      });
      
      cy.current.on("expandcollapse.afterexpand", function (e) {
        e.target._private.data.isCollapsed = false;
        console.log("isExpanded");
      });

      refreshCyNodes();
    }    
  }, [dispatch, nodes, cy, assetTypes]);

  useEffect(() => {
    if(newNodes && newNodes.length){
      const elementsToAdd = [];
      const clonnedNewElements = getMappedNodes(newNodes);
      const previousNodes = cy.current.nodes();
      const previousEdges = cy.current.edges();
      var parentId = "group-" + clickedAssetId;
      
      clonnedNewElements.forEach((newElement, index) => {
        if(newElement.data.id){
          const alreadyExists = previousNodes.find(n => n._private.data.id === newElement.data.id);
          
          if(!alreadyExists){
            newElement.data.parent = parentId;
            elementsToAdd.push(newElement);
          }
        }
        else {
          const alreadyExists = previousEdges.find(n => n._private.data.source === newElement.data.source && n._private.data.target === newElement.data.target && n._private.data.type === newElement.data.type);
          
          if(!alreadyExists){
            elementsToAdd.push(newElement);
          }
        }
      });
      
      if(elementsToAdd.length){
        var hex = "";
        const parentNode = cy.current.getElementById(clickedAssetId);

        if(parentNode._private.data.parent){
          const parentGroup = cy.current.getElementById(parentNode._private.data.parent);
          hex = parentGroup._private.data.backgroundColor;
        }
        else{
          const hue = Math.floor(Math.random() * 360);
          hex = hslToHex(hue, 100, 50);
        }

        const newGroup = {
          data: {
            id: parentId,
            assetTypeId: parentNode._private.data.assetTypeId,
            assetType: parentNode._private.data.assetType,
            name: parentNode._private.data.name,
            parent: parentNode._private.data.parent,
            isCollapsed: false,
            backgroundColor: hex,
            isGroup: true
          },
        };
        
        elementsToAdd.push(newGroup);
        cy.current.add(elementsToAdd);
        refreshCyNodes();

        parentNode._private.data.parent = parentId;
        parentNode.move({ parent:  parentId });

        cy.current.layout(getLayoutConfiguration(layout)).run();
      }
    }
  }, [newNodes]);

  useEffect(() => {
    if(nodesForAdd && nodesForAdd.length){
      const elementsToAdd = [];
      const clonnedNewElements = getMappedNodes(nodesForAdd);
      const previousNodes = cy.current.nodes();
      
      clonnedNewElements.forEach((newElement, index) => {
        const alreadyExists = previousNodes.find(n => n._private.data.id === newElement.data.id);
        
        if(!alreadyExists){
          elementsToAdd.push(newElement);
        }
      });
      
      if(elementsToAdd.length){
        cy.current.add(elementsToAdd);
        refreshCyNodes();
        cy.current.layout(getLayoutConfiguration(layout)).run();
      }
    }
  }, [nodesForAdd]);

  useEffect(() => {
    if(assetTypes && assetTypes.length){
      const stylesheet = [
        {
          selector: "node",
          style: {
            'height': 80,
            'width': 80,
            'shape': 'ellipse'
          }
        },
        // {
        //   selector: `node[id = "${selectedAssetId}"]`,
        //   style: {
        //     'height': 120,
        //     'width': 120
        //   }
        // },
        {
          selector: "edge",
          style: {
            'width': 2,
            'arrow-scale': 2,
            'target-arrow-shape': 'triangle',
            'curve-style': 'bezier',
            'source-distance-from-node': 5,
            'target-distance-from-node': 10
          }
        },
        {
          selector: "node[?isGroup]",
          style: {
            shape: 'round-rectangle',
            'background-color': "data(backgroundColor)",
            'background-opacity': 0.1,
            'border-color': "data(backgroundColor)"
          }
        },
        {
          selector: "node[?isCollapsed]",
          css: {
            width: 100,
            height: 100,
            shape: 'square',
            "border-width": 1,
            "border-opacity": 1,
            "border-style": "solid",
            'background-color': "data(backgroundColor)",
            'background-opacity': 0.1,
            'border-color': "data(backgroundColor)",
          }
        },
      ]

      setCytoscapeStylesheet(stylesheet);
    }
  }, [assetTypes]);

  return (
    <div style={{position: "relative"}}>
      <LeftConfigurationOverlay>
        <GraphicSettings 
            handleLayoutChange={handleLayoutChange}
            currentLayout={layout}
          />
      </LeftConfigurationOverlay>

      <RightConfigurationOverlay>
        <AssetFilters 
          assetTypes={assetTypes}
          handleClearAll={handleClearAll}
          handleGetNodes={GetNodes}
          handleGetByTarget={GetByTarget}
          handleGetBySource={GetBySource}
          handleGetByLinkName={GetByLinkName}
          handleGetByLinkValue={GetByLinkValue}
          onExpandAll={onExpandAll}
          onCollapseAll={onCollapseAll}
          hiddenNodes={hiddenNodes}
          nodes={cyNodes}
          onChangeHiddenNodes={onChangeHiddenNodes}
        />
      </RightConfigurationOverlay>
        
      {!isLoadingNodes && nodes && nodes.length && cytoscapeStylesheet ?
        <CytoscapeComponent 
          cy={(ref) => { cy.current = ref; }}
          style={ { width: '100%', height: '90vh', color: theme.palette.text.primary } }
          stylesheet={cytoscapeStylesheet}
          zoomingEnabled = {true}
          userZoomingEnabled = {true}
          autoungrabify = {false}
          layout={getLayoutConfiguration(layout)}
          elements={getMappedNodes(nodes)}
        />
        : <FitSpace className={"text-center"}>{isLoadingNodes ? <SimpleLoader /> : <Typography color="text.primary">There is no content to show, use the filters to search for assets.</Typography>}</FitSpace> 
      }
      
      { showInfoModal &&
          <MaterialAssetInfoModal 
            onHideModal={onHideModal} 
            showModal={showInfoModal}
            assetId={clickedAssetId}
            classifications={classifications}
          />
        }

      <AssetMenu 
        anchorPosition={assetMenuAnchorPosition} 
        open={assetMenuOpen}
        handleClose={handleCloseAssetMenu}
        assetId={clickedAssetId}
        viewInfo={handleViewInfo}
        getAdjacentNodes={handleGetAdjacentNodes}
        getAllPath={handleGetAllPath}
        removeNode={removeNode}
      />

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingNodesForMerge || isLoadingNodesForAdd || isLoadingAssetTypes}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

const RightConfigurationOverlay = styled.div`
  position: absolute;
  right: 20px;
  display: flex;
  z-index: 200;
`;

const LeftConfigurationOverlay = styled.div`
position: absolute;
left: 15px;
display: flex;
z-index: 200;
`;

const FitSpace = styled.div`
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
`;

const Node = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 29px;
`;

const NodeBody = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  border: 6px solid rgb(255, 255, 255, 1);
  box-shadow: 0 0 1px 0 rgb(0, 0, 0, 0.12), 0 1px 1px 0 rgb(0, 0, 0, 0.24);
  
  &:hover {
    opacity: 0.4;
  }
`;

const NodeIcon = styled.span`
  font-size: 40px;
  color: white;
`;

const NodeLabel = styled.p`
  font-size: 15px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;
  padding-top: 5px;
  color: ${colorText};
`;

const getNodeHTMLElement = (nodeData) => {
  return ReactDOMServer.renderToString(
    <Node>
      <NodeBody style={{"backgroundColor": nodeData.assetType.color}}>
        <NodeIcon className={`lnr ${nodeData.assetType.icon}`}></NodeIcon>
      </NodeBody>
      <NodeLabel title={nodeData.name}>{nodeData.name}</NodeLabel>
    </Node>
  ); 
}

const getGroupHTMLElement = (nodeData) => {
  return ReactDOMServer.renderToString(
    <Node>
      <NodeBody style={{"backgroundColor": nodeData.assetType.color}}>
        <NodeIcon className={`lnr ${nodeData.assetType.icon}`}></NodeIcon>
      </NodeBody>
      <NodeLabel title={nodeData.name}>{nodeData.name}</NodeLabel>
    </Node>
  ); 
}

const getMainNodeHTMLElement = (nodeData) => {
  return ReactDOMServer.renderToString(
    <Node>
      <NodeBody style={{"width": "120px", "height": "120px", "border": "6px solid red", "backgroundColor": nodeData.assetType.color}}>
        <NodeIcon className={`lnr ${nodeData.assetType.icon}`}></NodeIcon>
      </NodeBody>
      <NodeLabel title={nodeData.name}>{nodeData.name}</NodeLabel>
    </Node>
  ); 
}

const getLayoutConfiguration = (layout) => {
  var configuration;
  if(layout === "hierarchy"){
    configuration = { 
      name: "breadthfirst", 
      directed: true, 
      padding: 10, 
      spacingFactor: 1.2, 
      animate: true,
      animationDuration: 1000 
    }
  }
  else{
    configuration = { 
      name: "cose",
      spacingFactor: 2, 
      animate: 'end', 
      animationDuration: 1000,
      idealEdgeLength: 50
    };
  }

  return configuration;
}
export default EnterpriseArchitecture;