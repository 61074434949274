import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_SERVICE_LOGS');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getServiceLogsRequest = createAction('SERVICE_LOGS_GET_REQUEST');
export const getServiceLogsSuccess = createAction('SERVICE_LOGS_GET_SUCCESS');
export const getServiceLogsError = createAction('SERVICE_LOGS_GET_ERROR');

export const getServiceLogs = (id) => async (dispatch) => {
  try {
    dispatch(getServiceLogsRequest());
    const resp = await instance.get(`/v1/ServiceLog/GetByServiceId/${id}`);
console.log(resp.data);
    dispatch(getServiceLogsSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getServiceLogsError(e.response.data.description));
    else
      dispatch(getServiceLogsError(e.message));
  }
};