import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_ASSET_VIEW');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getAllAssetsRequest = createAction('GET_ALL_ASSETS_REQUEST');
export const getAllAssetsSuccess = createAction('GET_ALL_ASSETS_SUCCESS');
export const getAllAssetsError = createAction('GET_ALL_ASSETS_ERROR');

export const getAllAssets = () => async (dispatch) => {
  try {
    dispatch(getAllAssetsRequest());
    const resp = await instance.get(`/v1/Asset/GetAllListView`);

    dispatch(getAllAssetsSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getAllAssetsError(e.response.data.description));
    else
      dispatch(getAllAssetsError(e.message));
  }
};

export const getClassificationsRequest = createAction('GET_CLASSIFICATIONS_REQUEST');
export const getClassificationsSuccess = createAction('GET_CLASSIFICATIONS_SUCCESS');
export const getClassificationsError = createAction('GET_CLASSIFICATIONS_ERROR');

export const getClassifications = () => async (dispatch) => {
  try {
    dispatch(getClassificationsRequest());
    const resp = await instance.get(`/v1/Classification/GetAll`);
    console.log(resp);
    dispatch(getClassificationsSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getClassificationsError(e.response.data.description));
    else
      dispatch(getClassificationsError(e.message));
  }
};