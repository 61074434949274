import { Col, Container, Row } from "react-bootstrap";
import React, { useEffect, useState } from 'react';
import MultipleLinesChart from "@/shared/components/charts/MultipleLinesChart";
import DoughnutChart from "@/shared/components/charts/DoughnutChart";
import StackedBarsChart from "@/shared/components/charts/StackedBarsChart";
import { CardPage, CardPageBody, CardPageHeader, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap, CardPageGridWrap, CardPageChartBoxWrap, CardPageChartBoxTitleWrap} from '@/shared/components/MaterialCardPage';
import { CopilotPageBox, CopilotPageBoxBody } from '@/shared/components/CopilotPageBox';
import { Autocomplete, Card, CardContent, TextField, Typography } from "@mui/material";
import { getDashboardFilterYears } from "@/utils/dates";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ActivityCounts from "./components/activityCounts";

const Home = () => {
  const roles = useSelector(({ auth }) => auth.roles);
  const chartRoles = ["Administrator", "Manager", "TenantAdministrator", "SystemAdministrator", "Auditor"];
  const showCharts = roles.filter(value => chartRoles.includes(value)).length > 0;


  const years = getDashboardFilterYears();
  const [selectedYear, setSelectedYear] = useState(years[years.length - 1]);

  const {t} = useTranslation("common");

  return(
    <Container>
        {/* <CopilotPageBox>
          <CopilotPageBoxBody>
            <Chatbot />
          </CopilotPageBoxBody>
        </CopilotPageBox> */}
        <CardPage>
          <CardPageBody>
            <CardPageHeader>
              <CardPageTitleWrap>
                <CardPageTitle>{t("app.home.activitySummary")}</CardPageTitle>
              </CardPageTitleWrap>
              <CardPageButtonWrap>
                      <Autocomplete
                        style={{ width: 100 }}
                        disablePortal
                        disableClearable
                        options={years}
                        value={selectedYear}
                        onChange={(e, data) => setSelectedYear(data)}
                        renderInput={(params) => 
                            <TextField 
                                {...params}
                                size="small"
                                label="Year"
                            />
                        }
                      />
              </CardPageButtonWrap>
            </CardPageHeader>

              <ActivityCounts 
                year={selectedYear}
              />

              { showCharts && 
              <>
                <CardPageTitleWrap>
                  <CardPageTitle>{t("app.home.title")}</CardPageTitle>
                </CardPageTitleWrap>
              
                <Row style={{marginTop: 10}}>
                  <Col md={12}>
                          <MultipleLinesChart 
                            endpoint={`GetActivityTrend?year=${selectedYear}`}
                            title={{
                              text: 'Activities Trend'
                            }}
                          />
                  </Col>
                </Row>
                <Row style={{marginTop: 30}}>
                  <Col md={6}>
                        <DoughnutChart 
                          endpoint={`GetActivityByWorkflow?year=${selectedYear}`} 
                          legend={{
                            orient: 'vertical',
                            left: 'left'
                          }}
                          title={{
                            text: 'Activities by Workflow',
                            left: 'center'
                          }}
                        />
                  </Col>

                  <Col md={6}>
                        <StackedBarsChart 
                          endpoint={`GetActivityByPriority?year=${selectedYear}`} 
                          legend={{
                            top: '10%'
                          }}
                          title={{
                            text: 'Activities by Priority',
                            left: 'center'
                          }}
                          grid={{ 
                            top: '25%', 
                            bottom: '0%'
                          }}
                        />
                  </Col>
                </Row>
              </>
              }
          </CardPageBody>
        </CardPage>
    </Container>
  );
}

export default Home;