import { createAction } from 'redux-actions';
import { instance } from "../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_VULNERABILITIES');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getVulnerabilitiesRequest = createAction('VULNERABILITIES_GET_ALL_REQUEST');
export const getVulnerabilitiesSuccess = createAction('VULNERABILITIES_GET_ALL_SUCCESS');
export const getVulnerabilitiesError = createAction('VULNERABILITIES_GET_ALL_ERROR');

export const getVulnerabilities = () => async (dispatch) => {
  try {
    dispatch(getVulnerabilitiesRequest());

    const data = {};
    const resp = await instance.get(`/v1/Vulnerability/GetListByKeyValue`, { params:{ ...data } });
    
    dispatch(getVulnerabilitiesSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getVulnerabilitiesError(e.response.data.description));
    else
      dispatch(getVulnerabilitiesError(e.message));
  }
};

export const getRatingScoresRequest = createAction('VULNERABILITIES_GET_RATING_SCORES_REQUEST');
export const getRatingScoresSuccess = createAction('VULNERABILITIES_GET_RATING_SCORES_SUCCESS');
export const getRatingScoresError = createAction('VULNERABILITIES_GET_RATING_SCORES_ERROR');

export const getRatingScores = () => async (dispatch) => {
  try {
    dispatch(getRatingScoresRequest());
    const resp = await instance.get(`/v1/RatingScore/GetByCVSS`);
    
    dispatch(getRatingScoresSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getRatingScoresError(e.response.data.description));
    else
      dispatch(getRatingScoresError(e.message));
  }
};