import { Box, Button, Chip, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridToolbar, gridClasses } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { cleanup, getVulnerabilities, getRatingScores } from "@/reducers/vulnerabilities/vulnerabilitiesAction";
import { Col, Container, Row } from "react-bootstrap";
import { enqueueSnackbar } from "notistack";
import VulnerabilityInfoModal from "./components/vulnerabilityInfoModal";
import SearchIcon from '@mui/icons-material/Search';
import dayjs from "dayjs";
import ExpandableCell from '@/shared/components/datagrid/ExpandableCell';
import { CardPage, CardPageFilterWrapper, CardPageHeader, CardPageBody, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap,CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import TransparentTextChip from "@/shared/components/TransparentTextChip";

const Vulnerabilities = () => {

    const dispatch = useDispatch();
    const { 
        vulnerabilities, isLoadingVulnerabilities, getVulnerabilitiesError,
        ratingScores, isLoadingRatingScores, getRatingScoresError,
    } = useSelector(({ vulnerabilities }) => vulnerabilities);

    const [showVulnerabilityInfoModal, setShowVulnerabilityInfoModal] = useState(false);
    const [selectedVulnerability, setSelectedVulnerability] = useState(null);

    useEffect(() => {
        dispatch(getVulnerabilities());
        dispatch(getRatingScores());
    
        return () => {
          dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
        if(getVulnerabilitiesError){
            enqueueSnackbar(getVulnerabilitiesError, {variant: "error"});
        }
    }, [getVulnerabilitiesError]);

    useEffect(() => {
        if(getRatingScoresError){
            enqueueSnackbar(getRatingScoresError, {variant: "error"});
        }
    }, [getRatingScoresError]);

    const handleClickViewVulnerability = (vulnerability) => {
        setSelectedVulnerability(vulnerability);
        setShowVulnerabilityInfoModal(true);
    }

    const onHideVulnerabilityInfoModal = () => {
        setShowVulnerabilityInfoModal(false);
        setSelectedVulnerability(null);
    };

    const columns = [
        { 
            field: 'name', 
            headerName: 'Name',
            minWidth: 350,
            flex: 1,
            renderCell: (params) => <ExpandableCell {...params} maxLength={150} />
        },
        { 
            field: 'cve', 
            headerName: 'CVE',
            minWidth: 200,
            flex: 1,
        },
        { 
            field: 'baseScore', 
            headerName: 'CVSS Base Score', 
            minWidth: 200,
            flex: 1,
            renderCell: (params) => {
              if(!params.value)
                return <></>;
                
              const ratingScore = ratingScores.find(r => r.minValue <= params.value && r.maxValue >= params.value);
              return (
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  { ratingScore && 
                    <TransparentTextChip 
                      label={(params.value ? params.value : 0) + " - " + ratingScore.name} 
                      sx={{ 
                        backgroundColor: ratingScore.color 
                      }} 
                    /> 
                  }
                </Box>
              );
            },
          },
          { 
            field: 'epssScore', 
            headerName: 'EPSS Score', 
            minWidth: 150,
            flex: 1,
            valueGetter: (params) => {
              if (!params.value) {
                return params.value;
              }
              return params.value * 100;
            },
            valueFormatter: (params) => {
              if (params.value == null) {
                return '';
              }
              return `${params.value.toFixed(2)}%`;
            }
          },
          { 
            field: 'knownExploited', 
            headerName: 'Known Exploited', 
            minWidth: 150,
            flex: 1
          },
          { 
            field: 'source', 
            headerName: 'Source', 
            minWidth: 150,
            flex: 1
          },
        { 
            field: 'createDate', 
            headerName: 'Create Date',
            minWidth: 150,
            flex: 0.5,
            valueFormatter: params => params.value ? dayjs(params.value).format("DD/MM/YYYY") : "",
        },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<SearchIcon />}
                    label="View"
                    onClick={() => handleClickViewVulnerability(params.row)}
                />
            ]
          },
      ];

    return(
        <Container>
            <CardPage>
                <CardPageBody>
                    <CardPageHeader>
                        <CardPageTitleWrap>
                            <CardPageTitle>Vulnerabilities</CardPageTitle>
                            <CardPageSubhead>
                                View and manage your vulnerabilities.
                            </CardPageSubhead>
                        </CardPageTitleWrap>
                    </CardPageHeader>

                    <CardPageGridWrap>
                        <DataGrid 
                            autoHeight
                            density="compact"
                            disableRowSelectionOnClick
                            loading={isLoadingVulnerabilities} 
                            rows={vulnerabilities}
                            columns={columns} 
                            slots={{ toolbar: GridToolbar }} 
                            getRowHeight={() => 'auto'}
                            getEstimatedRowHeight={() => 59}
                            slotProps={{
                                toolbar: {
                                showQuickFilter: true
                                },
                            }}
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                py: 1,
                                },
                            }}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 30 } },
                            }}
                            pageSizeOptions={[15, 30, 50, 100]}
                        />
                    </CardPageGridWrap>

                    { showVulnerabilityInfoModal &&
                        <VulnerabilityInfoModal
                            onHideModal={onHideVulnerabilityInfoModal}
                            open={showVulnerabilityInfoModal}
                            vulnerabilityId={selectedVulnerability.id}
                        />
                    }
                </CardPageBody>
            </CardPage>
        </Container>
    )
}

export default Vulnerabilities;