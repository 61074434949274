import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getBusinessUnitsRequest,
  getBusinessUnitsSuccess,
  getBusinessUnitsError,
  deleteVulnerabilityStatusRequest,
  deleteVulnerabilityStatusSuccess,
  deleteVulnerabilityStatusError
} from './businessUnitsAction';

const defaultState = {
  businessUnits: [],
  isLoadingBusinessUnits: true,
  getBusinessUnitsError: null,
  isDeletingVulnerabilityStatus: false,
  deleteVulnerabilityStatusError: null,
  deletedVulnerabilityStatus: false
};

export default handleActions(
  {
    [getBusinessUnitsRequest](state) {
      return {
        ...state,
        businessUnits: [],
        isLoadingBusinessUnits: true,
        getBusinessUnitsError: null,
      };
    },
    [getBusinessUnitsSuccess](state, { payload }) {
      return {
        ...state,
        businessUnits: payload,
        isLoadingBusinessUnits: false,
        getBusinessUnitsError: null,
      };
    },
    [getBusinessUnitsError](state, { payload }) {
      return {
        ...state,
        businessUnits: [],
        isLoadingBusinessUnits: false,
        getBusinessUnitsError: payload,
      };
    },
    [deleteVulnerabilityStatusRequest](state) {
      return {
        ...state,
        isDeletingVulnerabilityStatus: true,
        deletedVulnerabilityStatus: false,
        deleteVulnerabilityStatusError: null,
      };
    },
    [deleteVulnerabilityStatusSuccess](state, { payload }) {
      return {
        ...state,
        isDeletingVulnerabilityStatus: false,
        deletedVulnerabilityStatus: true,
        deleteVulnerabilityStatusError: null,
      };
    },
    [deleteVulnerabilityStatusError](state, { payload }) {
      return {
        ...state,
        isDeletingVulnerabilityStatus: false,
        deletedVulnerabilityStatus: false,
        deleteVulnerabilityStatusError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);