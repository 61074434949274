import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"

const ConfirmationDialog = ({
    open, 
    onClose, 
    onConfirm,
    title, 
    body, 
    cancelButtonText = 'Cancel', 
    confirmButtonText = 'Ok', 
    color = '', 
    ...other
}) => {
    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={open}
            onClose={onClose}
            {...other}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{body}</DialogContent>
            <DialogActions>
            <Button autoFocus onClick={onClose}>{cancelButtonText}</Button>
            <Button variant="contained" color={color} onClick={onConfirm}>{confirmButtonText}</Button>
        </DialogActions>
        </Dialog>
    )
}

export default ConfirmationDialog;