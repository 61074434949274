import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  updateLocationRequest,
  updateLocationSuccess,
  updateLocationError
} from './updateLocationAction';

const defaultState = {
  isUpdatingLocation: false,
  updateLocationError: null,
  updatedLocation: false
};

export default handleActions(
  {
    
    [updateLocationRequest](state) {
      return {
        ...state,
        isUpdatingLocation: true,
        updateLocationError: null,
        updatedLocation: false
      };
    },
    [updateLocationSuccess](state, { payload }) {
      return {
        ...state,
        isUpdatingLocation: false,
        updateLocationError: null,
        updatedLocation: true
      };
    },
    [updateLocationError](state, { payload }) {
      return {
        ...state,
        isUpdatingLocation: false,
        updateLocationError: payload,
        updatedLocation: false
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);