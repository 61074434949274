import React from "react";
import ProtectedRoutes from "../config/protectedRoutes";
import { WrapContainer } from "./wrapperRoutes";

const Routers = () => (
  <div className='wrapper'>
    <main>
      <ProtectedRoutes>
        <WrapContainer />
      </ProtectedRoutes>
    </main>
  </div>
);

export default Routers;
