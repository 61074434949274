import { Backdrop, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridToolbar, gridClasses } from "@mui/x-data-grid";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { cleanup, getTenants, deleteTenant } from "@/reducers/system/tenants/systemTenantListAction";
import { Col, Container, Row } from "react-bootstrap";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { CardPage, CardPageFilterWrapper, CardPageHeader, CardPageBody, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap,CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import AddIcon from '@mui/icons-material/Add';
import { SearchFilters } from "@/shared/components/SearchFilters";
import ConfirmationDialog from '@/shared/components/ConfirmationDialog';
import PaidIcon from '@mui/icons-material/Paid';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { impersonateTenant } from "@/reducers/auth/authAction";

const SystemTenantList = () => {
    const dispatch = useDispatch();

    const { 
        tenants, tenantsTotalCount, isLoadingTenants, getTenantsError,
        isDeletingTenant, deletedTenant, deleteTenantError
    } = useSelector(({ systemTenantList }) => systemTenantList);

    const isLogging = useSelector(({ auth }) => auth.isLogging);

    const [searchFilters, setSearchFilters] = useState({ pageSize: 30, page: 1 });
    
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [tenantToDelete, setTenantToDelete] = useState(null);

    const [openConfirmImpersonate, setOpenConfirmImpersonate] = useState(false);
    const [tenantToImpersonate, setTenantToImpersonate] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        return () => {
          dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
        if(getTenantsError){
            enqueueSnackbar(getTenantsError, {variant: "error"});
        }
    }, [getTenantsError]);
    
    useEffect(() => {
        if(deleteTenantError){
            enqueueSnackbar(deleteTenantError, {variant: "error"});
        }
    }, [deleteTenantError]);

    useEffect(() => {
        dispatch(getTenants(searchFilters));
    }, [searchFilters]);

    const setPaginationModel = (model) => {
        setSearchFilters({ 
            ...searchFilters, 
            page: model.page + 1,
            pageSize: model.pageSize
        });
    };

    const mappedTenants = useMemo(() => {
        const clonnedTenants = JSON.parse(JSON.stringify(tenants));
        clonnedTenants.forEach(t => t.subscriptionTypeName = t.subscription?.subscriptionType?.name);
        return clonnedTenants;
      }, [tenants]);

    const onFilterSubmit = (data) => {
        setSearchFilters({ ...searchFilters, page: 1, ...data});
    }

    const handleClickSubscription = (id) => {
        navigate(`Subscription/${id}`);
    }

    const handleClickEditTenant = (id) => {
        navigate(`Update/${id}`);
    }

    const handleClickAddTenant = () => {
        navigate("Add");
    }

    const handleClickImpersonate = (tenant) => {
        setTenantToImpersonate(tenant);
        setOpenConfirmImpersonate(true);
    }

    const handleClickDeleteTenant = (tenant) => {
        setTenantToDelete(tenant);
        setOpenConfirmDelete(true);
    }

    const handleConfirmDelete = () => {
        setOpenConfirmDelete(false);
        dispatch(deleteTenant(tenantToDelete.tenantId));
        setTenantToDelete(null);
    }

    const handleOnCloseDelete = () => {
        setOpenConfirmDelete(false);
        setTenantToDelete(null);
    };

    const handleConfirmImpersonate = () => {
        setOpenConfirmImpersonate(false);
        dispatch(impersonateTenant(tenantToImpersonate.tenantId));
        setTenantToImpersonate(null);
    }

    const handleOnCloseImpersonate = () => {
        setOpenConfirmImpersonate(false);
        setTenantToImpersonate(null);
    };

    useEffect(() => {
        if(deletedTenant){
            enqueueSnackbar("Tenant deleted successfully", {variant: "success"});
            dispatch(getTenants(searchFilters));
        }
    }, [deletedTenant]);


    const columns = [
        { 
            field: 'name',
            headerName: 'Name',
            minWidth: 200,
            flex: 0.5
        },
        { 
            field: 'emailAddress', 
            headerName: 'Email', 
            minWidth: 300,
            flex: 1
        },
        { 
            field: 'billingName', 
            headerName: 'Billing Name', 
            minWidth: 200,
            flex: 0.5
        },        
        { 
            field: 'billingAddress', 
            headerName: 'Billing Address', 
            minWidth: 300,
            flex: 1
        },
        { 
            field: 'billingCountry', 
            headerName: 'Billing Country', 
            minWidth: 200,
            flex: 0.5
        },
        { 
            field: 'billingCity', 
            headerName: 'Billing City', 
            minWidth: 200,
            flex: 0.5
        },
        { 
            field: 'vat', 
            headerName: 'VAT', 
            minWidth: 200,
            flex: 0.5
        },
        { 
            field: 'vatCode', 
            headerName: 'SDI Code', 
            minWidth: 200,
            flex: 0.5
        },
        { 
            field: 'subscriptionTypeName', 
            headerName: 'Subscription', 
            minWidth: 200,
            flex: 0.5
        },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<SupervisorAccountIcon />}
                    showInMenu
                    label="Impersonate Tenant"
                    onClick={() => handleClickImpersonate(params.row)}
                />,
                <GridActionsCellItem
                    icon={<PaidIcon />}
                    showInMenu
                    label="Manage Subscription"
                    onClick={() => handleClickSubscription(params.id)}
                />,
                <GridActionsCellItem
                    icon={<EditIcon />}
                    showInMenu
                    label="Edit"
                    onClick={() => handleClickEditTenant(params.id)}
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    showInMenu
                    label="Delete"
                    onClick={() => handleClickDeleteTenant(params.row)}
                />
            ],
          },
      ];

    return(
        <Container>
            <CardPage>
                <CardPageBody>   
                    <CardPageHeader>
                        <CardPageTitleWrap>
                            <CardPageTitle>Tenants</CardPageTitle>
                            <CardPageSubhead>
                            Here you can view all the Tenants.
                            </CardPageSubhead>
                        </CardPageTitleWrap>
                        <CardPageButtonWrap>
                            <Button 
                                onClick={handleClickAddTenant}
                                variant="contained" 
                                color="primary" 
                                endIcon={<AddIcon/>}>Add Tenant</Button>
                        </CardPageButtonWrap>
                    </CardPageHeader>

                    <CardPageFilterWrapper>
                        <SearchFilters 
                            onFilterSubmit={onFilterSubmit}
                        />
                    </CardPageFilterWrapper>

                    <CardPageGridWrap>
                        <DataGrid 
                        autoHeight
                        density="compact"
                        disableRowSelectionOnClick
                        loading={isLoadingTenants} 
                        rows={mappedTenants}
                        getRowId={(row) => row.tenantId}
                        columns={columns} 
                        slots={{ toolbar: GridToolbar }}
                        sx={{
                            [`& .${gridClasses.cell}`]: {
                            py: 1,
                            },
                        }}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 30 } },
                        }}
                        pageSizeOptions={[5, 15, 30, 50, 100]}
                        rowCount={tenantsTotalCount}
                        paginationModel={{
                            page: searchFilters.page - 1, 
                            pageSize: searchFilters.pageSize}}
                        paginationMode="server"
                        onPaginationModelChange={setPaginationModel}
                        />

                        { openConfirmDelete &&
                            <ConfirmationDialog 
                                open={openConfirmDelete}
                                onConfirm={handleConfirmDelete}
                                onClose={handleOnCloseDelete}
                                title="Delete Tenant"
                                body={`Are you sure you want to delete ${tenantToDelete.name}?`}
                                color='error'
                                confirmButtonText='Delete'
                            />
                        }

                        { openConfirmImpersonate &&
                            <ConfirmationDialog 
                                open={openConfirmImpersonate}
                                onConfirm={handleConfirmImpersonate}
                                onClose={handleOnCloseImpersonate}
                                title="Impersonate Tenant"
                                body={`Are you sure you want to impersonate ${tenantToImpersonate.name}?`}
                                color='primary'
                                confirmButtonText='Impersonate'
                            />
                        }

                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={isDeletingTenant || isLogging}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </CardPageGridWrap>
                </CardPageBody>
            </CardPage>
        </Container>
    )
}

export default SystemTenantList;