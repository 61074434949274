import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_AU_ASSET_LOGO');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getLogoRequest = createAction('AU_ASSET_LOGO_GET_LOGO_REQUEST');
export const getLogoSuccess = createAction('AU_ASSET_LOGO_GET_LOGO_SUCCESS');
export const getLogoError = createAction('AU_ASSET_LOGO_GET_LOGO_ERROR');

export const getLogo = (id) => async (dispatch) => {
  try {
    dispatch(getLogoRequest());
    const resp = await instance.get(`/v1/Stakeholder/GetLogo/${id}`, { 
        responseType: 'blob'
    });

    dispatch(getLogoSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getLogoError(e.response.data.description));
    else
      dispatch(getLogoError(e.message));
  }
};
