import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getAuditCampaignRequest,
  getAuditCampaignSuccess,
  getAuditCampaignError,
  getAssetsRequest,
  getAssetsSuccess,
  getAssetsError,
  confirmAssetsRequest,
  confirmAssetsSuccess,
  confirmAssetsError
} from './AUSelectAssetsAction';

const defaultState = {
  auditCampaign: null, 
  isLoadingAuditCampaign: true, 
  getAuditCampaignError: null,
  assets: [],
  isLoadingAssets: true,
  getAssetsError: null,
  isConfirmingAssets: false, 
  confirmedAssets: false, 
  confirmAssetsError: null
};

export default handleActions(
  {
    [getAuditCampaignRequest](state) {
      return {
        ...state,
        auditCampaign: null,
        isLoadingAuditCampaign: true,
        getAuditCampaignError: null,
      };
    },
    [getAuditCampaignSuccess](state, { payload }) {
      return {
        ...state,
        auditCampaign: payload,
        isLoadingAuditCampaign: false,
        getAuditCampaignError: null,
      };
    },
    [getAuditCampaignError](state, { payload }) {
      return {
        ...state,
        auditCampaign: null,
        isLoadingAuditCampaign: false,
        getAuditCampaignError: payload,
      };
    },
    [getAssetsRequest](state) {
      return {
        ...state,
        assets: [],
        isLoadingAssets: true,
        getAssetsError: null,
      };
    },
    [getAssetsSuccess](state, { payload }) {
      return {
        ...state,
        assets: payload,
        isLoadingAssets: false,
        getAssetsError: null,
      };
    },
    [getAssetsError](state, { payload }) {
      return {
        ...state,
        assets: [],
        isLoadingAssets: false,
        getAssetsError: payload,
      };
    },
    [confirmAssetsRequest](state) {
      return {
        ...state,
        isConfirmingAssets: true,
        confirmAssetsError: null,
        confirmedAssets: false
      };
    },
    [confirmAssetsSuccess](state, { payload }) {
      return {
        ...state,
        isConfirmingAssets: false,
        confirmAssetsError: null,
        confirmedAssets: true
      };
    },
    [confirmAssetsError](state, { payload }) {
      return {
        ...state,
        isConfirmingAssets: false,
        confirmAssetsError: payload,
        confirmedAssets: false
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);