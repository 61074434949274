import { Button, ButtonGroup } from "@mui/material";
import DashboardIcon from '@mui/icons-material/Dashboard';
import ViewListIcon from '@mui/icons-material/ViewList';
import styled from "styled-components";

export const ViewType = ({
    viewType = 'grid',
    onChangeViewType
}) => {
    return(
        <ButtonGroup>
            <Button 
                disabled={viewType === 'grid'}
                onClick={() => onChangeViewType('grid')}
            >
                <DashboardIcon />
            </Button>
            <Button
                disabled={viewType === 'list'}
                onClick={() => onChangeViewType('list')}
            >
                <ViewListIcon />
            </Button>
        </ButtonGroup>
    );
};

export const ViewTypeWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
`;