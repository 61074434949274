import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GridToolbar, DataGrid, gridClasses } from '@mui/x-data-grid';
import { Col, Container, Row } from 'react-bootstrap';
import { cleanup, getClassifications, getSchema, getListForRemediation,  getDomains, getRatingScores } from "@/reducers/activity/assetVulnerabilityList/assetVulnerabilityListAction";
import { useForm } from "react-hook-form";
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { FormMultiSelectField } from '@/shared/components/form/material-controls/MultiSelectField';
import { FormDateField } from '@/shared/components/form/material-controls/DateField';
import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, Typography, Backdrop, CircularProgress, Box } from '@mui/material';
import MaterialDynamicFilters from '@/shared/components/dynamic-filters/dynamicFilters';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandableCell from '@/shared/components/datagrid/ExpandableCell';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs';
import { useOutletContext } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { useRef } from 'react';
import ClassificationChip from '../../../AssetManagement/AssetInventory/components/classificationChip';

const AssetVulnerabilityView = () => {
  const dispatch = useDispatch();

  const submitButton = useRef(null);

  const { activityTypeId } = useOutletContext();

  const { 
    classifications, isLoadingClassification, getClassificationsError,
    schema, isLoadingSchema, getSchemaError,
    domains, isLoadingDomains, getDomainsError,
    ratingScores, isLoadingRatingScores, getRatingScoresError,
    assetVulnerabilities, isLoadingAssetVulnerabilities, getAssetVulnerabilitiesError,
  } = useSelector(({ assetVulnerabilityList }) => assetVulnerabilityList);

  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    dispatch(getSchema());
    dispatch(getDomains());
    dispatch(getRatingScores());
    dispatch(getClassifications());

    return () => {
      dispatch(cleanup());
    }
  }, [dispatch]);

  const { handleSubmit, reset, control, setValue } = useForm();

  useEffect(() => {
    if(getDomainsError){
      enqueueSnackbar(getDomainsError, {variant: "error"});
    }
  }, [getDomainsError]);

  useEffect(() => {
    if(getClassificationsError){
      enqueueSnackbar(getClassificationsError, {variant: "error"});
    }
  }, [getClassificationsError]);

  useEffect(() => {
    if(getSchemaError){
      enqueueSnackbar(getSchemaError, {variant: "error"});
    }
  }, [getSchemaError]);

  useEffect(() => {
    if(getRatingScoresError){
      enqueueSnackbar(getRatingScoresError, {variant: "error"});
    }
  }, [getRatingScoresError]);

  useEffect(() => {
    if(getAssetVulnerabilitiesError){
      enqueueSnackbar(getAssetVulnerabilitiesError, {variant: "error"});
    }
  }, [getAssetVulnerabilitiesError]);

  const onSubmit = (data) => {
    var keyValues = {};

    if(data.asset){
      keyValues["asset.Name"] = data.asset;
    }

    if(data.vulnerability){
      keyValues["vulnerability.name"] = data.vulnerability;
    }

    if(data.cve){
      keyValues["vulnerability.cve"] = data.cve;
    }

    if(data.detail){
      keyValues["detail"] = data.detail;
    }

    if(data.severity && data.severity.length){
      keyValues["vulnerability.ratingScoreId"] = data.severity;
    }

    if(data.domain && data.domain.length){
      keyValues["domainId"] = data.domain;
    }

    if(data.createData){
      keyValues["createData"] = new Date(data.createData);
    }

    if(data.dynamicFilter && data.dynamicFilter.length){
      data.dynamicFilter.forEach(f => {
        keyValues[f.key] = f.value;
      });
    }

    dispatch(getListForRemediation(keyValues));
  }

  const columns = [
    { 
      field: 'asset', 
      headerName: 'Asset', 
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return(
          <Box sx={{
            display: 'flex',
            flexDirection: 'column'
          }}>
            <div>{params.value?.ip}</div>
            <div>{params.value?.assetName}</div>
          </Box>
        )
      }
    },
    { 
      field: 'classification',
      headerName: 'Classification',
      minWidth: 350,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const confidentiality = classifications.find(r => r.name === params.value?.confidentiality);
        const integrity = classifications.find(r => r.name === params.value?.integrity);
        const availability = classifications.find(r => r.name === params.value?.availability);

        return (
          <Box sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignContent: 'center',
            width: '100%'
          }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
            }}>
              <ClassificationChip letter="C" name="Confidentiality" classification={confidentiality} />
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center'
            }}>
              <ClassificationChip letter="I"  name="Integrity"  classification={integrity} />
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center'
            }}>
              <ClassificationChip letter="A"  name="Availability"  classification={availability} />
            </Box>
          </Box>
        );
      },
      valueFormatter: (params) => {
        const confidentiality = classifications.find(r => r.name === params.value?.confidentiality);
        const integrity = classifications.find(r => r.name === params.value?.integrity);
        const availability = classifications.find(r => r.name === params.value?.availability);

        return confidentiality?.name + integrity?.name + availability?.name;
      }
    },
    { 
        field: 'vulnerability', 
        headerName: 'Vulnerability',
        minWidth: 350,
        flex: 1,
        renderCell: (params) => {
          return(
            <Box sx={{
              display: 'flex',
              flexDirection: 'column'
            }}>
              <div>{params.value?.vulnerabilityCVE}</div>
              <ExpandableCell value={params.value?.vulnerabilityName} maxLength={80} />
            </Box>
          )
        }
    },
    { 
        field: 'detail', 
        headerName: 'Details', 
        minWidth: 350,
        flex: 1,
        renderCell: (params) => <ExpandableCell {...params} maxLength={150} />,
    },
    { 
        field: 'ratingScoreName', 
        headerName: 'Severity', 
        minWidth: 150,
        flex: 1,
        renderCell: (params) => {
          const color = ratingScores.find(r => r.Name === params.value)?.color;
          return (
            <Chip style={{backgroundColor: color}} label={params.value} />
          );
        },
    },
    { 
        field: 'domains', 
        headerName: 'Domains', 
        minWidth: 150,
        flex: 1,
        renderCell: (params) => <ExpandableCell {...params} maxLength={150} />,
    },
    { 
        field: 'createDate', 
        headerName: 'Create Date', 
        type: 'date',
        minWidth: 150,
        flex: 1,
        valueFormatter: params => params.value ? dayjs(params.value).format("DD/MM/YYYY") : ""
    }
  ];

  return (
    <React.Fragment>
      <Row>
        <Col md={12}>
          <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
            <Typography>Basic Filters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Row>
                <Col md={6}>
                  <FormTextField name="asset" label="Asset" control={control} />
                </Col>
                <Col md={6}>
                  <FormTextField 
                    name="vulnerability" 
                    label="Vulnerability" 
                    control={control} 
                  />
                </Col>
                <Col md={6}>
                  <FormTextField 
                    name="detail" 
                    label="Detail" 
                    control={control} 
                  />
                </Col>
                <Col md={6}>
                  <FormTextField 
                    name="cve" 
                    label="CVE" 
                    control={control} 
                  />
                </Col>
                <Col md={6}>
                  <FormMultiSelectField 
                    name="severity" 
                    label="Severity" 
                    control={control} 
                    options={ratingScores}
                    keyValue={{id:"id", label:"Name"}}
                  />
                </Col>
                <Col md={6}>
                  <FormMultiSelectField 
                    name="domain" 
                    label="Domain" 
                    control={control} 
                    options={domains}
                    keyValue={{id:"id", label:"value"}}
                  />
                </Col>
                <Col md={6}>
                  <FormDateField 
                    name="createDate" 
                    label="Create Date" 
                    control={control}
                  />
                </Col>
              </Row>
            </AccordionDetails>
          </Accordion>
        </Col>

        <Col md={12}>
          <MaterialDynamicFilters schema={schema} control={control} name="dynamicFilter" />
        </Col>

      </Row>
      <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBlock: 20}}>
          <Button ref={submitButton} disabled={isLoadingAssetVulnerabilities} color="primary" size='medium' variant="contained" onClick={handleSubmit(onSubmit)} endIcon={<SearchIcon/>}>Search</Button>
      </div>
      <div style={{'height': 800, 'paddingBottom': 20}}>
        <DataGrid 
          density="compact"
          checkboxSelection 
          disableRowSelectionOnClick
          getRowId={(row) => row._id}
          getRowHeight={() => 'auto'}
          getEstimatedRowHeight={() => 59}
          loading={isLoadingAssetVulnerabilities} 
          rows={mapAssetVulnerabilities(assetVulnerabilities)}
          columns={columns} 
          slots={{ toolbar: GridToolbar }} 
          slotProps={{
            toolbar: {
              showQuickFilter: true
            },
          }}
          sx={{
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 30 } },
          }}
          pageSizeOptions={[15, 30, 50, 100]}
        />
      </div>
    </React.Fragment>
  );
}

const mapAssetVulnerabilities = (assetVulnerabilities) => {
  var mappedData = JSON.parse(JSON.stringify(assetVulnerabilities));

  mappedData.forEach(m => {
      m.classification = { confidentiality: m.confidentiality, integrity: m.integrity, availability: m.availability };
      m.vulnerability = { vulnerabilityCVE: m.vulnerabilityCVE, vulnerabilityName: m.vulnerabilityName };
      m.asset = { assetName: m.assetName, ip: m.ip };
  });

  return mappedData;
}

export default AssetVulnerabilityView;