import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  addClassificationRequest,
  addClassificationSuccess,
  addClassificationError
} from './addClassificationAction';

const defaultState = {
  isAddingClassification: false,
  addClassificationError: null,
  addedClassification: false
};

export default handleActions(
  {
    [addClassificationRequest](state) {
      return {
        ...state,
        isAddingClassification: true,
        addClassificationError: null,
        addedClassification: false
      };
    },
    [addClassificationSuccess](state, { payload }) {
      return {
        ...state,
        isAddingClassification: false,
        addClassificationError: null,
        addedClassification: true
      };
    },
    [addClassificationError](state, { payload }) {
      return {
        ...state,
        isAddingClassification: false,
        addClassificationError: payload,
        addedClassification: false
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);