import { createAction } from 'redux-actions';
import { instance } from "../../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_CHANGE_PASSWORD');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const changePasswordRequest = createAction('CHANGE_PASSWORD_REQUEST');
export const changePasswordSuccess = createAction('CHANGE_PASSWORD_SUCCESS');
export const changePasswordError = createAction('CHANGE_PASSWORD_ERROR');

export const changePassword = (data) => async (dispatch) => {
    try {
      dispatch(changePasswordRequest());
      const resp = await instance.post(`/v1/Account/ChangePassword`, data);
      
      dispatch(changePasswordSuccess(resp.data));
  
    } catch (e) {
      if(e.response && e.response.data && e.response.data.description)
        dispatch(changePasswordError(e.response.data.description));
      else
        dispatch(changePasswordError(e.message));
    }
  };