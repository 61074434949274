import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordError
} from './changePasswordAction';

const defaultState = {
  isChanginPass: false,
  changePassError: null,
  changedPass: false
};

export default handleActions(
  {
    [changePasswordRequest](state) {
        return {
            ...state,
            isChanginPass: true,
            changePassError: null,
            changedPass: false
        };
    },
    [changePasswordSuccess](state, { payload }) {
        return {
            ...state,
            isChanginPass: false,
            changePassError: null,
            changedPass: true
        };
    },
    [changePasswordError](state, { payload }) {
        return {
            ...state,
            isChanginPass: false,
            changePassError: payload,
            changedPass: false
        };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);