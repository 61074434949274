import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getCurrentTenantRequest,
  getCurrentTenantSuccess,
  getCurrentTenantError,
  updateTenantRequest,
  updateTenantSuccess,
  updateTenantError,
  getLogoRequest,
  getLogoSuccess,
  getLogoError,
  deleteLogoRequest,
  deleteLogoSuccess,
  deleteLogoError
} from './currentTenantAction';

const defaultState = {
  currentTenant: null,
  isLoadingCurrentTenant: false,
  getCurrentTenantError: null,
  isUpdatingTenant: false,
  updateTenantError: null,
  updatedTenant: false,
  isDeletingLogo: false,
  deleteLogoError: null,
  deletedLogo: false,
  logo: null
};

export default handleActions(
  {
    [getCurrentTenantRequest](state) {
      return {
        ...state,
        isLoadingCurrentTenant: true,
        getCurrentTenantError: null,
      };
    },
    [getCurrentTenantSuccess](state, { payload }) {
      return {
        ...state,
        currentTenant: payload,
        isLoadingCurrentTenant: false,
        getCurrentTenantError: null,
      };
    },
    [getCurrentTenantError](state, { payload }) {
      return {
        ...state,
        isLoadingCurrentTenant: false,
        getCurrentTenantError: payload,
      };
    },
    [updateTenantRequest](state) {
        return {
            ...state,
            isUpdatingTenant: true,
            updateTenantError: null,
            updatedTenant: false
        };
    },
    [updateTenantSuccess](state, { payload }) {
        return {
            ...state,
            isUpdatingTenant: false,
            updateTenantError: null,
            updatedTenant: true
        };
    },
    [updateTenantError](state, { payload }) {
        return {
            ...state,
            isUpdatingTenant: false,
            updateTenantError: payload,
            updatedTenant: false
        };
    },
    [getLogoRequest](state) {
      return {
          ...state,
          isLoadingLogo: true,
          getLogoError: null,
      };
    },
    [getLogoSuccess](state, { payload }) {
        return {
            ...state,
            logo: payload && payload.size ? payload : null,
            isLoadingLogo: false,
            getLogoError: null,
        };
    },
    [getLogoError](state, { payload }) {
        return {
            ...state,
            isLoadingLogo: false,
            getLogoError: payload,
        };
    },
    [deleteLogoRequest](state) {
      return {
          ...state,
          isDeletingLogo: true,
          deleteLogoError: null,
          deletedLogo: false
      };
    },
    [deleteLogoSuccess](state, { payload }) {
        return {
            ...state,
            isDeletingLogo: false,
            deleteLogoError: null,
            deletedLogo: true,
            logo: null
        };
    },
    [deleteLogoError](state, { payload }) {
        return {
            ...state,
            isDeletingLogo: false,
            deleteLogoError: payload,
            deletedLogo: false
        };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);