import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_RESET_PASSWORD');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const resetPasswordRequest = createAction('RESET_PASSWORD_REQUEST');
export const resetPasswordSuccess = createAction('RESET_PASSWORD_SUCCESS');
export const resetPasswordError = createAction('RESET_PASSWORD_ERROR');

export const resetPassword = (data) => async (dispatch) => {
  try {
    dispatch(resetPasswordRequest());
    const resp = await instance.post(`/v1/Account/ResetPassword`, data);

    dispatch(resetPasswordSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(resetPasswordError(e.response.data.description));
    else if(e.response && e.response.data && e.response.data.description)
      dispatch(resetPasswordError(e.response.data.description));
    else
      dispatch(resetPasswordError(e.message));
  }
};
