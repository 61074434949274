import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_ASSETTYPE_UPDATE');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const updateAssetTypesRequest = createAction('UPDATE_ASSETTYPES_REQUEST');
export const updateAssetTypesSuccess = createAction('UPDATE_ASSETTYPES_SUCCESS');
export const updateAssetTypesError = createAction('UPDATE_ASSETTYPES_ERROR');

export const updateAssetTypes = (data) => async (dispatch) => {
  try {
    dispatch(updateAssetTypesRequest());
    const resp = await instance.put("/v1/AssetType/Put", data);
    
    if(resp.data){
      dispatch(updateAssetTypesSuccess(resp.data));
    }
    else{
      dispatch(updateAssetTypesError(resp));
    }
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(updateAssetTypesError(e.response.data.description));
    else
      dispatch(updateAssetTypesError(e.message));
  }
};
