import { CircularProgress, Collapse, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import CircularProgressWithLabel from '@/shared/components/CircularProgressWithLabel';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const StepList = ({
    activity,
    activitySteps,
    touchedTasks,
    showTasks
}) => {
    const navigate = useNavigate();
    const [disabledSteps, setDisabledSteps] = useState([]);

    useEffect(() => {
        if(activitySteps && activitySteps.steps){
            const tempDisabledSteps = [];
            activitySteps.steps.forEach((step, index) => {
                if(!step.isEditor){
                    tempDisabledSteps[index] = true;
                }
                else if(step.dependOn){
                    const dependStep = activitySteps.steps.find(s => s.workflowActivityStepId === step.dependOn);
                    if(!dependStep || dependStep.percentageCompleted < 1){
                        tempDisabledSteps[index] = true;
                    }
                    else{
                        tempDisabledSteps[index] = false;
                    }
                }
                else{
                    tempDisabledSteps[index] = false;
                }
            });

            setDisabledSteps(tempDisabledSteps);
        }
    }, [activitySteps]);

    const handleClickEdit = (step) => {
        navigate("/Activities/EditStep/" + activity.workflowActivityId + "/" + step.workflowActivityStep.componentPath);
    };

    return(
        <Collapse in={showTasks} timeout="auto" unmountOnExit>
            <Divider component="li"/>
            <List component="div" disablePadding>
                {!touchedTasks || activitySteps.isLoadingSteps || !activitySteps.steps ? <div style={{height: '100px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><CircularProgress /></div> : 
                !activitySteps.steps.length ? <div style={{height: '56px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Typography>There are no steps to show.</Typography></div> :
                activitySteps.steps.map((step, index) => (
                    <div key={index}>
                        <ListItem key={index} sx={{ pl: 4 }}
                            secondaryAction={
                                disabledSteps[index] ? 
                                <IconButton 
                                    edge="end" 
                                    aria-label="edit" 
                                    onClick={() => handleClickEdit(step)}
                                >
                                    <SearchIcon/>
                                </IconButton>
                                :
                                <IconButton 
                                edge="end" 
                                aria-label="edit" 
                                onClick={() => handleClickEdit(step)}
                                >
                                    <EditIcon/>
                                </IconButton>
                            }
                        >
                            <ListItemIcon>
                                <CircularProgressWithLabel value={step.percentageCompleted * 100} />
                            </ListItemIcon>
                            <ListItemText
                                primary={step.workflowActivityStep.label}
                            />
                        </ListItem>
                        {index !== activitySteps.steps.length - 1 &&
                            <Divider variant="inset" component="li" />
                        }
                    </div>
                ))}
            </List>
        </Collapse>
    );
}

export default StepList;