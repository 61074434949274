import { styled } from '@mui/material/styles';

const ProgressCell = (props) => {
  const {
    value
  } = props;
return(
    <Root>
        <Value>{value.toLocaleString()} %</Value>
        <Bar
            className={(value < 30 ? "low" : value >= 30 && value <= 70 ? "medium" : "high")}
            style={{maxWidth: `${value}%`}} 
        />
    </Root>
);
};

const Root = styled('div')(({
    theme
  }) => ({
    border: `1px solid ${(theme.vars || theme).palette.divider}`,
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: 26,
    borderRadius: 2
  }));

  const Value = styled('div')({
    position: 'absolute',
    lineHeight: '24px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  });

  const Bar = styled('div')({
    height: '100%',
    '&.low': {
      backgroundColor: '#f44336'
    },
    '&.medium': {
      backgroundColor: '#efbb5aa3'
    },
    '&.high': {
      backgroundColor: '#088208a3'
    }
  });

  export default ProgressCell