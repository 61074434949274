import { Backdrop, Button, CircularProgress, Typography } from "@mui/material"; 
import { cleanup, getServices, getAssemblyCollectionList, deleteService } from "@/reducers/customers/customersAction";
import { DataGrid, GridActionsCellItem, GridToolbar, gridClasses } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { Col, Container, Row } from "react-bootstrap";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";
import PlusCircleIcon from "mdi-react/PlusCircleIcon";

// import CreateServiceModal from "./components/createServiceModal";
// import EditServiceModal from "./components/editServiceModal";
// import AddIcon from '@mui/icons-material/Add';

import ConfirmationDialog from '@/shared/components/ConfirmationDialog';
 //onClick={onClickCreateCreateModal} endIcon={<AddIcon/>}
 import {
    Card, CardBody, CardTitleWrap, CardTitle, CardSubhead
  } from '@/shared/components/Card';

const Customers = () => {

    const dispatch = useDispatch();

    const { 
        services, isLoadingServices, getServicesError,
    } = useSelector(({ services }) => services);

    const [selectedRows, setSelectedRows] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getServices());
   
        return () => {
          dispatch(cleanup());
        }
    }, [dispatch]);

    const columns = [
      { 
          field: 'name',
          //valueGetter: ({ row }) => row.data.name,
          headerName: 'Name',
          minWidth: 350,
          flex: 1,
      },
//      { 
//          field: 'decription',
//          headerName: 'Decription', 
//          minWidth: 150
//      },
      {
          field: 'actions',
          type: 'actions',
          width: 80,
          getActions: (params) => {
              if(params.row.isSystem){
                  return [];
              }

              return [
                  <GridActionsCellItem
                      icon={<EditIcon />}
                      label="Edit"
                    //  onClick={() => handleClickEditService(params.row)}
                  />,
                  <GridActionsCellItem
                      icon={<DeleteIcon />}
                      label="Delete"
                    //  onClick={() => handleClickDeleteService(params.row)}
                  />
              ];
          },
        },
    ];

    return(
        <Container>
            <Row>

            <Card>
              <CardBody>
                <div>
                  <CardTitleWrap>
                    <CardTitle>Customer</CardTitle>
                    <CardSubhead>
                      Here you can view your Customers.
                    </CardSubhead>
                  </CardTitleWrap>
                  <Button variant='contained' color='primary' size='medium'>Add <PlusCircleIcon style={{"marginLeft": 5}} /></Button>
                  <Button variant='outlined' color='primary' size='medium'>Export Excel</Button>
                  <Button variant='contained' color='primary'>Submit</Button>
                  <Button color='secondary' variant='text'>Cancel</Button>
                </div>
                <div>
                <div style={{height: 800, paddingBottom: 20, width: '100%'}}>
                <DataGrid 
                    autoHeight
                    checkboxSelection 
                    disableRowSelectionOnClick
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setSelectedRows(newRowSelectionModel);
                    }}
                    rowSelectionModel={selectedRows}
                    loading={isLoadingServices} 
                    rows={services}
                    getRowId={(row) => row.applicationModuleId}
                    columns={columns} 
                    slots={{ toolbar: GridToolbar }} 
                    slotProps={{
                        toolbar: {
                        showQuickFilter: true
                        },
                    }}
                    sx={{
                        [`& .${gridClasses.cell}`]: {
                        py: 1,
                        },
                    }}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 30 } },
                    }}
                    pageSizeOptions={[15, 30, 50, 100]}
                />
            </div>

                </div>
              </CardBody>
            </Card>
            </Row>
    </Container>
    )
}

export default Customers;