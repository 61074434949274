import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_ACTIVITY_USER_ROLES');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getRolesAndUsersRequest = createAction('ACTIVITY_USER_ROLES_GET_USERS_AND_ROLES_REQUEST');
export const getRolesAndUsersSuccess = createAction('ACTIVITY_USER_ROLES_GET_USERS_AND_ROLES_SUCCESS');
export const getRolesAndUsersError = createAction('ACTIVITY_USER_ROLES_GET_USERS_AND_ROLES_ERROR');

export const getRolesAndUsers = (users, roles) => async (dispatch) => {
  try {
    dispatch(getRolesAndUsersRequest());
    
    const resp = await instance.post(`/v1/UserAndRole/GetUserAndRoleDataByIds`, { 
      roleIds: roles,
      userIds: users
    });

    dispatch(getRolesAndUsersSuccess(resp.data));
    
  } catch (e) {
    console.log(e);
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getRolesAndUsersError(e.response.data.description));
    else
      dispatch(getRolesAndUsersError(e.message));
  }
};