import React, { useRef } from "react";
import PropTypes from "prop-types";
import TopbarLanguage from "../components/topbar/TopbarLanguage";
import TopbarMail from "../components/topbar/TopbarMail";
import TopbarProfile from "../components/topbar/TopbarProfile";
import TopbarNotification from "../components/topbar/TopbarNotification";
import TopbarSearch from "../components/topbar/TopbarSearch";
import TopbarSidebarButton from "../components/topbar/TopbarSidebarButton";
import TopbarTenantLogo from "../components/topbar/TopbarTenantLogo";
import {
  TopbarContainer,
  TopbarLeft,
  TopbarLogo,
  TopbarRight,
  TopbarRightOver,
  TopbarSearchWrap,
} from "../components/topbar/BasicTopbarComponents";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { IconButton } from "@mui/material";
import Tooltip from "@/shared/components/Tooltip";
import TopbarImpersonating from "../components/topbar/TopbarImpersonating";
import TopbarChatbot from "../components/topbar/TopbarChatbot";

const Topbar = ({
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
  darkTheme,
  changeToDark,
}) => {
  const refButton = useRef();
  return (
    <TopbarContainer>
      <TopbarLeft>
        <TopbarLogo to='/' />
        <TopbarSidebarButton
          onClickMobile={changeMobileSidebarVisibility}
          onClickDesktop={changeSidebarVisibility}
        />
        <TopbarTenantLogo />
      </TopbarLeft>
      <TopbarRight>
        {/* <TopbarSearchWrap>
          <TopbarSearch />
        </TopbarSearchWrap> */}
        <TopbarRightOver>
          <TopbarImpersonating />
          <TopbarChatbot />
          {/* <TopbarNotification /> */}
          {/* <TopbarMail new /> */}
          <TopbarProfile />
          <TopbarLanguage />
          <Tooltip
            dir='ltr'
            text={
              darkTheme === "dark"
                ? "switch to light theme"
                : "switch to dark theme"
            }
            placement='bottom'
          >
            <IconButton ref={refButton} onClick={changeToDark}>
              {darkTheme === "dark" ? (
                <Brightness7Icon sx={{ color: "#bbbbc2" }} />
              ) : (
                <Brightness4Icon sx={{ color: "#bbbbc2" }} />
              )}
            </IconButton>
          </Tooltip>
        </TopbarRightOver>
      </TopbarRight>
    </TopbarContainer>
  );
};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;
