import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  addDomainRequest,
  addDomainSuccess,
  addDomainError
} from './addDomainAction';

const defaultState = {
  isAddingDomain: false,
  addDomainError: null,
  addedDomain: false
};

export default handleActions(
  {
    [addDomainRequest](state) {
      return {
        ...state,
        isAddingDomain: true,
        addDomainError: null,
        addedDomain: false
      };
    },
    [addDomainSuccess](state, { payload }) {
      return {
        ...state,
        isAddingDomain: false,
        addDomainError: null,
        addedDomain: true
      };
    },
    [addDomainError](state, { payload }) {
      return {
        ...state,
        isAddingDomain: false,
        addDomainError: payload,
        addedDomain: false
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);