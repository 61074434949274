import { createAction } from 'redux-actions';
import { instance } from "../../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_AVATAR');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const updateAvatarRequest = createAction('AVATAR_UPDATE_REQUEST');
export const updateAvatarSuccess = createAction('AVATAR_UPDATE_SUCCESS');
export const updateAvatarError = createAction('AVATAR_UPDATE_ERROR');

export const updateAvatar = (formData) => async (dispatch) => {
    try {
      dispatch(updateAvatarRequest());
      const resp = await instance.post(`/v1/Account/AddAvatarPic`, 
        formData, 
        { 
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      
      dispatch(updateAvatarSuccess(resp.data));
  
    } catch (e) {
      if(e.response && e.response.data && e.response.data.description)
        dispatch(updateAvatarError(e.response.data.description));
      else
        dispatch(updateAvatarError(e.message));
    }
  };