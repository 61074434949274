import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getTenantRequest,
  getTenantSuccess,
  getTenantError,
  updateTenantRequest,
  updateTenantSuccess,
  updateTenantError
} from './systemUpdateTenantAction';

const defaultState = {
  tenant: null,
  isLoadingTenant: true,
  getTenantError: null,
  isUpdatingTenant: false,
  updatedTenant: false,
  updateTenantError: null
};

export default handleActions(
  {
    [getTenantRequest](state) {
      return {
        ...state,
        tenant: null,
        isLoadingTenant: true,
        getTenantError: null,
      };
    },
    [getTenantSuccess](state, { payload }) {
      return {
        ...state,
        tenant: payload,
        isLoadingTenant: false,
        getTenantError: null,
      };
    },
    [getTenantError](state, { payload }) {
      return {
        ...state,
        tenant: null,
        isLoadingTenant: false,
        getTenantError: payload,
      };
    },
    [updateTenantRequest](state) {
      return {
        ...state,
        isUpdatingTenant: true,
        updateTenantError: null,
      };
    },
    [updateTenantSuccess](state, { payload }) {
      return {
        ...state,
        updatedTenant: true,
        isUpdatingTenant: false,
        updateTenantError: null,
      };
    },
    [updateTenantError](state, { payload }) {
      return {
        ...state,
        isUpdatingTenant: false,
        updateTenantError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);
