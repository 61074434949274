import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const ActivityMenu = ({
    activity,
    handleClickEditActivity,
    handleActivityDeleteClick,
}) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const onClickEditActivity = () => {
        handleCloseMenu();
        handleClickEditActivity(activity);
    }

    const onClickDeleteActivity = () => {
        handleCloseMenu();
        handleActivityDeleteClick(activity);
    }

    return (
        <>
            <IconButton 
                id={`menu-button${activity.workflowActivityId}`}
                aria-controls={open ? `menu${activity.workflowActivityId}` : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClickMenu}
                aria-label="settings"
            >
                <MoreVertIcon />
            </IconButton>

            <Menu
                id={`menu${activity.workflowActivityId}`}
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={onClickEditActivity}>
                    <ListItemIcon>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                </MenuItem>
                <MenuItem onClick={onClickDeleteActivity}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
};

export default ActivityMenu;