import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_SYSTEM_TENANT_LIST');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getTenantsRequest = createAction('GET_TENANTS_SYSTEM_TENANT_LIST_REQUEST');
export const getTenantsSuccess = createAction('GET_TENANTS_SYSTEM_TENANT_LIST_SUCCESS');
export const getTenantsError = createAction('GET_TENANTS_SYSTEM_TENANT_LIST_ERROR');

export const getTenants = (data) => async (dispatch) => {
  try {
    dispatch(getTenantsRequest());

    const resp = await instance.get(`/v1/Tenant/GetListPagedByFilter`, { params: {
      ...data
    }});
    
    dispatch(getTenantsSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getTenantsError(e.response.data.description));
    else
      dispatch(getTenantsError(e.message));
  }
};

export const deleteTenantRequest = createAction('SYSTEM_TENANT_LIST_DELETE_REQUEST');
export const deleteTenantSuccess = createAction('SYSTEM_TENANT_LIST_DELETE_SUCCESS');
export const deleteTenantError = createAction('SYSTEM_TENANT_LIST_DELETE_ERROR');

export const deleteTenant = (id) => async (dispatch) => {
  try {
    dispatch(deleteTenantRequest());
    const resp = await instance.delete(`/v1/Tenant/DeleteLogic/${id}`);

    dispatch(deleteTenantSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(deleteTenantError(e.response.data.description));
    else
      dispatch(deleteTenantError(e.message));
  }
};