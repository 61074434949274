import React, { useEffect, useState, setState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from "notistack";
import { Backdrop, Button, Card, CardContent, CardHeader, CircularProgress, Typography } from "@mui/material";
import styled from 'styled-components';
import SecurityUpdateGoodIcon from '@mui/icons-material/SecurityUpdateGood';
import { Col, Row } from "react-bootstrap";
import { QR } from "@/shared/components/QRCode";
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { FormCheckboxField } from '@/shared/components/form/material-controls/CheckboxField';
import { cleanup, getTwoFactorSettings, setup2FAs, disable2FAs } from '@/reducers/account/settings/twoFactorSettings/twoFactorSettingsAction';
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import { border } from 'polished';

const TwoFactorSettings = () => {

    const dispatch = useDispatch();
    
    const [checkState, setCheckState] = useState(false)

    function handleCheck() {
        setCheckState(twoFactorSettings.twoFactorEnabled);
        twoFactorSettings.twoFactorEnabled = checkState;     
    }

    const {
        twoFactorSettings, isLoadingTwoFactorSettings, twoFactorSettingsError
    } = useSelector(({ twoFactorSettings }) => twoFactorSettings);
    
    const {
        setup2FA, isSetup2FA, setup2FAError
    } = useSelector(({ setup2FA }) => setup2FA);

    const {
        disable2FA, isdisable2FA, disable2FAError
    } = useSelector(({ disable2FA }) => disable2FA);

    const { handleSubmit, reset, control, setValue, getValues, watch } = useForm();

    useEffect(() => {
        dispatch(getTwoFactorSettings());
        return () => {
            dispatch(cleanup());
            reset();
        }
    }, [dispatch]);

    useEffect(() => {
        if (twoFactorSettingsError) {
            enqueueSnackbar(twoFactorSettingsError, { variant: "error" });
        }
    }, [twoFactorSettingsError]);

    useEffect(() => {
        if (setup2FA) {
            enqueueSnackbar("Setup 2FA completed", { variant: "success" });
            dispatch(getTwoFactorSettings());
        }
    }, [setup2FA]);

    useEffect(() => {
        if(setup2FAError){
          enqueueSnackbar(setup2FAError, {variant: "error"});
        }
      }, [setup2FAError]);

    useEffect(() => {
        if (disable2FA) {
            enqueueSnackbar("2FA disabled", { variant: "success" });
            dispatch(getTwoFactorSettings());
        }
    }, [disable2FA]);

    useEffect(() => {
        if (disable2FAError) {
            enqueueSnackbar(disable2FAError, { variant: "error" });
        }
    }, [disable2FAError]);

    const onSubmitDisable = (data) => {
        dispatch(disable2FAs(data));
    }

    const onSubmitSetup = (data) => {
        dispatch(setup2FAs(data));
    }

    return (
        <Card>
            <CardContent>
                <TitleContainer>
                    <SecurityUpdateGoodIcon />
                    <Typography variant='h5'>Two Factor Authentication</Typography>
                </TitleContainer>
                
                <Row>
                    <Col md={12}>
                       
                    </Col>
                </Row>
                {isLoadingTwoFactorSettings || !twoFactorSettings ? <CircularProgressWrapper /> :
                    <Row>
                        <Row>
                            <Col md={12}>
                                <FormCheckboxField
                                    name="twoFactorEnabled"
                                    label="Enabled"
                                    control={control}
                                    defaultValue={twoFactorSettings.twoFactorEnabled}
                                    onClick = { event => event.preventDefault()}
                                   // onChange={handleCheck}
                                   // checked={twoFactorSettings.twoFactorEnabled}
                                /> 
                            </Col>
                        </Row>
                        {twoFactorSettings.twoFactorEnabled && 
                            <Row>
                                <Col md={6}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button
                                            variant="contained"
                                           // onClick={handleSubmit(onSubmit)}
                                        >
                                            Reset Two Factor Authentication
                                        </Button>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button
                                            variant="contained"
                                            onClick={handleSubmit(onSubmitDisable)}
                                        >
                                            Disable Two Factor Authentication
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        }
                        {!twoFactorSettings.twoFactorEnabled &&
                        <>
                            <Row>
                                <Col md={12}>
                                    
                                    <div name="Setup2FASection">
                                        <Typography>
                                            1. Download a two-factor authenticator app like Microsoft Authenticator for <a href="https://go.microsoft.com/fwlink/?Linkid=825071" target="_blank">Windows Phone</a>, 
                                            <a href="https://play.google.com/store/apps/details?id=com.azure.authenticator" target="_blank"> Android</a>, 
                                            <a href="https://apps.apple.com/app/id983156458" target="_blank">iOS</a>. Google Authenticator for
                                            <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en" target="_blank"> Android</a>,
                                            <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank"> iOS</a>.
                                        </Typography>
                                        <br/>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div name="Setup2FASection">
                                        <Typography>
                                            2. ScanQR <span style={{padding: '3px', fontWeight:'bold', border: '2px', borderStyle: 'solid', borderColor: '#0d6efd', } }>{twoFactorSettings.code}</span> into your two factor authenticator app. Spaces and casing do not matter.
                                        </Typography>
                                        <br/>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div name="Setup2FASection">
                                        <QR
                                            size={200}
                                            value={twoFactorSettings.qrCodeUri}
                                        />
                                        <br/>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div name="Setup2FASection">
                                        <Typography>
                                            3. Once you have scanned the QR code or input the key above, your two factor authentication app will provide you with a unique code. Enter the code in the confirmation box below.
                                        </Typography>
                                        <br/>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div name="Setup2FASection">
                                    <FormTextField
                                        name="code"
                                        label="Generated Code"
                                        control={control}
                                        required={true}
                                    />
                                    </div>
                                    <br/>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button
                                            variant="contained"
                                            onClick={handleSubmit(onSubmitSetup)}
                                        >
                                            Verify Code
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </>
                        }
                    </Row>
                }


            </CardContent>
        </Card>
    );
};

const CodeContainer = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 20px;

`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 20px;

  & > svg{
    margin-right: 5px;
    font-size: 30px;
  }
`;

export default TwoFactorSettings;