import { Col, Container, Row } from "react-bootstrap";
import { CardPage, CardPageBody, CardPageTitleWrap, CardPageTitle, CardPageGridWrap} from '@/shared/components/MaterialCardPage';
import SelectChipArray from "@/shared/components/SelectChipArray";
import { useDispatch, useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { CircularProgressWrapper } from '@/shared/components/CircularProgressWrapper';
import { getSurveys, cleanup } from "@/reducers/externalAudit/dashboard/externalAuditDasboardAction";
import DoughnutChart from "@/shared/components/charts/DoughnutChart";
import { useState } from "react";
import { getDashboardFilterYears } from "@/utils/dates";
import { Autocomplete, TextField, Typography } from "@mui/material";
import ActivityCounts from "../../Home/components/activityCounts";

const ExternalAuditDashboard = () => {

    const dispatch = useDispatch();

    const { 
        surveys, isLoadingSurveys, getSurveysError 
    } = useSelector(({ externalAuditDasboard }) => externalAuditDasboard);

    const [selectedSurvey, setSelectedSurvey] = useState(null);

    const years = getDashboardFilterYears();
    const [selectedYear, setSelectedYear] = useState(years[years.length - 1]);

    useEffect(() => {
        dispatch(getSurveys());

        return () => {
            dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
        if(getSurveysError){
          enqueueSnackbar(getSurveysError, {variant: "error"});
        }
    }, [getSurveysError]);

    const onChangeSurvey = (survey) => {
        setSelectedSurvey(survey);
    };

    return(
        <Container>
            <CardPage>
                <CardPageBody>
                <CardPageTitleWrap>
                    <CardPageTitle>External Audit Dashboard</CardPageTitle>
                </CardPageTitleWrap>
                <Row style={{marginBottom: '16px'}}>
                    <Col md={12}>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Autocomplete
                            style={{ width: 100 }}
                            disablePortal
                            disableClearable
                            options={years}
                            value={selectedYear}
                            onChange={(e, data) => setSelectedYear(data)}
                            renderInput={(params) => 
                                <TextField 
                                    {...params}
                                    size="small"
                                    label="Year"
                                />
                            }
                            />
                        </div>
                    </Col>
                </Row>

                <ActivityCounts year={selectedYear} activityTypeId={4} />

                <CardPageGridWrap style={{marginTop: 20}}>
                    {isLoadingSurveys || !surveys ? <CircularProgressWrapper /> :
                        <>
                            <Row>
                                <Col style={{marginTop: 10}} md={12}>
                                    <SelectChipArray 
                                        options={surveys}
                                        defaultValue={surveys[0]}
                                        onChange={onChangeSurvey}
                                        keyValue={{id: "surveyId", label: "label"}}
                                    />
                                </Col>
                                <Col style={{marginTop: 10}} md={12}>
                                <DoughnutChart 
                                    endpoint={`GetExternalAuditRisk?year=${selectedYear}&surveyId=${selectedSurvey ? selectedSurvey.surveyId : surveys[0].surveyId}`}
                                    height={500} 
                                    legend={{
                                        orient: 'vertical',
                                        left: 'left'
                                    }}
                                    title={{
                                        text: selectedSurvey ? selectedSurvey.label : surveys[0].label,
                                        left: 'center'
                                    }}
                                    tooltip= {{
                                        trigger: 'item',
                                        axisPointer: {
                                          type: 'cross'
                                        },
                                        hideDelay: 2000,
                                        position: function (pos, params, dom, rect, size) {
                                            if((pos[0] < size.viewSize[0] / 2)){
                                                return "left";
                                            }
                                            else{
                                                return "right";
                                            }
                                        },
                                        enterable: true,
                                        formatter: function (params, ticket, callback) {
                                            const content = params.data.items.join(', ');
                                            return `
                                                <div><strong>${params.name}: </strong>${params.value}</div>
                                                </br>
                                                <div style="max-width: 350px; max-height: 400px; overflow-y: auto; white-space: wrap;">
                                                    <strong>Suppliers: </strong>${content}
                                                </div>
                                            `;
                                        }
                                    }}
                                    />
                                </Col>
                            </Row>
                        </>
                    }
                </CardPageGridWrap>
                </CardPageBody>
            </CardPage>
        </Container>
    );
}

export default ExternalAuditDashboard;