import { useForm } from "react-hook-form";
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { Box, Button } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';

const ChatInput = ({
    onMessageSend
}) => {
    
    const { handleSubmit, reset, control, setValue, getValues } = useForm();

    const onSubmit = (data) => {
        if(data.message){
            onMessageSend(data.message);
        }

        reset();
    }

    return(
        <Box sx={{
            display: 'flex',
            marginTop: '10px'
        }}>
            <FormTextField 
                style={{marginTop: 0}}
                name='message'
                label='Message'
                control={control}
                multiline
                rows={2}
            />
            <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                <SendIcon/>
            </Button>
        </Box>
    );
}

export default ChatInput;