import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  updateVulnerabilityStatusRequest,
  updateVulnerabilityStatusSuccess,
  updateVulnerabilityStatusError
} from './updateVulnerabilityStatusAction';

const defaultState = {
  isUpdatingVulnerabilityStatus: false,
  updateVulnerabilityStatusError: null,
  updatedVulnerabilityStatus: false
};

export default handleActions(
  {
    [updateVulnerabilityStatusRequest](state) {
      return {
        ...state,
        isUpdatingVulnerabilityStatus: true,
        updateVulnerabilityStatusError: null,
        updatedVulnerabilityStatus: false
      };
    },
    [updateVulnerabilityStatusSuccess](state, { payload }) {
      return {
        ...state,
        isUpdatingVulnerabilityStatus: false,
        updateVulnerabilityStatusError: null,
        updatedVulnerabilityStatus: true
      };
    },
    [updateVulnerabilityStatusError](state, { payload }) {
      return {
        ...state,
        isUpdatingVulnerabilityStatus: false,
        updateVulnerabilityStatusError: payload,
        updatedVulnerabilityStatus: false
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);