import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_UPDATE_VULNERABILITY_STATUS');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const updateVulnerabilityStatusRequest = createAction('UPDATE_VULNERABILITY_STATUS_POST_REQUEST');
export const updateVulnerabilityStatusSuccess = createAction('UPDATE_VULNERABILITY_STATUS_POST_SUCCESS');
export const updateVulnerabilityStatusError = createAction('UPDATE_VULNERABILITY_STATUS_POST_ERROR');

export const updateVulnerabilityStatus = (request) => async (dispatch) => {
  try {
    dispatch(updateVulnerabilityStatusRequest());
    const resp = await instance.put(`/v1/VulnerabilityStatus/Put`, request);

    dispatch(updateVulnerabilityStatusSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(updateVulnerabilityStatusError(e.response.data.description));
    else
      dispatch(updateVulnerabilityStatusError(e.message));
  }
};
