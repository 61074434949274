import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getTwoFactorSettingsRequest,
  getTwoFactorSettingsSuccess,
  getTwoFactorSettingsError,
  setup2FARequest,
  setup2FASuccess,
  setup2FAError,
  disable2FARequest,
  disable2FASuccess,
  disable2FAError, 
//   updateUserOptionsRequest,
//   updateUserOptionsSuccess,
//   updateUserOptionsError,
} from './twoFactorSettingsAction';

const defaultState = {
  checkState: null,
  twoFactorSettings: null,
  isLoadingTwoFactorSettings: false,
  twoFactorSettingsError: null,
  isSetup2FA: false,
  setup2FAError: null,
  setup2FA: null,
  isDisable2FA: false,
  disableFAError: null,
  disable2FA: null
//   isUpdatingUserOptions: false,
//   updateUserOptionsError: null,
//   updatedOptionsUser: false
};

export default handleActions(
  {
    [getTwoFactorSettingsRequest](state) {
      return {
        ...state,
        isLoadingTwoFactorSettings: true,
        twoFactorSettingsError: null,
      };
    },
    [getTwoFactorSettingsSuccess](state, { payload }) {
      return {
        ...state,
        twoFactorSettings: payload,
        checkState: payload.twoFactorEnabled,
        isLoadingTwoFactorSettings: false,
        twoFactorSettingsError: null,
      };
    },
    [getTwoFactorSettingsError](state, { payload }) {
      return {
        ...state,
        isLoadingTwoFactorSettings: false,
        twoFactorSettingsError: payload,
      };
    },
    [setup2FARequest](state) {
        return {
            ...state,
            isSetup2FA: true,
            setup2FAError: null,
            setup2FA: false
        };
    },
    [setup2FASuccess](state, { payload }) {
        return {
            ...state,
            isSetup2FA: false,
            setup2FAError: null,
            setup2FA: true
        };
    },
    [setup2FAError](state, { payload }) {
        return {
            ...state,
            isSetup2FA: false,
            setup2FAError: payload,
            setup2FA: false
        };
    },
    [disable2FARequest](state) {
      return {
        ...state,
        isDisable2FA: true,
        disable2FAError: null,
        disable2FA: false
      };
    },
    [disable2FASuccess](state, { payload }) {
      return {
        ...state,
        isDisable2FA: false,
        disable2FAError: null,
        disable2FA: true
      };
    },
    [disable2FAError](state, { payload }) {
      return {
        ...state,
        isDisable2FA: false,
        disable2FAError: payload,
        disable2FA: false
      };
    },
    // [updateUserOptionsRequest](state) {
    //     return {
    //         ...state,
    //         isUpdatingUserOptions: true,
    //         updateUserOptionsError: null,
    //         updatedUserOptions: false
    //     };
    // },
    // [updateUserOptionsSuccess](state, { payload }) {
    //     return {
    //         ...state,
    //         isUpdatingUserOptions: false,
    //         updateUserOptionsError: null,
    //         updatedUserOptions: true
    //     };
    // },
    // [updateUserOptionsError](state, { payload }) {
    //     return {
    //         ...state,
    //         isUpdatingUserOptions: false,
    //         updateUserOptionsError: payload,
    //         updatedUserOptions: false
    //     };
    // },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);