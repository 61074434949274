import { Box, Divider, Icon, Tab, Tabs, Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import RiskRadar from "./riskRadar";
import ReportCalculationHistory from "./reportCalculationHistory";
import ReportGradient from "./reportGradient";
import TabPanel, { tabIndexProps } from "@/shared/components/MaterialTabPanel";
import { useState } from "react";
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import RestoreIcon from '@mui/icons-material/Restore';

const ReportAndKPI = ({
    asset
}) => {

    const [tab, setTab] = useState(0);

    const handleTabChange = (event, newValue) => {
      setTab(newValue);
    };

    const lastCalculationSurveys = [];
    const groupByCodeArray = [];

    if(asset.metadata?.surveyCalculations?.length)
    {
        const groupByCode = Object.groupBy(asset.metadata.surveyCalculations, ({ surveyCode }) => surveyCode);
        const keys = Object.keys(groupByCode);

        keys.forEach(k => groupByCodeArray.push(groupByCode[k]));
        groupByCodeArray.forEach(g => lastCalculationSurveys.push(g[g.length - 1]));
    }

    const ratingScores = [
        {
            name: "Very Low",
            value: 1,
            color: "#00B050"
        },
        {
            name: "Low",
            value: 2,
            color: "#92D050"
        },
        {
            name: "Medium",
            value: 3,
            color: "#FFC000"
        },
        {
            name: "High",
            value: 4,
            color: "#FF8D00"
        },
        {
            name: "Critical",
            value: 5,
            color: "#FF0000"
        }
    ];

    var gradient = "linear-gradient(to right";
    ratingScores.forEach(r => gradient += ", " + r.color);
    gradient += ")";

    return(
        <Box>
            <Tabs
              value={tab}
              variant="fullWidth"
              onChange={handleTabChange}
              aria-label="icon position tabs example"
            >
              <Tab 
                icon={<QueryStatsIcon />} 
                iconPosition="start" 
                label="Reports"
                {...tabIndexProps(0)}
              />
               <Tab 
                icon={<RestoreIcon />} 
                iconPosition="start" 
                label="History"
                {...tabIndexProps(1)}
              />
            </Tabs>
          
            <TabPanel value={tab} index={0}>
                <Row>
                    <Divider sx={{mb: 1}} textAlign="left">Risk</Divider>
                    <Col md={6}>
                    { lastCalculationSurveys.map(s => 
                        <Row>
                            <Col md={8}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap',
                                    mb: 3
                                }}>
                                    <Box sx={{
                                        display: 'flex'
                                    }}>
                                        <Icon sx={{ mr: 1 }}>{s.icon}</Icon>
                                        <Typography>{s.surveyName}</Typography>
                                    </Box>
                                    <Typography sx={{
                                        ml: 4,
                                        color: 'text.secondary',
                                        fontSize: 10
                                    }}>({s.surveyCode})</Typography>
                                </Box>
                            </Col>
                            <Col md={4}>
                                <ReportGradient 
                                    calculation={s}
                                    gradient={gradient}
                                />
                            </Col>
                        </Row>
                    )}
                    </Col>
                    <Col md={6}>
                        { lastCalculationSurveys.length >= 3 && 
                            <RiskRadar calculations={lastCalculationSurveys}/>
                        }
                    </Col>

                    <Divider sx={{my: 2}} textAlign="left">ESG</Divider>
                </Row>
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <ReportCalculationHistory 
                    calculations={groupByCodeArray} 
                    gradient={gradient}
                />
            </TabPanel>
        </Box> 
    );
};

export default ReportAndKPI;