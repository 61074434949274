import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";
import qs from 'qs';

export const cleanupAction = createAction('CLEANUP_AU_SELECT_SURVEYS');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getAuditCampaignRequest = createAction('AU_SELECT_SURVEYS_GET_CAMPAIGN_REQUEST');
export const getAuditCampaignSuccess = createAction('AU_SELECT_SURVEYS_GET_CAMPAIGN_SUCCESS');
export const getAuditCampaignError = createAction('AU_SELECT_SURVEYS_GET_CAMPAIGN_ERROR');

export const getAuditCampaign = (id) => async (dispatch) => {
  try {
    dispatch(getAuditCampaignRequest());
    const resp = await instance.get(`/v1/ActivityCampaign/GetByActivityId/${id}`);
    
    dispatch(getAuditCampaignSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getAuditCampaignError(e.response.data.description));
    else
      dispatch(getAuditCampaignError(e.message));
  }
};

export const getSurveysRequest = createAction('AU_SELECT_SURVEYS_GET_LIST_REQUEST');
export const getSurveysSuccess = createAction('AU_SELECT_SURVEYS_GET_LIST_SUCCESS');
export const getSurveysError = createAction('AU_SELECT_SURVEYS_GET_LIST_ERROR');

export const getSurveys = (libraryIds) => async (dispatch) => {
  try {
   
    dispatch(getSurveysRequest());
    const resp = await instance.get(`/v1/Survey/GetListByLibrary`, {
      params: {
        libraryIds
      },
      paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'repeat'})
    });
    
    dispatch(getSurveysSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getSurveysError(e.response.data.description));
    else
      dispatch(getSurveysError(e.message));
  }
};

export const confirmSurveysRequest = createAction('AU_SELECT_SURVEYS_CONFIRM_REQUEST');
export const confirmSurveysSuccess = createAction('AU_SELECT_SURVEYS_CONFIRM_SUCCESS');
export const confirmSurveysError = createAction('AU_SELECT_SURVEYS_CONFIRM_ERROR');

export const confirmSurveys = (data) => async (dispatch) => {
  try {
    dispatch(confirmSurveysRequest());
    const resp = await instance.post(`/v1/ActivityCampaign/UpdateSurveyIds`, data);
    
    dispatch(confirmSurveysSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(confirmSurveysError(e.response.data.description));
    else
      dispatch(confirmSurveysError(e.message));
  }
};