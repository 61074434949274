import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_GENERAL_INFO');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}


export const getPrioritiesRequest = createAction('GENERAL_INFO_GET_PRIORITIES_REQUEST');
export const getPrioritiesSuccess = createAction('GENERAL_INFO_GET_PRIORITIES_SUCCESS');
export const getPrioritiesError = createAction('GENERAL_INFO_GET_PRIORITIES_ERROR');

export const getPriorities = () => async (dispatch) => {
  try {
    dispatch(getPrioritiesRequest());
    const resp = await instance.get(`/v1/Workflow/GetWorkflowActivityPriority`);

    if(resp.data.isSuccessed)
      dispatch(getPrioritiesSuccess(resp.data.data));
    else
      dispatch(getPrioritiesError(resp.data.error));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getPrioritiesError(e.response.data.description));
    else
      dispatch(getPrioritiesError(e.message));
  }
};

export const getWorkflowRolesRequest = createAction('GENERAL_INFO_GET_WORKFLOW_ROLES_REQUEST');
export const getWorkflowRolesSuccess = createAction('GENERAL_INFO_GET_WORKFLOW_ROLES_SUCCESS');
export const getWorkflowRolesError = createAction('GENERAL_INFO_GET_WORKFLOW_ROLES_ERROR');

export const getWorkflowRoles = (id) => async (dispatch) => {
  try {
    dispatch(getWorkflowRolesRequest());
    const resp = await instance.get(`/v1/Workflow/GetWorkflowRoles?ActivityTypeId=${id}`);
    
    if(resp.data.isSuccessed)
      dispatch(getWorkflowRolesSuccess(resp.data.data));
    else
      dispatch(getWorkflowRolesError(resp.data.error));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getWorkflowRolesError(e.response.data.description));
    else
      dispatch(getWorkflowRolesError(e.message));
  }
};

export const getRolesWithUsersRequest = createAction('GENERAL_INFO_GET_USERS_WITH_ROLES_REQUEST');
export const getRolesWithUsersSuccess = createAction('GENERAL_INFO_GET_USERS_WITH_ROLES_SUCCESS');
export const getRolesWithUsersError = createAction('GENERAL_INFO_GET_USERS_WITH_ROLES_ERROR');

export const getRolesWithUsers = (roles) => async (dispatch) => {
  try {
    dispatch(getRolesWithUsersRequest());
    
    const resp = await instance.post(`/v1/UserAndRole/GetUsersByRoleIds`, { 
      roleIds: roles 
    });
    
    dispatch(getRolesWithUsersSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getRolesWithUsersError(e.response.data.description));
    else
      dispatch(getRolesWithUsersError(e.message));
  }
};