import { createAction } from 'redux-actions';
import { instance } from "../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_SURVEY_TEST');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getSurveyRequest = createAction('SURVEY_TEST_GET_REQUEST');
export const getSurveySuccess = createAction('SURVEY_TEST_GET_SUCCESS');
export const getSurveyError = createAction('SURVEY_TEST_GET_ERROR');

export const getSurvey = (templateId) => async (dispatch) => {
  try {
    dispatch(getSurveyRequest());
    const resp = await instance.get(`/v1/Template/GetSurveyModel`, {
      params: {
        templateId
      }
    });
    
    dispatch(getSurveySuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getSurveyError(e.response.data.description));
    else
      dispatch(getSurveyError(e.message));
  }
};