import { createAction } from 'redux-actions';
import { instance } from "../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_SERVICES');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getServicesRequest = createAction('SERVICES_GET_ALL_REQUEST');
export const getServicesSuccess = createAction('SERVICES_GET_ALL_SUCCESS');
export const getServicesError = createAction('SERVICES_GET_ALL_ERROR');

export const getServices = () => async (dispatch) => {
  try {
    dispatch(getServicesRequest());
    const resp = await instance.get(`/v1/Service/GetAll`);
    
    dispatch(getServicesSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getServicesError(e.response.data.description));
    else
      dispatch(getServicesError(e.message));
  }
};

export const getConnectorsRequest = createAction('SERVICES_GET_CONNECTORS_REQUEST');
export const getConnectorsSuccess = createAction('SERVICES_GET_CONNECTORS_SUCCESS');
export const getConnectorsError = createAction('SERVICES_GET_CONNECTORS_ERROR');

export const getConnectors = () => async (dispatch) => {
  try {
    dispatch(getConnectorsRequest());
    const resp = await instance.get(`/v1/Connector/GetKeyValue`);

    dispatch(getConnectorsSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getConnectorsError(e.response.data.description));
    else
      dispatch(getConnectorsError(e.message));
  }
};

export const getCronIntervalsRequest = createAction('SERVICES_GET_CRON_INVERVALS_REQUEST');
export const getCronIntervalsSuccess = createAction('SERVICES_GET_CRON_INVERVALS_SUCCESS');
export const getCronIntervalsError = createAction('SERVICES_GET_CRON_INVERVALS_ERROR');

export const getCronIntervals = () => async (dispatch) => {
  try {
    dispatch(getCronIntervalsRequest());
    const resp = await instance.get(`/v1/CronInterval/GetKeyValue`);

    dispatch(getCronIntervalsSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getCronIntervalsError(e.response.data.description));
    else
      dispatch(getCronIntervalsError(e.message));
  }
};

export const deleteServiceRequest = createAction('SERVICES_DELETE_REQUEST');
export const deleteServiceSuccess = createAction('SERVICES_DELETE_SUCCESS');
export const deleteServiceError = createAction('SERVICES_DELETE_ERROR');

export const deleteService = (id) => async (dispatch) => {
  try {
    dispatch(deleteServiceRequest());
    const resp = await instance.delete(`/v1/Service/DeleteLogic/${id}`);

    dispatch(deleteServiceSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(deleteServiceError(e.response.data.description));
    else
      dispatch(deleteServiceError(e.message));
  }
};

export const executeServiceRequest = createAction('SERVICES_EXECUTE_REQUEST');
export const executeServiceSuccess = createAction('SERVICES_EXECUTE_SUCCESS');
export const executeServiceError = createAction('SERVICES_EXECUTE_ERROR');

export const executeService = (id) => async (dispatch) => {
  try {
    dispatch(executeServiceRequest());
    const resp = await instance.post(`/v1/Service/TriggerJob/${id}`);

    dispatch(executeServiceSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(executeServiceError(e.response.data.description));
    else
      dispatch(executeServiceError(e.message));
  }
};