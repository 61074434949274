import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getSchemaRequest,
  getSchemaSuccess,
  getSchemaError,
  getAssetsRequest,
  getAssetsSuccess,
  getAssetsError
} from './advancedFiltersAction';

const defaultState = {
  schema: [],
  isLoadingSchema: true,
  getSchemaError: null,
  assets: [],
  isLoadingAssets: false,
  getAssetsError: null
};

export default handleActions(
  {
    [getSchemaRequest](state) {
      return {
        ...state,
        isLoadingSchema: true,
        getSchemaError: null,
      };
    },
    [getSchemaSuccess](state, { payload }) {
      return {
        ...state,
        schema: payload,
        isLoadingSchema: false,
        getSchemaError: null,
      };
    },
    [getSchemaError](state, { payload }) {
      return {
        ...state,
        isLoadingSchema: false,
        getSchemaError: payload,
      };
    },    
    [getAssetsRequest](state) {
      return {
        ...state,
        isLoadingAssets: true,
        getAssetsError: null,
      };
    },
    [getAssetsSuccess](state, { payload }) {
      return {
        ...state,
        assets: payload,
        isLoadingAssets: false,
        getAssetsError: null,
      };
    },
    [getAssetsError](state, { payload }) {
      return {
        ...state,
        isLoadingAssets: false,
        getAssetsError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);
