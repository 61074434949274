import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  updateClassificationRequest,
  updateClassificationSuccess,
  updateClassificationError
} from './updateClassificationAction';

const defaultState = {
  isUpdatingClassification: false,
  updateClassificationError: null,
  updatedClassification: false
};

export default handleActions(
  {
    [updateClassificationRequest](state) {
      return {
        ...state,
        isUpdatingClassification: true,
        updateClassificationError: null,
        updatedClassification: false
      };
    },
    [updateClassificationSuccess](state, { payload }) {
      return {
        ...state,
        isUpdatingClassification: false,
        updateClassificationError: null,
        updatedClassification: true
      };
    },
    [updateClassificationError](state, { payload }) {
      return {
        ...state,
        isUpdatingClassification: false,
        updateClassificationError: payload,
        updatedClassification: false
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);