import { Backdrop, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { FormSelectField } from '@/shared/components/form/material-controls/SelectField';
import { FormDateField } from '@/shared/components/form/material-controls/DateField';
import { cleanup, updateActivity, getRolesWithUsers } from "@/reducers/activity/editActivityInfo/editActivityInfoAction";
import dayjs from "dayjs";

const EditActivityModal = ({ priorities, open, onHideModal, onActivityUpdated, activity }) => {
    const dispatch = useDispatch();
  
    const { 
      isUpdatingActivity, updateRemediationError, updatedActivity,
      rolesWithUsers, isLoadingRolesWithUsers, getRolesWithUsersError
    } = useSelector(({ editActivityInfo }) => editActivityInfo);

    useEffect(() => {
      dispatch(getRolesWithUsers(activity.workflowActivityUserRole.map(w => w.roleId)));

      return () => {
        dispatch(cleanup());
      }
    }, [dispatch]);
  
    const { handleSubmit, reset, control, setValue, getValues } = useForm();
  
    const handleClose = (event, reason) => {
      if (reason && reason === "backdropClick") 
          return;
        
      reset();
      onHideModal();
    }

    useEffect(() => {
      if(getRolesWithUsersError){
        enqueueSnackbar(getRolesWithUsersError, {variant: "error"});
      }
    }, [getRolesWithUsersError]);
  
    useEffect(() => {
      if(updateRemediationError){
        enqueueSnackbar(updateRemediationError, {variant: "error"});
      }
    }, [updateRemediationError]);
  
    const onSubmit = (data) => {
      data.workflowActivityId = activity.workflowActivityId;      
      dispatch(updateActivity(data));
    }
  
    useEffect(() => {
      if(updatedActivity){
        enqueueSnackbar("Activity info updated successfully", {variant: "success"});
        handleClose();
        onActivityUpdated();
      }
    }, [updatedActivity]);
  
    return (
      <Dialog
          fullWidth
          maxWidth='md'
          open={open}
          onClose={handleClose}
      >
          <DialogTitle>Edit Activity Info</DialogTitle>
          <DialogContent>
              {!activity ? <></> : 
                <Row style={{marginTop: 10}}>
                  <Col md={12}>
                    <FormTextField 
                        style={{marginTop: 0}}
                        name='description'
                        label='Description'
                        control={control}
                        rules={{ required: 'This field is required' }} 
                        defaultValue={activity.description}
                        multiline
                        rows={4}
                    />
                  </Col>
                  <Col md={4}>
                    <FormSelectField 
                      name="workflowActivityPriorityId" 
                      label="Priority" 
                      control={control} 
                      options={priorities}
                      keyValue={{ id:"workflowActivityPriorityId", label:"name" }}
                      defaultValue={activity.workflowActivityPriorityId}
                      rules={{ required: 'This field is required' }} 
                    />
                  </Col>
                  <Col md={4}>
                      <FormDateField
                          name='startDate'
                          label='Start Date'
                          control={control}
                          defaultValue={activity.startDate ? dayjs(activity.startDate) : null}
                      />
                  </Col>
                  <Col md={4}>
                      <FormDateField
                          name='endDate'
                          label='End Date'
                          control={control}
                          defaultValue={activity.endDate ? dayjs(activity.endDate) : null}
                      />
                  </Col>
                  <Col md={12} style={{marginTop: 20}}>
                    <Card sx={{border: '1px solid'}}>
                      <CardContent>
                        <Typography>Assign Users</Typography>
                        <Row>
                          { isLoadingRolesWithUsers || !rolesWithUsers ? <div style={{height: '200px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><CircularProgress /></div> :
                            activity.workflowActivityUserRole.map((item, index) => {
                              const grouppedUsers = rolesWithUsers.find(r => r.roleId === item.roleId);

                              return ( 
                                <Col md={4} key={index}>
                                    <FormSelectField
                                        name={`usersRoles.${item.roleId}`}
                                        label={`User for role ${grouppedUsers.roleName}`}
                                        control={control}
                                        rules={{ required: 'This field is required' }} 
                                        options={grouppedUsers.users}
                                        keyValue={{label: "fullName", id: "id"}}
                                        defaultValue={item.userId}
                                    />
                                </Col>
                            )})
                          }
                        </Row>
                      </CardContent>
                    </Card>
                  </Col>
                </Row>
              }
          </DialogContent>
          <DialogActions style={{marginTop: 100}}>
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant='contained' onClick={handleSubmit(onSubmit)}>Edit</Button>
          </DialogActions>
  
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isUpdatingActivity}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
      </Dialog>
    );
  }
  
  export default EditActivityModal;