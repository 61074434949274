import { Backdrop, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { cleanup, updateVulnerabilityStatus } from "@/reducers/vulnerabilityStatuses/updateVulnerabilityStatus/updateVulnerabilityStatusAction";
import { FormColorField } from "@/shared/components/form/material-controls/ColorField";
import { FormSliderField } from "@/shared/components/form/material-controls/SliderField";

const EditVulnerabilityStatusModal = ({ open, onHideModal, onVulnerabilityStatusUpdated, vulnerabilityStatus }) => {
    const dispatch = useDispatch();
  
    const { 
      isUpdatingVulnerabilityStatus, updateVulnerabilityStatusError, updatedVulnerabilityStatus
    } = useSelector(({ updateVulnerabilityStatus }) => updateVulnerabilityStatus);

    useEffect(() => {
      return () => {
        dispatch(cleanup());
      }
    }, [dispatch]);
  
    const { handleSubmit, reset, control, setValue, getValues } = useForm();
  
    const handleClose = (event, reason) => {
      if (reason && reason === "backdropClick") 
          return;
        
      reset();
      onHideModal();
    }
  
    useEffect(() => {
      if(updateVulnerabilityStatusError){
        enqueueSnackbar(updateVulnerabilityStatusError, {variant: "error"});
      }
    }, [updateVulnerabilityStatusError]);
  
    const onSubmit = (data) => {
      data.id = vulnerabilityStatus.id;
      data.name = { it: data.nameTraslated, en: data.nameTraslated};
      data.description = { it: data.descriptionTraslated, en: data.descriptionTraslated};

      dispatch(updateVulnerabilityStatus(data));
    }
  
    useEffect(() => {
      if(updatedVulnerabilityStatus){
        enqueueSnackbar("Vulnerability Status updated successfully", {variant: "success"});
        handleClose();
        onVulnerabilityStatusUpdated();
      }
    }, [updatedVulnerabilityStatus]);
  
    return (
      <Dialog
          fullWidth={true}
          maxWidth='lg'
          scroll='paper'
          open={open}
          onClose={handleClose}
      >
          <DialogTitle>Edit Vulnerability Status</DialogTitle>
          <DialogContent>
                <Row>
                  <Col md={12}>
                    <FormTextField
                        name='nameTraslated'
                        label='Name'
                        control={control}
                        rules={{ required: 'This field is required' }}
                        defaultValue={vulnerabilityStatus.nameTraslated}
                    />
                  </Col>
                  <Col md={12}>
                    <FormTextField
                        name='descriptionTraslated'
                        label='Description'
                        control={control}
                        defaultValue={vulnerabilityStatus.descriptionTraslated}
                        multiline
                        rows={4}
                    />
                  </Col>
                  <Col md={6}>
                    <FormSliderField 
                      name='value'
                      label='Value'
                      control={control}
                      marks
                      min={0}
                      max={1}
                      step={0.1}
                      defaultValue={vulnerabilityStatus.value}
                    />
                  </Col>
                  <Col md={6}>
                    <FormColorField 
                        name='color'
                        label='Color'
                        control={control}
                        defaultValue={vulnerabilityStatus.color}
                    />
                  </Col>
                </Row>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant='contained' color="success" onClick={handleSubmit(onSubmit)}>Edit</Button>
          </DialogActions>
  
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isUpdatingVulnerabilityStatus}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
      </Dialog>
    );
  }
  
  export default EditVulnerabilityStatusModal;