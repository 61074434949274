export const supportedLanguages = [
    "en",
    "it" 
];

export const languages = {
    en: {
        displayName: "English",
        image: "https://flagcdn.com/w20/gb.png",
        countryCode: "gb",
        applicationLanguageId: 1,
    },
    it: {
        displayName: "Italian",
        image: "https://flagcdn.com/w20/it.png",
        countryCode: "it",
        applicationLanguageId: 2,
    },
};

export function getFlag(size, countryCode){
    return `https://flagcdn.com/w${size}/${countryCode}.png`;
}