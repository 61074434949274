import React from "react";
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Tab, Tabs, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import HubIcon from '@mui/icons-material/Hub';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';

const GraphicSettings = ({ handleLayoutChange, currentLayout }) => {
  return (
    <CollapseContainer>

        <ScrollableCollapse key={1} className="with-shadow">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <AccordionTitle>
              <SettingsIcon style={{marginRight: 10}}/>
              <Typography>Settings</Typography>
            </AccordionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <FormLabel id="layout-radio-buttons">Layout</FormLabel>
              <RadioGroup
                aria-labelledby="layout-radio-buttons"
                name="layout"
                value={currentLayout}
                onChange={handleLayoutChange}
              >
                <FormControlLabel 
                  value="hierarchy" 
                  control={<Radio />} 
                  label={<Typography><DeviceHubIcon /> Hierarchy</Typography>}
                />
                <FormControlLabel 
                  value="network" 
                  control={<Radio />} 
                  label={<Typography><HubIcon /> Network</Typography>}
                />
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </ScrollableCollapse>
    </CollapseContainer>
  );
};

const CollapseContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ScrollableCollapse = styled(Accordion)`
  width: 200px;
  max-height: 570px;
  overflow-y: auto;
`;
const AccordionTitle = styled.div`
  display: flex;
  align-items: center;
`;

GraphicSettings.propTypes = {
  handleLayoutChange: PropTypes.func, 
  currentLayout: PropTypes.string
};

GraphicSettings.defaultProps = {
    currentLayout: "hierarchy"
  };

export default GraphicSettings;