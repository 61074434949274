import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  addLocationRequest,
  addLocationSuccess,
  addLocationError
} from './addLocationAction';

const defaultState = {
  isAddingLocation: false,
  addLocationError: null,
  addedLocation: false
};

export default handleActions(
  {
    [addLocationRequest](state) {
      return {
        ...state,
        isAddingLocation: true,
        addLocationError: null,
        addedLocation: false
      };
    },
    [addLocationSuccess](state, { payload }) {
      return {
        ...state,
        isAddingLocation: false,
        addLocationError: null,
        addedLocation: true
      };
    },
    [addLocationError](state, { payload }) {
      return {
        ...state,
        isAddingLocation: false,
        addLocationError: payload,
        addedLocation: false
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);