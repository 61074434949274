import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, Stack, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import MaterialAddDynamicFilterModal from './addDynamicFilterModal';
import { useFieldArray } from 'react-hook-form';

const MaterialDynamicFilters = ({ controller, schema, control, name }) => {

    const [showFieldValuesModal, setShowFieldValuesModal] = useState(false);

    const { fields, append, remove } = useFieldArray({
        control,
        name
    });

    const onCloseFieldValues = () => {
        setShowFieldValuesModal(false);
    };

    const handleClickAdd = (e) => {
        e.stopPropagation();
        setShowFieldValuesModal(true);
    }

    const handleClickDelete = (index) => {
        remove(index);
    }

    const onKeyValueAdded = (keyValue) => {
        setShowFieldValuesModal(false);
        append(keyValue);
    }

    return(
        <>
            <Accordion style={{marginTop: 20}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                <Typography>Advanced Filters({fields.length}) <Button style={{marginLeft:10}} variant='outlined' endIcon={<AddIcon />} onClick={handleClickAdd}>Add</Button></Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack direction="row" spacing={1}>
                        {fields.map((item, index) => (
                            <Chip style={{margin: 3}} label={`${item.key} = ${item.value}`} onDelete={() => handleClickDelete(index)} />
                        ))}
                    </Stack>
                </AccordionDetails>
            </Accordion>

            <MaterialAddDynamicFilterModal
                controller={controller}
                open={showFieldValuesModal}
                onHideModal={onCloseFieldValues}
                schema={schema}
                onKeyValueAdded={onKeyValueAdded}
            />
        </>
    )
}

export default MaterialDynamicFilters;