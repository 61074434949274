import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getDomainsRequest,
  getDomainsSuccess,
  getDomainsError,
  deleteDomainRequest,
  deleteDomainSuccess,
  deleteDomainError
} from './domainsAction';

const defaultState = {
  domains: [],
  isLoadingDomains: true,
  getDomainsError: null,
  isDeletingDomain: false,
  deleteDomainError: null,
  deletedDomain: false
};

export default handleActions(
  {
    [getDomainsRequest](state) {
      return {
        ...state,
        domains: [],
        isLoadingDomains: true,
        getDomainsError: null,
      };
    },
    [getDomainsSuccess](state, { payload }) {
      return {
        ...state,
        domains: payload,
        isLoadingDomains: false,
        getDomainsError: null,
      };
    },
    [getDomainsError](state, { payload }) {
      return {
        ...state,
        domains: [],
        isLoadingDomains: false,
        getDomainsError: payload,
      };
    },
    [deleteDomainRequest](state) {
      return {
        ...state,
        isDeletingDomain: true,
        deletedDomain: false,
        deleteDomainError: null,
      };
    },
    [deleteDomainSuccess](state, { payload }) {
      return {
        ...state,
        isDeletingDomain: false,
        deletedDomain: true,
        deleteDomainError: null,
      };
    },
    [deleteDomainError](state, { payload }) {
      return {
        ...state,
        isDeletingDomain: false,
        deletedDomain: false,
        deleteDomainError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);