import { Divider, Typography } from "@mui/material";
import { Col } from "react-bootstrap";
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { FormSelectField } from '@/shared/components/form/material-controls/SelectField';
import { useEffect } from "react";

const StakeholderCountry = ({ control, stakeholderInfo, countries, setValue, watch}) => {
    
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if(name === "dictionaryCountryId"){
                var countryCode = "";
                var regionCode = "";
                var subRegionCode = "";
                var intermediateRegionCode = "";

                if(value.dictionaryCountryId){
                    const country = countries.find(c => c.dictionaryCountryId === value.dictionaryCountryId);
                    countryCode = country.countryCode;
                    regionCode = country.regionCode;
                    subRegionCode = country.subRegionCode;
                    intermediateRegionCode = country.intermediateRegionCode;
                }

                setValue("countryCode", countryCode);
                setValue("regionCode", regionCode);
                setValue("subRegionCode", subRegionCode);
                setValue("intermediateRegionCode", intermediateRegionCode);
            }
        });

        return () => subscription.unsubscribe();
    }, [watch, countries])

    return(
        <>
            <Col md={12}>
                <Divider component='div' sx={{marginTop: '20px', marginBottom: '20px'}}/>
                <Typography>Country information</Typography>
            </Col>

            <Col md={12}>
                <FormSelectField
                    name="dictionaryCountryId"
                    label="Country"
                    control={control}
                    defaultValue={stakeholderInfo?.dictionaryCountryId}
                    style={{marginTop: 10}}
                    options={countries}
                    keyValue={{ id:"dictionaryCountryId", label:"name" }}
                    rules={{ required: 'This field is required' }}
                />
            </Col>
            <Col md={3}>
                <FormTextField
                    name="countryCode"
                    label="Country Code"
                    control={control}
                    defaultValue={stakeholderInfo?.countryCode}
                    disabled={true}
                />
            </Col>
            <Col md={3}>
                <FormTextField
                    name="regionCode"
                    label="Region Code"
                    control={control}
                    defaultValue={stakeholderInfo?.regionCode}
                    disabled={true}
                />
            </Col>
            <Col md={3}>
                <FormTextField
                    name="subRegionCode"
                    label="Sub Region Code"
                    control={control}
                    defaultValue={stakeholderInfo?.subRegionCode}
                    disabled={true}
                />
            </Col>
            <Col md={3}>
                <FormTextField
                    name="intermediateRegionCode"
                    label="Intermediate Region Code"
                    control={control}
                    defaultValue={stakeholderInfo?.intermediateRegionCode}
                    disabled={true}
                />
            </Col>
        </>
    );
};

export default StakeholderCountry;