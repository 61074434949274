import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  updateLogoRequest,
  updateLogoSuccess,
  updateLogoError
} from './logoChangeAction';

const defaultState = {
  isUpdatingLogo: false,
  updateLogoError: null,
  updatedLogo: false
};

export default handleActions(
  {
    [updateLogoRequest](state) {
        return {
            ...state,
            isUpdatingLogo: true,
            updateLogoError: null,
            updatedLogo: false
        };
    },
    [updateLogoSuccess](state, { payload }) {
        return {
            ...state,
            isUpdatingLogo: false,
            updateLogoError: null,
            updatedLogo: true
        };
    },
    [updateLogoError](state, { payload }) {
        return {
            ...state,
            isUpdatingLogo: false,
            updateLogoError: payload,
            updatedLogo: false
        };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);