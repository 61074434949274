import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getRolesAndUsersRequest,
  getRolesAndUsersSuccess,
  getRolesAndUsersError
} from './activityUserRolesAction';

const defaultState = {
  rolesAndUsers: null,
  isLoadingRolesAndUsers: true,
  getRolesAndUsersError: null
};

export default handleActions(
  {
    [getRolesAndUsersRequest](state) {
      return {
        ...state,
        isLoadingRolesAndUsers: true,
        getRolesAndUsersError: null,
      };
    },
    [getRolesAndUsersSuccess](state, { payload }) {
      return {
        ...state,
        rolesAndUsers: payload,
        isLoadingRolesAndUsers: false,
        getRolesAndUsersError: null,
      };
    },
    [getRolesAndUsersError](state, { payload }) {
      return {
        ...state,
        isLoadingRolesAndUsers: false,
        getRolesAndUsersError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);