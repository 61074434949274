import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getRolesRequest,
  getRolesSuccess,
  getRolesError,
  addRemediationRequest,
  addRemediationSuccess,
  addRemediationError
} from './addRemediationAction';

const defaultState = {
  roles: [],
  isLoadingRoles: true,
  getRolesError: null,
  isAddingRemediation: false,
  addRemediationError: null,
  addedRemediation: false
};

export default handleActions(
  {
    [getRolesRequest](state) {
      return {
        ...state,
        isLoadingRoles: true,
        getRolesError: null,
      };
    },
    [getRolesSuccess](state, { payload }) {
      return {
        ...state,
        roles: payload,
        isLoadingRoles: false,
        getRolesError: null,
      };
    },
    [getRolesError](state, { payload }) {
      return {
        ...state,
        isLoadingRoles: false,
        getRolesError: payload,
      };
    },    
    [addRemediationRequest](state) {
      return {
        ...state,
        isAddingRemediation: true,
        addRemediationError: null,
        addedRemediation: false
      };
    },
    [addRemediationSuccess](state, { payload }) {
      return {
        ...state,
        isAddingRemediation: false,
        addRemediationError: null,
        addedRemediation: true
      };
    },
    [addRemediationError](state, { payload }) {
      return {
        ...state,
        isAddingRemediation: false,
        addRemediationError: payload,
        addedRemediation: false
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);