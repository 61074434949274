import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  updateVulnerabilityScoreRequest,
  updateVulnerabilityScoreSuccess,
  updateVulnerabilityScoreError
} from './updateVulnerabilityScoreAction';

const defaultState = {
  isUpdatingVulnerabilityScore: false,
  updateVulnerabilityScoreError: null,
  updatedVulnerabilityScore: false
};

export default handleActions(
  {
    [updateVulnerabilityScoreRequest](state) {
      return {
        ...state,
        isUpdatingVulnerabilityScore: true,
        updateVulnerabilityScoreError: null,
        updatedVulnerabilityScore: false
      };
    },
    [updateVulnerabilityScoreSuccess](state, { payload }) {
      return {
        ...state,
        isUpdatingVulnerabilityScore: false,
        updateVulnerabilityScoreError: null,
        updatedVulnerabilityScore: true
      };
    },
    [updateVulnerabilityScoreError](state, { payload }) {
      return {
        ...state,
        isUpdatingVulnerabilityScore: false,
        updateVulnerabilityScoreError: payload,
        updatedVulnerabilityScore: false
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);