import { Backdrop, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { cleanup, updateClassification } from "@/reducers/classification/updateClassification/updateClassificationAction";
import { FormNumberField } from "@/shared/components/form/material-controls/NumberField";
import { FormColorField } from "@/shared/components/form/material-controls/ColorField";

const EditClassificationModal = ({ open, onHideModal, onClassificationUpdated, classification }) => {
    const dispatch = useDispatch();
  
    const { 
      isUpdatingClassification, updateClassificationError, updatedClassification
    } = useSelector(({ updateClassification }) => updateClassification);

    useEffect(() => {
      return () => {
        dispatch(cleanup());
      }
    }, [dispatch]);
  
    const { handleSubmit, reset, control, setValue, getValues } = useForm();
  
    const handleClose = (event, reason) => {
      if (reason && reason === "backdropClick") 
          return;

      reset();
      onHideModal();
    }
  
    useEffect(() => {
      if(updateClassificationError){
        enqueueSnackbar(updateClassificationError, {variant: "error"});
      }
    }, [updateClassificationError]);
  
    const onSubmit = (data) => {
      data.id = classification.id;
      dispatch(updateClassification(data));
    }
  
    useEffect(() => {
      if(updatedClassification){
        enqueueSnackbar("Classification updated successfully", {variant: "success"});
        handleClose();
        onClassificationUpdated();
      }
    }, [updatedClassification]);
  
    return (
      <Dialog
          fullWidth={true}
          maxWidth='lg'
          scroll='paper'
          open={open}
          onClose={handleClose}
      >
          <DialogTitle>Edit Classification</DialogTitle>
          <DialogContent>
                <Row>
                <Col md={12}>
                    <FormTextField
                        name='name'
                        label='Name'
                        control={control}
                        rules={{ required: 'This field is required' }}
                        defaultValue={classification.name}
                    />
                  </Col>
                  <Col md={12}>
                    <FormTextField 
                        name='description'
                        label='Description'
                        control={control}
                        multiline
                        rows={4}
                        defaultValue={classification.description}
                    />
                  </Col>
                  <Col md={12}>
                    <FormNumberField
                        name='value'
                        label='Value'
                        control={control}
                        rules={{ required: 'This field is required' }}
                        defaultValue={classification.value}
                    />
                  </Col>
                  <Col md={12}>
                    <FormColorField 
                        name="color" 
                        label="Color" 
                        control={control} 
                        rules={{ required: 'This field is required' }}
                        defaultValue={classification.color}
                    />
                  </Col>
                </Row>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant='contained' onClick={handleSubmit(onSubmit)}>Edit</Button>
          </DialogActions>
  
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isUpdatingClassification}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
      </Dialog>
    );
  }
  
  export default EditClassificationModal;