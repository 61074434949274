import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getRemediationsRequest,
  getRemediationsSuccess,
  getRemediationsError
} from './viewAction';

const defaultState = {
  remediations: [],
  isLoadingRemediations: true,
  getRemediationsError: null
};

export default handleActions(
  {
    [getRemediationsRequest](state) {
      return {
        ...state,
        isLoadingRemediations: true,
        getRemediationsError: null,
      };
    },
    [getRemediationsSuccess](state, { payload }) {
      return {
        ...state,
        remediations: payload,
        isLoadingRemediations: false,
        getRemediationsError: null,
      };
    },
    [getRemediationsError](state, { payload }) {
      return {
        ...state,
        isLoadingRemediations: false,
        getRemediationsError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);