import { createAction } from 'redux-actions';
import { instance } from "../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_CLASSIFICATIONS');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getClassificationsRequest = createAction('CLASSIFICATIONS_GET_ALL_REQUEST');
export const getClassificationsSuccess = createAction('CLASSIFICATIONS_GET_ALL_SUCCESS');
export const getClassificationsError = createAction('CLASSIFICATIONS_GET_ALL_ERROR');

export const getClassifications = () => async (dispatch) => {
  try {
    dispatch(getClassificationsRequest());
    const resp = await instance.get(`/v1/Classification/GetAll`);
    
    dispatch(getClassificationsSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getClassificationsError(e.response.data.description));
    else
      dispatch(getClassificationsError(e.message));
  }
};

export const deleteClassificationRequest = createAction('CLASSIFICATIONS_DELETE_REQUEST');
export const deleteClassificationSuccess = createAction('CLASSIFICATIONS_DELETE_SUCCESS');
export const deleteClassificationError = createAction('CLASSIFICATIONS_DELETE_ERROR');

export const deleteClassification = (id) => async (dispatch) => {
  try {
    dispatch(deleteClassificationRequest());
    const resp = await instance.delete(`/v1/Classification/DeleteLogic/${id}`);

    dispatch(deleteClassificationSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(deleteClassificationError(e.response.data.description));
    else
      dispatch(deleteClassificationError(e.message));
  }
};