import { Accordion, AccordionDetails, AccordionTitle, AccordionSummary, Icon, Typography, Box } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Col, Row } from "react-bootstrap";
import ReportGradient from "./reportGradient";
import dayjs from "dayjs";
import PerformanceLineChart from "./performanceLineChart";

const ReportCalculationHistory = ({
    calculations,
    gradient
}) => {
    return(
        calculations.map(c => {
            const reversed = c.toReversed();
            return(
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Icon style={{marginRight: 10}}>{reversed[0].icon}</Icon>
                            <Typography>{reversed[0].surveyName}</Typography>
                            
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Row>
                            <Col md={6}>
                                {reversed.map((singleCalculation, index) =>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        mb: 3
                                    }}>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: '100%',
                                            justifyContent: 'center',
                                            flexWrap: 'wrap',
                                            mr: 3
                                        }}>
                                            <Box sx={{
                                                display: 'flex'
                                            }}>
                                                <Typography>{dayjs(singleCalculation.answerDate).format("DD/MM/YYYY HH:mm:ss")}</Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{
                                            width: '200px',
                                            mr: 6
                                        }}>
                                            <ReportGradient 
                                                calculation={singleCalculation}
                                                gradient={gradient}
                                            />
                                        </Box>
                                        { index < reversed.length - 1 && 
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}>
                                                { singleCalculation.value - reversed[index + 1].value < 0 ? 
                                                <>
                                                    <img style={{transform: "rotate(90deg)"}} height={25} src="/img/green-arrow.png" alt="arrow"/>
                                                    <Typography sx={{
                                                        color: '#00B050',
                                                        fontSize: '24px',
                                                        ml: 1,
                                                        fontWeight: 'bold'
                                                    }}>{ -(singleCalculation.value - reversed[index + 1].value) }</Typography>
                                                </>
                                                :
                                                singleCalculation.value - reversed[index + 1].value > 0 ?
                                                <>
                                                    <img style={{transform: "rotate(270deg)"}} height={25} src="/img/red-arrow.png" alt="arrow"/>
                                                    <Typography sx={{
                                                        color: '#FF0000',
                                                        fontSize: '24px',
                                                        ml: 1,
                                                        fontWeight: 'bold'
                                                    }}>{ singleCalculation.value - reversed[index + 1].value }</Typography>
                                                </>
                                                :
                                                <>
                                                    <Typography sx={{
                                                        color: singleCalculation.color,
                                                        fontSize: '40px',
                                                        fontWeight: 'bold'
                                                    }}>
                                                        =
                                                    </Typography>
                                                    <Typography sx={{
                                                        color: singleCalculation.color,
                                                        fontSize: '24px',
                                                        ml: 1,
                                                        fontWeight: 'bold'
                                                    }}>{ singleCalculation.value - reversed[index + 1].value }</Typography>
                                                </>
                                                }
                                            </Box>
                                        }
                                    </Box>
                                )}
                            </Col>
                            <Col md={6}>
                                { reversed.length > 1 &&
                                    <PerformanceLineChart 
                                        calculations={c}
                                    />
                                }
                            </Col>
                        </Row>
                        
                    </AccordionDetails>
                </Accordion>
        )}
    ));
}

export default ReportCalculationHistory;