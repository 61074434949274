import { Box, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const HelpPanelHeader = ({
    onClickClose
}) => {
    return(
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 1
        }}>
            <Box sx={{
                marginLeft: 'auto'
            }}>
                <IconButton onClick={onClickClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
        </Box>
    );
};

export default HelpPanelHeader;