import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_CHECK_LIST');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getSchemaRequest = createAction('CHECK_LIST_GET_SCHEMA_REQUEST');
export const getSchemaSuccess = createAction('CHECK_LIST_GET_SCHEMA_SUCCESS');
export const getSchemaError = createAction('CHECK_LIST_GET_SCHEMA_ERROR');

export const getSchema = () => async (dispatch) => {
  try {
    dispatch(getSchemaRequest());
    const resp = await instance.get(`/v1/AssetVulnerability/GetSchema`);
    
    dispatch(getSchemaSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getSchemaError(e.response.data.description));
    else
      dispatch(getSchemaError(e.message));
  }
};

export const getVulnerabilityStatusesRequest = createAction('CHECK_LIST_GET_VULNERABILITY_STATUS_REQUEST');
export const getVulnerabilityStatusesSuccess = createAction('CHECK_LIST_GET_VULNERABILITY_STATUS_SUCCESS');
export const getVulnerabilityStatusesError = createAction('CHECK_LIST_GET_VULNERABILITY_STATUS_ERROR');

export const getVulnerabilityStatuses = () => async (dispatch) => {
  try {
    dispatch(getVulnerabilityStatusesRequest());
    const resp = await instance.get(`/v1/VulnerabilityStatus/GetAll`);
    
    dispatch(getVulnerabilityStatusesSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getVulnerabilityStatusesError(e.response.data.description));
    else
      dispatch(getVulnerabilityStatusesError(e.message));
  }
};

export const getDomainsRequest = createAction('CHECK_LIST_GET_DOMAINS_REQUEST');
export const getDomainsSuccess = createAction('CHECK_LIST_GET_DOMAINS_SUCCESS');
export const getDomainsError = createAction('CHECK_LIST_GET_DOMAINS_ERROR');

export const getDomains = () => async (dispatch) => {
  try {
    dispatch(getDomainsRequest());
    const resp = await instance.get(`/v1/Domain/GetAll`);
    
    dispatch(getDomainsSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getDomainsError(e.response.data.description));
    else
      dispatch(getDomainsError(e.message));
  }
};

export const getRatingScoresRequest = createAction('CHECK_LIST_GET_RATING_SCORES_REQUEST');
export const getRatingScoresSuccess = createAction('CHECK_LIST_GET_RATING_SCORES_SUCCESS');
export const getRatingScoresError = createAction('CHECK_LIST_GET_RATING_SCORES_ERROR');

export const getRatingScores = () => async (dispatch) => {
  try {
    dispatch(getRatingScoresRequest());
    const resp = await instance.get(`/v1/RatingScore/GetAll`);
    
    dispatch(getRatingScoresSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getRatingScoresError(e.response.data.description));
    else
      dispatch(getRatingScoresError(e.message));
  }
};

export const getCheckListRequest = createAction('CHECK_LIST_GET_REQUEST');
export const getCheckListSuccess = createAction('CHECK_LIST_GET_SUCCESS');
export const getCheckListError = createAction('CHECK_LIST_GET_ERROR');

export const getCheckList = (data) => async (dispatch) => {
  try {
    dispatch(getCheckListRequest());
    const resp = await instance.get(`/v1/AssetVulnerability/GetListForChecklist`, data);

    dispatch(getCheckListSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getCheckListError(e.response.data.description));
    else
      dispatch(getCheckListError(e.message));
  }
};

export const saveCheckListRequest = createAction('SAVE_CHECK_LIST_REQUEST');
export const saveCheckListSuccess = createAction('SAVE_CHECK_LIST_SUCCESS');
export const saveCheckListError = createAction('SAVE_CHECK_LIST_ERROR');

export const saveCheckList = (data) => async (dispatch) => {
  try {
    dispatch(saveCheckListRequest());
    const resp = await instance.put(`/v1/AssetVulnerability/UpdateRemediationChk`, data);

    dispatch(saveCheckListSuccess(resp.data));
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(saveCheckListError(e.response.data.description));
    else
      dispatch(saveCheckListError(e.message));
  }
};

export const resetTableDataRequest = createAction('CHECK_LIST_RESET_TABLE_DATA_REQUEST');

export const resetTableData = (data) => async (dispatch) => {
    dispatch(resetTableDataRequest(data));
}