import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getSurveyRequest,
  getSurveySuccess,
  getSurveyError
} from './surveyTestAction';

const defaultState = {
  surveyJson: null,
  isLoadingSurveyJson: true,
  getSurveyJsonError: null
};

export default handleActions(
  {
    [getSurveyRequest](state) {
      return {
        ...state,
        surveyJson: null,
        isLoadingSurveyJson: true,
        getSurveyJsonError: null,
      };
    },
    [getSurveySuccess](state, { payload }) {
      return {
        ...state,
        surveyJson: payload,
        isLoadingSurveyJson: false,
        getSurveyJsonError: null,
      };
    },
    [getSurveyError](state, { payload }) {
      return {
        ...state,
        surveyJson: null,
        isLoadingSurveyJson: false,
        getSurveyJsonError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);