import ReactECharts from 'echarts-for-react';
import { useSelector } from 'react-redux';


const PerformanceBars = ({performance}) => {

    const {theme} = useSelector(({changeTheme}) => changeTheme);

    const options = {
        tooltip: {
            trigger: 'item'
        },
        title: {
          text: "Vulnerability by Contextual Score",
          left: 'center'
        },
        yAxis: {
            type: 'category',
            data: [
                "None", 
                "Very Low",
                "Low",
                "Medium",
                "High",
                "Critical"
            ]
          },
          xAxis: {
            type: 'value'
          },
          series: [
            {
                data: [
                    {
                        value: performance.remediationContextualScoreNone,
                        itemStyle: {
                            color: '#ddd'
                        }
                    },
                    {
                        value: performance.remediationContextualScoreVeryLow,
                        itemStyle: {
                            color: '#53aa33'
                        }
                    },
                    {
                        value: performance.remediationContextualScoreLow,
                        itemStyle: {
                            color: '#ffcb0d'
                        }
                    },
                    {
                        value: performance.remediationContextualScoreMedium,
                        itemStyle: {
                            color: '#f9a009'
                        }
                    },
                    {
                        value: performance.remediationContextualScoreHigh,
                        itemStyle: {
                            color: '#df3d03'
                        }
                    },
                    {
                        value: performance.remediationContextualScoreCritical,
                        itemStyle: {
                            color: '#cc0500'
                        }
                    }
                ],
              type: 'bar',
              label: {
                show: true,
                position: 'inside'
              },
            }
          ]
    };
    
    return(
        <ReactECharts
            theme={theme}
            style={{height: 400, width: '100%'}}
            notMerge={true}
            option={options}
        />
    );
};

export default PerformanceBars;