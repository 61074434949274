import { Backdrop, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { FormSelectField } from '@/shared/components/form/material-controls/SelectField';
import { FormCheckboxField } from '@/shared/components/form/material-controls/CheckboxField';
import { cleanup, updateService } from "@/reducers/services/updateService/updateServiceAction";
import ServiceConnectorsTable from "./serviceConnectorsTable";
import { useRef } from "react";
import { FormSliderField } from "@/shared/components/form/material-controls/SliderField";

const EditServiceModal = ({ connectors, cronIntervals, open, onHideModal, onServiceUpdated, service }) => {
    const dispatch = useDispatch();
  
    const { 
      isUpdatingService, updateServiceError, updatedService
    } = useSelector(({ updateService }) => updateService);

    const tableRef = useRef();

    const [disableIntervals, setDisableIntervals] = useState(!service.isInterval);

    useEffect(() => {
      return () => {
        dispatch(cleanup());
      }
    }, [dispatch]);
  
    const { handleSubmit, reset, control, setValue, getValues } = useForm();
  
    const handleClose = (event, reason) => {
      if (reason && reason === "backdropClick") 
          return;

      reset();
      onHideModal();
    }
  
    useEffect(() => {
      if(updateServiceError){
        enqueueSnackbar(updateServiceError, {variant: "error"});
      }
    }, [updateServiceError]);
  
    const onSubmit = (data) => {
      data.isSystem = service.isSystem;
      data.id = service.id;

      if(!data.isSystem){
        data.serviceConnector = tableRef.current.getData();

        data.serviceConnector.forEach((connector, index) => {
          if(data.serviceConnector.some((c, i) => c.assemblyCollectionId === connector.assemblyCollectionId && i !== index)){
            enqueueSnackbar("Can't have more than one connector with the same function.", {variant: "error"});
            return;
          }
          else if(data.serviceConnector.some((c, i) => c.order === connector.order && i !== index)){
            enqueueSnackbar("Can't have more than one connector with the same order.", {variant: "error"});
            return;
          }
        });
      }

      dispatch(updateService(data));
    }
  
    useEffect(() => {
      if(updatedService){
        enqueueSnackbar("Service updated successfully", {variant: "success"});
        handleClose();
        onServiceUpdated();
      }
    }, [updatedService]);

    const onChangeIsInterval = (e, value) => {
      setValue("isInterval", value);
      setDisableIntervals(!value);
    }
  
    return (
      <Dialog
          fullWidth={true}
          maxWidth='lg'
          scroll='paper'
          open={open}
          onClose={handleClose}
      >
          <DialogTitle>Edit Service</DialogTitle>
          <DialogContent>
                <Row>
                  <Col md={12}>
                    <FormTextField
                        name='name'
                        label='Name'
                        control={control}
                        rules={{ required: 'This field is required' }}
                        defaultValue={service.name}
                        disabled={service.isSystem}
                    />
                  </Col>
                  <Col md={12}>
                    <FormTextField 
                        style={{marginBlock: 20}}
                        name='cron'
                        label='Cron'
                        control={control}
                        defaultValue={service.cron}
                    />
                  </Col>
                  { !service.isSystem &&
                      <Col md={12}>
                        <FormCheckboxField 
                            name='scheduled'
                            label='Scheduled'
                            control={control}
                            defaultValue={service.scheduled}
                        />
                      </Col>
                  }                  
                  <Col md={12}>
                    <FormCheckboxField 
                        name='enabled'
                        label='Enabled'
                        control={control}
                        defaultValue={service.enabled}
                    />
                  </Col>
                  { !service.isSystem &&
                    <Col md={12}>
                      <FormCheckboxField 
                          name='isInterval'
                          label='Is Interval'
                          control={control}
                          onChange={onChangeIsInterval}
                          defaultValue={service.isInterval}
                      />
                    </Col>
                  }
                  { (!service.isSystem || service.isInterval) &&
                  <>
                    <Col md={6}>
                      <FormSliderField 
                          name='interval'
                          label='Interval'
                          control={control}
                          min={1}
                          max={360}
                          disabled={disableIntervals}
                          defaultValue={service.interval}
                      />
                    </Col>
                    <Col md={6}>
                      <FormSelectField 
                          name='cronIntervalId'
                          label='Cron Interval'
                          control={control}
                          options={cronIntervals}
                          keyValue={{ id:"key", label:"name" }}
                          disabled={disableIntervals}
                          defaultValue={service.cronIntervalId}
                      />
                    </Col>
                  </>
                  }
                  { !service.isSystem &&
                    <Col md={12}>
                      <ServiceConnectorsTable 
                        ref={tableRef}
                        assemblyCollections={service.serviceConnector}
                        connectors={connectors}
                        isEdit={true}
                        cronIntervals={cronIntervals}
                      />
                    </Col>
                  }
                </Row>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant='contained' onClick={handleSubmit(onSubmit)}>Edit</Button>
          </DialogActions>
  
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isUpdatingService}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
      </Dialog>
    );
  }
  
  export default EditServiceModal;