import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GridToolbar, DataGrid, gridClasses, useGridApiRef, GridActionsCellItem   } from '@mui/x-data-grid';
import { Col, Container, Row } from 'react-bootstrap';
import { cleanup, removeItems, resetTableData, getContextualScores, getClassifications, getRemediation, getSchema, getCheckList,  getDomains, getRatingScores, getVulnerabilityStatuses, saveCheckList } from "@/reducers/activity/assetVulnerabilityCheckList/assetVulnerabilityCheckListAction";
import { useForm } from "react-hook-form";
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import { FormMultiSelectField } from '@/shared/components/form/material-controls/MultiSelectField';
import { FormSelectField } from '@/shared/components/form/material-controls/SelectField';
import { FormDateField } from '@/shared/components/form/material-controls/DateField';
import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Box, Button, Chip, CircularProgress, Divider, Icon, Typography, darken, lighten, styled } from '@mui/material';
import MaterialDynamicFilters from '@/shared/components/dynamic-filters/dynamicFilters';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandableCell from '@/shared/components/datagrid/ExpandableCell';
import SearchIcon from '@mui/icons-material/Search';
import Edit from '@mui/icons-material/Edit';
import { ColoredSelectCell } from '@/shared/components/datagrid/ColoredSelectCell';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { grey } from '@mui/material/colors';
import ConfirmationDialog from '@/shared/components/ConfirmationDialog';
import { enqueueSnackbar } from 'notistack';
import { useOutletContext } from 'react-router-dom';
import MassiveUpdatePanel from './components/massiveUpdatePanel';
import { useRef } from 'react';
import ClassificationChip from '../../../AssetManagement/AssetInventory/components/classificationChip';
import { FormRangeSliderField } from '../../../../shared/components/form/material-controls/RangeSliderField';
import VulnerabilityInfoModal from '../../../VulnerabilityManagement/Vulnerabilities/components/vulnerabilityInfoModal';
import TransparentTextChip from '../../../../shared/components/TransparentTextChip';
import ScanModal from './components/scanModal';
import MaterialAssetInfoModal from '../../../AssetManagement/AssetInventory/components/materialAssetInfoModal';

const AssetVulnerabilityCheckList = () => {
  const { 
    activity, 
    workflowActivityStepCompletedId, 
    isEditor,
    handleRefreshProgress 
  } = useOutletContext();

  const dispatch = useDispatch();
  const { 
    classifications, isLoadingClassification, getClassificationsError,
    remediation, isLoadingRemediation, getRemediationError,
    schema, isLoadingSchema, getSchemaError,
    domains, isLoadingDomains, getDomainsError,
    ratingScores, isLoadingRatingScores, getRatingScoresError,
    contextualScores, isLoadingContextualScores, getContextualScoresError,
    vulnerabilityStatuses, isLoadingVulnerabilityStatuses, getVulnerabilityStatusesError,
    checkList, isLoadingCheckList, getCheckListError ,
    isSavingCheckList, savedCheckList, saveCheckListError,
    isRemovingItems, removedItems, removeItemsError
  } = useSelector(({ assetVulnerabilityCheckList }) => assetVulnerabilityCheckList);

  const [selectedRows, setSelectedRows] = useState([]);
  const [tableCheckList, setTableCheckList] = useState([]);
  const [pendingChanges, setPendingChanges] = useState([]);
  const [openConfirmDiscard, setOpenConfirmDiscard] = useState(false);
  const [openConfirmRemove, setOpenConfirmRemove] = useState(false);

  const [showVulnerabilityInfoModal, setShowVulnerabilityInfoModal] = useState(false);
  const [selectedVulnerability, setSelectedVulnerability] = useState(null);
  
  const [showAssetInfoModal, setShowAssetInfoModal] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState(null);

  const [showScanModal, setShowScanModal] = useState(false);
  const [selectedVulnerabilityScan, setSelectedVulnerabilityScan] = useState(null);

  const apiRef = useGridApiRef();

  const searchButton = useRef(null);

  useEffect(() => {
    dispatch(getSchema());
    dispatch(getClassifications());
    dispatch(getDomains());
    dispatch(getRatingScores());
    dispatch(getContextualScores());
    dispatch(getVulnerabilityStatuses());
    dispatch(getRemediation(activity.workflowActivityId));

    return () => {
      dispatch(cleanup());
    }
  }, [dispatch]);

  useEffect(() => {
    if(remediation){
      dispatch(getCheckList({ remediationId: remediation.id, dictionary: {} }));
    }
  }, [remediation]);

  useEffect(() => {
    if(removeItemsError){
      enqueueSnackbar(removeItemsError, {variant: "error"});
    }
  }, [removeItemsError]);

  useEffect(() => {
    if(getRemediationError){
      enqueueSnackbar(getRemediationError, {variant: "error"});
    }
  }, [getRemediationError]);

  useEffect(() => {
    if(getContextualScoresError){
      enqueueSnackbar(getContextualScoresError, {variant: "error"});
    }
  }, [getContextualScoresError]);

  useEffect(() => {
    if(getClassificationsError){
      enqueueSnackbar(getClassificationsError, {variant: "error"});
    }
  }, [getClassificationsError]);
  
  useEffect(() => {
    if(getSchemaError){
      enqueueSnackbar(getSchemaError, {variant: "error"});
    }
  }, [getSchemaError]);

  useEffect(() => {
    if(getDomainsError){
      enqueueSnackbar(getDomainsError, {variant: "error"});
    }
  }, [getDomainsError]);

  useEffect(() => {
    if(getRatingScoresError){
      enqueueSnackbar(getRatingScoresError, {variant: "error"});
    }
  }, [getRatingScoresError]);

  useEffect(() => {
    if(getVulnerabilityStatusesError){
      enqueueSnackbar(getVulnerabilityStatusesError, {variant: "error"});
    }
  }, [getVulnerabilityStatusesError]);

  useEffect(() => {
    if(getCheckListError){
      enqueueSnackbar(getCheckListError, {variant: "error"});
    }
  }, [getCheckListError]);

  useEffect(() => {
    if(saveCheckListError){
      enqueueSnackbar(saveCheckListError, {variant: "error"});
    }
  }, [saveCheckListError]);

  useEffect(() => {
    if(removedItems){
      enqueueSnackbar("Vulnerabilities removed successfully", {variant: "success"});
      setPendingChanges([]);
      resetTableCheckList();
      searchButton.current.click();
    }
  }, [removedItems]);

  useEffect(() => {
    if(checkList){
      resetTableCheckList();
    }
  }, [checkList]);

  const resetTableCheckList = () => {
    var mappedData = JSON.parse(JSON.stringify(checkList));
      mappedData.forEach(m => {
      m.vulnerabilityJson = JSON.stringify({ vulnerabilityCVE: m.vulnerabilityCVE, vulnerabilityName: m.vulnerabilityName, cwe: m.cwe });
      m.assetJson = JSON.stringify({ assetName: m.assetName, ip: m.ip, confidentiality: m.confidentiality, integrity: m.integrity, availability: m.availability });
      m.scanDetailsJson = JSON.stringify(m.scanDetails);
      m.vulnerabilityStatusName = vulnerabilityStatuses.find(s => s.id === m.vulnerabilityStatusId).nameTraslated;
    });
    setTableCheckList(mappedData);
  }

  const onChangeStatusCell = (newStatus, cellData) => {
    var clon = JSON.parse(JSON.stringify(pendingChanges));
    
    if(clon.some(c => c.rowId === cellData.id)){
      var row = clon.find(c => c.rowId === cellData.id);
      row.vulnerabilityStatusName = newStatus.nameTraslated;
      row.vulnerabilityStatusNameChanged = true;
    }
    else{
      clon.push({
        rowId: cellData.id, 
        vulnerabilityStatusName: newStatus.nameTraslated, 
        vulnerabilityStatusNameChanged: true,
        scheduledDate: cellData.scheduledDate
      });
    }
    setPendingChanges(clon);

    apiRef.current.setEditCellValue({
      id: cellData.id,
      field: "vulnerabilityStatusName",
      value: newStatus.nameTraslated,
    });
    apiRef.current.stopCellEditMode({ 
      id: cellData.id, 
      field: "vulnerabilityStatusName"
    });
  };

  const onChangeDateCell = (newScheduledDate, cellData) => {
    var clon = JSON.parse(JSON.stringify(pendingChanges));

    if(clon.some(c => c.rowId === cellData.id)){
      var row = clon.find(c => c.rowId === cellData.id);
      row.scheduledDate = newScheduledDate;
      row.scheduledDateChanged = true;
    }
    else{
      clon.push({
        rowId: cellData.id, 
        scheduledDate: newScheduledDate, 
        scheduledDateChanged: true,
        vulnerabilityStatusName: cellData.row.vulnerabilityStatusName
      });
    }
    setPendingChanges(clon);

    apiRef.current.setEditCellValue({
      id: cellData.id,
      field: "scheduledDate",
      value: newScheduledDate,
    });
    apiRef.current.stopCellEditMode({ 
      id: cellData.id, 
      field: "scheduledDate"
    });
  };

  const handleClickSaveChanges = () => {
    const data = pendingChanges.map(p => { return { 
      assetVulnerabilityId: p.rowId,
      statusId: vulnerabilityStatuses.find(s => s.nameTraslated === p.vulnerabilityStatusName).id,
      scheduledDate: p.scheduledDate,
      remediationId: remediation.id,
      workflowActivityStepCompletedId
    }});

    dispatch(saveCheckList(data));
  };

  useEffect(() => {
    if(savedCheckList){
      enqueueSnackbar("Changes saved successfully", {variant: "success"});
      
      setPendingChanges([]);

      var tableData = Object.values(JSON.parse(JSON.stringify(apiRef.current.store.value.rows.dataRowIdToModelLookup)));
      tableData.forEach(d => d.vulnerabilityStatusId = vulnerabilityStatuses.find(s => s.nameTraslated === d.vulnerabilityStatusName).id);
      dispatch(resetTableData(tableData));
      
      handleRefreshProgress();
    }
  }, [savedCheckList]);

  const handleClickDiscardChanges = () => {
    setOpenConfirmDiscard(true);
  }

  const handleConfirmDiscard = () => {
    setOpenConfirmDiscard(false);
    setPendingChanges([]);
    resetTableCheckList();
  }

  const handleOnCloseDiscard = () => {
    setOpenConfirmDiscard(false);
  };

  const handleClickDiscardRemove = () => {
    setOpenConfirmRemove(true);
  }

  const handleConfirmRemove = () => {
    setOpenConfirmRemove(false);
    const data = {
      ids: selectedRows
    }
    dispatch(removeItems(data));
  }

  const handleOnCloseRemove = () => {
    setOpenConfirmRemove(false);
  };

  const { handleSubmit, reset, control, watch, setValue, getValues } = useForm();

  const handleClickViewVulnerability = (vulnerability) => {
    setSelectedVulnerability(vulnerability);
    setShowVulnerabilityInfoModal(true);
}

const onHideVulnerabilityInfoModal = () => {
    setShowVulnerabilityInfoModal(false);
    setSelectedVulnerability(null);
};


const onClickScan = (scanDetails) => {
  setSelectedVulnerabilityScan(scanDetails);
  setShowScanModal(true);
}

const onHideModal = () => {
  setShowScanModal(false);
  setSelectedVulnerabilityScan(null);
};

const onClickAssetInfo = (assetId) => {
  setSelectedAssetId(assetId);
  setShowAssetInfoModal(true);
};

const onHideAssetInfoModal = () => {
  setShowAssetInfoModal(false);
  setSelectedAssetId(null);
};

const onCellClick = (params, event, details) => {
  if(params.field === "assetJson"){
    onClickAssetInfo(params.row?.assetId);
  }
  else if(params.field === "vulnerabilityJson"){
    handleClickViewVulnerability(params.row.vulnerabilityId);
  }
  else if(params.field === "scanDetailsJson"){
    onClickScan(params.row.scanDetailsJson);
  }
}

  const onSubmit = (data) => {
    setPendingChanges([]);
    var keyValues = {};

    if(data.asset){
      keyValues["asset.Name"] = data.asset;
    }

    if(data.vulnerability){
      keyValues["vulnerability.name"] = data.vulnerability;
    }

    if(data.cve){
      keyValues["vulnerability.cve"] = data.cve;
    }

    if(data.detail){
      keyValues["detail"] = data.detail;
    }

    if(data.severity && data.severity.length){
      keyValues["vulnerability.ratingScoreId"] = data.severity;
    }

    if(data.domain && data.domain.length){
      keyValues["domainId"] = data.domain;
    }

    if(data.createData){
      keyValues["createDate"] = dayjs(data.createDate);
    }

    if(data.dynamicFilter && data.dynamicFilter.length){
      data.dynamicFilter.forEach(f => {
        keyValues[f.key] = f.value;
      });
    }
    
    const finalData = { 
      remediationId: remediation.id, 
      epssMin: data.threshold[0],
      epssMax: data.threshold[1],
      epss: data.enableEpss,
      baseScoreId: data.baseScore,
      contextualScoreId: data.contextualScore,
      exploited: data.exploited,
      dictionary: keyValues
    };

    dispatch(getCheckList(finalData));
  };

  const onMassiveUpdate = () => {
    setPendingChanges([]);
    searchButton.current.click();
    handleRefreshProgress();
  };

  const columns = [
    { 
      field: 'assetJson', 
      headerName: 'Asset', 
      minWidth: 350,
      flex: 1,
      renderHeader: (params) => (<Box sx={{ display: 'flex', alignItems: 'center' }}>Asset <Icon style={{marginLeft: 10}}>ads_click</Icon></Box>),
      renderCell: (params) => {
        if(params.value){
          const value = JSON.parse(params.value);

          const confidentiality = classifications.find(r => r.name === value.confidentiality);
          const integrity = classifications.find(r => r.name === value.integrity);
          const availability = classifications.find(r => r.name === value.availability);
          
          return(
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%'
            }}>
              <div>{value.ip}</div>
              <div>{value.assetName}</div>

              { confidentiality && integrity && availability &&
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignContent: 'center',
                width: '100%',
                marginTop: 1
              }}>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                }}>
                  <ClassificationChip sx={{marginLeft: 0}} letter="C" name="Confidentiality" classification={confidentiality} />
                </Box>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center'
                }}>
                  <ClassificationChip letter="I"  name="Integrity"  classification={integrity} />
                </Box>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center'
                }}>
                  <ClassificationChip letter="A"  name="Availability"  classification={availability} />
                </Box>
              </Box>
              }
            </Box>
          );
        }

        return <></>;
      }
    },
    {
      field: 'scanDetailsJson', 
      headerName: 'Scan Details',
      minWidth: 350,
      flex: 1,
      renderHeader: (params) => (<Box sx={{ display: 'flex', alignItems: 'center' }}>Scan Details <Icon style={{marginLeft: 10}}>ads_click</Icon></Box>),
      renderCell: (params) => {
        if(params.value){
          return <ExpandableCell sx={{ lineBreak: 'anywhere' }} value={params.value} maxLength={80} />;
        }

        return <></>;
      }
    },
    { 
        field: 'vulnerabilityJson', 
        headerName: 'Vulnerability',
        minWidth: 350,
        flex: 1,
        renderHeader: (params) => (<Box sx={{ display: 'flex', alignItems: 'center' }}>Vulnerability <Icon style={{marginLeft: 10}}>ads_click</Icon></Box>),
        renderCell: (params) => {
          if(params.value){
            const value = JSON.parse(params.value);

            return(
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
              }}>
                <div>{value.vulnerabilityCVE} {value.cwe && <span>({value.cwe})</span>}</div>
                <ExpandableCell value={value.vulnerabilityName} maxLength={80} />
              </Box>
            );
          }

          return <></>;
        }
    },
    { 
      field: 'vulnerabilityStatusName', 
      headerName: 'Vulnerability Status', 
      minWidth: 250,
      flex: 1,
      renderHeader: (params) => (<div>Vulnerability Status <Edit style={{marginLeft: 10}}/></div>),
      renderCell: (params) => {
        const color = vulnerabilityStatuses.find(r => r.nameTraslated === params.value)?.color;
        return (
          <Chip style={{backgroundColor: color, fontWeight: 'bold'}} label={params.value} />
        );
      },
      renderEditCell: !isEditor ? undefined : (params) => {
        return(
          <ColoredSelectCell
            value={vulnerabilityStatuses.find(v => v.nameTraslated === params.value)}
            options={vulnerabilityStatuses}
            onChange={(e, data) => onChangeStatusCell(data, params)}
          />
        );
      },
      editable: true
    },
    { 
      field: 'scheduledDate', 
      headerName: 'Scheduled Date', 
      type: 'date',
      minWidth: 200,
      flex: 1,
      renderHeader: (params) => (<div>Scheduled Date <Edit style={{marginLeft: 10}}/></div>),
      valueFormatter: params => params.value ? dayjs(params.value).format("DD/MM/YYYY") : "",
      renderEditCell: !isEditor ? undefined : (params) => {
        return(
          <DatePicker 
            slotProps={{
              textField: { fullWidth: true },
              actionBar: { actions: ['clear', 'today'] }
            }}
            defaultValue={params.value ? dayjs(params.value) : null}
            onChange={(data) => onChangeDateCell(data, params)}
          />
        );
      },
      editable: true
    },
    { 
      field: 'contextualScore', 
      headerName: 'Contextual Score', 
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        if(!params.value)
          return <></>;

        const contextualScore = contextualScores.find(r => r.minValue <= params.value && r.maxValue >= params.value);
        return (
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            { contextualScore && 
              <TransparentTextChip 
                  label={(params.value ? params.value : 0) + " - " + contextualScore.name} 
                  sx={{ 
                    backgroundColor: contextualScore.color 
                  }} 
                /> 
            }
          </Box>
        );
      },
    },
    { 
      field: 'baseScore', 
      headerName: 'CVSS Base Score', 
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        if(!params.value)
          return <></>;
          
        const ratingScore = ratingScores.find(r => r.minValue <= params.value && r.maxValue >= params.value);
        return (
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            { ratingScore && 
              <TransparentTextChip 
                label={(params.value ? params.value : 0) + " - " + ratingScore.name} 
                sx={{ 
                  backgroundColor: ratingScore.color 
                }} 
              /> 
            }
          </Box>
        );
      },
    },
    { 
      field: 'epssScore', 
      headerName: 'EPSS Score', 
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => {
        if (!params.value) {
          return params.value;
        }
        return params.value * 100;
      },
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return `${params.value.toFixed(2)}%`;
      }
    },
    { 
      field: 'knownExploited', 
      headerName: 'Known Exploited', 
      minWidth: 150,
      flex: 1
    },
    // { 
    //     field: 'domains', 
    //     headerName: 'Domains', 
    //     minWidth: 150,
    //     flex: 1,
    //     renderCell: (params) => <ExpandableCell {...params} maxLength={150} />,
    // },
    { 
        field: 'createDate', 
        headerName: 'Create Date', 
        type: 'date',
        minWidth: 150,
        flex: 1,
        valueFormatter: params => params.value ? dayjs(params.value).format("DD/MM/YYYY") : ""
    },
    { 
      field: 'updateDate', 
      headerName: 'Update Date', 
      type: 'date',
      minWidth: 150,
      flex: 1,
      valueFormatter: params => params.value ? dayjs(params.value).format("DD/MM/YYYY") : ""
    }
  ];

  return (
    <Container>
      <Row>
        <Col md={12}>
          <Accordion key={1}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
            <Typography>Basic Filters</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Row>
                {/* <Col md={6}>
                  <FormTextField name="asset" label="Asset" control={control} />
                </Col> */}
                <Col md={6}>
                  <FormTextField 
                    name="cve" 
                    label="CVE" 
                    control={control} 
                  />
                </Col>
                <Col md={6}>
                  <FormDateField 
                    name="createDate" 
                    label="Create Date" 
                    control={control}
                  />
                </Col>
                <Col md={6}>
                  <FormTextField 
                    name="vulnerability" 
                    label="Vulnerability Name" 
                    control={control} 
                  />
                </Col>
                {/* <Col md={6}>
                  <FormMultiSelectField 
                    name="domain" 
                    label="Domain" 
                    control={control} 
                    options={domains}
                    keyValue={{id:"id", label:"value"}}
                  />
                </Col> */}
                <Divider component="div" sx={{marginBlock: 2}} />
                <Typography>Scores</Typography>
                <Col md={6}>
                  <FormSelectField 
                    name="baseScore" 
                    label="CVSS Base Score" 
                    control={control} 
                    options={ratingScores}
                    keyValue={{id:"id", label:"name"}}
                  />
                </Col>
                <Col md={6}>
                  <FormSelectField 
                    name="contextualScore" 
                    label="Contextual Score" 
                    control={control} 
                    options={contextualScores}
                    keyValue={{id:"id", label:"name"}}
                  />
                </Col>
                <Col md={6}>
                  <FormSelectField 
                    name="exploited" 
                    label="Exploited" 
                    control={control} 
                    options={[
                      { id: false, label: "No" }, 
                      { id: true, label: "Si" }
                    ]}
                    keyValue={{id:"id", label:"label"}}
                  />
                </Col>
                <Col md={6}>
                  <FormRangeSliderField 
                    name='threshold'
                    label='EPSS Threshold'
                    canEnable={true}
                    defaultEnabled={false}
                    nameCheckbox="enableEpss"
                    labelCheckbox="Enable"
                    watch={watch}
                    control={control}
                    min={0}
                    max={100}
                    step={0.01}
                    defaultValue={[0, 100]}
                    showPercent={true}
                    showArrows={true}
                    decimals={2}
                    setValue={setValue}
                    getValues={getValues}
                  />
                </Col>
              </Row>
            </AccordionDetails>
          </Accordion>
        </Col>

        {/* <Col md={12}>
          <MaterialDynamicFilters schema={schema} control={control} name="dynamicFilter" />
        </Col> */}

        { isEditor && 
          <Col md={12}>
            <MassiveUpdatePanel 
              vulnerabilityStatuses={vulnerabilityStatuses}
              selectedRowsIds={selectedRows}
              remediationId={remediation?.id}
              stepCompletedId={workflowActivityStepCompletedId}
              onMassiveUpdate={onMassiveUpdate}
            />
          </Col>
        }
      </Row>
    
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBlock: 20}}>
        <div>
          { isEditor &&
            <>
              <Button disabled={!pendingChanges.length} color="success" size='large' variant="contained" onClick={handleClickSaveChanges}>Save ({pendingChanges.length} rows)</Button>
              <Button disabled={!pendingChanges.length} style={{marginLeft: 10}} color="error" size='large' variant="contained" onClick={handleClickDiscardChanges}>Discard Changes</Button>
              <Button disabled={!selectedRows.length} style={{marginLeft: 10}} color="warning" size='large' variant="contained" onClick={handleClickDiscardRemove}>Remove from remediation</Button>
            </> 
          }
        </div>
        <Button ref={searchButton} disabled={isLoadingCheckList} color="primary" size='medium' variant="contained" onClick={handleSubmit(onSubmit)} endIcon={<SearchIcon/>}>Search</Button>
      </div>
      
      <div style={{'height': 1000, 'paddingBottom': 20}}>
        <StyledDataGrid 
          density='compact'
          apiRef={apiRef}
          checkboxSelection 
          disableRowSelectionOnClick
          getRowHeight={() => 'auto'}
          getEstimatedRowHeight={() => 59}
          // getRowClassName={(params) => pendingChanges.some(p => p.rowId === params.id) ? "edited-row": ""}
          getCellClassName={(params) => {
            if (params.field === 'scheduledDate' || params.field === 'vulnerabilityStatusName') {
              const change = pendingChanges.find(p => p.rowId === params.id);
              if(change && change[params.field + "Changed"]){
                return "edited-cell";
              }
            }
            return "";
          }}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setSelectedRows(newRowSelectionModel);
          }}
          rowSelectionModel={selectedRows}
          loading={isLoadingCheckList} 
          rows={tableCheckList}
          columns={columns} 
          onCellClick={onCellClick}
          slots={{ toolbar: GridToolbar }} 
          slotProps={{
            toolbar: {
              showQuickFilter: true
            },
          }}
          sx={{
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
          }}
        />
      </div>

      {openConfirmDiscard && 
        <ConfirmationDialog 
          open={openConfirmDiscard}
          onConfirm={handleConfirmDiscard}
          onClose={handleOnCloseDiscard}
          title="Discard Changes"
          body={`Are you sure you want to discard your changes on ${pendingChanges.length} rows?`}
          color='error'
          confirmButtonText='Discard'
        />
      }

      {openConfirmRemove && 
        <ConfirmationDialog 
          open={openConfirmRemove}
          onConfirm={handleConfirmRemove}
          onClose={handleOnCloseRemove}
          title="Remove from remediation"
          body={`Are you sure you want to remove ${selectedRows.length} vulnerabilities from remediation? Non applied changes will be discarded.`}
          color='warning'
          confirmButtonText='Remove'
        />
      }

      { showVulnerabilityInfoModal &&
          <VulnerabilityInfoModal
              onHideModal={onHideVulnerabilityInfoModal}
              open={showVulnerabilityInfoModal}
              vulnerabilityId={selectedVulnerability}
          />
      }

      { showScanModal &&
        <ScanModal 
        onHideModal={onHideModal} 
        showModal={showScanModal}
        scanDetails={selectedVulnerabilityScan}
        classifications={classifications}
        />
      } 

      { showAssetInfoModal &&
        <MaterialAssetInfoModal 
        onHideModal={onHideAssetInfoModal} 
        showModal={showAssetInfoModal}
        assetId={selectedAssetId}
        classifications={classifications}
        />
      }

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isSavingCheckList || isLoadingRemediation || isRemovingItems}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
    </Container>
  );
}

const getBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .edited-cell': {
    backgroundColor: getBackgroundColor(
      grey[400],
      theme.palette.mode
      ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        grey[400],
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        grey[400],
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          grey[400],
          theme.palette.mode,
        ),
      },
    },
  },
}));

export default AssetVulnerabilityCheckList;