import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_USER_INFO');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getUserProfileRequest = createAction('GET_USER_PROFILE_REQUEST');
export const getUserProfileSuccess = createAction('GET_USER_PROFILE_SUCCESS');
export const getUserProfileError = createAction('GET_USER_PROFILE_ERROR');

export const getUserProfile = () => async (dispatch) => {
  try {
    dispatch(getUserProfileRequest());
    const resp = await instance.get(`/v1/Account/GetUserProfile`);
    
    dispatch(getUserProfileSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getUserProfileError(e.response.data.description));
    else
      dispatch(getUserProfileError(e.message));
  }
};

export const getUserOptionsRequest = createAction('GET_USER_OPTIONS_REQUEST');
export const getUserOptionsSuccess = createAction('GET_USER_OPTIONS_SUCCESS');
export const getUserOptionsError = createAction('GET_USER_OPTIONS_ERROR');

export const getUserOptions = () => async (dispatch) => {
  try {
    dispatch(getUserOptionsRequest());
    const resp = await instance.get(`/v1/Account/GetUserOption`);
    
    dispatch(getUserOptionsSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getUserOptionsError(e.response.data.description));
    else
      dispatch(getUserOptionsError(e.message));
  }
};

export const getAvatarRequest = createAction('USER_GET_AVATAR_REQUEST');
export const getAvatarSuccess = createAction('USER_GET_AVATAR_SUCCESS');
export const getAvatarError = createAction('USER_GET_AVATAR_ERROR');

export const getAvatar = () => async (dispatch) => {
  try {
    dispatch(getAvatarRequest());
    const resp = await instance.get(`/v1/Account/GetAvatarPic`, { 
        responseType: 'blob'
    });

    if(resp && resp.data)
      dispatch(getAvatarSuccess(resp.data));
    else
      dispatch(getAvatarSuccess(null));
  } catch (e) {

  }
};

export const updateUserRequest = createAction('USER_UPDATE_REQUEST');
export const updateUserSuccess = createAction('USER_UPDATE_SUCCESS');
export const updateUserError = createAction('USER_UPDATE_ERROR');

export const updateUser = (data) => async (dispatch) => {
    try {
      dispatch(updateUserRequest());
      const resp = await instance.put(`/v1/Account/UpdateUserProfile`, data);
      
      dispatch(updateUserSuccess(resp.data));
  
    } catch (e) {
      if(e.response && e.response.data && e.response.data.description)
        dispatch(updateUserError(e.response.data.description));
      else
        dispatch(updateUserError(e.message));
    }
  };

export const deleteAvatarRequest = createAction('USER_DELETE_AVATAR_REQUEST');
export const deleteAvatarSuccess = createAction('USER_DELETE_AVATAR_SUCCESS');
export const deleteAvatarError = createAction('USER_DELETE_AVATAR_ERROR');

export const deleteAvatar = (id) => async (dispatch) => {
  try {
    dispatch(deleteAvatarRequest());
    const resp = await instance.post(`/v1/UserAndRole/DeleteAvatarPic`, null,  {
        params: {
            applicationUserId: id
        }
      }
    );

    dispatch(deleteAvatarSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(deleteAvatarError(e.response.data.description));
    else
      dispatch(deleteAvatarError(e.message));
  }
};