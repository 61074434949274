import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  getServicesRequest,
  getServicesSuccess,
  getServicesError,
  getConnectorsRequest,
  getConnectorsSuccess,
  getConnectorsError,
  getCronIntervalsRequest,
  getCronIntervalsSuccess,
  getCronIntervalsError,
  deleteServiceRequest,
  deleteServiceSuccess,
  deleteServiceError,
  executeServiceRequest,
  executeServiceSuccess,
  executeServiceError
} from './servicesAction';

const defaultState = {
  services: [],
  isLoadingServices: true,
  getServicesError: null,
  connectors: [],
  isLoadingConnectors: true,
  getConnectorsError: null,
  cronIntervals: [],
  isLoadingCronIntervals: true,
  getCronIntervalsError: null,
  isDeletingService: false,
  deleteServiceError: null,
  deletedService: false,
  isExecutingService: false,
  executeServiceError: null,
  executedService: false
};

export default handleActions(
  {
    [getServicesRequest](state) {
      return {
        ...state,
        services: [],
        isLoadingServices: true,
        getServicesError: null,
      };
    },
    [getServicesSuccess](state, { payload }) {
      return {
        ...state,
        services: payload,
        isLoadingServices: false,
        getServicesError: null,
      };
    },
    [getServicesError](state, { payload }) {
      return {
        ...state,
        services: [],
        isLoadingServices: false,
        getServicesError: payload,
      };
    },
    [getConnectorsRequest](state) {
      return {
        ...state,
        connectors: [],
        isLoadingConnectors: true,
        getConnectorsError: null,
      };
    },
    [getConnectorsSuccess](state, { payload }) {
      return {
        ...state,
        connectors: payload,
        isLoadingConnectors: false,
        getConnectorsError: null,
      };
    },
    [getConnectorsError](state, { payload }) {
      return {
        ...state,
        connectors: [],
        isLoadingConnectors: false,
        getConnectorsError: payload,
      };
    },
    [getCronIntervalsRequest](state) {
      return {
        ...state,
        cronIntervals: [],
        isLoadingCronIntervals: true,
        getCronIntervalsError: null,
      };
    },
    [getCronIntervalsSuccess](state, { payload }) {
      return {
        ...state,
        cronIntervals: payload,
        isLoadingCronIntervals: false,
        getCronIntervalsError: null,
      };
    },
    [getCronIntervalsError](state, { payload }) {
      return {
        ...state,
        cronIntervals: [],
        isLoadingCronIntervals: false,
        getCronIntervalsError: payload,
      };
    },
    [deleteServiceRequest](state) {
      return {
        ...state,
        isDeletingService: true,
        deletedService: false,
        deleteServiceError: null,
      };
    },
    [deleteServiceSuccess](state, { payload }) {
      return {
        ...state,
        isDeletingService: false,
        deletedService: true,
        deleteServiceError: null,
      };
    },
    [deleteServiceError](state, { payload }) {
      return {
        ...state,
        isDeletingService: false,
        deletedService: false,
        deleteServiceError: payload,
      };
    },
    [executeServiceRequest](state) {
      return {
        ...state,
        isExecutingService: true,
        executedService: false,
        executeServiceError: null,
      };
    },
    [executeServiceSuccess](state, { payload }) {
      return {
        ...state,
        isExecutingService: false,
        executedService: true,
        executeServiceError: null,
      };
    },
    [executeServiceError](state, { payload }) {
      return {
        ...state,
        isExecutingService: false,
        executedService: false,
        executeServiceError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);