import * as type from "../types";

const initialState = {
  theme: "dark",
};

const ChangeThemeReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.CHANGE_THEME:
      return { ...state, theme: action.theme };
    default:
      return state;
  }
};

export default ChangeThemeReducer;
