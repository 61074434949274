import { createAction } from 'redux-actions';
import { instance } from "../../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_SYSTEM_TENANT_UPDATE');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getTenantRequest = createAction('SYSTEM_TENANT_UPDATE_GET_REQUEST');
export const getTenantSuccess = createAction('SYSTEM_TENANT_UPDATE_GET_SUCCESS');
export const getTenantError = createAction('SYSTEM_TENANT_UPDATE_GET_ERROR');

export const getTenant = (id) => async (dispatch) => {
  try {
    dispatch(getTenantRequest());

    const resp = await instance.get(`/v1/Tenant/GetTenant/${id}`);
    
    dispatch(getTenantSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getTenantError(e.response.data.description));
    else
      dispatch(getTenantError(e.message));
  }
};

export const updateTenantRequest = createAction('SYSTEM_TENANT_UPDATE_REQUEST');
export const updateTenantSuccess = createAction('SYSTEM_TENANT_UPDATE_SUCCESS');
export const updateTenantError = createAction('SYSTEM_TENANT_UPDATE_ERROR');

export const updateTenant = (data) => async (dispatch) => {
  try {
    dispatch(updateTenantRequest());

    const resp = await instance.put(`/v1/Tenant/Put`, data);
    
    dispatch(updateTenantSuccess(resp.data));

  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(updateTenantError(e.response.data.description));
    else
      dispatch(updateTenantError(e.message));
  }
};