import { useForm } from "react-hook-form";
import SearchIcon from '@mui/icons-material/Search';
import { FormTextField } from '@/shared/components/form/material-controls/TextField';
import styled from "styled-components";
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, Grid, Icon, IconButton } from "@mui/material";
import { useState } from "react";

export const SearchFilters = ({
    showSearch = true,
    searchColumns = 6,
    searchLabel='Search',
    onFilterSubmit,
    renderChildrenInputs,
    renderAvancedFilters,
}) => {

    const { handleSubmit, reset, control, setValue } = useForm();
    const [expanded, setExpanded] = useState(false);

    const handleOpen = () => {
        setExpanded(!expanded);
    };

    return(
        <Accordion 
            expanded={expanded} 
            sx={{ 
                bgcolor: 'cardBackground2.main', 
                padding: 0, 
                borderTop: 'none' ,
                "& > .Mui-focusVisible": {
                    backgroundColor: 'initial !important'
                }
            }}>
                <AccordionSummary sx={{paddingBottom: 0}}>
                <Grid 
                    container
                    direction="row"
                    justifyContent="space-between"
                    spacing={2}>
                    <Grid item xs={12} md={10}>
                        <Grid 
                            container
                            direction="row"
                            alignItems="center" 
                            spacing={2}>
                            
                            {showSearch &&
                                <Grid item xs={12} md={searchColumns}>
                                    <FormTextField
                                        style={{margin: 0}}
                                        name="filter" 
                                        label={searchLabel} 
                                        control={control}
                                    />
                                </Grid>
                            }

                            {renderChildrenInputs && renderChildrenInputs(control)}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={handleSubmit(onFilterSubmit)}
                                endIcon={<SearchIcon />}>
                                    Search
                            </Button>

                            {renderAvancedFilters && 
                                <IconButton onClick={() => handleOpen()}>
                                    <Icon>filter_alt</Icon>
                                    {expanded ?  <Icon>expand_less</Icon> : <Icon>expand_more</Icon>}
                                </IconButton>
                            }
                        </div>
                    </Grid>
                </Grid>
                </AccordionSummary>

                {renderAvancedFilters &&
                    <AccordionDetails sx={{paddingTop: 0}}>
                        <Grid 
                            container
                            direction="row"
                            alignItems="center" 
                            spacing={2}>
                            {renderAvancedFilters(control)}
                        </Grid>
                    </AccordionDetails>
                }
        </Accordion>
    );
};

export const CardContentWrapper = styled(CardContent)`
  padding: 16px !important;
`;