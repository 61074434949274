import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_LOGS');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getLogsRequest = createAction('GET_LOGS_REQUEST');
export const getLogsSuccess = createAction('GET_LOGS_SUCCESS');
export const getLogsError = createAction('GET_LOGS_ERROR');

export const getLogs = (data) => async (dispatch) => {
  try {
    dispatch(getLogsRequest());
    
    const resp = await instance.get(`/v1/LogInfra/GetLogByFilters`, { params: {
      ...data
    }});
    
    dispatch(getLogsSuccess(resp.data));
    
  } catch (e) {
    if(e.response && e.response.data && e.response.data.description)
      dispatch(getLogsError(e.response.data.description));
    else
      dispatch(getLogsError(e.message));
  }
};