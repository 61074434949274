import { handleActions } from 'redux-actions';
import {
  cleanupAction,
  createAssetTypesRequest,
  createAssetTypesSuccess,
  createAssetTypesError,
} from './assetTypeCreateAction';

const defaultState = {
  isCreatingAssetType: false,
  createdAssetType: false,
  creatingAssetTypeError: null
};

export default handleActions(
  {
    [createAssetTypesRequest](state) {
      return {
        ...state,
        isCreatingAssetType: true,
        createdAssetType: false,
        creatingAssetTypeError: null,
      };
    },
    [createAssetTypesSuccess](state, { payload }) {
      return {
        ...state,
        isCreatingAssetType: false,
        createdAssetType: true,
        creatingAssetTypeError: null
      };
    },
    [createAssetTypesError](state, { payload }) {
      return {
        ...state,
        isCreatingAssetType: false,
        createdAssetType: false,
        creatingAssetTypeError: payload
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);
