import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
} from 'react-bootstrap';
import { getAllAssets, getClassifications, cleanup } from "@/reducers/asset/view/assetViewAction";
import MaterialAssetInfoModal from './components/materialAssetInfoModal';
import { DataGrid, GridActionsCellItem, GridToolbar, gridClasses } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import { CardPage, CardPageBody, CardPageTitleWrap, CardPageHeader, CardPageTitle, CardPageSubhead, CardPageButtonWrap, CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import { enqueueSnackbar } from 'notistack';
import { useMemo } from 'react';
import ClassificationChip from './components/classificationChip';
import { Box, Typography } from '@mui/material';
import ExpandableCell from '@/shared/components/datagrid/ExpandableCell';
import { useTranslation } from 'react-i18next';


const AssetInventory = () => {
  const {t} = useTranslation("common");
  
  const dispatch = useDispatch();
  const { 
    assets, isLoadingAssets, getAssetsError,
    classifications, isLoadingClassifications, getClassificationsError
  } = useSelector(({ assetView }) => assetView);
  
  const [showInfoModal, setShowInfoModal] = useState({show: false, assetId: null});
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    dispatch(getAllAssets());
    dispatch(getClassifications());

    return () => {
      dispatch(cleanup());
    }
  }, [dispatch]);

  const mappedAssets = useMemo(() => {
    const clonnedAssets = JSON.parse(JSON.stringify(assets));
    clonnedAssets.forEach(a => { 
      a.assetTypeName = a.assetType.nameTraslated;
    });
    return clonnedAssets;
  }, [assets]);

  useEffect(() => {
    if(getAssetsError){
        enqueueSnackbar(getAssetsError, {variant: "error"});
    }
}, [getAssetsError]);

  useEffect(() => {
    if(getClassificationsError){
        enqueueSnackbar(getClassificationsError, {variant: "error"});
    }
  }, [getClassificationsError]);

  const columns = [
    { 
        field: 'name', 
        headerName: 'Name',
        minWidth: 150,
        flex: 1,
    },
    { 
      field: 'assetTypeName', 
      headerName: 'Type',
      minWidth: 150,
      flex: 1,
    },
    { 
      field: 'descriptionTraslated', 
      headerName: 'Description',
      minWidth: 250,
      renderCell: (params) => <ExpandableCell {...params} maxLength={150} />,
      flex: 1,
    },
    { 
      field: 'classification',
      headerName: 'Classification',
      minWidth: 350,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const confidentiality = classifications.find(r => r.id === params.value?.confidentialityId);
        const integrity = classifications.find(r => r.id === params.value?.integrityId);
        const availability = classifications.find(r => r.id === params.value?.availabilityId);

        return (
          <Box sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignContent: 'center',
            width: '100%'
          }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
            }}>
              <ClassificationChip letter="C" name="Confidentiality" classification={confidentiality} />
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center'
            }}>
              <ClassificationChip letter="I"  name="Integrity"  classification={integrity} />
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center'
            }}>
              <ClassificationChip letter="A"  name="Availability"  classification={availability} />
            </Box>
          </Box>
        );
      },
      valueFormatter: (params) => {
        const confidentiality = classifications.find(r => r.id === params.value?.confidentialityId);
        const integrity = classifications.find(r => r.id === params.value?.integrityId);
        const availability = classifications.find(r => r.id === params.value?.availabilityId);

        return confidentiality?.name + integrity?.name + availability?.name;
      }
    },
    { 
      field: 'isCritical', 
      headerName: 'Critical', 
      type: 'boolean',
      minWidth: 150
    },
    {
        field: 'actions',
        type: 'actions',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<SearchIcon />}
            label="View"
            onClick={() => openInfoModal(params.id)}
          />
        ],
      },
  ];

  // const columnGroupingModel = [
  //   {
  //     groupId: 'Classification',
  //     headerAlign: 'center',
  //     children: [
  //       { field: 'confidentialityId' },
  //       { field: 'integrityId' },
  //       { field: 'availabilityId' }
  //     ],
  //   },
  // ];

  const onHideModal = () => {
    setShowInfoModal({show: false, assetId: null});
  };

  const openInfoModal = (selectedAsset) => {
    setShowInfoModal({show: true, assetId: selectedAsset});
  };

  return (
    <Container>
      <CardPage>
        <CardPageBody>
            <CardPageHeader>
              <CardPageTitleWrap>
                <CardPageTitle>{t("app.assetManagement.title")}</CardPageTitle>
                <CardPageSubhead>
                  {t("app.assetManagement.subtitle")}
                </CardPageSubhead>
              </CardPageTitleWrap>
            </CardPageHeader>

            <CardPageGridWrap>
              <DataGrid 
                  // experimentalFeatures={{ columnGrouping: true }}
                  // columnGroupingModel={columnGroupingModel}
                  autoHeight
                  disableRowSelectionOnClick
                  rowSelectionModel={selectedRows}
                  loading={isLoadingAssets || isLoadingClassifications} 
                  rows={mappedAssets}
                  getRowId={(row) => row._id}
                  columns={columns} 
                  slots={{ toolbar: GridToolbar }} 
                  slotProps={{
                      toolbar: {
                      showQuickFilter: true
                      },
                  }}
                  sx={{
                      [`& .${gridClasses.cell}`]: {
                        py: 1,
                      },
                  }}
                  initialState={{
                      pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  pageSizeOptions={[10, 25, 50]}
              />
            
            </CardPageGridWrap>

            { showInfoModal.show &&
              <MaterialAssetInfoModal 
              onHideModal={onHideModal} 
              showModal={showInfoModal.show}
              assetId={showInfoModal.assetId}
              classifications={classifications}
              />
            }
        </CardPageBody>
      </CardPage>
    </Container>
  );
};

export default AssetInventory;